import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { handlePostRequest } from "../../helpers/handle-response";

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();
  const [status, setStatus] = useState({
    state: false,
    message: "",
    loading: false,
  });

  const submit = (data) => {
    setStatus((status) => ({ ...status, loading: true }));
    console.log(data);
    handlePostRequest(
      `${process.env.REACT_APP_HOST_URL}/users/forgotPassword`,
      [
        {
          email: data.email,
        },
      ]
    )
      .then((res) => {
        if (res.data) {
          setStatus({ state: true, message: res.data, loading: false });
          //remove form add success msg
        }
      })
      .catch((err) => {
        console.log(err);
        if (err) {
          setStatus((status) => ({
            ...status,
            state: true,
            error: "Korisnik s tim emailom ne postoji",
            loading: false,
          }));
          setTimeout(() => {
            setStatus((status) => ({
              ...status,
              state: false,
              error: "",
              loading: false,
            }));
          }, 2000);
        }
      });
  };

  return (
    <div className="nk-app-root mt-n3">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <div className="nk-split nk-split-page nk-split-md">
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white w-lg-45">
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="brand-logo pb-5">
                    <div className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src="/static/media/logo1beta.e9731041.png"
                        alt="logo"
                      ></img>
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src="/static/media/logo1beta.e9731041.png"
                        alt="logo-dark"
                      ></img>
                    </div>
                  </div>

                  {status.state && status.message.length > 0 ? (
                    <div className="d-flex flex-wrap mt-3">
                      <div className="w-100 d-flex justify-content-center">
                        <svg
                          width="10em"
                          height="10em"
                          viewBox="0 0 16 16"
                          className="bi bi-check-square-fill"
                          fill="#62B697"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                          />
                        </svg>
                      </div>
                      <div className="mx-auto mt-4">
                        <p
                          className="font-weight-bold text-center"
                          style={{ fontSize: 18 }}
                        >
                          Provjeri email
                        </p>
                        <p
                          className="font-weight-bold"
                          style={{ fontSize: 22 }}
                        >
                          {status.message}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            Zaboravljena lozinka
                          </h5>
                          <div className="nk-block-des">
                            <p>
                              Ako ste zaboravili lozinku, poslat ćemo vam email
                              s uputstvima kako resetirati lozinku.
                            </p>
                            {/*  <p>
                          If you forgot your password, well, then we'll email
                          you instructions to reset your password
                        </p> */}
                          </div>
                        </div>
                      </div>
                      <form onSubmit={handleSubmit(submit)}>
                        <div className="form-group">
                          <div className="form-label-group">
                            <label className="form-label" for="email">
                              Email
                            </label>
                            <a className="link link-primary link-sm" href="#">
                              Need help?
                            </a>
                          </div>
                          <input
                            id="email"
                            name="email"
                            ref={register({
                              required: "Unesi email računa",
                              pattern: {
                                value: /^\S+@\S+$/i,
                                message: "Email nije ispravan",
                              },
                            })}
                            className={`form-control form-control-lg ${
                              errors.email && "error"
                            }`}
                            type="text"
                            placeholder="Unesi svoj email"
                          />
                          {errors.email && (
                            <span className="error position-absolute">
                              {errors.email.message}
                            </span>
                          )}
                        </div>
                        {status.state && status.error.length > 0 && (
                          <div
                            style={{ maxWidth: 800 }}
                            className="mx-auto mb-3"
                          >
                            <div
                              className="alert alert-dismissible alert-icon alert-fill alert-danger"
                              role="alert"
                            >
                              <em className="icon ni ni-cross-circle"></em>
                              {status.error.length > 0 && status.error}
                              <button
                                className="close"
                                data-dismiss="alert"
                                type="button"
                                onClick={() =>
                                  setStatus({ state: false, error: "" })
                                }
                              ></button>
                            </div>
                          </div>
                        )}
                        <div className="form-group">
                          <button
                            disabled={status.loading}
                            type="submit"
                            class="btn btn-lg btn-primary btn-block"
                          >
                            Pošalji link za reset
                          </button>
                        </div>
                      </form>
                      <div className="form-note-s2 pt-5">
                        <Link to="/login">
                          <strong>Vrati se na prijavu</strong>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
                <div className="nk-block nk-auth-footer">
                  <div className="nk-block-between">
                    <ul className="nav nav-sm">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Terms & Condition
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Help
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>© 2021 Paušal.hr All Rights Reserved.</p>
                  </div>
                </div>
              </div>
              <div className="nk-split-content nk-split-stretch bg-abstract"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

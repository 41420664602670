import Button from "../../components/Button";
import Modal from "../../components/Modal";

const DeleteModal = ({ open, toggle, state, handleDelete }) => {
  const invoice = state.invoicePreview;
  return (
    <Modal
      title="Obriši račun"
      open={open}
      handleClose={() => toggle(false)}
      toggle={() => toggle((open) => !open)}
      size="lg"
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-center">
              Jeste li sigurni da želite obrisati račun:{" "}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col my-2">
            <p
              className="text-center"
              style={{ fontSize: 22, fontWeight: 500 }}
            >
              Broj računa:{" "}
              <span style={{ fontWeight: 600 }}>{invoice.broj}</span>
            </p>
          </div>
          <div className="col-12">
            <p
              className="text-center"
              style={{ fontSize: 18, fontWeight: 600 }}
            >
              Obrisat će se i plaćanja
            </p>
          </div>
          <div className="col-12 ">
            <Button
              type="button"
              onClick={() => handleDelete(invoice.id)}
              classnames="customButton d-flex btn-dim btn-outline-danger form-control-lg d-flex mt-2 mx-auto mb-0 "
            >
              Obriši
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;

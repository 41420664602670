import { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { authenticationService } from "../services/authentication";

export default function PrivateRoute({ children, checkUser, ...rest }) {
  const currentUser = authenticationService.currentUserValue;
  const [isDataFilled, setIsDataFilled] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const hasFalse = [];
    if (currentUser) {
      Object.keys(currentUser).map((key, index) => {
        if (
          key !== "pdvid" &&
          key !== "podrucniUred" &&
          key !== "poreznaKod" &&
          key !== "ispostava" &&
          String(currentUser[key]).trim().length < 1
        ) {
          hasFalse.push(false);
        }
        return null;
      });
    }
    hasFalse.includes(false) ? setIsDataFilled(false) : setIsDataFilled(true);
    setIsChecking(false);
  }, [currentUser]);

  return !isChecking ? (
    <Route
      {...rest}
      render={({ location }) =>
        currentUser ? (
          checkUser ? (
            isDataFilled ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            )
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  ) : null;
}

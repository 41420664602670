export const InvoiceList = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>
    <rect
      x="12"
      y="5"
      width="55"
      height="68"
      rx="6"
      ry="6"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M78,15.8l-5.72,6.33L66.7,16l-5.33,5.91-5.82-6.46L49.67,22,44.13,15.8l-5.49,6.09L33,15.62,27.27,22l-6.16-6.83L21,15V79c0,3.33,2.43,6,5.43,6H72.57c3,0,5.43-2.69,5.43-6V15.76S77.94,15.79,78,15.8Z"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="49.5"
      cy="35.5"
      r="2.5"
      fill="url('#fill')"
      stroke="none"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="71"
      y1="50"
      x2="28"
      y2="49.86"
      fill="none"
      stroke="url('#fill')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="71"
      y1="56"
      x2="28"
      y2="55.86"
      fill="none"
      stroke="url('#fill')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="71"
      y1="61"
      x2="28"
      y2="60.86"
      fill="none"
      stroke="url('#fill')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="28"
      y1="45"
      x2="38"
      y2="45"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="61"
      y1="45"
      x2="71"
      y2="45"
      fill="none"
      stroke="rgba(29, 38, 113, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="28"
      y1="76"
      x2="41"
      y2="76"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="61"
      y1="76"
      x2="69"
      y2="76"
      fill="none"
      stroke="rgba(29, 38, 113, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="59"
      y1="66"
      x2="69"
      y2="66"
      fill="none"
      stroke="rgba(29, 38, 113, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
export const NewInvoice = () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>

    <path
      d="M61 5H18C14.6863 5 12 7.68629 12 11V67C12 70.3137 14.6863 73 18 73H61C64.3137 73 67 70.3137 67 67V11C67 7.68629 64.3137 5 61 5Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78 15.8L72.28 22.13L66.7 16L61.37 21.91L55.55 15.45L49.67 22L44.13 15.8L38.64 21.89L33 15.62L27.27 22L21.11 15.17L21 15V79C21 82.33 23.43 85 26.43 85H72.57C75.57 85 78 82.31 78 79V15.76C78 15.76 77.94 15.79 78 15.8Z"
      fill="white"
      stroke="#6B2E6B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M49.5 38C50.8807 38 52 36.8807 52 35.5C52 34.1193 50.8807 33 49.5 33C48.1193 33 47 34.1193 47 35.5C47 36.8807 48.1193 38 49.5 38Z"
      fill="url('#fill')"
      stroke="none"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M71 50L28 49.86"
      stroke="url('#fill')"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71 56L28 55.86"
      stroke="url('#fill')"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71 61L28 60.86"
      stroke="url('#fill')"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 45H38"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61 45H71"
      stroke="rgba(29, 38, 113, 0.6)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 76H41"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61 76H69"
      stroke="rgba(29, 38, 113, 0.6)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59 66H69"
      stroke="rgba(29, 38, 113, 0.6)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71 88.04C80.9411 88.04 89 79.9722 89 70.02C89 60.0678 80.9411 52 71 52C61.0589 52 53 60.0678 53 70.02C53 79.9722 61.0589 88.04 71 88.04Z"
      fill="white"
      stroke="#6B2E6B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71 77.04C74.866 77.04 78 73.906 78 70.04C78 66.174 74.866 63.04 71 63.04C67.134 63.04 64 66.174 64 70.04C64 73.906 67.134 77.04 71 77.04Z"
      fill="none" /*Ukoliko želimo krug ispod plusa promijenit boju*/
    />
    <path
      d="M71 79V70.04V61"
      stroke="rgba(192, 55, 100, .9)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80 70H71.04H62"
      stroke="rgba(192, 55, 100, .9)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NewPayment = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>

    <rect
      x="9"
      y="21"
      width="55"
      height="39"
      rx="6"
      ry="6"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="17"
      y1="44"
      x2="25"
      y2="44"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="30"
      y1="44"
      x2="38"
      y2="44"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="42"
      y1="44"
      x2="50"
      y2="44"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="17"
      y1="50"
      x2="36"
      y2="50"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x="16"
      y="31"
      width="15"
      height="8"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <path
      d="M76.79,72.87,32.22,86.73a6,6,0,0,1-7.47-4L17,57.71A6,6,0,0,1,21,50.2L65.52,36.34a6,6,0,0,1,7.48,4l7.73,25.06A6,6,0,0,1,76.79,72.87Z"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polygon
      points="75.27 47.3 19.28 64.71 17.14 57.76 73.12 40.35 75.27 47.3"
      fill="url('#stroke')"
    />
    <path
      d="M30,77.65l-1.9-6.79a1,1,0,0,1,.69-1.23l4.59-1.3a1,1,0,0,1,1.23.7l1.9,6.78A1,1,0,0,1,35.84,77l-4.59,1.3A1,1,0,0,1,30,77.65Z"
      fill="url('#fill')"
    />
    <path
      d="M41.23,74.48l-1.9-6.78A1,1,0,0,1,40,66.47l4.58-1.3a1,1,0,0,1,1.23.69l1.9,6.78A1,1,0,0,1,47,73.88l-4.58,1.29A1,1,0,0,1,41.23,74.48Z"
      fill="url('#fill')"
    />
    <path
      d="M52.43,71.32l-1.9-6.79a1,1,0,0,1,.69-1.23L55.81,62A1,1,0,0,1,57,62.7l1.9,6.78a1,1,0,0,1-.69,1.23L53.66,72A1,1,0,0,1,52.43,71.32Z"
      fill="url('#fill')"
    />
    <ellipse
      cx="55.46"
      cy="19.1"
      rx="16.04"
      ry="16.1"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <ellipse cx="55.46" cy="19.1" rx="12.11" ry="12.16" fill="url('#fill')" />
    <text
      transform="translate(50.7 23.72) scale(0.99 1)"
      fontSize="16.12"
      fill="#6B2E6B"
      fontFamily="Nunito-Black, Nunito Black"
    >
      $
    </text>
  </svg>
);

export const Statistic = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>
    <path
      d="M40.74,5.16l38.67,9.23a6,6,0,0,1,4.43,7.22L70.08,80a6,6,0,0,1-7.17,4.46L24.23,75.22A6,6,0,0,1,19.81,68L33.56,9.62A6,6,0,0,1,40.74,5.16Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M50.59,6.5,11.18,11.73a6,6,0,0,0-5.13,6.73L13.85,78a6,6,0,0,0,6.69,5.16l39.4-5.23a6,6,0,0,0,5.14-6.73l-7.8-59.49A6,6,0,0,0,50.59,6.5Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x="15"
      y="15"
      width="54"
      height="70"
      rx="6"
      ry="6"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="42"
      y1="77"
      x2="58"
      y2="77"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="38"
      cy="77"
      r="0.5"
      fill="url('#fill')"
      stroke="url('#fill')"
      strokeMiterlimit="10"
    />
    <line
      x1="42"
      y1="72"
      x2="58"
      y2="72"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="38"
      cy="72"
      r="0.5"
      fill="url('#fill')"
      stroke="url('#fill')"
      strokeMiterlimit="10"
    />
    <line
      x1="42"
      y1="66"
      x2="58"
      y2="66"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="38"
      cy="66"
      r="0.5"
      fill="url('#fill')"
      stroke="url('#fill')"
      strokeMiterlimit="10"
    />
    <path
      d="M46,40l-15-.3V40A15,15,0,1,0,46,25h-.36Z"
      fill="url('#fill')"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M42,22A14,14,0,0,0,28,36H42V22"
      fill="url('#fill')"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="33.47 30.07 28.87 23 23 23"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="25 56 35 56 40.14 49"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export const KPR = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>
    <path
      d="M68.14,80.86,30.21,72.69a5.93,5.93,0,0,1-4.57-7l12.26-56A6,6,0,0,1,45,5.14l28.18,6.07L85.5,29.51,75.24,76.33A6,6,0,0,1,68.14,80.86Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="73 12.18 69.83 26.66 85.37 30.08"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M66.26,71.15,29.05,82.46a6,6,0,0,1-7.46-4L4.76,23.15a6,6,0,0,1,4-7.47l27.64-8.4L56.16,17.39,70.24,63.68A6,6,0,0,1,66.26,71.15Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="36.7 8.22 41.05 22.53 56.33 17.96"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M68,85H29a6,6,0,0,1-6-6V21a6,6,0,0,1,6-6H58L74,30.47V79A6,6,0,0,1,68,85Z"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="58 16 58 31 74 31.07"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="45"
      y1="41"
      x2="61"
      y2="41"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="35"
      y1="48"
      x2="61"
      y2="48"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="35"
      y1="55"
      x2="61"
      y2="55"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="35"
      y1="63"
      x2="61"
      y2="63"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="35"
      y1="69"
      x2="51"
      y2="69"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <text
      transform="translate(34.54 43.18) scale(0.99 1)"
      fontSize="9.31"
      fill="url('#stroke')"
      fontFamily="Nunito-Black, Nunito Black"
    >
      $
    </text>
  </svg>
);

export const PaymentReview = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>
    <path
      d="M43.94,13.5H81a6,6,0,0,1,6,6v36a6,6,0,0,1-6,6H22a6,6,0,0,1-6-6v-36a6,6,0,0,1,6-6H43.94Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M38.94,20.5H76a6,6,0,0,1,6,6v36a6,6,0,0,1-6,6H17a6,6,0,0,1-6-6v-36a6,6,0,0,1,6-6H38.94Z"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x="3"
      y="26.5"
      width="73"
      height="50"
      rx="6"
      ry="6"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle
      cx="64.5"
      cy="65"
      r="4.5"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <circle
      cx="59.5"
      cy="65"
      r="4.5"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <rect
      x="11"
      y="32.5"
      width="13"
      height="9"
      fill="url('#fill')"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x="10"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="16"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="23"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="30"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="37"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="43"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="50"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="57"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect
      x="64"
      y="47.5"
      width="2"
      height="5"
      rx="1"
      ry="1"
      fill="url('#fill')"
    />
    <rect x="65" y="32.5" width="4" height="6" fill="url('#fill')" />
    <rect x="58" y="32.5" width="4" height="6" fill="url('#fill')" />
    <rect x="51" y="32.5" width="4" height="6" fill="url('#fill')" />
    <line
      x1="10.5"
      y1="61"
      x2="34.5"
      y2="61"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
    <line
      x1="10.5"
      y1="68"
      x2="17.5"
      y2="68"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
    <line
      x1="22.5"
      y1="68"
      x2="39.5"
      y2="68"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);
export const Storage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>
    <rect
      x="14.5"
      y="5"
      width="56"
      height="70"
      rx="6"
      ry="6"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M69.38,85H29.62a6.06,6.06,0,0,1-6.12-6V21a6.06,6.06,0,0,1,6.12-6H59.16L75.5,30.47V79A6.06,6.06,0,0,1,69.38,85Z"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="59.5 16 59.5 31 74.5 31.07"
      fill="none"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <rect
      x="34.5"
      y="40"
      width="30"
      height="30"
      fill="url('#fill')"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="34.5"
      y1="48"
      x2="63.5"
      y2="48"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="45.5"
      y1="70"
      x2="45.5"
      y2="40"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="34.5"
      y1="56"
      x2="63.5"
      y2="56"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="34.5"
      y1="64"
      x2="63.5"
      y2="64"
      fill="none"
      stroke="url('#stroke')"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export const FormIco = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
    <defs>
      <linearGradient id="stroke" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="#c33764" />
        <stop offset="95%" stopColor="#1d2671" />
      </linearGradient>
      <linearGradient id="fill" gradientTransform="rotate(0)">
        <stop offset="15%" stopColor="rgba(192, 55, 100, 0.6)" />
        <stop offset="95%" stopColor="rgba(29, 38, 113, 0.6)" />
      </linearGradient>
    </defs>
    <rect
      x="15"
      y="5"
      width="56"
      height="70"
      rx="6"
      ry="6"
      fill="url('#fill')"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
      fill="#fff"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <polyline
      points="60 16 60 31 75 31.07"
      fill="none"
      stroke="#6B2E6B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="58"
      y1="50"
      x2="32"
      y2="50"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="46"
      y1="38"
      x2="32"
      y2="38"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="68"
      y1="44"
      x2="32"
      y2="44"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="68"
      y1="56"
      x2="32"
      y2="56"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="58"
      y1="62"
      x2="32"
      y2="62"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="68"
      y1="68"
      x2="32"
      y2="68"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      x1="58"
      y1="75"
      x2="32"
      y2="75"
      fill="none"
      stroke="rgba(192, 55, 100, 0.6)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);
export const GoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 48 48"
    width="20"
    height="20"
  >
    <defs>
      <path
        id="a"
        d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"
      />
    </defs>
    <clipPath id="b">
      <use xlinkHref="#a" overflow="visible" />
    </clipPath>
    <path clipPath="url(#b)" fill="#FBBC05" d="M0 37V11l17 13z" />
    <path clipPath="url(#b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
    <path clipPath="url(#b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
    <path clipPath="url(#b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
  </svg>
);

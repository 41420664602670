import { Modal } from "@material-ui/core";

const CustomModal = ({
  open,
  toggle,
  title,
  children,
  handleClose,
  size,
  footer,
}) => {
  return (
    <Modal open={open} onClose={handleClose ? handleClose : null}>
      <div
        className="modal fade d-block"
        style={{ opacity: 1, overflowY: "auto" }}
        tabIndex="-1"
        id="modalForm"
      >
        <div className={`modal-dialog modal-${size}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                href="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggle}
              >
                <em className="icon ni ni-cross"></em>
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer bg-light">
              <span className="sub-text">{footer}</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CustomModal;

import { useMemo } from "react";
import { useQuery } from "react-query";
import Table from "../../components/Table";
import { formatCurrency } from "../../helpers";
import { getInventura } from "../../helpers/getData";
import { useTitle } from "../../helpers/hooks";

const WarehouseReview = () => {
  useTitle("Inventura");
  const { data: skladiste, isFetching } = useQuery("inventura", getInventura);
  const columns = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Šifra", accessor: "sifra" },
      { Header: "Količina", accessor: "kolicina" },
      {
        Header: "Vrijednost",
        accessor: "vrijednost",
        Cell: (props) => formatCurrency(props.value),
      },
      {
        Header: "Ukupna vrijednost",
        accessor: "ukupno",
        Cell: (props) => formatCurrency(props.value),
      },
    ],
    []
  );
  return (
    <div className="container-fluid">
      {isFetching ? (
        <div>
          <h3>Učitavanje...</h3>
        </div>
      ) : (
        <Table
          columns={columns}
          data={skladiste.data}
          name="inventura"
          pdfName="pregledRacuna"
          exportCols={columns}
          //replace text and dataField inside invoicehr-en html/pdf template
          pdfCols={columns.map((item) => ({
            text: item.Header,
            dataField: item.accessor,
          }))}
          exportData={skladiste.data.map((item) => ({
            naziv: item.naziv,
            sifra: item.sifra,
            kolicina: item.kolicina,
            vrijednost: item.vrijednost,
            ukupno: item.ukupno,
          }))}
        />
      )}
    </div>
  );
};
export default WarehouseReview;

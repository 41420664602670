import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import saveAs from "file-saver";
import { formatCurrency } from "../../helpers";

export const getXml = (PDV, currentUser, dates, type) => {
  console.log(PDV, dates, type);
  const types = {
    PDV:
      type === "PDV" &&
      `<ObrazacPDV verzijaSheme="9.0" xmlns="http://e-porezna.porezna-uprava.hr/sheme/zahtjevi/ObrazacPDV/v9-0">
    <Metapodaci xmlns="http://e-porezna.porezna-uprava.hr/sheme/Metapodaci/v2-0">
      <Naslov dc="http://purl.org/dc/elements/1.1/title">Prijava poreza na dodanu vrijednost</Naslov>
      <Autor dc="http://purl.org/dc/elements/1.1/creator">${
        currentUser.vlasnik
      }</Autor>
      <Datum dc="http://purl.org/dc/elements/1.1/date">${dayjs().format(
        "YYYY-MM-DD'T'HH:mm:ss"
      )}</Datum>
      <Format dc="http://purl.org/dc/elements/1.1/format">text/xml</Format>
      <Jezik dc="http://purl.org/dc/elements/1.1/language">hr-HR</Jezik>
      <Identifikator dc="http://purl.org/dc/elements/1.1/identifier">${uuidv4()}</Identifikator>
      <Uskladjenost dc="http://purl.org/dc/terms/conformsTo">ObrazacPDV-v9-0</Uskladjenost>
      <Tip dc="http://purl.org/dc/elements/1.1/type">Elektronički obrazac</Tip>
      <Adresant>Ministarstvo Financija, Porezna uprava, Zagreb</Adresant>
    </Metapodaci>
    <Zaglavlje>
      <Razdoblje>
        <DatumOd>${dates.from.toISOString().split("T")[0]}</DatumOd>
        <DatumDo>${dates.till.toISOString().split("T")[0]}</DatumDo>
      </Razdoblje>
      <Obveznik>
        <Naziv>${currentUser.naziv}</Naziv>
        <OIB>${currentUser.oib}</OIB>
        <Adresa>
          <Mjesto>${currentUser.mjesto}</Mjesto>
          <Ulica>${currentUser.ulica}</Ulica>
          <Broj>${currentUser.kucniBroj}</Broj>
        </Adresa>
      </Obveznik>
      <ObracunSastavio>
        <Ime>${currentUser.ime}</Ime>
        <Prezime>${currentUser.prezime}</Prezime>
      </ObracunSastavio>
      <Ispostava>${currentUser.poreznaKod}</Ispostava>
    </Zaglavlje>
    <Tijelo>
      <Podatak000>${PDV.ukupnoSve}</Podatak000>
      <Podatak100>${PDV.ukupnoSve}</Podatak100>
      <Podatak101>0.00</Podatak101>
      <Podatak102>0.00</Podatak102>
      <Podatak103>${PDV.iznosRobeI3}</Podatak103>
      <Podatak104>${PDV.iznosUslugaI4}</Podatak104>
      <Podatak105>${PDV.iznosRobeI5 + PDV.iznosUslugaI5}</Podatak105>
      <Podatak106>0.00</Podatak106>
      <Podatak107>0.00</Podatak107>
      <Podatak108>0.00</Podatak108>
      <Podatak109>0.00</Podatak109>
      <Podatak110>0.00</Podatak110>
      <Podatak200>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak200>
      <Podatak201>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak201>
      <Podatak202>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak202>
      <Podatak203>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak203>
      <Podatak204>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak204>
      <Podatak205>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak205>
      <Podatak206>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak206>
      <Podatak207>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak207>
      <Podatak208>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak208>
      <Podatak209>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak209>
      <Podatak210>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak210>
      <Podatak211>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak211>
      <Podatak212>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak212>
      <Podatak213>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak213>
      <Podatak214>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak214>
      <Podatak215>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak215>
      <Podatak300>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak300>
      <Podatak301>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak301>
      <Podatak302>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak302>
      <Podatak303>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak303>
      <Podatak304>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak304>
      <Podatak305>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak305>
      <Podatak306>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak306>
      <Podatak307>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak307>
      <Podatak308>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak308>
      <Podatak309>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak309>
      <Podatak310>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak310>
      <Podatak311>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak311>
      <Podatak312>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak312>
      <Podatak313>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak313>
      <Podatak314>
        <Vrijednost>0.00</Vrijednost>
        <Porez>0.00</Porez>
      </Podatak314>
      <Podatak315>0.00</Podatak315>
      <Podatak400>0.00</Podatak400>
      <Podatak500>0.00</Podatak500>
      <Podatak600>0.00</Podatak600>
      <Podatak700>0.00</Podatak700>
      <Podatak810>0.00</Podatak810>
      <Podatak811>0.00</Podatak811>
      <Podatak812>0.00</Podatak812>
      <Podatak813>0.00</Podatak813>
      <Podatak814>0.00</Podatak814>
      <Podatak815>0.00</Podatak815>
      <Podatak820>0.00</Podatak820>
      <Podatak830>0.00</Podatak830>
      <Podatak831>
        <Vrijednost>0.00</Vrijednost>
        <Broj>0</Broj>
      </Podatak831>
      <Podatak832>
        <Vrijednost>0.00</Vrijednost>
        <Broj>0</Broj>
      </Podatak832>
      <Podatak833>
        <Vrijednost>0.00</Vrijednost>
        <Broj>0</Broj>
      </Podatak833>
      <Podatak840>0.00</Podatak840>
      <Podatak850>${PDV.ukupnoVIII5}</Podatak850>
      <Podatak860>0.00</Podatak860>
      <Podatak870>false</Podatak870>
    </Tijelo>
  </ObrazacPDV>`,
    ZP:
      type === "ZP" &&
      `<ObrazacZP verzijaSheme="1.0" xmlns="http://e-porezna.porezna-uprava.hr/sheme/zahtjevi/ObrazacZP/v1-0">
    <Metapodaci xmlns="http://e-porezna.porezna-uprava.hr/sheme/Metapodaci/v2-0">
      <Naslov dc="http://purl.org/dc/elements/1.1/title">Zbirna prijava za isporuke dobara i usluga u druge države članice Europske unije</Naslov>
      <Autor dc="http://purl.org/dc/elements/1.1/creator">${
        currentUser.vlasnik
      }</Autor>
      <Datum dc="http://purl.org/dc/elements/1.1/date">${dayjs().format(
        "YYYY-MM-DD'T'HH:mm:ss"
      )}</Datum>
      <Format dc="http://purl.org/dc/elements/1.1/format">text/xml</Format>
      <Jezik dc="http://purl.org/dc/elements/1.1/language">hr-HR</Jezik>
      <Identifikator dc="http://purl.org/dc/elements/1.1/identifier">${uuidv4()}</Identifikator>
      <Uskladjenost dc="http://purl.org/dc/terms/conformsTo">ObrazacZP-v1-0</Uskladjenost>
      <Tip dc="http://purl.org/dc/elements/1.1/type">Elektronički obrazac</Tip>
      <Adresant>Ministarstvo Financija, Porezna uprava, Zagreb</Adresant>
    </Metapodaci>
    <Zaglavlje>
      <Razdoblje>
        <DatumOd>${dates.from.toISOString().split("T")[0]}</DatumOd>
        <DatumDo>${dates.till.toISOString().split("T")[0]}</DatumDo>
      </Razdoblje>
      <Obveznik>
        <Naziv>${currentUser.naziv}</Naziv>
        <OIB>${currentUser.oib}</OIB>
        <Adresa>
          <Mjesto>${currentUser.mjesto}</Mjesto>
          <Ulica>${currentUser.ulica}</Ulica>
          <Broj>${currentUser.kucniBroj}</Broj>
        </Adresa>
      </Obveznik>
      <ObracunSastavio>
        <Ime>${currentUser.ime}</Ime>
        <Prezime>${currentUser.prezime}</Prezime>
        <Telefon>${"+385" + parseInt(currentUser.telefon, 10)}</Telefon>
        <Email>${currentUser.email}</Email>
      </ObracunSastavio>
      <Ispostava>${currentUser.poreznaKod}</Ispostava>
    </Zaglavlje>
    <Tijelo>
      <Isporuke>
      ${Object.keys(PDV.ZP)
        .map((key, index) => {
          return ` <Isporuka>
        <RedBr>${index + 1}</RedBr>
        <KodDrzave>${PDV.ZP[key].countryCode}</KodDrzave>
        <PDVID>${key}</PDVID>
        <I1>${PDV.ZP[key].zp11Iznos}</I1>
        <I2>0.00</I2>
        <I3>0.00</I3>
        <I4>${PDV.ZP[key].zp14Iznos}</I4>
      </Isporuka>`;
        })
        .join("")}
      </Isporuke>
      <IsporukeUkupno>
        <I1>${PDV.zpSum.zp11Iznos}</I1>
        <I2>0.00</I2>
        <I3>0.00</I3>
        <I4>${PDV.zpSum.zp14Iznos}</I4>
      </IsporukeUkupno>
    </Tijelo>
  </ObrazacZP>`,
  };
  const string = types[type];
  const blob = new Blob([string], { type: "text/xml" });
  const name =
    type === "PDV"
      ? "ObracunPDV.xml"
      : `ZP${dayjs(dates.from).format("MM.YYYY")}.xml`;
  saveAs(blob, name);
};

export function getPOSD(currentUser, data, invoices, callback) {
  const stupnjeviPDV = {
    1: 1530,
    2: 2070,
    3: 2691,
    4: 4140,
    5: 5400,
  };
  const bezgotovinski = invoices
    .filter((item) => {
      if (
        item.status === "Plaćeno" &&
        item.nacinPlacanja === "Transakcijski račun"
      ) {
        return item;
      }
      return null;
    })
    .reduce(
      (a, b) => {
        return {
          konacanIznos: Number(a.konacanIznos) + Number(b.konacanIznos),
        };
      },
      { konacanIznos: 0 }
    );
  const gotovinski = invoices
    .filter((item) => {
      if (
        item.status === "Plaćeno" &&
        item.nacinPlacanja !== "Transakcijski račun"
      ) {
        return item;
      }
      return null;
    })
    .reduce(
      (a, b) => {
        return {
          konacanIznos: Number(a.konacanIznos) + Number(b.konacanIznos),
        };
      },
      { konacanIznos: 0 }
    );
  const ukupno = bezgotovinski.konacanIznos + gotovinski.konacanIznos;

  function getPDVRazred(iznos) {
    if (iznos <= 85000) {
      return stupnjeviPDV[1];
    } else if (iznos >= 85000.01 && iznos <= 115000) {
      return stupnjeviPDV[2];
    } else if (iznos >= 115000.01 && iznos <= 149500) {
      return stupnjeviPDV[3];
    } else if (iznos >= 149500.01 && iznos <= 230000) {
      return stupnjeviPDV[4];
    } else if (iznos >= 230000.01 && iznos <= 300000) {
      return stupnjeviPDV[5];
    }
  }
  function getValues() {
    const iznosPoreza = getPDVRazred(ukupno);
    const prirez = iznosPoreza * (data.prirez.iznos / 100);
    const porezPrirez = iznosPoreza + prirez;
    const umanjenje = data.umanjenje ? 0 : 0;
    const nakonUmanjenja = porezPrirez - umanjenje;
    const uplaceno = data.uplaceno ? data.uplaceno : 0;
    //const razlikaIznos = nakonUmanjenja - uplaceno;
    const razlika = nakonUmanjenja - uplaceno;
    const mjesecno = razlika / 12;
    return {
      iznosPoreza,
      prirez,
      porezPrirez,
      umanjenje,
      nakonUmanjenja,
      uplaceno,
      razlika,
      mjesecno,
    };
  }
  const finalValues = getValues();
  const posd = `<ObrazacPOSD verzijaSheme="1.0" xmlns="http://e-porezna.porezna-uprava.hr/sheme/zahtjevi/ObrazacPOSD/v2-0">
	<Metapodaci xmlns="http://e-porezna.porezna-uprava.hr/sheme/Metapodaci/v2-0">
		<Naslov dc="http://purl.org/dc/elements/1.1/title">Izvješće o paušalnom dohotku od samostalnih djelatnosti i uplaćenom paušalnom porezu na dohodak i prirezu poreza na dohodak</Naslov>
		<Autor dc="http://purl.org/dc/elements/1.1/creator">TESTNI KORISNIK</Autor>
		<Datum dc="http://purl.org/dc/elements/1.1/date">${dayjs().format(
      "YYYY-MM-DD'T'HH:mm:ss"
    )}</Datum>
		<Format dc="http://purl.org/dc/elements/1.1/format">text/xml</Format>
		<Jezik dc="http://purl.org/dc/elements/1.1/language">hr-HR</Jezik>
		<Identifikator dc="http://purl.org/dc/elements/1.1/identifier">${uuidv4()}</Identifikator>
		<Uskladjenost dc="http://purl.org/dc/terms/conformsTo">ObrazacPOSD-v2-0</Uskladjenost>
		<Tip dc="http://purl.org/dc/elements/1.1/type">Elektronički obrazac</Tip>
		<Adresant>Ministarstvo Financija, Porezna uprava, Zagreb</Adresant>
	</Metapodaci>
	<Zaglavlje>
		<Obveznik>
			<OIB>${currentUser.oib}</OIB>
			<Ime>${currentUser.ime}</Ime>
			<Prezime>${currentUser.prezime}</Prezime>
			<Adresa>
				<Mjesto>${currentUser.mjesto}</Mjesto>
				<Ulica>${currentUser.ulica}</Ulica>
				<Broj>${currentUser.kucniBroj}</Broj>
			</Adresa>
			<Telefon/>
			<Email>${currentUser.email}</Email>
		</Obveznik>
		<PodaciODjelatnosti>
			<NazivDjelatnosti>${currentUser.nazivDjelatnosti}</NazivDjelatnosti>
			<VrstaDjelatnostiNkd2007>${currentUser.djelatnost.replace(
        ".",
        ""
      )}</VrstaDjelatnostiNkd2007>
			<AdresaDjelatnosti>${currentUser.adresa}</AdresaDjelatnosti>
		</PodaciODjelatnosti>
		<Razdoblje>
			<DatumOd>2019-01-01</DatumOd>
			<DatumDo>2019-12-31</DatumDo>
		</Razdoblje>
		<PotpomognutaPodrucjaIGradVukovar>
			<PotpomognutoPodrucje>${
        data.potpomognutaPodrucja ? "DA" : "NE"
      }</PotpomognutoPodrucje>
			<GradVukovar>${data.vukovar ? "DA" : "NE"}</GradVukovar>
			<OtociISkupine>${data.otociSkupine ? "DA" : "NE"}</OtociISkupine>
		</PotpomognutaPodrucjaIGradVukovar>
	</Zaglavlje>
	<Tijelo>
		<PrivremenaObustavaCijeluGodinu>NE</PrivremenaObustavaCijeluGodinu>
		<RazdobljePojedinacneDjelatnosti />
		<RazdobljeZajednickeDjelatnosti />
		<PodaciOPrimicima>
			<PrimiciUGotovini>${formatCurrency(gotovinski.konacanIznos)}</PrimiciUGotovini>
			<PrimiciBezGotovine>${formatCurrency(
        bezgotovinski.konacanIznos
      )}</PrimiciBezGotovine>
			<Ukupno>${formatCurrency(
        gotovinski.konacanIznos + bezgotovinski.konacanIznos
      )}</Ukupno>
		</PodaciOPrimicima>
		<GodisnjiDohodakOdPojedinacneDjelatnosti>
			<GodisnjiDohodak>0.00</GodisnjiDohodak>
			<BrojMjeseciObavljanjaDjelatnosti>0</BrojMjeseciObavljanjaDjelatnosti>
		</GodisnjiDohodakOdPojedinacneDjelatnosti>
		<GodisnjiDohodakOdZajednickeDjelatnosti>
			<GodisnjiDohodak>0.00</GodisnjiDohodak>
			<BrojMjeseciObavljanjaDjelatnosti>0</BrojMjeseciObavljanjaDjelatnosti>
		</GodisnjiDohodakOdZajednickeDjelatnosti>
		<UkupniGodisnjiDohodak>0.00</UkupniGodisnjiDohodak>
		<ObracunPorezaIPrireza>
			<ObvezaPoreza>0.00</ObvezaPoreza>
			<ProsjecnaStopa>${finalValues.prirez}</ProsjecnaStopa>
			<Prirez>${formatCurrency(data.prirez.iznos)}</Prirez>
			<UkupniPorezIPrirez>${formatCurrency(
        finalValues.porezPrirez
      )}</UkupniPorezIPrirez>
			<UmanjenjePorezaIPrireza>${formatCurrency(
        finalValues.umanjenje
      )}</UmanjenjePorezaIPrireza>
			<UkupnaObvezaPorezaIPrireza>${formatCurrency(
        finalValues.nakonUmanjenja
      )}</UkupnaObvezaPorezaIPrireza>
			<UkupniUplaceniPorezIPrirez>${formatCurrency(
        finalValues.uplaceno
      )}</UkupniUplaceniPorezIPrirez>
			<RazlikaZaUplatu>${formatCurrency(finalValues.razlika)}</RazlikaZaUplatu>
			<RazlikaZaPovrat>${formatCurrency(finalValues.mjesecno)}</RazlikaZaPovrat>
			<MjesecniPausalniPorezIPrirez>0.00</MjesecniPausalniPorezIPrirez>
		</ObracunPorezaIPrireza>
		<DostavaPrilogaPOSD2020DaNe>NE</DostavaPrilogaPOSD2020DaNe>
		<Prilozi />
	</Tijelo>
</ObrazacPOSD>`;

  const blob = new Blob([posd], { type: "text/xml" });

  saveAs(blob, "posd.xml");
  callback();
}

import dayjs from "dayjs";

export const formatDate = (date) => {
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  let newDate = new Date(date);
  const day = newDate.getDate();
  const monthIndex = newDate.getMonth();
  const year = newDate.getFullYear();
  const correctDay = day < 10 ? "0" + day : day;
  return correctDay + "." + monthNames[monthIndex] + "." + year;
};
export const formatDateTime = (date) => {
  const monthNames = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let newDate = new Date(date);
  const day = newDate.getDate();
  const monthIndex = newDate.getMonth();
  const year = newDate.getFullYear();
  const correctDay = day < 10 ? "0" + day : day;
  const hours =
    newDate.getHours() < 10 ? `0${newDate.getHours()}` : newDate.getHours();
  const minutes =
    newDate.getMinutes() < 10
      ? `0${newDate.getMinutes()}`
      : newDate.getMinutes();
  return `${correctDay}.${monthNames[monthIndex]}.${year} ${hours}:${minutes}`;
};
export const getDateArray = function (start, end, type) {
  if (type === "array") {
    const arr = [];
    const dt = new Date(start);
    while (dt <= end) {
      let date = new Date(dt);
      arr.push(date);
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  } else {
    const arr = {};
    const dt = new Date(start);
    while (dt <= end) {
      let placeholder = new Date(dt);
      arr[placeholder] = 0;
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }
};

export function getBase64(url, callback, outputFormat) {
  const img = new Image();
  img.crossOrigin = "Anonymous";
  img.onload = function () {
    let canvas = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    let dataURL;
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    dataURL = canvas.toDataURL(outputFormat);
    callback(dataURL);
    canvas = null;
  };
  img.src = url;
}
export function getQuartalNum(data, option, from, till) {
  const newArr = data.filter((item) => {
    if (item.datumPlacanja === null) {
      return false;
    } else {
      if (
        new Date(
          `${item.datumPlacanja.substr(3, 2)}.${item.datumPlacanja.substr(
            0,
            2
          )}.${item.datumPlacanja.substr(6, 5)}`
        ) >= new Date(from) &&
        new Date(
          `${item.datumPlacanja.substr(3, 2)}.${item.datumPlacanja.substr(
            0,
            2
          )}.${item.datumPlacanja.substr(6, 5)}`
        ) <= new Date(till)
      ) {
        if (
          item[option] === "" ||
          item[option] === null ||
          item[option] === undefined
        ) {
          return false;
        }
        return true;
      }
    }
    return 0;
  });
  return newArr.reduce(
    (a, b) => {
      return { placeno: Number(a.placeno) + Number(b.placeno) };
    },
    { placeno: 0 }
  );
}
export function formatCurrency(value) {
  return new Intl.NumberFormat("hr-HR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
}

export function createDate(string) {
  if (string instanceof Date) {
    return string;
  } else {
    const dateTime = string.split(" ");
    const date = dateTime[0].split(".");
    const time = dateTime[1].split(":");
    return new Date(date[2], date[1] - 1, date[0], time[0], time[1]);
  }
}
export function createOnlyDate(string) {
  if (string instanceof Date) {
    return string;
  } else {
    const date = string.split(" ")[0].split(".");
    return new Date(date[2], date[1] - 1, date[0]);
  }
}
function createStatisticsDate(string) {
  const dateTime = string.split(" ");
  const date = dateTime[0].split(".");
  return new Date(date[2], date[1] - 1, date[0], 10, 0);
}
export function getDates(date) {
  const firstDay = dayjs(date)
    .date(1)
    .hour(10)
    .minute(0)
    .second(0)
    .millisecond(0);
  const lastDay = dayjs(date)
    .date(dayjs().daysInMonth())
    .hour(10)
    .minute(0)
    .second(0)
    .millisecond(0);
  return { from: firstDay, till: lastDay };
}
export function convertKeyToDate(obj) {
  const object = {};
  for (let key in obj) {
    Object.assign(object, { [createStatisticsDate(key)]: obj[key] });
  }
  return object;
}

export const createInvoiceData = (
  initialValues,
  predlozakNum,
  selectedInvoice,
  comingFrom,
  isPredlozak
) => {
  return {
    ...initialValues,
    broj: isPredlozak
      ? "Predložak " + predlozakNum
      : comingFrom === "editInvoice"
      ? selectedInvoice.broj
      : predlozakNum,
    kupac: selectedInvoice.kupac,
    drzava: selectedInvoice.kupacDetails.drzava,
    adresa: selectedInvoice.kupacDetails.adresa,
    ulica: selectedInvoice.kupacDetails.ulica,
    kucniBroj: selectedInvoice.kupacDetails.kucniBroj,
    mjesto: selectedInvoice.kupacDetails.mjesto,
    postanskiBroj: selectedInvoice.kupacDetails.postanskiBroj,
    oib: selectedInvoice.kupacDetails.oib,
    datum:
      selectedInvoice.datumRacun === undefined
        ? createDate(selectedInvoice.datum)
        : createDate(selectedInvoice.datumRacun),
    izvrsenoOd: createOnlyDate(selectedInvoice.izvrsenoOd),
    izvrsenoDo: createOnlyDate(selectedInvoice.izvrsenoDo),
    datumDospijeca: createOnlyDate(selectedInvoice.datumDospijeca),
    proizvod: selectedInvoice.proizvod ? selectedInvoice.proizvod : "",
    status: selectedInvoice.status,
    ukupanIznos: selectedInvoice.ukupanIznos,
    konacanIznos: selectedInvoice.konacanIznos,
    placeno: selectedInvoice.placeno,
    kolicina: selectedInvoice.kolicina,
    newPrice: selectedInvoice.newPrice,
    pdv: selectedInvoice.pdv,
    pdvs: [],
    popust: selectedInvoice.popust === undefined ? "" : selectedInvoice.popust,
    razlogPopusta:
      selectedInvoice.razlogPopusta === undefined
        ? ""
        : selectedInvoice.razlogPopusta,
    proizvodi: selectedInvoice.proizvodi,
    nacinPlacanja: selectedInvoice.nacinPlacanja,
    logoBase64: selectedInvoice.logoBase64,
    kupacDetails: selectedInvoice.kupacDetails,
    comingFrom: comingFrom,
    id: selectedInvoice.id,
  };
};
export const resetInvoiceData = (
  initialValues,
  predlozakNum,
  selectedInvoice,
  comingFrom,
  isPredlozak
) => {
  /* console.log({
    initialValues,
    predlozakNum,
    selectedInvoice,
    comingFrom,
    isPredlozak,
  }); */
  return {
    ...initialValues,
    broj: isPredlozak
      ? "Predložak " + predlozakNum
      : comingFrom === "editInvoice"
      ? selectedInvoice.broj
      : predlozakNum,
    kupac: selectedInvoice.kupac,
    drzava: selectedInvoice.kupacDetails.drzava,
    adresa: selectedInvoice.kupacDetails.adresa,
    ulica: selectedInvoice.kupacDetails.ulica,
    kucniBroj: selectedInvoice.kupacDetails.kucniBroj,
    mjesto: selectedInvoice.kupacDetails.mjesto,
    postanskiBroj: selectedInvoice.kupacDetails.postanskiBroj,
    oib: selectedInvoice.kupacDetails.oib,
    datum:
      selectedInvoice.datumRacun === undefined
        ? createDate(selectedInvoice.datum)
        : createDate(selectedInvoice.datumRacun),
    izvrsenoOd: createOnlyDate(selectedInvoice.izvrsenoOd),
    izvrsenoDo: createOnlyDate(selectedInvoice.izvrsenoDo),
    datumDospijeca: createOnlyDate(selectedInvoice.datumDospijeca),
    datumPlacanja:
      comingFrom === "storno" ? selectedInvoice.datumPlacanja : null,
    operater: selectedInvoice.operater,
    proizvod: selectedInvoice.proizvod ? selectedInvoice.proizvod : "",
    status: comingFrom === "storno" ? "Plaćeno" : "Neplaćeno",
    ukupanIznos: selectedInvoice.ukupanIznos,
    konacanIznos: selectedInvoice.konacanIznos,
    placeno: comingFrom === "storno" ? selectedInvoice.placeno : 0,
    kolicina: selectedInvoice.kolicina,
    newPrice: selectedInvoice.newPrice,
    pdv: selectedInvoice.pdv,
    pdvs: [],
    placanja: comingFrom === "storno" ? selectedInvoice.placanja : [],
    popust: selectedInvoice.popust === undefined ? "" : selectedInvoice.popust,
    razlogPopusta:
      selectedInvoice.razlogPopusta === undefined
        ? ""
        : selectedInvoice.razlogPopusta,
    proizvodi: selectedInvoice.proizvodi,
    nacinPlacanja: selectedInvoice.nacinPlacanja,
    logoBase64: selectedInvoice.logoBase64,
    kupacDetails: selectedInvoice.kupacDetails,
    skladisteIzlaz: selectedInvoice.skladisteIzlaz,
    napomena: selectedInvoice.napomena,
    comingFrom: comingFrom,
    stornoRacuna: selectedInvoice.stornoRacuna
      ? selectedInvoice.stornoRacuna
      : null,
    idRacunaZaStorno: selectedInvoice.idRacunaZaStorno
      ? selectedInvoice.idRacunaZaStorno
      : null,
    fiskalizacija: comingFrom === "storno" ? true : false,
    id: selectedInvoice.id,
  };
};
export const formatISODate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};

export const createCalendarData = (invoiceData, payData) => {
  const calendarPayItems = payData
    .map((item) => {
      if (item.datumPlacanja) {
        return {
          broj: item.broj,
          datum: item.datumPlacanja && createOnlyDate(item.datumPlacanja),
          brojPlacanja: 1,
          placeno: Number(item.placeno),
          preostalo: 0,
          brojRacuna: 0,
        };
      } else return null;
    })
    .filter((item) => item !== null)
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.datum) - new Date(a.datum);
    })
    .reduce((acc, next) => {
      // acc stands for accumulator
      const lastItemIndex = acc.length - 1;
      const accHasContent = acc.length >= 1;
      if (
        accHasContent &&
        acc[lastItemIndex].datum.getTime() === next.datum.getTime()
      ) {
        acc[lastItemIndex].brojPlacanja += next.brojPlacanja;
        acc[lastItemIndex].placeno += next.placeno;
        acc[lastItemIndex].preostalo += next.preostalo;
      } else {
        // first time seeing this entry. add it!
        acc[lastItemIndex + 1] = next;
      }
      return acc;
    }, []);

  const calendarData = invoiceData
    .map((item) => {
      return {
        broj: item.broj,
        datum: createOnlyDate(item.datumRacun),
        brojRacuna: 1,
        brojPlacanja: 0,
        placeno: 0,
        preostalo: Number(item.preostalo) > 0 ? Number(item.preostalo) : 0,
      };
    })
    .reduce((acc, next) => {
      // acc stands for accumulator
      const lastItemIndex = acc.length - 1;
      const accHasContent = acc.length >= 1;

      if (
        accHasContent &&
        acc[lastItemIndex].datum.getTime() === next.datum.getTime()
      ) {
        acc[lastItemIndex].brojRacuna += next.brojRacuna;
        acc[lastItemIndex].preostalo += next.preostalo;
      } else {
        // first time seeing this entry. add it!
        acc[lastItemIndex + 1] = next;
      }
      return acc;
    }, []);
  const finalData = [...calendarData, ...calendarPayItems]
    .sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.datum) - new Date(a.datum);
    })
    .reduce((acc, next) => {
      // acc stands for accumulator
      const lastItemIndex = acc.length - 1;
      const accHasContent = acc.length >= 1;

      if (
        accHasContent &&
        acc[lastItemIndex].datum.getTime() === next.datum.getTime()
      ) {
        acc[lastItemIndex].brojRacuna += next.brojRacuna;
        acc[lastItemIndex].brojPlacanja += next.brojPlacanja;
        acc[lastItemIndex].placeno += next.placeno;
        acc[lastItemIndex].preostalo += next.preostalo;
      } else {
        // first time seeing this entry. add it!
        acc[lastItemIndex + 1] = next;
      }
      return acc;
    }, [])
    .map((item) => {
      return {
        ...item,
        day: formatISODate(item.datum),
        value: item.brojRacuna,
      };
    });
  return finalData;
};
const getTotalPrice = (items, whichValue) => {
  if (items.length !== 0) {
    if (items.length === 1) {
      return { [whichValue]: items[0][whichValue] };
    } else {
      return items.reduce(
        (accumulator, currentValue) => {
          return {
            [whichValue]: accumulator[whichValue] + currentValue[whichValue],
          };
        },
        { [whichValue]: 0 }
      );
    }
  }
};

export const storno = (selectedInvoice) => {
  const data = Object.assign({}, selectedInvoice);
  const proizvodi = data.proizvodi.map((item) => {
    const kolicina = -Math.abs(item.kolicina);
    const ukupnaCijena = kolicina * item.cijena;
    const rabat = item.rabat;
    const popust = ukupnaCijena * (item.rabat / 100);
    const newPrice = ukupnaCijena - popust;
    const pdvPrice = newPrice;
    return {
      ...item,
      kolicina,
      ukupnaCijena,
      rabat,
      popust,
      newPrice,
      pdvPrice,
    };
  });
  const ukupanIznos = getTotalPrice(proizvodi, "ukupnaCijena").ukupnaCijena;
  const konacanIznos = getTotalPrice(proizvodi, "pdvPrice").pdvPrice;
  return {
    ...data,
    proizvodi,
    ukupanIznos,
    konacanIznos,
    stornoRacuna: selectedInvoice.broj,
    idRacunaZaStorno: selectedInvoice.id,
    placeno: konacanIznos,
    preostalo: 0,
    datumPlacanja: new Date(),
    placanja: [
      {
        id: 1,
        iznos: konacanIznos,
        napomena: "storno",
        datum: new Date(),
      },
    ],
  };
};

import { useState, useMemo } from "react";
import { useStateMachine } from "little-state-machine";
import { updateClient } from "../../helpers/updateState";
import NewClient from "./NewClient";
import Table from "../../components/Table";
import { useQuery, useQueryClient } from "react-query";
import { getClients } from "../../helpers/getData";
import Button from "../../components/Button";
import { authenticationService } from "../../services/authentication";
import { handleGetRequest } from "../../helpers/handle-response";
import DeleteModal from "./DeleteModal";

const Buttons = ({ toggle }) => {
  return (
    <Button classnames="btn-dim btn-outline-primary" onClick={toggle}>
      Dodaj novog klijenta
    </Button>
  );
};

const ClientsReview = () => {
  const currentUser = authenticationService.currentUserValue;
  const queryClient = useQueryClient();
  const { data: clients, isFetching } = useQuery("clients", getClients);
  const { actions } = useStateMachine({ updateClient });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteClient, setDeleteClient] = useState({});
  const [status, setStatus] = useState({
    state: false,
    sending: false,
    message: "",
  });
  const [open, setOpen] = useState({ state: false, from: "" });
  const toggle = () => {
    actions.updateClient({
      id: null,
      ulica: "",
      broj: "",
      naziv: "",
      drzava: null,
      oib: "",
      postanskiBroj: "",
      mjesto: "",
      vrstaKlijenta: null,
      adresa: "",
      pdvid: "",
      created: "",
      zemlja: "",
      hasPdvId: false,
    });
    setOpen((open) => ({ state: !open.state, from: "newClient" }));
  };

  const handleDelete = () => {
    setStatus((status) => ({ ...status, sending: true }));

    handleGetRequest(
      `${process.env.REACT_APP_HOST_URL}/clients/delete?id=${deleteClient.id}&client=${deleteClient.naziv}`,
      currentUser.token
    )
      .then((res) => {
        console.log(res);
        queryClient.invalidateQueries("clients");
        setStatus({ state: true, sending: false, message: res });

        setDeleteModal(false);
      })
      .catch((error) => console.log(error));
  };

  const columns = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Država", accessor: "drzava" },
      { Header: "Adresa", accessor: "adresa" },
      { Header: "Mjesto", accessor: "mjesto" },
      { Header: "Poštanski broj", accessor: "postanskiBroj" },
      { Header: "OIB", accessor: "oib" },
      { Header: "PDV ID", accessor: "pdvid" },
      {
        Header: "Uredi",
        Cell: (props) => {
          return (
            <button
              className="blankBtn"
              onClick={() => {
                const clientData = {
                  ...props.row.original,
                  vrstaKlijenta: {
                    label: props.row.original.vrstaKlijenta,
                    value: props.row.original.vrstaKlijenta,
                  },
                  drzava: {
                    label: props.row.original.drzava,
                    value: props.row.original.zemlja,
                  },
                };
                actions.updateClient(clientData);
                setOpen((open) => ({
                  state: !open.state,
                  from: "editClient",
                }));
              }}
            >
              <em className="icon ni ni-edit tableIcons"></em>
            </button>
          );
        },
      },
      {
        Header: "Izbriši",
        Cell: (props) => {
          return (
            <button
              //disabled={disabled}
              onClick={() => {
                //actions.updateInvoicePreview(props.row.original);
                setDeleteClient(props.row.original);
                setDeleteModal(true);
              }}
              className="blankBtn"
            >
              <em
                className="icon ni ni-cross tableIcons"
                style={{ color: "#E85347" }}
              ></em>
            </button>
          );
        },
      },
    ],
    [actions]
  );
  return (
    <div>
      <div className="container-fluid">
        {isFetching ? (
          <div>
            <h3>Učitavanje...</h3>
          </div>
        ) : (
          <Table
            columns={columns}
            data={clients?.data}
            buttons={<Buttons toggle={toggle} />}
            name="klijenti"
            pdfName="pregledRacuna"
            exportCols={columns.map((item) => ({
              naziv: item.Header,
              adresa: item.Header,
              mjesto: item.Header,
              oib: item.Header,
            }))}
            //replace text and dataField inside invoicehr-en html/pdf template
            pdfCols={columns
              .map((item) => ({
                naziv: item.naziv,
                adresa: item.adresa,
                mjesto: item.mjesto,
                oib: item.oib,
              }))
              .map((item) => ({ text: item.Header, dataField: item.accessor }))}
            exportData={clients.data.map((item) => ({
              naziv: item.naziv,
              adresa: item.adresa,
              mjesto: item.mjesto,
              oib: String(item.oib),
            }))}
          />
        )}
      </div>
      <NewClient
        open={open.state}
        toggle={toggle}
        from={open.from}
        clients={clients}
      />
      <DeleteModal
        deleteClient={deleteClient}
        handleDelete={handleDelete}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        status={status}
        setStatus={setStatus}
      />
    </div>
  );
};
export default ClientsReview;

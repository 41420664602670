import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveCalendar } from "@nivo/calendar";
import dayjs from "dayjs";

export const Bar = ({ data, size }) => {
  console.log(size)
  return (
    <ResponsiveBar
      data={data}
      keys={["Iznos"]}
      indexBy="datum"
      layout="horizontal"
      margin={{ top: 10, right: 100, bottom: 50, left: 100 }}
      padding={data.length > 10 ? 0.3 : 0.6}
      colors={"#899AFF"}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisRight={null}
      axisBottom={{
        tickSize: 4,
        tickValues: size.width < 660 ? 2 : 8,
        tickPadding: 5,
        tickRotation: 0,
        legend: "HRK",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 3,
        tickPadding: 8,
        tickRotation: 0,
        format: (values) => {
          return dayjs(values).format("DD.MM.YYYY");
        },
        legend: "Datum",
        legendPosition: "middle",
        legendOffset: -90,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 10]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
      tooltip={(values) => {
        return (
          <>
            <h5 style={{ marginTop: 5, marginBottom: 5 }}>Primici na dan</h5>
            <p style={{ marginTop: 5, marginBottom: 5 }}>
              <span
                style={{
                  backgroundColor: values.color,
                  height: 15,
                  width: 15,
                  marginRight: 5,
                  alignSelf: "center",
                }}
              ></span>
              {dayjs(values.indexValue).format("DD.MM.YYYY")}:{" "}
              <span style={{ fontWeight: 600 }}>{values.value}</span> HRK
            </p>
          </>
        );
      }}
    />
  );
};
export const Pie = ({ data }) => (
  <ResponsivePie
    data={data}
    margin={{ top: 20, right: 80, bottom: 20, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    colors={["#2E2770", "#A73466", "#642E6C"]}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: "color" }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    sliceLabelsTextColor="#fff"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    /*  legends={[
      {
        anchor: "bottom",
        direction: "row",
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: "#999",
        symbolSize: 18,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000",
            },
          },
        ],
      },
    ]} */
  />
);

export const SecondBar = ({ data }) => {
  return (
    <ResponsiveBar
      data={data}
      keys={["Plaćanja"]}
      indexBy="datum"
      layout="horizontal"
      margin={{ top: 10, right: 100, bottom: 50, left: 100 }}
      padding={0.3}
      colors={"#1FC997"}
      borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickValues: 2,
        tickRotation: 0,
        legend: "Broj plaćanja",
        legendPosition: "middle",
        legendOffset: 40,
      }}
      axisLeft={{
        tickSize: 3,
        tickPadding: 2,
        tickRotation: 0,
        format: (values) => {
          return dayjs(values).format("DD.MM.YYYY");
        },
        legend: "Datum",
        legendPosition: "middle",
        legendOffset: -90,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 10]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={false}
      motionStiffness={90}
      motionDamping={15}
      tooltip={(values) => {
        return (
          <>
            <h5 style={{ marginTop: 5, marginBottom: 5 }}>Broj plaćanja</h5>
            <p style={{ marginTop: 5, marginBottom: 5 }}>
              <span
                style={{
                  backgroundColor: values.color,
                  height: 15,
                  width: 15,
                  marginRight: 5,
                  alignSelf: "center",
                }}
              ></span>
              {dayjs(values.indexValue).format("DD.MM.YYYY")}:{" "}
              <span style={{ fontWeight: 600 }}>{values.value}</span>
            </p>
          </>
        );
      }}
    />
  );
};

export const Calendar = ({ data /* see data tab */, size }) => {
  const date = {
    from: dayjs(new Date(new Date().getFullYear(), 0, 2)).format("YYYY-MM-DD"),
    till: dayjs(new Date(new Date().getFullYear(), 11, 31)).format(
      "YYYY-MM-DD"
    ),
  };
  
  return (
    <ResponsiveCalendar
      data={data}
      from={date.from}
      to={date.till}
      direction={size.width < 700 ? "vertical" : "horizontal"}
      tooltip={(values) => {
        const {
          brojPlacanja,
          brojRacuna,
          placeno,
          preostalo,
          datum,
        } = values.data;
        if (values.value === undefined) return null;
        return (
          <div className="p-2 card">
            <h4>Datum: {dayjs(datum).format("DD.MM.YYYY")}</h4>
            <ul>
              <li>
                Broj izdanih računa:{" "}
                <span style={{ fontWeight: 600, color: "#636363" }}>
                  {brojRacuna}
                </span>
              </li>
              <li>
                Broj plaćanja:{" "}
                <span style={{ fontWeight: 600, color: "#636363" }}>
                  {brojPlacanja}
                </span>
              </li>
              <li>
                Naplaćeni iznos:{" "}
                <span style={{ fontWeight: 600, color: "#636363" }}>
                  {placeno}
                </span>{" "}
                HRK
              </li>
              <li>
                Preostali iznos:{" "}
                <span style={{ fontWeight: 600, color: "#636363" }}>
                  {preostalo}
                </span>{" "}
                HRK
              </li>
            </ul>
          </div>
        );
      }}
      emptyColor="#eeeeee"
      colors={["#97e3d5", "#61cdbb", "#5095a6"]}
      margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
      yearSpacing={40}
      monthBorderColor="#ffffff"
      dayBorderWidth={2}
      dayBorderColor="#ffffff"
      legends={[
        {
          anchor: "bottom-right",
          direction: "row",
          translateY: 36,
          itemCount: 4,
          itemWidth: 42,
          itemHeight: 36,
          itemsSpacing: 14,
          itemDirection: "right-to-left",
        },
      ]}
    />
  );
};

import { useMemo, useCallback, useState, useEffect } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import Datepicker from "../../components/DatePicker";
import StatCard from "../../components/StatCard";
import StatBarChart from "../../components/StatBarChart";
import StatCircleChart from "../../components/StatCircleChart";
import { Calendar, Pie, SecondBar } from "../../components/Charts";
import { useTitle, useWindowSize } from "../../helpers/hooks";
import { useQuery } from "react-query";
import { getAllInvoices, getAllPaidInvoices } from "../../helpers/getData";
import { getStats } from "./functions";
import { formatCurrency, getDates } from "../../helpers";

dayjs.extend(isSameOrAfter);

const months = {
  0: "siječanj",
  1: "veljača",
  2: "ožujak",
  3: "travanj",
  4: "svibanj",
  5: "lipanj",
  6: "srpanj",
  7: "kolovoz",
  8: "rujan",
  9: "listopad",
  10: "studeni",
  11: "prosinac",
};

const StatReview = () => {
  const size = useWindowSize();
  const [month, setMonth] = useState(new Date());
  const [dates, setDates] = useState(getDates(month));
  const [timeMsg, setTimeMsg] = useState(
    "za " + months[month.getMonth()] + " " + month.getFullYear()
  );

  const { data: initialData, isFetching } = useQuery(
    "invoices",
    getAllInvoices
  );
  const { data: paymentData, isFetching: isFetchingP } = useQuery(
    "paidInvoices",
    getAllPaidInvoices
  );

  const data =
    !isFetching &&
    initialData.filter((item) => !item.broj.includes("Predložak"));
  const info =
    !isFetching &&
    !isFetchingP &&
    getStats(data, paymentData.data, month, dates, size);

  useTitle("Statistika");

  const handleFocus = (event) => event.target.select();

  return (
    <div className="container">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>Statistika</h4>
          </div>
          <hr></hr>
          <div className="row d-flex mb-3">
            <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
              Raspon
            </label>
            <div className="col-12 col-lg-2 my-0">
              <Datepicker
                format="DD.MM.YYYY"
                autoOk
                value={dates.from}
                invalidDateMessage="Format datuma nije ispravan"
                onFocus={handleFocus}
                onChange={(value) => {
                  if (value !== null) {
                    setDates({ ...dates, from: dayjs(value).hour(10) });
                    if (!isNaN(value.$d.getTime())) {
                      setTimeMsg(
                        "od " +
                          dayjs(value).format("DD.MM.YYYY") +
                          " do " +
                          dayjs(dates.till).format("DD.MM.YYYY") +
                          " "
                      );
                    }
                  }
                }}
              />
            </div>
            <label className="align-self-center m-auto mx-lg-0">-</label>
            <div className="col-12 col-lg-2">
              <Datepicker
                format="DD.MM.YYYY"
                autoOk
                value={dates.till}
                onFocus={handleFocus}
                onChange={(value) => {
                  setDates({ ...dates, till: dayjs(value).hour(10) });
                  setTimeMsg(
                    "od " +
                      dayjs(dates.from).format("DD.MM.YYYY.") +
                      " do " +
                      dayjs(value.$d).format("DD.MM.YYYY.") +
                      " "
                  );
                }}
              />
            </div>
          </div>
          <div className="row d-flex mb-5">
            <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
              Period
            </label>
            <div className="col-12 col-lg-4 my-0">
              <Datepicker
                value={month}
                autoOk
                openTo="month"
                views={["year", "month"]}
                format="MMMM YYYY"
                openOnFocus={true}
                pickerType="pure"
                onChange={(value) => {
                  setMonth(value.$d);
                  setTimeMsg(
                    "za " +
                      months[value.$d.getMonth()] +
                      " " +
                      value.$d.getFullYear()
                  );
                  setDates(getDates(value.$d));
                }}
              />
            </div>
          </div>
          <div className="row d-flex mb-5">
            <div className="col-12 col-lg mb-4 mb-lg-0">
              <StatCard
                title="Trenutno neplaćeni iznos:"
                description={formatCurrency(info ? info.unpaid?.preostalo : 0)}
              ></StatCard>
            </div>
            <div className="col-12 col-lg mb-4 mb-lg-0">
              <StatCard
                title={`Primici ${timeMsg}:`}
                description={formatCurrency(
                  info ? info.totalMonth?.placeno : 0
                )}
              ></StatCard>
            </div>
            <div className="col-12 col-lg">
              <StatCard
                title="Primici za godinu 2021:"
                description={formatCurrency(info ? info.totalYear?.placeno : 0)}
              ></StatCard>
            </div>
          </div>
          <div className="row d-flex mb-3">
            <div className="col-12 col-lg-7 mb-4 mb-lg-0" style={{ height: 700 }}>
              {info?.reducedLineDataArray && (
                <StatBarChart
                  size={size}
                  timeMsg={timeMsg}
                  data={info.reducedLineDataArray}
                />
              )}
            </div>
            <div className="col-12 col-lg-5">
              <div className="col-sm-12 px-0">
                {info?.pieData && <StatCircleChart data={info.pieData} />}
              </div>
              <div className="col-sm-12 px-0 mt-4">
                <div className="card card-preview">
                  <div className="card-inner">
                    <div className="card-head text-center">
                      <h6 className="title">Plaćanja {timeMsg}</h6>
                    </div>
                    <div className="h-100">
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <div style={{ height: 250 }}>
                            <div style={{ height: 250 }}>
                              {info?.reducedlineInvoiceNumArray && (
                                <SecondBar
                                  data={Object.keys(
                                    info.reducedlineInvoiceNumArray
                                  )
                                    .map((key) => {
                                      return {
                                        datum: new Date(key).toString(),
                                        Plaćanja:
                                          info.reducedlineInvoiceNumArray[
                                            new Date(key)
                                          ],
                                      };
                                    })
                                    .filter((item) => item["Plaćanja"] !== 0)
                                    .reverse()}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {size.width > 700 && (
              <div className="col-12 mt-4">
                <div className="card card-preview">
                  <div className="card-inner">
                    <div className="card-head text-center">
                      <h6 className="title">Godišnji pregled</h6>
                    </div>
                    <div className="h-100">
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <div style={{ height: 250 }}>
                            <div style={{ height: 250 }}>
                              {info?.calendarData && (
                                <Calendar
                                  data={info.calendarData}
                                  size={size}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatReview;

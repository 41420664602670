export const options = [
  {
    label: "Bez prireza",
    value: 0,
  },
  {
    label: "Andrijaševci",
    value: 8,
  },
  {
    label: "Antunovac",
    value: 10,
  },
  {
    label: "Babina Greda",
    value: 5,
  },
  {
    label: "Bale",
    value: 1,
  },
  {
    label: "Barban",
    value: 5,
  },
  {
    label: "Bedenica",
    value: 3,
  },
  {
    label: "Bednja",
    value: 10,
  },
  {
    label: "Beli Manastir",
    value: 5,
  },
  {
    label: "Belica",
    value: 0,
  },
  {
    label: "Belišće",
    value: 7,
  },
  {
    label: "Benkovac",
    value: 5,
  },
  {
    label: "Beretinec",
    value: 7,
  },
  {
    label: "Bilice",
    value: 10,
  },
  {
    label: "Bilje",
    value: 5,
  },
  {
    label: "Biograd na Moru",
    value: 12,
  },
  {
    label: "Biskupija",
    value: 4,
  },
  {
    label: "Bistra",
    value: 10,
  },
  {
    label: "Bizovac",
    value: 3,
  },
  {
    label: "Bjelovar",
    value: 0,
  },
  {
    label: "Blato",
    value: 10,
  },
  {
    label: "Bol",
    value: 10,
  },
  {
    label: "Borovo",
    value: 6,
  },
  {
    label: "Bošnjaci",
    value: 5,
  },
  {
    label: "Brckovljani",
    value: 3,
  },
  {
    label: "Brdovec",
    value: 10,
  },
  {
    label: "Brela",
    value: 5,
  },
  {
    label: "Brestovac",
    value: 5,
  },
  {
    label: "Breznica",
    value: 10,
  },
  {
    label: "Breznički Hum",
    value: 3,
  },
  {
    label: "Brinje",
    value: 10,
  },
  {
    label: "Brodski Stupnik",
    value: 10,
  },
  {
    label: "Buje",
    value: 6,
  },
  {
    label: "Bukovlje",
    value: 10,
  },
  {
    label: "Buzet",
    value: 6,
  },
  {
    label: "Cerna",
    value: 5,
  },
  {
    label: "Cernik",
    value: 10,
  },
  {
    label: "Cerovlje",
    value: 2,
  },
  {
    label: "Cestica",
    value: 10,
  },
  {
    label: "Cista Provo",
    value: 3,
  },
  {
    label: "Civljane",
    value: 5,
  },
  {
    label: "Crikvenica",
    value: 10,
  },
  {
    label: "Čabar",
    value: 5,
  },
  {
    label: "Čaglin",
    value: 5,
  },
  {
    label: "Čakovec",
    value: 10,
  },
  {
    label: "Čazma",
    value: 10,
  },
  {
    label: "Čeminac",
    value: 10,
  },
  {
    label: "Čepin",
    value: 10,
  },
  {
    label: "Darda",
    value: 5,
  },
  {
    label: "Daruvar",
    value: 10,
  },
  {
    label: "Davor",
    value: 10,
  },
  {
    label: "Delnice",
    value: 5,
  },
  {
    label: "Dežanovac",
    value: 5,
  },
  {
    label: "Dicmo",
    value: 8,
  },
  {
    label: "Donja Stubica",
    value: 10,
  },
  {
    label: "Donja Voća",
    value: 10,
  },
  {
    label: "Donji Andrijevci",
    value: 8,
  },
  {
    label: "Donji Kukuruzari",
    value: 6,
  },
  {
    label: "Donji Lapac",
    value: 5,
  },
  {
    label: "Donji Martijanec",
    value: 5,
  },
  {
    label: "Donji Miholjac",
    value: 5,
  },
  {
    label: "Dragalić",
    value: 10,
  },
  {
    label: "Draž",
    value: 2,
  },
  {
    label: "Drenovci",
    value: 5,
  },
  {
    label: "Drniš",
    value: 5,
  },
  {
    label: "Dubrava",
    value: 6,
  },
  {
    label: "Dubravica",
    value: 10,
  },
  {
    label: "Dubrovačko primorje",
    value: 10,
  },
  {
    label: "Dubrovnik",
    value: 10,
  },
  {
    label: "Duga Resa",
    value: 10,
  },
  {
    label: "Dugo Selo",
    value: 9,
  },
  {
    label: "Dugopolje",
    value: 0,
  },
  {
    label: "Dvor",
    value: 5,
  },
  {
    label: "Đakovo",
    value: 10,
  },
  {
    label: "Đurđenovac",
    value: 10,
  },
  {
    label: "Erdut",
    value: 5,
  },
  {
    label: "Ernestinovo",
    value: 10,
  },
  {
    label: "Ervenik",
    value: 3,
  },
  {
    label: "Farkaševac",
    value: 3,
  },
  {
    label: "Fažana",
    value: 3,
  },
  {
    label: "Feričanci",
    value: 10,
  },
  {
    label: "Fužine",
    value: 5,
  },
  {
    label: "Garčin",
    value: 10,
  },
  {
    label: "Garešnica",
    value: 10,
  },
  {
    label: "Gornji Bogićevci",
    value: 5,
  },
  {
    label: "Gornji Kneginec",
    value: 10,
  },
  {
    label: "Gospić",
    value: 10,
  },
  {
    label: "Gračac",
    value: 7,
  },
  {
    label: "Gračišće",
    value: 5,
  },
  {
    label: "Gradec",
    value: 5,
  },
  {
    label: "Grubišno Polje",
    value: 5,
  },
  {
    label: "Gunja",
    value: 5,
  },
  {
    label: "Hrašćina",
    value: 5,
  },
  {
    label: "Hrvace",
    value: 10,
  },
  {
    label: "Hrvatska Dubica",
    value: 3,
  },
  {
    label: "Hrvatska Kostajnica",
    value: 6,
  },
  {
    label: "Ilok",
    value: 0,
  },
  {
    label: "Imotski",
    value: 6,
  },
  {
    label: "Ivanec",
    value: 10,
  },
  {
    label: "Ivanić grad",
    value: 6,
  },
  {
    label: "Ivankovo",
    value: 10,
  },
  {
    label: "Jagodnjak",
    value: 3,
  },
  {
    label: "Jakovlje",
    value: 5,
  },
  {
    label: "Jakšić",
    value: 5,
  },
  {
    label: "Jastrebarsko",
    value: 9,
  },
  {
    label: "Jelsa",
    value: 10,
  },
  {
    label: "Kamanje",
    value: 10,
  },
  {
    label: "Kanfanar",
    value: 3,
  },
  {
    label: "Kapela",
    value: 8,
  },
  {
    label: "Kaptol",
    value: 10,
  },
  {
    label: "Karlovac",
    value: 12,
  },
  {
    label: "Karojba",
    value: 5,
  },
  {
    label: "Kaštela",
    value: 12,
  },
  {
    label: "Kaštelir-Labinci",
    value: 5,
  },
  {
    label: "Kijevo",
    value: 5,
  },
  {
    label: "Kistanje",
    value: 3,
  },
  {
    label: "Klanjec",
    value: 12,
  },
  {
    label: "Klenovnik",
    value: 7,
  },
  {
    label: "Klinča Sela",
    value: 10,
  },
  {
    label: "Klis",
    value: 10,
  },
  {
    label: "Kloštar Ivanić",
    value: 2,
  },
  {
    label: "Kloštar Podravski",
    value: 5,
  },
  {
    label: "Kneževi Vinogradi",
    value: 4,
  },
  {
    label: "Knin",
    value: 10,
  },
  {
    label: "Komiža",
    value: 5,
  },
  {
    label: "Konavle",
    value: 10,
  },
  {
    label: "Končanica",
    value: 5,
  },
  {
    label: "Konjšćina",
    value: 10,
  },
  {
    label: "Korčula",
    value: 6,
  },
  {
    label: "Kraljevica",
    value: 5,
  },
  {
    label: "Krašić",
    value: 6,
  },
  {
    label: "Kravarsko",
    value: 5,
  },
  {
    label: "Križ",
    value: 2,
  },
  {
    label: "Križevci",
    value: 10,
  },
  {
    label: "Krnjak",
    value: 8,
  },
  {
    label: "Kula Norinska",
    value: 10,
  },
  {
    label: "Kutina",
    value: 12,
  },
  {
    label: "Labin",
    value: 6,
  },
  {
    label: "Lanišće",
    value: 5,
  },
  {
    label: "Lasinja",
    value: 10,
  },
  {
    label: "Lastovo",
    value: 10,
  },
  {
    label: "Lepoglava",
    value: 8,
  },
  {
    label: "Lipik",
    value: 10,
  },
  {
    label: "Lipovljani",
    value: 6,
  },
  {
    label: "Lišane Ostrovičke",
    value: 6,
  },
  {
    label: "Ližnjan",
    value: 5,
  },
  {
    label: "Lokvičići",
    value: 7,
  },
  {
    label: "Lovas",
    value: 5,
  },
  {
    label: "Lovinac",
    value: 8,
  },
  {
    label: "Ludbreg",
    value: 12,
  },
  {
    label: "Luka",
    value: 5,
  },
  {
    label: "Lukač",
    value: 8,
  },
  {
    label: "Lumbarda",
    value: 5,
  },
  {
    label: "Lupoglav",
    value: 1,
  },
  {
    label: "Ljubešćica",
    value: 10,
  },
  {
    label: "Magadenovac",
    value: 2,
  },
  {
    label: "Majur",
    value: 5,
  },
  {
    label: "Makarska",
    value: 10,
  },
  {
    label: "Mali Bukovec",
    value: 10,
  },
  {
    label: "Marčana",
    value: 5,
  },
  {
    label: "Marija Gorica",
    value: 10,
  },
  {
    label: "Marijanci",
    value: 5,
  },
  {
    label: "Markušica",
    value: 5,
  },
  {
    label: "Maruševec",
    value: 5,
  },
  {
    label: "Matulji",
    value: 10,
  },
  {
    label: "Medulin",
    value: 5,
  },
  {
    label: "Metković",
    value: 8,
  },
  {
    label: "Milna",
    value: 3,
  },
  {
    label: "Mljet",
    value: 10,
  },
  {
    label: "Motovun",
    value: 0,
  },
  {
    label: "Mrkopalj",
    value: 7,
  },
  {
    label: "Muć",
    value: 7,
  },
  {
    label: "Murter-Kornati",
    value: 8,
  },
  {
    label: "Našice",
    value: 8,
  },
  {
    label: "Negoslavci",
    value: 5,
  },
  {
    label: "Nova Gradiška",
    value: 12,
  },
  {
    label: "Nova Kapela",
    value: 10,
  },
  {
    label: "Novi Marof",
    value: 10,
  },
  {
    label: "Novi Vinodolski",
    value: 7,
  },
  {
    label: "Novska",
    value: 10,
  },
  {
    label: "Nuštar",
    value: 6,
  },
  {
    label: "Ogulin",
    value: 5,
  },
  {
    label: "Okučani",
    value: 10,
  },
  {
    label: "Omiš",
    value: 8,
  },
  {
    label: "Opatija",
    value: 7.5,
  },
  {
    label: "Opuzen",
    value: 10,
  },
  {
    label: "Orle",
    value: 5,
  },
  {
    label: "Osijek",
    value: 13,
  },
  {
    label: "Otočac",
    value: 9,
  },
  {
    label: "Otok (Sinj)",
    value: 5,
  },
  {
    label: "Otok (Vinkovci)",
    value: 10,
  },
  {
    label: "Ozalj",
    value: 10,
  },
  {
    label: "Pakrac",
    value: 10,
  },
  {
    label: "Pazin",
    value: 9,
  },
  {
    label: "Perušić",
    value: 8,
  },
  {
    label: "Petlovac",
    value: 5,
  },
  {
    label: "Petrijanec",
    value: 5,
  },
  {
    label: "Petrijevci",
    value: 5,
  },
  {
    label: "Petrinja",
    value: 10,
  },
  {
    label: "Pićan",
    value: 2,
  },
  {
    label: "Pirovac",
    value: 6,
  },
  {
    label: "Pisarovina",
    value: 6,
  },
  {
    label: "Pleternica",
    value: 10,
  },
  {
    label: "Plitvička jezera",
    value: 8,
  },
  {
    label: "Ploče",
    value: 10,
  },
  {
    label: "Podbablje",
    value: 6,
  },
  {
    label: "Podcrkavlje",
    value: 5,
  },
  {
    label: "Podgora",
    value: 10,
  },
  {
    label: "Podgorač",
    value: 5,
  },
  {
    label: "Podstrana",
    value: 10,
  },
  {
    label: "Pokupsko",
    value: 5,
  },
  {
    label: "Polača",
    value: 10,
  },
  {
    label: "Popovac",
    value: 3,
  },
  {
    label: "Popovača",
    value: 6,
  },
  {
    label: "Požega",
    value: 7,
  },
  {
    label: "Pregrada",
    value: 10,
  },
  {
    label: "Preseka",
    value: 5,
  },
  {
    label: "Primošten",
    value: 10,
  },
  {
    label: "Proložac",
    value: 5,
  },
  {
    label: "Promina",
    value: 0,
  },
  {
    label: "Pučišća",
    value: 5,
  },
  {
    label: "Pula",
    value: 12,
  },
  {
    label: "Pušća",
    value: 10,
  },
  {
    label: "Radoboj",
    value: 8,
  },
  {
    label: "Rakovec",
    value: 3,
  },
  {
    label: "Rakovica",
    value: 8,
  },
  {
    label: "Raša",
    value: 6,
  },
  {
    label: "Ravna Gora",
    value: 7.5,
  },
  {
    label: "Rešetari",
    value: 10,
  },
  {
    label: "Ribnik",
    value: 5,
  },
  {
    label: "Rijeka",
    value: 15,
  },
  {
    label: "Rovinj",
    value: 6,
  },
  {
    label: "Rugvica",
    value: 0,
  },
  {
    label: "Runovići",
    value: 6,
  },
  {
    label: "Ružić",
    value: 5,
  },
  {
    label: "Senj",
    value: 10,
  },
  {
    label: "Sibinj",
    value: 10,
  },
  {
    label: "Sinj",
    value: 0,
  },
  {
    label: "Sisak",
    value: 10,
  },
  {
    label: "Skradin",
    value: 5,
  },
  {
    label: "Slatina",
    value: 6,
  },
  {
    label: "Slavonski Brod",
    value: 12,
  },
  {
    label: "Slunj",
    value: 5,
  },
  {
    label: "Solin",
    value: 10,
  },
  {
    label: "Split",
    value: 15,
  },
  {
    label: "Sračinec",
    value: 5,
  },
  {
    label: "Stara Gradiška",
    value: 5,
  },
  {
    label: "Stari Grad",
    value: 10,
  },
  {
    label: "Stari Mikanovci",
    value: 10,
  },
  {
    label: "Staro Petrovo Selo",
    value: 10,
  },
  {
    label: "Stupnik",
    value: 6,
  },
  {
    label: "Supetar",
    value: 10,
  },
  {
    label: "Sutivan",
    value: 10,
  },
  {
    label: "Sveta Nedelja (Labin)",
    value: 6,
  },
  {
    label: "Sveta Nedelja (Samobor)",
    value: 0,
  },
  {
    label: "Sveti Đurđ",
    value: 5,
  },
  {
    label: "Sveti Filip i Jakov",
    value: 10,
  },
  {
    label: "Sveti Ilija",
    value: 10,
  },
  {
    label: "Sveti Ivan Zelina",
    value: 12,
  },
  {
    label: "Sveti Lovreč",
    value: 5,
  },
  {
    label: "Sveti Petar u Šumi",
    value: 7,
  },
  {
    label: "Svetvinčenat",
    value: 5,
  },
  {
    label: "Šibenik",
    value: 10,
  },
  {
    label: "Škabrnja",
    value: 10,
  },
  {
    label: "Špišić Bukovica",
    value: 9,
  },
  {
    label: "Štefanje",
    value: 0,
  },
  {
    label: "Štitar",
    value: 5,
  },
  {
    label: "Tinjan",
    value: 5,
  },
  {
    label: "Tisno",
    value: 9,
  },
  {
    label: "Tordinci",
    value: 5,
  },
  {
    label: "Tribunj",
    value: 6,
  },
  {
    label: "Trnovec Bartolovečki",
    value: 7,
  },
  {
    label: "Trogir",
    value: 8,
  },
  {
    label: "Trpanj",
    value: 10,
  },
  {
    label: "Tučepi",
    value: 10,
  },
  {
    label: "Udbina",
    value: 5,
  },
  {
    label: "Umag",
    value: 6,
  },
  {
    label: "Unešić",
    value: 5,
  },
  {
    label: "Valpovo",
    value: 0,
  },
  {
    label: "Varaždin",
    value: 10,
  },
  {
    label: "Varaždinske Toplice",
    value: 10,
  },
  {
    label: "Vela Luka",
    value: 9,
  },
  {
    label: "Velika Gorica",
    value: 12,
  },
  {
    label: "Velika Kopanica",
    value: 7,
  },
  {
    label: "Veliki Bukovec",
    value: 5,
  },
  {
    label: "Veliko Trgovišće",
    value: 7.5,
  },
  {
    label: "Vidovec",
    value: 10,
  },
  {
    label: "Vinica",
    value: 5,
  },
  {
    label: "Vinkovci",
    value: 13,
  },
  {
    label: "Virovitica",
    value: 10,
  },
  {
    label: "Vis",
    value: 3,
  },
  {
    label: "Visoko",
    value: 10,
  },
  {
    label: "Višnjan",
    value: 5,
  },
  {
    label: "Vižinada",
    value: 3,
  },
  {
    label: "Vladislavci",
    value: 10,
  },
  {
    label: "Vodice",
    value: 6,
  },
  {
    label: "Vodnjan",
    value: 7.5,
  },
  {
    label: "Vođinci",
    value: 10,
  },
  {
    label: "Vojnić",
    value: 7.5,
  },
  {
    label: "Vrbanja",
    value: 5,
  },
  {
    label: "Vrbje",
    value: 3,
  },
  {
    label: "Vrbovec",
    value: 12,
  },
  {
    label: "Vrbovsko",
    value: 10,
  },
  {
    label: "Vrgorac",
    value: 10,
  },
  {
    label: "Vrhovine",
    value: 5,
  },
  {
    label: "Vrlika",
    value: 7,
  },
  {
    label: "Zadar",
    value: 12,
  },
  {
    label: "Zadvarje",
    value: 1,
  },
  {
    label: "Zagreb",
    value: 18,
  },
  {
    label: "Zagvozd",
    value: 6,
  },
  {
    label: "Zaprešić",
    value: 12,
  },
  {
    label: "Zlatar",
    value: 10,
  },
  {
    label: "Zlatar Bistrica",
    value: 5,
  },
  {
    label: "Zmijavci",
    value: 8,
  },
  {
    label: "Žakanje",
    value: 0,
  },
  {
    label: "Žminj",
    value: 5,
  },
  {
    label: "Žumberak",
    value: 3,
  },
  {
    label: "Župa Dubrovačka",
    value: 10,
  },
  {
    label: "Županja",
    value: 12,
  },
].map((item) => ({
  label: `${item.label} (${item.value}%)`,
  value: `${item.label} - ${item.value}`,
  iznos: item.value,
}));

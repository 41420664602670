import { Link } from "react-router-dom";
import Card from "../../components/Card";
import { getWelcomeMessage } from "../../helpers/context";
import { useTitle } from "../../helpers/hooks";
import {
  InvoiceList,
  NewInvoice,
  NewPayment,
  Statistic,
  KPR,
  PaymentReview,
  Storage,
  FormIco,
} from "./icons";

const Main = () => {
  useTitle(getWelcomeMessage());
  return (
    <div className="container">
      <h4>Financijsko poslovanje</h4>
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <Link to="/novi-racun">
            <Card
              title="Izdavanje računa"
              description="Neki opis"
              icon={<NewInvoice />}
            />
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/pregled-racuna">
            <Card
              title="Pregled računa"
              description="Neki opis"
              icon={<InvoiceList />}
            />
          </Link>
        </div>

        <div className="col-12 col-md-4 my-2">
          <Link to="/unos-placanja">
            <Card
              title="Unos plaćanja"
              description="Opis plaćanja"
              icon={<NewPayment />}
            />
          </Link>
        </div>
      </div>
      <h4>Robno poslovanje</h4>
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <Link to="/ulazni-racun">
            <Card
              title="Zaprimanje robe"
              description="Opis ulaznog računa"
              icon={<NewInvoice />}
            />
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/skladiste">
            <Card
              title="Skladište"
              description="Pregled skladišta"
              icon={<Storage />}
            ></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/inventura">
            <Card
              title="Inventura"
              description="Pregled skladišta"
              icon={<Storage />}
            ></Card>
          </Link>
        </div>
      </div>
      <h4>Statistika</h4>
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <Link to="/statistika">
            <Card
              title="Statistika"
              description="Opis statistika"
              icon={<Statistic />}
            />
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/pregled-placanja">
            <Card
              title="Pregled plaćanja"
              description="Opis plaćanja"
              icon={<PaymentReview />}
            />
          </Link>
        </div>
      </div>

      <h4>Porezna izvješća</h4>
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          <Link to="/kpr">
            <Card title="KPR" description="Opis KPR" icon={<KPR />}></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/po-sd">
            <Card
              title="PO-SD"
              description="Opis PO-SD"
              icon={<FormIco />}
            ></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/tz">
            <Card title="TZ" description="Opis TZ" icon={<FormIco />}></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/eporezna">
            <Card title="ePorezna" description="Opis ePorezna" icon={<FormIco />}></Card>
          </Link>
        </div>
        {/*
        <div className="col-12 col-md-4 my-2">
          <Link to="/">
            <Card title="PDV" description="Opis PDV" icon={<KPR />}></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/">
            <Card title="ZP" description="Opis ZP" icon={<KPR />}></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/">
            <Card title="PDV-S" description="Opis PDV-S" icon={<KPR />}></Card>
          </Link>
        </div>
        <div className="col-12 col-md-4 my-2">
          <Link to="/">
            <Card title="URA" description="Opis URA" icon={<KPR />}></Card>
          </Link>
        </div>
        /////////////////////////////////////////////
        Kartice koje će nam kasnije trebati         /
        /////////////////////////////////////////////
        */}
      </div>
    </div>
  );
};

export default Main;

import Button from "./Button";
import logo from "../assets/images/logo1beta.png";
import Greet from "./GreetMessage";
import { useHistory, useLocation } from "react-router";
import { authenticationService } from "../services/authentication";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../helpers/hooks";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const shouldDisable = (url) => {
  if (
    url.includes("login") ||
    url.includes("register") ||
    url.includes("forgotpassword")
  ) {
    return true;
  } else return false;
};

const Header = () => {
  const currentUser = authenticationService.currentUserValue;
  const currentTheme = localStorage.getItem("theme") === "true" ? true : false;
  let history = useHistory();
  const location = useLocation();
  const disabled = shouldDisable(location.pathname);
  const [darkTheme, setDarkTheme] = useState(currentTheme);
  const [isDataFilled, setIsDataFilled] = useState(true);
  const [timeLeft, setTimeLeft] = useState(
    dayjs(currentUser?.exp * 1000).diff(dayjs(), "minutes")
  );

  useEffect(() => {
    setTimeLeft(dayjs(currentUser?.exp * 1000).diff(dayjs(), "seconds"));
  });

  useEffect(() => {
    const hasFalse = [];
    if (currentUser) {
      Object.keys(currentUser).map((key, index) => {
        if (
          key !== "pdvid" &&
          key !== "podrucniUred" &&
          key !== "poreznaKod" &&
          key !== "ispostava" &&
          String(currentUser[key]).trim().length < 1
        ) {
          hasFalse.push(false);
        }
        return null;
      });
    }
    hasFalse.includes(false) ? setIsDataFilled(false) : setIsDataFilled(true);
  }, [currentUser]);

  useEffect(() => {
    if (currentTheme) {
      document.body.classList =
        "nk-body bg-lighter npc-general has-sidebar no-touch nk-nio-theme dark-mode";
    } else if (!currentTheme) {
      document.body.classList =
        "nk-body bg-lighter npc-general has-sidebar no-touch nk-nio-theme ";
    }
  }, [currentTheme]);

  const switchTheme = () => {
    localStorage.setItem("theme", !darkTheme);
    setDarkTheme(!darkTheme);
  };

  const logout = () => {
    authenticationService.logout(() => {
      history.push("/");
    });
  };

  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((open) => !open);
  };
  const ref = useRef();
  useOnClickOutside(ref, (e) => setOpen(false));

  return !disabled && currentUser ? (
    <header
      className="container-fluid"
      style={{ display: disabled ? "none" : "block" }}
    >
      <div className="d-none">
        <div id="barcode">
          <canvas />
        </div>
      </div>
      <div className="row tempclr">
        <div className="container">
          <div className="d-flex py-3">
            <div>
              <Link to="/">
                <img
                  src={logo}
                  className="logo-img logo-img-lg"
                  alt="logo"
                ></img>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="container d-flex justify-content-between py-3">
          <Greet name={currentUser && currentUser.ime}></Greet>
          <div className="d-flex justify-content-end align-self-center">
            <div className="mx-2 d-none d-md-flex">
              <Link to="/">
                <Button classnames="btn btn-lg btn-primary btn-pausal">
                  Početna
                </Button>
              </Link>
            </div>

            <ul className="nk-quick-nav" ref={ref}>
              <li className="dropdown user-dropdown">
                <button
                  href="#"
                  className="blankBtn dropdown-toggle"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={toggle}
                >
                  <div className="user-toggle">
                    <div className="user-avatar sm bg-primary">
                      <em className="icon ni ni-user-alt"></em>
                    </div>
                    <div className="user-info d-block">
                      <div className="user-name dropdown-indicator">
                        {currentUser.ime.length > 0
                          ? currentUser.ime
                          : "Ime Prezime"}{" "}
                        {currentUser.prezime}{" "}
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  className={`${
                    open ? "d-block" : "d-none"
                  } dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 border-primary`}
                  id="DropToggle"
                >
                  <div className="dropdown-inner user-card-wrap bg-lighter d-block">
                    <div className="user-card">
                      <div className="user-avatar bg-primary">
                        <span>
                          {currentUser?.ime?.charAt(0)}
                          {currentUser?.prezime?.charAt(0)}
                        </span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">
                          {currentUser.ime.length > 0
                            ? currentUser.ime
                            : "Ime Prezime"}{" "}
                          {currentUser.prezime}{" "}
                        </span>
                        <span className="sub-text">{currentUser.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link to="/postavke" onClick={toggle}>
                          <em className="icon ni ni-setting-alt"></em>
                          <span>Postavke</span>
                        </Link>
                      </li>

                      <li>
                        <a
                          style={{ cursor: "pointer", userSelect: "none" }}
                          onClick={switchTheme}
                          className={`blankBtn dark-switch ${
                            darkTheme ? "active" : ""
                          }`}
                        >
                          <em className="icon ni ni-moon"></em>
                          <span>Tamni način</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <button
                          className="blankBtn link"
                          onClick={() => {
                            logout();
                            toggle();
                          }}
                        >
                          <em className="icon ni ni-signout"></em>
                          <span>Odjava</span>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {!isDataFilled && (
          <div className="container">
            <div
              className="alert alert-icon alert-danger"
              role="alert"
              style={{ fontSize: 18 }}
            >
              <em className="icon ni ni-alert-circle"></em>
              Prije korištenja aplikacije popunite sve osobne podatke u
              postavkama.{" "}
              <strong>
                <Link
                  style={{ color: "#c3463c", textDecoration: "underline" }}
                  to="/postavke"
                >
                  Kliknite ovdje
                </Link>
              </strong>
            </div>
          </div>
        )}
        {timeLeft < 6 ? (
          <div
            className="position-fixed"
            style={{ bottom: 25, right: 25, zIndex: 99999 }}
          >
            <div className="alert alert-pro alert-warning">
              <div className="alert-text">
                <h6>
                  Sesija istječe za {timeLeft}{" "}
                  {timeLeft < 5 && timeLeft > 1
                    ? "minute"
                    : timeLeft === 1
                    ? "minutu"
                    : "minuta"}
                </h6>{" "}
                <p>Ponovno se prijavi</p>{" "}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </header>
  ) : null;
};
export default Header;

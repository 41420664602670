import { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { authenticationService } from "../../services/authentication";
import { useStateMachine } from "little-state-machine";
import { updateInvoicePayment } from "../../helpers/updateState";
import { invoicePayment } from "../../initialState";
import Table from "../../components/Table";
import { formatCurrency } from "../../helpers";
import { getAllPaidInvoices } from "../../helpers/getData";
import { handlePostRequest } from "../../helpers/handle-response";
import DeleteModal from "./DeleteModal";
import { useTitle } from "../../helpers/hooks";

const status = {
  Plaćeno: "success",
  Neplaćeno: "danger",
  Djelomično: "warning",
};

const PaymentReview = () => {
  const currentUser = authenticationService.currentUserValue;
  let history = useHistory();
  const { data: invoices, isFetching } = useQuery(
    "paidInvoices",
    getAllPaidInvoices
  );
  const queryClient = useQueryClient();
  const { state, actions } = useStateMachine({ updateInvoicePayment });
  const [deleteModal, setDeleteModal] = useState(false);
  useTitle("Pregled plaćanja");
  const handleDelete = (data) => {
    handlePostRequest(
      `${process.env.REACT_APP_HOST_URL}/invoices/update`,
      [data],
      currentUser.token
    )
      .then((res) => {
        if (res.status) {
          queryClient.invalidateQueries("paidInvoices");
          queryClient.invalidateQueries("invoices");
          setDeleteModal(false);
          actions.updateInvoicePayment(invoicePayment);
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Broj računa",
        accessor: "broj",
        Cell: (props) => {
          return <p className={`tb-odr-id blankBtn utrzak`}>{props.value}</p>;
        },
      },
      {
        Header: "Naziv kupca",
        accessor: "kupac",
      },
      {
        Header: "Datum računa",
        accessor: "datumRacun",
      },
      {
        Header: "Datum naplate",
        accessor: "datumPlacanja",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => {
          return (
            <span className="tb-odr-status">
              <span className={`badge badge-dot badge-${status[props.value]}`}>
                {props.value}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Ukupan iznos",
        accessor: "konacanIznos",
        Cell: (props) => {
          return (
            <span className="tb-odr-total w-100 text-right">
              <span className="amount">{formatCurrency(props.value)} HRK</span>
            </span>
          );
        },
      },

      {
        Header: "Plaćeno",
        accessor: "placeno",
        Cell: (props) => {
          return (
            <span className="tb-odr-total w-100 text-right">
              <span className="amount">{formatCurrency(props.value)} HRK</span>
            </span>
          );
        },
      },

      {
        Header: "Preostalo",
        accessor: "preostalo",
        Cell: (props) => {
          return (
            <span className="tb-odr-total w-100 text-right">
              <span className="amount">{formatCurrency(props.value)} HRK</span>
            </span>
          );
        },
      },
      { Header: "Napomena", accessor: "napomena" },
      {
        Header: "Uredi",
        accessor: "edit",
        disableSortBy: true,
        noPrint: true,
        Cell: (props) => {
          const handleEdit = () => {
            const placanja = props.row.original.placanja.filter(
              (item) => item.id !== props.row.original.payID
            );
            const totalPaid = placanja.reduce(
              (a, b) => {
                return { iznos: a.iznos + b.iznos };
              },
              { iznos: 0 }
            );
            const {
              kupacDetails,
              datumRacun,
              ukupanIznos,
              preostalo,
              proizvodi,
              placeno,
              napomena,
            } = props.row.original;
            const data = {
              invoice: {
                value: props.row.original,
                label: `${props.row.original.broj} - ${props.row.original.datumRacun} - ${props.row.original.kupac}`,
              },
              placanja: placanja,
              klijent: kupacDetails.naziv,
              datumRacun,
              ukupanIznos: Number(ukupanIznos),
              placeno: totalPaid.iznos,
              preostalo: Number(placeno) + Number(preostalo),
              napomena,
              proizvodi,
            };
            actions.updateInvoicePayment(data);
            history.push("/unos-placanja");
          };
          return (
            <button
              disabled={props.row.original.broj.includes("P-")}
              className="blankBtn"
              onClick={handleEdit}
            >
              <em className="icon ni ni-edit tableIcons"></em>
            </button>
          );
        },
      },
      {
        Header: "Izbriši",
        accessor: "delete",
        disableSortBy: true,
        noPrint: true,
        Cell: (props) => {
          const handleDelete = () => {
            const placanja = props.row.original.placanja.filter(
              (item) => item.id !== props.row.original.payID
            );
            const totalPaid = placanja.reduce(
              (a, b) => {
                return { iznos: a.iznos + b.iznos };
              },
              { iznos: 0 }
            );

            const {
              kupacDetails,
              datumRacun,
              ukupanIznos,
              preostalo,
              proizvodi,
              placeno,
              napomena,
            } = props.row.original;
            const data = {
              invoice: props.row.original,
              id: props.row.original.id,
              placanja: placanja,
              klijent: kupacDetails.naziv,
              datumRacun,
              ukupanIznos: Number(ukupanIznos),
              placeno: totalPaid.iznos,
              preostalo: Number(placeno) + Number(preostalo),
              napomena,
              proizvodi,
              placenoSad: 0,
              thisPayment: props.row.values.placeno,
            };
            actions.updateInvoicePayment(data);
            setDeleteModal(true);
          };
          return (
            <button
              onClick={handleDelete}
              disabled={props.row.original.broj.includes("P-")}
              className="blankBtn"
            >
              <em
                className="icon ni ni-cross tableIcons"
                style={{ color: "#E85347" }}
              ></em>
            </button>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="container-fluid">
      {isFetching ? (
        <div>
          <h3>Učitavanje...</h3>
        </div>
      ) : (
        <Table
          columns={columns}
          data={invoices.data}
          name="pregled_placanja"
          pdfName="pregledRacuna"
          exportCols={columns.slice(0, columns.length - 2)}
          //replace text and dataField inside invoicehr-en html/pdf template
          pdfCols={columns
            .slice(0, columns.length - 2)
            .map((item) => ({ text: item.Header, dataField: item.accessor }))}
          exportData={invoices.data.map((item) => ({
            broj: `"${item.broj}"`,
            kupac: item.kupac,
            datumRacun: item.datumRacun,
            datumPlacanja: item.datumPlacanja,
            status: item.status,
            konacanIznos: formatCurrency(item.konacanIznos),
            placeno: formatCurrency(item.placeno),
            preostalo: formatCurrency(item.preostalo),
            napomena: item.napomena,
          }))}
        />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          toggle={setDeleteModal}
          state={state}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default PaymentReview;

import i18n from "i18next";
//import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    hr: {
      translations: {
        racun: "Račun",
        broj: "Broj",
        mjesto: "Mjesto",
        referenca: "Referenca",
        datum: "Datum",
        izvrseno: "Izvršeno",
        dospijece: "Dospijeće",
        idBroj: "OIB",
        vrstaRobe: "Vrsta robe odnosno usluga",
        kolicina: "Količina",
        cijena: "Cijena",
        popust: "Popust",
        vrijednostHRK: "Vrijednost",
        ukupno: "UKUPNO",
        ukupnoHRK: "UKUPNO",
        zaPlacanje: "Za plaćanje",
        nacinPlacanja: "Način plaćanja",
        operater: "Operater",
      },
    },
    en: {
      translations: {
        racun: "Invoice",
        broj: "Number",
        mjesto: "City",
        referenca: "Reference",
        datum: "Date",
        izvrseno: "Completed",
        dospijece: "Due",
        idBroj: "Company ID #",
        vrstaRobe: "Goods/services",
        kolicina: "Quantity",
        cijena: "Price",
        popust: "Discount",
        vrijednostHRK: "Value",
        ukupno: "TOTAL",
        ukupnoHRK: "TOTAL",
        zaPlacanje: "To be paid",
        nacinPlacanja: "Method of payment",
        operater: "Operator",
      },
    },
  },
  lng: "hr",
  fallbackLng: "hr",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

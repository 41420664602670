import { useMemo, useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import { isString } from "lodash";
import { Modal } from "@material-ui/core";
import { useStateMachine } from "little-state-machine";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import { getAllUnpaidInvoices } from "../../helpers/getData";
import { updateInvoicePayment, resetState } from "../../helpers/updateState";
import Input from "../../components/Input";
import InputValue from "../../components/InputValue";
import Datepicker from "../../components/DatePicker";
import Button from "../../components/Button";
import CustomSelect from "../../components/Select";
import Table from "../../components/Table";
import { invoicePayment } from "../../initialState";
import { useTitle } from "../../helpers/hooks";

const getID = (array) => {
  let max = 0;

  array.forEach((item) => {
    if (item.id >= max) {
      max = item.id + 1;
    } else {
      max = 1;
    }
  });
  return max;
};

const NewPayment = () => {
  const currentUser = authenticationService.currentUserValue;
  let history = useHistory();
  const queryClient = useQueryClient();
  const [status, setStatus] = useState({
    state: false,
    message: "",
    error: "",
    loading: false,
  });
  useTitle("Unos naplate");
  const { actions, state } = useStateMachine({
    updateInvoicePayment,
    resetState,
  });
  const cols = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Šifra", accessor: "sifra" },
      { Header: "JM", accessor: "unit" },
      { Header: "Cijena", accessor: "cijena" },
      { Header: "Količina", accessor: "kolicina" },
      { Header: "Ukupna cijena", accessor: "ukupnaCijena" },
      { Header: "Popust", accessor: "rabat" },
      { Header: "Konačna cijena", accessor: "pdvPrice" },
    ],
    []
  );

  const {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    reset,
  } = useForm({
    defaultValues: state.invoicePayment,
  });

  useEffect(() => {
    register({ name: "proizvodi" });
    register({ name: "placanja" });
  }, [register]);
  useEffect(() => {
    return () => {
      actions.updateInvoicePayment(invoicePayment);
    };
  }, []);

  const { data: invoices } = useQuery("unpaidInvoices", getAllUnpaidInvoices);

  const submit = (data) => {
    const date = dayjs(data.datumRacun, "DD.MM.YYYY HH:mm");
    const placeno = isString(data.placeno)
      ? Number(data.placeno)
      : data.placeno;
    const ukupanIznos = isString(data.ukupanIznos)
      ? Number(data.ukupanIznos)
      : data.ukupanIznos;
    const placenoSad = isString(data.placenoSad)
      ? Number(data.placenoSad.replaceAll(".", "").replace(",", "."))
      : data.placenoSad;

    const totalPaid = Number(placeno) + Number(placenoSad);

    if (Number(placeno) > Number(ukupanIznos)) {
      setError("placenoSad", {
        type: "manual",
        message: "Unijeli ste iznos veći od ukupnog",
      });
    } else if (totalPaid > ukupanIznos) {
      setError("placenoSad", {
        type: "manual",
        message: "Konačan iznos je veći od ukupnog",
      });
    } else if (
      dayjs(data.datumPlacanja).hour(23).minute(59).second(59).isBefore(date)
    ) {
      setError("datumPlacanja", {
        type: "manual",
        message: "Datum plaćanja je manji od datuma izdavanja računa",
      });
    } else {
      setStatus({ state: true, message: "Slanje", error: "", loading: true });

      const preostalo = ukupanIznos - placenoSad - placeno;
      const placanje = {
        id:
          data.placanja && data.placanja.length > 0
            ? getID(data.placanja)
            : data.placanja
            ? data.placanja.length
            : 0,
        iznos: placenoSad,
        napomena: data.napomena,
        datum: data.datumPlacanja,
      };
      const placanja = data.placanja ? [...data.placanja] : [];
      placanja.push(placanje);
      const invoiceData = {
        ...data,
        broj: data.invoice.value.broj,
        id: data.invoice.value.id,
        placanja,
        placeno,
        preostalo,
        placenoSad: Number(
          data.placenoSad.replaceAll(".", "").replace(",", ".")
        ),
      };
      handlePostRequest(
        `${process.env.REACT_APP_HOST_URL}/invoices/update`,
        [invoiceData],
        currentUser.token
      )
        .then((res) => {
          if (res.data.error) {
            setStatus({
              state: false,
              error: res.data.error,
              message: "",
              loading: false,
            });
          } else {
            setStatus({
              state: true,
              loading: false,
              message: res.data.status,
              error: "",
            });
            reset(invoicePayment);
            actions.updateInvoicePayment(invoicePayment);
            queryClient.invalidateQueries("unpaidInvoices");
            queryClient.invalidateQueries("invoices");
            setTimeout(() => {
              setStatus({
                state: false,
                loading: false,
                message: "",
                error: "",
              });
            }, 2000);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <div className="container">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>Predložak računa</h4>
          </div>
          <hr></hr>
          <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Odabir neplaćenog računa</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  name="invoice"
                  defaultValue={null}
                  control={control}
                  id="invoice"
                  rules={{ required: "Izaberi neplaćeni račun" }}
                  render={(props) => {
                    return (
                      <CustomSelect
                        {...props}
                        onChange={(e) => {
                          setValue("klijent", e.value.kupacDetails.naziv);
                          setValue("datumRacun", e.value.datumRacun);
                          setValue("ukupanIznos", Number(e.value.ukupanIznos));
                          setValue("preostalo", Number(e.value.preostalo));
                          setValue("placeno", Number(e.value.placeno));
                          setValue("proizvodi", e.value.proizvodi);
                          setValue("placanja", e.value.placanja);

                          props.onChange(e);
                        }}
                        placeholder="Izaberi neplaćeni račun"
                        menuPlacement="auto"
                        options={invoices?.options}
                        noOptionsMessage={() => "Račun ne postoji"}
                      />
                    );
                  }}
                />
                {errors.invoice && (
                  <span className="error">{errors.invoice.message}</span>
                )}
              </div>
            </div>
            <div className={`${watch("invoice") ? "d-block" : "d-none"}`}>
              <div className="form-group row d-flex mb-3">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                  <label className="my-0">Klijent</label>
                </div>
                <div className="form-group col-12 col-lg-4">
                  <Input
                    name="klijent"
                    ref={register()}
                    readOnly
                    classnames="form-control-lg"
                  ></Input>
                </div>
              </div>
              <div className="form-group row d-flex mb-3">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                  <label className="my-0">Datum</label>
                </div>
                <div className="form-group col-12 col-lg-4">
                  <Input
                    name="datumRacun"
                    ref={register()}
                    readOnly
                    classnames="form-control-lg"
                  ></Input>
                </div>
              </div>
              <div className="form-group row d-flex mb-3">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                  <label className="my-0">Ukupan iznos</label>
                </div>
                <div className="form-group col-12 col-lg-4">
                  <Controller
                    name="ukupanIznos"
                    control={control}
                    render={({ onChange, onBlur, value, name }) => {
                      return (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Ukupan iznos"
                          readOnly
                          customInput={InputValue}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="form-group row d-flex mb-3">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                  <label className="my-0">Plaćeno</label>
                </div>
                <div className="form-group col-12 col-lg-4">
                  <Controller
                    name="placeno"
                    control={control}
                    render={({ onChange, onBlur, value, name }) => {
                      return (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Plaćeno"
                          readOnly
                          customInput={InputValue}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className="form-group row d-flex mb-3">
                <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                  <label className="my-0">Preostalo</label>
                </div>
                <div className="form-group col-12 col-lg-4">
                  <Controller
                    name="preostalo"
                    control={control}
                    render={({ onChange, onBlur, value, name }) => {
                      return (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Preostalo"
                          readOnly
                          customInput={InputValue}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Datum naplate</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  defaultValue={state.invoicePayment.datumPlacanja}
                  name="datumPlacanja"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {errors.datumPlacanja && (
                  <span className="error">{errors.datumPlacanja.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Unos plaćanja</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  rules={{ required: "Unesi iznos za naplatu" }}
                  name="placenoSad"
                  defaultValue={state.invoicePayment.placenoSad}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Iznos"
                      customInput={InputValue}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                {errors.placenoSad && (
                  <span className="error">{errors.placenoSad.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Napomena</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Input
                  name="napomena"
                  ref={register()}
                  classnames="form-control-lg"
                  placeholder="Napomena"
                ></Input>
              </div>
            </div>
            <hr></hr>
            {status.state && status.message.length > 0 && (
              <div style={{ maxWidth: 800 }} className="mx-auto">
                <div
                  className="alert alert-dismissible alert-icon alert-fill alert-success"
                  role="alert"
                >
                  <em className="icon ni ni-check-circle"></em>
                  Plaćanje za račun{" "}
                  {status.message.length > 0 && status.message} spremljeno.
                  <button
                    className="close"
                    data-dismiss="alert"
                    type="button"
                    onClick={() => setStatus({ state: false, message: "" })}
                  ></button>
                </div>
              </div>
            )}
            {getValues().proizvodi && getValues().proizvodi.length > 0 ? (
              <>
                <Table
                  disableButtons={true}
                  columns={cols}
                  data={getValues().proizvodi ? getValues().proizvodi : []}
                />
                <hr></hr>
              </>
            ) : null}

            <div className="col-12 mx-auto my-2 mx-lg-0 my-lg-0 d-flex justify-content-md-end">
              <Button
                disabled={status.state}
                type="submit"
                classnames="btn-dim btn-outline-primary form-control-lg mx-2 "
              >
                Spremi plaćanje
              </Button>
              <Button
                disabled={status.state}
                type="button"
                onClick={() => history.push("/pregled-placanja")}
                classnames="btn-dim btn-outline-danger form-control-lg ml-2"
              >
                Izađi bez spremanja
              </Button>
            </div>
          </form>
        </div>
      </div>
      <Modal open={status.loading}>
        <div style={{ marginTop: "20%" }}>
          <div class="d-flex justify-content-center">
            <div
              class="spinner-border text-light"
              role="status"
              style={{ width: "5em", height: "5em" }}
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <h1 className="text-center align-self-center text-light">
            Slanje...
          </h1>
        </div>
      </Modal>
    </div>
  );
};

export default NewPayment;

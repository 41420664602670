import { forwardRef } from "react";
import Select from "react-select";

const colourStyles = {
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    color: "#3c4d62",
    boxShadow: "0",
    ":hover": {
      borderColor: "#6576ff",
      outline: 0,
      boxShadow: "0 0 0 3px rgb(101 118 255 / 10%)",
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: ".4065rem 1rem",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      borderRadius: 5,
      marginTop: 2,
      marginBottom: 2,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#ebeef2"
        : isFocused
        ? "#ebeef2"
        : null,
      color: isDisabled ? "#a9b5ca" : isSelected ? "#526484" : "#526484",
      cursor: isDisabled ? "not-allowed" : "default",
      ":active": {
        ...styles[":active"],
        color: "#fff",
        backgroundColor: !isDisabled && (isSelected ? "#5d7390" : "#4b5c73"),
      },
    };
  },
  menu: (styles) => ({ ...styles, zIndex: 999 }),
  menuList: (styles) => ({
    ...styles,
    padding: 6,
    zIndex: 999,
  }),
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

const CustomSelect = forwardRef((props, ref) => {
  return (
    <Select
      {...props}
      ref={ref}
      classNamePrefix={"custom-select"}
      styles={colourStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
});

export default CustomSelect;

import { useState } from "react";
import { authenticationService } from "../../services/authentication";
import EditCompany from "./EditCompany";
import Button from "../../components/Button";

const SettingsCompany = (props) => {
  const currentUser = authenticationService.currentUserValue;
  const [open, setOpen] = useState({ state: false, to: "" });
  const toggle = (to) => {
    setOpen((open) => ({ state: !open.state, to: to }));
  };

 

  return (
    <div className="w-100">
      <div className="card-inner card-inner-lg ">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">Porezne Informacije</h4>
            </div>
            <div className="nk-block-head-content align-self-start d-lg-none">
              <a className="toggle btn btn-icon btn-trigger mt-n1" onClick={props.setOpen} >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
            </div>
            <div className="nk-block-head-content align-self-center d-none d-lg-block">
              <Button
                classnames="btn-lg btn-dim btn-outline-primary"
                onClick={toggle}
              >
                Uredi
              </Button>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Basics</h6>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("oib")}
            >
              <div className="data-col">
                <span className="data-label">OIB:</span>
                <span className="data-value">{currentUser.oib}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("hasPdvId")}
            >
              <div className="data-col">
                <span className="data-label">PDV ID:</span>
                <span className="data-value">{currentUser.pdvid}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("djelatnost")}
            >
              <div className="data-col">
                <span className="data-label">Naziv djelatnosti:</span>
                <span className="data-value">
                  {currentUser.nazivDjelatnosti}
                </span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("iban")}
            >
              <div className="data-col">
                <span className="data-label">IBAN:</span>
                <span className="data-value">{currentUser.iban}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditCompany open={open} toggle={toggle} />
    </div>
  );
};

export default SettingsCompany;

import "./main.css";

const Card = ({ title, description, link, icon }) => {
  return (
    <div className="card card-bordered card-full ">
      <div className="nk-wg1">
        <div className="nk-wg1-block">
          <div className="nk-wg1-img">{icon}</div>
          <div className="nk-wg1-text">
            <h5 className="title">{title}</h5>
            <p>{description}</p>
          </div>
        </div>
        <div className="nk-wg1-action">
          <div className="link cardHover">
            <span>Ulaz</span>
            <em className="icon ni ni-chevron-right"></em>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;

import { useState } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useAsyncDebounce,
  useGlobalFilter,
} from "react-table";
import {
  saveAsExcel,
  saveAsCsv,
  exportPdf,
  exportKprPdf,
} from "../helpers/functions";
import Input from "./Input";
import CustomSelect from "./Select";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="d-flex">
      <label
        htmlFor="search"
        className="d-flex mb-0 align-center justify-content-end mr-2"
        style={{ fontSize: 16 }}
      >
        Pretraži:
      </label>
      <div className="">
        <Input
          id="search"
          classnames="form-control-lg"
          value={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} ${count < 5 ? "stavke" : "stavki"}...`}
          style={{
            fontSize: "1.1rem",
          }}
        />
      </div>
    </div>
  );
}
export default function DataTable({
  data,
  onOpen,
  setPickedValues,
  disableButtons,
  columns,
  buttons,
  exportCols,
  exportData,
  name,
  pdfName,
  pdfCols,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [downloading, setDownloading] = useState(false);

  return (
    <>
      <div className="row my-2" id="tableUtils">
        <div
          className={`col ${
            disableButtons ? "d-none" : "d-flex"
          } justify-content-center justify-content-lg-start`}
        >
          <div className="btn-group btn-group-lg" id="customButtons" aria-label="Basic example">
            <button
              type="button"
              className="btn btn-dim btn-outline-pausal btnCustom borderCustom1"
              onClick={() => {
                saveAsExcel(exportCols, exportData, name);
              }}
            >
              Excel
            </button>
            <button
              type="button"
              className="btn btn-dim btn-outline-pausal btnCustom borderCustom2"
              onClick={() => {
                saveAsCsv(exportCols, exportData, name);
              }}
            >
              CSV
            </button>
            <button
              disabled={downloading}
              type="button"
              className="btn btn-dim btn-outline-pausal btnCustom borderCustom3"
              onClick={() => {
                if (name === "knjiga_prometa") {
                  exportKprPdf(data, setDownloading);
                } else {
                  exportPdf(pdfCols, exportData, name, pdfName, setDownloading);
                }
              }}
            >
              {downloading ? (
                <>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </>
              ) : (
                "PDF"
              )}
            </button>
            <button
              type="button"
              className="btn btn-dim btn-outline-pausal btnCustom borderCustom4"
              onClick={() => window.print()}
            >
              Print
            </button>
            {buttons ? buttons : null}
          </div>
        </div>

        <div className="col-12 col-lg d-flex mt-2 mt-lg-0 justify-content-center justify-content-lg-end align-items-center">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div className="card card-bordered card-preview table-responsive">
        <table {...getTableProps()} className="table table-orders">
          <thead className="tb-odr-head thead-dark">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tb-odr-item"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`tb-odr-info text-center ${
                      column.noPrint ? "noPrint" : ""
                    }`}
                  >
                    {column.render("Header")}
                    <span className="pl-1">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <>
                            <em className="icon ni ni-arrow-long-down"></em>
                          </>
                        ) : (
                          <>
                            <em className="icon ni ni-arrow-long-up"></em>
                          </>
                        )
                      ) : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="tb-odr-body">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="tb-odr-item">
                  {row.cells.map((cell) => (
                    <td
                      className={`tb-odr-info text-center ${
                        cell.column.noPrint ? "noPrint" : ""
                      }`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div ml={2} className="pagination row my-2">
        <div className="col">
          <div style={{ width: 200 }}>
            <label className="form-label" htmlFor="selectPage">
              Prikaži:
            </label>

            <CustomSelect
              id="selectPage"
              w="150px"
              menuPlacement="auto"
              value={{ value: pageSize, label: pageSize }}
              options={[
                { value: 10, label: 10 },
                { value: 20, label: 20 },
                { value: 30, label: 30 },
                { value: 40, label: 40 },
                { value: 50, label: 50 },
              ]}
              onChange={(e) => {
                setPageSize(Number(e.value));
              }}
            />
          </div>
        </div>
        <div className="col mb-4 mt-4">
          <div className="d-flex justify-content-center justify-content-lg-end">
            <button
              className="btn btn-dim btn-outline-pausal"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              Prethodna
            </button>{" "}
            <div className="d-flex align-center justify-content-center mx-4 flex-wrap">
              Stranica
              <strong className="ml-1">
                {pageIndex + 1} od {pageOptions.length}
              </strong>{" "}
            </div>
            <button
              className="btn btn-dim btn-outline-pausal"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              Sljedeća
            </button>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useStateMachine } from "little-state-machine";
import { updateAccountInfo } from "../../helpers/updateState";
import { authenticationService } from "../../services/authentication";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import {
  checkPDVID,
  getCodes,
  updateProfile,
  decodeHTMLEntities,
  getBank,
} from "../../helpers/functions";

const EditCompany = ({ open, toggle }) => {
  const currentUser = authenticationService.currentUserValue;
  const { actions, state } = useStateMachine({ updateAccountInfo });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    trigger,
    clearErrors,
    errors,
  } = useForm({
    defaultValues: state.accountInfo,
  });
  const [vatStatus, setVatStatus] = useState({ state: null, message: "" });

  const [status, setStatus] = useState({
    state: false,
    message: "",
    loading: false,
  });
  const submit = (data) => {
    setStatus({ state: true, message: "Slanje...", loading: true });
    console.log(data);
    const tempData = new FormData();
    data.id = currentUser.id;
    for (var value in data) {
      if (value === "email" && currentUser.email !== "") {
      } else {
        tempData.append(value, data[value]);
      }
    }
    //create function to update and get profile data
    updateProfile(
      tempData,
      (successRes) => {
        console.log(successRes);
        actions.updateAccountInfo({ ...successRes.data, logo: null });
        setStatus({
          state: true,
          message: "Podaci spremljeni.",
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (errorRes) => {
        console.log(errorRes);
      }
    );
  };
  return (
    <Modal open={open.state} toggle={toggle} title="Uredi porezne informacije">
      <form onSubmit={handleSubmit(submit)} className="form-validate is-alter">
        <div className="form-group">
          <label className="form-label" htmlFor="full-name">
            OIB:
          </label>
          <div className="form-control-wrap">
            <Controller
              autoFocus={open.to === "oib"}
              id="oib"
              control={control}
              name="oib"
              defaultValue={state.accountInfo.oib}
              rules={{
                required: "Unesi OIB",
                minLength: {
                  value: 11,
                  message: "OIB mora sadržavati najmanje 11 znakova",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  autoFocus={open.to === "oib"}
                  getInputRef={ref}
                  maxLength={11}
                  name={name}
                  allowLeadingZeros
                  decimalSeparator={false}
                  type="text"
                  classnames="form-control-lg"
                  placeholder="OIB"
                  customInput={Input}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            {errors.oib && <span className="error">{errors.oib.message}</span>}
          </div>
        </div>
        <div className="form-group form-control-wrap mt-2">
          <div className="custom-control custom-control-xs custom-checkbox">
            <input
              type="checkbox"
              className="form-control custom-control-input position-relative"
              id="hasPdvId"
              autoFocus={open.to === "hasPdvId"}
              name="hasPdvId"
              onChange={async (e) => {
                setValue("hasPdvId", false);
                const values = getValues();
                const validation = await trigger(["oib"]);
                if (validation) {
                  setVatStatus({ state: "checking", message: "Provjera..." });
                  checkPDVID("HR", values.oib, (res) => {
                    if (res.isValid === "true") {
                      setVatStatus({
                        state: true,
                        message: "Tvrtka ima PDV ID",
                      });
                      setValue("pdvid", res.code + res.vat);
                      setValue(
                        "hasPdvId",
                        res.isValid === "true" ? true : false
                      );
                    } else {
                      setVatStatus({
                        state: false,
                        message: "Tvrtka nema PDV ID",
                      });
                      setValue("pdvid", "");
                      setTimeout(() => {
                        setVatStatus({
                          state: false,
                          message: "",
                        });
                      }, 1500);
                    }
                  });
                }
              }}
              ref={register()}
            />

            <label className="custom-control-label" htmlFor="hasPdvId">
              {vatStatus.state === "checking"
                ? vatStatus.message
                : "Tvrtka ima PDV ID? (VIES Provjera)"}
            </label>
          </div>
        </div>
        {vatStatus.state !== "checking" && vatStatus.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto mb-2">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-${
                vatStatus.state ? "success" : "gray"
              }`}
              role="alert"
            >
              <em
                className={`icon ni ni-${
                  vatStatus.state ? "check" : "alert"
                }-circle`}
              ></em>
              {vatStatus.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setVatStatus({ state: null, message: "" })}
              ></button>
            </div>
          </div>
        )}
        <div className="form-group">
          <label className="form-label" htmlFor="email-address">
            PDV ID
          </label>
          <div className="form-control-wrap">
            <Input
              name="pdvid"
              readOnly
              ref={register()}
              placeholder="Unos preko VIES provjere"
              classnames="form-control-lg"
            ></Input>
          </div>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="nkd">
            Šifra djelatnosti:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="djelatnost"
              control={control}
              name="djelatnost"
              defaultValue={state.clientData.djelatnost}
              rules={{
                required: "Unesi šifru djelatnosti",
                minLength: {
                  value: 5,
                  message:
                    "Šifra djelatnosti mora sadržavati najviše 4 znakova",
                },
                validate: {
                  validNum: (value) => {
                    if (!getValues("nazivDjelatnosti")) {
                      return "Šifra djelatnosti ne postoji";
                    }
                  },
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  autoFocus={open.to === "djelatnost"}
                  mask="_"
                  getInputRef={ref}
                  format="##.##"
                  name={name}
                  allowLeadingZeros
                  type="text"
                  classnames="form-control-lg"
                  placeholder="Šifra djelanosti"
                  customInput={Input}
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    const nkd = getCodes(e.target.value);
                    if (nkd) {
                      clearErrors(["djelatnost", "nazivDjelatnosti"]);
                      setValue(
                        "nazivDjelatnosti",
                        decodeHTMLEntities(nkd ? nkd?.name : "")
                      );
                    } else {
                      setError("djelatnost", {
                        type: "manual",
                        message: "Šifra djelatnosti ne postoji",
                        shouldFocus: true,
                      });
                      setValue("nazivDjelatnosti", "");
                    }
                    console.log(nkd);
                  }}
                  value={value}
                />
              )}
            />
            {errors.djelatnost && (
              <span className="error">{errors.djelatnost.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Djelatnost:
          </label>
          <div className="form-control-wrap">
            <Input
              ref={register({
                required: "Unesi šifru djelatnosti iznad",
              })}
              readOnly
              name="nazivDjelatnosti"
              placeholder="Naziv djelatnosti"
              classnames="form-control-lg"
            />
            {errors.nazivDjelatnosti && (
              <span className="error">{errors.nazivDjelatnosti.message}</span>
            )}
          </div>
        </div>

        <div className="form-group mb-4">
          <label className="form-label" htmlFor="phone-no">
            IBAN:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="iban"
              control={control}
              name="iban"
              defaultValue={state.accountInfo.iban}
              rules={{
                required: "Unesi IBAN",
                maxLength: {
                  value: 21,
                  message: "IBAN mora sadržavati najviše 21 znak",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <Input
                  onChange={(e) => {
                    onChange(e);
                    const bank = getBank(e.target.value);
                    setValue("banka", bank);
                  }}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  ref={ref}
                  autoFocus={open.to === "iban"}
                  classnames={`form-control-lg ${errors.iban && "error"}`}
                />
              )}
            />
            {errors.iban && (
              <span className="error">{errors.iban.message}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Banka:
          </label>
          <div className="form-control-wrap">
            <Input
              ref={register({
                required: "Unesi IBAN iznad ili unesi naziv banke",
              })}
              name="banka"
              placeholder="Naziv banke"
              classnames="form-control-lg"
            />
            {errors.banka && (
              <span className="error">{errors.banka.message}</span>
            )}
          </div>
        </div>

        <hr></hr>
        {status.state && status.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-${
                status.loading ? "info" : "success"
              }`}
              role="alert"
            >
              <em
                className={`icon ni ni-${
                  status.loading ? "alert" : "check"
                }-circle`}
              ></em>
              {status.message.length > 0 && status.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setStatus({ state: false, message: "" })}
              ></button>
            </div>
          </div>
        )}
        <div className="form-group mt-4">
          <div className="form-control-wrap row">
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-start">
              <Button
                disabled={status.state}
                type="submit"
                classnames="btn-dim btn-outline-primary btn-lg"
              >
                Spremi izmjene
              </Button>
            </div>
            <div
              type="button"
              onClick={toggle}
              className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-end"
            >
              <Button
                disabled={status.state}
                classnames="btn-dim btn-outline-danger btn-lg"
              >
                Odustani
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditCompany;

import { forwardRef } from "react";

const InputValue = forwardRef((props, ref) => {
  return (
    <div className="form-control-wrap w-100">
      <div className="form-text-hint">
        <span className="overline-title">
          {props.valuta ? props.valuta : "HRK"}
        </span>
      </div>
      <input
        {...props}
        ref={ref}
        className={`form-control ${props.classnames}`}
      ></input>
    </div>
  );
});

export default InputValue;

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useStateMachine } from "little-state-machine";
import { authenticationService } from "../../services/authentication";
import { updateAccountInfo } from "../../helpers/updateState";
import { getZip, updateProfile } from "../../helpers/functions";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";

const EditUser = ({ open, toggle }) => {
  const currentUser = authenticationService.currentUserValue;
  const { actions, state } = useStateMachine({ updateAccountInfo });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    errors,
  } = useForm({
    defaultValues: { ...state.accountInfo, logo: "" },
  });
  const [status, setStatus] = useState({
    state: false,
    message: "",
    loading: false,
  });

  const submit = (data) => {
    setStatus({ state: true, message: "Slanje...", loading: true });
    console.log(data);
    const tempData = new FormData();
    data.id = currentUser.id;
    data.vlasnik = `${data.ime} ${data.prezime}`;
    data.adresa = `${data.ulica} ${data.kucniBroj}`;
    data.cert = [];
    if (data.logo.length < 1) {
      delete data.logo;
    } else {
      data.logo = data.logo[0];
    }
    for (var value in data) {
      if (value === "email" && currentUser.email !== "") {
      } else {
        tempData.append(value, data[value]);
      }
    }
    //create function to update and get profile data
    updateProfile(
      tempData,
      (successRes) => {
        console.log(successRes);
        actions.updateAccountInfo({ ...successRes.data, logo: null });
        setStatus({
          state: true,
          message: "Podaci spremljeni.",
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (errorRes) => {
        console.log(errorRes);
      }
    );
  };
  console.log(
    open,
    watch("logo"),
    getValues(),
    state.accountInfo,
    typeof getValues().logo === typeof {}
  );

  return (
    <Modal
      open={open.state}
      toggle={toggle}
      title="Uredi korisničke informacije"
    >
      <form onSubmit={handleSubmit(submit)} className="form-validate is-alter">
        <div className="form-group">
          <label className="form-label" htmlFor="full-name">
            Naziv obrta:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "naziv"}
              classnames={`form-control-lg ${errors.naziv && "error"}`}
              name="naziv"
              ref={register({ required: "Upiši naziv" })}
            />
            {errors.naziv && (
              <span className="error">{errors.naziv.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="email-address">
            Ime vlasnika:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "ime"}
              classnames={`form-control-lg ${errors.ime && "error"}`}
              name="ime"
              ref={register({ required: "Upiši ime" })}
            />
            {errors.ime && <span className="error">{errors.ime.message}</span>}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Prezime vlasnika:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "prezime"}
              classnames={`form-control-lg ${errors.prezime && "error"}`}
              name="prezime"
              ref={register({ required: "Upiši prezime" })}
            />
            {errors.prezime && (
              <span className="error">{errors.prezime.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Ulica:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "ulica"}
              classnames={`form-control-lg ${errors.ulica && "error"}`}
              name="ulica"
              ref={register({ required: "Upiši ulicu" })}
            />
            {errors.ulica && (
              <span className="error">{errors.ulica.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Kućni broj:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "kucniBroj"}
              classnames={`form-control-lg ${errors.kucniBroj && "error"}`}
              name="kucniBroj"
              ref={register({ required: "Upiši kućni broj" })}
            />
            {errors.kucniBroj && (
              <span className="error">{errors.kucniBroj.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Mjesto:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "mjesto"}
              classnames={`form-control-lg ${errors.mjesto && "error"}`}
              name="mjesto"
              onChange={(e) => {
                setValue("mjesto", e.target.value);
                const zip = getZip(e.target.value);
                setValue("postanskiBroj", zip !== undefined ? zip.brojPu : "");
              }}
              ref={register({ required: "Upiši mjesto" })}
            />
            {errors.mjesto && (
              <span className="error">{errors.mjesto.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Poštanski broj:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="postanskiBroj"
              control={control}
              name="postanskiBroj"
              defaultValue={state.accountInfo.postanskiBroj}
              rules={{
                required: "Unesi poštanski broj",
                minLength: {
                  value: 5,
                  message: "Poštanski broj mora sadržavati 5 znakova",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  autoFocus={open.to === "postanskiBroj"}
                  getInputRef={ref}
                  maxLength={5}
                  name={name}
                  allowLeadingZeros
                  decimalSeparator={false}
                  type="text"
                  classnames="form-control-lg"
                  placeholder="Poštanski broj"
                  customInput={Input}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            {errors.postanskiBroj && (
              <span className="error">{errors.postanskiBroj.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            E-Mail (za fakturu):
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "emailRacun"}
              classnames={`form-control-lg ${errors.emailRacun && "error"}`}
              name="emailRacun"
              ref={register({
                required: "Upiši email za fakturu",
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Email nije ispravan",
                },
              })}
            />
            {errors.emailRacun && (
              <span className="error">{errors.emailRacun.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Telefon:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "telefon"}
              classnames={`form-control-lg ${errors.telefon && "error"}`}
              name="telefon"
              ref={register({ required: "Upiši telefon" })}
            />
            {errors.telefon && (
              <span className="error">{errors.telefon.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="customFileLabel">
            Logo{" "}
          </label>
          <div className="form-control-wrap">
            <div className="custom-file">
              <input
                autoFocus={open.to === "logo"}
                name="logo"
                type="file"
                ref={register()}
                className="custom-file-input form-control-lg"
                id="logoPicture"
                accept="image/x-png,image/gif,image/jpeg"
              ></input>
              <label className="custom-file-label" htmlFor="customFile">
                {watch("logo") &&
                typeof getValues().logo === typeof {} &&
                getValues().logo[0]
                  ? getValues().logo[0].name
                  : "Odaberi datoteku"}
              </label>
            </div>
          </div>
        </div>

        <hr></hr>
        {status.state && status.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-${
                status.loading ? "info" : "success"
              }`}
              role="alert"
            >
              <em
                className={`icon ni ni-${
                  status.loading ? "alert" : "check"
                }-circle`}
              ></em>
              {status.message.length > 0 && status.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setStatus({ state: false, message: "" })}
              ></button>
            </div>
          </div>
        )}
        <div className="form-group mt-4">
          <div className="form-control-wrap row">
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-start">
              <Button
                disabled={status.state}
                type="submit"
                classnames="btn-dim btn-outline-primary btn-lg"
              >
                Spremi izmjene
              </Button>
            </div>
            <div
              type="button"
              className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-end"
              onClick={toggle}
            >
              <Button
                disabled={status.state}
                classnames="btn-dim btn-outline-danger btn-lg"
              >
                Odustani
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditUser;

import dayjs from "dayjs";

const add = (proizvod, data, iznos, times, history, index) => {
  const date = dayjs().second(index).$d;
  const obj = {};
  Object.assign(obj, data[proizvod.id]);
  const { values, suma, stanje } = obj;
  Object.assign(suma, { [iznos]: 0 });
  //Object.assign(stanje, { [`${date.getTime()} - ${iznos}`]: 0 });
  suma.total += Number(iznos) * Number(times);
  suma[iznos] = Number(iznos) * Number(times);

  const ukupno = times;
  stanje.push({ [iznos]: Number(times), ukupno });
  /* FIFO 
  PUSH TO ARRAY
  ON REMOVE GO FROM INDEX 0 
*/
  values.push({ iznos: iznos, kolicina: times });
  const proizvodData = {
    proizvodId: proizvod.id,
    naziv: proizvod.naziv,
    sifra: proizvod.sifra,
    user: proizvod.user,
    vrstaIsporuke: proizvod.vrstaIsporuke,
  };
  Object.assign(history, {
    [date]: {
      text: `dodano ${times} proizvoda po ${iznos} hrk `,
      ulaz: { cijena: iznos, kolicina: times },
      saldo: suma.total,
    },
  });
  const result = {
    [proizvod.id]: {
      values,
      suma,
      stanje,
      proizvodData,
      proizvodId: proizvod.id,
      izlaz: obj.izlaz,
      history,
    },
  };

  return result;
};

export function createSkladiste(proizvodi) {
  const skladiste = {};
  proizvodi.map((item) => {
    Object.assign(skladiste, {
      [item.id]: {
        values: [],
        suma: { total: 0 },
        stanje: [],
        izlaz: { ukupno: 0, ukupanIznos: 0 },
      },
    });
    return null;
  });
  const history = {};
  for (let i = 0; i < proizvodi.length; i++) {
    Object.assign(
      skladiste,
      add(
        proizvodi[i],
        skladiste,
        proizvodi[i].oneCijenaHRK,
        proizvodi[i].kolicina,
        history,
        i
      )
    );
  }
  return skladiste;
}

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { handleGetCountries, handleGetRequest } from "./handle-response";
import { authenticationService } from "../services/authentication";
import { incrementInvoice } from "../helpers/functions";
import { find, extend, map } from "lodash";

dayjs().format();

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const noPDVCountries = [
  "Ålandski otoci",
  "Farski Otoci",
  "Francuska Gvajana",
  "Gibraltar",
  "Otok Man",
  "Hrvatska",
];

export const getAllInvoices = async () => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/invoices/get-all/?userId=${
      currentUser.id
    }&year=${new Date().getFullYear()}`,
    currentUser.token
  );
  const data = await res.data
    .map((item) => {
      return {
        ...item,
        datumRacun: dayjs(item.datumRacuna).format("DD.MM.YYYY HH:mm"),
        izvrsenoOd: dayjs(item.izvrsenoOd).format("DD.MM.YYYY"),
        izvrsenoDo: dayjs(item.izvrsenoDo).format("DD.MM.YYYY"),
        datumDospijeca:
          item.datumDospijeca === null
            ? null
            : dayjs(item.datumDospijeca).format("DD.MM.YYYY"),
        datumPlacanja:
          item.datumPlacanja === null
            ? null
            : dayjs(item.datumPlacanja).format("DD.MM.YYYY"),
        konacanIznos: item.cijenaPdv,
        key:
          item.broj + " " + dayjs(item.datumRacuna).format("DD.MM.YYYY HH:mm"),
        created: item.created + "." + item.key,
      };
    })
    .sort((a, b) => (a.broj < b.broj ? 1 : b.broj < a.broj ? -1 : 0));
  const numToIncrement = data
    .filter((item) => !item.broj.includes("Predložak"))
    .sort((a, b) => {
      const aMod = a.broj.slice(
        0,
        a.broj.indexOf(a.broj.replace("Predložak ", "").match(/\D/))
      );
      const bMod = b.broj.slice(
        0,
        b.broj.indexOf(b.broj.replace("Predložak ", "").match(/\D/))
      );

      return Number(aMod) < Number(bMod)
        ? 1
        : Number(bMod) < Number(aMod)
        ? -1
        : 0;
    });
  const predlozakNums = data
    .filter((item) => item.broj.includes("Predložak"))
    .sort((a, b) => {
      const aMod = a.broj
        .replace("Predložak ", "")
        .slice(
          0,
          a.broj
            .replace("Predložak ", "")
            .indexOf(a.broj.replace("Predložak ", "").match(/\D/))
        );
      const bMod = b.broj
        .replace("Predložak ", "")
        .slice(
          0,
          b.broj
            .replace("Predložak ", "")
            .indexOf(b.broj.replace("Predložak ", "").match(/\D/))
        );

      return Number(aMod) < Number(bMod)
        ? 1
        : Number(bMod) < Number(aMod)
        ? -1
        : 0;
    });
  return [...predlozakNums, ...numToIncrement];
};

export const getAllUnpaidInvoices = async () => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/invoices/get-all/?userId=${
      currentUser.id
    }&year=${new Date().getFullYear()}`,
    currentUser.token
  );
  const data = await res.data
    .map((item) => {
      return {
        ...item,
        datumRacun: dayjs(item.datumRacuna).format("DD.MM.YYYY HH:mm"),
        izvrsenoOd: dayjs(item.izvrsenoOd).format("DD.MM.YYYY"),
        izvrsenoDo: dayjs(item.izvrsenoDo).format("DD.MM.YYYY"),
        datumDospijeca:
          item.datumDospijeca === null
            ? null
            : dayjs(item.datumDospijeca).format("DD.MM.YYYY"),
        datumPlacanja:
          item.datumPlacanja === null
            ? null
            : dayjs(item.datumPlacanja).format("DD.MM.YYYY"),
        konacanIznos: item.cijenaPdv,
        key:
          item.broj + " " + dayjs(item.datumRacuna).format("DD.MM.YYYY HH:mm"),
        created: item.created + "." + item.key,
      };
    })
    .sort((a, b) => {
      const aMod = a.broj.slice(
        0,
        a.broj.indexOf(a.broj.replace("Predložak ", "").match(/\D/))
      );
      const bMod = b.broj.slice(
        0,
        b.broj.indexOf(b.broj.replace("Predložak ", "").match(/\D/))
      );

      return Number(aMod) < Number(bMod)
        ? 1
        : Number(bMod) < Number(aMod)
        ? -1
        : 0;
    })
    .filter((item) => {
      if (item.broj.includes("Predložak") || item.status === "Plaćeno") {
        return false;
      } else return true;
    });
  return {
    data,
    options: data.map((item) => ({
      label: `${item.broj} - ${item.datumRacun} - ${item.kupac}`,
      value: item,
    })),
  };
};
export const getAllPaidInvoices = async (from, till) => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/invoices/get-all/?userId=${
      currentUser.id
    }&year=${new Date().getFullYear()}`,
    currentUser.token
  );
  const data = await res.data
    .filter((item) => item.status !== "Neplaćeno")
    .map((item) => {
      return {
        ...item,
        datumRacun: dayjs(item.datumRacuna).format("DD.MM.YYYY HH:mm"),
        izvrsenoOd: dayjs(item.izvrsenoOd).format("DD.MM.YYYY"),
        izvrsenoDo: dayjs(item.izvrsenoDo).format("DD.MM.YYYY"),
        datumDospijeca:
          item.datumDospijeca === null
            ? null
            : dayjs(item.datumDospijeca).format("DD.MM.YYYY"),
        datumPlacanja:
          item.datumPlacanja === null
            ? null
            : dayjs(item.datumPlacanja).format("DD.MM.YYYY"),
        konacanIznos: item.cijenaPdv,
        key:
          item.broj + " " + dayjs(item.datumRacuna).format("DD.MM.YYYY HH:mm"),
        created: item.created + "." + item.key,
      };
    });

  const paidInvoices = data
    .map((item) => {
      if (item.placanja) {
        return item.placanja.map((itemPlacanje) => {
          return {
            ...item,
            napomena: itemPlacanje.napomena,
            datumPlaceno: itemPlacanje.datum,
            datumPlacanja: dayjs(itemPlacanje.datum).format("DD.MM.YYYY HH:mm"),
            placeno: itemPlacanje.iznos,
            payID: itemPlacanje.id,
            key: uuidv4(),
          };
        });
      } else {
        return item;
      }
    })
    .flat(2)
    .sort((a, b) =>
      dayjs(a.datumPlacanja).isBefore(dayjs(b.datumPlacanja))
        ? 1
        : dayjs(b.datumPlacanja).isBefore(a.datumPlacanja)
        ? -1
        : 0
    );
  const kpr =
    from && till
      ? paidInvoices
          .filter((item) => {
            if (
              dayjs(item.datumPlacanja, "DD.MM.YYYY").isSameOrAfter(
                dayjs(from)
              ) &&
              dayjs(item.datumPlacanja, "DD.MM.YYYY").isSameOrBefore(
                dayjs(till)
              )
            ) {
              return true;
            } else return false;
          })
          .map((item) => ({
            ...item,
            ukupnoPlaceno: item.placeno,
          }))
          .filter((item, index) => {
            item.id = index + 1;
            return item;
          })
      : [];
  return {
    data: paidInvoices,
    kpr,
  };
};

export const getClients = async () => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/clients/get-all/?userId=${
      currentUser.id
    }&year=${new Date().getFullYear()}`,
    currentUser.token
  );
  const data = await res.data;
  return {
    data,
    options: data.map((item) => ({ value: item, label: item.naziv })),
  };
};
export const getProducts = async () => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/products/get-all/?userId=${
      currentUser.id
    }&year=${new Date().getFullYear()}`,
    currentUser.token
  );
  const data = await res.data;
  const merged = data.products
    .map((item) => {
      return extend(item, find(data.skladisteItems, { id: item.id }));
    })
    .map((item) => {
      if (item.skladiste) {
        let sum = 0;
        item.skladiste.stanje.map((obj) => {
          const num = Number(Object.entries(obj)[0][1]);
          sum += num;
        });
        Object.assign(item, { kolicina: sum });
        return { ...item, kolicina: sum };
      } else return { ...item, kolicina: 0 };
    });

  return {
    data: merged,
    options: data.products.map((item) => ({
      value: item,
      label: `${item.naziv} - ${item.sifra}`,
    })),
  };
};
export const getInvoiceNumber = async () => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/invoices/get-all/?userId=${
      currentUser.id
    }&year=${new Date().getFullYear()}`,
    currentUser.token
  );
  const data = await res.data;
  const numToIncrement = data
    .filter((item) => !item.broj.includes("Predložak"))
    .sort((a, b) => {
      const aMod = a.broj.slice(
        0,
        a.broj.indexOf(a.broj.replace("Predložak ", "").match(/\D/))
      );
      const bMod = b.broj.slice(
        0,
        b.broj.indexOf(b.broj.replace("Predložak ", "").match(/\D/))
      );

      return Number(aMod) < Number(bMod)
        ? 1
        : Number(bMod) < Number(aMod)
        ? -1
        : 0;
    });
  const predlozakNum = `Predložak *${currentUser.delimiter}${currentUser.oznakaProstora}${currentUser.delimiter}${currentUser.oznakaUredaja}`;

  if (data.length < 1) {
    if (currentUser.brojRacuna !== 0) {
      return {
        invoiceNumber: incrementInvoice(
          `${currentUser.brojRacuna}${currentUser.delimiter}${currentUser.oznakaProstora}${currentUser.delimiter}${currentUser.oznakaUredaja}`
        ),
        predlozakNum,
      };
    } else {
      return {
        invoiceNumber: `1${currentUser.delimiter}${currentUser.oznakaProstora}${currentUser.delimiter}${currentUser.oznakaUredaja}`,
        predlozakNum,
      };
    }
  } else {
    if (data.length < 1) {
      return {
        invoiceNumber: `1${currentUser.delimiter}${currentUser.oznakaProstora}${currentUser.delimiter}${currentUser.oznakaUredaja}`,
        predlozakNum,
      };
    } else {
      if (numToIncrement.length !== 0) {
        return {
          predlozakNum,
          invoiceNumber: incrementInvoice(
            numToIncrement.reverse()[numToIncrement.length - 1].broj
          ),
        };
      } else {
        return {
          invoiceNumber: `1${currentUser.delimiter}${currentUser.oznakaProstora}${currentUser.delimiter}${currentUser.oznakaUredaja}`,
          predlozakNum,
        };
      }
    }
  }
};
export const getCountries = async () => {
  const res = await handleGetCountries(`https://restcountries.eu/rest/v2/all`);
  const data = await res.data.map((item) => ({
    name: item.translations.hr ? item.translations.hr : item.name,
    alpha2Code: item.alpha2Code,
  }));
  return {
    eu: data
      .filter((item) => {
        if (noPDVCountries.includes(item.name)) {
          return false;
        } else return true;
      })
      .map((item) => ({ label: item.name, value: item.alpha2Code })),
    global: data.map((item) => ({ label: item.name, value: item.alpha2Code })),
  };
};
export const getInventura = async () => {
  const currentUser = authenticationService.currentUserValue;
  const res = await handleGetRequest(
    `${process.env.REACT_APP_HOST_URL}/products/get-skladiste/?userId=${currentUser.id}`,
    currentUser.token
  );
  /*
  const key = Object.keys(item).filter((key) => key !== "ukupno");
  const amount = item[key[0]]; 
  */
  const data = res.data.skladiste
    .map((item) => {
      const transformData = item.stanje.map((stanjeItem) => {
        const proizvodInfo = item.proizvodData;
        const key = Object.keys(stanjeItem).filter((key) => {
          if (key === "ukupno" || stanjeItem[key] < 1) {
            return false;
          }
          return true;
        });
        const amount = stanjeItem[key[0]];
        return {
          ...proizvodInfo,
          broj: "",
          history: item.history,
          izlazHistory: item.izlazHistory,
          kolicina: amount,
          vrijednost: Number(key[0]),
          ukupno: amount * Number(key[0]),
          tableId: key + item.proizvodId,
        };
      });
      return transformData;
    })
    .flat(2);
  const history = res.data.skladiste
    .map((item) => {
      return Object.keys(item.history).map((key) => {
        return {
          ...item,
          broj: "",
          datum: key,
          naziv: item.proizvodData.naziv,
          text: item.history[key].text,
          ulazVrijednost: item.history[key].ulaz?.cijena,
          ulazKolicina: item.history[key].ulaz?.kolicina,
          saldo: item.history[key].saldo,
        };
      });
    })
    .flat(2);
  const outHistory = res.data.skladiste
    .map((item) => {
      return item.izlazHistory
        ? Object.keys(item.izlazHistory)
            .map((key) => {
              const saldo = Object.assign(
                {},
                item.izlazHistory[key].izlaz.saldo
              );
              delete item.izlazHistory[key].izlaz.saldo;
              delete item.izlazHistory[key].izlaz.ukupno;
              delete item.izlazHistory[key].izlaz.ukupanIznos;
              return Object.keys(item.izlazHistory[key].izlaz).map((keyI) => {
                return {
                  broj: "",
                  datum: key,
                  naziv: item.proizvodData.naziv,
                  text: item.izlazHistory[key][key],
                  izlazVrijednost: keyI,
                  izlazKolicina: item.izlazHistory[key].izlaz[keyI],
                  saldo: saldo[keyI],
                };
              });
            })
            .flat(2)
        : {};
    })
    .flat(2);
  return {
    history: [
      ...history,
      ...outHistory.filter((item) => item.datum),
    ].sort((a, b) =>
      dayjs(a.datum).isBefore(dayjs(b.datum))
        ? 1
        : dayjs(b.datum).isBefore(a.datum)
        ? -1
        : 0
    ),
    data,
    outHistory,
  };
};

import { useLocation } from "react-router";
import { useContext, useState } from "react";
import ReportProblem from "../pages/Settings/ReportProblem";
import TitleContext from "../helpers/context";

const shouldDisable = (url) => {
  if (
    url.includes("login") ||
    url.includes("register") ||
    url.includes("zaboravljena-lozinka") ||
    url.includes("reset")
  ) {
    return true;
  } else return false;
};

const Footer = () => {
  const location = useLocation();
  const disabled = shouldDisable(location.pathname);
  const [open, setModals] = useState(false);

  const toggle = () => {
    setModals((open) => !open);
  };

  //is no currentUser disable header and footer
  return !disabled ? (
    <div className="container-fluid mt-4 bg-white">
      <footer className="container py-2">
        <div className="row d-flex">
          <div className="col-6 d-flex">
            <p className="text-dark">Design By Parpar Studio</p>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <button className="text-dark blankBtn" onClick={() => toggle()}>
              Prijavi problem
            </button>
          </div>
        </div>
      </footer>
      {open ? <ReportProblem open={open} toggle={toggle} /> : null}
    </div>
  ) : null;
};

export default Footer;

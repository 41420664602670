import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useStateMachine } from "little-state-machine";
import { updateInvoice, updateClient } from "../../helpers/updateState";
import { authenticationService } from "../../services/authentication";
import { handlePostRequest } from "../../helpers/handle-response";
import { getCountries } from "../../helpers/getData";
import { checkPDVID, getZip, checkOIB } from "../../helpers/functions";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import CustomSelect from "../../components/Select";

const NewClient = ({ open, toggle, from, clients, setClient }) => {
  const currentUser = authenticationService.currentUserValue;
  const queryClient = useQueryClient();
  const { data: countries } = useQuery("countries", getCountries);
  const { actions, state } = useStateMachine({
    updateInvoice,
    updateClient,
  });
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    setError,
    trigger,
  } = useForm();

  const [vatStatus, setVatStatus] = useState({ state: null, message: "" });
  const [sending, setSending] = useState(false);

  useEffect(() => {
    register({ name: "pdvid" });
  }, []);

  const submit = (data) => {
    //check if that client already exists from all clients
    if (
      from !== "editClient" &&
      clients.data.filter(function (e) {
        return e.naziv.toLowerCase() === data.naziv.toLowerCase();
      }).length > 0
    ) {
      setError("naziv", {
        type: "manual",
        message: "Unos s tim nazivom već postoji",
        shouldFocus: true,
      });
    } else if (
      from !== "editClient" &&
      clients.data.filter(function (e) {
        return Number(e.oib) === Number(data.oib);
      }).length > 0
    ) {
      setError("oib", {
        type: "manual",
        message: "Unos s tim OIB-om već postoji",
        shouldFocus: true,
      });
    } else {
      data.adresa = `${data.ulica} ${data.broj}`;
      data.vrstaKlijenta = data.vrstaKlijenta.value;
      if (data.drzava.value === "HR") {
        data.zemlja = "Hrvatska";
      } else if (
        countries.eu.find((item) => item.value === data.drzava.value)
          ? true
          : false
      ) {
        data.zemlja = " EU";
      } else data.zemlja = "Treća zemlja";
      data.drzava = data.drzava.label;
      data.id = state.clientData.id;
      setSending(true);

      setVatStatus({
        state: false,
        message: "",
      });

      handlePostRequest(
        `${process.env.REACT_APP_HOST_URL}/clients/${
          from === "editClient" ? "update" : "create"
        }`,
        [data],
        currentUser.token
      )
        .then((res) => {
          queryClient.invalidateQueries("clients");
          setTimeout(() => {
            if (from === "newInvoice") {
              actions.updateInvoice({
                klijent: {
                  value: res.data.client,
                  label: res.data.client.naziv,
                },
              });
              setClient("klijent", {
                value: res.data.client,
                label: res.data.client.naziv,
              });
            } else if (from === "newIncoming") {
              setClient("klijent", {
                value: res.data.client,
                label: res.data.client.naziv,
              });
            }
            setSending(false);
            toggle();
          }, 2000);
        })
        .catch((err) => console.log(err));
    }
  };
  const trapSpacesForRequiredFields = (value) => !!value.trim();

  return (
    <Modal
      open={open}
      toggle={toggle}
      title={from === "editClient" ? "Uredi klijenta" : "Novi klijent"}
    >
      <form
        onSubmit={handleSubmit(submit)}
        className="form form-validate is-alter"
      >
        <div className="form-group">
          <label className="form-label" htmlFor="drzava">
            Država:
          </label>
          <div className="form-control-wrap">
            <Controller
              name="drzava"
              classnames="form-control-lg"
              control={control}
              id="drzava"
              defaultValue={
                countries?.global.find(
                  (item) => item.label === state.clientData.drzava?.label
                )
                  ? countries?.global.find(
                      (item) => item.label === state.clientData.drzava?.label
                    )
                  : null
              }
              rules={{ required: "Izaberi državu" }}
              placeholder="Izaberi državu"
              menuPlacement="auto"
              options={countries?.global}
              noOptionsMessage={() => "Nema države"}
              as={CustomSelect}
            />

            {errors.drzava && (
              <span className="error">{errors.drzava.message}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="oib">
            OIB:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="oib"
              control={control}
              name="oib"
              defaultValue={state.clientData.oib}
              rules={{
                required: "Unesi OIB",
                validate: {
                  minLength: (value) => {
                    if (
                      getValues("drzava")?.value === "HR" &&
                      value?.length < 11
                    ) {
                      return "OIB mora sadržavati 11 znakova";
                    }
                  },
                  validOib: (value) => {
                    if (
                      getValues("drzava")?.value === "HR" &&
                      value?.length <= 11 &&
                      !checkOIB(value)
                    ) {
                      return "OIB nije valjan";
                    }
                  },
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <Input
                  getInputRef={ref}
                  maxLength={11}
                  name={name}
                  type="text"
                  classnames="form-control-lg"
                  placeholder="OIB"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.oib && <span className="error">{errors.oib.message}</span>}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="vrstaKlijenta">
            Vrsta klijenta:
          </label>
          <div className="form-control-wrap">
            <Controller
              name="vrstaKlijenta"
              control={control}
              id="vrstaKlijenta"
              defaultValue={state.clientData.vrstaKlijenta}
              rules={{ required: "Izaberi vrstu klijenta" }}
              placeholder="Izaberi vrstu klijenta"
              menuPlacement="auto"
              options={[
                { value: "Fizička osoba", label: "Fizička osoba" },
                { value: "Pravna osoba", label: "Pravna osoba" },
              ]}
              noOptionsMessage={() => "Nema vrste klijenta"}
              as={CustomSelect}
            />

            {errors.vrstaKlijenta && (
              <span className="error">{errors.vrstaKlijenta.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="naziv">
            Naziv:
          </label>
          <div className="form-control-wrap">
            <Controller
              classnames="form-control-lg"
              name="naziv"
              control={control}
              id="naziv"
              defaultValue={state.clientData.naziv}
              rules={{
                validate: {
                  required: (value) =>
                    !trapSpacesForRequiredFields(value) ? "Unesi naziv" : true,
                },
              }}
              placeholder="Naziv"
              as={Input}
            />
            {errors.naziv && (
              <span className="error">{errors.naziv.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="ulica">
            Ulica:
          </label>
          <div className="form-control-wrap">
            <Controller
              classnames="form-control-lg"
              name="ulica"
              control={control}
              id="ulica"
              defaultValue={state.clientData.ulica}
              rules={{
                validate: {
                  required: (value) =>
                    !trapSpacesForRequiredFields(value)
                      ? "Ulica je obvezna"
                      : true,
                },
              }}
              placeholder="Ulica"
              as={Input}
            />

            {errors.ulica && (
              <span className="error">{errors.ulica.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="broj">
            Broj:
          </label>
          <div className="form-control-wrap">
            <Controller
              classnames="form-control-lg"
              name="broj"
              control={control}
              id="broj"
              defaultValue={state.clientData.broj}
              rules={{
                validate: {
                  required: (value) =>
                    !trapSpacesForRequiredFields(value)
                      ? "Broj je obvezan"
                      : true,
                },
              }}
              placeholder="Broj"
              as={Input}
            />

            {errors.broj && (
              <span className="error">{errors.broj.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="mjesto">
            Mjesto:
          </label>
          <div className="form-control-wrap">
            <Controller
              name="mjesto"
              control={control}
              id="mjesto"
              defaultValue={state.clientData.mjesto}
              rules={{
                validate: {
                  required: (value) =>
                    !trapSpacesForRequiredFields(value)
                      ? "Mjesto je obvezno"
                      : true,
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <Input
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  ref={ref}
                  classnames="form-control-lg"
                  placeholder="Mjesto"
                  onChange={(e) => {
                    onChange(e);
                    const zip = getZip(e.target.value);
                    if (zip !== undefined) {
                      setValue("postanskiBroj", zip.brojPu);
                    }
                  }}
                />
              )}
            />
            {errors.mjesto && (
              <span className="error">{errors.mjesto.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="postanskiBroj">
            Poštanski broj:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="postanskiBroj"
              control={control}
              name="postanskiBroj"
              defaultValue={state.clientData.postanskiBroj}
              rules={{
                required: "Unesi poštanski broj",
                minLength: {
                  value: 5,
                  message: "Poštanski broj mora sadržavati 5 znakova",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  maxLength={5}
                  name={name}
                  allowLeadingZeros
                  decimalSeparator={false}
                  type="text"
                  getInputRef={ref}
                  classnames="form-control-lg"
                  placeholder="Poštanski broj"
                  customInput={Input}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.postanskiBroj && (
              <span className="error">{errors.postanskiBroj.message}</span>
            )}
          </div>
        </div>

        <div className="form-group form-control-wrap mt-2">
          <div className="custom-control custom-control-xs custom-checkbox">
            <input
              type="checkbox"
              className="form-control custom-control-input position-relative"
              id="hasPdvId"
              name="hasPdvId"
              onChange={async (e) => {
                setValue("hasPdvId", false);
                const values = getValues();
                const validation = await trigger(["drzava", "oib"]);
                if (validation) {
                  setVatStatus({ state: "checking", message: "Provjera..." });
                  checkPDVID(values.drzava.value, values.oib, (res) => {
                    if (res.isValid === "true") {
                      setVatStatus({
                        state: true,
                        message: "Tvrtka ima PDV ID",
                      });
                      setValue("pdvid", res.code + res.vat);
                      setValue(
                        "hasPdvId",
                        res.isValid === "true" ? true : false
                      );
                    } else {
                      setVatStatus({
                        state: false,
                        message: "Tvrtka nema PDV ID",
                      });
                      setTimeout(() => {
                        setVatStatus({
                          state: false,
                          message: "",
                        });
                      }, 1500);
                    }
                  });
                }
              }}
              ref={register()}
            />

            <label className="custom-control-label" htmlFor="hasPdvId">
              {vatStatus.state === "checking"
                ? vatStatus.message
                : "Tvrtka ima PDV ID? (VIES Provjera)"}
            </label>
          </div>
        </div>
        <hr></hr>
        {vatStatus.state !== "checking" && vatStatus.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto mb-2">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-${
                vatStatus.state ? "success" : "gray"
              }`}
              role="alert"
            >
              <em
                className={`icon ni ni-${
                  vatStatus.state ? "check" : "alert"
                }-circle`}
              ></em>
              {vatStatus.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setVatStatus({ state: null, message: "" })}
              ></button>
            </div>
          </div>
        )}
        {sending && (
          <div style={{ maxWidth: 800 }} className="mx-auto mb-2">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-success`}
              role="alert"
            >
              <em className={`icon ni ni-check-circle`}></em>
              {from === "editClient"
                ? `Klijent ${getValues().naziv} ažuriran`
                : "Novi klijent spremljen"}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setSending(false)}
              ></button>
            </div>
          </div>
        )}
        <div className="form-group">
          <div className="form-control-wrap row">
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-start">
              <Button
                disabled={sending}
                classnames="btn-dim btn-outline-primary btn-lg"
                type="submit"
              >
                {from === "editClient"
                  ? "Spremi promjene"
                  : "Dodaj novog klijenta"}
              </Button>
            </div>
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-end">
              <Button
                disabled={sending}
                classnames="btn-dim btn-outline-danger btn-lg"
                type="button"
                onClick={toggle}
              >
                Izađi bez spremanja
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default NewClient;

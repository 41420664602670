import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { authenticationService } from "../../services/authentication";
import TitleContext from "../../helpers/context";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { handlePostRequest } from "../../helpers/handle-response";

const ReportProblem = ({ open, toggle }) => {
  const currentUser = authenticationService.currentUserValue;
  const { oib, email, naziv, ime, prezime } = currentUser;
  const { title } = useContext(TitleContext);
  const naslov = useRef(`${title.includes(", ") ? "Paušal.hr" : title} - `);
  const [status, setStatus] = useState({
    state: false,
    message: "",
    loading: false,
  });
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      naslov: naslov.current,
      opisProblema: "",
    },
  });

  const submit = (data) => {
    setStatus((status) => ({ ...status, loading: true, message: "Slanje..." }));
    const obj = {
      naslov: data.naslov,
      opisProblema: data.opis,
      oib,
      email,
      naziv,
      ime,
      prezime,
    };
    console.log(data);
    handlePostRequest(
      `${process.env.REACT_APP_HOST_URL}/users/report/`,
      [obj],
      currentUser.token
    )
      .then((res) => {
        if (res.data === "Prijava poslana") {
          setStatus({ state: true, message: res.data, loading: false });
          setTimeout(() => {
            setStatus({ state: false, message: "", loading: false });
            toggle();
          }, 2000);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Modal
      open={open}
      toggle={toggle}
      title="Zahtjev za pomoć / Prijava problema"
    >
      <form onSubmit={handleSubmit(submit)} className="form-validate is-alter">
        <div className="form-group">
          <label className="form-label">Naslov:</label>
          <div className="form-control-wrap">
            <Input
              name="naslov"
              placeholder="Naslov"
              ref={register({ required: "Obvezno polje" })}
              classnames={`form-control form-control-lg ${
                errors.naslov && "error"
              }`}
            />
            {errors.naslov && (
              <span className="error position-absolute">
                {errors.naslov.message}
              </span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label">Opis problema:</label>
          <div className="form-control-wrap ">
            <textarea
              name="opis"
              ref={register({ required: "Obvezno polje" })}
              className={`form-control no-resize form-control-lg ${
                errors.opis && "error"
              }`}
              id="default-textarea"
              placeholder="Opis problema"
            />
            {errors.opis && (
              <span className="error position-absolute">
                {errors.opis.message}
              </span>
            )}
          </div>
        </div>
        {status.state && status.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto mb-2">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-${
                status.loading ? "info" : "success"
              }`}
              role="alert"
            >
              <em
                className={`icon ni ni-${
                  status.loading ? "alert" : "check"
                }-circle`}
              ></em>
              {status.message.length > 0 && status.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setStatus({ state: false, message: "" })}
              ></button>
            </div>
          </div>
        )}
        <div className="form-group row d-flex">
          <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start my-2 my-lg-0">
            <Button
              disabled={status.state}
              type="submit"
              classnames="btn-dim btn-outline-primary"
            >
              Pošalji
            </Button>
          </div>
          <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end my-2 my-lg-0">
            <Button
              disabled={status.state}
              onClick={toggle}
              type="button"
              classnames="btn-dim btn-outline-danger"
            >
              Izađi
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ReportProblem;

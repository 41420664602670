import React from "react";
import xml2js from "xml2js";
import Excel from "exceljs";
import saveAs from "file-saver";
import { authenticationService } from "../services/authentication";
import { handleGetXml, handlePostRequest } from "./handle-response";
import { PDF417 } from "../services/pdf417";
import { zipCodes, djelatnosti, banks } from "../constants/index";
import { formatDate } from "./index";

export function decodeHTMLEntities(text) {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

export function getZip(naselje) {
  const obj = zipCodes.mjestaRH.mjesto.find(
    (obj) => obj.naselje.toLowerCase() === naselje.toLowerCase()
  );
  return obj;
}
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export function getDates(date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return { from: firstDay, till: lastDay };
}
export const caret = (order, column) => {
  if (!order)
    return (
      <div className="d-flex">
        <i className="la la-long-arrow-up icon " style={{ opacity: 0.3 }}></i>
        <i className="la la-long-arrow-down icon " style={{ opacity: 0.3 }}></i>
      </div>
    );
  else if (order === "asc")
    return (
      <div className="d-flex">
        <i className="la la-long-arrow-up icon "></i>
        <i className="la la-long-arrow-down icon " style={{ opacity: 0.3 }}></i>
      </div>
    );
  else if (order === "desc")
    return (
      <div className="d-flex">
        <i className="la la-long-arrow-up icon " style={{ opacity: 0.3 }}></i>
        <i className="la la-long-arrow-down icon "></i>
      </div>
    );
  return null;
};
export const headerFormatter = (column, colIndex, components) => {
  return (
    <div className="d-flex">
      <div style={{ flex: 1 }}>{column.text}</div>
      {components.sortElement}
    </div>
  );
};

export const dateFormatter = (column, colIndex, components) => {
  return (
    <div className="d-flex">
      <div style={{ flex: 1 }}>{formatDate(new Date(column))}</div>
      {components.sortElement}
    </div>
  );
};

export function generate(text, canvas) {
  PDF417.init(text);

  var barcode = PDF417.getBarcodeArray();

  // block sizes (width and height) in pixels
  var bw = 1;
  var bh = 1;

  // create canvas element based on number of columns and rows in barcode
  var container = document.getElementById("barcode");
  container.removeChild(container.firstChild);

  canvas.width = bw * barcode["num_cols"];
  canvas.height = bh * barcode["num_rows"];
  container.appendChild(canvas);

  var ctx = canvas.getContext("2d");

  // graph barcode elements
  var y = 0;
  // for each row
  for (var r = 0; r < barcode["num_rows"]; ++r) {
    var x = 0;
    // for each column
    for (var c = 0; c < barcode["num_cols"]; ++c) {
      //eslint-disable-next-line
      if (barcode["bcode"][r][c] == 1) {
        ctx.fillRect(x, y, bw, bh);
      }
      x += bw;
    }
    y += bh;
  }
}

export function createCanvas(text) {
  PDF417.init(text);

  var barcode = PDF417.getBarcodeArray();

  // block sizes (width and height) in pixels
  var bw = 2;
  var bh = 2;

  // create canvas element based on number of columns and rows in barcode
  var canvas = document.createElement("canvas");
  const container = document.getElementById("barcode");
  container.removeChild(container.firstChild);
  canvas.width = bw * barcode["num_cols"];
  canvas.height = bh * barcode["num_rows"];
  container.appendChild(canvas);

  var ctx = canvas.getContext("2d");

  // graph barcode elements
  var y = 0;
  // for each row
  for (var r = 0; r < barcode["num_rows"]; ++r) {
    var x = 0;
    // for each column
    for (var c = 0; c < barcode["num_cols"]; ++c) {
      //eslint-disable-next-line
      if (barcode["bcode"][r][c] == 1) {
        ctx.fillRect(x, y, bw, bh);
      }
      x += bw;
    }
    y += bh;
  }

  return container;
}

export function helpBarcode(createDate) {
  const code =
    "HRVHUB30\nHRK\n" +
    `0.00\n` +
    `` +
    `` +
    `` +
    `ROTARY KLUB BJELOVAR\n` +
    `Vatroslava Lisinskog 2\n` +
    `43000 Bjelovar \n` +
    `HR9124020061100069570 \n` +
    `HR99\n` +
    `${createDate(new Date())}` +
    `COST\n` +
    `Donacija - potres`;
  return createCanvas(code).firstChild.toDataURL();
}
export function incrementInvoice(number) {
  const num = number.slice(0, number.indexOf(number.match(/\D/)));
  const otherPart = number.slice(number.indexOf(number.match(/\D/)));
  return Number(num) + 1 + otherPart;
}
export function stringToNum(value) {
  if (typeof "" === typeof value) {
    return Number(value.replaceAll(".", "").replace(",", "."));
  } else return value;
}
export const getTotalPrice = (items, whichValue) => {
  if (items.length !== 0) {
    if (items.length === 1) {
      return { [whichValue]: items[0][whichValue] };
    } else {
      return items.reduce(
        (accumulator, currentValue) => {
          return {
            [whichValue]: accumulator[whichValue] + currentValue[whichValue],
          };
        },
        { [whichValue]: 0 }
      );
    }
  }
};
export const generatePdvs = (values) => {
  const pdv0 = values.proizvodi
    .filter(({ porez }) => porez === 0)
    .reduce(
      (a, b) => ({
        newPrice: a.newPrice + b.newPrice,
        pdvAmount: a.pdvAmount + b.pdvAmount,
      }),
      {
        newPrice: 0,
        pdvAmount: 0,
      }
    );
  const pdv5 = values.proizvodi
    .filter(({ porez }) => porez === 5)
    .reduce(
      (a, b) => ({
        newPrice: a.newPrice + b.newPrice,
        pdvAmount: a.pdvAmount + b.pdvAmount,
      }),
      {
        newPrice: 0,
        pdvAmount: 0,
      }
    );
  const pdv13 = values.proizvodi
    .filter(({ porez }) => porez === 13)
    .reduce(
      (a, b) => ({
        newPrice: a.newPrice + b.newPrice,
        pdvAmount: a.pdvAmount + b.pdvAmount,
      }),
      {
        newPrice: 0,
        pdvAmount: 0,
      }
    );
  const pdv25 = values.proizvodi
    .filter(({ porez }) => porez === 25)
    .reduce(
      (a, b) => ({
        newPrice: a.newPrice + b.newPrice,
        pdvAmount: a.pdvAmount + b.pdvAmount,
      }),
      {
        newPrice: 0,
        pdvAmount: 0,
      }
    );
  return [
    { cijena: pdv0.newPrice, pdv: pdv0.pdvAmount, perc: 0 },
    { cijena: pdv5.newPrice, pdv: pdv5.pdvAmount, perc: 5 },
    { cijena: pdv13.newPrice, pdv: pdv13.pdvAmount, perc: 13 },
    { cijena: pdv25.newPrice, pdv: pdv25.pdvAmount, perc: 25 },
  ];
};
export const checkPDVID = (code, oib, callback) => {
  const xml = `<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
  xmlns:tns1="urn:ec.europa.eu:taxud:vies:services:checkVat:types"
  xmlns:impl="urn:ec.europa.eu:taxud:vies:services:checkVat">
  <soap:Header>
  </soap:Header>
  <soap:Body>
    <tns1:checkVat xmlns:tns1="urn:ec.europa.eu:taxud:vies:services:checkVat:types"
     xmlns="urn:ec.europa.eu:taxud:vies:services:checkVat:types">
     <tns1:countryCode>${code}</tns1:countryCode>
     <tns1:vatNumber>${oib}</tns1:vatNumber>
    </tns1:checkVat>
  </soap:Body>
</soap:Envelope>`;
  handleGetXml(`${process.env.REACT_APP_HOST_URL}/proxy/post`, {
    xml,
    url: "https://ec.europa.eu/taxation_customs/vies/services/checkVatService",
  })
    .then(function (response) {
      xml2js.parseString(response.data, { trim: true }, function (err, result) {
        if (result["soap:Envelope"]["soap:Body"][0]["soap:Fault"]) {
          const res = {
            isValid: false,
            code:
              result["soap:Envelope"]["soap:Body"][0]["soap:Fault"][0]
                .faultstring[0],
            error: "Pogrešan unos",
          };
          callback(res);
        } else {
          const res = {
            isValid:
              result["soap:Envelope"]["soap:Body"][0]["checkVatResponse"][0][
                "valid"
              ][0],
            code:
              result["soap:Envelope"]["soap:Body"][0]["checkVatResponse"][0][
                "countryCode"
              ][0],
            vat:
              result["soap:Envelope"]["soap:Body"][0]["checkVatResponse"][0][
                "vatNumber"
              ][0],
            done: true,
          };
          callback(res);
        }
      });
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateProfile = async (data, callSuccess, callError) => {
  const currentUser = await authenticationService.currentUserValue;

  handlePostRequest(
    `${process.env.REACT_APP_HOST_URL}/users/update`,
    [data],
    currentUser.token
  )
    .then((res) => {
      if (res.data.error) {
        callError(res.data.error);
      } else {
        handlePostRequest(
          `${process.env.REACT_APP_HOST_URL}/users/profile`,
          [{}],
          currentUser.token
        )
          .then((res) => {
            authenticationService.setUser(res.data);
            callSuccess(res);
          })
          .catch((err) => console.error(err));
      }
    })
    .catch((err) => console.error(err));
};
export function getCodes(sifra) {
  console.log(sifra);
  const obj = djelatnosti.find(
    (obj) => obj.sifra.replace(/\./g, "") === sifra.replace(/\./g, "")
  );
  return obj;
}
export function getBank(iban) {
  return banks[iban.substring(4, 11)];
}
export function generatePassword() {
  let length = 12,
    charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-?!",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}
export async function saveAsExcel(cols, data, name) {
  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet(name);
  const newCols = cols.map((item) => {
    return { header: item.Header, key: item.accessor, width: 15 };
  });
  ws.columns = newCols;
  ws.getRow(1).font = { bold: true };
  data.map((item, index) => {
    ws.addRow({ ...item, id: index + 1 });
    return null;
  });
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${name}.xlsx`);
}
export async function saveAsCsv(cols, data, name) {
  const wb = new Excel.Workbook();
  const ws = wb.addWorksheet(name);
  const newCols = cols.map((item) => {
    return { header: item.Header, key: item.accessor, width: 15 };
  });
  ws.columns = newCols;
  ws.getRow(1).font = { bold: true };
  data.map((item, index) => {
    ws.addRow({ ...item, id: index + 1 });
    return null;
  });
  const buf = await wb.csv.writeBuffer();
  saveAs(new Blob([buf]), `${name}.csv`);
}

export function exportPdf(cols, data, name, pdfName, setDownloading) {
  setDownloading(true);

  handlePostRequest(`https://pdf-api.accountant.hr/create-pdf/`, [
    {
      cols,
      dataToSend: data,
      name: pdfName,
    },
  ])
    .then((res) => {
      setDownloading(false);

      saveAs(b64toBlob(res.data), `${name}.pdf`);
    })
    .catch((e) => alert(e));
}
export function exportKprPdf(data, setDownloading) {
  const currentUser = authenticationService.currentUserValue;
  const newDataIds = data.filter((item, index) => {
    item.id = index + 1;
    return item;
  });
  const userData = {
    adresa: currentUser.adresa,
    banka: currentUser.banka,
    djelatnost: currentUser.djelatnost,
    nazivDjelatnosti: currentUser.nazivDjelatnosti,
    email: currentUser.email,
    iban: currentUser.iban,
    mjesto: currentUser.mjesto,
    postanskiBroj: currentUser.postanskiBroj,
    naziv: currentUser.naziv,
    oib: currentUser.oib,
    telefon: currentUser.telefon,
    vlasnik: currentUser.vlasnik,
  };
  setDownloading(true);

  handlePostRequest(`https://pdf-api.accountant.hr/create-pdf/`, [
    {
      userData,
      newDataIds,
      name: "kpr",
    },
  ])
    .then((res) => {
      setDownloading(false);

      saveAs(b64toBlob(res.data), "knjiga_prometa.pdf");
    })
    .catch((e) => alert(e));
}
export function checkOIB(input) {
  const oib = input.toString();

  if (oib.match(/\d{11}/) === null) {
    return false;
  }

  let calculated = 10;

  for (const digit of oib.substring(0, 10)) {
    calculated += parseInt(digit);

    calculated %= 10;

    if (calculated === 0) {
      calculated = 10;
    }

    calculated *= 2;

    calculated %= 11;
  }

  let check = 11 - calculated;

  if (check === 10) {
    check = 0;
  }

  return check === parseInt(oib[10]);
}

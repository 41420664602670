import { Pie } from "./Charts";

const StatCircleChart = ({ data }) => {
  return (
    <div className="card card-preview">
      <div className="card-inner">
        <div className="card-head text-center">
          <h6 className="title">Status računa</h6>
        </div>
        <div className="h-100">
          <div className="chartjs-size-monitor">
            <div className="chartjs-size-monitor-expand">
              <div style={{ height: 250 }}>
                <div style={{ height: 250 }}>
                  <Pie data={data}></Pie>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatCircleChart;

import { useState } from "react";
import { authenticationService } from "../../services/authentication";
import EditUser from "./EditUser";
import Button from "../../components/Button";
import { Modal } from "@material-ui/core";

const SettingsPersonal = (props) => {
  const currentUser = authenticationService.currentUserValue;
  const [open, setOpen] = useState({ state: false, to: "" });
  const toggle = (to) => {
    setOpen((open) => ({ state: !open.state, to: to }));
  };

  return (
    <div className="w-100">
      <div className="card-inner card-inner-lg">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">Osnovne informacije</h4>
            </div>
            <div className="nk-block-head-content align-self-start d-lg-none">
              <a
                className=" btn btn-icon mt-n1"
                onClick={() => {
                  if (!props.open) {
                    props.setOpen(true);
                  }
                }}
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
            </div>
            <div className="nk-block-head-content align-self-center d-none d-lg-block">
              <Button
                classnames="btn-lg btn-dim btn-outline-primary"
                onClick={toggle}
              >
                Uredi
              </Button>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Basics</h6>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("naziv")}
            >
              <div className="data-col">
                <span className="data-label">Naziv obrta:</span>
                <span className="data-value">{currentUser.naziv}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("ime")}
            >
              <div className="data-col">
                <span className="data-label">Ime i prezime</span>
                <span className="data-value">
                  {currentUser.ime} {currentUser.prezime}
                </span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("ulica")}
            >
              <div className="data-col">
                <span className="data-label">Ulica:</span>
                <span className="data-value">{currentUser.ulica}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more disable">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("kucniBroj")}
            >
              <div className="data-col">
                <span className="data-label">Kućni broj:</span>
                <span className="data-value">{currentUser.kucniBroj}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("mjesto")}
            >
              <div className="data-col">
                <span className="data-label">Mjesto:</span>
                <span className="data-value">{currentUser.mjesto}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              data-tab-target="#address"
              onClick={() => toggle("postanskiBroj")}
            >
              <div className="data-col">
                <span className="data-label">Poštanski broj:</span>
                <span className="data-value">{currentUser.postanskiBroj}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("emailRacun")}
            >
              <div className="data-col">
                <span className="data-label">E-Mail (za fakturu):</span>
                <span className="data-value">{currentUser.emailRacun}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("telefon")}
            >
              <div className="data-col">
                <span className="data-label">Telefon:</span>
                <span className="data-value">{currentUser.telefon}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("logo")}
            >
              <div className="data-col">
                <span className="data-label">Logo:</span>
                <span className="data-logo">
                  <div className="w-25 d-flex justify-content-center align-items-centerd">
                    <img
                      src={`${process.env.REACT_APP_HOST_URL}/${currentUser.logo}`}
                      alt=""
                    ></img>
                  </div>
                </span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditUser open={open} toggle={toggle} />
    </div>
  );
};

export default SettingsPersonal;

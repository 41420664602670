import { useMemo, useCallback, useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Modal } from "@material-ui/core";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import { getClients, getProducts } from "../../helpers/getData";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputValue from "../../components/InputValue";
import Datepicker from "../../components/DatePicker";
import CustomSelect from "../../components/Select";
import NewClient from "../Clients/NewClient";
import NewProduct from "../Products/NewProduct";
import { useTitle } from "../../helpers/hooks";
import Table from "../../components/Table";
import { formatCurrency } from "../../helpers";
import { addIncomingProduct } from "../Invoices/functions";
import { stringToNum } from "../../helpers/functions";
import { createSkladiste } from "../../helpers/fifo";

const changeProductsPrices = (products, currency, callback) => {
  const newPrices = products.map((product) => {
    const hrkPrice = product.cijena * currency;

    return {
      ...product,
      cijenaHRK: stringToNum(product.pdvPrice) * currency,

      oneCijenaHRK:
        hrkPrice - hrkPrice * (product.rabat ? product.rabat / 100 : 100 / 100),
    };
  });
  callback(newPrices);
};

const NewIncoming = () => {
  const [status, setStatus] = useState({
    state: false,
    message: "",
    error: "",
  });
  useTitle("Unos ulaznog računa");
  const [modals, setModal] = useState({ addClient: false, addProduct: false });
  const { data: clients } = useQuery("clients", getClients);
  const { data: products } = useQuery("products", getProducts);
  const [proizvodi, setProizvodi] = useState([]);
  const [loadCurrency, setLoadCurrency] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    errors,
    setError,
    reset,
    getValues,
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      proizvodi: [],
      klijent: null,
      datumRacuna: new Date(),
      izvrsenoOd: new Date(),
      izvrsenoDo: new Date(),
      datumDospijeca: new Date(),
      valuta: { label: "HRK", value: "HRK", val: 1 },
      broj: "",
      proizvod: null,
      ukupanIznos: "",
      dobra: "",
      usluge: "",
      skladiste: "",
      dobraHRK: "",
      uslugeHRK: "",
      skladisteHRK: "",
    },
  });

  const {
    handleSubmit: handleSubmitP,
    errors: errorsP,
    setValue: setValueP,
    reset: resetP,
    control: controlP,
    setError: setErrorP,
  } = useForm({
    defaultValues: {
      proizvod: null,
      cijena: null,
      kolicina: null,
      popust: null,
    },
  });

  useEffect(() => {
    register({ name: "proizvodi" });
    register({ name: "dobraHRK" });
    register({ name: "uslugeHRK" });
    register({ name: "skladisteHRK" });
  }, [register]);

  const editProduct = useCallback(
    (values) => {
      setProizvodi((proizvodi) =>
        proizvodi.filter((item) => {
          if (
            item.id === values.id &&
            item.cijena === values.cijena &&
            item.rabat === values.rabat
          ) {
            return false;
          } else return true;
        })
      );
      setValueP("proizvod", values.option);
      setValueP("cijena", values.cijena);
      setValueP("kolicina", values.kolicina);
      setValueP("popust", values.rabat ? values.rabat : "");
    },
    [setValueP]
  );
  const cols = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Šifra", accessor: "sifra" },
      { Header: "JM", accessor: "unit" },
      {
        Header: "Cijena",
        accessor: "cijena",
        Cell: (props) =>
          formatCurrency(props.value) + ` ${getValues("valuta").label}`,
      },
      { Header: "Količina", accessor: "kolicina" },
      {
        Header: "Ukupna cijena",
        accessor: "ukupnaCijena",
        Cell: (props) =>
          formatCurrency(props.value) + ` ${getValues("valuta").label}`,
      },
      { Header: "Popust", accessor: "rabat" },
      {
        Header: "Konačna cijena",
        accessor: "pdvPrice",
        Cell: (props) =>
          formatCurrency(props.value) + ` ${getValues("valuta").label}`,
      },
      {
        Header: "Konačna cijena HRK",
        accessor: "cijenaHRK",
        Cell: (props) => formatCurrency(props.value),
      },
      {
        Header: "Uredi",
        accessor: "option",
        Cell: (props) => {
          return (
            <button
              type="button"
              onClick={() => {
                editProduct(props.row.values);
              }}
              className="blankBtn d-flex mx-auto editButton"
            >
              <em className="icon ni ni-edit editButton"></em>
            </button>
          );
        },
      },
      {
        Header: "Obriši",
        accessor: "id",
        Cell: (props) => {
          return (
            <button
              onClick={() =>
                setProizvodi((proizvodi) =>
                  proizvodi.filter((item) => {
                    if (
                      item.id === props.row.values.id &&
                      item.cijena === props.row.values.cijena &&
                      item.rabat === props.row.values.rabat
                    ) {
                      return false;
                    } else return true;
                  })
                )
              }
              type="button"
              className="blankBtn d-flex mx-auto deleteButton"
            >
              <em className="icon ni ni-cross-round-fill"></em>
            </button>
          );
        },
      },
    ],
    [editProduct, getValues]
  );

  const handleChangeCurrency = (value, date, products) => {
    setLoadCurrency(true);
    const dobra = stringToNum(getValues("dobra"));
    const usluge = stringToNum(getValues("usluge"));
    const skladiste = stringToNum(getValues("skladiste"));

    if (value !== "HRK" && date instanceof Date && !isNaN(date)) {
      const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      handlePostRequest(`${process.env.REACT_APP_HOST_URL}/helpers/get/`, [
        {
          url: `https://api.hnb.hr/tecajn/v2?valuta=${value}&datum=${date.getFullYear()}-${
            date.getMonth() + 1
          }-${day}`,
        },
      ]).then((res) => {
        const val = Number(res.data[0].srednji_tecaj.replace(",", "."));
        setLoadCurrency(false);
        setValue("valuta", {
          label: res.data[0].valuta,
          value: res.data[0].valuta,
          val,
        });
        setValue("dobraHRK", val * dobra);
        setValue("uslugeHRK", val * usluge);
        setValue("skladisteHRK", val * skladiste);
        if (products.length > 0) {
          changeProductsPrices(products, val, (res) => setProizvodi(res));
        }
      });
    } else {
      setValue("valuta", { val: 1, label: "HRK", value: "HRK" });
      setValue("dobraHRK", 1 * dobra);
      setValue("uslugeHRK", 1 * usluge);
      setValue("skladisteHRK", 1 * skladiste);
      if (products.length > 0) {
        changeProductsPrices(products, 1, (res) => setProizvodi(res));
      }

      setLoadCurrency(false);
    }
  };
  const submit = (data) => {
    const currentUser = authenticationService.currentUserValue;
    const dobra = stringToNum(data.dobra);
    const usluge = stringToNum(data.usluge);
    const skladiste = stringToNum(data.skladiste);
    const total = dobra + usluge + skladiste;
    setValue("ukupanIznos", total);
    if (data.skladiste.length > 0 && proizvodi.length < 1) {
      setErrorP("proizvod", {
        type: "manual",
        message: "Odaberi proizvod",
        shouldFocus: true,
      });
    } else {
      setStatus({
        state: false,
        message: "Slanje",
        error: "",
        loading: true,
      });
      const allSkladiste = createSkladiste(
        proizvodi.filter((item) => item.vrstaIsporuke === "Roba")
      );
      const obj = {
        brojRacuna: data.broj,
        datum: data.datumRacuna,
        isporukaOd: data.izvrsenoOd,
        isporukaDo: data.izvrsenoDo,
        klijent: data.klijent.value.naziv,
        klijentDetails: data.klijent.value,
        iznos: total,
        iznosHRK: total * data.valuta.val,
        proizvodi: proizvodi,
        valuta: data.valuta.label,
        iznosDobra: stringToNum(data.dobra),
        iznosUsluga: stringToNum(data.usluge),
        iznosSkladiste: stringToNum(data.skladiste),
        iznosDobraHRK: stringToNum(data.dobraHRK),
        iznosUslugaHRK: stringToNum(data.uslugeHRK),
        iznosSkladisteHRK: stringToNum(data.skladisteHRK),
        skladiste: allSkladiste,
      };
      console.log(allSkladiste, obj);
      handlePostRequest(
        `${process.env.REACT_APP_HOST_URL}/incomingInvoices/add`,
        [obj],
        currentUser.token
      )
        .then(function (response) {
          if (response.data.error) {
            setStatus({
              state: true,
              error: "Račun s tim brojem već postoji",
              message: "",
              loading: false,
            });
          } else {
            const broj = response.data.broj
              ? response.data.broj
              : response.data.status;
            setStatus({
              state: true,
              loading: true,
              message: `Račun ${broj} spremljen`,
              error: "",
            });
          }
          // setAddedFlag(Math.random());
          setTimeout(() => {
            setStatus({
              state: true,
              message: "",
              error: "",
              loading: false,
            });
            reset();
            resetP();
            setProizvodi([]);
            // resetForm();
          }, 2000);
          //set flag invoice saved, settimeout 2s and rerender
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const submitP = (data) => {
    const obj = addIncomingProduct(data, proizvodi, getValues("valuta").val);
    const arr = [...obj.products, obj.exists ? obj.exists : obj.product];
    const total = arr.reduce((a, b) => ({
      pdvPrice: a.pdvPrice + b.pdvPrice,
    }));
    setValue("skladiste", formatCurrency(total.pdvPrice));
    setProizvodi(arr);
    console.log(arr, obj, total);
    //handleChangeCurrency(getValues("valuta").label, new Date(), arr);
    /*  setProizvodi(() => [
      ...obj.products,
      obj.exists ? obj.exists : obj.product,
    ]); */
  };

  return (
    <div className="container">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>Ulazni račun</h4>
          </div>
          <hr></hr>
          <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Odabir klijenta</label>
              </div>
              <div className="form-group col-lg-3 col-12 my-0">
                <Controller
                  name="klijent"
                  control={control}
                  id="klijent"
                  rules={{ required: "Izaberi klijenta" }}
                  placeholder="Izaberi klijenta"
                  menuPlacement="auto"
                  options={clients?.options}
                  noOptionsMessage={() => "Klijent ne postoji"}
                  as={CustomSelect}
                />

                {errors.klijent && (
                  <span className="error">{errors.klijent.message}</span>
                )}
              </div>
              <div className="form-group mx-auto my-2 mx-lg-0 my-lg-0">
                <Button
                  type="button"
                  classnames="btn-dim btn-outline-secondary form-control-lg custNewInvBtn"
                  onClick={() =>
                    setModal((modals) => ({ ...modals, addClient: true }))
                  }
                >
                  Dodaj novog klijenta
                </Button>
              </div>
            </div>

            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Datum</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  name="datumRacuna"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY HH:mm"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Izvršeno</label>
              </div>
              <div className="form-group col-12 col-lg-2 my-0">
                <Controller
                  control={control}
                  name="izvrsenoOd"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
              <label className="align-self-center m-auto mx-lg-0">-</label>
              <div className="form-group col-12 col-lg-2">
                <Controller
                  control={control}
                  name="izvrsenoDo"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Broj računa</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  name="broj"
                  control={control}
                  id="broj"
                  rules={{ required: "Unesi broj računa" }}
                  placeholder="Unesi broj računa"
                  classnames="customInput form-control-lg"
                  as={Input}
                />

                {errors.broj && (
                  <span className="error">{errors.broj.message}</span>
                )}
              </div>
            </div>

            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Odaberi valutu</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  name="valuta"
                  defaultValue={{ label: "HRK", value: "HRK", val: 1 }}
                  control={control}
                  id="valuta"
                  rules={{ required: "Izaberi valutu" }}
                  render={(props) => {
                    return (
                      <CustomSelect
                        {...props}
                        onChange={(e) => {
                          setValue("valuta", e);
                          handleChangeCurrency(e.label, new Date(), proizvodi);
                        }}
                        placeholder="Izaberi valutu"
                        menuPlacement="auto"
                        options={[
                          { label: "HRK", value: "HRK", val: 1 },
                          { label: "EUR", value: "EUR" },
                          { label: "USD", value: "USD" },
                          { label: "GBP", value: "GBP" },
                        ]}
                        noOptionsMessage={() => "Valuta ne postoji"}
                      />
                    );
                  }}
                />

                {errors.valuta && (
                  <span className="error">{errors.valuta.message}</span>
                )}
              </div>
            </div>

            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Ukupan iznos</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  name="ukupanIznos"
                  rules={{ required: "Unesi ukupan iznos računa" }}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Iznos"
                      valuta={watch("valuta").label}
                      customInput={InputValue}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                {errors.ukupanIznos && (
                  <span className="error">{errors.ukupanIznos.message}</span>
                )}
              </div>
            </div>

            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="mb-0">Dobra</label>
              </div>
              <div className="form-group col-12 col-lg-3 mb-0">
                <Controller
                  control={control}
                  name="dobra"
                  rules={{
                    validate: {
                      required: (value) => {
                        if (
                          !value &&
                          getValues("usluge").length < 1 &&
                          getValues("skladiste").length < 1
                        )
                          return "Unesi iznos";
                        return true;
                      },
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Iznos"
                      valuta={watch("valuta").label}
                      customInput={InputValue}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      onBlur={onBlur}
                      onChange={(e) => {
                        const currency = getValues("valuta").val;
                        setValue(
                          "dobraHRK",
                          currency * stringToNum(e.target.value)
                        );
                        onChange(e);
                      }}
                      value={value}
                    />
                  )}
                />

                {errors.dobra && (
                  <span className="error">{errors.dobra.message}</span>
                )}
              </div>
              <p className="col-12 col-lg-2 d-flex align-items-center justify-content-lg-start text-muted mb-0">
                = {watch("dobraHRK") && formatCurrency(getValues("dobraHRK"))}{" "}
                HRK
              </p>
            </div>
            <div className="form-group row d-flex mb-3">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="mb-0">Usluge</label>
              </div>
              <div className="form-group col-12 col-lg-3 mb-0">
                <Controller
                  control={control}
                  name="usluge"
                  rules={{
                    validate: {
                      required: (value) => {
                        if (
                          !value &&
                          getValues("dobra").length < 1 &&
                          getValues("skladiste").length < 1
                        )
                          return "Unesi iznos";
                        return true;
                      },
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Iznos"
                      valuta={watch("valuta").label}
                      customInput={InputValue}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      onBlur={onBlur}
                      onChange={(e) => {
                        const currency = getValues("valuta").val;
                        setValue(
                          "uslugeHRK",
                          currency * stringToNum(e.target.value)
                        );
                        onChange(e);
                      }}
                      value={value}
                    />
                  )}
                />

                {errors.usluge && (
                  <span className="error">{errors.usluge.message}</span>
                )}
              </div>
              <p className="col-12 col-lg-2 d-flex align-items-center justify-content-lg-start text-muted mb-0">
                = {watch("uslugeHRK") && formatCurrency(getValues("uslugeHRK"))}{" "}
                HRK
              </p>
            </div>
            <div className="form-group row d-flex mb-3 mb-0">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="mb-0">Skladište</label>
              </div>
              <div className="form-group col-12 col-lg-3 mb-0">
                <Controller
                  control={control}
                  name="skladiste"
                  rules={{
                    validate: {
                      required: (value) => {
                        if (
                          !value &&
                          getValues("usluge").length < 1 &&
                          getValues("dobra").length < 1
                        )
                          return "Unesi iznos";
                        return true;
                      },
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Iznos"
                      valuta={watch("valuta").label}
                      customInput={InputValue}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      onBlur={onBlur}
                      onChange={(e) => {
                        const currency = getValues("valuta").val;
                        setValue(
                          "skladisteHRK",
                          currency * stringToNum(e.target.value)
                        );
                        onChange(e);
                      }}
                      value={value}
                    />
                  )}
                />

                {errors.skladiste && (
                  <span className="error">{errors.skladiste.message}</span>
                )}
              </div>
              <p className="col-12 col-lg-2 d-flex align-items-center justify-content-lg-start text-muted mb-0">
                ={" "}
                {watch("skladisteHRK") &&
                  formatCurrency(getValues("skladisteHRK"))}{" "}
                HRK
              </p>
            </div>
          </form>
          <hr></hr>
          {status.state && status.message.length > 0 && (
            <div style={{ maxWidth: 800 }} className="mx-auto">
              <div
                className="alert alert-dismissible alert-icon alert-fill alert-success"
                role="alert"
              >
                <em className="icon ni ni-check-circle"></em>
                {status.message.length > 0 && status.message}
                <button
                  className="close"
                  data-dismiss="alert"
                  type="button"
                  onClick={() => setStatus({ state: false, message: "" })}
                ></button>
              </div>
            </div>
          )}
          {status.state && status.error.length > 0 && (
            <div style={{ maxWidth: 800 }} className="mx-auto">
              <div
                className="alert alert-dismissible alert-icon alert-fill alert-danger"
                role="alert"
              >
                <em className="icon ni ni-cross-circle"></em>
                {status.error.length > 0 && status.error}
                <button
                  className="close"
                  data-dismiss="alert"
                  type="button"
                  onClick={() => setStatus({ state: false, error: "" })}
                ></button>
              </div>
            </div>
          )}
          <form className="form" onSubmit={handleSubmitP(submitP)}>
            {watch("skladiste").length > 0 ? (
              <>
                <div className="form-group row d-flex align-items-center my-5">
                  <h4 className="col-2 form-control-label d-flex justify-content-lg-center">
                    Proizvodi
                  </h4>
                </div>
                <div className="form-group row d-flex align-items-center mb-3">
                  <div className="col-12 col-lg-1 space"></div>
                  <div className="form-group col-12 col-lg-2 px-lg-0 m-lg-0">
                    <Controller
                      name="proizvod"
                      control={controlP}
                      id="selectClient"
                      rules={{ required: "Odaberi proizvod" }}
                      render={({ onChange, onBlur, value, ref }) => {
                        return (
                          <CustomSelect
                            ref={ref}
                            placeholder="Izaberi proizvod"
                            onBlur={onBlur}
                            value={value}
                            onChange={(e) => {
                              setValueP("cijena", Number(e.value.cijena));
                              onChange(e);
                            }}
                            menuPlacement="auto"
                            options={products?.options}
                            noOptionsMessage={() => "Proizvod ne postoji"}
                          />
                        );
                      }}
                    />
                    {errorsP.proizvod && (
                      <span className="error">{errorsP.proizvod.message}</span>
                    )}
                  </div>
                  <div className="orTxt col-12 col-lg-1">
                    <p className="text-center text-lg-start">ili</p>
                  </div>
                  <div className=" mx-auto my-2 mx-lg-0 my-lg-0">
                    <Button
                      classnames="btn-dim btn-outline-secondary form-control-lg custNewInvBtn"
                      type="button"
                      onClick={() =>
                        setModal((modals) => ({
                          ...modals,
                          addProduct: true,
                        }))
                      }
                    >
                      Dodaj novi proizvod
                    </Button>
                  </div>
                  <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                    <Controller
                      control={controlP}
                      name="kolicina"
                      rules={{ required: "Unesi količinu" }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Količina"
                          customInput={Input}
                          fixedDecimalScale
                          inputMode="decimal"
                          decimalScale={2}
                          decimalSeparator={","}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />

                    {errorsP.kolicina && (
                      <span className="error">{errorsP.kolicina.message}</span>
                    )}
                  </div>
                  <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                    <Controller
                      control={controlP}
                      name="cijena"
                      rules={{ required: "Unesi cijenu" }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Cijena"
                          customInput={Input}
                          fixedDecimalScale
                          decimalScale={2}
                          inputMode="decimal"
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />

                    {errorsP.cijena && (
                      <span className="error">{errorsP.cijena.message}</span>
                    )}
                  </div>
                  <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                    <Controller
                      control={controlP}
                      name="popust"
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Popust"
                          customInput={Input}
                          fixedDecimalScale
                          decimalScale={2}
                          inputMode="decimal"
                          decimalSeparator={","}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                    <Button
                      type="button"
                      classnames="btn-dim btn-outline-secondary form-control-lg d-flex mx-auto "
                      onClick={handleSubmitP(submitP)}
                    >
                      Potvrdi
                    </Button>
                  </div>
                </div>
                <br></br>
                {proizvodi.length > 0 ? (
                  <Table
                    disableButtons={true}
                    columns={cols}
                    data={proizvodi}
                  />
                ) : null}
                <hr></hr>
              </>
            ) : null}

            <div className="form-group row d-flex align-items-center justify-content-end mb-3">
              <div className="col-12 col-lg-2">
                <Button
                  disabled={status.loading}
                  type="button"
                  onClick={handleSubmit(submit)}
                  classnames="btn-dim btn-outline-primary form-control-lg d-flex my-2 mx-auto ml-lg-auto mr-lg-0 my-lg-0 "
                >
                  Spremi račun
                </Button>
              </div>
              <div className="col-12 col-lg-2 my-2 mx-lg-0 my-lg-0">
                <Button
                  disabled={status.loading}
                  classnames="btn-dim btn-outline-danger form-control-lg d-flex my-2 mx-auto ml-lg-0 mr-lg-auto my-lg-0 "
                >
                  Izađi bez spremanja
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <NewClient
        from="newIncoming"
        open={modals.addClient}
        clients={clients}
        setClient={setValue}
        toggle={() =>
          setModal((modals) => ({ ...modals, addClient: !modals.addClient }))
        }
      />
      <NewProduct
        setProduct={setValueP}
        from="newIncoming"
        open={modals.addProduct}
        products={products}
        toggle={() =>
          setModal((modals) => ({ ...modals, addProduct: !modals.addProduct }))
        }
      />

      <Modal open={loadCurrency}>
        <div style={{ marginTop: "20%" }}>
          <div className="d-flex justify-content-center">
            <div
              tabIndex="-1"
              className="spinner-border text-primary"
              role="status"
              style={{ width: "5em", height: "5em" }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NewIncoming;

import { forwardRef } from "react";

const Input = forwardRef((props, ref) => {
  return (
    <input
      {...props}
      ref={ref}
      className={`form-control ${props.classnames}`}
    />
  );
});

export default Input;

export const djelatnosti = [
  {
    name: "Uzgoj žitarica (osim riže), mahunarki i uljanog sjemenja",
    sifra: "01.11",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj riže",
    sifra: "01.12",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj povrća, dinja i lubenica, korjenastog i gomoljastog povrća",
    sifra: "01.13",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj šećerne trske",
    sifra: "01.14",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj duhana",
    sifra: "01.15",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj predivog bilja",
    sifra: "01.16",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj ostalih jednogodišnjih usjeva",
    sifra: "01.19",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.1",
  },
  {
    name: "Uzgoj grožđa",
    sifra: "01.21",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj tropskog i suptropskog voća",
    sifra: "01.22",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj agruma",
    sifra: "01.23",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj jezgričavog i koštuničavog voća",
    sifra: "01.24",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj bobičastog, orašastog i ostalog voća",
    sifra: "01.25",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj uljanih plodova",
    sifra: "01.26",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj usjeva za pripremanje napitaka",
    sifra: "01.27",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name:
      "Uzgoj bilja za uporabu u farmaciji, aromatskog, začinskog i ljekovitog bilja&nbsp;",
    sifra: "01.28",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj ostalih višegodišnjih usjeva&nbsp;",
    sifra: "01.29",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.2",
  },
  {
    name: "Uzgoj sadnog materijala i ukrasnog bilja",
    sifra: "01.30",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.3",
  },
  {
    name: "Uzgoj muznih krava",
    sifra: "01.41",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj ostalih goveda i bivola",
    sifra: "01.42",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj konja, magaraca, mula i mazgi",
    sifra: "01.43",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj deva i ljama",
    sifra: "01.44",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj ovaca i koza",
    sifra: "01.45",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj svinja",
    sifra: "01.46",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj peradi",
    sifra: "01.47",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Uzgoj ostalih životinja",
    sifra: "01.49",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.4",
  },
  {
    name: "Mješovita proizvodnja",
    sifra: "01.50",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.5",
  },
  {
    name: "Pomoćne djelatnosti za uzgoj usjeva",
    sifra: "01.61",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.6",
  },
  {
    name: "Pomoćne djelatnosti za uzgoj životinja",
    sifra: "01.62",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.6",
  },
  {
    name:
      "Djelatnosti koje se obavljaju nakon žetve usjeva (priprema usjeva za primarna tržišta)",
    sifra: "01.63",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.6",
  },
  {
    name: "Dorada sjemena za sjemenski materijal",
    sifra: "01.64",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.6",
  },
  {
    name: "Lov, stupičarenje i uslužne djelatnosti povezane s njima",
    sifra: "01.70",
    podrucje: "A",
    odjeljak: "01",
    skupina: "01.7",
  },
  {
    name: "Uzgoj šuma i ostale djelatnosti u šumarstvu povezane s njime",
    sifra: "02.10",
    podrucje: "A",
    odjeljak: "02",
    skupina: "02.1",
  },
  {
    name: "Sječa drva",
    sifra: "02.20",
    podrucje: "A",
    odjeljak: "02",
    skupina: "02.2",
  },
  {
    name: "Skupljanje šumskih plodova i proizvoda, osim šumskih sortimenata",
    sifra: "02.30",
    podrucje: "A",
    odjeljak: "02",
    skupina: "02.3",
  },
  {
    name: "Pomoćne usluge u šumarstvu",
    sifra: "02.40",
    podrucje: "A",
    odjeljak: "02",
    skupina: "02.4",
  },
  {
    name: "Morski ribolov",
    sifra: "03.11",
    podrucje: "A",
    odjeljak: "03",
    skupina: "03.1",
  },
  {
    name: "Slatkovodni ribolov",
    sifra: "03.12",
    podrucje: "A",
    odjeljak: "03",
    skupina: "03.1",
  },
  {
    name: "Morska akvakultura",
    sifra: "03.21",
    podrucje: "A",
    odjeljak: "03",
    skupina: "03.2",
  },
  {
    name: "Slatkovodna akvakultura",
    sifra: "03.22",
    podrucje: "A",
    odjeljak: "03",
    skupina: "03.2",
  },
  {
    name: "Vađenje kamenog ugljena",
    sifra: "05.10",
    podrucje: "B",
    odjeljak: "05",
    skupina: "05.1",
  },
  {
    name: "Vađenje lignita",
    sifra: "05.20",
    podrucje: "B",
    odjeljak: "05",
    skupina: "05.2",
  },
  {
    name: "Vađenje sirove nafte",
    sifra: "06.10",
    podrucje: "B",
    odjeljak: "06",
    skupina: "06.1",
  },
  {
    name: "Vađenje prirodnog plina",
    sifra: "06.20",
    podrucje: "B",
    odjeljak: "06",
    skupina: "06.2",
  },
  {
    name: "Vađenje željeznih ruda",
    sifra: "07.10",
    podrucje: "B",
    odjeljak: "07",
    skupina: "07.1",
  },
  {
    name: "Vađenje uranovih i torijevih ruda",
    sifra: "07.21",
    podrucje: "B",
    odjeljak: "07",
    skupina: "07.2",
  },
  {
    name: "Vađenje ostalih ruda obojenih metala",
    sifra: "07.29",
    podrucje: "B",
    odjeljak: "07",
    skupina: "07.2",
  },
  {
    name:
      "Vađenje ukrasnoga kamena i kamena za gradnju, vapnenca, gipsa, krede i škriljevca",
    sifra: "08.11",
    podrucje: "B",
    odjeljak: "08",
    skupina: "08.1",
  },
  {
    name: "Djelatnosti šljunčara i pješčara; vađenje gline i kaolina",
    sifra: "08.12",
    podrucje: "B",
    odjeljak: "08",
    skupina: "08.1",
  },
  {
    name: "Vađenje minerala za kemikalije i gnojiva",
    sifra: "08.91",
    podrucje: "B",
    odjeljak: "08",
    skupina: "08.9",
  },
  {
    name: "Vađenje treseta",
    sifra: "08.92",
    podrucje: "B",
    odjeljak: "08",
    skupina: "08.9",
  },
  {
    name: "Vađenje soli",
    sifra: "08.93",
    podrucje: "B",
    odjeljak: "08",
    skupina: "08.9",
  },
  {
    name: "Vađenje ostalih ruda i kamena, d. n.",
    sifra: "08.99",
    podrucje: "B",
    odjeljak: "08",
    skupina: "08.9",
  },
  {
    name: "Pomoćne djelatnosti za vađenje nafte i prirodnog plina",
    sifra: "09.10",
    podrucje: "B",
    odjeljak: "09",
    skupina: "09.1",
  },
  {
    name: "Pomoćne djelatnosti za ostalo rudarstvo i vađenje",
    sifra: "09.90",
    podrucje: "B",
    odjeljak: "09",
    skupina: "09.9",
  },
  {
    name: "Prerada i konzerviranje mesa",
    sifra: "10.11",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.1",
  },
  {
    name: "Prerada i konzerviranje mesa peradi",
    sifra: "10.12",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.1",
  },
  {
    name: "Proizvodnja proizvoda od mesa i mesa peradi",
    sifra: "10.13",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.1",
  },
  {
    name: "Prerada i konzerviranje riba, rakova i školjki",
    sifra: "10.20",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.2",
  },
  {
    name: "Prerada i konzerviranje krumpira",
    sifra: "10.31",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.3",
  },
  {
    name: "Proizvodnja sokova od voća i povrća",
    sifra: "10.32",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.3",
  },
  {
    name: "Ostala prerada i konzerviranje voća i povrća",
    sifra: "10.39",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.3",
  },
  {
    name: "Proizvodnja ulja i masti",
    sifra: "10.41",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.4",
  },
  {
    name: "Proizvodnja margarina i sličnih jestivih masti",
    sifra: "10.42",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.4",
  },
  {
    name: "Djelatnosti mljekara i proizvođača sira",
    sifra: "10.51",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.5",
  },
  {
    name: "Proizvodnja sladoleda",
    sifra: "10.52",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.5",
  },
  {
    name: "Proizvodnja mlinskih proizvoda",
    sifra: "10.61",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.6",
  },
  {
    name: "Proizvodnja škroba i škrobnih proizvoda",
    sifra: "10.62",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.6",
  },
  {
    name:
      "Proizvodnja kruha; proizvodnja svježih peciva, slastičarskih proizvoda i kolača",
    sifra: "10.71",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.7",
  },
  {
    name:
      "Proizvodnja dvopeka, keksa i srodnih proizvoda; pr. trajnih peciva, slast. proizvoda i kolača",
    sifra: "10.72",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.7",
  },
  {
    name: "Proizvodnja makarona, njoka, kuskusa i slične tjestenine",
    sifra: "10.73",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.7",
  },
  {
    name: "Proizvodnja šećera",
    sifra: "10.81",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name: "Proizvodnja kakao, čokoladnih i bombonskih proizvoda",
    sifra: "10.82",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name: "Prerada čaja i kave",
    sifra: "10.83",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name: "Proizvodnja začina i drugih dodataka hrani",
    sifra: "10.84",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name: "Proizvodnja gotove hrane i jela",
    sifra: "10.85",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name:
      "Proizvodnja homogeniziranih prehrambenih pripravaka i dijetetske hrane",
    sifra: "10.86",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name: "Proizvodnja ostalih prehrambenih proizvoda, d. n.",
    sifra: "10.89",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.8",
  },
  {
    name: "Proizvodnja pripremljene stočne hrane",
    sifra: "10.91",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.9",
  },
  {
    name: "Proizvodnja pripremljene hrane za kućne ljubimce",
    sifra: "10.92",
    podrucje: "C",
    odjeljak: "10",
    skupina: "10.9",
  },
  {
    name: "Destiliranje, pročišćavanje i miješanje alkoholnih pića",
    sifra: "11.01",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name: "Proizvodnja vina od grožđa",
    sifra: "11.02",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name: "Proizvodnja jabukovače i ostalih voćnih vina",
    sifra: "11.03",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name: "Proizvodnja ostalih nedestiliranih fermentiranih pića",
    sifra: "11.04",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name: "Proizvodnja piva",
    sifra: "11.05",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name: "Proizvodnja slada",
    sifra: "11.06",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name:
      "Proizvodnja osvježavajućih napitaka; proizvodnja mineralne i drugih flaširanih voda",
    sifra: "11.07",
    podrucje: "C",
    odjeljak: "11",
    skupina: "11.0",
  },
  {
    name: "Proizvodnja duhanskih proizvoda",
    sifra: "12.00",
    podrucje: "C",
    odjeljak: "12",
    skupina: "12.0",
  },
  {
    name: "Priprema i predenje tekstilnih vlakana",
    sifra: "13.10",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.1",
  },
  {
    name: "Tkanje tekstila",
    sifra: "13.20",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.2",
  },
  {
    name: "Dovršavanje tekstila",
    sifra: "13.30",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.3",
  },
  {
    name: "Proizvodnja pletenih i kukičanih tkanina",
    sifra: "13.91",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name: "Proizvodnja gotovih tekstilnih proizvoda, osim odjeće",
    sifra: "13.92",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name: "Proizvodnja tepiha i sagova",
    sifra: "13.93",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name: "Proizvodnja užadi, konopaca, upletenoga konca i mreža",
    sifra: "13.94",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name:
      "Proizvodnja netkanog tekstila i proizvoda od netkanog tekstila, osim odjeće",
    sifra: "13.95",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name: "Proizvodnja ostaloga tehničkog i industrijskog tekstila",
    sifra: "13.96",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name: "Proizvodnja ostalog tekstila, d. n.",
    sifra: "13.99",
    podrucje: "C",
    odjeljak: "13",
    skupina: "13.9",
  },
  {
    name: "Proizvodnja kožne odjeće",
    sifra: "14.11",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.1",
  },
  {
    name: "Proizvodnja radne odjeće",
    sifra: "14.12",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.1",
  },
  {
    name: "Proizvodnja ostale vanjske odjeće",
    sifra: "14.13",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.1",
  },
  {
    name: "Proizvodnja rublja",
    sifra: "14.14",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.1",
  },
  {
    name: "Proizvodnja ostale odjeće i pribora za odjeću",
    sifra: "14.19",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.1",
  },
  {
    name: "Proizvodnja proizvoda od krzna",
    sifra: "14.20",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.2",
  },
  {
    name: "Proizvodnja pletenih i kukičanih čarapa",
    sifra: "14.31",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.3",
  },
  {
    name: "Proizvodnja ostale pletene i kukičane odjeće",
    sifra: "14.39",
    podrucje: "C",
    odjeljak: "14",
    skupina: "14.3",
  },
  {
    name: "Štavljenje i obrada kože; dorada i bojenje krzna",
    sifra: "15.11",
    podrucje: "C",
    odjeljak: "15",
    skupina: "15.1",
  },
  {
    name:
      "Proizvodnja putnih i ručnih torba i slično, sedlarskih i remenarskih proizvoda",
    sifra: "15.12",
    podrucje: "C",
    odjeljak: "15",
    skupina: "15.1",
  },
  {
    name: "Proizvodnja obuće",
    sifra: "15.20",
    podrucje: "C",
    odjeljak: "15",
    skupina: "15.2",
  },
  {
    name: "Piljenje i blanjanje drva",
    sifra: "16.10",
    podrucje: "C",
    odjeljak: "16",
    skupina: "16.1",
  },
  {
    name: "Proizvodnja furnira i ostalih ploča od drva",
    sifra: "16.21",
    podrucje: "C",
    odjeljak: "16",
    skupina: "16.2",
  },
  {
    name: "Proizvodnja sastavljenog parketa",
    sifra: "16.22",
    podrucje: "C",
    odjeljak: "16",
    skupina: "16.2",
  },
  {
    name: "Proizvodnja ostale građevne stolarije i elemenata",
    sifra: "16.23",
    podrucje: "C",
    odjeljak: "16",
    skupina: "16.2",
  },
  {
    name: "Proizvodnja ambalaže od drva",
    sifra: "16.24",
    podrucje: "C",
    odjeljak: "16",
    skupina: "16.2",
  },
  {
    name:
      "Proizvodnja ostalih proizvoda od drva, proizvoda od pluta, slame i pletarskih materijala",
    sifra: "16.29",
    podrucje: "C",
    odjeljak: "16",
    skupina: "16.2",
  },
  {
    name: "Proizvodnja celuloze",
    sifra: "17.11",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.1",
  },
  {
    name: "Proizvodnja papira i kartona",
    sifra: "17.12",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.1",
  },
  {
    name:
      "Proizvodnja valovitog papira i kartona te ambalaže od papira i kartona",
    sifra: "17.21",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.2",
  },
  {
    name:
      "Proizvodnja robe za kućanstvo i higijenu te toaletnih potrepština od papira",
    sifra: "17.22",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.2",
  },
  {
    name: "Proizvodnja uredskog materijala od papira",
    sifra: "17.23",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.2",
  },
  {
    name: "Proizvodnja zidnih tapeta",
    sifra: "17.24",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.2",
  },
  {
    name: "Proizvodnja ostalih proizvoda od papira i kartona",
    sifra: "17.29",
    podrucje: "C",
    odjeljak: "17",
    skupina: "17.2",
  },
  {
    name: "Tiskanje novina",
    sifra: "18.11",
    podrucje: "C",
    odjeljak: "18",
    skupina: "18.1",
  },
  {
    name: "Ostalo tiskanje",
    sifra: "18.12",
    podrucje: "C",
    odjeljak: "18",
    skupina: "18.1",
  },
  {
    name: "Usluge pripreme za tisak i objavljivanje",
    sifra: "18.13",
    podrucje: "C",
    odjeljak: "18",
    skupina: "18.1",
  },
  {
    name: "Knjigoveške i srodne usluge",
    sifra: "18.14",
    podrucje: "C",
    odjeljak: "18",
    skupina: "18.1",
  },
  {
    name: "Umnožavanje snimljenih zapisa",
    sifra: "18.20",
    podrucje: "C",
    odjeljak: "18",
    skupina: "18.2",
  },
  {
    name: "Proizvodnja proizvoda koksnih peći",
    sifra: "19.10",
    podrucje: "C",
    odjeljak: "19",
    skupina: "19.1",
  },
  {
    name: "Proizvodnja rafiniranih naftnih proizvoda",
    sifra: "19.20",
    podrucje: "C",
    odjeljak: "19",
    skupina: "19.2",
  },
  {
    name: "Proizvodnja industrijskih plinova",
    sifra: "20.11",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja koloranata i pigmenata",
    sifra: "20.12",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja ostalih anorganskih osnovnih kemikalija",
    sifra: "20.13",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja ostalih organskih osnovnih kemikalija",
    sifra: "20.14",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja gnojiva i dušičnih spojeva",
    sifra: "20.15",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja plastike u primarnim oblicima",
    sifra: "20.16",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja sintetičkoga kaučuka u primarnim oblicima",
    sifra: "20.17",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.1",
  },
  {
    name: "Proizvodnja pesticida i drugih agrokemijskih proizvoda",
    sifra: "20.20",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.2",
  },
  {
    name: "Proizvodnja boja, lakova i sličnih premaza, grafičkih boja i kitova",
    sifra: "20.30",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.3",
  },
  {
    name:
      "Proizvodnja sapuna i deterdženata, sredstava za čišćenje i poliranje",
    sifra: "20.41",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.4",
  },
  {
    name: "Proizvodnja parfema i toaletno-kozmetičkih preparata",
    sifra: "20.42",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.4",
  },
  {
    name: "Proizvodnja eksploziva",
    sifra: "20.51",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.5",
  },
  {
    name: "Proizvodnja ljepila",
    sifra: "20.52",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.5",
  },
  {
    name: "Proizvodnja eteričnih ulja",
    sifra: "20.53",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.5",
  },
  {
    name: "Proizvodnja ostalih kemijskih proizvoda, d. n.",
    sifra: "20.59",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.5",
  },
  {
    name: "Proizvodnja umjetnih vlakana",
    sifra: "20.60",
    podrucje: "C",
    odjeljak: "20",
    skupina: "20.6",
  },
  {
    name: "Proizvodnja osnovnih farmaceutskih proizvoda",
    sifra: "21.10",
    podrucje: "C",
    odjeljak: "21",
    skupina: "21.1",
  },
  {
    name: "Proizvodnja farmaceutskih pripravaka",
    sifra: "21.20",
    podrucje: "C",
    odjeljak: "21",
    skupina: "21.2",
  },
  {
    name:
      "Proizvodnja vanjskih i unutrašnjih guma za vozila; protektiranje vanjskih guma",
    sifra: "22.11",
    podrucje: "C",
    odjeljak: "22",
    skupina: "22.1",
  },
  {
    name: "Proizvodnja ostalih proizvoda od gume",
    sifra: "22.19",
    podrucje: "C",
    odjeljak: "22",
    skupina: "22.1",
  },
  {
    name: "Proizvodnja ploča, listova, cijevi i profila od plastike",
    sifra: "22.21",
    podrucje: "C",
    odjeljak: "22",
    skupina: "22.2",
  },
  {
    name: "Proizvodnja ambalaže od plastike",
    sifra: "22.22",
    podrucje: "C",
    odjeljak: "22",
    skupina: "22.2",
  },
  {
    name: "Proizvodnja proizvoda od plastike za građevinarstvo",
    sifra: "22.23",
    podrucje: "C",
    odjeljak: "22",
    skupina: "22.2",
  },
  {
    name: "Proizvodnja ostalih proizvoda od plastike",
    sifra: "22.29",
    podrucje: "C",
    odjeljak: "22",
    skupina: "22.2",
  },
  {
    name: "Proizvodnja ravnog stakla",
    sifra: "23.11",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.1",
  },
  {
    name: "Oblikovanje i obrada ravnog stakla",
    sifra: "23.12",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.1",
  },
  {
    name: "Proizvodnja šupljeg stakla",
    sifra: "23.13",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.1",
  },
  {
    name: "Proizvodnja staklenih vlakana",
    sifra: "23.14",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.1",
  },
  {
    name:
      "Proizvodnja i obrada ostalog stakla uključujući tehničku robu od stakla",
    sifra: "23.19",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.1",
  },
  {
    name: "Proizvodnja vatrostalnih proizvoda",
    sifra: "23.20",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.2",
  },
  {
    name: "Proizvodnja keramičkih pločica i ploča",
    sifra: "23.31",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.3",
  },
  {
    name:
      "Proizvodnja opeke, crijepa i ostalih proizvoda od pečene gline za građevinarstvo",
    sifra: "23.32",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.3",
  },
  {
    name: "Proizvodnja keramičkih proizvoda za kućanstvo i ukrasnih predmeta",
    sifra: "23.41",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.4",
  },
  {
    name: "Proizvodnja sanitarne keramike",
    sifra: "23.42",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.4",
  },
  {
    name: "Proizvodnja keramičkih izolatora i izolacijskog pribora",
    sifra: "23.43",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.4",
  },
  {
    name: "Proizvodnja ostalih tehničkih proizvoda od keramike",
    sifra: "23.44",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.4",
  },
  {
    name: "Proizvodnja ostalih proizvoda od keramike",
    sifra: "23.49",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.4",
  },
  {
    name: "Proizvodnja cementa",
    sifra: "23.51",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.5",
  },
  {
    name: "Proizvodnja vapna i gipsa",
    sifra: "23.52",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.5",
  },
  {
    name: "Proizvodnja proizvoda od betona za građevinarstvo",
    sifra: "23.61",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.6",
  },
  {
    name: "Proizvodnja proizvoda od gipsa za građevinarstvo",
    sifra: "23.62",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.6",
  },
  {
    name: "Proizvodnja gotove betonske smjese",
    sifra: "23.63",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.6",
  },
  {
    name: "Proizvodnja žbuke",
    sifra: "23.64",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.6",
  },
  {
    name: "Proizvodnja fibro-cementa",
    sifra: "23.65",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.6",
  },
  {
    name: "Proizvodnja ostalih proizvoda od betona, cementa i gipsa",
    sifra: "23.69",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.6",
  },
  {
    name: "Rezanje, oblikovanje i obrada kamena",
    sifra: "23.70",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.7",
  },
  {
    name: "Proizvodnja brusnih proizvoda",
    sifra: "23.91",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.9",
  },
  {
    name: "Proizvodnja ostalih nemetalnih mineralnih proizvoda, d. n.",
    sifra: "23.99",
    podrucje: "C",
    odjeljak: "23",
    skupina: "23.9",
  },
  {
    name: "Proizvodnja sirovog željeza, čelika i ferolegura",
    sifra: "24.10",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.1",
  },
  {
    name: "Proizvodnja čeličnih cijevi i pribora",
    sifra: "24.20",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.2",
  },
  {
    name: "Hladno vučenje šipki",
    sifra: "24.31",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.3",
  },
  {
    name: "Hladno valjanje uskih vrpci",
    sifra: "24.32",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.3",
  },
  {
    name: "Hladno oblikovanje i profiliranje",
    sifra: "24.33",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.3",
  },
  {
    name: "Hladno vučenje žice",
    sifra: "24.34",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.3",
  },
  {
    name: "Proizvodnja plemenitih metala",
    sifra: "24.41",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.4",
  },
  {
    name: "Proizvodnja aluminija",
    sifra: "24.42",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.4",
  },
  {
    name: "Proizvodnja olova, cinka i kositra",
    sifra: "24.43",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.4",
  },
  {
    name: "Proizvodnja bakra",
    sifra: "24.44",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.4",
  },
  {
    name: "Proizvodnja ostalih obojenih metala",
    sifra: "24.45",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.4",
  },
  {
    name: "Obrada nuklearnoga goriva",
    sifra: "24.46",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.4",
  },
  {
    name: "Lijevanje željeza",
    sifra: "24.51",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.5",
  },
  {
    name: "Lijevanje čelika",
    sifra: "24.52",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.5",
  },
  {
    name: "Lijevanje lakih metala",
    sifra: "24.53",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.5",
  },
  {
    name: "Lijevanje ostalih obojenih metala",
    sifra: "24.54",
    podrucje: "C",
    odjeljak: "24",
    skupina: "24.5",
  },
  {
    name: "Proizvodnja metalnih konstrukcija i njihovih dijelova",
    sifra: "25.11",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.1",
  },
  {
    name: "Proizvodnja vrata i prozora od metala",
    sifra: "25.12",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.1",
  },
  {
    name: "Proizvodnja radijatora i kotlova za centralno grijanje",
    sifra: "25.21",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.2",
  },
  {
    name: "Proizvodnja ostalih metalnih cisterni, rezervoara i sličnih posuda",
    sifra: "25.29",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.2",
  },
  {
    name:
      "Proizvodnja parnih kotlova, osim kotlova za centralno grijanje toplom vodom",
    sifra: "25.30",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.3",
  },
  {
    name: "Proizvodnja oružja i streljiva",
    sifra: "25.40",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.4",
  },
  {
    name: "Kovanje, prešanje, štancanje i valjanje metala; metalurgija praha",
    sifra: "25.50",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.5",
  },
  {
    name: "Obrada i prevlačenje metala",
    sifra: "25.61",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.6",
  },
  {
    name: "Strojna obrada metala",
    sifra: "25.62",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.6",
  },
  {
    name: "Proizvodnja sječiva",
    sifra: "25.71",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.7",
  },
  {
    name: "Proizvodnja brava i okova",
    sifra: "25.72",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.7",
  },
  {
    name: "Proizvodnja alata",
    sifra: "25.73",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.7",
  },
  {
    name: "Proizvodnja čeličnih bačava i sličnih posuda",
    sifra: "25.91",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.9",
  },
  {
    name: "Proizvodnja ambalaže od lakih metala",
    sifra: "25.92",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.9",
  },
  {
    name: "Proizvodnja proizvoda od žice, lanaca i opruga",
    sifra: "25.93",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.9",
  },
  {
    name: "Proizvodnja zakovica i vijčane robe",
    sifra: "25.94",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.9",
  },
  {
    name: "Proizvodnja ostalih gotovih proizvoda od metala, d. n.",
    sifra: "25.99",
    podrucje: "C",
    odjeljak: "25",
    skupina: "25.9",
  },
  {
    name: "Proizvodnja elektroničkih komponenata",
    sifra: "26.11",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.1",
  },
  {
    name: "Proizvodnja punih elektroničkih ploča",
    sifra: "26.12",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.1",
  },
  {
    name: "Proizvodnja računala i periferne opreme",
    sifra: "26.20",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.2",
  },
  {
    name: "Proizvodnja komunikacijske opreme",
    sifra: "26.30",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.3",
  },
  {
    name: "Proizvodnja elektroničkih uređaja za široku potrošnju",
    sifra: "26.40",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.4",
  },
  {
    name:
      "Proizvodnja instrumenata i aparata za mjerenje, ispitivanje i navigaciju",
    sifra: "26.51",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.5",
  },
  {
    name: "Proizvodnja satova",
    sifra: "26.52",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.5",
  },
  {
    name:
      "Proizvodnja opreme za zračenje, elektromedicinske i elektroterapeutske opreme",
    sifra: "26.60",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.6",
  },
  {
    name: "Proizvodnja optičkih instrumenata i fotografske opreme",
    sifra: "26.70",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.7",
  },
  {
    name: "Proizvodnja magnetskih i optičkih medija",
    sifra: "26.80",
    podrucje: "C",
    odjeljak: "26",
    skupina: "26.8",
  },
  {
    name: "Proizvodnja elektromotora, generatora i transformatora",
    sifra: "27.11",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.1",
  },
  {
    name: "Proizvodnja uređaja za distribuciju i kontrolu električne energije",
    sifra: "27.12",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.1",
  },
  {
    name: "Proizvodnja baterija i akumulatora",
    sifra: "27.20",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.2",
  },
  {
    name: "Proizvodnja kablova od optičkih vlakana",
    sifra: "27.31",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.3",
  },
  {
    name: "Proizvodnja ostalih elektroničkih i električnih žica i kablova",
    sifra: "27.32",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.3",
  },
  {
    name: "Proizvodnja elektroinstalacijskog materijala",
    sifra: "27.33",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.3",
  },
  {
    name: "Proizvodnja električne opreme za rasvjetu",
    sifra: "27.40",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.4",
  },
  {
    name: "Proizvodnja električnih aparata za kućanstvo",
    sifra: "27.51",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.5",
  },
  {
    name: "Proizvodnja neelektričnih aparata za kućanstvo",
    sifra: "27.52",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.5",
  },
  {
    name: "Proizvodnja ostale električne opreme",
    sifra: "27.90",
    podrucje: "C",
    odjeljak: "27",
    skupina: "27.9",
  },
  {
    name:
      "Proizvodnja motora i turbina, osim motora za zrakoplove i motorna vozila",
    sifra: "28.11",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.1",
  },
  {
    name: "Proizvodnja hidrauličnih pogonskih uređaja",
    sifra: "28.12",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.1",
  },
  {
    name: "Proizvodnja ostalih crpki i kompresora",
    sifra: "28.13",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.1",
  },
  {
    name: "Proizvodnja ostalih slavina i ventila",
    sifra: "28.14",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.1",
  },
  {
    name:
      "Proizvodnja ležajeva, prijenosnika te prijenosnih i pogonskih elemenata",
    sifra: "28.15",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.1",
  },
  {
    name: "Proizvodnja peći i plamenika",
    sifra: "28.21",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.2",
  },
  {
    name: "Proizvodnja uređaja za dizanje i prenošenje",
    sifra: "28.22",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.2",
  },
  {
    name:
      "Proizvodnja uredskih strojeva i opreme (osim proizvodnje računala i periferne opreme)",
    sifra: "28.23",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.2",
  },
  {
    name: "Proizvodnja mehaniziranoga ručnog alata",
    sifra: "28.24",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.2",
  },
  {
    name: "Proizvodnja rashladne i ventilacijske opreme, osim za kućanstvo",
    sifra: "28.25",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.2",
  },
  {
    name: "Proizvodnja ostalih strojeva za opće namjene, d. n.",
    sifra: "28.29",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.2",
  },
  {
    name: "Proizvodnja strojeva za poljoprivredu i šumarstvo",
    sifra: "28.30",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.3",
  },
  {
    name: "Proizvodnja strojeva za obradu metala",
    sifra: "28.41",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.4",
  },
  {
    name: "Proizvodnja ostalih alatnih strojeva",
    sifra: "28.49",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.4",
  },
  {
    name: "Proizvodnja strojeva za metalurgiju",
    sifra: "28.91",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja strojeva za rudnike, kamenolome i građevinarstvo",
    sifra: "28.92",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja strojeva za industriju hrane, pića i duhana",
    sifra: "28.93",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja strojeva za industriju tekstila, odjeće i kože",
    sifra: "28.94",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja strojeva za industriju papira i kartona",
    sifra: "28.95",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja strojeva za plastiku i gumu",
    sifra: "28.96",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja ostalih strojeva za posebne namjene, d. n.",
    sifra: "28.99",
    podrucje: "C",
    odjeljak: "28",
    skupina: "28.9",
  },
  {
    name: "Proizvodnja motornih vozila",
    sifra: "29.10",
    podrucje: "C",
    odjeljak: "29",
    skupina: "29.1",
  },
  {
    name: "Proizvodnja karoserija za motorna vozila, prikolica i poluprikolica",
    sifra: "29.20",
    podrucje: "C",
    odjeljak: "29",
    skupina: "29.2",
  },
  {
    name: "Proizvodnja električne i elektroničke opreme za motorna vozila",
    sifra: "29.31",
    podrucje: "C",
    odjeljak: "29",
    skupina: "29.3",
  },
  {
    name: "Proizvodnja ostalih dijelova i pribora za motorna vozila",
    sifra: "29.32",
    podrucje: "C",
    odjeljak: "29",
    skupina: "29.3",
  },
  {
    name: "Gradnja brodova i plutajućih objekata",
    sifra: "30.11",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.1",
  },
  {
    name: "Gradnja čamaca za razonodu i sportskih čamaca",
    sifra: "30.12",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.1",
  },
  {
    name: "Proizvodnja željezničkih lokomotiva i tračničkih vozila",
    sifra: "30.20",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.2",
  },
  {
    name:
      "Proizvodnja zrakoplova i svemirskih letjelica te srodnih prijevoznih sredstava i opreme",
    sifra: "30.30",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.3",
  },
  {
    name: "Proizvodnja vojnih borbenih vozila",
    sifra: "30.40",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.4",
  },
  {
    name: "Proizvodnja motocikala",
    sifra: "30.91",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.9",
  },
  {
    name: "Proizvodnja bicikala i invalidskih kolica",
    sifra: "30.92",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.9",
  },
  {
    name: "Proizvodnja ostalih prijevoznih sredstava, d. n.",
    sifra: "30.99",
    podrucje: "C",
    odjeljak: "30",
    skupina: "30.9",
  },
  {
    name: "Proizvodnja namještaja za poslovne i prodajne prostore",
    sifra: "31.01",
    podrucje: "C",
    odjeljak: "31",
    skupina: "31.0",
  },
  {
    name: "Proizvodnja kuhinjskog namještaja",
    sifra: "31.02",
    podrucje: "C",
    odjeljak: "31",
    skupina: "31.0",
  },
  {
    name: "Proizvodnja madraca",
    sifra: "31.03",
    podrucje: "C",
    odjeljak: "31",
    skupina: "31.0",
  },
  {
    name: "Proizvodnja ostalog namještaja",
    sifra: "31.09",
    podrucje: "C",
    odjeljak: "31",
    skupina: "31.0",
  },
  {
    name: "Proizvodnja novca",
    sifra: "32.11",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.1",
  },
  {
    name: "Proizvodnja nakita i srodnih proizvoda",
    sifra: "32.12",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.1",
  },
  {
    name: "Proizvodnja imitacije nakita (bižuterije) i srodnih proizvoda",
    sifra: "32.13",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.1",
  },
  {
    name: "Proizvodnja glazbenih instrumenata",
    sifra: "32.20",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.2",
  },
  {
    name: "Proizvodnja sportske opreme",
    sifra: "32.30",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.3",
  },
  {
    name: "Proizvodnja igara i igračaka",
    sifra: "32.40",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.4",
  },
  {
    name: "Proizvodnja medicinskih i stomatoloških instrumenata i pribora",
    sifra: "32.50",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.5",
  },
  {
    name: "Proizvodnja metla i četaka",
    sifra: "32.91",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.9",
  },
  {
    name: "Ostala prerađivačka industrija, d. n.",
    sifra: "32.99",
    podrucje: "C",
    odjeljak: "32",
    skupina: "32.9",
  },
  {
    name: "Popravak proizvoda od metala",
    sifra: "33.11",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak strojeva",
    sifra: "33.12",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak elektroničke i optičke opreme",
    sifra: "33.13",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak električne opreme",
    sifra: "33.14",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak i održavanje brodova i čamaca",
    sifra: "33.15",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak i održavanje zrakoplova i svemirskih letjelica",
    sifra: "33.16",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak i održavanje ostalih prijevoznih sredstava",
    sifra: "33.17",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Popravak ostale opreme",
    sifra: "33.19",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.1",
  },
  {
    name: "Instaliranje industrijskih strojeva i opreme",
    sifra: "33.20",
    podrucje: "C",
    odjeljak: "33",
    skupina: "33.2",
  },
  {
    name: "Proizvodnja električne energije",
    sifra: "35.11",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.1",
  },
  {
    name: "Prijenos električne energije",
    sifra: "35.12",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.1",
  },
  {
    name: "Distribucija električne energije",
    sifra: "35.13",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.1",
  },
  {
    name: "Trgovina električnom energijom",
    sifra: "35.14",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.1",
  },
  {
    name: "Proizvodnja plina",
    sifra: "35.21",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.2",
  },
  {
    name: "Distribucija plinovitih goriva distribucijskom mrežom",
    sifra: "35.22",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.2",
  },
  {
    name: "Trgovina plinom distribucijskom mrežom",
    sifra: "35.23",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.2",
  },
  {
    name: "Opskrba parom i klimatizacija",
    sifra: "35.30",
    podrucje: "D",
    odjeljak: "35",
    skupina: "35.3",
  },
  {
    name: "Skupljanje, pročišćavanje i opskrba vodom",
    sifra: "36.00",
    podrucje: "E",
    odjeljak: "36",
    skupina: "36.0",
  },
  {
    name: "Uklanjanje otpadnih voda",
    sifra: "37.00",
    podrucje: "E",
    odjeljak: "37",
    skupina: "37.0",
  },
  {
    name: "Skupljanje neopasnog otpada",
    sifra: "38.11",
    podrucje: "E",
    odjeljak: "38",
    skupina: "38.1",
  },
  {
    name: "Skupljanje opasnog otpada",
    sifra: "38.12",
    podrucje: "E",
    odjeljak: "38",
    skupina: "38.1",
  },
  {
    name: "Obrada i zbrinjavanje neopasnog otpada",
    sifra: "38.21",
    podrucje: "E",
    odjeljak: "38",
    skupina: "38.2",
  },
  {
    name: "Obrada i zbrinjavanje opasnog otpada",
    sifra: "38.22",
    podrucje: "E",
    odjeljak: "38",
    skupina: "38.2",
  },
  {
    name: "Rastavljanje olupina",
    sifra: "38.31",
    podrucje: "E",
    odjeljak: "38",
    skupina: "38.3",
  },
  {
    name: "Oporaba posebno izdvojenih materijala",
    sifra: "38.32",
    podrucje: "E",
    odjeljak: "38",
    skupina: "38.3",
  },
  {
    name:
      "Djelatnosti sanacije okoliša te ostale djelatnosti gospodarenja otpadom",
    sifra: "39.00",
    podrucje: "E",
    odjeljak: "39",
    skupina: "39.0",
  },
  {
    name: "Organizacija izvedbe projekata za zgrade",
    sifra: "41.10",
    podrucje: "F",
    odjeljak: "41",
    skupina: "41.1",
  },
  {
    name: "Gradnja stambenih i nestambenih zgrada",
    sifra: "41.20",
    podrucje: "F",
    odjeljak: "41",
    skupina: "41.2",
  },
  {
    name: "Gradnja cesta i autocesta",
    sifra: "42.11",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.1",
  },
  {
    name: "Gradnja željezničkih pruga i podzemnih željeznica",
    sifra: "42.12",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.1",
  },
  {
    name: "Gradnja mostova i tunela",
    sifra: "42.13",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.1",
  },
  {
    name: "Gradnja cjevovoda za tekućine i plinove",
    sifra: "42.21",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.2",
  },
  {
    name: "Gradnja vodova za električnu struju i telekomunikacije",
    sifra: "42.22",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.2",
  },
  {
    name: "Gradnja vodnih građevina",
    sifra: "42.91",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.9",
  },
  {
    name: "Gradnja ostalih građevina niskogradnje, d. n.",
    sifra: "42.99",
    podrucje: "F",
    odjeljak: "42",
    skupina: "42.9",
  },
  {
    name: "Uklanjanje građevina",
    sifra: "43.11",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.1",
  },
  {
    name: "Pripremni radovi na gradilištu",
    sifra: "43.12",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.1",
  },
  {
    name: "Pokusno bušenje i sondiranje terena za gradnju",
    sifra: "43.13",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.1",
  },
  {
    name: "Elektroinstalacijski radovi",
    sifra: "43.21",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.2",
  },
  {
    name:
      "Uvođenje instalacija vodovoda, kanalizacije i plina i instalacija za grijanje i klimatizaciju",
    sifra: "43.22",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.2",
  },
  {
    name: "Ostali građevinski instalacijski radovi",
    sifra: "43.29",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.2",
  },
  {
    name: "Fasadni i štukaturski radovi",
    sifra: "43.31",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.3",
  },
  {
    name: "Ugradnja stolarije",
    sifra: "43.32",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.3",
  },
  {
    name: "Postavljanje podnih i zidnih obloga",
    sifra: "43.33",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.3",
  },
  {
    name: "Soboslikarski i staklarski radovi",
    sifra: "43.34",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.3",
  },
  {
    name: "Ostali završni građevinski radovi",
    sifra: "43.39",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.3",
  },
  {
    name: "Radovi na krovištu",
    sifra: "43.91",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.9",
  },
  {
    name: "Ostale specijalizirane građevinske djelatnosti, d. n.",
    sifra: "43.99",
    podrucje: "F",
    odjeljak: "43",
    skupina: "43.9",
  },
  {
    name: "Trgovina automobilima i motornim vozilima lake kategorije",
    sifra: "45.11",
    podrucje: "G",
    odjeljak: "45",
    skupina: "45.1",
  },
  {
    name: "Trgovina ostalim motornim vozilima",
    sifra: "45.19",
    podrucje: "G",
    odjeljak: "45",
    skupina: "45.1",
  },
  {
    name: "Održavanje i popravak motornih vozila",
    sifra: "45.20",
    podrucje: "G",
    odjeljak: "45",
    skupina: "45.2",
  },
  {
    name: "Trgovina na veliko dijelovima i priborom za motorna vozila",
    sifra: "45.31",
    podrucje: "G",
    odjeljak: "45",
    skupina: "45.3",
  },
  {
    name: "Trgovina na malo dijelovima i priborom za motorna vozila",
    sifra: "45.32",
    podrucje: "G",
    odjeljak: "45",
    skupina: "45.3",
  },
  {
    name:
      "Trgovina motociklima, dijelovima i priborom za motocikle te održavanje i popravak motocikala",
    sifra: "45.40",
    podrucje: "G",
    odjeljak: "45",
    skupina: "45.4",
  },
  {
    name:
      "Posredovanje u trgovini poljoprivrednim sirovinama, živom stokom, tekstilnim sirovinama...",
    sifra: "46.11",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name:
      "Posredovanje u trgovini gorivima, rudama, metalima i industrijskim kemijskim proizvodima",
    sifra: "46.12",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name: "Posredovanje u trgovini drvom i građevinskim materijalom",
    sifra: "46.13",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name:
      "Posredovanje u trgovini strojevima, industrijskom opremom, brodovima i zrakoplovima",
    sifra: "46.14",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name:
      "Posredovanje u trgovini namještajem, proizvodima za kućanstvo i željeznom robom",
    sifra: "46.15",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name:
      "Posredovanje u trgovini tekstilom, odjećom, krznom, obućom i kožnim proizvodima",
    sifra: "46.16",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name: "Posredovanje u trgovini hranom, pićima i duhanom",
    sifra: "46.17",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name: "Posredovanje u trgovini specijaliziranoj za određene proizvode",
    sifra: "46.18",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name: "Posredovanje u trgovini raznovrsnim proizvodima",
    sifra: "46.19",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.1",
  },
  {
    name:
      "Trgovina na veliko žitaricama, sirovim duhanom, sjemenjem i stočnom hranom",
    sifra: "46.21",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.2",
  },
  {
    name: "Trgovina na veliko cvijećem i sadnicama",
    sifra: "46.22",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.2",
  },
  {
    name: "Trgovina na veliko živom stokom",
    sifra: "46.23",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.2",
  },
  {
    name: "Trgovina na veliko sirovim i štavljenim kožama",
    sifra: "46.24",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.2",
  },
  {
    name: "Trgovina na veliko voćem i povrćem",
    sifra: "46.31",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name: "Trgovina na veliko mesom i mesnim proizvodima",
    sifra: "46.32",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name:
      "Trgovina na veliko mlijekom, mliječnim proizvodima, jajima, jestivim uljima i mastima",
    sifra: "46.33",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name: "Trgovina na veliko pićima",
    sifra: "46.34",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name: "Trgovina na veliko duhanskim proizvodima",
    sifra: "46.35",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name: "Trgovina na veliko šećerom, čokoladom i bombonima",
    sifra: "46.36",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name: "Trgovina na veliko kavom, čajem, kakaom i začinima",
    sifra: "46.37",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name:
      "Trgovina na veliko ostalom hranom uključujući ribe, rakove i školjke",
    sifra: "46.38",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name:
      "Nespecijalizirana trgovina na veliko hranom, pićima i duhanskim proizvodima",
    sifra: "46.39",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.3",
  },
  {
    name: "Trgovina na veliko tekstilom",
    sifra: "46.41",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko odjećom i obućom",
    sifra: "46.42",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko električnim aparatima za kućanstvo",
    sifra: "46.43",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko porculanom, staklom i sredstvima za čišćenje",
    sifra: "46.44",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko parfemima i kozmetikom",
    sifra: "46.45",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko farmaceutskim proizvodima",
    sifra: "46.46",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko namještajem, sagovima i opremom za rasvjetu",
    sifra: "46.47",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko satovima i nakitom",
    sifra: "46.48",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko ostalim proizvodima za kućanstvo",
    sifra: "46.49",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.4",
  },
  {
    name: "Trgovina na veliko računalima, perifernom opremom i softverom",
    sifra: "46.51",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.5",
  },
  {
    name:
      "Trgovina na veliko elektroničkim i telekomunikacijskim dijelovima i opremom",
    sifra: "46.52",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.5",
  },
  {
    name: "Trgovina na veliko poljoprivrednim strojevima, opremom i priborom",
    sifra: "46.61",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name: "Trgovina na veliko alatnim strojevima",
    sifra: "46.62",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name: "Trgovina na veliko strojevima za rudnike i građevinarstvo",
    sifra: "46.63",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name:
      "Trgovina na veliko strojevima za tekstilnu industriju te strojevima za šivanje i pletenje",
    sifra: "46.64",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name: "Trgovina na veliko uredskim namještajem",
    sifra: "46.65",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name: "Trgovina na veliko ostalim uredskim strojevima i opremom",
    sifra: "46.66",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name: "Trgovina na veliko ostalim strojevima i opremom",
    sifra: "46.69",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.6",
  },
  {
    name:
      "Trgovina na veliko krutim, tekućim i plinovitim gorivima i srodnim proizvodima",
    sifra: "46.71",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name: "Trgovina na veliko metalima i metalnim rudama",
    sifra: "46.72",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name:
      "Trgovina na veliko drvom, građevinskim materijalom i sanitarnom opremom",
    sifra: "46.73",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name:
      "Trgovina na veliko željeznom robom, instalacijskim materijalom i opremom za vodovod i grijanje",
    sifra: "46.74",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name: "Trgovina na veliko kemijskim proizvodima",
    sifra: "46.75",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name: "Trgovina na veliko ostalim poluproizvodima",
    sifra: "46.76",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name: "Trgovina na veliko ostacima i otpacima",
    sifra: "46.77",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.7",
  },
  {
    name: "Nespecijalizirana trgovina na veliko",
    sifra: "46.90",
    podrucje: "G",
    odjeljak: "46",
    skupina: "46.9",
  },
  {
    name:
      "Trgovina na malo u nespecijaliziranim prodavaonicama pretežno hranom, pićima i duhan. pr.",
    sifra: "47.11",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.1",
  },
  {
    name: "Ostala trgovina na malo u nespecijaliziranim prodavaonicama",
    sifra: "47.19",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.1",
  },
  {
    name: "Trgovina na malo voćem i povrćem u specijaliziranim prodavaonicama",
    sifra: "47.21",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name:
      "Trgovina na malo mesom i mesnim proizvodima u specijaliziranim prodavaonicama",
    sifra: "47.22",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name:
      "Trgovina na malo ribama, rakovima i školjkama u specijaliziranim prodavaonicama",
    sifra: "47.23",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name:
      "Trgovina na malo kruhom, pecivom, kolačima, tjesteninama, bombonima i slatk. u spec. prod.",
    sifra: "47.24",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name: "Trgovina na malo pićima u specijaliziranim prodavaonicama",
    sifra: "47.25",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name:
      "Trgovina na malo duhanskim proizvodima u specijaliziranim prodavaonicama",
    sifra: "47.26",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name:
      "Ostala trgovina na malo prehrambenim proizvodima u specijaliziranim prodavaonicama",
    sifra: "47.29",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.2",
  },
  {
    name:
      "Trgovina na malo motornim gorivima i mazivima u specijaliziranim prodavaonicama",
    sifra: "47.30",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.3",
  },
  {
    name:
      "Trgovina na malo računalima, perifernim jedinicama i softverom u specijaliziranim prodav.",
    sifra: "47.41",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.4",
  },
  {
    name:
      "Trgovina na malo telekomunikacijskom opremom u specijaliziranim prodavaonicama",
    sifra: "47.42",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.4",
  },
  {
    name:
      "Trgovina na malo audio i videoopremom u specijaliziranim prodavaonicama",
    sifra: "47.43",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.4",
  },
  {
    name: "Trgovina na malo tekstilom u specijaliziranim prodavaonicama",
    sifra: "47.51",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.5",
  },
  {
    name:
      "Trgovina na malo željeznom robom, bojama i staklom u specijaliziranim prodavaonicama",
    sifra: "47.52",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.5",
  },
  {
    name:
      "Trgovina na malo sagovima i prostiračima za pod, zidnim i podnim oblogama u spec. prod.",
    sifra: "47.53",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.5",
  },
  {
    name:
      "Trgovina na malo električnim aparatima za kućanstvo u specijaliziranim prodavaonicama",
    sifra: "47.54",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.5",
  },
  {
    name:
      "Trgovina na malo namještajem, opremom za rasvjetu i ost. pr. za kućanstvo u spec. prodav.",
    sifra: "47.59",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.5",
  },
  {
    name: "Trgovina na malo knjigama u specijaliziranim prodavaonicama",
    sifra: "47.61",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.6",
  },
  {
    name:
      "Trgovina na malo novinama, papirnatom robom i pisaćim priborom u specijaliziranim prodav.",
    sifra: "47.62",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.6",
  },
  {
    name:
      "Trgovina na malo glazbenim i videozapisima u specijaliziranim prodavaonicama",
    sifra: "47.63",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.6",
  },
  {
    name:
      "Trgovina na malo sportskom opremom u specijaliziranim prodavaonicama",
    sifra: "47.64",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.6",
  },
  {
    name:
      "Trgovina na malo igrama i igračkama u specijaliziranim prodavaonicama",
    sifra: "47.65",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.6",
  },
  {
    name: "Trgovina na malo odjećom u specijaliziranim prodavaonicama",
    sifra: "47.71",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name: "Trgovina na malo obućom i proizvodima od kože",
    sifra: "47.72",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name: "Ljekarne",
    sifra: "47.73",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name:
      "Trgovina na malo medicinskim pripravcima i ortopedskim pomagalima u specijal. prodav.",
    sifra: "47.74",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name:
      "Trgovina na malo kozmetičkim i toaletnim proizvodima u specijaliziranim prodavaonicama",
    sifra: "47.75",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name:
      "Trgovina na malo cvijećem, sadnicama, sjemenjem, gnojivom, kućnim ljubimcima i hranom...",
    sifra: "47.76",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name:
      "Trgovina na malo satovima i nakitom u specijaliziranim prodavaonicama",
    sifra: "47.77",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name:
      "Ostala trgovina na malo novom robom u specijaliziranim prodavaonicama",
    sifra: "47.78",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name: "Trgovina na malo rabljenom robom u specijaliziranim prodavaonicama",
    sifra: "47.79",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.7",
  },
  {
    name:
      "Trgovina na malo hranom, pićima i duhanskim proizvodima na štandovima i tržnicama",
    sifra: "47.81",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.8",
  },
  {
    name:
      "Trgovina na malo tekstilom, odjećom i obućom na štandovima i tržnicama",
    sifra: "47.82",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.8",
  },
  {
    name: "Trgovina na malo ostalom robom na štandovima i tržnicama",
    sifra: "47.89",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.8",
  },
  {
    name: "Trgovina na malo preko pošte ili interneta",
    sifra: "47.91",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.9",
  },
  {
    name: "Ostala trgovina na malo izvan prodavaonica, štandova i tržnica",
    sifra: "47.99",
    podrucje: "G",
    odjeljak: "47",
    skupina: "47.9",
  },
  {
    name: "Željeznički prijevoz putnika, međugradski",
    sifra: "49.10",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.1",
  },
  {
    name: "Željeznički prijevoz robe",
    sifra: "49.20",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.2",
  },
  {
    name: "Gradski i prigradski kopneni prijevoz putnika",
    sifra: "49.31",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.3",
  },
  {
    name: "Taksi služba",
    sifra: "49.32",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.3",
  },
  {
    name: "Ostali kopneni prijevoz putnika, d. n.",
    sifra: "49.39",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.3",
  },
  {
    name: "Cestovni prijevoz robe",
    sifra: "49.41",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.4",
  },
  {
    name: "Usluge preseljenja",
    sifra: "49.42",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.4",
  },
  {
    name: "Cjevovodni transport",
    sifra: "49.50",
    podrucje: "H",
    odjeljak: "49",
    skupina: "49.5",
  },
  {
    name: "Pomorski i obalni prijevoz putnika",
    sifra: "50.10",
    podrucje: "H",
    odjeljak: "50",
    skupina: "50.1",
  },
  {
    name: "Pomorski i obalni prijevoz robe",
    sifra: "50.20",
    podrucje: "H",
    odjeljak: "50",
    skupina: "50.2",
  },
  {
    name: "Prijevoz putnika unutrašnjim vodenim putovima",
    sifra: "50.30",
    podrucje: "H",
    odjeljak: "50",
    skupina: "50.3",
  },
  {
    name: "Prijevoz robe unutrašnjim vodenim putovima",
    sifra: "50.40",
    podrucje: "H",
    odjeljak: "50",
    skupina: "50.4",
  },
  {
    name: "Zračni prijevoz putnika",
    sifra: "51.10",
    podrucje: "H",
    odjeljak: "51",
    skupina: "51.1",
  },
  {
    name: "Zračni prijevoz robe",
    sifra: "51.21",
    podrucje: "H",
    odjeljak: "51",
    skupina: "51.2",
  },
  {
    name: "Svemirski prijevoz",
    sifra: "51.22",
    podrucje: "H",
    odjeljak: "51",
    skupina: "51.2",
  },
  {
    name: "Skladištenje robe",
    sifra: "52.10",
    podrucje: "H",
    odjeljak: "52",
    skupina: "52.1",
  },
  {
    name: "Uslužne djelatnosti u vezi s kopnenim prijevozom",
    sifra: "52.21",
    podrucje: "H",
    odjeljak: "52",
    skupina: "52.2",
  },
  {
    name: "Uslužne djelatnosti u vezi s vodenim prijevozom",
    sifra: "52.22",
    podrucje: "H",
    odjeljak: "52",
    skupina: "52.2",
  },
  {
    name: "Uslužne djelatnosti u vezi sa zračnim prijevozom",
    sifra: "52.23",
    podrucje: "H",
    odjeljak: "52",
    skupina: "52.2",
  },
  {
    name: "Prekrcaj tereta",
    sifra: "52.24",
    podrucje: "H",
    odjeljak: "52",
    skupina: "52.2",
  },
  {
    name: "Ostale prateće djelatnosti u prijevozu",
    sifra: "52.29",
    podrucje: "H",
    odjeljak: "52",
    skupina: "52.2",
  },
  {
    name: "Djelatnosti pružanja univerzalnih poštanskih usluga",
    sifra: "53.10",
    podrucje: "H",
    odjeljak: "53",
    skupina: "53.1",
  },
  {
    name: "Djelatnosti pružanja ostalih poštanskih i kurirskih usluga",
    sifra: "53.20",
    podrucje: "H",
    odjeljak: "53",
    skupina: "53.2",
  },
  {
    name: "Hoteli i sličan smještaj",
    sifra: "55.10",
    podrucje: "I",
    odjeljak: "55",
    skupina: "55.1",
  },
  {
    name: "Odmarališta i slični objekti za kraći odmor",
    sifra: "55.20",
    podrucje: "I",
    odjeljak: "55",
    skupina: "55.2",
  },
  {
    name: "Kampovi i prostori za kampiranje",
    sifra: "55.30",
    podrucje: "I",
    odjeljak: "55",
    skupina: "55.3",
  },
  {
    name: "Ostali smještaj",
    sifra: "55.90",
    podrucje: "I",
    odjeljak: "55",
    skupina: "55.9",
  },
  {
    name:
      "Djelatnosti restorana i ostalih objekata za pripremu i usluživanje hrane",
    sifra: "56.10",
    podrucje: "I",
    odjeljak: "56",
    skupina: "56.1",
  },
  {
    name: "Djelatnosti keteringa",
    sifra: "56.21",
    podrucje: "I",
    odjeljak: "56",
    skupina: "56.2",
  },
  {
    name: "Ostale djelatnosti pripreme i usluživanja hrane",
    sifra: "56.29",
    podrucje: "I",
    odjeljak: "56",
    skupina: "56.2",
  },
  {
    name: "Djelatnosti pripreme i usluživanja pića",
    sifra: "56.30",
    podrucje: "I",
    odjeljak: "56",
    skupina: "56.3",
  },
  {
    name: "Izdavanje knjiga",
    sifra: "58.11",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.1",
  },
  {
    name: "Izdavanje imenika i popisa korisničkih adresa",
    sifra: "58.12",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.1",
  },
  {
    name: "Izdavanje novina",
    sifra: "58.13",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.1",
  },
  {
    name: "Izdavanje časopisa i periodičnih publikacija",
    sifra: "58.14",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.1",
  },
  {
    name: "Ostala izdavačka djelatnost",
    sifra: "58.19",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.1",
  },
  {
    name: "Izdavanje računalnih igara",
    sifra: "58.21",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.2",
  },
  {
    name: "Izdavanje ostalog softvera",
    sifra: "58.29",
    podrucje: "J",
    odjeljak: "58",
    skupina: "58.2",
  },
  {
    name: "Proizvodnja filmova, videofilmova i televizijskog programa",
    sifra: "59.11",
    podrucje: "J",
    odjeljak: "59",
    skupina: "59.1",
  },
  {
    name:
      "Djelatnosti koje slijede nakon proizvodnje filmova, videofilmova i televizijskog programa",
    sifra: "59.12",
    podrucje: "J",
    odjeljak: "59",
    skupina: "59.1",
  },
  {
    name: "Distribucija filmova, videofilmova i televizijskog programa",
    sifra: "59.13",
    podrucje: "J",
    odjeljak: "59",
    skupina: "59.1",
  },
  {
    name: "Djelatnosti prikazivanja filmova",
    sifra: "59.14",
    podrucje: "J",
    odjeljak: "59",
    skupina: "59.1",
  },
  {
    name: "Djelatnosti snimanja zvučnih zapisa i izdavanja glazbenih zapisa",
    sifra: "59.20",
    podrucje: "J",
    odjeljak: "59",
    skupina: "59.2",
  },
  {
    name: "Emitiranje radijskog programa",
    sifra: "60.10",
    podrucje: "J",
    odjeljak: "60",
    skupina: "60.1",
  },
  {
    name: "Emitiranje televizijskog programa",
    sifra: "60.20",
    podrucje: "J",
    odjeljak: "60",
    skupina: "60.2",
  },
  {
    name: "Djelatnosti žičane telekomunikacije",
    sifra: "61.10",
    podrucje: "J",
    odjeljak: "61",
    skupina: "61.1",
  },
  {
    name: "Djelatnosti bežične telekomunikacije",
    sifra: "61.20",
    podrucje: "J",
    odjeljak: "61",
    skupina: "61.2",
  },
  {
    name: "Djelatnosti satelitske telekomunikacije",
    sifra: "61.30",
    podrucje: "J",
    odjeljak: "61",
    skupina: "61.3",
  },
  {
    name: "Ostale telekomunikacijske djelatnosti",
    sifra: "61.90",
    podrucje: "J",
    odjeljak: "61",
    skupina: "61.9",
  },
  {
    name: "Računalno programiranje",
    sifra: "62.01",
    podrucje: "J",
    odjeljak: "62",
    skupina: "62.0",
  },
  {
    name: "Savjetovanje u vezi s računalima",
    sifra: "62.02",
    podrucje: "J",
    odjeljak: "62",
    skupina: "62.0",
  },
  {
    name: "Upravljanje računalnom opremom i sustavom",
    sifra: "62.03",
    podrucje: "J",
    odjeljak: "62",
    skupina: "62.0",
  },
  {
    name:
      "Ostale uslužne djelatnosti u vezi s informacijskom tehnologijom i računalima",
    sifra: "62.09",
    podrucje: "J",
    odjeljak: "62",
    skupina: "62.0",
  },
  {
    name: "Obrada podataka, usluge poslužitelja i djelatnosti povezane s njima",
    sifra: "63.11",
    podrucje: "J",
    odjeljak: "63",
    skupina: "63.1",
  },
  {
    name: "Internetski portali",
    sifra: "63.12",
    podrucje: "J",
    odjeljak: "63",
    skupina: "63.1",
  },
  {
    name: "Djelatnosti novinskih agencija",
    sifra: "63.91",
    podrucje: "J",
    odjeljak: "63",
    skupina: "63.9",
  },
  {
    name: "Ostale informacijske uslužne djelatnosti, d. n.",
    sifra: "63.99",
    podrucje: "J",
    odjeljak: "63",
    skupina: "63.9",
  },
  {
    name: "Središnje bankarstvo",
    sifra: "64.11",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.1",
  },
  {
    name: "Ostalo novčarsko posredovanje",
    sifra: "64.19",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.1",
  },
  {
    name: "Djelatnosti holding-društava",
    sifra: "64.20",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.2",
  },
  {
    name:
      "Uzajamni fondovi (trustovi), ostali fondovi i slični financijski subjekti",
    sifra: "64.30",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.3",
  },
  {
    name: "Financijski leasing",
    sifra: "64.91",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.9",
  },
  {
    name: "Ostalo kreditno posredovanje",
    sifra: "64.92",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.9",
  },
  {
    name:
      "Ostale financijske uslužne djelatnosti, osim osiguranja i mirovinskih fondova, d. n.",
    sifra: "64.99",
    podrucje: "K",
    odjeljak: "64",
    skupina: "64.9",
  },
  {
    name: "Životno osiguranje",
    sifra: "65.11",
    podrucje: "K",
    odjeljak: "65",
    skupina: "65.1",
  },
  {
    name: "Ostalo osiguranje",
    sifra: "65.12",
    podrucje: "K",
    odjeljak: "65",
    skupina: "65.1",
  },
  {
    name: "Reosiguranje",
    sifra: "65.20",
    podrucje: "K",
    odjeljak: "65",
    skupina: "65.2",
  },
  {
    name: "Mirovinski fondovi",
    sifra: "65.30",
    podrucje: "K",
    odjeljak: "65",
    skupina: "65.3",
  },
  {
    name: "Poslovanje financijskih tržišta",
    sifra: "66.11",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.1",
  },
  {
    name:
      "Djelatnosti posredovanja u poslovanju vrijednosnim papirima i robnim ugovorima",
    sifra: "66.12",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.1",
  },
  {
    name:
      "Ostale pomoćne djelatnosti kod financijskih usluga, osim osiguranja i mirovinskih fondova",
    sifra: "66.19",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.1",
  },
  {
    name: "Procjena rizika i štete",
    sifra: "66.21",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.2",
  },
  {
    name: "Djelatnosti agenata i posrednika osiguranja",
    sifra: "66.22",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.2",
  },
  {
    name: "Ostale pomoćne djelatnosti u osiguranju i mirovinskim fondovima",
    sifra: "66.29",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.2",
  },
  {
    name: "Djelatnosti upravljanja fondovima",
    sifra: "66.30",
    podrucje: "K",
    odjeljak: "66",
    skupina: "66.3",
  },
  {
    name: "Kupnja i prodaja vlastitih nekretnina",
    sifra: "68.10",
    podrucje: "L",
    odjeljak: "68",
    skupina: "68.1",
  },
  {
    name:
      "Iznajmljivanje i upravljanje vlastitim nekretninama ili nekretninama uzetim u zakup (leasing)",
    sifra: "68.20",
    podrucje: "L",
    odjeljak: "68",
    skupina: "68.2",
  },
  {
    name: "Agencije za poslovanje nekretninama",
    sifra: "68.31",
    podrucje: "L",
    odjeljak: "68",
    skupina: "68.3",
  },
  {
    name: "Upravljanje nekretninama uz naplatu ili na osnovi ugovora",
    sifra: "68.32",
    podrucje: "L",
    odjeljak: "68",
    skupina: "68.3",
  },
  {
    name: "Pravne djelatnosti",
    sifra: "69.10",
    podrucje: "M",
    odjeljak: "69",
    skupina: "69.1",
  },
  {
    name:
      "Računovodstvene, knjigovodstvene i revizijske djelatnosti; porezno savjetovanje",
    sifra: "69.20",
    podrucje: "M",
    odjeljak: "69",
    skupina: "69.2",
  },
  {
    name: "Upravljačke djelatnosti",
    sifra: "70.10",
    podrucje: "M",
    odjeljak: "70",
    skupina: "70.1",
  },
  {
    name: "Odnosi s javnošću i djelatnosti priopćivanja",
    sifra: "70.21",
    podrucje: "M",
    odjeljak: "70",
    skupina: "70.2",
  },
  {
    name: "Savjetovanje u vezi s poslovanjem i ostalim upravljanjem",
    sifra: "70.22",
    podrucje: "M",
    odjeljak: "70",
    skupina: "70.2",
  },
  {
    name: "Arhitektonske djelatnosti",
    sifra: "71.11",
    podrucje: "M",
    odjeljak: "71",
    skupina: "71.1",
  },
  {
    name: "Inženjerstvo i s njim povezano tehničko savjetovanje",
    sifra: "71.12",
    podrucje: "M",
    odjeljak: "71",
    skupina: "71.1",
  },
  {
    name: "Tehničko ispitivanje i analiza",
    sifra: "71.20",
    podrucje: "M",
    odjeljak: "71",
    skupina: "71.2",
  },
  {
    name: "Istraživanje i eksperimentalni razvoj u biotehnologiji",
    sifra: "72.11",
    podrucje: "M",
    odjeljak: "72",
    skupina: "72.1",
  },
  {
    name:
      "Ostalo istraživanje i eksperimentalni razvoj u prirodnim, tehničkim i tehnološkim znanostima",
    sifra: "72.19",
    podrucje: "M",
    odjeljak: "72",
    skupina: "72.1",
  },
  {
    name:
      "Istraživanje i eksperimentalni razvoj u društvenim i humanističkim znanostima",
    sifra: "72.20",
    podrucje: "M",
    odjeljak: "72",
    skupina: "72.2",
  },
  {
    name: "Agencije za promidžbu (reklamu i propagandu)",
    sifra: "73.11",
    podrucje: "M",
    odjeljak: "73",
    skupina: "73.1",
  },
  {
    name: "Oglašavanje preko medija",
    sifra: "73.12",
    podrucje: "M",
    odjeljak: "73",
    skupina: "73.1",
  },
  {
    name: "Istraživanje tržišta i ispitivanje javnoga mnijenja",
    sifra: "73.20",
    podrucje: "M",
    odjeljak: "73",
    skupina: "73.2",
  },
  {
    name: "Specijalizirane dizajnerske djelatnosti",
    sifra: "74.10",
    podrucje: "M",
    odjeljak: "74",
    skupina: "74.1",
  },
  {
    name: "Fotografske djelatnosti",
    sifra: "74.20",
    podrucje: "M",
    odjeljak: "74",
    skupina: "74.2",
  },
  {
    name: "Prevoditeljske djelatnosti i usluge tumača",
    sifra: "74.30",
    podrucje: "M",
    odjeljak: "74",
    skupina: "74.3",
  },
  {
    name: "Ostale stručne, znanstvene i tehničke djelatnosti, d. n.",
    sifra: "74.90",
    podrucje: "M",
    odjeljak: "74",
    skupina: "74.9",
  },
  {
    name: "Veterinarske djelatnosti",
    sifra: "75.00",
    podrucje: "M",
    odjeljak: "75",
    skupina: "75.0",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) automobila i motornih vozila lake kategorije",
    sifra: "77.11",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.1",
  },
  {
    name: "Iznajmljivanje i davanje u zakup (leasing) kamiona",
    sifra: "77.12",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.1",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) opreme za rekreaciju i sport",
    sifra: "77.21",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.2",
  },
  {
    name: "Iznajmljivanje videokaseta i diskova",
    sifra: "77.22",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.2",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) ostalih predmeta za osobnu uporabu i kućanstvo",
    sifra: "77.29",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.2",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) poljoprivrednih strojeva i opreme",
    sifra: "77.31",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.3",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) strojeva i opreme za građevinarstvo i inženjerstvo",
    sifra: "77.32",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.3",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) uredskih strojeva i opreme (uključujući računala)",
    sifra: "77.33",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.3",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) plovnih prijevoznih sredstava",
    sifra: "77.34",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.3",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) zračnih prijevoznih sredstava",
    sifra: "77.35",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.3",
  },
  {
    name:
      "Iznajmljivanje i davanje u zakup (leasing) ostalih strojeva, opreme i materijalnih dobara, d. n.",
    sifra: "77.39",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.3",
  },
  {
    name:
      "Davanje u zakup (leasing) prava na uporabu intelektualnog vlasništva i sličnih proizvoda...",
    sifra: "77.40",
    podrucje: "N",
    odjeljak: "77",
    skupina: "77.4",
  },
  {
    name: "Djelatnosti agencija za zapošljavanje",
    sifra: "78.10",
    podrucje: "N",
    odjeljak: "78",
    skupina: "78.1",
  },
  {
    name: "Djelatnosti agencija za privremeno zapošljavanje",
    sifra: "78.20",
    podrucje: "N",
    odjeljak: "78",
    skupina: "78.2",
  },
  {
    name: "Ostalo ustupanje ljudskih resursa",
    sifra: "78.30",
    podrucje: "N",
    odjeljak: "78",
    skupina: "78.3",
  },
  {
    name: "Djelatnosti putničkih agencija",
    sifra: "79.11",
    podrucje: "N",
    odjeljak: "79",
    skupina: "79.1",
  },
  {
    name: "Djelatnosti organizatora putovanja (turoperatora)",
    sifra: "79.12",
    podrucje: "N",
    odjeljak: "79",
    skupina: "79.1",
  },
  {
    name: "Ostale rezervacijske usluge i djelatnosti povezane s njima",
    sifra: "79.90",
    podrucje: "N",
    odjeljak: "79",
    skupina: "79.9",
  },
  {
    name: "Djelatnosti privatne zaštite",
    sifra: "80.10",
    podrucje: "N",
    odjeljak: "80",
    skupina: "80.1",
  },
  {
    name: "Usluge zaštite uz pomoć sigurnosnih sustava",
    sifra: "80.20",
    podrucje: "N",
    odjeljak: "80",
    skupina: "80.2",
  },
  {
    name: "Istražne djelatnosti",
    sifra: "80.30",
    podrucje: "N",
    odjeljak: "80",
    skupina: "80.3",
  },
  {
    name: "Upravljanje zgradama",
    sifra: "81.10",
    podrucje: "N",
    odjeljak: "81",
    skupina: "81.1",
  },
  {
    name: "Osnovno čišćenje zgrada",
    sifra: "81.21",
    podrucje: "N",
    odjeljak: "81",
    skupina: "81.2",
  },
  {
    name: "Ostale djelatnosti čišćenja zgrada i objekata",
    sifra: "81.22",
    podrucje: "N",
    odjeljak: "81",
    skupina: "81.2",
  },
  {
    name: "Ostale djelatnosti čišćenja",
    sifra: "81.29",
    podrucje: "N",
    odjeljak: "81",
    skupina: "81.2",
  },
  {
    name: "Uslužne djelatnosti uređenja i održavanja krajolika",
    sifra: "81.30",
    podrucje: "N",
    odjeljak: "81",
    skupina: "81.3",
  },
  {
    name: "Kombinirane uredske administrativne uslužne djelatnosti",
    sifra: "82.11",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.1",
  },
  {
    name:
      "Fotokopiranje, priprema dokumenata i ostale specijalizirane uredske pomoćne djelatnosti",
    sifra: "82.19",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.1",
  },
  {
    name: "Djelatnosti pozivnih centara",
    sifra: "82.20",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.2",
  },
  {
    name: "Organizacija sastanaka i poslovnih sajmova",
    sifra: "82.30",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.3",
  },
  {
    name:
      "Djelatnosti agencija za prikupljanje i naplatu računa te kreditnih ureda",
    sifra: "82.91",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.9",
  },
  {
    name: "Djelatnosti pakiranja",
    sifra: "82.92",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.9",
  },
  {
    name: "Ostale poslovne pomoćne uslužne djelatnosti, d. n.",
    sifra: "82.99",
    podrucje: "N",
    odjeljak: "82",
    skupina: "82.9",
  },
  {
    name: "Opće djelatnosti javne uprave",
    sifra: "84.11",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.1",
  },
  {
    name:
      "Reguliranje djelatnosti subjekata koji pružaju zdravstvenu zaštitu, usluge u obrazovanju i kulturi",
    sifra: "84.12",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.1",
  },
  {
    name: "Reguliranje i poboljšavanje poslovanja u gospodarstvu",
    sifra: "84.13",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.1",
  },
  {
    name: "Vanjski poslovi",
    sifra: "84.21",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.2",
  },
  {
    name: "Poslovi obrane",
    sifra: "84.22",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.2",
  },
  {
    name: "Sudske i pravosudne djelatnosti",
    sifra: "84.23",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.2",
  },
  {
    name: "Poslovi javnog reda i sigurnosti",
    sifra: "84.24",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.2",
  },
  {
    name: "Djelatnosti vatrogasne službe",
    sifra: "84.25",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.2",
  },
  {
    name: "Djelatnosti obveznoga socijalnog osiguranja",
    sifra: "84.30",
    podrucje: "O",
    odjeljak: "84",
    skupina: "84.3",
  },
  {
    name: "Predškolsko obrazovanje",
    sifra: "85.10",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.1",
  },
  {
    name: "Osnovno obrazovanje",
    sifra: "85.20",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.2",
  },
  {
    name: "Opće srednje obrazovanje",
    sifra: "85.31",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.3",
  },
  {
    name: "Tehničko i strukovno srednje obrazovanje",
    sifra: "85.32",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.3",
  },
  {
    name: "Obrazovanje nakon srednjeg koje nije visoko",
    sifra: "85.41",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.4",
  },
  {
    name: "Visoko obrazovanje",
    sifra: "85.42",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.4",
  },
  {
    name: "Obrazovanje i poučavanje u području sporta i rekreacije",
    sifra: "85.51",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.5",
  },
  {
    name: "Obrazovanje i poučavanje u području kulture",
    sifra: "85.52",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.5",
  },
  {
    name: "Djelatnosti vozačkih škola",
    sifra: "85.53",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.5",
  },
  {
    name: "Ostalo obrazovanje i poučavanje, d. n.",
    sifra: "85.59",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.5",
  },
  {
    name: "Pomoćne uslužne djelatnosti u obrazovanju",
    sifra: "85.60",
    podrucje: "P",
    odjeljak: "85",
    skupina: "85.6",
  },
  {
    name: "Djelatnosti bolnica",
    sifra: "86.10",
    podrucje: "Q",
    odjeljak: "86",
    skupina: "86.1",
  },
  {
    name: "Djelatnosti opće medicinske prakse",
    sifra: "86.21",
    podrucje: "Q",
    odjeljak: "86",
    skupina: "86.2",
  },
  {
    name: "Djelatnosti specijalističke medicinske prakse",
    sifra: "86.22",
    podrucje: "Q",
    odjeljak: "86",
    skupina: "86.2",
  },
  {
    name: "Djelatnosti stomatološke prakse",
    sifra: "86.23",
    podrucje: "Q",
    odjeljak: "86",
    skupina: "86.2",
  },
  {
    name: "Ostale djelatnosti zdravstvene zaštite",
    sifra: "86.90",
    podrucje: "Q",
    odjeljak: "86",
    skupina: "86.9",
  },
  {
    name: "Djelatnosti ustanova za njegu",
    sifra: "87.10",
    podrucje: "Q",
    odjeljak: "87",
    skupina: "87.1",
  },
  {
    name:
      "Djelatnosti socijalne skrbi sa smještajem za osobe s teškoćama u razvoju, duševno bolesne...",
    sifra: "87.20",
    podrucje: "Q",
    odjeljak: "87",
    skupina: "87.2",
  },
  {
    name:
      "Djelatnosti socijalne skrbi sa smještajem za starije osobe i osobe s invaliditetom",
    sifra: "87.30",
    podrucje: "Q",
    odjeljak: "87",
    skupina: "87.3",
  },
  {
    name: "Ostale djelatnosti socijalne skrbi sa smještajem",
    sifra: "87.90",
    podrucje: "Q",
    odjeljak: "87",
    skupina: "87.9",
  },
  {
    name:
      "Djelatnosti socijalne skrbi bez smještaja za starije osobe i osobe s invaliditetom",
    sifra: "88.10",
    podrucje: "Q",
    odjeljak: "88",
    skupina: "88.1",
  },
  {
    name: "Djelatnosti dnevne skrbi o djeci",
    sifra: "88.91",
    podrucje: "Q",
    odjeljak: "88",
    skupina: "88.9",
  },
  {
    name: "Ostale djelatnosti socijalne skrbi bez smještaja, d. n.",
    sifra: "88.99",
    podrucje: "Q",
    odjeljak: "88",
    skupina: "88.9",
  },
  {
    name: "Izvođačka umjetnost",
    sifra: "90.01",
    podrucje: "R",
    odjeljak: "90",
    skupina: "90.0",
  },
  {
    name: "Pomoćne djelatnosti u izvođačkoj umjetnosti",
    sifra: "90.02",
    podrucje: "R",
    odjeljak: "90",
    skupina: "90.0",
  },
  {
    name: "Umjetničko stvaralaštvo",
    sifra: "90.03",
    podrucje: "R",
    odjeljak: "90",
    skupina: "90.0",
  },
  {
    name: "Rad umjetničkih objekata",
    sifra: "90.04",
    podrucje: "R",
    odjeljak: "90",
    skupina: "90.0",
  },
  {
    name: "Djelatnosti knjižnica i arhiva",
    sifra: "91.01",
    podrucje: "R",
    odjeljak: "91",
    skupina: "91.0",
  },
  {
    name: "Djelatnosti muzeja",
    sifra: "91.02",
    podrucje: "R",
    odjeljak: "91",
    skupina: "91.0",
  },
  {
    name:
      "Rad povijesnih mjesta i građevina te sličnih zanimljivosti za posjetitelje",
    sifra: "91.03",
    podrucje: "R",
    odjeljak: "91",
    skupina: "91.0",
  },
  {
    name: "Djelatnosti botaničkih i zooloških vrtova i prirodnih rezervata",
    sifra: "91.04",
    podrucje: "R",
    odjeljak: "91",
    skupina: "91.0",
  },
  {
    name: "Djelatnosti kockanja i klađenja",
    sifra: "92.00",
    podrucje: "R",
    odjeljak: "92",
    skupina: "92.0",
  },
  {
    name: "Rad sportskih objekata",
    sifra: "93.11",
    podrucje: "R",
    odjeljak: "93",
    skupina: "93.1",
  },
  {
    name: "Djelatnosti sportskih klubova",
    sifra: "93.12",
    podrucje: "R",
    odjeljak: "93",
    skupina: "93.1",
  },
  {
    name: "Fitnes centri",
    sifra: "93.13",
    podrucje: "R",
    odjeljak: "93",
    skupina: "93.1",
  },
  {
    name: "Ostale sportske djelatnosti",
    sifra: "93.19",
    podrucje: "R",
    odjeljak: "93",
    skupina: "93.1",
  },
  {
    name: "Djelatnosti zabavnih i tematskih parkova",
    sifra: "93.21",
    podrucje: "R",
    odjeljak: "93",
    skupina: "93.2",
  },
  {
    name: "Ostale zabavne i rekreacijske djelatnosti",
    sifra: "93.29",
    podrucje: "R",
    odjeljak: "93",
    skupina: "93.2",
  },
  {
    name: "Djelatnosti poslovnih organizacija i organizacija poslodavaca",
    sifra: "94.11",
    podrucje: "S",
    odjeljak: "94",
    skupina: "94.1",
  },
  {
    name: "Djelatnosti strukovnih članskih organizacija",
    sifra: "94.12",
    podrucje: "S",
    odjeljak: "94",
    skupina: "94.1",
  },
  {
    name: "Djelatnosti sindikata",
    sifra: "94.20",
    podrucje: "S",
    odjeljak: "94",
    skupina: "94.2",
  },
  {
    name: "Djelatnosti vjerskih organizacija",
    sifra: "94.91",
    podrucje: "S",
    odjeljak: "94",
    skupina: "94.9",
  },
  {
    name: "Djelatnosti političkih organizacija",
    sifra: "94.92",
    podrucje: "S",
    odjeljak: "94",
    skupina: "94.9",
  },
  {
    name: "Djelatnosti ostalih članskih organizacija, d. n.",
    sifra: "94.99",
    podrucje: "S",
    odjeljak: "94",
    skupina: "94.9",
  },
  {
    name: "Popravak računala i periferne opreme",
    sifra: "95.11",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.1",
  },
  {
    name: "Popravak komunikacijske opreme",
    sifra: "95.12",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.1",
  },
  {
    name: "Popravak elektroničkih uređaja za široku potrošnju",
    sifra: "95.21",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.2",
  },
  {
    name: "Popravak aparata za kućanstvo te opreme za kuću i vrt",
    sifra: "95.22",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.2",
  },
  {
    name: "Popravak obuće i proizvoda od kože",
    sifra: "95.23",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.2",
  },
  {
    name: "Popravak namještaja i pokućstva",
    sifra: "95.24",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.2",
  },
  {
    name: "Popravak satova i nakita",
    sifra: "95.25",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.2",
  },
  {
    name: "Popravak ostalih predmeta za osobnu uporabu i kućanstvo",
    sifra: "95.29",
    podrucje: "S",
    odjeljak: "95",
    skupina: "95.2",
  },
  {
    name: "Pranje i kemijsko čišćenje tekstila i krznenih proizvoda",
    sifra: "96.01",
    podrucje: "S",
    odjeljak: "96",
    skupina: "96.0",
  },
  {
    name: "Frizerski saloni i saloni za uljepšavanje",
    sifra: "96.02",
    podrucje: "S",
    odjeljak: "96",
    skupina: "96.0",
  },
  {
    name: "Pogrebne i srodne djelatnosti",
    sifra: "96.03",
    podrucje: "S",
    odjeljak: "96",
    skupina: "96.0",
  },
  {
    name: "Djelatnosti za njegu i održavanje tijela",
    sifra: "96.04",
    podrucje: "S",
    odjeljak: "96",
    skupina: "96.0",
  },
  {
    name: "Ostale osobne uslužne djelatnosti, d. n.",
    sifra: "96.09",
    podrucje: "S",
    odjeljak: "96",
    skupina: "96.0",
  },
  {
    name: "Djelatnosti kućanstava koja zapošljavaju poslugu",
    sifra: "97.00",
    podrucje: "T",
    odjeljak: "97",
    skupina: "97.0",
  },
  {
    name:
      "Djelatnosti privatnih kućanstava koja proizvode različitu robu za vlastite potrebe",
    sifra: "98.10",
    podrucje: "T",
    odjeljak: "98",
    skupina: "98.1",
  },
  {
    name:
      "Djelatnosti privatnih kućanstava koja obavljaju različite usluge za vlastite potrebe",
    sifra: "98.20",
    podrucje: "T",
    odjeljak: "98",
    skupina: "98.2",
  },
  {
    name: "Djelatnosti izvanteritorijalnih organizacija i tijela",
    sifra: "99.00",
    podrucje: "U",
    odjeljak: "99",
    skupina: "99.0",
  },
];
export const banks = {
  2500009: "ADDIKO BANK d.d. Zagreb",
  2481000: "AGRAM BANKA d.d. Zagreb",
  4133006: "BANKA KOVANICA d.d. Varaždin",
  2488001: "BKS BANK AG, Glavna podružnica Hrvatska",
  2485003: "CROATIA BANKA d.d. Zagreb",
  2402006: "ERSTE&STEIERMÄRKISCHE BANK d.d. Rijeka",
  2493003: "HRVATSKA BANKA ZA OBNOVU I RAZVITAK Zagreb",
  1001005: "HRVATSKA NARODNA BANKA",
  2390001: "HRVATSKA POŠTANSKA BANKA d.d. Zagreb",
  2492008: "IMEX BANKA d.d. Split",
  2380006: "ISTARSKA KREDITNA BANKA UMAG d.d. Umag",
  2489004: "J&T banka d.d. Varaždin",
  2400008: "KARLOVAČKA BANKA d.d. Karlovac",
  4124003: "KENTBANK d.d. Zagreb",
  2407000: "OTP BANKA HRVATSKA d.d. Split",
  2408002: "PARTNER BANKA d.d. Zagreb",
  2386002: "PODRAVSKA BANKA d.d. Koprivnica",
  2340009: "PRIVREDNA BANKA ZAGREB d.d. Zagreb",
  2484008: "RAIFFEISENBANK AUSTRIA d.d. Zagreb",
  2403009: "SAMOBORSKA BANKA d.d. Samobor",
  2503007: "SBERBANK d.d. Zagreb",
  2412009: "SLATINSKA BANKA d.d. Slatina",
  2360000: "ZAGREBAČKA BANKA d.d. Zagreb",
};
export const zipCodes = {
  mjestaRH: {
    totalCount: {
      count: "6778",
    },
    mjesto: [
      {
        id: "1",
        brojPu: "31207",
        redBroj: "1",
        nazivPu: "Tenja",
        naselje: "Ada",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2",
        brojPu: "10363",
        redBroj: "2",
        nazivPu: "Belovar",
        naselje: "Adamovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3",
        brojPu: "35420",
        redBroj: "3",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Adžamovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4",
        brojPu: "34000",
        redBroj: "4",
        nazivPu: "Požega",
        naselje: "Alaginci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5",
        brojPu: "53270",
        redBroj: "5",
        nazivPu: "Senj",
        naselje: "Alan",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6",
        brojPu: "53000",
        redBroj: "6",
        nazivPu: "Gospić",
        naselje: "Aleksinica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "7",
        brojPu: "34000",
        redBroj: "7",
        nazivPu: "Požega",
        naselje: "Alilovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "8",
        brojPu: "31226",
        redBroj: "8",
        nazivPu: "Dalj",
        naselje: "Aljmaš",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "9",
        brojPu: "34000",
        redBroj: "9",
        nazivPu: "Požega",
        naselje: "Amatovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "10",
        brojPu: "43240",
        redBroj: "10",
        nazivPu: "Čazma",
        naselje: "Andigola",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "11",
        brojPu: "49243",
        redBroj: "11",
        nazivPu: "Oroslavje",
        naselje: "Andraševec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "12",
        brojPu: "32100",
        redBroj: "12",
        nazivPu: "Vinkovci",
        naselje: "Andrijaševci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "13",
        brojPu: "31500",
        redBroj: "13",
        nazivPu: "Našice",
        naselje: "Andrijevac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "14",
        brojPu: "10370",
        redBroj: "14",
        nazivPu: "Dugo Selo",
        naselje: "Andrilovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "15",
        brojPu: "52466",
        redBroj: "15",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Antenal - Antenal",
        zupanija: "ISTARSKA",
      },
      {
        id: "16",
        brojPu: "32100",
        redBroj: "16",
        nazivPu: "Vinkovci",
        naselje: "Antin",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "17",
        brojPu: "48316",
        redBroj: "17",
        nazivPu: "Đelekovec",
        naselje: "Antolovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "18",
        brojPu: "52440",
        redBroj: "18",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Antonci",
        zupanija: "ISTARSKA",
      },
      {
        id: "19",
        brojPu: "52460",
        redBroj: "19",
        nazivPu: "Buje (Buie)",
        naselje: "Antonci - Antonzi",
        zupanija: "ISTARSKA",
      },
      {
        id: "20",
        brojPu: "34551",
        redBroj: "20",
        nazivPu: "Lipik",
        naselje: "Antunovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "21",
        brojPu: "34000",
        redBroj: "21",
        nazivPu: "Požega",
        naselje: "Antunovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "22",
        brojPu: "34550",
        redBroj: "22",
        nazivPu: "Pakrac",
        naselje: "Antunovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "23",
        brojPu: "52440",
        redBroj: "23",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Anžići",
        zupanija: "ISTARSKA",
      },
      {
        id: "24",
        brojPu: "42230",
        redBroj: "24",
        nazivPu: "Ludbreg",
        naselje: "Apatija",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "25",
        brojPu: "48260",
        redBroj: "25",
        nazivPu: "Križevci",
        naselje: "Apatovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "26",
        brojPu: "32252",
        redBroj: "26",
        nazivPu: "Otok",
        naselje: "Apševci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "27",
        brojPu: "47240",
        redBroj: "27",
        nazivPu: "Slunj",
        naselje: "Arapovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "28",
        brojPu: "21220",
        redBroj: "28",
        nazivPu: "Trogir",
        naselje: "Arbanija",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "29",
        brojPu: "21256",
        redBroj: "29",
        nazivPu: "Cista Provo",
        naselje: "Aržano",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "30",
        brojPu: "34310",
        redBroj: "30",
        nazivPu: "Pleternica",
        naselje: "Ašikovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "31",
        brojPu: "10414",
        redBroj: "31",
        nazivPu: "Pokupsko",
        naselje: "Auguštanovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "32",
        brojPu: "52440",
        redBroj: "32",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Babići",
        zupanija: "ISTARSKA",
      },
      {
        id: "33",
        brojPu: "52470",
        redBroj: "33",
        nazivPu: "Umag (Umago)",
        naselje: "Babići - Babici",
        zupanija: "ISTARSKA",
      },
      {
        id: "34",
        brojPu: "32270",
        redBroj: "34",
        nazivPu: "Županja",
        naselje: "Babina Greda",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "35",
        brojPu: "44430",
        redBroj: "35",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Babina Rijeka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "36",
        brojPu: "43000",
        redBroj: "36",
        nazivPu: "Bjelovar",
        naselje: "Babinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "37",
        brojPu: "43270",
        redBroj: "37",
        nazivPu: "Veliki Grđevac",
        naselje: "Babinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "38",
        brojPu: "23000",
        redBroj: "38",
        nazivPu: "Zadar",
        naselje: "Babindub",
        zupanija: "ZADARSKA",
      },
      {
        id: "39",
        brojPu: "42208",
        redBroj: "39",
        nazivPu: "Cestica",
        naselje: "Babinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "40",
        brojPu: "20225",
        redBroj: "40",
        nazivPu: "Babino Polje",
        naselje: "Babino Polje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "41",
        brojPu: "43000",
        redBroj: "41",
        nazivPu: "Bjelovar",
        naselje: "Babotok",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "42",
        brojPu: "52440",
        redBroj: "42",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Babudri",
        zupanija: "ISTARSKA",
      },
      {
        id: "43",
        brojPu: "33410",
        redBroj: "43",
        nazivPu: "Suhopolje",
        naselje: "Bačevac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "44",
        brojPu: "43270",
        redBroj: "44",
        nazivPu: "Veliki Grđevac",
        naselje: "Bačkovica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "45",
        brojPu: "52440",
        redBroj: "45",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bačva",
        zupanija: "ISTARSKA",
      },
      {
        id: "46",
        brojPu: "44430",
        redBroj: "46",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Baćin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "47",
        brojPu: "35400",
        redBroj: "47",
        nazivPu: "Nova Gradiška",
        naselje: "Baćin Dol",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "48",
        brojPu: "20340",
        redBroj: "48",
        nazivPu: "Ploče",
        naselje: "Baćina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "49",
        brojPu: "22320",
        redBroj: "49",
        nazivPu: "Drniš",
        naselje: "Badanj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "50",
        brojPu: "52440",
        redBroj: "50",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Baderna",
        zupanija: "ISTARSKA",
      },
      {
        id: "51",
        brojPu: "40000",
        redBroj: "51",
        nazivPu: "Čakovec",
        naselje: "Badličan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "52",
        brojPu: "34550",
        redBroj: "52",
        nazivPu: "Pakrac",
        naselje: "Badljevina",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "53",
        brojPu: "47280",
        redBroj: "53",
        nazivPu: "Ozalj",
        naselje: "Badovinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "54",
        brojPu: "20350",
        redBroj: "54",
        nazivPu: "Metković",
        naselje: "Badžula",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "55",
        brojPu: "10342",
        redBroj: "55",
        nazivPu: "Dubrava",
        naselje: "Bađinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "56",
        brojPu: "47000",
        redBroj: "56",
        nazivPu: "Karlovac",
        naselje: "Baići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "57",
        brojPu: "44330",
        redBroj: "57",
        nazivPu: "Novska",
        naselje: "Bair",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "58",
        brojPu: "21238",
        redBroj: "58",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Bajagić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "59",
        brojPu: "51500",
        redBroj: "59",
        nazivPu: "Krk",
        naselje: "Bajčići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "60",
        brojPu: "52440",
        redBroj: "60",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bajkini",
        zupanija: "ISTARSKA",
      },
      {
        id: "61",
        brojPu: "51262",
        redBroj: "61",
        nazivPu: "Kraljevica",
        naselje: "Bakar",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "62",
        brojPu: "51262",
        redBroj: "62",
        nazivPu: "Kraljevica",
        naselje: "Bakar (Rijeka)",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "63",
        brojPu: "51262",
        redBroj: "63",
        nazivPu: "Kraljevica",
        naselje: "Bakarac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "64",
        brojPu: "33520",
        redBroj: "64",
        nazivPu: "Slatina",
        naselje: "Bakić",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "65",
        brojPu: "53202",
        redBroj: "65",
        nazivPu: "Perušić",
        naselje: "Bakovac Kosinjski",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "66",
        brojPu: "48000",
        redBroj: "66",
        nazivPu: "Koprivnica",
        naselje: "Bakovčica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "67",
        brojPu: "52440",
        redBroj: "67",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Baldaši",
        zupanija: "ISTARSKA",
      },
      {
        id: "68",
        brojPu: "52210",
        redBroj: "68",
        nazivPu: "Rovinj (Rovigno)",
        naselje: "Bale - Valle",
        zupanija: "ISTARSKA",
      },
      {
        id: "69",
        brojPu: "52341",
        redBroj: "69",
        nazivPu: "Žminj",
        naselje: "Balići I",
        zupanija: "ISTARSKA",
      },
      {
        id: "70",
        brojPu: "44400",
        redBroj: "70",
        nazivPu: "Glina",
        naselje: "Balinac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "71",
        brojPu: "33515",
        redBroj: "71",
        nazivPu: "Orahovica",
        naselje: "Balinci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "72",
        brojPu: "22320",
        redBroj: "72",
        nazivPu: "Drniš",
        naselje: "Baljci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "73",
        brojPu: "47240",
        redBroj: "73",
        nazivPu: "Slunj",
        naselje: "Bandino Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "74",
        brojPu: "40313",
        redBroj: "74",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Banfi",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "75",
        brojPu: "10340",
        redBroj: "75",
        nazivPu: "Vrbovec",
        naselje: "Baničevec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "76",
        brojPu: "35400",
        redBroj: "76",
        nazivPu: "Nova Gradiška",
        naselje: "Banićevac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "77",
        brojPu: "20232",
        redBroj: "77",
        nazivPu: "Slano",
        naselje: "Banići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "78",
        brojPu: "52440",
        redBroj: "78",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Banki",
        zupanija: "ISTARSKA",
      },
      {
        id: "79",
        brojPu: "33515",
        redBroj: "79",
        nazivPu: "Orahovica",
        naselje: "Bankovci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "80",
        brojPu: "34000",
        redBroj: "80",
        nazivPu: "Požega",
        naselje: "Bankovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "81",
        brojPu: "44320",
        redBroj: "81",
        nazivPu: "Kutina",
        naselje: "Banova Jaruga",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "82",
        brojPu: "32252",
        redBroj: "82",
        nazivPu: "Otok",
        naselje: "Banovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "83",
        brojPu: "35000",
        redBroj: "83",
        nazivPu: "Slavonski Brod",
        naselje: "Banovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "84",
        brojPu: "10340",
        redBroj: "84",
        nazivPu: "Vrbovec",
        naselje: "Banovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "85",
        brojPu: "49290",
        redBroj: "85",
        nazivPu: "Klanjec",
        naselje: "Banska Gorica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "86",
        brojPu: "47000",
        redBroj: "86",
        nazivPu: "Karlovac",
        naselje: "Banska Selnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "87",
        brojPu: "47000",
        redBroj: "87",
        nazivPu: "Karlovac",
        naselje: "Banski Kovačevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "88",
        brojPu: "47000",
        redBroj: "88",
        nazivPu: "Karlovac",
        naselje: "Banski Moravci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "89",
        brojPu: "44440",
        redBroj: "89",
        nazivPu: "Dvor",
        naselje: "Bansko Vrpolje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "90",
        brojPu: "49240",
        redBroj: "90",
        nazivPu: "Donja Stubica",
        naselje: "Banšćica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "91",
        brojPu: "23271",
        redBroj: "91",
        nazivPu: "Kukljica",
        naselje: "Banj",
        zupanija: "ZADARSKA",
      },
      {
        id: "92",
        brojPu: "20340",
        redBroj: "92",
        nazivPu: "Ploče",
        naselje: "Banja",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "93",
        brojPu: "21276",
        redBroj: "93",
        nazivPu: "Vrgorac",
        naselje: "Banja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "94",
        brojPu: "10380",
        redBroj: "94",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Banje Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "95",
        brojPu: "23422",
        redBroj: "95",
        nazivPu: "Stankovci",
        naselje: "Banjevci",
        zupanija: "ZADARSKA",
      },
      {
        id: "96",
        brojPu: "51280",
        redBroj: "96",
        nazivPu: "Rab",
        naselje: "Banjol",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "97",
        brojPu: "52100",
        redBroj: "97",
        nazivPu: "Pula (Pola)",
        naselje: "Banjole",
        zupanija: "ISTARSKA",
      },
      {
        id: "98",
        brojPu: "47250",
        redBroj: "98",
        nazivPu: "Duga Resa",
        naselje: "Banjsko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "99",
        brojPu: "10410",
        redBroj: "99",
        nazivPu: "Velika Gorica",
        naselje: "Bapča",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "100",
        brojPu: "32236",
        redBroj: "100",
        nazivPu: "Ilok",
        naselje: "Bapska",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "101",
        brojPu: "31551",
        redBroj: "101",
        nazivPu: "Belišće",
        naselje: "Baranjsko Petrovo Selo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "102",
        brojPu: "52341",
        redBroj: "102",
        nazivPu: "Žminj",
        naselje: "Barat",
        zupanija: "ISTARSKA",
      },
      {
        id: "103",
        brojPu: "52440",
        redBroj: "103",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Barat",
        zupanija: "ISTARSKA",
      },
      {
        id: "104",
        brojPu: "52207",
        redBroj: "104",
        nazivPu: "Barban",
        naselje: "Barban",
        zupanija: "ISTARSKA",
      },
      {
        id: "105",
        brojPu: "10413",
        redBroj: "105",
        nazivPu: "Kravarsko",
        naselje: "Barbarići Kravarski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "106",
        brojPu: "51280",
        redBroj: "106",
        nazivPu: "Rab",
        naselje: "Barbat na Rabu",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "107",
        brojPu: "52220",
        redBroj: "107",
        nazivPu: "Labin",
        naselje: "Barbići",
        zupanija: "ISTARSKA",
      },
      {
        id: "108",
        brojPu: "52420",
        redBroj: "108",
        nazivPu: "Buzet",
        naselje: "Baredine",
        zupanija: "ISTARSKA",
      },
      {
        id: "109",
        brojPu: "52460",
        redBroj: "109",
        nazivPu: "Buje (Buie)",
        naselje: "Baredine - Baredine",
        zupanija: "ISTARSKA",
      },
      {
        id: "110",
        brojPu: "43500",
        redBroj: "110",
        nazivPu: "Daruvar",
        naselje: "Barica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "111",
        brojPu: "53288",
        redBroj: "111",
        nazivPu: "Karlobag",
        naselje: "Barić Draga",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "112",
        brojPu: "52440",
        redBroj: "112",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Barići",
        zupanija: "ISTARSKA",
      },
      {
        id: "113",
        brojPu: "47250",
        redBroj: "113",
        nazivPu: "Duga Resa",
        naselje: "Barilović",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "114",
        brojPu: "48260",
        redBroj: "114",
        nazivPu: "Križevci",
        naselje: "Barlabaševec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "115",
        brojPu: "53000",
        redBroj: "115",
        nazivPu: "Gospić",
        naselje: "Barlete",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "116",
        brojPu: "10454",
        redBroj: "116",
        nazivPu: "Krašić",
        naselje: "Barovka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "117",
        brojPu: "52220",
        redBroj: "117",
        nazivPu: "Labin",
        naselje: "Bartići",
        zupanija: "ISTARSKA",
      },
      {
        id: "118",
        brojPu: "52424",
        redBroj: "118",
        nazivPu: "Motovun (Montona)",
        naselje: "Bartolići",
        zupanija: "ISTARSKA",
      },
      {
        id: "119",
        brojPu: "35252",
        redBroj: "119",
        nazivPu: "Sibinj",
        naselje: "Bartolovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "120",
        brojPu: "42202",
        redBroj: "120",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Bartolovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "121",
        brojPu: "51511",
        redBroj: "121",
        nazivPu: "Malinska",
        naselje: "Barušići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "122",
        brojPu: "52420",
        redBroj: "122",
        nazivPu: "Buzet",
        naselje: "Barušići",
        zupanija: "ISTARSKA",
      },
      {
        id: "123",
        brojPu: "47240",
        redBroj: "123",
        nazivPu: "Slunj",
        naselje: "Basara",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "124",
        brojPu: "21320",
        redBroj: "124",
        nazivPu: "Baška Voda",
        naselje: "Bast",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "125",
        brojPu: "43500",
        redBroj: "125",
        nazivPu: "Daruvar",
        naselje: "Bastajski Brđani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "126",
        brojPu: "52470",
        redBroj: "126",
        nazivPu: "Umag (Umago)",
        naselje: "Bašanija - Bassania",
        zupanija: "ISTARSKA",
      },
      {
        id: "127",
        brojPu: "52440",
        redBroj: "127",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bašarinka",
        zupanija: "ISTARSKA",
      },
      {
        id: "128",
        brojPu: "51523",
        redBroj: "128",
        nazivPu: "Baška",
        naselje: "Baška",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "129",
        brojPu: "21320",
        redBroj: "129",
        nazivPu: "Baška Voda",
        naselje: "Baška Voda",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "130",
        brojPu: "53000",
        redBroj: "130",
        nazivPu: "Gospić",
        naselje: "Baške Oštarije",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "131",
        brojPu: "52440",
        redBroj: "131",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Baškoti",
        zupanija: "ISTARSKA",
      },
      {
        id: "132",
        brojPu: "51218",
        redBroj: "132",
        nazivPu: "Dražice",
        naselje: "Baštijani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "133",
        brojPu: "51250",
        redBroj: "133",
        nazivPu: "Novi Vinodolski",
        naselje: "Bater",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "134",
        brojPu: "31300",
        redBroj: "134",
        nazivPu: "Beli Manastir",
        naselje: "Batina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "135",
        brojPu: "44320",
        redBroj: "135",
        nazivPu: "Kutina",
        naselje: "Batina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "136",
        brojPu: "44400",
        redBroj: "136",
        nazivPu: "Glina",
        naselje: "Batinova Kosa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "137",
        brojPu: "48350",
        redBroj: "137",
        nazivPu: "Đurđevac",
        naselje: "Batinske",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "138",
        brojPu: "34550",
        redBroj: "138",
        nazivPu: "Pakrac",
        naselje: "Batinjani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "139",
        brojPu: "43500",
        redBroj: "139",
        nazivPu: "Daruvar",
        naselje: "Batinjani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "140",
        brojPu: "43500",
        redBroj: "140",
        nazivPu: "Daruvar",
        naselje: "Batinjska Rijeka",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "141",
        brojPu: "52341",
        redBroj: "141",
        nazivPu: "Žminj",
        naselje: "Batlug",
        zupanija: "ISTARSKA",
      },
      {
        id: "142",
        brojPu: "47240",
        redBroj: "142",
        nazivPu: "Slunj",
        naselje: "Batnoga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "143",
        brojPu: "51523",
        redBroj: "143",
        nazivPu: "Baška",
        naselje: "Batomalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "144",
        brojPu: "35410",
        redBroj: "144",
        nazivPu: "Nova Kapela",
        naselje: "Batrina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "145",
        brojPu: "44400",
        redBroj: "145",
        nazivPu: "Glina",
        naselje: "Baturi",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "146",
        brojPu: "52000",
        redBroj: "146",
        nazivPu: "Pazin",
        naselje: "Bazgalji",
        zupanija: "ISTARSKA",
      },
      {
        id: "147",
        brojPu: "51306",
        redBroj: "147",
        nazivPu: "Čabar",
        naselje: "Bazli",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "148",
        brojPu: "35000",
        redBroj: "148",
        nazivPu: "Slavonski Brod",
        naselje: "Bebrina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "149",
        brojPu: "47250",
        redBroj: "149",
        nazivPu: "Duga Resa",
        naselje: "Beč",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "150",
        brojPu: "35250",
        redBroj: "150",
        nazivPu: "Oriovac",
        naselje: "Bečic",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "151",
        brojPu: "49221",
        redBroj: "151",
        nazivPu: "Bedekovčina",
        naselje: "Bedekovčina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "152",
        brojPu: "42240",
        redBroj: "152",
        nazivPu: "Ivanec",
        naselje: "Bedenec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "153",
        brojPu: "10380",
        redBroj: "153",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Bedenica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "154",
        brojPu: "43270",
        redBroj: "154",
        nazivPu: "Veliki Grđevac",
        naselje: "Bedenička",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "155",
        brojPu: "43270",
        redBroj: "155",
        nazivPu: "Veliki Grđevac",
        naselje: "Bedenik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "156",
        brojPu: "10430",
        redBroj: "156",
        nazivPu: "Samobor",
        naselje: "Beder",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "157",
        brojPu: "42250",
        redBroj: "157",
        nazivPu: "Lepoglava",
        naselje: "Bednja",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "158",
        brojPu: "42250",
        redBroj: "158",
        nazivPu: "Lepoglava",
        naselje: "Bednjica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "159",
        brojPu: "52440",
        redBroj: "159",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Begi",
        zupanija: "ISTARSKA",
      },
      {
        id: "160",
        brojPu: "53250",
        redBroj: "160",
        nazivPu: "Donji Lapac",
        naselje: "Begluci",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "161",
        brojPu: "47300",
        redBroj: "161",
        nazivPu: "Ogulin",
        naselje: "Begovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "162",
        brojPu: "43280",
        redBroj: "162",
        nazivPu: "Garešnica",
        naselje: "Begovača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "163",
        brojPu: "44250",
        redBroj: "163",
        nazivPu: "Petrinja",
        naselje: "Begovići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "164",
        brojPu: "47240",
        redBroj: "164",
        nazivPu: "Slunj",
        naselje: "Begovo Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "165",
        brojPu: "10454",
        redBroj: "165",
        nazivPu: "Krašić",
        naselje: "Begovo Brdo Žumberačko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "166",
        brojPu: "51300",
        redBroj: "166",
        nazivPu: "Delnice",
        naselje: "Begovo Razdolje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "167",
        brojPu: "31431",
        redBroj: "167",
        nazivPu: "Čepin",
        naselje: "Beketinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "168",
        brojPu: "48260",
        redBroj: "168",
        nazivPu: "Križevci",
        naselje: "Beketinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "169",
        brojPu: "34340",
        redBroj: "169",
        nazivPu: "Kutjevo",
        naselje: "Bektež",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "170",
        brojPu: "42240",
        redBroj: "170",
        nazivPu: "Ivanec",
        naselje: "Bela",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "171",
        brojPu: "51300",
        redBroj: "171",
        nazivPu: "Delnice",
        naselje: "Bela Vodica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "172",
        brojPu: "47250",
        redBroj: "172",
        nazivPu: "Duga Resa",
        naselje: "Belaj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "173",
        brojPu: "52420",
        redBroj: "173",
        nazivPu: "Buzet",
        naselje: "Belaj",
        zupanija: "ISTARSKA",
      },
      {
        id: "174",
        brojPu: "47250",
        redBroj: "174",
        nazivPu: "Duga Resa",
        naselje: "Belajska Vinica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "175",
        brojPu: "47250",
        redBroj: "175",
        nazivPu: "Duga Resa",
        naselje: "Belajske Poljice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "176",
        brojPu: "47250",
        redBroj: "176",
        nazivPu: "Duga Resa",
        naselje: "Belajski Malinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "177",
        brojPu: "48000",
        redBroj: "177",
        nazivPu: "Koprivnica",
        naselje: "Belanovo Selo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "178",
        brojPu: "47250",
        redBroj: "178",
        nazivPu: "Duga Resa",
        naselje: "Belavići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "179",
        brojPu: "52207",
        redBroj: "179",
        nazivPu: "Barban",
        naselje: "Belavići",
        zupanija: "ISTARSKA",
      },
      {
        id: "180",
        brojPu: "10450",
        redBroj: "180",
        nazivPu: "Jastrebarsko",
        naselje: "Belčići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "181",
        brojPu: "49250",
        redBroj: "181",
        nazivPu: "Zlatar ",
        naselje: "Belec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "182",
        brojPu: "51550",
        redBroj: "182",
        nazivPu: "Mali Lošinj",
        naselje: "Belej",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "183",
        brojPu: "42204",
        redBroj: "183",
        nazivPu: "Turčin",
        naselje: "Beletinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "184",
        brojPu: "51557",
        redBroj: "184",
        nazivPu: "Cres",
        naselje: "Beli",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "185",
        brojPu: "31300",
        redBroj: "185",
        nazivPu: "Beli Manastir",
        naselje: "Beli Manastir",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "186",
        brojPu: "40000",
        redBroj: "186",
        nazivPu: "Čakovec",
        naselje: "Belica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "187",
        brojPu: "47280",
        redBroj: "187",
        nazivPu: "Ozalj",
        naselje: "Belinsko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "188",
        brojPu: "31551",
        redBroj: "188",
        nazivPu: "Belišće",
        naselje: "Belišće",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "189",
        brojPu: "51300",
        redBroj: "189",
        nazivPu: "Delnice",
        naselje: "Belo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "190",
        brojPu: "51300",
        redBroj: "190",
        nazivPu: "Delnice",
        naselje: "Belo Selo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "191",
        brojPu: "10380",
        redBroj: "191",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Beloslavec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "192",
        brojPu: "47280",
        redBroj: "192",
        nazivPu: "Ozalj",
        naselje: "Belošići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "193",
        brojPu: "10363",
        redBroj: "193",
        nazivPu: "Belovar",
        naselje: "Belovar",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "194",
        brojPu: "49221",
        redBroj: "194",
        nazivPu: "Bedekovčina",
        naselje: "Belovar Zlatarski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "195",
        brojPu: "51300",
        redBroj: "195",
        nazivPu: "Delnice",
        naselje: "Belski Ravan",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "196",
        brojPu: "31511",
        redBroj: "196",
        nazivPu: "Đurđenovac",
        naselje: "Beljevina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "197",
        brojPu: "52460",
        redBroj: "197",
        nazivPu: "Buje (Buie)",
        naselje: "Bencani - Benzani",
        zupanija: "ISTARSKA",
      },
      {
        id: "198",
        brojPu: "52440",
        redBroj: "198",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Benčani",
        zupanija: "ISTARSKA",
      },
      {
        id: "199",
        brojPu: "52341",
        redBroj: "199",
        nazivPu: "Žminj",
        naselje: "Benčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "200",
        brojPu: "52420",
        redBroj: "200",
        nazivPu: "Buzet",
        naselje: "Benčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "201",
        brojPu: "31540",
        redBroj: "201",
        nazivPu: "Donji Miholjac",
        naselje: "Beničanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "202",
        brojPu: "23420",
        redBroj: "202",
        nazivPu: "Benkovac",
        naselje: "Benkovac",
        zupanija: "ZADARSKA",
      },
      {
        id: "203",
        brojPu: "35430",
        redBroj: "203",
        nazivPu: "Okučani",
        naselje: "Benkovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "204",
        brojPu: "51300",
        redBroj: "204",
        nazivPu: "Delnice",
        naselje: "Benkovac Fužinski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "205",
        brojPu: "23420",
        redBroj: "205",
        nazivPu: "Benkovac",
        naselje: "Benkovačko Selo",
        zupanija: "ZADARSKA",
      },
      {
        id: "206",
        brojPu: "42250",
        redBroj: "206",
        nazivPu: "Lepoglava",
        naselje: "Benkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "207",
        brojPu: "49000",
        redBroj: "207",
        nazivPu: "Krapina",
        naselje: "Benkovec Petrovski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "208",
        brojPu: "49218",
        redBroj: "208",
        nazivPu: "Pregrada",
        naselje: "Benkovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "209",
        brojPu: "32242",
        redBroj: "209",
        nazivPu: "Slakovci",
        naselje: "Berak",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "210",
        brojPu: "52000",
        redBroj: "210",
        nazivPu: "Pazin",
        naselje: "Beram",
        zupanija: "ISTARSKA",
      },
      {
        id: "211",
        brojPu: "35222",
        redBroj: "211",
        nazivPu: "Gundinci",
        naselje: "Beravci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "212",
        brojPu: "43000",
        redBroj: "212",
        nazivPu: "Bjelovar",
        naselje: "Berek",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "213",
        brojPu: "42204",
        redBroj: "213",
        nazivPu: "Turčin",
        naselje: "Beretinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "214",
        brojPu: "10380",
        redBroj: "214",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Berislavec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "215",
        brojPu: "34000",
        redBroj: "215",
        nazivPu: "Požega",
        naselje: "Bertelovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "216",
        brojPu: "52000",
        redBroj: "216",
        nazivPu: "Pazin",
        naselje: "Bertoši",
        zupanija: "ISTARSKA",
      },
      {
        id: "217",
        brojPu: "10431",
        redBroj: "217",
        nazivPu: "Sveta Nedelja",
        naselje: "Bestovje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "218",
        brojPu: "44210",
        redBroj: "218",
        nazivPu: "Sunja",
        naselje: "Bestrma",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "219",
        brojPu: "34000",
        redBroj: "219",
        nazivPu: "Požega",
        naselje: "Bešinci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "220",
        brojPu: "10310",
        redBroj: "220",
        nazivPu: "Ivanić Grad",
        naselje: "Bešlinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "221",
        brojPu: "10450",
        redBroj: "221",
        nazivPu: "Jastrebarsko",
        naselje: "Beter",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "222",
        brojPu: "22243",
        redBroj: "222",
        nazivPu: "Murter",
        naselje: "Betina",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "223",
        brojPu: "49210",
        redBroj: "223",
        nazivPu: "Zabok",
        naselje: "Bezavina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "224",
        brojPu: "52460",
        redBroj: "224",
        nazivPu: "Buje (Buie)",
        naselje: "Bibali - Bibali",
        zupanija: "ISTARSKA",
      },
      {
        id: "225",
        brojPu: "52341",
        redBroj: "225",
        nazivPu: "Žminj",
        naselje: "Bibići",
        zupanija: "ISTARSKA",
      },
      {
        id: "226",
        brojPu: "23205",
        redBroj: "226",
        nazivPu: "Bibinje",
        naselje: "Bibinje",
        zupanija: "ZADARSKA",
      },
      {
        id: "227",
        brojPu: "35212",
        redBroj: "227",
        nazivPu: "Garčin",
        naselje: "Bicko Selo",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "228",
        brojPu: "52207",
        redBroj: "228",
        nazivPu: "Barban",
        naselje: "Bičići",
        zupanija: "ISTARSKA",
      },
      {
        id: "229",
        brojPu: "22222",
        redBroj: "229",
        nazivPu: "Skradin",
        naselje: "Bićine",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "230",
        brojPu: "43500",
        redBroj: "230",
        nazivPu: "Daruvar",
        naselje: "Bijela",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "231",
        brojPu: "10291",
        redBroj: "231",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Bijela Gorica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "232",
        brojPu: "31500",
        redBroj: "232",
        nazivPu: "Našice",
        naselje: "Bijela Loza",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "233",
        brojPu: "35430",
        redBroj: "233",
        nazivPu: "Okučani",
        naselje: "Bijela Stijena",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "234",
        brojPu: "44400",
        redBroj: "234",
        nazivPu: "Glina",
        naselje: "Bijele Vode",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "235",
        brojPu: "52460",
        redBroj: "235",
        nazivPu: "Buje (Buie)",
        naselje: "Bijele Zemlje - Terre Bianche",
        zupanija: "ISTARSKA",
      },
      {
        id: "236",
        brojPu: "47220",
        redBroj: "236",
        nazivPu: "Vojnić",
        naselje: "Bijeli Klanac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "237",
        brojPu: "20350",
        redBroj: "237",
        nazivPu: "Metković",
        naselje: "Bijeli Vir",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "238",
        brojPu: "44250",
        redBroj: "238",
        nazivPu: "Petrinja",
        naselje: "Bijelnik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "239",
        brojPu: "31226",
        redBroj: "239",
        nazivPu: "Dalj",
        naselje: "Bijelo Brdo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "240",
        brojPu: "33515",
        redBroj: "240",
        nazivPu: "Orahovica",
        naselje: "Bijeljevina Orahovička",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "241",
        brojPu: "42243",
        redBroj: "241",
        nazivPu: "Maruševec",
        naselje: "Bikovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "242",
        brojPu: "23422",
        redBroj: "242",
        nazivPu: "Stankovci",
        naselje: "Bila Vlaka",
        zupanija: "ZADARSKA",
      },
      {
        id: "243",
        brojPu: "53000",
        redBroj: "243",
        nazivPu: "Gospić",
        naselje: "Bilaj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "244",
        brojPu: "51250",
        redBroj: "244",
        nazivPu: "Novi Vinodolski",
        naselje: "Bile",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "245",
        brojPu: "35410",
        redBroj: "245",
        nazivPu: "Nova Kapela",
        naselje: "Bili Brig",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "246",
        brojPu: "22000",
        redBroj: "246",
        nazivPu: "Šibenik",
        naselje: "Bilice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "247",
        brojPu: "34310",
        redBroj: "247",
        nazivPu: "Pleternica",
        naselje: "Bilice",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "248",
        brojPu: "23450",
        redBroj: "248",
        nazivPu: "Obrovac",
        naselje: "Bilišane",
        zupanija: "ZADARSKA",
      },
      {
        id: "249",
        brojPu: "47240",
        redBroj: "249",
        nazivPu: "Slunj",
        naselje: "Bilo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "250",
        brojPu: "31326",
        redBroj: "250",
        nazivPu: "Darda",
        naselje: "Bilje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "251",
        brojPu: "42243",
        redBroj: "251",
        nazivPu: "Maruševec",
        naselje: "Biljevec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "252",
        brojPu: "51300",
        redBroj: "252",
        nazivPu: "Delnice",
        naselje: "Biljevina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "253",
        brojPu: "53270",
        redBroj: "253",
        nazivPu: "Senj",
        naselje: "Biljevine",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "254",
        brojPu: "22300",
        redBroj: "254",
        nazivPu: "Knin",
        naselje: "Biočić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "255",
        brojPu: "23210",
        redBroj: "255",
        nazivPu: "Biograd na Moru",
        naselje: "Biograd na Moru",
        zupanija: "ZADARSKA",
      },
      {
        id: "256",
        brojPu: "21270",
        redBroj: "256",
        nazivPu: "Zagvozd",
        naselje: "Biokovsko Selo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "257",
        brojPu: "21256",
        redBroj: "257",
        nazivPu: "Cista Provo",
        naselje: "Biorine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "258",
        brojPu: "22300",
        redBroj: "258",
        nazivPu: "Knin",
        naselje: "Biovičino Selo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "259",
        brojPu: "53250",
        redBroj: "259",
        nazivPu: "Donji Lapac",
        naselje: "Birovača",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "260",
        brojPu: "42220",
        redBroj: "260",
        nazivPu: "Novi Marof",
        naselje: "Bisag",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "261",
        brojPu: "21232",
        redBroj: "261",
        nazivPu: "Dicmo",
        naselje: "Bisko",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "262",
        brojPu: "22300",
        redBroj: "262",
        nazivPu: "Knin",
        naselje: "Biskupija",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "263",
        brojPu: "44210",
        redBroj: "263",
        nazivPu: "Sunja",
        naselje: "Bistrač",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "264",
        brojPu: "33520",
        redBroj: "264",
        nazivPu: "Slatina",
        naselje: "Bistrica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "265",
        brojPu: "31551",
        redBroj: "265",
        nazivPu: "Belišće",
        naselje: "Bistrinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "266",
        brojPu: "44400",
        redBroj: "266",
        nazivPu: "Glina",
        naselje: "Bišćanovo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "267",
        brojPu: "21485",
        redBroj: "267",
        nazivPu: "Komiža",
        naselje: "Biševo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "268",
        brojPu: "34000",
        redBroj: "268",
        nazivPu: "Požega",
        naselje: "Biškupci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "269",
        brojPu: "10380",
        redBroj: "269",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Biškupec Zelinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "270",
        brojPu: "47250",
        redBroj: "270",
        nazivPu: "Duga Resa",
        naselje: "Bitorajci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "271",
        brojPu: "31550",
        redBroj: "271",
        nazivPu: "Valpovo",
        naselje: "Bizovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "272",
        brojPu: "34550",
        redBroj: "272",
        nazivPu: "Pakrac",
        naselje: "Bjelajci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "273",
        brojPu: "34551",
        redBroj: "273",
        nazivPu: "Lipik",
        naselje: "Bjelanovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "274",
        brojPu: "23420",
        redBroj: "274",
        nazivPu: "Benkovac",
        naselje: "Bjelina",
        zupanija: "ZADARSKA",
      },
      {
        id: "275",
        brojPu: "34340",
        redBroj: "275",
        nazivPu: "Kutjevo",
        naselje: "Bjeliševac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "276",
        brojPu: "33520",
        redBroj: "276",
        nazivPu: "Slatina",
        naselje: "Bjelkovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "277",
        brojPu: "53230",
        redBroj: "277",
        nazivPu: "Korenica",
        naselje: "Bjelopolje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "278",
        brojPu: "43000",
        redBroj: "278",
        nazivPu: "Bjelovar",
        naselje: "Bjelovar",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "279",
        brojPu: "21210",
        redBroj: "279",
        nazivPu: "Solin",
        naselje: "Blaca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "280",
        brojPu: "20355",
        redBroj: "280",
        nazivPu: "Opuzen",
        naselje: "Blace",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "281",
        brojPu: "34310",
        redBroj: "281",
        nazivPu: "Pleternica",
        naselje: "Blacko",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "282",
        brojPu: "47240",
        redBroj: "282",
        nazivPu: "Slunj",
        naselje: "Blagaj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "283",
        brojPu: "52440",
        redBroj: "283",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Blagdanići",
        zupanija: "ISTARSKA",
      },
      {
        id: "284",
        brojPu: "43280",
        redBroj: "284",
        nazivPu: "Garešnica",
        naselje: "Blagorodovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "285",
        brojPu: "10362",
        redBroj: "285",
        nazivPu: "Kašina",
        naselje: "Blaguša",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "286",
        brojPu: "31540",
        redBroj: "286",
        nazivPu: "Donji Miholjac",
        naselje: "Blanje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "287",
        brojPu: "10380",
        redBroj: "287",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Blaškovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "288",
        brojPu: "52333",
        redBroj: "288",
        nazivPu: "Podpićan",
        naselje: "Blaškovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "289",
        brojPu: "47300",
        redBroj: "289",
        nazivPu: "Ogulin",
        naselje: "Blata",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "290",
        brojPu: "52420",
        redBroj: "290",
        nazivPu: "Buzet",
        naselje: "Blatna Vas",
        zupanija: "ISTARSKA",
      },
      {
        id: "291",
        brojPu: "43240",
        redBroj: "291",
        nazivPu: "Čazma",
        naselje: "Blatnica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "292",
        brojPu: "47000",
        redBroj: "292",
        nazivPu: "Karlovac",
        naselje: "Blatnica Pokupska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "293",
        brojPu: "20225",
        redBroj: "293",
        nazivPu: "Babino Polje",
        naselje: "Blato",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "294",
        brojPu: "20271",
        redBroj: "294",
        nazivPu: "Blato",
        naselje: "Blato",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "295",
        brojPu: "21250",
        redBroj: "295",
        nazivPu: "Šestanovac",
        naselje: "Blato na Cetini",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "296",
        brojPu: "44410",
        redBroj: "296",
        nazivPu: "Vrginmost",
        naselje: "Blatuša",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "297",
        brojPu: "51326",
        redBroj: "297",
        nazivPu: "Vrbovsko",
        naselje: "Blaževci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "298",
        brojPu: "10380",
        redBroj: "298",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Blaževdol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "299",
        brojPu: "35420",
        redBroj: "299",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Blažević Dol",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "300",
        brojPu: "44250",
        redBroj: "300",
        nazivPu: "Petrinja",
        naselje: "Blinja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "301",
        brojPu: "44210",
        redBroj: "301",
        nazivPu: "Sunja",
        naselje: "Blinjska Greda",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "302",
        brojPu: "44010",
        redBroj: "302",
        nazivPu: "Sisak-Caprag",
        naselje: "Blinjski Kut",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "303",
        brojPu: "21222",
        redBroj: "303",
        nazivPu: "Marina",
        naselje: "Blizna Donja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "304",
        brojPu: "21222",
        redBroj: "304",
        nazivPu: "Marina",
        naselje: "Blizna Gornja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "305",
        brojPu: "35430",
        redBroj: "305",
        nazivPu: "Okučani",
        naselje: "Bobare",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "306",
        brojPu: "22320",
        redBroj: "306",
        nazivPu: "Drniš",
        naselje: "Bobodol",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "307",
        brojPu: "32000",
        redBroj: "307",
        nazivPu: "Vukovar",
        naselje: "Bobota",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "308",
        brojPu: "44210",
        redBroj: "308",
        nazivPu: "Sunja",
        naselje: "Bobovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "309",
        brojPu: "10292",
        redBroj: "309",
        nazivPu: "Šenkovec",
        naselje: "Bobovec Rozganski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "310",
        brojPu: "49290",
        redBroj: "310",
        nazivPu: "Klanjec",
        naselje: "Bobovec Tomaševečki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "311",
        brojPu: "10430",
        redBroj: "311",
        nazivPu: "Samobor",
        naselje: "Bobovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "312",
        brojPu: "21405",
        redBroj: "312",
        nazivPu: "Milna",
        naselje: "Bobovišća",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "313",
        brojPu: "49000",
        redBroj: "313",
        nazivPu: "Krapina",
        naselje: "Bobovje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "314",
        brojPu: "31550",
        redBroj: "314",
        nazivPu: "Valpovo",
        naselje: "Bocanjevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "315",
        brojPu: "31540",
        redBroj: "315",
        nazivPu: "Donji Miholjac",
        naselje: "Bockovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "316",
        brojPu: "49282",
        redBroj: "316",
        nazivPu: "Konjščina",
        naselje: "Bočadir",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "317",
        brojPu: "49282",
        redBroj: "317",
        nazivPu: "Konjščina",
        naselje: "Bočaki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "318",
        brojPu: "31540",
        redBroj: "318",
        nazivPu: "Donji Miholjac",
        naselje: "Bočkinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "319",
        brojPu: "48260",
        redBroj: "319",
        nazivPu: "Križevci",
        naselje: "Bočkovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "320",
        brojPu: "35430",
        redBroj: "320",
        nazivPu: "Okučani",
        naselje: "Bodegraj",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "321",
        brojPu: "35420",
        redBroj: "321",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Bodovaljci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "322",
        brojPu: "48260",
        redBroj: "322",
        nazivPu: "Križevci",
        naselje: "Bogačevo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "323",
        brojPu: "48260",
        redBroj: "323",
        nazivPu: "Križevci",
        naselje: "Bogačevo Riječko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "324",
        brojPu: "22320",
        redBroj: "324",
        nazivPu: "Drniš",
        naselje: "Bogatić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "325",
        brojPu: "22320",
        redBroj: "325",
        nazivPu: "Drniš",
        naselje: "Bogatić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "326",
        brojPu: "23450",
        redBroj: "326",
        nazivPu: "Obrovac",
        naselje: "Bogatnik",
        zupanija: "ZADARSKA",
      },
      {
        id: "327",
        brojPu: "32000",
        redBroj: "327",
        nazivPu: "Vukovar",
        naselje: "Bogdanovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "328",
        brojPu: "40000",
        redBroj: "328",
        nazivPu: "Čakovec",
        naselje: "Bogdanovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "329",
        brojPu: "21220",
        redBroj: "329",
        nazivPu: "Trogir",
        naselje: "Bogdanovići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "330",
        brojPu: "34000",
        redBroj: "330",
        nazivPu: "Požega",
        naselje: "Bogdašić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "331",
        brojPu: "21469",
        redBroj: "331",
        nazivPu: "Sućuraj",
        naselje: "Bogomolje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "332",
        brojPu: "47000",
        redBroj: "332",
        nazivPu: "Karlovac",
        naselje: "Bogovci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "333",
        brojPu: "51511",
        redBroj: "333",
        nazivPu: "Malinska",
        naselje: "Bogovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "334",
        brojPu: "47240",
        redBroj: "334",
        nazivPu: "Slunj",
        naselje: "Bogovolja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "335",
        brojPu: "49290",
        redBroj: "335",
        nazivPu: "Klanjec",
        naselje: "Bojačno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "336",
        brojPu: "43240",
        redBroj: "336",
        nazivPu: "Čazma",
        naselje: "Bojana",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "337",
        brojPu: "44400",
        redBroj: "337",
        nazivPu: "Glina",
        naselje: "Bojna",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "338",
        brojPu: "48260",
        redBroj: "338",
        nazivPu: "Križevci",
        naselje: "Bojnikovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "339",
        brojPu: "44000",
        redBroj: "339",
        nazivPu: "Sisak",
        naselje: "Bok Palanječki",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "340",
        brojPu: "33520",
        redBroj: "340",
        nazivPu: "Slatina",
        naselje: "Bokane",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "341",
        brojPu: "52440",
        redBroj: "341",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bokići",
        zupanija: "ISTARSKA",
      },
      {
        id: "342",
        brojPu: "52341",
        redBroj: "342",
        nazivPu: "Žminj",
        naselje: "Bokordići",
        zupanija: "ISTARSKA",
      },
      {
        id: "343",
        brojPu: "31511",
        redBroj: "343",
        nazivPu: "Đurđenovac",
        naselje: "Bokšić",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "344",
        brojPu: "32242",
        redBroj: "344",
        nazivPu: "Slakovci",
        naselje: "Bokšić",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "345",
        brojPu: "31511",
        redBroj: "345",
        nazivPu: "Đurđenovac",
        naselje: "Bokšić Lug",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "346",
        brojPu: "21420",
        redBroj: "346",
        nazivPu: "Bol",
        naselje: "Bol",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "347",
        brojPu: "10342",
        redBroj: "347",
        nazivPu: "Dubrava",
        naselje: "Bolč",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "348",
        brojPu: "42230",
        redBroj: "348",
        nazivPu: "Ludbreg",
        naselje: "Bolfan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "349",
        brojPu: "31300",
        redBroj: "349",
        nazivPu: "Beli Manastir",
        naselje: "Bolman",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "350",
        brojPu: "34000",
        redBroj: "350",
        nazivPu: "Požega",
        naselje: "Bolomače",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "351",
        brojPu: "20230",
        redBroj: "351",
        nazivPu: "Ston",
        naselje: "Boljenovići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "352",
        brojPu: "52333",
        redBroj: "352",
        nazivPu: "Podpićan",
        naselje: "Boljevići",
        zupanija: "ISTARSKA",
      },
      {
        id: "353",
        brojPu: "52420",
        redBroj: "353",
        nazivPu: "Buzet",
        naselje: "Boljun",
        zupanija: "ISTARSKA",
      },
      {
        id: "354",
        brojPu: "52420",
        redBroj: "354",
        nazivPu: "Buzet",
        naselje: "Boljunsko Polje",
        zupanija: "ISTARSKA",
      },
      {
        id: "355",
        brojPu: "52440",
        redBroj: "355",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bonaci",
        zupanija: "ISTARSKA",
      },
      {
        id: "356",
        brojPu: "22000",
        redBroj: "356",
        nazivPu: "Šibenik",
        naselje: "Boraja",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "357",
        brojPu: "21310",
        redBroj: "357",
        nazivPu: "Omiš",
        naselje: "Borak",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "358",
        brojPu: "42220",
        redBroj: "358",
        nazivPu: "Novi Marof",
        naselje: "Borenec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "359",
        brojPu: "53250",
        redBroj: "359",
        nazivPu: "Donji Lapac",
        naselje: "Boričevac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "360",
        brojPu: "34000",
        redBroj: "360",
        nazivPu: "Požega",
        naselje: "Boričevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "361",
        brojPu: "42223",
        redBroj: "361",
        nazivPu: "Varaždinske Toplice",
        naselje: "Boričevec Toplički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "362",
        brojPu: "33515",
        redBroj: "362",
        nazivPu: "Orahovica",
        naselje: "Borik",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "363",
        brojPu: "52207",
        redBroj: "363",
        nazivPu: "Barban",
        naselje: "Borinići",
        zupanija: "ISTARSKA",
      },
      {
        id: "364",
        brojPu: "43500",
        redBroj: "364",
        nazivPu: "Daruvar",
        naselje: "Boriš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "365",
        brojPu: "48260",
        redBroj: "365",
        nazivPu: "Križevci",
        naselje: "Borje",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "366",
        brojPu: "49250",
        redBroj: "366",
        nazivPu: "Zlatar",
        naselje: "Borkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "367",
        brojPu: "31400",
        redBroj: "367",
        nazivPu: "Đakovo",
        naselje: "Borojevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "368",
        brojPu: "44430",
        redBroj: "368",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Borojevići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "369",
        brojPu: "33410",
        redBroj: "369",
        nazivPu: "Suhopolje",
        naselje: "Borova",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "370",
        brojPu: "43500",
        redBroj: "370",
        nazivPu: "Daruvar",
        naselje: "Borova Kosa",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "371",
        brojPu: "44330",
        redBroj: "371",
        nazivPu: "Novska",
        naselje: "Borovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "372",
        brojPu: "20350",
        redBroj: "372",
        nazivPu: "Metković",
        naselje: "Borovci",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "373",
        brojPu: "21480",
        redBroj: "373",
        nazivPu: "Vis",
        naselje: "Borovik",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "374",
        brojPu: "31400",
        redBroj: "374",
        nazivPu: "Đakovo",
        naselje: "Borovik",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "375",
        brojPu: "44400",
        redBroj: "375",
        nazivPu: "Glina",
        naselje: "Borovita",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "376",
        brojPu: "48000",
        redBroj: "376",
        nazivPu: "Koprivnica",
        naselje: "Borovljani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "377",
        brojPu: "32000",
        redBroj: "377",
        nazivPu: "Vukovar",
        naselje: "Borovo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "378",
        brojPu: "52000",
        redBroj: "378",
        nazivPu: "Pazin",
        naselje: "Borut",
        zupanija: "ISTARSKA",
      },
      {
        id: "379",
        brojPu: "47250",
        redBroj: "379",
        nazivPu: "Duga Resa",
        naselje: "Bosanci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "380",
        brojPu: "20207",
        redBroj: "380",
        nazivPu: "Mlini",
        naselje: "Bosanka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "381",
        brojPu: "43240",
        redBroj: "381",
        nazivPu: "Čazma",
        naselje: "Bosiljevo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "382",
        brojPu: "47250",
        redBroj: "382",
        nazivPu: "Duga Resa",
        naselje: "Bosiljevo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "383",
        brojPu: "10380",
        redBroj: "383",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Bosna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "384",
        brojPu: "23250",
        redBroj: "384",
        nazivPu: "Pag",
        naselje: "Bošana",
        zupanija: "ZADARSKA",
      },
      {
        id: "385",
        brojPu: "47280",
        redBroj: "385",
        nazivPu: "Ozalj",
        naselje: "Boševci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "386",
        brojPu: "52341",
        redBroj: "386",
        nazivPu: "Žminj",
        naselje: "Boškari",
        zupanija: "ISTARSKA",
      },
      {
        id: "387",
        brojPu: "32270",
        redBroj: "387",
        nazivPu: "Županja",
        naselje: "Bošnjaci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "388",
        brojPu: "47250",
        redBroj: "388",
        nazivPu: "Duga Resa",
        naselje: "Bošt",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "389",
        brojPu: "43000",
        redBroj: "389",
        nazivPu: "Bjelovar",
        naselje: "Botinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "390",
        brojPu: "10020",
        redBroj: "390",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Botinec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "391",
        brojPu: "48000",
        redBroj: "391",
        nazivPu: "Koprivnica",
        naselje: "Botinovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "392",
        brojPu: "48316",
        redBroj: "392",
        nazivPu: "Đelekovec",
        naselje: "Botovo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "393",
        brojPu: "44410",
        redBroj: "393",
        nazivPu: "Vrginmost",
        naselje: "Bović",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "394",
        brojPu: "23286",
        redBroj: "394",
        nazivPu: "Božava",
        naselje: "Božava",
        zupanija: "ZADARSKA",
      },
      {
        id: "395",
        brojPu: "10370",
        redBroj: "395",
        nazivPu: "Dugo Selo",
        naselje: "Božjakovina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "396",
        brojPu: "49210",
        redBroj: "396",
        nazivPu: "Zabok",
        naselje: "Bračak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "397",
        brojPu: "31400",
        redBroj: "397",
        nazivPu: "Đakovo",
        naselje: "Bračevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "398",
        brojPu: "21203",
        redBroj: "398",
        nazivPu: "Donji Muć",
        naselje: "Bračević",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "399",
        brojPu: "47000",
        redBroj: "399",
        nazivPu: "Karlovac",
        naselje: "Brajakovo Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "400",
        brojPu: "47240",
        redBroj: "400",
        nazivPu: "Slunj",
        naselje: "Brajdić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "401",
        brojPu: "52000",
        redBroj: "401",
        nazivPu: "Pazin",
        naselje: "Brajkovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "402",
        brojPu: "52341",
        redBroj: "402",
        nazivPu: "Žminj",
        naselje: "Brajkovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "403",
        brojPu: "52440",
        redBroj: "403",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bralići",
        zupanija: "ISTARSKA",
      },
      {
        id: "404",
        brojPu: "34550",
        redBroj: "404",
        nazivPu: "Pakrac",
        naselje: "Branešci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "405",
        brojPu: "31500",
        redBroj: "405",
        nazivPu: "Našice",
        naselje: "Branimirovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "406",
        brojPu: "31300",
        redBroj: "406",
        nazivPu: "Beli Manastir",
        naselje: "Branjin Vrh",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "407",
        brojPu: "31300",
        redBroj: "407",
        nazivPu: "Beli Manastir",
        naselje: "Branjina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "408",
        brojPu: "10430",
        redBroj: "408",
        nazivPu: "Samobor",
        naselje: "Braslovje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "409",
        brojPu: "20207",
        redBroj: "409",
        nazivPu: "Mlini",
        naselje: "Brašina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "410",
        brojPu: "47280",
        redBroj: "410",
        nazivPu: "Ozalj",
        naselje: "Brašljevica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "411",
        brojPu: "10454",
        redBroj: "411",
        nazivPu: "Krašić",
        naselje: "Bratelji",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "412",
        brojPu: "10451",
        redBroj: "412",
        nazivPu: "Pisarovina",
        naselje: "Bratina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "413",
        brojPu: "22222",
        redBroj: "413",
        nazivPu: "Skradin",
        naselje: "Bratiškovci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "414",
        brojPu: "49290",
        redBroj: "414",
        nazivPu: "Klanjec",
        naselje: "Bratkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "415",
        brojPu: "47280",
        redBroj: "415",
        nazivPu: "Ozalj",
        naselje: "Bratovanci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "416",
        brojPu: "52440",
        redBroj: "416",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bratovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "417",
        brojPu: "49290",
        redBroj: "417",
        nazivPu: "Klanjec",
        naselje: "Bratovski Vrh",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "418",
        brojPu: "52207",
        redBroj: "418",
        nazivPu: "Barban",
        naselje: "Bratulići",
        zupanija: "ISTARSKA",
      },
      {
        id: "419",
        brojPu: "34000",
        redBroj: "419",
        nazivPu: "Požega",
        naselje: "Bratuljevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "420",
        brojPu: "23287",
        redBroj: "420",
        nazivPu: "Veli Rat",
        naselje: "Brbinj",
        zupanija: "ZADARSKA",
      },
      {
        id: "421",
        brojPu: "47250",
        redBroj: "421",
        nazivPu: "Duga Resa",
        naselje: "Brcković Draga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "422",
        brojPu: "10370",
        redBroj: "422",
        nazivPu: "Dugo Selo",
        naselje: "Brckovljani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "423",
        brojPu: "10340",
        redBroj: "423",
        nazivPu: "Vrbovec",
        naselje: "Brčevec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "424",
        brojPu: "52000",
        redBroj: "424",
        nazivPu: "Pazin",
        naselje: "Brčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "425",
        brojPu: "52440",
        redBroj: "425",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Brčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "426",
        brojPu: "35252",
        redBroj: "426",
        nazivPu: "Sibinj",
        naselje: "Brčino",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "427",
        brojPu: "51211",
        redBroj: "427",
        nazivPu: "Matulji",
        naselje: "Brdce",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "428",
        brojPu: "52460",
        redBroj: "428",
        nazivPu: "Buje (Buie)",
        naselje: "Brdo - Collato",
        zupanija: "ISTARSKA",
      },
      {
        id: "429",
        brojPu: "48214",
        redBroj: "429",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Brdo Cirkvensko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "430",
        brojPu: "49225",
        redBroj: "430",
        nazivPu: "Đurmanec",
        naselje: "Brdo Jesenjsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "431",
        brojPu: "48260",
        redBroj: "431",
        nazivPu: "Križevci",
        naselje: "Brdo Orehovečko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "432",
        brojPu: "47220",
        redBroj: "432",
        nazivPu: "Vojnić",
        naselje: "Brdo Utinjsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "433",
        brojPu: "10291",
        redBroj: "433",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Brdovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "434",
        brojPu: "35252",
        redBroj: "434",
        nazivPu: "Sibinj",
        naselje: "Brđani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "435",
        brojPu: "35420",
        redBroj: "435",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Brđani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "436",
        brojPu: "44210",
        redBroj: "436",
        nazivPu: "Sunja",
        naselje: "Brđani Cesta",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "437",
        brojPu: "44210",
        redBroj: "437",
        nazivPu: "Sunja",
        naselje: "Brđani Kosa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "438",
        brojPu: "48000",
        redBroj: "438",
        nazivPu: "Koprivnica",
        naselje: "Brđani Sokolovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "439",
        brojPu: "10257",
        redBroj: "439",
        nazivPu: "Brezovica",
        naselje: "Brebernica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "440",
        brojPu: "47220",
        redBroj: "440",
        nazivPu: "Vojnić",
        naselje: "Brebornica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "441",
        brojPu: "10450",
        redBroj: "441",
        nazivPu: "Jastrebarsko",
        naselje: "Brebrovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "442",
        brojPu: "52000",
        redBroj: "442",
        nazivPu: "Pazin",
        naselje: "Brečevići",
        zupanija: "ISTARSKA",
      },
      {
        id: "443",
        brojPu: "20350",
        redBroj: "443",
        nazivPu: "Metković",
        naselje: "Brečići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "444",
        brojPu: "52220",
        redBroj: "444",
        nazivPu: "Labin",
        naselje: "Breg",
        zupanija: "ISTARSKA",
      },
      {
        id: "445",
        brojPu: "10380",
        redBroj: "445",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Breg Mokrički",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "446",
        brojPu: "10430",
        redBroj: "446",
        nazivPu: "Samobor",
        naselje: "Bregana",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "447",
        brojPu: "10451",
        redBroj: "447",
        nazivPu: "Pisarovina",
        naselje: "Bregana Pisarovinska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "448",
        brojPu: "10430",
        redBroj: "448",
        nazivPu: "Samobor",
        naselje: "Breganica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "449",
        brojPu: "51211",
        redBroj: "449",
        nazivPu: "Matulji",
        naselje: "Bregi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "450",
        brojPu: "49218",
        redBroj: "450",
        nazivPu: "Pregrada",
        naselje: "Bregi Kostelski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "451",
        brojPu: "49000",
        redBroj: "451",
        nazivPu: "Krapina",
        naselje: "Bregi Radobojski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "452",
        brojPu: "49210",
        redBroj: "452",
        nazivPu: "Zabok",
        naselje: "Bregi Zabočki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "453",
        brojPu: "10290",
        redBroj: "453",
        nazivPu: "Zaprešić",
        naselje: "Bregovljana",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "454",
        brojPu: "34551",
        redBroj: "454",
        nazivPu: "Lipik",
        naselje: "Brekinska",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "455",
        brojPu: "21320",
        redBroj: "455",
        nazivPu: "Baška Voda",
        naselje: "Brela",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "456",
        brojPu: "34310",
        redBroj: "456",
        nazivPu: "Pleternica",
        naselje: "Bresnica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "457",
        brojPu: "52420",
        redBroj: "457",
        nazivPu: "Buzet",
        naselje: "Brest",
        zupanija: "ISTARSKA",
      },
      {
        id: "458",
        brojPu: "52420",
        redBroj: "458",
        nazivPu: "Buzet",
        naselje: "Brest pod Učkom",
        zupanija: "ISTARSKA",
      },
      {
        id: "459",
        brojPu: "44250",
        redBroj: "459",
        nazivPu: "Petrinja",
        naselje: "Brest Pokupski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "460",
        brojPu: "44330",
        redBroj: "460",
        nazivPu: "Novska",
        naselje: "Brestača",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "461",
        brojPu: "44400",
        redBroj: "461",
        nazivPu: "Glina",
        naselje: "Brestik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "462",
        brojPu: "51300",
        redBroj: "462",
        nazivPu: "Delnice",
        naselje: "Brestova Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "463",
        brojPu: "34000",
        redBroj: "463",
        nazivPu: "Požega",
        naselje: "Brestovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "464",
        brojPu: "43500",
        redBroj: "464",
        nazivPu: "Daruvar",
        naselje: "Brestovačka Brda",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "465",
        brojPu: "49221",
        redBroj: "465",
        nazivPu: "Bedekovčina",
        naselje: "Brestovec Orehovički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "466",
        brojPu: "51211",
        redBroj: "466",
        nazivPu: "Matulji",
        naselje: "Brešca",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "467",
        brojPu: "53234",
        redBroj: "467",
        nazivPu: "Udbina",
        naselje: "Breštane",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "468",
        brojPu: "33515",
        redBroj: "468",
        nazivPu: "Orahovica",
        naselje: "Breštanovci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "469",
        brojPu: "43000",
        redBroj: "469",
        nazivPu: "Bjelovar",
        naselje: "Breza",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "470",
        brojPu: "51217",
        redBroj: "470",
        nazivPu: "Klana",
        naselje: "Breza",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "471",
        brojPu: "49290",
        redBroj: "471",
        nazivPu: "Klanjec",
        naselje: "Brezakovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "472",
        brojPu: "10340",
        redBroj: "472",
        nazivPu: "Vrbovec",
        naselje: "Brezani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "473",
        brojPu: "10450",
        redBroj: "473",
        nazivPu: "Jastrebarsko",
        naselje: "Brezari",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "474",
        brojPu: "10454",
        redBroj: "474",
        nazivPu: "Krašić",
        naselje: "Brezarić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "475",
        brojPu: "51250",
        redBroj: "475",
        nazivPu: "Novi Vinodolski",
        naselje: "Breze",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "476",
        brojPu: "33000",
        redBroj: "476",
        nazivPu: "Virovitica",
        naselje: "Brezik",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "477",
        brojPu: "33520",
        redBroj: "477",
        nazivPu: "Slatina",
        naselje: "Brezik",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "478",
        brojPu: "53000",
        redBroj: "478",
        nazivPu: "Gospić",
        naselje: "Brezik",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "479",
        brojPu: "31500",
        redBroj: "479",
        nazivPu: "Našice",
        naselje: "Brezik Našički",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "480",
        brojPu: "10342",
        redBroj: "480",
        nazivPu: "Dubrava",
        naselje: "Brezine",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "481",
        brojPu: "34551",
        redBroj: "481",
        nazivPu: "Lipik",
        naselje: "Brezine",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "482",
        brojPu: "10342",
        redBroj: "482",
        nazivPu: "Dubrava",
        naselje: "Brezje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "483",
        brojPu: "10431",
        redBroj: "483",
        nazivPu: "Sveta Nedelja",
        naselje: "Brezje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "484",
        brojPu: "40000",
        redBroj: "484",
        nazivPu: "Čakovec",
        naselje: "Brezje",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "485",
        brojPu: "49240",
        redBroj: "485",
        nazivPu: "Donja Stubica",
        naselje: "Brezje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "486",
        brojPu: "51300",
        redBroj: "486",
        nazivPu: "Delnice",
        naselje: "Brezje Dobransko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "487",
        brojPu: "42208",
        redBroj: "487",
        nazivPu: "Cestica",
        naselje: "Brezje Dravsko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "488",
        brojPu: "48260",
        redBroj: "488",
        nazivPu: "Križevci",
        naselje: "Brezje Miholečko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "489",
        brojPu: "47280",
        redBroj: "489",
        nazivPu: "Ozalj",
        naselje: "Brezje Vivodinsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "490",
        brojPu: "42220",
        redBroj: "490",
        nazivPu: "Novi Marof",
        naselje: "Breznica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "491",
        brojPu: "31400",
        redBroj: "491",
        nazivPu: "Đakovo",
        naselje: "Breznica Đakovačka",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "492",
        brojPu: "31500",
        redBroj: "492",
        nazivPu: "Našice",
        naselje: "Breznica Našička",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "493",
        brojPu: "42220",
        redBroj: "493",
        nazivPu: "Novi Marof",
        naselje: "Breznički Hum",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "494",
        brojPu: "47280",
        redBroj: "494",
        nazivPu: "Ozalj",
        naselje: "Breznik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "495",
        brojPu: "10450",
        redBroj: "495",
        nazivPu: "Jastrebarsko",
        naselje: "Breznik Plešivički",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "496",
        brojPu: "47000",
        redBroj: "496",
        nazivPu: "Karlovac",
        naselje: "Breznik Žakanjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "497",
        brojPu: "49231",
        redBroj: "497",
        nazivPu: "Hum na Sutli",
        naselje: "Brezno Gora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "498",
        brojPu: "49223",
        redBroj: "498",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Brezova",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "499",
        brojPu: "47220",
        redBroj: "499",
        nazivPu: "Vojnić",
        naselje: "Brezova Glava",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "500",
        brojPu: "42250",
        redBroj: "500",
        nazivPu: "Lepoglava",
        naselje: "Brezova Gora",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "501",
        brojPu: "43000",
        redBroj: "501",
        nazivPu: "Bjelovar",
        naselje: "Brezovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "502",
        brojPu: "44330",
        redBroj: "502",
        nazivPu: "Novska",
        naselje: "Brezovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "503",
        brojPu: "47240",
        redBroj: "503",
        nazivPu: "Slunj",
        naselje: "Brezovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "504",
        brojPu: "53250",
        redBroj: "504",
        nazivPu: "Donji Lapac",
        naselje: "Brezovac Dobroselski",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "505",
        brojPu: "10450",
        redBroj: "505",
        nazivPu: "Jastrebarsko",
        naselje: "Brezovac Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "506",
        brojPu: "40313",
        redBroj: "506",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Brezovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "507",
        brojPu: "10380",
        redBroj: "507",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Brezovec Zelinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "508",
        brojPu: "10257",
        redBroj: "508",
        nazivPu: "Brezovica",
        naselje: "Brezovica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "509",
        brojPu: "31540",
        redBroj: "509",
        nazivPu: "Donji Miholjac",
        naselje: "Brezovica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "510",
        brojPu: "33410",
        redBroj: "510",
        nazivPu: "Suhopolje",
        naselje: "Brezovica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "511",
        brojPu: "49000",
        redBroj: "511",
        nazivPu: "Krapina",
        naselje: "Brezovica Petrovska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "512",
        brojPu: "47280",
        redBroj: "512",
        nazivPu: "Ozalj",
        naselje: "Brezovica Žumberačka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "513",
        brojPu: "48214",
        redBroj: "513",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Brezovljani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "514",
        brojPu: "33515",
        redBroj: "514",
        nazivPu: "Orahovica",
        naselje: "Brezovljani Vojlovički",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "515",
        brojPu: "44400",
        redBroj: "515",
        nazivPu: "Glina",
        naselje: "Brezovo Polje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "516",
        brojPu: "44250",
        redBroj: "516",
        nazivPu: "Petrinja",
        naselje: "Brežane Lekeničke",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "517",
        brojPu: "47000",
        redBroj: "517",
        nazivPu: "Karlovac",
        naselje: "Brežani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "518",
        brojPu: "48260",
        redBroj: "518",
        nazivPu: "Križevci",
        naselje: "Brežani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "519",
        brojPu: "52220",
        redBroj: "519",
        nazivPu: "Labin",
        naselje: "Brgod",
        zupanija: "ISTARSKA",
      },
      {
        id: "520",
        brojPu: "23420",
        redBroj: "520",
        nazivPu: "Benkovac",
        naselje: "Brgud",
        zupanija: "ZADARSKA",
      },
      {
        id: "521",
        brojPu: "52420",
        redBroj: "521",
        nazivPu: "Buzet",
        naselje: "Brgudac",
        zupanija: "ISTARSKA",
      },
      {
        id: "522",
        brojPu: "23292",
        redBroj: "522",
        nazivPu: "Molat",
        naselje: "Brgulje",
        zupanija: "ZADARSKA",
      },
      {
        id: "523",
        brojPu: "22222",
        redBroj: "523",
        nazivPu: "Skradin",
        naselje: "Bribir",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "524",
        brojPu: "51250",
        redBroj: "524",
        nazivPu: "Novi Vinodolski",
        naselje: "Bribir",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "525",
        brojPu: "52460",
        redBroj: "525",
        nazivPu: "Buje (Buie)",
        naselje: "Brič - Briz",
        zupanija: "ISTARSKA",
      },
      {
        id: "526",
        brojPu: "52341",
        redBroj: "526",
        nazivPu: "Žminj",
        naselje: "Bričanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "527",
        brojPu: "52440",
        redBroj: "527",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Brig",
        zupanija: "ISTARSKA",
      },
      {
        id: "528",
        brojPu: "47000",
        redBroj: "528",
        nazivPu: "Karlovac",
        naselje: "Brihovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "529",
        brojPu: "31200",
        redBroj: "529",
        nazivPu: "Osijek",
        naselje: "Brijest",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "530",
        brojPu: "20246",
        redBroj: "530",
        nazivPu: "Janjina",
        naselje: "Brijesta",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "531",
        brojPu: "31200",
        redBroj: "531",
        nazivPu: "Osijek",
        naselje: "Briješće",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "532",
        brojPu: "44320",
        redBroj: "532",
        nazivPu: "Kutina",
        naselje: "Brinjani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "533",
        brojPu: "53260",
        redBroj: "533",
        nazivPu: "Brinje",
        naselje: "Brinje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "534",
        brojPu: "51306",
        redBroj: "534",
        nazivPu: "Čabar",
        naselje: "Brinjeva Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "535",
        brojPu: "21330",
        redBroj: "535",
        nazivPu: "Gradac",
        naselje: "Brist",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "536",
        brojPu: "21222",
        redBroj: "536",
        nazivPu: "Marina",
        naselje: "Bristivica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "537",
        brojPu: "23000",
        redBroj: "537",
        nazivPu: "Zadar",
        naselje: "Briševo",
        zupanija: "ZADARSKA",
      },
      {
        id: "538",
        brojPu: "22320",
        redBroj: "538",
        nazivPu: "Drniš",
        naselje: "Brištane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "539",
        brojPu: "52424",
        redBroj: "539",
        nazivPu: "Motovun (Montona)",
        naselje: "Brkač - Bercaz (S. Pancrazio)",
        zupanija: "ISTARSKA",
      },
      {
        id: "540",
        brojPu: "44000",
        redBroj: "540",
        nazivPu: "Sisak",
        naselje: "Brkiševina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "541",
        brojPu: "49282",
        redBroj: "541",
        nazivPu: "Konjščina",
        naselje: "Brlekovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "542",
        brojPu: "10454",
        redBroj: "542",
        nazivPu: "Krašić",
        naselje: "Brlenić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "543",
        brojPu: "53220",
        redBroj: "543",
        nazivPu: "Otočac",
        naselje: "Brlog",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "544",
        brojPu: "47280",
        redBroj: "544",
        nazivPu: "Ozalj",
        naselje: "Brlog Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "545",
        brojPu: "53220",
        redBroj: "545",
        nazivPu: "Otočac",
        naselje: "Brloška Dubrava",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "546",
        brojPu: "21230",
        redBroj: "546",
        nazivPu: "Sinj",
        naselje: "Brnaze",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "547",
        brojPu: "51218",
        redBroj: "547",
        nazivPu: "Dražice",
        naselje: "Brnelići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "548",
        brojPu: "52420",
        redBroj: "548",
        nazivPu: "Buzet",
        naselje: "Brnobići",
        zupanija: "ISTARSKA",
      },
      {
        id: "549",
        brojPu: "52440",
        redBroj: "549",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Brnobići",
        zupanija: "ISTARSKA",
      },
      {
        id: "550",
        brojPu: "44410",
        redBroj: "550",
        nazivPu: "Vrginmost",
        naselje: "Brnjavac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "551",
        brojPu: "44400",
        redBroj: "551",
        nazivPu: "Glina",
        naselje: "Brnjeuška",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "552",
        brojPu: "22323",
        redBroj: "552",
        nazivPu: "Unešić",
        naselje: "Brnjica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "553",
        brojPu: "20230",
        redBroj: "553",
        nazivPu: "Ston",
        naselje: "Broce",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "554",
        brojPu: "44330",
        redBroj: "554",
        nazivPu: "Novska",
        naselje: "Bročice",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "555",
        brojPu: "47240",
        redBroj: "555",
        nazivPu: "Slunj",
        naselje: "Broćanac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "556",
        brojPu: "51326",
        redBroj: "556",
        nazivPu: "Vrbovsko",
        naselje: "Brod Moravice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "557",
        brojPu: "51300",
        redBroj: "557",
        nazivPu: "Delnice",
        naselje: "Brod na Kupi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "558",
        brojPu: "22000",
        redBroj: "558",
        nazivPu: "Šibenik",
        naselje: "Brodarica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "559",
        brojPu: "42243",
        redBroj: "559",
        nazivPu: "Maruševec",
        naselje: "Brodarovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "560",
        brojPu: "48350",
        redBroj: "560",
        nazivPu: "Đurđevac",
        naselje: "Brodić",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "561",
        brojPu: "34310",
        redBroj: "561",
        nazivPu: "Pleternica",
        naselje: "Brodski Drenovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "562",
        brojPu: "35252",
        redBroj: "562",
        nazivPu: "Sibinj",
        naselje: "Brodski Stupnik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "563",
        brojPu: "35000",
        redBroj: "563",
        nazivPu: "Slavonski Brod",
        naselje: "Brodski Varoš",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "564",
        brojPu: "35000",
        redBroj: "564",
        nazivPu: "Slavonski Brod",
        naselje: "Brodski Zdenci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "565",
        brojPu: "31550",
        redBroj: "565",
        nazivPu: "Valpovo",
        naselje: "Brođanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "566",
        brojPu: "47000",
        redBroj: "566",
        nazivPu: "Karlovac",
        naselje: "Brođani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "567",
        brojPu: "52440",
        redBroj: "567",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Broskvari",
        zupanija: "ISTARSKA",
      },
      {
        id: "568",
        brojPu: "20210",
        redBroj: "568",
        nazivPu: "Cavtat",
        naselje: "Brotnice",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "569",
        brojPu: "53250",
        redBroj: "569",
        nazivPu: "Donji Lapac",
        naselje: "Brotnja",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "570",
        brojPu: "52220",
        redBroj: "570",
        nazivPu: "Labin",
        naselje: "Brovinje",
        zupanija: "ISTARSKA",
      },
      {
        id: "571",
        brojPu: "51417",
        redBroj: "571",
        nazivPu: "Mošćenička Draga",
        naselje: "Brseč",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "572",
        brojPu: "20232",
        redBroj: "572",
        nazivPu: "Slano",
        naselje: "Brsečine",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "573",
        brojPu: "32000",
        redBroj: "573",
        nazivPu: "Vukovar",
        naselje: "Bršadin",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "574",
        brojPu: "21204",
        redBroj: "574",
        nazivPu: "Dugopolje",
        naselje: "Brštanovo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "575",
        brojPu: "52466",
        redBroj: "575",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Brtonigla - Verteneglio",
        zupanija: "ISTARSKA",
      },
      {
        id: "576",
        brojPu: "44400",
        redBroj: "576",
        nazivPu: "Glina",
        naselje: "Brubno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "577",
        brojPu: "51500",
        redBroj: "577",
        nazivPu: "Krk",
        naselje: "Brusići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "578",
        brojPu: "21450",
        redBroj: "578",
        nazivPu: "Hvar",
        naselje: "Brusje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "579",
        brojPu: "34550",
        redBroj: "579",
        nazivPu: "Pakrac",
        naselje: "Brusnik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "580",
        brojPu: "53000",
        redBroj: "580",
        nazivPu: "Gospić",
        naselje: "Brušane",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "581",
        brojPu: "23420",
        redBroj: "581",
        nazivPu: "Benkovac",
        naselje: "Bruška",
        zupanija: "ZADARSKA",
      },
      {
        id: "582",
        brojPu: "23440",
        redBroj: "582",
        nazivPu: "Gračac",
        naselje: "Bruvno",
        zupanija: "ZADARSKA",
      },
      {
        id: "583",
        brojPu: "51500",
        redBroj: "583",
        nazivPu: "Krk",
        naselje: "Brzac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "584",
        brojPu: "52341",
        redBroj: "584",
        nazivPu: "Žminj",
        naselje: "Bubani",
        zupanija: "ISTARSKA",
      },
      {
        id: "585",
        brojPu: "47000",
        redBroj: "585",
        nazivPu: "Karlovac",
        naselje: "Bubnjarački Brod",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "586",
        brojPu: "47280",
        redBroj: "586",
        nazivPu: "Ozalj",
        naselje: "Bubnjarci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "587",
        brojPu: "52440",
        redBroj: "587",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bucalovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "588",
        brojPu: "34310",
        redBroj: "588",
        nazivPu: "Pleternica",
        naselje: "Bučje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "589",
        brojPu: "34550",
        redBroj: "589",
        nazivPu: "Pakrac",
        naselje: "Bučje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "590",
        brojPu: "31400",
        redBroj: "590",
        nazivPu: "Đakovo",
        naselje: "Bučje Gorjansko",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "591",
        brojPu: "47220",
        redBroj: "591",
        nazivPu: "Vojnić",
        naselje: "Budačka Rijeka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "592",
        brojPu: "23422",
        redBroj: "592",
        nazivPu: "Stankovci",
        naselje: "Budak",
        zupanija: "ZADARSKA",
      },
      {
        id: "593",
        brojPu: "53000",
        redBroj: "593",
        nazivPu: "Gospić",
        naselje: "Budak",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "594",
        brojPu: "33410",
        redBroj: "594",
        nazivPu: "Suhopolje",
        naselje: "Budakovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "595",
        brojPu: "48350",
        redBroj: "595",
        nazivPu: "Đurđevac",
        naselje: "Budančevica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "596",
        brojPu: "33410",
        redBroj: "596",
        nazivPu: "Suhopolje",
        naselje: "Budanica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "597",
        brojPu: "44000",
        redBroj: "597",
        nazivPu: "Sisak",
        naselje: "Budaševo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "598",
        brojPu: "10360",
        redBroj: "598",
        nazivPu: "Sesvete",
        naselje: "Budenec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "599",
        brojPu: "47280",
        redBroj: "599",
        nazivPu: "Ozalj",
        naselje: "Budim Vivodinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "600",
        brojPu: "31500",
        redBroj: "600",
        nazivPu: "Našice",
        naselje: "Budimci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "601",
        brojPu: "21240",
        redBroj: "601",
        nazivPu: "Trilj",
        naselje: "Budimiri",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "602",
        brojPu: "42240",
        redBroj: "602",
        nazivPu: "Ivanec",
        naselje: "Budinščak",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "603",
        brojPu: "49284",
        redBroj: "603",
        nazivPu: "Budinščina",
        naselje: "Budinšćina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "604",
        brojPu: "10454",
        redBroj: "604",
        nazivPu: "Krašić",
        naselje: "Budinjak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "605",
        brojPu: "42000",
        redBroj: "605",
        nazivPu: "Varaždin",
        naselje: "Budislavec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "606",
        brojPu: "48350",
        redBroj: "606",
        nazivPu: "Đurđevac",
        naselje: "Budrovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "607",
        brojPu: "33000",
        redBroj: "607",
        nazivPu: "Virovitica",
        naselje: "Budrovac Lukački",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "608",
        brojPu: "31400",
        redBroj: "608",
        nazivPu: "Đakovo",
        naselje: "Budrovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "609",
        brojPu: "47240",
        redBroj: "609",
        nazivPu: "Slunj",
        naselje: "Buhača",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "610",
        brojPu: "20207",
        redBroj: "610",
        nazivPu: "Mlini",
        naselje: "Buići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "611",
        brojPu: "52440",
        redBroj: "611",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Buići",
        zupanija: "ISTARSKA",
      },
      {
        id: "612",
        brojPu: "44440",
        redBroj: "612",
        nazivPu: "Dvor",
        naselje: "Buinja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "613",
        brojPu: "44440",
        redBroj: "613",
        nazivPu: "Dvor",
        naselje: "Buinjski Riječani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "614",
        brojPu: "52440",
        redBroj: "614",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bujarići",
        zupanija: "ISTARSKA",
      },
      {
        id: "615",
        brojPu: "34551",
        redBroj: "615",
        nazivPu: "Lipik",
        naselje: "Bujavica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "616",
        brojPu: "52460",
        redBroj: "616",
        nazivPu: "Buje (Buie)",
        naselje: "Buje - Buie",
        zupanija: "ISTARSKA",
      },
      {
        id: "617",
        brojPu: "34310",
        redBroj: "617",
        nazivPu: "Pleternica",
        naselje: "Buk",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "618",
        brojPu: "10380",
        redBroj: "618",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Bukevje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "619",
        brojPu: "10410",
        redBroj: "619",
        nazivPu: "Velika Gorica",
        naselje: "Bukevje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "620",
        brojPu: "52440",
        redBroj: "620",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Bukori",
        zupanija: "ISTARSKA",
      },
      {
        id: "621",
        brojPu: "51300",
        redBroj: "621",
        nazivPu: "Delnice",
        naselje: "Bukov Vrh",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "622",
        brojPu: "47240",
        redBroj: "622",
        nazivPu: "Slunj",
        naselje: "Bukovac Perjasički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "623",
        brojPu: "53202",
        redBroj: "623",
        nazivPu: "Perušić",
        naselje: "Bukovac Perušićki",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "624",
        brojPu: "51300",
        redBroj: "624",
        nazivPu: "Delnice",
        naselje: "Bukovac Podvrški",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "625",
        brojPu: "10450",
        redBroj: "625",
        nazivPu: "Jastrebarsko",
        naselje: "Bukovac Svetojanski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "626",
        brojPu: "33515",
        redBroj: "626",
        nazivPu: "Orahovica",
        naselje: "Bukovački Antunovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "627",
        brojPu: "10257",
        redBroj: "627",
        nazivPu: "Brezovica",
        naselje: "Bukovčak",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "628",
        brojPu: "34550",
        redBroj: "628",
        nazivPu: "Pakrac",
        naselje: "Bukovčani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "629",
        brojPu: "40313",
        redBroj: "629",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Bukovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "630",
        brojPu: "10380",
        redBroj: "630",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Bukovec Zelinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "631",
        brojPu: "35400",
        redBroj: "631",
        nazivPu: "Nova Gradiška",
        naselje: "Bukovica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "632",
        brojPu: "44400",
        redBroj: "632",
        nazivPu: "Glina",
        naselje: "Bukovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "633",
        brojPu: "10454",
        redBroj: "633",
        nazivPu: "Krašić",
        naselje: "Bukovica Prekriška",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "634",
        brojPu: "47220",
        redBroj: "634",
        nazivPu: "Vojnić",
        naselje: "Bukovica Utinjska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "635",
        brojPu: "23420",
        redBroj: "635",
        nazivPu: "Benkovac",
        naselje: "Buković",
        zupanija: "ZADARSKA",
      },
      {
        id: "636",
        brojPu: "10298",
        redBroj: "636",
        nazivPu: "Bistra",
        naselje: "Bukovje Bistransko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "637",
        brojPu: "48260",
        redBroj: "637",
        nazivPu: "Križevci",
        naselje: "Bukovje Križevačko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "638",
        brojPu: "47250",
        redBroj: "638",
        nazivPu: "Duga Resa",
        naselje: "Bukovje Netretićko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "639",
        brojPu: "10430",
        redBroj: "639",
        nazivPu: "Samobor",
        naselje: "Bukovje Podvrško",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "640",
        brojPu: "35000",
        redBroj: "640",
        nazivPu: "Slavonski Brod",
        naselje: "Bukovlje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "641",
        brojPu: "44000",
        redBroj: "641",
        nazivPu: "Sisak",
        naselje: "Bukovsko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "642",
        brojPu: "33515",
        redBroj: "642",
        nazivPu: "Orahovica",
        naselje: "Bukvik",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "643",
        brojPu: "20355",
        redBroj: "643",
        nazivPu: "Opuzen",
        naselje: "Buk-Vlaka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "644",
        brojPu: "23420",
        redBroj: "644",
        nazivPu: "Benkovac",
        naselje: "Bulić",
        zupanija: "ZADARSKA",
      },
      {
        id: "645",
        brojPu: "47280",
        redBroj: "645",
        nazivPu: "Ozalj",
        naselje: "Bulići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "646",
        brojPu: "43270",
        redBroj: "646",
        nazivPu: "Veliki Grđevac",
        naselje: "Bulinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "647",
        brojPu: "53270",
        redBroj: "647",
        nazivPu: "Senj",
        naselje: "Bunica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "648",
        brojPu: "53230",
        redBroj: "648",
        nazivPu: "Korenica",
        naselje: "Bunić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "649",
        brojPu: "10380",
        redBroj: "649",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Bunjak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "650",
        brojPu: "10315",
        redBroj: "650",
        nazivPu: "Novoselec",
        naselje: "Bunjani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "651",
        brojPu: "51326",
        redBroj: "651",
        nazivPu: "Vrbovsko",
        naselje: "Bunjevci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "652",
        brojPu: "47220",
        redBroj: "652",
        nazivPu: "Vojnić",
        naselje: "Burić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "653",
        brojPu: "52341",
        redBroj: "653",
        nazivPu: "Žminj",
        naselje: "Burići",
        zupanija: "ISTARSKA",
      },
      {
        id: "654",
        brojPu: "52470",
        redBroj: "654",
        nazivPu: "Umag (Umago)",
        naselje: "Buroli - Buroli",
        zupanija: "ISTARSKA",
      },
      {
        id: "655",
        brojPu: "34000",
        redBroj: "655",
        nazivPu: "Požega",
        naselje: "Busnovi",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "656",
        brojPu: "33000",
        redBroj: "656",
        nazivPu: "Virovitica",
        naselje: "Bušetina",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "657",
        brojPu: "10410",
        redBroj: "657",
        nazivPu: "Velika Gorica",
        naselje: "Buševec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "658",
        brojPu: "53250",
        redBroj: "658",
        nazivPu: "Donji Lapac",
        naselje: "Bušević",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "659",
        brojPu: "49218",
        redBroj: "659",
        nazivPu: "Pregrada",
        naselje: "Bušin",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "660",
        brojPu: "42220",
        redBroj: "660",
        nazivPu: "Novi Marof",
        naselje: "Butkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "661",
        brojPu: "52341",
        redBroj: "661",
        nazivPu: "Žminj",
        naselje: "Butkovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "662",
        brojPu: "52000",
        redBroj: "662",
        nazivPu: "Pazin",
        naselje: "Butoniga",
        zupanija: "ISTARSKA",
      },
      {
        id: "663",
        brojPu: "52440",
        redBroj: "663",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Butori",
        zupanija: "ISTARSKA",
      },
      {
        id: "664",
        brojPu: "10340",
        redBroj: "664",
        nazivPu: "Vrbovec",
        naselje: "Buzadovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "665",
        brojPu: "51218",
        redBroj: "665",
        nazivPu: "Dražice",
        naselje: "Buzdohanj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "666",
        brojPu: "52420",
        redBroj: "666",
        nazivPu: "Buzet",
        naselje: "Buzet",
        zupanija: "ISTARSKA",
      },
      {
        id: "667",
        brojPu: "44400",
        redBroj: "667",
        nazivPu: "Glina",
        naselje: "Buzeta",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "668",
        brojPu: "10010",
        redBroj: "668",
        nazivPu: "Zagreb-Sloboština",
        naselje: "Buzin",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "669",
        brojPu: "51300",
        redBroj: "669",
        nazivPu: "Delnice",
        naselje: "Buzin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "670",
        brojPu: "53000",
        redBroj: "670",
        nazivPu: "Gospić",
        naselje: "Bužim",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "671",
        brojPu: "52466",
        redBroj: "671",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Bužinija - Businia",
        zupanija: "ISTARSKA",
      },
      {
        id: "672",
        brojPu: "34310",
        redBroj: "672",
        nazivPu: "Pleternica",
        naselje: "Bzenica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "673",
        brojPu: "33410",
        redBroj: "673",
        nazivPu: "Suhopolje",
        naselje: "Cabuna",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "674",
        brojPu: "35430",
        redBroj: "674",
        nazivPu: "Okučani",
        naselje: "Cage",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "675",
        brojPu: "10310",
        redBroj: "675",
        nazivPu: "Ivanić Grad",
        naselje: "Caginec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "676",
        brojPu: "52440",
        redBroj: "676",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Cancini",
        zupanija: "ISTARSKA",
      },
      {
        id: "677",
        brojPu: "10454",
        redBroj: "677",
        nazivPu: "Krašić",
        naselje: "Careva Draga",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "678",
        brojPu: "48260",
        redBroj: "678",
        nazivPu: "Križevci",
        naselje: "Carevdar",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "679",
        brojPu: "51326",
        redBroj: "679",
        nazivPu: "Vrbovsko",
        naselje: "Carevići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "680",
        brojPu: "47300",
        redBroj: "680",
        nazivPu: "Ogulin",
        naselje: "Carevo Polje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "681",
        brojPu: "47250",
        redBroj: "681",
        nazivPu: "Duga Resa",
        naselje: "Carevo Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "682",
        brojPu: "42000",
        redBroj: "682",
        nazivPu: "Varaždin",
        naselje: "Cargovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "683",
        brojPu: "53291",
        redBroj: "683",
        nazivPu: "Novalja",
        naselje: "Caska",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "684",
        brojPu: "20210",
        redBroj: "684",
        nazivPu: "Cavtat",
        naselje: "Cavtat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "685",
        brojPu: "49250",
        redBroj: "685",
        nazivPu: "Zlatar",
        naselje: "Cebovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "686",
        brojPu: "10340",
        redBroj: "686",
        nazivPu: "Vrbovec",
        naselje: "Celine",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "687",
        brojPu: "10450",
        redBroj: "687",
        nazivPu: "Jastrebarsko",
        naselje: "Celine",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "688",
        brojPu: "40000",
        redBroj: "688",
        nazivPu: "Čakovec",
        naselje: "Celine",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "689",
        brojPu: "10291",
        redBroj: "689",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Celine Goričke",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "690",
        brojPu: "10430",
        redBroj: "690",
        nazivPu: "Samobor",
        naselje: "Celine Samoborske",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "691",
        brojPu: "44250",
        redBroj: "691",
        nazivPu: "Petrinja",
        naselje: "Cepeliš",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "692",
        brojPu: "48214",
        redBroj: "692",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Cepidlak",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "693",
        brojPu: "22323",
        redBroj: "693",
        nazivPu: "Unešić",
        naselje: "Cera",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "694",
        brojPu: "52220",
        redBroj: "694",
        nazivPu: "Labin",
        naselje: "Cere",
        zupanija: "ISTARSKA",
      },
      {
        id: "695",
        brojPu: "52341",
        redBroj: "695",
        nazivPu: "Žminj",
        naselje: "Cere",
        zupanija: "ISTARSKA",
      },
      {
        id: "696",
        brojPu: "31500",
        redBroj: "696",
        nazivPu: "Našice",
        naselje: "Ceremošnjak",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "697",
        brojPu: "32100",
        redBroj: "697",
        nazivPu: "Vinkovci",
        naselje: "Cerić",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "698",
        brojPu: "10340",
        redBroj: "698",
        nazivPu: "Vrbovec",
        naselje: "Cerik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "699",
        brojPu: "43240",
        redBroj: "699",
        nazivPu: "Čazma",
        naselje: "Cerina",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "700",
        brojPu: "52440",
        redBroj: "700",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Cerion",
        zupanija: "ISTARSKA",
      },
      {
        id: "701",
        brojPu: "52440",
        redBroj: "701",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Cerjani",
        zupanija: "ISTARSKA",
      },
      {
        id: "702",
        brojPu: "10340",
        redBroj: "702",
        nazivPu: "Vrbovec",
        naselje: "Cerje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "703",
        brojPu: "10361",
        redBroj: "703",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Cerje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "704",
        brojPu: "49225",
        redBroj: "704",
        nazivPu: "Đurmanec",
        naselje: "Cerje Jesenjsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "705",
        brojPu: "44250",
        redBroj: "705",
        nazivPu: "Petrinja",
        naselje: "Cerje Letovanićko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "706",
        brojPu: "42243",
        redBroj: "706",
        nazivPu: "Maruševec",
        naselje: "Cerje Nebojse",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "707",
        brojPu: "10414",
        redBroj: "707",
        nazivPu: "Pokupsko",
        naselje: "Cerje Pokupsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "708",
        brojPu: "10430",
        redBroj: "708",
        nazivPu: "Samobor",
        naselje: "Cerje Samoborsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "709",
        brojPu: "42240",
        redBroj: "709",
        nazivPu: "Ivanec",
        naselje: "Cerje Tužno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "710",
        brojPu: "47280",
        redBroj: "710",
        nazivPu: "Ozalj",
        naselje: "Cerje Vivodinsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "711",
        brojPu: "32272",
        redBroj: "711",
        nazivPu: "Cerna",
        naselje: "Cerna",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "712",
        brojPu: "10454",
        redBroj: "712",
        nazivPu: "Krašić",
        naselje: "Cernik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "713",
        brojPu: "35400",
        redBroj: "713",
        nazivPu: "Nova Gradiška",
        naselje: "Cernik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "714",
        brojPu: "51218",
        redBroj: "714",
        nazivPu: "Dražice",
        naselje: "Cernik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "715",
        brojPu: "23440",
        redBroj: "715",
        nazivPu: "Gračac",
        naselje: "Cerovac",
        zupanija: "ZADARSKA",
      },
      {
        id: "716",
        brojPu: "31550",
        redBroj: "716",
        nazivPu: "Valpovo",
        naselje: "Cerovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "717",
        brojPu: "34000",
        redBroj: "717",
        nazivPu: "Požega",
        naselje: "Cerovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "718",
        brojPu: "47250",
        redBroj: "718",
        nazivPu: "Duga Resa",
        naselje: "Cerovac Barilovićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "719",
        brojPu: "47000",
        redBroj: "719",
        nazivPu: "Karlovac",
        naselje: "Cerovac Vukmanićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "720",
        brojPu: "47250",
        redBroj: "720",
        nazivPu: "Duga Resa",
        naselje: "Cerovački Galovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "721",
        brojPu: "10454",
        redBroj: "721",
        nazivPu: "Krašić",
        naselje: "Cerovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "722",
        brojPu: "52000",
        redBroj: "722",
        nazivPu: "Pazin",
        naselje: "Cerovlje",
        zupanija: "ISTARSKA",
      },
      {
        id: "723",
        brojPu: "47300",
        redBroj: "723",
        nazivPu: "Ogulin",
        naselje: "Cerovnik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "724",
        brojPu: "10410",
        redBroj: "724",
        nazivPu: "Velika Gorica",
        naselje: "Cerovski Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "725",
        brojPu: "53288",
        redBroj: "725",
        nazivPu: "Karlobag",
        naselje: "Cesarica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "726",
        brojPu: "49290",
        redBroj: "726",
        nazivPu: "Klanjec",
        naselje: "Cesarska Ves",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "727",
        brojPu: "42208",
        redBroj: "727",
        nazivPu: "Cestica",
        naselje: "Cestica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "728",
        brojPu: "22300",
        redBroj: "728",
        nazivPu: "Knin",
        naselje: "Cetina",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "729",
        brojPu: "47240",
        redBroj: "729",
        nazivPu: "Slunj",
        naselje: "Cetingrad",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "730",
        brojPu: "49250",
        redBroj: "730",
        nazivPu: "Zlatar ",
        naselje: "Cetinovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "731",
        brojPu: "47240",
        redBroj: "731",
        nazivPu: "Slunj",
        naselje: "Cetinski Varoš",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "732",
        brojPu: "22213",
        redBroj: "732",
        nazivPu: "Pirovac",
        naselje: "Cicvare",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "733",
        brojPu: "34550",
        redBroj: "733",
        nazivPu: "Pakrac",
        naselje: "Cicvare",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "734",
        brojPu: "43000",
        redBroj: "734",
        nazivPu: "Bjelovar",
        naselje: "Ciglena",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "735",
        brojPu: "43280",
        redBroj: "735",
        nazivPu: "Garešnica",
        naselje: "Ciglenica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "736",
        brojPu: "44320",
        redBroj: "736",
        nazivPu: "Kutina",
        naselje: "Ciglenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "737",
        brojPu: "49223",
        redBroj: "737",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Ciglenica Zagorska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "738",
        brojPu: "34340",
        redBroj: "738",
        nazivPu: "Kutjevo",
        naselje: "Ciglenik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "739",
        brojPu: "35250",
        redBroj: "739",
        nazivPu: "Oriovac",
        naselje: "Ciglenik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "740",
        brojPu: "49218",
        redBroj: "740",
        nazivPu: "Pregrada",
        naselje: "Cigrovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "741",
        brojPu: "34550",
        redBroj: "741",
        nazivPu: "Pakrac",
        naselje: "Cikote",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "742",
        brojPu: "40323",
        redBroj: "742",
        nazivPu: "Prelog",
        naselje: "Cirkovljan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "743",
        brojPu: "48214",
        redBroj: "743",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Cirkvena",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "744",
        brojPu: "21256",
        redBroj: "744",
        nazivPu: "Cista Provo",
        naselje: "Cista Provo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "745",
        brojPu: "21256",
        redBroj: "745",
        nazivPu: "Cista Provo",
        naselje: "Cista Velika",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "746",
        brojPu: "22300",
        redBroj: "746",
        nazivPu: "Knin",
        naselje: "Civljane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "747",
        brojPu: "52207",
        redBroj: "747",
        nazivPu: "Barban",
        naselje: "Cokuni",
        zupanija: "ISTARSKA",
      },
      {
        id: "748",
        brojPu: "51326",
        redBroj: "748",
        nazivPu: "Vrbovsko",
        naselje: "Colnari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "749",
        brojPu: "43270",
        redBroj: "749",
        nazivPu: "Veliki Grđevac",
        naselje: "Cremušina",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "750",
        brojPu: "51557",
        redBroj: "750",
        nazivPu: "Cres",
        naselje: "Cres",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "751",
        brojPu: "31550",
        redBroj: "751",
        nazivPu: "Valpovo",
        naselje: "Cret Bizovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "752",
        brojPu: "31540",
        redBroj: "752",
        nazivPu: "Donji Miholjac",
        naselje: "Cret Viljevski",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "753",
        brojPu: "44410",
        redBroj: "753",
        nazivPu: "Vrginmost",
        naselje: "Crevarska Strana",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "754",
        brojPu: "51260",
        redBroj: "754",
        nazivPu: "Crikvenica",
        naselje: "Crikvenica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "755",
        brojPu: "21203",
        redBroj: "755",
        nazivPu: "Donji Muć",
        naselje: "Crivac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "756",
        brojPu: "52440",
        redBroj: "756",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Crklada",
        zupanija: "ISTARSKA",
      },
      {
        id: "757",
        brojPu: "42250",
        redBroj: "757",
        nazivPu: "Lepoglava",
        naselje: "Crkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "758",
        brojPu: "33515",
        redBroj: "758",
        nazivPu: "Orahovica",
        naselje: "Crkvari",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "759",
        brojPu: "44210",
        redBroj: "759",
        nazivPu: "Sunja",
        naselje: "Crkveni Bok",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "760",
        brojPu: "34000",
        redBroj: "760",
        nazivPu: "Požega",
        naselje: "Crkveni Vrhovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "761",
        brojPu: "34000",
        redBroj: "761",
        nazivPu: "Požega",
        naselje: "Crljenci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "762",
        brojPu: "23422",
        redBroj: "762",
        nazivPu: "Stankovci",
        naselje: "Crljenik",
        zupanija: "ZADARSKA",
      },
      {
        id: "763",
        brojPu: "47000",
        redBroj: "763",
        nazivPu: "Karlovac",
        naselje: "Crna Draga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "764",
        brojPu: "31500",
        redBroj: "764",
        nazivPu: "Našice",
        naselje: "Crna Klada",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "765",
        brojPu: "10450",
        redBroj: "765",
        nazivPu: "Jastrebarsko",
        naselje: "Crna Mlaka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "766",
        brojPu: "33515",
        redBroj: "766",
        nazivPu: "Orahovica",
        naselje: "Crnac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "767",
        brojPu: "44010",
        redBroj: "767",
        nazivPu: "Sisak-Caprag",
        naselje: "Crnac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "768",
        brojPu: "52220",
        redBroj: "768",
        nazivPu: "Labin",
        naselje: "Crni",
        zupanija: "ISTARSKA",
      },
      {
        id: "769",
        brojPu: "53288",
        redBroj: "769",
        nazivPu: "Karlobag",
        naselje: "Crni Dabar",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "770",
        brojPu: "53260",
        redBroj: "770",
        nazivPu: "Brinje",
        naselje: "Crni Kal",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "771",
        brojPu: "51306",
        redBroj: "771",
        nazivPu: "Čabar",
        naselje: "Crni Lazi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "772",
        brojPu: "51300",
        redBroj: "772",
        nazivPu: "Delnice",
        naselje: "Crni Lug",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "773",
        brojPu: "35000",
        redBroj: "773",
        nazivPu: "Slavonski Brod",
        naselje: "Crni Potok",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "774",
        brojPu: "44400",
        redBroj: "774",
        nazivPu: "Glina",
        naselje: "Crni Potok",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "775",
        brojPu: "23000",
        redBroj: "775",
        nazivPu: "Zadar",
        naselje: "Crno",
        zupanija: "ZADARSKA",
      },
      {
        id: "776",
        brojPu: "51250",
        redBroj: "776",
        nazivPu: "Novi Vinodolski",
        naselje: "Crno",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "777",
        brojPu: "47250",
        redBroj: "777",
        nazivPu: "Duga Resa",
        naselje: "Crno Kamanje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "778",
        brojPu: "47240",
        redBroj: "778",
        nazivPu: "Slunj",
        naselje: "Crno Vrelo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "779",
        brojPu: "52470",
        redBroj: "779",
        nazivPu: "Umag (Umago)",
        naselje: "Crveni Vrh - Monte Rosso",
        zupanija: "ISTARSKA",
      },
      {
        id: "780",
        brojPu: "48260",
        redBroj: "780",
        nazivPu: "Križevci",
        naselje: "Cubinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "781",
        brojPu: "10340",
        redBroj: "781",
        nazivPu: "Vrbovec",
        naselje: "Cugovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "782",
        brojPu: "52341",
        redBroj: "782",
        nazivPu: "Žminj",
        naselje: "Cukrići",
        zupanija: "ISTARSKA",
      },
      {
        id: "783",
        brojPu: "47000",
        redBroj: "783",
        nazivPu: "Karlovac",
        naselje: "Culibrki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "784",
        brojPu: "52420",
        redBroj: "784",
        nazivPu: "Buzet",
        naselje: "Cunj",
        zupanija: "ISTARSKA",
      },
      {
        id: "785",
        brojPu: "10380",
        redBroj: "785",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Curkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "786",
        brojPu: "47280",
        redBroj: "786",
        nazivPu: "Ozalj",
        naselje: "Cvetišće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "787",
        brojPu: "48000",
        redBroj: "787",
        nazivPu: "Koprivnica",
        naselje: "Cvetkovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "788",
        brojPu: "10450",
        redBroj: "788",
        nazivPu: "Jastrebarsko",
        naselje: "Cvetković",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "789",
        brojPu: "10410",
        redBroj: "789",
        nazivPu: "Velika Gorica",
        naselje: "Cvetković Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "790",
        brojPu: "42250",
        redBroj: "790",
        nazivPu: "Lepoglava",
        naselje: "Cvetlin",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "791",
        brojPu: "10414",
        redBroj: "791",
        nazivPu: "Pokupsko",
        naselje: "Cvetnić Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "792",
        brojPu: "47240",
        redBroj: "792",
        nazivPu: "Slunj",
        naselje: "Cvijanović Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "793",
        brojPu: "52440",
        redBroj: "793",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Cvitani",
        zupanija: "ISTARSKA",
      },
      {
        id: "794",
        brojPu: "47240",
        redBroj: "794",
        nazivPu: "Slunj",
        naselje: "Cvitović",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "795",
        brojPu: "51306",
        redBroj: "795",
        nazivPu: "Čabar",
        naselje: "Čabar",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "796",
        brojPu: "10450",
        redBroj: "796",
        nazivPu: "Jastrebarsko",
        naselje: "Čabdin",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "797",
        brojPu: "48260",
        redBroj: "797",
        nazivPu: "Križevci",
        naselje: "Čabraji",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "798",
        brojPu: "52341",
        redBroj: "798",
        nazivPu: "Žminj",
        naselje: "Čabrunići",
        zupanija: "ISTARSKA",
      },
      {
        id: "799",
        brojPu: "33515",
        redBroj: "799",
        nazivPu: "Orahovica",
        naselje: "Čačinci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "800",
        brojPu: "21240",
        redBroj: "800",
        nazivPu: "Trilj",
        naselje: "Čačvina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "801",
        brojPu: "43270",
        redBroj: "801",
        nazivPu: "Veliki Grđevac",
        naselje: "Čađavac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "802",
        brojPu: "33520",
        redBroj: "802",
        nazivPu: "Slatina",
        naselje: "Čađavica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "803",
        brojPu: "33520",
        redBroj: "803",
        nazivPu: "Slatina",
        naselje: "Čađavički Lug",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "804",
        brojPu: "34340",
        redBroj: "804",
        nazivPu: "Kutjevo",
        naselje: "Čaglin",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "805",
        brojPu: "44320",
        redBroj: "805",
        nazivPu: "Kutina",
        naselje: "Čaire",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "806",
        brojPu: "35214",
        redBroj: "806",
        nazivPu: "Donji Andrijevci",
        naselje: "Čajkovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "807",
        brojPu: "20236",
        redBroj: "807",
        nazivPu: "Mokošica",
        naselje: "Čajkovica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "808",
        brojPu: "20236",
        redBroj: "808",
        nazivPu: "Mokošica",
        naselje: "Čajkovići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "809",
        brojPu: "10413",
        redBroj: "809",
        nazivPu: "Kravarsko",
        naselje: "Čakanec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "810",
        brojPu: "32242",
        redBroj: "810",
        nazivPu: "Slakovci",
        naselje: "Čakovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "811",
        brojPu: "40000",
        redBroj: "811",
        nazivPu: "Čakovec",
        naselje: "Čakovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "812",
        brojPu: "42243",
        redBroj: "812",
        nazivPu: "Maruševec",
        naselje: "Čalinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "813",
        brojPu: "31540",
        redBroj: "813",
        nazivPu: "Donji Miholjac",
        naselje: "Čamagajevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "814",
        brojPu: "52333",
        redBroj: "814",
        nazivPu: "Podpićan",
        naselje: "Čambarelići",
        zupanija: "ISTARSKA",
      },
      {
        id: "815",
        brojPu: "47240",
        redBroj: "815",
        nazivPu: "Slunj",
        naselje: "Čamerovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "816",
        brojPu: "53220",
        redBroj: "816",
        nazivPu: "Otočac",
        naselje: "Čanak",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "817",
        brojPu: "42220",
        redBroj: "817",
        nazivPu: "Novi Marof",
        naselje: "Čanjevo",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "818",
        brojPu: "44210",
        redBroj: "818",
        nazivPu: "Sunja",
        naselje: "Čapljani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "819",
        brojPu: "21240",
        redBroj: "819",
        nazivPu: "Trilj",
        naselje: "Čaporice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "820",
        brojPu: "35430",
        redBroj: "820",
        nazivPu: "Okučani",
        naselje: "Čaprginci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "821",
        brojPu: "20274",
        redBroj: "821",
        nazivPu: "Pupnat",
        naselje: "Čara",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "822",
        brojPu: "47220",
        redBroj: "822",
        nazivPu: "Vojnić",
        naselje: "Čatrnja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "823",
        brojPu: "47240",
        redBroj: "823",
        nazivPu: "Slunj",
        naselje: "Čatrnja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "824",
        brojPu: "51218",
        redBroj: "824",
        nazivPu: "Dražice",
        naselje: "Čavle",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "825",
        brojPu: "44440",
        redBroj: "825",
        nazivPu: "Dvor",
        naselje: "Čavlovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "826",
        brojPu: "22320",
        redBroj: "826",
        nazivPu: "Drniš",
        naselje: "Čavoglave",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "827",
        brojPu: "43240",
        redBroj: "827",
        nazivPu: "Čazma",
        naselje: "Čazma",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "828",
        brojPu: "34000",
        redBroj: "828",
        nazivPu: "Požega",
        naselje: "Čečavac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "829",
        brojPu: "34000",
        redBroj: "829",
        nazivPu: "Požega",
        naselje: "Čečavački Vučjak",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "830",
        brojPu: "51300",
        redBroj: "830",
        nazivPu: "Delnice",
        naselje: "Čedanj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "831",
        brojPu: "10450",
        redBroj: "831",
        nazivPu: "Jastrebarsko",
        naselje: "Čeglje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "832",
        brojPu: "52440",
        redBroj: "832",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Čehići",
        zupanija: "ISTARSKA",
      },
      {
        id: "833",
        brojPu: "40323",
        redBroj: "833",
        nazivPu: "Prelog",
        naselje: "Čehovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "834",
        brojPu: "35252",
        redBroj: "834",
        nazivPu: "Sibinj",
        naselje: "Čelikovići",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "835",
        brojPu: "21310",
        redBroj: "835",
        nazivPu: "Omiš",
        naselje: "Čelina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "836",
        brojPu: "20207",
        redBroj: "836",
        nazivPu: "Mlini",
        naselje: "Čelopeci",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "837",
        brojPu: "33000",
        redBroj: "837",
        nazivPu: "Virovitica",
        naselje: "Čemernica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "838",
        brojPu: "10310",
        redBroj: "838",
        nazivPu: "Ivanić Grad",
        naselje: "Čemernica Lonjska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "839",
        brojPu: "31300",
        redBroj: "839",
        nazivPu: "Beli Manastir",
        naselje: "Čeminac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "840",
        brojPu: "31400",
        redBroj: "840",
        nazivPu: "Đakovo",
        naselje: "Čenkovo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "841",
        brojPu: "48350",
        redBroj: "841",
        nazivPu: "Đurđevac",
        naselje: "Čepelovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "842",
        brojPu: "52460",
        redBroj: "842",
        nazivPu: "Buje (Buie)",
        naselje: "Čepić - Ceppich",
        zupanija: "ISTARSKA",
      },
      {
        id: "843",
        brojPu: "20230",
        redBroj: "843",
        nazivPu: "Ston",
        naselje: "Čepikuće",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "844",
        brojPu: "31431",
        redBroj: "844",
        nazivPu: "Čepin",
        naselje: "Čepin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "845",
        brojPu: "31431",
        redBroj: "845",
        nazivPu: "Čepin",
        naselje: "Čepinski Martinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "846",
        brojPu: "52470",
        redBroj: "846",
        nazivPu: "Umag (Umago)",
        naselje: "Čepljani - Ceppiani",
        zupanija: "ISTARSKA",
      },
      {
        id: "847",
        brojPu: "52341",
        redBroj: "847",
        nazivPu: "Žminj",
        naselje: "Červari",
        zupanija: "ISTARSKA",
      },
      {
        id: "848",
        brojPu: "52440",
        redBroj: "848",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Červar-Porat",
        zupanija: "ISTARSKA",
      },
      {
        id: "849",
        brojPu: "40313",
        redBroj: "849",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Čestijanec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "850",
        brojPu: "20230",
        redBroj: "850",
        nazivPu: "Ston",
        naselje: "Česvinica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "851",
        brojPu: "34000",
        redBroj: "851",
        nazivPu: "Požega",
        naselje: "Češljakovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "852",
        brojPu: "33515",
        redBroj: "852",
        nazivPu: "Orahovica",
        naselje: "Četekovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "853",
        brojPu: "44210",
        redBroj: "853",
        nazivPu: "Sunja",
        naselje: "Četvrtkovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "854",
        brojPu: "20207",
        redBroj: "854",
        nazivPu: "Mlini",
        naselje: "Čibača",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "855",
        brojPu: "42230",
        redBroj: "855",
        nazivPu: "Ludbreg",
        naselje: "Čičkovina",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "856",
        brojPu: "44000",
        redBroj: "856",
        nazivPu: "Sisak",
        naselje: "Čigoč",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "857",
        brojPu: "20215",
        redBroj: "857",
        nazivPu: "Gruda",
        naselje: "Čilipi",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "858",
        brojPu: "48350",
        redBroj: "858",
        nazivPu: "Đurđevac",
        naselje: "Čingi-Lingi",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "859",
        brojPu: "52420",
        redBroj: "859",
        nazivPu: "Buzet",
        naselje: "Čiritež",
        zupanija: "ISTARSKA",
      },
      {
        id: "860",
        brojPu: "21292",
        redBroj: "860",
        nazivPu: "Srinjine",
        naselje: "Čisla",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "861",
        brojPu: "22213",
        redBroj: "861",
        nazivPu: "Pirovac",
        naselje: "Čista Mala",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "862",
        brojPu: "10361",
        redBroj: "862",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Čista Mlaka",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "863",
        brojPu: "22213",
        redBroj: "863",
        nazivPu: "Pirovac",
        naselje: "Čista Velika",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "864",
        brojPu: "21230",
        redBroj: "864",
        nazivPu: "Sinj",
        naselje: "Čitluk",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "865",
        brojPu: "22320",
        redBroj: "865",
        nazivPu: "Drniš",
        naselje: "Čitluk",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "866",
        brojPu: "51511",
        redBroj: "866",
        nazivPu: "Malinska",
        naselje: "Čižići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "867",
        brojPu: "33515",
        redBroj: "867",
        nazivPu: "Orahovica",
        naselje: "Čojlug",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "868",
        brojPu: "53234",
        redBroj: "868",
        nazivPu: "Udbina",
        naselje: "Čojluk",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "869",
        brojPu: "31431",
        redBroj: "869",
        nazivPu: "Čepin",
        naselje: "Čokadinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "870",
        brojPu: "35430",
        redBroj: "870",
        nazivPu: "Okučani",
        naselje: "Čovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "871",
        brojPu: "53220",
        redBroj: "871",
        nazivPu: "Otočac",
        naselje: "Čovići",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "872",
        brojPu: "10380",
        redBroj: "872",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Črečan",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "873",
        brojPu: "40000",
        redBroj: "873",
        nazivPu: "Čakovec",
        naselje: "Črečan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "874",
        brojPu: "44410",
        redBroj: "874",
        nazivPu: "Vrginmost",
        naselje: "Čremušnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "875",
        brojPu: "49290",
        redBroj: "875",
        nazivPu: "Klanjec",
        naselje: "Črešnjevec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "876",
        brojPu: "42204",
        redBroj: "876",
        nazivPu: "Turčin",
        naselje: "Črešnjevo",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "877",
        brojPu: "49217",
        redBroj: "877",
        nazivPu: "Krapinske Toplice",
        naselje: "Čret",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "878",
        brojPu: "42220",
        redBroj: "878",
        nazivPu: "Novi Marof",
        naselje: "Čret Bisaški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "879",
        brojPu: "10410",
        redBroj: "879",
        nazivPu: "Velika Gorica",
        naselje: "Čret Posavski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "880",
        brojPu: "48260",
        redBroj: "880",
        nazivPu: "Križevci",
        naselje: "Črnčevec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "881",
        brojPu: "42000",
        redBroj: "881",
        nazivPu: "Varaždin",
        naselje: "Črnec Biškupečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "882",
        brojPu: "10370",
        redBroj: "882",
        nazivPu: "Dugo Selo",
        naselje: "Črnec Dugoselski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "883",
        brojPu: "10370",
        redBroj: "883",
        nazivPu: "Dugo Selo",
        naselje: "Črnec Rugvički",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "884",
        brojPu: "52420",
        redBroj: "884",
        nazivPu: "Buzet",
        naselje: "Črnica",
        zupanija: "ISTARSKA",
      },
      {
        id: "885",
        brojPu: "42223",
        redBroj: "885",
        nazivPu: "Varaždinske Toplice",
        naselje: "Črnile",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "886",
        brojPu: "10450",
        redBroj: "886",
        nazivPu: "Jastrebarsko",
        naselje: "Črnilovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "887",
        brojPu: "31551",
        redBroj: "887",
        nazivPu: "Belišće",
        naselje: "Črnkovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "888",
        brojPu: "10410",
        redBroj: "888",
        nazivPu: "Velika Gorica",
        naselje: "Črnkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "889",
        brojPu: "52440",
        redBroj: "889",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Črvar",
        zupanija: "ISTARSKA",
      },
      {
        id: "890",
        brojPu: "51326",
        redBroj: "890",
        nazivPu: "Vrbovsko",
        naselje: "Čučak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "891",
        brojPu: "10454",
        redBroj: "891",
        nazivPu: "Krašić",
        naselje: "Čučići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "892",
        brojPu: "53230",
        redBroj: "892",
        nazivPu: "Korenica",
        naselje: "Čujića Krčevina",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "893",
        brojPu: "52440",
        redBroj: "893",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Čuki",
        zupanija: "ISTARSKA",
      },
      {
        id: "894",
        brojPu: "40323",
        redBroj: "894",
        nazivPu: "Prelog",
        naselje: "Čukovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "895",
        brojPu: "42230",
        redBroj: "895",
        nazivPu: "Ludbreg",
        naselje: "Čukovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "896",
        brojPu: "44430",
        redBroj: "896",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Čukur",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "897",
        brojPu: "10454",
        redBroj: "897",
        nazivPu: "Krašić",
        naselje: "Čunkova Draga",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "898",
        brojPu: "44250",
        redBroj: "898",
        nazivPu: "Petrinja",
        naselje: "Čuntić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "899",
        brojPu: "42220",
        redBroj: "899",
        nazivPu: "Novi Marof",
        naselje: "Čurilovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "900",
        brojPu: "52440",
        redBroj: "900",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Čuši",
        zupanija: "ISTARSKA",
      },
      {
        id: "901",
        brojPu: "22222",
        redBroj: "901",
        nazivPu: "Skradin",
        naselje: "Čvrljevo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "902",
        brojPu: "22323",
        redBroj: "902",
        nazivPu: "Unešić",
        naselje: "Čvrljevo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "903",
        brojPu: "32000",
        redBroj: "903",
        nazivPu: "Vukovar",
        naselje: "Ćelije",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "904",
        brojPu: "33515",
        redBroj: "904",
        nazivPu: "Orahovica",
        naselje: "Ćeralije",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "905",
        brojPu: "44440",
        redBroj: "905",
        nazivPu: "Dvor",
        naselje: "Ćore",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "906",
        brojPu: "34310",
        redBroj: "906",
        nazivPu: "Pleternica",
        naselje: "Ćosinac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "907",
        brojPu: "34000",
        redBroj: "907",
        nazivPu: "Požega",
        naselje: "Ćosine Laze",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "908",
        brojPu: "47240",
        redBroj: "908",
        nazivPu: "Slunj",
        naselje: "Ćuić Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "909",
        brojPu: "51550",
        redBroj: "909",
        nazivPu: "Mali Lošinj",
        naselje: "Ćunski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "910",
        brojPu: "43000",
        redBroj: "910",
        nazivPu: "Bjelovar",
        naselje: "Ćurlovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "911",
        brojPu: "52000",
        redBroj: "911",
        nazivPu: "Pazin",
        naselje: "Ćusi",
        zupanija: "ISTARSKA",
      },
      {
        id: "912",
        brojPu: "21233",
        redBroj: "912",
        nazivPu: "Hrvace",
        naselje: "Dabar",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "913",
        brojPu: "53220",
        redBroj: "913",
        nazivPu: "Otočac",
        naselje: "Dabar",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "914",
        brojPu: "53250",
        redBroj: "914",
        nazivPu: "Donji Lapac",
        naselje: "Dabašnica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "915",
        brojPu: "44400",
        redBroj: "915",
        nazivPu: "Glina",
        naselje: "Dabrina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "916",
        brojPu: "52466",
        redBroj: "916",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Dajla - Daila",
        zupanija: "ISTARSKA",
      },
      {
        id: "917",
        brojPu: "31226",
        redBroj: "917",
        nazivPu: "Dalj",
        naselje: "Dalj",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "918",
        brojPu: "51326",
        redBroj: "918",
        nazivPu: "Vrbovsko",
        naselje: "Damalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "919",
        brojPu: "52440",
        redBroj: "919",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Danci",
        zupanija: "ISTARSKA",
      },
      {
        id: "920",
        brojPu: "20246",
        redBroj: "920",
        nazivPu: "Janjina",
        naselje: "Dančanje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "921",
        brojPu: "47280",
        redBroj: "921",
        nazivPu: "Ozalj",
        naselje: "Dančulovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "922",
        brojPu: "10454",
        redBroj: "922",
        nazivPu: "Krašić",
        naselje: "Dane",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "923",
        brojPu: "52420",
        redBroj: "923",
        nazivPu: "Buzet",
        naselje: "Dane",
        zupanija: "ISTARSKA",
      },
      {
        id: "924",
        brojPu: "47250",
        redBroj: "924",
        nazivPu: "Duga Resa",
        naselje: "Dani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "925",
        brojPu: "22000",
        redBroj: "925",
        nazivPu: "Šibenik",
        naselje: "Danilo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "926",
        brojPu: "22000",
        redBroj: "926",
        nazivPu: "Šibenik",
        naselje: "Danilo Biranj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "927",
        brojPu: "22323",
        redBroj: "927",
        nazivPu: "Unešić",
        naselje: "Danilo Kraljice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "928",
        brojPu: "43240",
        redBroj: "928",
        nazivPu: "Čazma",
        naselje: "Dapci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "929",
        brojPu: "43290",
        redBroj: "929",
        nazivPu: "Grubišno Polje",
        naselje: "Dapčevački Brđani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "930",
        brojPu: "34000",
        redBroj: "930",
        nazivPu: "Požega",
        naselje: "Daranovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "931",
        brojPu: "31326",
        redBroj: "931",
        nazivPu: "Darda",
        naselje: "Darda",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "932",
        brojPu: "34340",
        redBroj: "932",
        nazivPu: "Kutjevo",
        naselje: "Darkovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "933",
        brojPu: "43500",
        redBroj: "933",
        nazivPu: "Daruvar",
        naselje: "Daruvar",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "934",
        brojPu: "43500",
        redBroj: "934",
        nazivPu: "Daruvar",
        naselje: "Daruvarski Brestovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "935",
        brojPu: "43500",
        redBroj: "935",
        nazivPu: "Daruvar",
        naselje: "Daruvarski Vinogradi",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "936",
        brojPu: "43240",
        redBroj: "936",
        nazivPu: "Čazma",
        naselje: "Daskatica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "937",
        brojPu: "43270",
        redBroj: "937",
        nazivPu: "Veliki Grđevac",
        naselje: "Dautan",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "938",
        brojPu: "35400",
        redBroj: "938",
        nazivPu: "Nova Gradiška",
        naselje: "Davor",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "939",
        brojPu: "22240",
        redBroj: "939",
        nazivPu: "Tisno",
        naselje: "Dazlina",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "940",
        brojPu: "10310",
        redBroj: "940",
        nazivPu: "Ivanić Grad",
        naselje: "Deanovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "941",
        brojPu: "44250",
        redBroj: "941",
        nazivPu: "Petrinja",
        naselje: "Deanovići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "942",
        brojPu: "53230",
        redBroj: "942",
        nazivPu: "Korenica",
        naselje: "Debelo Brdo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "943",
        brojPu: "53000",
        redBroj: "943",
        nazivPu: "Gospić",
        naselje: "Debelo Brdo I",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "944",
        brojPu: "53000",
        redBroj: "944",
        nazivPu: "Gospić",
        naselje: "Debelo Brdo II",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "945",
        brojPu: "23206",
        redBroj: "945",
        nazivPu: "Sukošan",
        naselje: "Debeljak",
        zupanija: "ZADARSKA",
      },
      {
        id: "946",
        brojPu: "52341",
        redBroj: "946",
        nazivPu: "Žminj",
        naselje: "Debeljuhi",
        zupanija: "ISTARSKA",
      },
      {
        id: "947",
        brojPu: "51300",
        redBroj: "947",
        nazivPu: "Delnice",
        naselje: "Dedin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "948",
        brojPu: "48260",
        redBroj: "948",
        nazivPu: "Križevci",
        naselje: "Dedina",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "949",
        brojPu: "40000",
        redBroj: "949",
        nazivPu: "Čakovec",
        naselje: "Dekanovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "950",
        brojPu: "48260",
        redBroj: "950",
        nazivPu: "Križevci",
        naselje: "Deklešanec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "951",
        brojPu: "52440",
        redBroj: "951",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Deklevi",
        zupanija: "ISTARSKA",
      },
      {
        id: "952",
        brojPu: "52440",
        redBroj: "952",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Deklići",
        zupanija: "ISTARSKA",
      },
      {
        id: "953",
        brojPu: "52440",
        redBroj: "953",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Dekovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "954",
        brojPu: "51326",
        redBroj: "954",
        nazivPu: "Vrbovsko",
        naselje: "Delači",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "955",
        brojPu: "47240",
        redBroj: "955",
        nazivPu: "Slunj",
        naselje: "Delić Poljana",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "956",
        brojPu: "52440",
        redBroj: "956",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Delići",
        zupanija: "ISTARSKA",
      },
      {
        id: "957",
        brojPu: "49250",
        redBroj: "957",
        nazivPu: "Zlatar",
        naselje: "Delkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "958",
        brojPu: "51300",
        redBroj: "958",
        nazivPu: "Delnice",
        naselje: "Delnice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "959",
        brojPu: "48350",
        redBroj: "959",
        nazivPu: "Đurđevac",
        naselje: "Delovi",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "960",
        brojPu: "10257",
        redBroj: "960",
        nazivPu: "Brezovica",
        naselje: "Demerje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "961",
        brojPu: "34550",
        redBroj: "961",
        nazivPu: "Pakrac",
        naselje: "Dereza",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "962",
        brojPu: "43240",
        redBroj: "962",
        nazivPu: "Čazma",
        naselje: "Dereza",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "963",
        brojPu: "10310",
        redBroj: "963",
        nazivPu: "Ivanić Grad",
        naselje: "Derežani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "964",
        brojPu: "23440",
        redBroj: "964",
        nazivPu: "Gračac",
        naselje: "Deringaj",
        zupanija: "ZADARSKA",
      },
      {
        id: "965",
        brojPu: "34000",
        redBroj: "965",
        nazivPu: "Požega",
        naselje: "Dervišaga",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "966",
        brojPu: "49216",
        redBroj: "966",
        nazivPu: "Desinić",
        naselje: "Desinić",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "967",
        brojPu: "49216",
        redBroj: "967",
        nazivPu: "Desinić",
        naselje: "Desinić Gora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "968",
        brojPu: "47300",
        redBroj: "968",
        nazivPu: "Ogulin",
        naselje: "Desmerice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "969",
        brojPu: "20340",
        redBroj: "969",
        nazivPu: "Ploče",
        naselje: "Desne",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "970",
        brojPu: "44400",
        redBroj: "970",
        nazivPu: "Glina",
        naselje: "Desni Degoj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "971",
        brojPu: "44000",
        redBroj: "971",
        nazivPu: "Sisak",
        naselje: "Desni Dubrovčak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "972",
        brojPu: "47000",
        redBroj: "972",
        nazivPu: "Karlovac",
        naselje: "Desni Štefanki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "973",
        brojPu: "47000",
        redBroj: "973",
        nazivPu: "Karlovac",
        naselje: "Desno Sredičko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "974",
        brojPu: "44000",
        redBroj: "974",
        nazivPu: "Sisak",
        naselje: "Desno Trebarjevo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "975",
        brojPu: "44000",
        redBroj: "975",
        nazivPu: "Sisak",
        naselje: "Desno Željezno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "976",
        brojPu: "10257",
        redBroj: "976",
        nazivPu: "Brezovica",
        naselje: "Desprim",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "977",
        brojPu: "33410",
        redBroj: "977",
        nazivPu: "Suhopolje",
        naselje: "Detkovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "978",
        brojPu: "43500",
        redBroj: "978",
        nazivPu: "Daruvar",
        naselje: "Dežanovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "979",
        brojPu: "34000",
        redBroj: "979",
        nazivPu: "Požega",
        naselje: "Deževci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "980",
        brojPu: "43290",
        redBroj: "980",
        nazivPu: "Grubišno Polje",
        naselje: "Dijakovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "981",
        brojPu: "10340",
        redBroj: "981",
        nazivPu: "Vrbovec",
        naselje: "Dijaneš",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "982",
        brojPu: "48260",
        redBroj: "982",
        nazivPu: "Križevci",
        naselje: "Dijankovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "983",
        brojPu: "52440",
        redBroj: "983",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Diklići",
        zupanija: "ISTARSKA",
      },
      {
        id: "984",
        brojPu: "33405",
        redBroj: "984",
        nazivPu: "Pitomača",
        naselje: "Dinjevac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "985",
        brojPu: "23250",
        redBroj: "985",
        nazivPu: "Pag",
        naselje: "Dinjiška",
        zupanija: "ZADARSKA",
      },
      {
        id: "986",
        brojPu: "43500",
        redBroj: "986",
        nazivPu: "Daruvar",
        naselje: "Dioš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "987",
        brojPu: "43280",
        redBroj: "987",
        nazivPu: "Garešnica",
        naselje: "Dišnik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "988",
        brojPu: "51300",
        redBroj: "988",
        nazivPu: "Delnice",
        naselje: "Divjake",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "989",
        brojPu: "21204",
        redBroj: "989",
        nazivPu: "Dugopolje",
        naselje: "Divojevići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "990",
        brojPu: "53000",
        redBroj: "990",
        nazivPu: "Gospić",
        naselje: "Divoselo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "991",
        brojPu: "31200",
        redBroj: "991",
        nazivPu: "Osijek",
        naselje: "Divoš",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "992",
        brojPu: "35214",
        redBroj: "992",
        nazivPu: "Donji Andrijevci",
        naselje: "Divoševci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "993",
        brojPu: "52207",
        redBroj: "993",
        nazivPu: "Barban",
        naselje: "Divšići",
        zupanija: "ISTARSKA",
      },
      {
        id: "994",
        brojPu: "21220",
        redBroj: "994",
        nazivPu: "Trogir",
        naselje: "Divulje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "995",
        brojPu: "44440",
        redBroj: "995",
        nazivPu: "Dvor",
        naselje: "Divuša",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "996",
        brojPu: "34340",
        redBroj: "996",
        nazivPu: "Kutjevo",
        naselje: "Djedina Rijeka",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "997",
        brojPu: "53250",
        redBroj: "997",
        nazivPu: "Donji Lapac",
        naselje: "Dnopolje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "998",
        brojPu: "43500",
        redBroj: "998",
        nazivPu: "Daruvar",
        naselje: "Dobra Kuća",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "999",
        brojPu: "23422",
        redBroj: "999",
        nazivPu: "Stankovci",
        naselje: "Dobra Voda",
        zupanija: "ZADARSKA",
      },
      {
        id: "1000",
        brojPu: "34340",
        redBroj: "1000",
        nazivPu: "Kutjevo",
        naselje: "Dobra Voda",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1001",
        brojPu: "20350",
        redBroj: "1001",
        nazivPu: "Metković",
        naselje: "Dobranje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1002",
        brojPu: "21256",
        redBroj: "1002",
        nazivPu: "Cista Provo",
        naselje: "Dobranje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1003",
        brojPu: "51415",
        redBroj: "1003",
        nazivPu: "Lovran",
        naselje: "Dobreć",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1004",
        brojPu: "47250",
        redBroj: "1004",
        nazivPu: "Duga Resa",
        naselje: "Dobrenići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1005",
        brojPu: "49240",
        redBroj: "1005",
        nazivPu: "Donja Stubica",
        naselje: "Dobri Zdenci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1006",
        brojPu: "33520",
        redBroj: "1006",
        nazivPu: "Slatina",
        naselje: "Dobrić",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1007",
        brojPu: "21260",
        redBroj: "1007",
        nazivPu: "Imotski",
        naselje: "Dobrinče",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1008",
        brojPu: "51511",
        redBroj: "1008",
        nazivPu: "Malinska",
        naselje: "Dobrinj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1009",
        brojPu: "10360",
        redBroj: "1009",
        nazivPu: "Sesvete",
        naselje: "Dobrodol",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1010",
        brojPu: "34340",
        redBroj: "1010",
        nazivPu: "Kutjevo",
        naselje: "Dobrogošće",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1011",
        brojPu: "23271",
        redBroj: "1011",
        nazivPu: "Kukljica",
        naselje: "Dobropoljana",
        zupanija: "ZADARSKA",
      },
      {
        id: "1012",
        brojPu: "23420",
        redBroj: "1012",
        nazivPu: "Benkovac",
        naselje: "Dobropoljci",
        zupanija: "ZADARSKA",
      },
      {
        id: "1013",
        brojPu: "53250",
        redBroj: "1013",
        nazivPu: "Donji Lapac",
        naselje: "Dobroselo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1014",
        brojPu: "34550",
        redBroj: "1014",
        nazivPu: "Pakrac",
        naselje: "Dobrovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1015",
        brojPu: "33520",
        redBroj: "1015",
        nazivPu: "Slatina",
        naselje: "Dobrović",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1016",
        brojPu: "44250",
        redBroj: "1016",
        nazivPu: "Petrinja",
        naselje: "Dodoši",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1017",
        brojPu: "21222",
        redBroj: "1017",
        nazivPu: "Marina",
        naselje: "Dograde",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1018",
        brojPu: "47280",
        redBroj: "1018",
        nazivPu: "Ozalj",
        naselje: "Dojutrovica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1019",
        brojPu: "51326",
        redBroj: "1019",
        nazivPu: "Vrbovsko",
        naselje: "Dokmanovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1020",
        brojPu: "10454",
        redBroj: "1020",
        nazivPu: "Krašić",
        naselje: "Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1021",
        brojPu: "21400",
        redBroj: "1021",
        nazivPu: "Supetar",
        naselje: "Dol",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1022",
        brojPu: "21460",
        redBroj: "1022",
        nazivPu: "Stari Grad",
        naselje: "Dol",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1023",
        brojPu: "49290",
        redBroj: "1023",
        nazivPu: "Klanjec",
        naselje: "Dol Klanječki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1024",
        brojPu: "34000",
        redBroj: "1024",
        nazivPu: "Požega",
        naselje: "Dolac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1025",
        brojPu: "10450",
        redBroj: "1025",
        nazivPu: "Jastrebarsko",
        naselje: "Dolanjski Jarak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1026",
        brojPu: "33515",
        redBroj: "1026",
        nazivPu: "Orahovica",
        naselje: "Dolci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1027",
        brojPu: "10435",
        redBroj: "1027",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Dolec Podokićki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1028",
        brojPu: "51326",
        redBroj: "1028",
        nazivPu: "Vrbovsko",
        naselje: "Dolenci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1029",
        brojPu: "52420",
        redBroj: "1029",
        nazivPu: "Buzet",
        naselje: "Dolenja Vas",
        zupanija: "ISTARSKA",
      },
      {
        id: "1030",
        brojPu: "20230",
        redBroj: "1030",
        nazivPu: "Ston",
        naselje: "Doli",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1031",
        brojPu: "21260",
        redBroj: "1031",
        nazivPu: "Imotski",
        naselje: "Dolića Draga",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1032",
        brojPu: "49000",
        redBroj: "1032",
        nazivPu: "Krapina",
        naselje: "Doliće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1033",
        brojPu: "35400",
        redBroj: "1033",
        nazivPu: "Nova Gradiška",
        naselje: "Dolina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1034",
        brojPu: "44400",
        redBroj: "1034",
        nazivPu: "Glina",
        naselje: "Dolnjaki",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1035",
        brojPu: "51511",
        redBroj: "1035",
        nazivPu: "Malinska",
        naselje: "Dolovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1036",
        brojPu: "51326",
        redBroj: "1036",
        nazivPu: "Vrbovsko",
        naselje: "Doluš",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1037",
        brojPu: "42204",
        redBroj: "1037",
        nazivPu: "Turčin",
        naselje: "Doljan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1038",
        brojPu: "34000",
        redBroj: "1038",
        nazivPu: "Požega",
        naselje: "Doljanci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1039",
        brojPu: "48260",
        redBroj: "1039",
        nazivPu: "Križevci",
        naselje: "Doljanec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1040",
        brojPu: "43500",
        redBroj: "1040",
        nazivPu: "Daruvar",
        naselje: "Doljani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1041",
        brojPu: "53220",
        redBroj: "1041",
        nazivPu: "Otočac",
        naselje: "Doljani",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1042",
        brojPu: "53250",
        redBroj: "1042",
        nazivPu: "Donji Lapac",
        naselje: "Doljani",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1043",
        brojPu: "47280",
        redBroj: "1043",
        nazivPu: "Ozalj",
        naselje: "Doljani Žumberački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1044",
        brojPu: "34000",
        redBroj: "1044",
        nazivPu: "Požega",
        naselje: "Doljanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1045",
        brojPu: "10450",
        redBroj: "1045",
        nazivPu: "Jastrebarsko",
        naselje: "Domagović",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1046",
        brojPu: "49210",
        redBroj: "1046",
        nazivPu: "Zabok",
        naselje: "Domahovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1047",
        brojPu: "48000",
        redBroj: "1047",
        nazivPu: "Koprivnica",
        naselje: "Domaji",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1048",
        brojPu: "43000",
        redBroj: "1048",
        nazivPu: "Bjelovar",
        naselje: "Domankuš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1049",
        brojPu: "10430",
        redBroj: "1049",
        nazivPu: "Samobor",
        naselje: "Domaslovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1050",
        brojPu: "40000",
        redBroj: "1050",
        nazivPu: "Čakovec",
        naselje: "Domašinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1051",
        brojPu: "52341",
        redBroj: "1051",
        nazivPu: "Žminj",
        naselje: "Domijanići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1052",
        brojPu: "43000",
        redBroj: "1052",
        nazivPu: "Bjelovar",
        naselje: "Dominkovica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1053",
        brojPu: "42000",
        redBroj: "1053",
        nazivPu: "Varaždin",
        naselje: "Domitrovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1054",
        brojPu: "49284",
        redBroj: "1054",
        nazivPu: "Budinščina",
        naselje: "Domovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1055",
        brojPu: "44250",
        redBroj: "1055",
        nazivPu: "Petrinja",
        naselje: "Donja Bačuga",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1056",
        brojPu: "20240",
        redBroj: "1056",
        nazivPu: "Trpanj",
        naselje: "Donja Banda",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1057",
        brojPu: "49250",
        redBroj: "1057",
        nazivPu: "Zlatar",
        naselje: "Donja Batina 1-215",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1058",
        brojPu: "49282",
        redBroj: "1058",
        nazivPu: "Konjščina",
        naselje: "Donja Batina 217-258",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1059",
        brojPu: "35000",
        redBroj: "1059",
        nazivPu: "Slavonski Brod",
        naselje: "Donja Bebrina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1060",
        brojPu: "10298",
        redBroj: "1060",
        nazivPu: "Bistra",
        naselje: "Donja Bistra",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1061",
        brojPu: "48260",
        redBroj: "1061",
        nazivPu: "Križevci",
        naselje: "Donja Brckovčina",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1062",
        brojPu: "47220",
        redBroj: "1062",
        nazivPu: "Vojnić",
        naselje: "Donja Brusovača",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1063",
        brojPu: "44400",
        redBroj: "1063",
        nazivPu: "Glina",
        naselje: "Donja Bučica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1064",
        brojPu: "44250",
        redBroj: "1064",
        nazivPu: "Petrinja",
        naselje: "Donja Budičina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1065",
        brojPu: "33520",
        redBroj: "1065",
        nazivPu: "Slatina",
        naselje: "Donja Bukovica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1066",
        brojPu: "44400",
        redBroj: "1066",
        nazivPu: "Glina",
        naselje: "Donja Čemernica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1067",
        brojPu: "51326",
        redBroj: "1067",
        nazivPu: "Vrbovsko",
        naselje: "Donja Dobra",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1068",
        brojPu: "10380",
        redBroj: "1068",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Donja Drenova",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1069",
        brojPu: "40320",
        redBroj: "1069",
        nazivPu: "Donji Kraljevec",
        naselje: "Donja Dubrava",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1070",
        brojPu: "47240",
        redBroj: "1070",
        nazivPu: "Slunj",
        naselje: "Donja Glina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1071",
        brojPu: "48260",
        redBroj: "1071",
        nazivPu: "Križevci",
        naselje: "Donja Glogovnica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1072",
        brojPu: "44317",
        redBroj: "1072",
        nazivPu: "Popovača",
        naselje: "Donja Gračenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1073",
        brojPu: "10370",
        redBroj: "1073",
        nazivPu: "Dugo Selo",
        naselje: "Donja Greda",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1074",
        brojPu: "23210",
        redBroj: "1074",
        nazivPu: "Biograd na moru",
        naselje: "Donja Jagodnja",
        zupanija: "ZADARSKA",
      },
      {
        id: "1075",
        brojPu: "44317",
        redBroj: "1075",
        nazivPu: "Popovača",
        naselje: "Donja Jelenska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1076",
        brojPu: "49282",
        redBroj: "1076",
        nazivPu: "Konjščina",
        naselje: "Donja Konjščina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1077",
        brojPu: "43270",
        redBroj: "1077",
        nazivPu: "Veliki Grđevac",
        naselje: "Donja Kovačica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1078",
        brojPu: "51300",
        redBroj: "1078",
        nazivPu: "Delnice",
        naselje: "Donja Krašićevica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1079",
        brojPu: "10451",
        redBroj: "1079",
        nazivPu: "Pisarovina",
        naselje: "Donja Kupčina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1080",
        brojPu: "51326",
        redBroj: "1080",
        nazivPu: "Vrbovsko",
        naselje: "Donja Lamana Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1081",
        brojPu: "44210",
        redBroj: "1081",
        nazivPu: "Sunja",
        naselje: "Donja Letina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1082",
        brojPu: "10410",
        redBroj: "1082",
        nazivPu: "Velika Gorica",
        naselje: "Donja Lomnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1083",
        brojPu: "44250",
        redBroj: "1083",
        nazivPu: "Petrinja",
        naselje: "Donja Mlinoga",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1084",
        brojPu: "31500",
        redBroj: "1084",
        nazivPu: "Našice",
        naselje: "Donja Motičina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1085",
        brojPu: "10310",
        redBroj: "1085",
        nazivPu: "Ivanić Grad",
        naselje: "Donja Obreška",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1086",
        brojPu: "34550",
        redBroj: "1086",
        nazivPu: "Pakrac",
        naselje: "Donja Obrijež",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1087",
        brojPu: "44440",
        redBroj: "1087",
        nazivPu: "Dvor",
        naselje: "Donja Oraovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1088",
        brojPu: "49223",
        redBroj: "1088",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Donja Pačetina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1089",
        brojPu: "44250",
        redBroj: "1089",
        nazivPu: "Petrinja",
        naselje: "Donja Pastuša",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1090",
        brojPu: "47250",
        redBroj: "1090",
        nazivPu: "Duga Resa",
        naselje: "Donja Perjasica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1091",
        brojPu: "43000",
        redBroj: "1091",
        nazivPu: "Bjelovar",
        naselje: "Donja Petrička",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1092",
        brojPu: "33515",
        redBroj: "1092",
        nazivPu: "Orahovica",
        naselje: "Donja Pištana",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1093",
        brojPu: "49218",
        redBroj: "1093",
        nazivPu: "Pregrada",
        naselje: "Donja Plemenšćina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1094",
        brojPu: "49240",
        redBroj: "1094",
        nazivPu: "Donja Stubica",
        naselje: "Donja Podgora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1095",
        brojPu: "42223",
        redBroj: "1095",
        nazivPu: "Varaždinske Toplice",
        naselje: "Donja Poljana",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1096",
        brojPu: "10450",
        redBroj: "1096",
        nazivPu: "Jastrebarsko",
        naselje: "Donja Purgarija",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1097",
        brojPu: "10290",
        redBroj: "1097",
        nazivPu: "Zaprešić",
        naselje: "Donja Pušća",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1098",
        brojPu: "43290",
        redBroj: "1098",
        nazivPu: "Grubišno Polje",
        naselje: "Donja Rašenica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1099",
        brojPu: "10450",
        redBroj: "1099",
        nazivPu: "Jastrebarsko",
        naselje: "Donja Reka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1100",
        brojPu: "48260",
        redBroj: "1100",
        nazivPu: "Križevci",
        naselje: "Donja Rijeka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1101",
        brojPu: "49250",
        redBroj: "1101",
        nazivPu: "Zlatar ",
        naselje: "Donja Selnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1102",
        brojPu: "47000",
        redBroj: "1102",
        nazivPu: "Karlovac",
        naselje: "Donja Stranica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1103",
        brojPu: "49240",
        redBroj: "1103",
        nazivPu: "Donja Stubica",
        naselje: "Donja Stubica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1104",
        brojPu: "44440",
        redBroj: "1104",
        nazivPu: "Dvor",
        naselje: "Donja Stupnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1105",
        brojPu: "53250",
        redBroj: "1105",
        nazivPu: "Donji Lapac",
        naselje: "Donja Suvaja",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1106",
        brojPu: "49000",
        redBroj: "1106",
        nazivPu: "Krapina",
        naselje: "Donja Šemnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1107",
        brojPu: "34550",
        redBroj: "1107",
        nazivPu: "Pakrac",
        naselje: "Donja Šumetlica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1108",
        brojPu: "43240",
        redBroj: "1108",
        nazivPu: "Čazma",
        naselje: "Donja Šušnjara",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1109",
        brojPu: "10380",
        redBroj: "1109",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Donja Topličica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1110",
        brojPu: "47000",
        redBroj: "1110",
        nazivPu: "Karlovac",
        naselje: "Donja Trebinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1111",
        brojPu: "44400",
        redBroj: "1111",
        nazivPu: "Glina",
        naselje: "Donja Trstenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1112",
        brojPu: "44430",
        redBroj: "1112",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Donja Velešnja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1113",
        brojPu: "10340",
        redBroj: "1113",
        nazivPu: "Vrbovec",
        naselje: "Donja Velika",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1114",
        brojPu: "48000",
        redBroj: "1114",
        nazivPu: "Koprivnica",
        naselje: "Donja Velika",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1115",
        brojPu: "47240",
        redBroj: "1115",
        nazivPu: "Slunj",
        naselje: "Donja Visočka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1116",
        brojPu: "42250",
        redBroj: "1116",
        nazivPu: "Lepoglava",
        naselje: "Donja Višnjica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1117",
        brojPu: "44317",
        redBroj: "1117",
        nazivPu: "Popovača",
        naselje: "Donja Vlahinička",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1118",
        brojPu: "42240",
        redBroj: "1118",
        nazivPu: "Ivanec",
        naselje: "Donja Voća",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1119",
        brojPu: "35000",
        redBroj: "1119",
        nazivPu: "Slavonski Brod",
        naselje: "Donja Vrba",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1120",
        brojPu: "43500",
        redBroj: "1120",
        nazivPu: "Daruvar",
        naselje: "Donja Vrijeska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1121",
        brojPu: "20240",
        redBroj: "1121",
        nazivPu: "Trpanj",
        naselje: "Donja Vrućica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1122",
        brojPu: "10450",
        redBroj: "1122",
        nazivPu: "Jastrebarsko",
        naselje: "Donja Zdenčina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1123",
        brojPu: "10380",
        redBroj: "1123",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Donja Zelina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1124",
        brojPu: "47240",
        redBroj: "1124",
        nazivPu: "Slunj",
        naselje: "Donja Žrvnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1125",
        brojPu: "48000",
        redBroj: "1125",
        nazivPu: "Koprivnica",
        naselje: "Donjara",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1126",
        brojPu: "33520",
        redBroj: "1126",
        nazivPu: "Slatina",
        naselje: "Donje Bazije",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1127",
        brojPu: "23420",
        redBroj: "1127",
        nazivPu: "Benkovac",
        naselje: "Donje Biljane",
        zupanija: "ZADARSKA",
      },
      {
        id: "1128",
        brojPu: "49231",
        redBroj: "1128",
        nazivPu: "Hum na Sutli",
        naselje: "Donje Brezno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1129",
        brojPu: "47250",
        redBroj: "1129",
        nazivPu: "Duga Resa",
        naselje: "Donje Bukovlje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1130",
        brojPu: "23420",
        redBroj: "1130",
        nazivPu: "Benkovac",
        naselje: "Donje Ceranje",
        zupanija: "ZADARSKA",
      },
      {
        id: "1131",
        brojPu: "43500",
        redBroj: "1131",
        nazivPu: "Daruvar",
        naselje: "Donje Cjepidlake",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1132",
        brojPu: "47250",
        redBroj: "1132",
        nazivPu: "Duga Resa",
        naselje: "Donje Dubrave",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1133",
        brojPu: "10370",
        redBroj: "1133",
        nazivPu: "Dugo Selo",
        naselje: "Donje Dvorišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1134",
        brojPu: "47240",
        redBroj: "1134",
        nazivPu: "Slunj",
        naselje: "Donje Gnojnice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1135",
        brojPu: "44400",
        redBroj: "1135",
        nazivPu: "Glina",
        naselje: "Donje Jame",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1136",
        brojPu: "49225",
        redBroj: "1136",
        nazivPu: "Đurmanec",
        naselje: "Donje Jesenje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1137",
        brojPu: "44010",
        redBroj: "1137",
        nazivPu: "Sisak-Caprag",
        naselje: "Donje Komarevo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1138",
        brojPu: "33520",
        redBroj: "1138",
        nazivPu: "Slatina",
        naselje: "Donje Kusonje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1139",
        brojPu: "42243",
        redBroj: "1139",
        nazivPu: "Maruševec",
        naselje: "Donje Ladanje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1140",
        brojPu: "42220",
        redBroj: "1140",
        nazivPu: "Novi Marof",
        naselje: "Donje Makojišće",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1141",
        brojPu: "47000",
        redBroj: "1141",
        nazivPu: "Karlovac",
        naselje: "Donje Mekušje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1142",
        brojPu: "44250",
        redBroj: "1142",
        nazivPu: "Petrinja",
        naselje: "Donje Mokrice",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1143",
        brojPu: "47250",
        redBroj: "1143",
        nazivPu: "Duga Resa",
        naselje: "Donje Mrzlo Polje Mrežničko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1144",
        brojPu: "32252",
        redBroj: "1144",
        nazivPu: "Otok",
        naselje: "Donje Novo Selo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1145",
        brojPu: "20236",
        redBroj: "1145",
        nazivPu: "Mokošica",
        naselje: "Donje Obuljeno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1146",
        brojPu: "21203",
        redBroj: "1146",
        nazivPu: "Donji Muć",
        naselje: "Donje Ogorje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1147",
        brojPu: "10380",
        redBroj: "1147",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Donje Orešje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1148",
        brojPu: "53000",
        redBroj: "1148",
        nazivPu: "Gospić",
        naselje: "Donje Pazarište",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1149",
        brojPu: "22323",
        redBroj: "1149",
        nazivPu: "Unešić",
        naselje: "Donje Planjane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1150",
        brojPu: "10410",
        redBroj: "1150",
        nazivPu: "Velika Gorica",
        naselje: "Donje Podotočje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1151",
        brojPu: "22000",
        redBroj: "1151",
        nazivPu: "Šibenik",
        naselje: "Donje Polje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1152",
        brojPu: "21203",
        redBroj: "1152",
        nazivPu: "Donji Muć",
        naselje: "Donje Postinje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1153",
        brojPu: "33515",
        redBroj: "1153",
        nazivPu: "Orahovica",
        naselje: "Donje Predrijevo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1154",
        brojPu: "10454",
        redBroj: "1154",
        nazivPu: "Krašić",
        naselje: "Donje Prekrižje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1155",
        brojPu: "47000",
        redBroj: "1155",
        nazivPu: "Karlovac",
        naselje: "Donje Prilišće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1156",
        brojPu: "47240",
        redBroj: "1156",
        nazivPu: "Slunj",
        naselje: "Donje Primišlje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1157",
        brojPu: "10380",
        redBroj: "1157",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Donje Psarjevo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1158",
        brojPu: "23210",
        redBroj: "1158",
        nazivPu: "Biograd na Moru",
        naselje: "Donje Raštane",
        zupanija: "ZADARSKA",
      },
      {
        id: "1159",
        brojPu: "44400",
        redBroj: "1159",
        nazivPu: "Glina",
        naselje: "Donje Selište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1160",
        brojPu: "21430",
        redBroj: "1160",
        nazivPu: "Grohote",
        naselje: "Donje Selo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1161",
        brojPu: "21311",
        redBroj: "1161",
        nazivPu: "Stobreč",
        naselje: "Donje Sitno",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1162",
        brojPu: "47000",
        redBroj: "1162",
        nazivPu: "Karlovac",
        naselje: "Donje Stative",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1163",
        brojPu: "44400",
        redBroj: "1163",
        nazivPu: "Glina",
        naselje: "Donje Taborište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1164",
        brojPu: "47240",
        redBroj: "1164",
        nazivPu: "Slunj",
        naselje: "Donje Taborište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1165",
        brojPu: "51300",
        redBroj: "1165",
        nazivPu: "Delnice",
        naselje: "Donje Tihovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1166",
        brojPu: "22323",
        redBroj: "1166",
        nazivPu: "Unešić",
        naselje: "Donje Utore",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1167",
        brojPu: "49217",
        redBroj: "1167",
        nazivPu: "Krapinske Toplice",
        naselje: "Donje Vino",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1168",
        brojPu: "22323",
        redBroj: "1168",
        nazivPu: "Unešić",
        naselje: "Donje Vinovo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1169",
        brojPu: "42243",
        redBroj: "1169",
        nazivPu: "Maruševec",
        naselje: "Donje Vratno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1170",
        brojPu: "42243",
        redBroj: "1170",
        nazivPu: "Maruševec",
        naselje: "Donje Vratno (Petrijanec)",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1171",
        brojPu: "47300",
        redBroj: "1171",
        nazivPu: "Ogulin",
        naselje: "Donje Zagorje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1172",
        brojPu: "48350",
        redBroj: "1172",
        nazivPu: "Đurđevac",
        naselje: "Donje Zdjelice",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1173",
        brojPu: "35214",
        redBroj: "1173",
        nazivPu: "Donji Andrijevci",
        naselje: "Donji Andrijevci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1174",
        brojPu: "53220",
        redBroj: "1174",
        nazivPu: "Otočac",
        naselje: "Donji Babin Potok",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1175",
        brojPu: "21233",
        redBroj: "1175",
        nazivPu: "Hrvace",
        naselje: "Donji Bitelić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1176",
        brojPu: "44430",
        redBroj: "1176",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Donji Bjelovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1177",
        brojPu: "35400",
        redBroj: "1177",
        nazivPu: "Nova Gradiška",
        naselje: "Donji Bogićevci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1178",
        brojPu: "43500",
        redBroj: "1178",
        nazivPu: "Daruvar",
        naselje: "Donji Borki",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1179",
        brojPu: "20207",
        redBroj: "1179",
        nazivPu: "Mlini",
        naselje: "Donji Brgat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1180",
        brojPu: "47220",
        redBroj: "1180",
        nazivPu: "Vojnić",
        naselje: "Donji Budački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1181",
        brojPu: "47000",
        redBroj: "1181",
        nazivPu: "Karlovac",
        naselje: "Donji Bukovac Žakanjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1182",
        brojPu: "47240",
        redBroj: "1182",
        nazivPu: "Slunj",
        naselje: "Donji Cerovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1183",
        brojPu: "44430",
        redBroj: "1183",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Donji Cerovljani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1184",
        brojPu: "35420",
        redBroj: "1184",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Donji Crnogovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1185",
        brojPu: "34550",
        redBroj: "1185",
        nazivPu: "Pakrac",
        naselje: "Donji Čaglić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1186",
        brojPu: "10020",
        redBroj: "1186",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Donji Čehi",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1187",
        brojPu: "10292",
        redBroj: "1187",
        nazivPu: "Šenkovec",
        naselje: "Donji Čemehovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1188",
        brojPu: "43500",
        redBroj: "1188",
        nazivPu: "Daruvar",
        naselje: "Donji Daruvar",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1189",
        brojPu: "10450",
        redBroj: "1189",
        nazivPu: "Jastrebarsko",
        naselje: "Donji Desinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1190",
        brojPu: "44440",
        redBroj: "1190",
        nazivPu: "Dvor",
        naselje: "Donji Dobretin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1191",
        brojPu: "21204",
        redBroj: "1191",
        nazivPu: "Dugopolje",
        naselje: "Donji Dolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1192",
        brojPu: "43240",
        redBroj: "1192",
        nazivPu: "Čazma",
        naselje: "Donji Draganec",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1193",
        brojPu: "43240",
        redBroj: "1193",
        nazivPu: "Čazma",
        naselje: "Donji Dragičevci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1194",
        brojPu: "10257",
        redBroj: "1194",
        nazivPu: "Brezovica",
        naselje: "Donji Dragonožec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1195",
        brojPu: "48260",
        redBroj: "1195",
        nazivPu: "Križevci",
        naselje: "Donji Dubovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1196",
        brojPu: "34000",
        redBroj: "1196",
        nazivPu: "Požega",
        naselje: "Donji Emovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1197",
        brojPu: "48260",
        redBroj: "1197",
        nazivPu: "Križevci",
        naselje: "Donji Fodrovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1198",
        brojPu: "47240",
        redBroj: "1198",
        nazivPu: "Slunj",
        naselje: "Donji Furjan",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1199",
        brojPu: "34550",
        redBroj: "1199",
        nazivPu: "Pakrac",
        naselje: "Donji Grahovljani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1200",
        brojPu: "34000",
        redBroj: "1200",
        nazivPu: "Požega",
        naselje: "Donji Gučani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1201",
        brojPu: "44210",
        redBroj: "1201",
        nazivPu: "Sunja",
        naselje: "Donji Hrastovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1202",
        brojPu: "40320",
        redBroj: "1202",
        nazivPu: "Donji Kraljevec",
        naselje: "Donji Hrašćan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1203",
        brojPu: "10413",
        redBroj: "1203",
        nazivPu: "Kravarsko",
        naselje: "Donji Hruševec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1204",
        brojPu: "21400",
        redBroj: "1204",
        nazivPu: "Supetar",
        naselje: "Donji Humac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1205",
        brojPu: "49216",
        redBroj: "1205",
        nazivPu: "Desinić",
        naselje: "Donji Jalšovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1206",
        brojPu: "44440",
        redBroj: "1206",
        nazivPu: "Dvor",
        naselje: "Donji Javoranj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1207",
        brojPu: "23450",
        redBroj: "1207",
        nazivPu: "Obrovac",
        naselje: "Donji Karin",
        zupanija: "ZADARSKA",
      },
      {
        id: "1208",
        brojPu: "23420",
        redBroj: "1208",
        nazivPu: "Benkovac",
        naselje: "Donji Kašić",
        zupanija: "ZADARSKA",
      },
      {
        id: "1209",
        brojPu: "44400",
        redBroj: "1209",
        nazivPu: "Glina",
        naselje: "Donji Klasnić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1210",
        brojPu: "42204",
        redBroj: "1210",
        nazivPu: "Turčin",
        naselje: "Donji Kneginec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1211",
        brojPu: "40313",
        redBroj: "1211",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Donji Koncovčak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1212",
        brojPu: "53202",
        redBroj: "1212",
        nazivPu: "Perušić",
        naselje: "Donji Kosinj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1213",
        brojPu: "40320",
        redBroj: "1213",
        nazivPu: "Donji Kraljevec",
        naselje: "Donji Kraljevec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1214",
        brojPu: "49284",
        redBroj: "1214",
        nazivPu: "Budinščina",
        naselje: "Donji Kraljevec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1215",
        brojPu: "47240",
        redBroj: "1215",
        nazivPu: "Slunj",
        naselje: "Donji Kremen",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1216",
        brojPu: "42000",
        redBroj: "1216",
        nazivPu: "Varaždin",
        naselje: "Donji Kućan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1217",
        brojPu: "44430",
        redBroj: "1217",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Donji Kukuruzari",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1218",
        brojPu: "10292",
        redBroj: "1218",
        nazivPu: "Šenkovec",
        naselje: "Donji Laduč",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1219",
        brojPu: "47240",
        redBroj: "1219",
        nazivPu: "Slunj",
        naselje: "Donji Lađevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1220",
        brojPu: "53250",
        redBroj: "1220",
        nazivPu: "Donji Lapac",
        naselje: "Donji Lapac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1221",
        brojPu: "23420",
        redBroj: "1221",
        nazivPu: "Benkovac",
        naselje: "Donji Lepuri",
        zupanija: "ZADARSKA",
      },
      {
        id: "1222",
        brojPu: "35410",
        redBroj: "1222",
        nazivPu: "Nova Kapela",
        naselje: "Donji Lipovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1223",
        brojPu: "43240",
        redBroj: "1223",
        nazivPu: "Čazma",
        naselje: "Donji Lipovčani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1224",
        brojPu: "47280",
        redBroj: "1224",
        nazivPu: "Ozalj",
        naselje: "Donji Lović",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1225",
        brojPu: "51300",
        redBroj: "1225",
        nazivPu: "Delnice",
        naselje: "Donji Ložac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1226",
        brojPu: "49225",
        redBroj: "1226",
        nazivPu: "Đurmanec",
        naselje: "Donji Macelj",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1227",
        brojPu: "10342",
        redBroj: "1227",
        nazivPu: "Dubrava",
        naselje: "Donji Marinkovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1228",
        brojPu: "10342",
        redBroj: "1228",
        nazivPu: "Dubrava",
        naselje: "Donji Markovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1229",
        brojPu: "48000",
        redBroj: "1229",
        nazivPu: "Koprivnica",
        naselje: "Donji Maslarac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1230",
        brojPu: "53234",
        redBroj: "1230",
        nazivPu: "Udbina",
        naselje: "Donji Mekinjar",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1231",
        brojPu: "33520",
        redBroj: "1231",
        nazivPu: "Slatina",
        naselje: "Donji Meljani",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1232",
        brojPu: "40320",
        redBroj: "1232",
        nazivPu: "Donji Kraljevec",
        naselje: "Donji Mihaljevec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1233",
        brojPu: "31540",
        redBroj: "1233",
        nazivPu: "Donji Miholjac",
        naselje: "Donji Miholjac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1234",
        brojPu: "43240",
        redBroj: "1234",
        nazivPu: "Čazma",
        naselje: "Donji Miklouš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1235",
        brojPu: "43000",
        redBroj: "1235",
        nazivPu: "Bjelovar",
        naselje: "Donji Mosti",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1236",
        brojPu: "21203",
        redBroj: "1236",
        nazivPu: "Donji Muć",
        naselje: "Donji Muć",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1237",
        brojPu: "47240",
        redBroj: "1237",
        nazivPu: "Slunj",
        naselje: "Donji Nikšić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1238",
        brojPu: "51300",
        redBroj: "1238",
        nazivPu: "Delnice",
        naselje: "Donji Okrug",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1239",
        brojPu: "10454",
        redBroj: "1239",
        nazivPu: "Krašić",
        naselje: "Donji Oštrc",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1240",
        brojPu: "47280",
        redBroj: "1240",
        nazivPu: "Ozalj",
        naselje: "Donji Oštri Vrh Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1241",
        brojPu: "47240",
        redBroj: "1241",
        nazivPu: "Slunj",
        naselje: "Donji Poloj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1242",
        brojPu: "47240",
        redBroj: "1242",
        nazivPu: "Slunj",
        naselje: "Donji Popovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1243",
        brojPu: "10315",
        redBroj: "1243",
        nazivPu: "Novoselec",
        naselje: "Donji Prnjarovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1244",
        brojPu: "21260",
        redBroj: "1244",
        nazivPu: "Imotski",
        naselje: "Donji Proložac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1245",
        brojPu: "40323",
        redBroj: "1245",
        nazivPu: "Prelog",
        naselje: "Donji Pustakovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1246",
        brojPu: "35430",
        redBroj: "1246",
        nazivPu: "Okučani",
        naselje: "Donji Rogolji",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1247",
        brojPu: "44400",
        redBroj: "1247",
        nazivPu: "Glina",
        naselje: "Donji Selkovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1248",
        brojPu: "47000",
        redBroj: "1248",
        nazivPu: "Karlovac",
        naselje: "Donji Sjeničak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1249",
        brojPu: "47250",
        redBroj: "1249",
        nazivPu: "Duga Resa",
        naselje: "Donji Skrad",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1250",
        brojPu: "35000",
        redBroj: "1250",
        nazivPu: "Slavonski Brod",
        naselje: "Donji Slatinik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1251",
        brojPu: "43500",
        redBroj: "1251",
        nazivPu: "Daruvar",
        naselje: "Donji Sređani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1252",
        brojPu: "10255",
        redBroj: "1252",
        nazivPu: "Gornji Stupnik",
        naselje: "Donji Stupnik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1253",
        brojPu: "51326",
        redBroj: "1253",
        nazivPu: "Vrbovsko",
        naselje: "Donji Šajn",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1254",
        brojPu: "51326",
        redBroj: "1254",
        nazivPu: "Vrbovsko",
        naselje: "Donji Šehovac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1255",
        brojPu: "49290",
        redBroj: "1255",
        nazivPu: "Klanjec",
        naselje: "Donji Škrnik",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1256",
        brojPu: "53250",
        redBroj: "1256",
        nazivPu: "Donji Lapac",
        naselje: "Donji Štrbci",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1257",
        brojPu: "10340",
        redBroj: "1257",
        nazivPu: "Vrbovec",
        naselje: "Donji Tkalec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1258",
        brojPu: "10257",
        redBroj: "1258",
        nazivPu: "Brezovica",
        naselje: "Donji Trpuci",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1259",
        brojPu: "51300",
        redBroj: "1259",
        nazivPu: "Delnice",
        naselje: "Donji Turni",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1260",
        brojPu: "53230",
        redBroj: "1260",
        nazivPu: "Korenica",
        naselje: "Donji Vaganac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1261",
        brojPu: "35430",
        redBroj: "1261",
        nazivPu: "Okučani",
        naselje: "Donji Varoš",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1262",
        brojPu: "47250",
        redBroj: "1262",
        nazivPu: "Duga Resa",
        naselje: "Donji Velemerić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1263",
        brojPu: "40320",
        redBroj: "1263",
        nazivPu: "Donji Kraljevec",
        naselje: "Donji Vidovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1264",
        brojPu: "44400",
        redBroj: "1264",
        nazivPu: "Glina",
        naselje: "Donji Viduševac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1265",
        brojPu: "21260",
        redBroj: "1265",
        nazivPu: "Imotski",
        naselje: "Donji Vinjani",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1266",
        brojPu: "51326",
        redBroj: "1266",
        nazivPu: "Vrbovsko",
        naselje: "Donji Vučkovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1267",
        brojPu: "44250",
        redBroj: "1267",
        nazivPu: "Petrinja",
        naselje: "Donji Vukojevac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1268",
        brojPu: "51326",
        redBroj: "1268",
        nazivPu: "Vrbovsko",
        naselje: "Donji Vukšići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1269",
        brojPu: "10342",
        redBroj: "1269",
        nazivPu: "Dubrava",
        naselje: "Donji Vukšinac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1270",
        brojPu: "51250",
        redBroj: "1270",
        nazivPu: "Novi Vinodolski",
        naselje: "Donji Zagon",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1271",
        brojPu: "49216",
        redBroj: "1271",
        nazivPu: "Desinić",
        naselje: "Donji Zbilj",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1272",
        brojPu: "40313",
        redBroj: "1272",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Donji Zebanec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1273",
        brojPu: "47250",
        redBroj: "1273",
        nazivPu: "Duga Resa",
        naselje: "Donji Zvečaj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1274",
        brojPu: "51306",
        redBroj: "1274",
        nazivPu: "Čabar",
        naselje: "Donji Žagari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1275",
        brojPu: "44440",
        redBroj: "1275",
        nazivPu: "Dvor",
        naselje: "Donji Žirovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1276",
        brojPu: "31431",
        redBroj: "1276",
        nazivPu: "Čepin",
        naselje: "Dopsin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1277",
        brojPu: "53288",
        redBroj: "1277",
        nazivPu: "Karlobag",
        naselje: "Došen Dabar",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1278",
        brojPu: "20246",
        redBroj: "1278",
        nazivPu: "Janjina",
        naselje: "Drače",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1279",
        brojPu: "52440",
        redBroj: "1279",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Dračevac",
        zupanija: "ISTARSKA",
      },
      {
        id: "1280",
        brojPu: "23241",
        redBroj: "1280",
        nazivPu: "Poličnik",
        naselje: "Dračevac Ninski",
        zupanija: "ZADARSKA",
      },
      {
        id: "1281",
        brojPu: "21400",
        redBroj: "1281",
        nazivPu: "Supetar",
        naselje: "Dračevica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1282",
        brojPu: "21480",
        redBroj: "1282",
        nazivPu: "Vis",
        naselje: "Dračevo Polje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1283",
        brojPu: "34000",
        redBroj: "1283",
        nazivPu: "Požega",
        naselje: "Draga",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1284",
        brojPu: "51523",
        redBroj: "1284",
        nazivPu: "Baška",
        naselje: "Draga Bašćanska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1285",
        brojPu: "51326",
        redBroj: "1285",
        nazivPu: "Vrbovsko",
        naselje: "Draga Lukovdolska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1286",
        brojPu: "10450",
        redBroj: "1286",
        nazivPu: "Jastrebarsko",
        naselje: "Draga Svetojanska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1287",
        brojPu: "35400",
        redBroj: "1287",
        nazivPu: "Nova Gradiška",
        naselje: "Dragalić",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1288",
        brojPu: "47000",
        redBroj: "1288",
        nazivPu: "Karlovac",
        naselje: "Draganić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1289",
        brojPu: "34340",
        redBroj: "1289",
        nazivPu: "Kutjevo",
        naselje: "Draganlug",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1290",
        brojPu: "48000",
        redBroj: "1290",
        nazivPu: "Koprivnica",
        naselje: "Draganovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1291",
        brojPu: "10430",
        redBroj: "1291",
        nazivPu: "Samobor",
        naselje: "Draganje Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1292",
        brojPu: "23210",
        redBroj: "1292",
        nazivPu: "Biograd na Moru",
        naselje: "Drage",
        zupanija: "ZADARSKA",
      },
      {
        id: "1293",
        brojPu: "47240",
        redBroj: "1293",
        nazivPu: "Slunj",
        naselje: "Drage",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1294",
        brojPu: "21276",
        redBroj: "1294",
        nazivPu: "Vrgorac",
        naselje: "Dragljane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1295",
        brojPu: "10430",
        redBroj: "1295",
        nazivPu: "Samobor",
        naselje: "Dragonoš",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1296",
        brojPu: "40000",
        redBroj: "1296",
        nazivPu: "Čakovec",
        naselje: "Dragoslavec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1297",
        brojPu: "40000",
        redBroj: "1297",
        nazivPu: "Čakovec",
        naselje: "Dragoslavec Breg",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1298",
        brojPu: "40000",
        redBroj: "1298",
        nazivPu: "Čakovec",
        naselje: "Dragoslavec Selo",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1299",
        brojPu: "47280",
        redBroj: "1299",
        nazivPu: "Ozalj",
        naselje: "Dragoševci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1300",
        brojPu: "10370",
        redBroj: "1300",
        nazivPu: "Dugo Selo",
        naselje: "Dragošička",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1301",
        brojPu: "31400",
        redBroj: "1301",
        nazivPu: "Đakovo",
        naselje: "Dragotin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1302",
        brojPu: "44400",
        redBroj: "1302",
        nazivPu: "Glina",
        naselje: "Dragotina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1303",
        brojPu: "44250",
        redBroj: "1303",
        nazivPu: "Petrinja",
        naselje: "Dragotinci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1304",
        brojPu: "10450",
        redBroj: "1304",
        nazivPu: "Jastrebarsko",
        naselje: "Dragovanščak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1305",
        brojPu: "35410",
        redBroj: "1305",
        nazivPu: "Nova Kapela",
        naselje: "Dragovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1306",
        brojPu: "23286",
        redBroj: "1306",
        nazivPu: "Božava",
        naselje: "Dragove",
        zupanija: "ZADARSKA",
      },
      {
        id: "1307",
        brojPu: "34550",
        redBroj: "1307",
        nazivPu: "Pakrac",
        naselje: "Dragović",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1308",
        brojPu: "51326",
        redBroj: "1308",
        nazivPu: "Vrbovsko",
        naselje: "Dragovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1309",
        brojPu: "51557",
        redBroj: "1309",
        nazivPu: "Cres",
        naselje: "Dragozetići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1310",
        brojPu: "52000",
        redBroj: "1310",
        nazivPu: "Pazin",
        naselje: "Draguć",
        zupanija: "ISTARSKA",
      },
      {
        id: "1311",
        brojPu: "52207",
        redBroj: "1311",
        nazivPu: "Barban",
        naselje: "Draguzeti",
        zupanija: "ISTARSKA",
      },
      {
        id: "1312",
        brojPu: "53230",
        redBroj: "1312",
        nazivPu: "Korenica",
        naselje: "Drakulić Rijeka",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1313",
        brojPu: "51260",
        redBroj: "1313",
        nazivPu: "Crikvenica",
        naselje: "Dramalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1314",
        brojPu: "51218",
        redBroj: "1314",
        nazivPu: "Dražice",
        naselje: "Drastin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1315",
        brojPu: "10454",
        redBroj: "1315",
        nazivPu: "Krašić",
        naselje: "Drašći Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1316",
        brojPu: "49290",
        redBroj: "1316",
        nazivPu: "Klanjec",
        naselje: "Draše",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1317",
        brojPu: "44440",
        redBroj: "1317",
        nazivPu: "Dvor",
        naselje: "Draškovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1318",
        brojPu: "40323",
        redBroj: "1318",
        nazivPu: "Prelog",
        naselje: "Draškovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1319",
        brojPu: "42220",
        redBroj: "1319",
        nazivPu: "Novi Marof",
        naselje: "Drašković",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1320",
        brojPu: "21327",
        redBroj: "1320",
        nazivPu: "Podgora",
        naselje: "Drašnice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1321",
        brojPu: "31300",
        redBroj: "1321",
        nazivPu: "Beli Manastir",
        naselje: "Draž",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1322",
        brojPu: "21276",
        redBroj: "1322",
        nazivPu: "Vrgorac",
        naselje: "Draževitići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1323",
        brojPu: "43270",
        redBroj: "1323",
        nazivPu: "Veliki Grđevac",
        naselje: "Dražica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1324",
        brojPu: "51218",
        redBroj: "1324",
        nazivPu: "Dražice",
        naselje: "Dražice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1325",
        brojPu: "10361",
        redBroj: "1325",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Drenčec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1326",
        brojPu: "44330",
        redBroj: "1326",
        nazivPu: "Novska",
        naselje: "Drenov Bok",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1327",
        brojPu: "53220",
        redBroj: "1327",
        nazivPu: "Otočac",
        naselje: "Drenov Klanac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1328",
        brojPu: "44400",
        redBroj: "1328",
        nazivPu: "Glina",
        naselje: "Drenovac Banski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1329",
        brojPu: "53250",
        redBroj: "1329",
        nazivPu: "Donji Lapac",
        naselje: "Drenovac Osredački",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1330",
        brojPu: "53000",
        redBroj: "1330",
        nazivPu: "Gospić",
        naselje: "Drenovac Radučki",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1331",
        brojPu: "32257",
        redBroj: "1331",
        nazivPu: "Drenovci",
        naselje: "Drenovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1332",
        brojPu: "42223",
        redBroj: "1332",
        nazivPu: "Varaždinske Toplice",
        naselje: "Drenovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1333",
        brojPu: "47000",
        redBroj: "1333",
        nazivPu: "Karlovac",
        naselje: "Drenovica Lipnička",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1334",
        brojPu: "31400",
        redBroj: "1334",
        nazivPu: "Đakovo",
        naselje: "Drenje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1335",
        brojPu: "52220",
        redBroj: "1335",
        nazivPu: "Labin",
        naselje: "Drenje",
        zupanija: "ISTARSKA",
      },
      {
        id: "1336",
        brojPu: "10291",
        redBroj: "1336",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Drenje Brdovečko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1337",
        brojPu: "10410",
        redBroj: "1337",
        nazivPu: "Velika Gorica",
        naselje: "Drenje Šćitarjevsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1338",
        brojPu: "47300",
        redBroj: "1338",
        nazivPu: "Ogulin",
        naselje: "Drežnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1339",
        brojPu: "35420",
        redBroj: "1339",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Drežnik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1340",
        brojPu: "10257",
        redBroj: "1340",
        nazivPu: "Brezovica",
        naselje: "Drežnik Brezovički",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1341",
        brojPu: "47240",
        redBroj: "1341",
        nazivPu: "Slunj",
        naselje: "Drežnik Grad",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1342",
        brojPu: "10435",
        redBroj: "1342",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Drežnik Podokićki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1343",
        brojPu: "51250",
        redBroj: "1343",
        nazivPu: "Novi Vinodolski",
        naselje: "Drinak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1344",
        brojPu: "22320",
        redBroj: "1344",
        nazivPu: "Drniš",
        naselje: "Drinovci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1345",
        brojPu: "51262",
        redBroj: "1345",
        nazivPu: "Kraljevica",
        naselje: "Drivenik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1346",
        brojPu: "43500",
        redBroj: "1346",
        nazivPu: "Daruvar",
        naselje: "Drlež",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1347",
        brojPu: "44210",
        redBroj: "1347",
        nazivPu: "Sunja",
        naselje: "Drljača",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1348",
        brojPu: "43270",
        redBroj: "1348",
        nazivPu: "Veliki Grđevac",
        naselje: "Drljanovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1349",
        brojPu: "10410",
        redBroj: "1349",
        nazivPu: "Velika Gorica",
        naselje: "Drnek",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1350",
        brojPu: "22320",
        redBroj: "1350",
        nazivPu: "Drniš",
        naselje: "Drniš",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1351",
        brojPu: "48316",
        redBroj: "1351",
        nazivPu: "Đelekovec",
        naselje: "Drnje",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1352",
        brojPu: "10340",
        redBroj: "1352",
        nazivPu: "Vrbovec",
        naselje: "Dropčevec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1353",
        brojPu: "48260",
        redBroj: "1353",
        nazivPu: "Križevci",
        naselje: "Dropkovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1354",
        brojPu: "34000",
        redBroj: "1354",
        nazivPu: "Požega",
        naselje: "Drškovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1355",
        brojPu: "21260",
        redBroj: "1355",
        nazivPu: "Imotski",
        naselje: "Drum",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1356",
        brojPu: "42243",
        redBroj: "1356",
        nazivPu: "Maruševec",
        naselje: "Druškovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1357",
        brojPu: "49231",
        redBroj: "1357",
        nazivPu: "Hum na Sutli",
        naselje: "Druškovec Gora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1358",
        brojPu: "49231",
        redBroj: "1358",
        nazivPu: "Hum na Sutli",
        naselje: "Druškovec Humski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1359",
        brojPu: "42208",
        redBroj: "1359",
        nazivPu: "Cestica",
        naselje: "Družbinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1360",
        brojPu: "49210",
        redBroj: "1360",
        nazivPu: "Zabok",
        naselje: "Družilovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1361",
        brojPu: "20215",
        redBroj: "1361",
        nazivPu: "Gruda",
        naselje: "Drvenik",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1362",
        brojPu: "21330",
        redBroj: "1362",
        nazivPu: "Gradac",
        naselje: "Drvenik",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1363",
        brojPu: "21225",
        redBroj: "1363",
        nazivPu: "Drvenik Veliki",
        naselje: "Drvenik Mali",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1364",
        brojPu: "21225",
        redBroj: "1364",
        nazivPu: "Drvenik Veliki",
        naselje: "Drvenik Veliki",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1365",
        brojPu: "40000",
        redBroj: "1365",
        nazivPu: "Čakovec",
        naselje: "Držimurec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1366",
        brojPu: "20355",
        redBroj: "1366",
        nazivPu: "Opuzen",
        naselje: "Duba",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1367",
        brojPu: "20210",
        redBroj: "1367",
        nazivPu: "Cavtat",
        naselje: "Duba Konavoska",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1368",
        brojPu: "20240",
        redBroj: "1368",
        nazivPu: "Trpanj",
        naselje: "Duba Pelješka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1369",
        brojPu: "20230",
        redBroj: "1369",
        nazivPu: "Ston",
        naselje: "Duba Stonska",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1370",
        brojPu: "35000",
        redBroj: "1370",
        nazivPu: "Slavonski Brod",
        naselje: "Dubočac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1371",
        brojPu: "20355",
        redBroj: "1371",
        nazivPu: "Opuzen",
        naselje: "Duboka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1372",
        brojPu: "21480",
        redBroj: "1372",
        nazivPu: "Vis",
        naselje: "Duboka",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1373",
        brojPu: "34340",
        redBroj: "1373",
        nazivPu: "Kutjevo",
        naselje: "Duboka",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1374",
        brojPu: "23440",
        redBroj: "1374",
        nazivPu: "Gračac",
        naselje: "Duboki Dol",
        zupanija: "ZADARSKA",
      },
      {
        id: "1375",
        brojPu: "31300",
        redBroj: "1375",
        nazivPu: "Beli Manastir",
        naselje: "Duboševica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1376",
        brojPu: "35430",
        redBroj: "1376",
        nazivPu: "Okučani",
        naselje: "Dubovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1377",
        brojPu: "49240",
        redBroj: "1377",
        nazivPu: "Donja Stubica",
        naselje: "Dubovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1378",
        brojPu: "10380",
        redBroj: "1378",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Dubovec Bisaški",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1379",
        brojPu: "42230",
        redBroj: "1379",
        nazivPu: "Ludbreg",
        naselje: "Dubovica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1380",
        brojPu: "35000",
        redBroj: "1380",
        nazivPu: "Slavonski Brod",
        naselje: "Dubovik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1381",
        brojPu: "10410",
        redBroj: "1381",
        nazivPu: "Velika Gorica",
        naselje: "Dubranec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1382",
        brojPu: "10342",
        redBroj: "1382",
        nazivPu: "Dubrava",
        naselje: "Dubrava",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1383",
        brojPu: "20246",
        redBroj: "1383",
        nazivPu: "Janjina",
        naselje: "Dubrava",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1384",
        brojPu: "21292",
        redBroj: "1384",
        nazivPu: "Srinjine",
        naselje: "Dubrava",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1385",
        brojPu: "22000",
        redBroj: "1385",
        nazivPu: "Šibenik",
        naselje: "Dubrava kod Šibenika",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1386",
        brojPu: "22240",
        redBroj: "1386",
        nazivPu: "Tisno",
        naselje: "Dubrava kod Tisna",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1387",
        brojPu: "42208",
        redBroj: "1387",
        nazivPu: "Cestica",
        naselje: "Dubrava Križovljanska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1388",
        brojPu: "10290",
        redBroj: "1388",
        nazivPu: "Zaprešić",
        naselje: "Dubrava Pušćanska",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1389",
        brojPu: "10430",
        redBroj: "1389",
        nazivPu: "Samobor",
        naselje: "Dubrava Samoborska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1390",
        brojPu: "49210",
        redBroj: "1390",
        nazivPu: "Zabok",
        naselje: "Dubrava Zabočka",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1391",
        brojPu: "47250",
        redBroj: "1391",
        nazivPu: "Duga Resa",
        naselje: "Dubravci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1392",
        brojPu: "52341",
        redBroj: "1392",
        nazivPu: "Žminj",
        naselje: "Dubravci",
        zupanija: "ISTARSKA",
      },
      {
        id: "1393",
        brojPu: "47250",
        redBroj: "1393",
        nazivPu: "Duga Resa",
        naselje: "Dubravčani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1394",
        brojPu: "20350",
        redBroj: "1394",
        nazivPu: "Metković",
        naselje: "Dubrave",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1395",
        brojPu: "47240",
        redBroj: "1395",
        nazivPu: "Slunj",
        naselje: "Dubrave",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1396",
        brojPu: "42240",
        redBroj: "1396",
        nazivPu: "Ivanec",
        naselje: "Dubravec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1397",
        brojPu: "10292",
        redBroj: "1397",
        nazivPu: "Šenkovec",
        naselje: "Dubravica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1398",
        brojPu: "20232",
        redBroj: "1398",
        nazivPu: "Slano",
        naselje: "Dubravica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1399",
        brojPu: "20350",
        redBroj: "1399",
        nazivPu: "Metković",
        naselje: "Dubravica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1400",
        brojPu: "49216",
        redBroj: "1400",
        nazivPu: "Desinić",
        naselje: "Dubravica Desinićka",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1401",
        brojPu: "22222",
        redBroj: "1401",
        nazivPu: "Skradin",
        naselje: "Dubravice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1402",
        brojPu: "20215",
        redBroj: "1402",
        nazivPu: "Gruda",
        naselje: "Dubravka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1403",
        brojPu: "10342",
        redBroj: "1403",
        nazivPu: "Dubrava",
        naselje: "Dubravski Markovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1404",
        brojPu: "49210",
        redBroj: "1404",
        nazivPu: "Zabok",
        naselje: "Dubrovčan 1-130",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1405",
        brojPu: "49290",
        redBroj: "1405",
        nazivPu: "Klanjec",
        naselje: "Dubrovčan 131-214",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1406",
        brojPu: "20000",
        redBroj: "1406",
        nazivPu: "Dubrovnik",
        naselje: "Dubrovnik",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1407",
        brojPu: "47280",
        redBroj: "1407",
        nazivPu: "Ozalj",
        naselje: "Dučići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1408",
        brojPu: "21310",
        redBroj: "1408",
        nazivPu: "Omiš",
        naselje: "Duće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1409",
        brojPu: "47250",
        redBroj: "1409",
        nazivPu: "Duga Resa",
        naselje: "Duga Gora",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1410",
        brojPu: "52220",
        redBroj: "1410",
        nazivPu: "Labin",
        naselje: "Duga Luka",
        zupanija: "ISTARSKA",
      },
      {
        id: "1411",
        brojPu: "33515",
        redBroj: "1411",
        nazivPu: "Orahovica",
        naselje: "Duga Međa",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1412",
        brojPu: "47250",
        redBroj: "1412",
        nazivPu: "Duga Resa",
        naselje: "Duga Resa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1413",
        brojPu: "48000",
        redBroj: "1413",
        nazivPu: "Koprivnica",
        naselje: "Duga Rijeka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1414",
        brojPu: "47250",
        redBroj: "1414",
        nazivPu: "Duga Resa",
        naselje: "Dugače",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1415",
        brojPu: "21276",
        redBroj: "1415",
        nazivPu: "Vrgorac",
        naselje: "Duge Njive",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1416",
        brojPu: "47220",
        redBroj: "1416",
        nazivPu: "Vojnić",
        naselje: "Dugi Dol",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1417",
        brojPu: "21315",
        redBroj: "1417",
        nazivPu: "Dugi Rat",
        naselje: "Dugi Rat",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1418",
        brojPu: "49290",
        redBroj: "1418",
        nazivPu: "Klanjec",
        naselje: "Dugnjevec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1419",
        brojPu: "10370",
        redBroj: "1419",
        nazivPu: "Dugo Selo",
        naselje: "Dugo Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1420",
        brojPu: "44410",
        redBroj: "1420",
        nazivPu: "Vrginmost",
        naselje: "Dugo Selo Lasinjsko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1421",
        brojPu: "33000",
        redBroj: "1421",
        nazivPu: "Virovitica",
        naselje: "Dugo Selo Lukačko",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1422",
        brojPu: "21204",
        redBroj: "1422",
        nazivPu: "Dugopolje",
        naselje: "Dugobabe",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1423",
        brojPu: "21204",
        redBroj: "1423",
        nazivPu: "Dugopolje",
        naselje: "Dugopolje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1424",
        brojPu: "53250",
        redBroj: "1424",
        nazivPu: "Donji Lapac",
        naselje: "Dugopolje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1425",
        brojPu: "43280",
        redBroj: "1425",
        nazivPu: "Garešnica",
        naselje: "Duhovi",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1426",
        brojPu: "47300",
        redBroj: "1426",
        nazivPu: "Ogulin",
        naselje: "Dujmić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1427",
        brojPu: "49223",
        redBroj: "1427",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Dukovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1428",
        brojPu: "10340",
        redBroj: "1428",
        nazivPu: "Vrbovec",
        naselje: "Dulepska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1429",
        brojPu: "44250",
        redBroj: "1429",
        nazivPu: "Petrinja",
        naselje: "Dumače",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1430",
        brojPu: "10361",
        redBroj: "1430",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Dumovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1431",
        brojPu: "20215",
        redBroj: "1431",
        nazivPu: "Gruda",
        naselje: "Dunave",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1432",
        brojPu: "47220",
        redBroj: "1432",
        nazivPu: "Vojnić",
        naselje: "Dunjak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1433",
        brojPu: "40000",
        redBroj: "1433",
        nazivPu: "Čakovec",
        naselje: "Dunjkovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1434",
        brojPu: "52000",
        redBroj: "1434",
        nazivPu: "Pazin",
        naselje: "Duričići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1435",
        brojPu: "47280",
        redBroj: "1435",
        nazivPu: "Ozalj",
        naselje: "Durlinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1436",
        brojPu: "21276",
        redBroj: "1436",
        nazivPu: "Vrgorac",
        naselje: "Dusina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1437",
        brojPu: "33515",
        redBroj: "1437",
        nazivPu: "Orahovica",
        naselje: "Duzluk",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1438",
        brojPu: "44250",
        redBroj: "1438",
        nazivPu: "Petrinja",
        naselje: "Dužica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1439",
        brojPu: "44440",
        redBroj: "1439",
        nazivPu: "Dvor",
        naselje: "Dvor",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1440",
        brojPu: "10451",
        redBroj: "1440",
        nazivPu: "Pisarovina",
        naselje: "Dvoranci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1441",
        brojPu: "52440",
        redBroj: "1441",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Dvori",
        zupanija: "ISTARSKA",
      },
      {
        id: "1442",
        brojPu: "10340",
        redBroj: "1442",
        nazivPu: "Vrbovec",
        naselje: "Dvorišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1443",
        brojPu: "44400",
        redBroj: "1443",
        nazivPu: "Glina",
        naselje: "Dvorišće",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1444",
        brojPu: "47280",
        redBroj: "1444",
        nazivPu: "Ozalj",
        naselje: "Dvorišće Ozaljsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1445",
        brojPu: "47220",
        redBroj: "1445",
        nazivPu: "Vojnić",
        naselje: "Dvorište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1446",
        brojPu: "47280",
        redBroj: "1446",
        nazivPu: "Ozalj",
        naselje: "Dvorište Vivodinsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1447",
        brojPu: "47250",
        redBroj: "1447",
        nazivPu: "Duga Resa",
        naselje: "Dvorjanci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1448",
        brojPu: "22202",
        redBroj: "1448",
        nazivPu: "Primošten",
        naselje: "Dvornica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1449",
        brojPu: "33410",
        redBroj: "1449",
        nazivPu: "Suhopolje",
        naselje: "Dvorska",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1450",
        brojPu: "47220",
        redBroj: "1450",
        nazivPu: "Vojnić",
        naselje: "Džaperovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1451",
        brojPu: "31400",
        redBroj: "1451",
        nazivPu: "Đakovo",
        naselje: "Đakovo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1452",
        brojPu: "48316",
        redBroj: "1452",
        nazivPu: "Đelekovec",
        naselje: "Đelekovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1453",
        brojPu: "32252",
        redBroj: "1453",
        nazivPu: "Otok",
        naselje: "Đeletovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1454",
        brojPu: "22300",
        redBroj: "1454",
        nazivPu: "Knin",
        naselje: "Đevrske",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1455",
        brojPu: "10340",
        redBroj: "1455",
        nazivPu: "Vrbovec",
        naselje: "Đivan",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1456",
        brojPu: "52470",
        redBroj: "1456",
        nazivPu: "Umag (Umago)",
        naselje: "Đuba - Giubba",
        zupanija: "ISTARSKA",
      },
      {
        id: "1457",
        brojPu: "43500",
        redBroj: "1457",
        nazivPu: "Daruvar",
        naselje: "Đulovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1458",
        brojPu: "32284",
        redBroj: "1458",
        nazivPu: "Stari Mikanovci",
        naselje: "Đurđanci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1459",
        brojPu: "10362",
        redBroj: "1459",
        nazivPu: "Kašina",
        naselje: "Đurđekovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1460",
        brojPu: "31511",
        redBroj: "1460",
        nazivPu: "Đurđenovac",
        naselje: "Đurđenovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1461",
        brojPu: "48350",
        redBroj: "1461",
        nazivPu: "Đurđevac",
        naselje: "Đurđevac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1462",
        brojPu: "43000",
        redBroj: "1462",
        nazivPu: "Bjelovar",
        naselje: "Đurđic",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1463",
        brojPu: "48260",
        redBroj: "1463",
        nazivPu: "Križevci",
        naselje: "Đurđic",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1464",
        brojPu: "33520",
        redBroj: "1464",
        nazivPu: "Slatina",
        naselje: "Đuričić",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1465",
        brojPu: "32257",
        redBroj: "1465",
        nazivPu: "Drenovci",
        naselje: "Đurići",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1466",
        brojPu: "47240",
        redBroj: "1466",
        nazivPu: "Slunj",
        naselje: "Đurin Potok",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1467",
        brojPu: "20215",
        redBroj: "1467",
        nazivPu: "Gruda",
        naselje: "Đurinići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1468",
        brojPu: "42220",
        redBroj: "1468",
        nazivPu: "Novi Marof",
        naselje: "Đurinovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1469",
        brojPu: "49225",
        redBroj: "1469",
        nazivPu: "Đurmanec",
        naselje: "Đurmanec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1470",
        brojPu: "34000",
        redBroj: "1470",
        nazivPu: "Požega",
        naselje: "Eminovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1471",
        brojPu: "34000",
        redBroj: "1471",
        nazivPu: "Požega",
        naselje: "Emovački Lug",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1472",
        brojPu: "21232",
        redBroj: "1472",
        nazivPu: "Dicmo",
        naselje: "Ercegovci",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1473",
        brojPu: "47250",
        redBroj: "1473",
        nazivPu: "Duga Resa",
        naselje: "Erdelj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1474",
        brojPu: "48260",
        redBroj: "1474",
        nazivPu: "Križevci",
        naselje: "Erdovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1475",
        brojPu: "31226",
        redBroj: "1475",
        nazivPu: "Dalj",
        naselje: "Erdut",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1476",
        brojPu: "52420",
        redBroj: "1476",
        nazivPu: "Buzet",
        naselje: "Erkovčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1477",
        brojPu: "31200",
        redBroj: "1477",
        nazivPu: "Osijek",
        naselje: "Ernestinovo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1478",
        brojPu: "47000",
        redBroj: "1478",
        nazivPu: "Karlovac",
        naselje: "Ertić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1479",
        brojPu: "22300",
        redBroj: "1479",
        nazivPu: "Knin",
        naselje: "Ervenik",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1480",
        brojPu: "49246",
        redBroj: "1480",
        nazivPu: "Marija Bistrica",
        naselje: "Ervenik Zlatarski 1-5 i 37-118",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1481",
        brojPu: "49250",
        redBroj: "1481",
        nazivPu: "Zlatar ",
        naselje: "Ervenik Zlatarski 9-37",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1482",
        brojPu: "52220",
        redBroj: "1482",
        nazivPu: "Labin",
        naselje: "Eržišće",
        zupanija: "ISTARSKA",
      },
      {
        id: "1483",
        brojPu: "52440",
        redBroj: "1483",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Fabci",
        zupanija: "ISTARSKA",
      },
      {
        id: "1484",
        brojPu: "48260",
        redBroj: "1484",
        nazivPu: "Križevci",
        naselje: "Fajerovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1485",
        brojPu: "10435",
        redBroj: "1485",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Falašćak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1486",
        brojPu: "42208",
        redBroj: "1486",
        nazivPu: "Cestica",
        naselje: "Falinić Breg",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1487",
        brojPu: "52440",
        redBroj: "1487",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Farini",
        zupanija: "ISTARSKA",
      },
      {
        id: "1488",
        brojPu: "10342",
        redBroj: "1488",
        nazivPu: "Dubrava",
        naselje: "Farkaševac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1489",
        brojPu: "10430",
        redBroj: "1489",
        nazivPu: "Samobor",
        naselje: "Farkaševec Samoborski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1490",
        brojPu: "52100",
        redBroj: "1490",
        nazivPu: "Pula (Pola)",
        naselje: "Fažana - Fasana",
        zupanija: "ISTARSKA",
      },
      {
        id: "1491",
        brojPu: "51306",
        redBroj: "1491",
        nazivPu: "Čabar",
        naselje: "Fažonci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1492",
        brojPu: "51306",
        redBroj: "1492",
        nazivPu: "Čabar",
        naselje: "Ferbežari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1493",
        brojPu: "48350",
        redBroj: "1493",
        nazivPu: "Đurđevac",
        naselje: "Ferdinandovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1494",
        brojPu: "47280",
        redBroj: "1494",
        nazivPu: "Ozalj",
        naselje: "Ferenci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1495",
        brojPu: "52440",
        redBroj: "1495",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ferenci",
        zupanija: "ISTARSKA",
      },
      {
        id: "1496",
        brojPu: "48260",
        redBroj: "1496",
        nazivPu: "Križevci",
        naselje: "Ferežani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1497",
        brojPu: "31511",
        redBroj: "1497",
        nazivPu: "Đurđenovac",
        naselje: "Feričanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1498",
        brojPu: "40315",
        redBroj: "1498",
        nazivPu: "Mursko Središče",
        naselje: "Ferketinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1499",
        brojPu: "34340",
        redBroj: "1499",
        nazivPu: "Kutjevo",
        naselje: "Ferovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1500",
        brojPu: "10340",
        redBroj: "1500",
        nazivPu: "Vrbovec",
        naselje: "Festinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1501",
        brojPu: "52207",
        redBroj: "1501",
        nazivPu: "Barban",
        naselje: "Filipana",
        zupanija: "ISTARSKA",
      },
      {
        id: "1502",
        brojPu: "52440",
        redBroj: "1502",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Filipi",
        zupanija: "ISTARSKA",
      },
      {
        id: "1503",
        brojPu: "42240",
        redBroj: "1503",
        nazivPu: "Ivanec",
        naselje: "Filipići",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1504",
        brojPu: "52440",
        redBroj: "1504",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Filipini",
        zupanija: "ISTARSKA",
      },
      {
        id: "1505",
        brojPu: "34550",
        redBroj: "1505",
        nazivPu: "Pakrac",
        naselje: "Filipovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1506",
        brojPu: "10380",
        redBroj: "1506",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Filipovići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1507",
        brojPu: "51557",
        redBroj: "1507",
        nazivPu: "Cres",
        naselje: "Filozići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1508",
        brojPu: "48260",
        redBroj: "1508",
        nazivPu: "Križevci",
        naselje: "Finčevec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1509",
        brojPu: "52470",
        redBroj: "1509",
        nazivPu: "Umag (Umago)",
        naselje: "Finida - Finida",
        zupanija: "ISTARSKA",
      },
      {
        id: "1510",
        brojPu: "52466",
        redBroj: "1510",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Fiorini - Fiorini",
        zupanija: "ISTARSKA",
      },
      {
        id: "1511",
        brojPu: "52440",
        redBroj: "1511",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Flengi",
        zupanija: "ISTARSKA",
      },
      {
        id: "1512",
        brojPu: "49290",
        redBroj: "1512",
        nazivPu: "Klanjec",
        naselje: "Florijan",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1513",
        brojPu: "48260",
        redBroj: "1513",
        nazivPu: "Križevci",
        naselje: "Fodrovec Riječki",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1514",
        brojPu: "52341",
        redBroj: "1514",
        nazivPu: "Žminj",
        naselje: "Foli",
        zupanija: "ISTARSKA",
      },
      {
        id: "1515",
        brojPu: "52420",
        redBroj: "1515",
        nazivPu: "Buzet",
        naselje: "Forčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1516",
        brojPu: "31400",
        redBroj: "1516",
        nazivPu: "Đakovo",
        naselje: "Forkuševci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1517",
        brojPu: "42240",
        redBroj: "1517",
        nazivPu: "Ivanec",
        naselje: "Fotez Breg",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1518",
        brojPu: "52220",
        redBroj: "1518",
        nazivPu: "Labin",
        naselje: "Frančići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1519",
        brojPu: "52440",
        redBroj: "1519",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Frata",
        zupanija: "ISTARSKA",
      },
      {
        id: "1520",
        brojPu: "47250",
        redBroj: "1520",
        nazivPu: "Duga Resa",
        naselje: "Fratrovci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1521",
        brojPu: "47280",
        redBroj: "1521",
        nazivPu: "Ozalj",
        naselje: "Fratrovci Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1522",
        brojPu: "40000",
        redBroj: "1522",
        nazivPu: "Čakovec",
        naselje: "Frkanovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1523",
        brojPu: "53230",
        redBroj: "1523",
        nazivPu: "Korenica",
        naselje: "Frkašić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1524",
        brojPu: "47250",
        redBroj: "1524",
        nazivPu: "Duga Resa",
        naselje: "Frketić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1525",
        brojPu: "34310",
        redBroj: "1525",
        nazivPu: "Pleternica",
        naselje: "Frkljevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1526",
        brojPu: "49252",
        redBroj: "1526",
        nazivPu: "Mihovljan",
        naselje: "Frkuljevec Mihovljanski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1527",
        brojPu: "49250",
        redBroj: "1527",
        nazivPu: "Zlatar",
        naselje: "Frkuljevec Peršaveški",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1528",
        brojPu: "52440",
        redBroj: "1528",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Frnjolići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1529",
        brojPu: "47250",
        redBroj: "1529",
        nazivPu: "Duga Resa",
        naselje: "Fučkovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1530",
        brojPu: "10342",
        redBroj: "1530",
        nazivPu: "Dubrava",
        naselje: "Fuka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1531",
        brojPu: "52440",
        redBroj: "1531",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Funtana - Fontane",
        zupanija: "ISTARSKA",
      },
      {
        id: "1532",
        brojPu: "47280",
        redBroj: "1532",
        nazivPu: "Ozalj",
        naselje: "Furjanići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1533",
        brojPu: "52440",
        redBroj: "1533",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Fuškulin",
        zupanija: "ISTARSKA",
      },
      {
        id: "1534",
        brojPu: "51300",
        redBroj: "1534",
        nazivPu: "Delnice",
        naselje: "Fužine",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1535",
        brojPu: "48316",
        redBroj: "1535",
        nazivPu: "Đelekovec",
        naselje: "Gabajeva Greda",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1536",
        brojPu: "49216",
        redBroj: "1536",
        nazivPu: "Desinić",
        naselje: "Gaber",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1537",
        brojPu: "51511",
        redBroj: "1537",
        nazivPu: "Malinska",
        naselje: "Gabonjin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1538",
        brojPu: "32100",
        redBroj: "1538",
        nazivPu: "Vinkovci",
        naselje: "Gaboš",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1539",
        brojPu: "20215",
        redBroj: "1539",
        nazivPu: "Gruda",
        naselje: "Gabrili",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1540",
        brojPu: "31511",
        redBroj: "1540",
        nazivPu: "Đurđenovac",
        naselje: "Gabrilovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1541",
        brojPu: "49218",
        redBroj: "1541",
        nazivPu: "Pregrada",
        naselje: "Gabrovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1542",
        brojPu: "47220",
        redBroj: "1542",
        nazivPu: "Vojnić",
        naselje: "Gačeša Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1543",
        brojPu: "42240",
        redBroj: "1543",
        nazivPu: "Ivanec",
        naselje: "Gačice",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1544",
        brojPu: "22000",
        redBroj: "1544",
        nazivPu: "Šibenik",
        naselje: "Gaćelezi",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1545",
        brojPu: "47250",
        redBroj: "1545",
        nazivPu: "Duga Resa",
        naselje: "Gaćeško Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1546",
        brojPu: "33410",
        redBroj: "1546",
        nazivPu: "Suhopolje",
        naselje: "Gaćište",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1547",
        brojPu: "44440",
        redBroj: "1547",
        nazivPu: "Dvor",
        naselje: "Gage",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1548",
        brojPu: "10340",
        redBroj: "1548",
        nazivPu: "Vrbovec",
        naselje: "Gaj",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1549",
        brojPu: "34551",
        redBroj: "1549",
        nazivPu: "Lipik",
        naselje: "Gaj",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1550",
        brojPu: "53291",
        redBroj: "1550",
        nazivPu: "Novalja",
        naselje: "Gajac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1551",
        brojPu: "52100",
        redBroj: "1551",
        nazivPu: "Pula (Pola)",
        naselje: "Gajana - Gaiano",
        zupanija: "ISTARSKA",
      },
      {
        id: "1552",
        brojPu: "10362",
        redBroj: "1552",
        nazivPu: "Kašina",
        naselje: "Gajec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1553",
        brojPu: "31300",
        redBroj: "1553",
        nazivPu: "Beli Manastir",
        naselje: "Gajić",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1554",
        brojPu: "53250",
        redBroj: "1554",
        nazivPu: "Donji Lapac",
        naselje: "Gajine",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1555",
        brojPu: "21238",
        redBroj: "1555",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Gala",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1556",
        brojPu: "47280",
        redBroj: "1556",
        nazivPu: "Ozalj",
        naselje: "Galezova Draga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1557",
        brojPu: "10435",
        redBroj: "1557",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Galgovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1558",
        brojPu: "47280",
        redBroj: "1558",
        nazivPu: "Ozalj",
        naselje: "Galin",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1559",
        brojPu: "52100",
        redBroj: "1559",
        nazivPu: "Pula (Pola)",
        naselje: "Galižana - Gallesano",
        zupanija: "ISTARSKA",
      },
      {
        id: "1560",
        brojPu: "23222",
        redBroj: "1560",
        nazivPu: "Zemunik",
        naselje: "Galovac",
        zupanija: "ZADARSKA",
      },
      {
        id: "1561",
        brojPu: "43000",
        redBroj: "1561",
        nazivPu: "Bjelovar",
        naselje: "Galovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1562",
        brojPu: "49282",
        redBroj: "1562",
        nazivPu: "Konjščina",
        naselje: "Galovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1563",
        brojPu: "49223",
        redBroj: "1563",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Galovec Začretski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1564",
        brojPu: "47250",
        redBroj: "1564",
        nazivPu: "Duga Resa",
        naselje: "Galović Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1565",
        brojPu: "52440",
        redBroj: "1565",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Gambetići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1566",
        brojPu: "52460",
        redBroj: "1566",
        nazivPu: "Buje (Buie)",
        naselje: "Gamboci - Gambozzi",
        zupanija: "ISTARSKA",
      },
      {
        id: "1567",
        brojPu: "52440",
        redBroj: "1567",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Garbina",
        zupanija: "ISTARSKA",
      },
      {
        id: "1568",
        brojPu: "35212",
        redBroj: "1568",
        nazivPu: "Garčin",
        naselje: "Garčin",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1569",
        brojPu: "40000",
        redBroj: "1569",
        nazivPu: "Čakovec",
        naselje: "Gardinovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1570",
        brojPu: "21240",
        redBroj: "1570",
        nazivPu: "Trilj",
        naselje: "Gardun",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1571",
        brojPu: "43280",
        redBroj: "1571",
        nazivPu: "Garešnica",
        naselje: "Garešnica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1572",
        brojPu: "43280",
        redBroj: "1572",
        nazivPu: "Garešnica",
        naselje: "Garešnički Brestovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1573",
        brojPu: "51500",
        redBroj: "1573",
        nazivPu: "Krk",
        naselje: "Garica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1574",
        brojPu: "21236",
        redBroj: "1574",
        nazivPu: "Vrlika",
        naselje: "Garjak",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1575",
        brojPu: "31400",
        redBroj: "1575",
        nazivPu: "Đakovo",
        naselje: "Gašinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1576",
        brojPu: "51300",
        redBroj: "1576",
        nazivPu: "Delnice",
        naselje: "Gašparci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1577",
        brojPu: "31551",
        redBroj: "1577",
        nazivPu: "Belišće",
        naselje: "Gat",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1578",
        brojPu: "21292",
        redBroj: "1578",
        nazivPu: "Srinjine",
        naselje: "Gata",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1579",
        brojPu: "31511",
        redBroj: "1579",
        nazivPu: "Đurđenovac",
        naselje: "Gazije",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1580",
        brojPu: "21469",
        redBroj: "1580",
        nazivPu: "Sućuraj",
        naselje: "Gdinj",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1581",
        brojPu: "42240",
        redBroj: "1581",
        nazivPu: "Ivanec",
        naselje: "Gečkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1582",
        brojPu: "52440",
        redBroj: "1582",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Gedići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1583",
        brojPu: "47220",
        redBroj: "1583",
        nazivPu: "Vojnić",
        naselje: "Gejkovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1584",
        brojPu: "47250",
        redBroj: "1584",
        nazivPu: "Duga Resa",
        naselje: "Generalski Stol",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1585",
        brojPu: "51306",
        redBroj: "1585",
        nazivPu: "Čabar",
        naselje: "Gerovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1586",
        brojPu: "47300",
        redBroj: "1586",
        nazivPu: "Ogulin",
        naselje: "Gerovo Tounjsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1587",
        brojPu: "51306",
        redBroj: "1587",
        nazivPu: "Čabar",
        naselje: "Gerovski Kraj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1588",
        brojPu: "31540",
        redBroj: "1588",
        nazivPu: "Donji Miholjac",
        naselje: "Gezinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1589",
        brojPu: "35400",
        redBroj: "1589",
        nazivPu: "Nova Gradiška",
        naselje: "Giletinci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1590",
        brojPu: "21203",
        redBroj: "1590",
        nazivPu: "Donji Muć",
        naselje: "Gizdavac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1591",
        brojPu: "10413",
        redBroj: "1591",
        nazivPu: "Kravarsko",
        naselje: "Gladovec Kravarski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1592",
        brojPu: "10414",
        redBroj: "1592",
        nazivPu: "Pokupsko",
        naselje: "Gladovec Pokupski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1593",
        brojPu: "53220",
        redBroj: "1593",
        nazivPu: "Otočac",
        naselje: "Glavace",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1594",
        brojPu: "52207",
        redBroj: "1594",
        nazivPu: "Barban",
        naselje: "Glavani",
        zupanija: "ISTARSKA",
      },
      {
        id: "1595",
        brojPu: "20290",
        redBroj: "1595",
        nazivPu: "Lastovo",
        naselje: "Glavat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1596",
        brojPu: "23206",
        redBroj: "1596",
        nazivPu: "Sukošan",
        naselje: "Glavica",
        zupanija: "ZADARSKA",
      },
      {
        id: "1597",
        brojPu: "34550",
        redBroj: "1597",
        nazivPu: "Pakrac",
        naselje: "Glavica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1598",
        brojPu: "47250",
        redBroj: "1598",
        nazivPu: "Duga Resa",
        naselje: "Glavica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1599",
        brojPu: "21230",
        redBroj: "1599",
        nazivPu: "Sinj",
        naselje: "Glavice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1600",
        brojPu: "44440",
        redBroj: "1600",
        nazivPu: "Dvor",
        naselje: "Glavičani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1601",
        brojPu: "21260",
        redBroj: "1601",
        nazivPu: "Imotski",
        naselje: "Glavina Donja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1602",
        brojPu: "21260",
        redBroj: "1602",
        nazivPu: "Imotski",
        naselje: "Glavina Gornja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1603",
        brojPu: "10363",
        redBroj: "1603",
        nazivPu: "Belovar",
        naselje: "Glavnica Donja",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1604",
        brojPu: "10363",
        redBroj: "1604",
        nazivPu: "Belovar",
        naselje: "Glavnica Gornja",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1605",
        brojPu: "10361",
        redBroj: "1605",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Glavničica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1606",
        brojPu: "53260",
        redBroj: "1606",
        nazivPu: "Brinje",
        naselje: "Glibodol",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1607",
        brojPu: "44400",
        redBroj: "1607",
        nazivPu: "Glina",
        naselje: "Glina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1608",
        brojPu: "47240",
        redBroj: "1608",
        nazivPu: "Slunj",
        naselje: "Glinice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1609",
        brojPu: "44250",
        redBroj: "1609",
        nazivPu: "Petrinja",
        naselje: "Glinska Poljana",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1610",
        brojPu: "47240",
        redBroj: "1610",
        nazivPu: "Slunj",
        naselje: "Glinsko Vrelo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1611",
        brojPu: "49246",
        redBroj: "1611",
        nazivPu: "Marija Bistrica",
        naselje: "Globočec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1612",
        brojPu: "42230",
        redBroj: "1612",
        nazivPu: "Ludbreg",
        naselje: "Globočec Ludbreški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1613",
        brojPu: "48000",
        redBroj: "1613",
        nazivPu: "Koprivnica",
        naselje: "Glogovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1614",
        brojPu: "49290",
        redBroj: "1614",
        nazivPu: "Klanjec",
        naselje: "Glogovec Zagorski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1615",
        brojPu: "35000",
        redBroj: "1615",
        nazivPu: "Slavonski Brod",
        naselje: "Glogovica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1616",
        brojPu: "23440",
        redBroj: "1616",
        nazivPu: "Gračac",
        naselje: "Glogovo",
        zupanija: "ZADARSKA",
      },
      {
        id: "1617",
        brojPu: "20350",
        redBroj: "1617",
        nazivPu: "Metković",
        naselje: "Glušci",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1618",
        brojPu: "10454",
        redBroj: "1618",
        nazivPu: "Krašić",
        naselje: "Glušinja",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1619",
        brojPu: "21238",
        redBroj: "1619",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Gljev",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1620",
        brojPu: "47240",
        redBroj: "1620",
        nazivPu: "Slunj",
        naselje: "Gnojnice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1621",
        brojPu: "35420",
        redBroj: "1621",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Godinjak",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1622",
        brojPu: "42000",
        redBroj: "1622",
        nazivPu: "Varaždin",
        naselje: "Gojanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1623",
        brojPu: "47240",
        redBroj: "1623",
        nazivPu: "Slunj",
        naselje: "Gojkovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1624",
        brojPu: "44320",
        redBroj: "1624",
        nazivPu: "Kutina",
        naselje: "Gojlo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1625",
        brojPu: "48331",
        redBroj: "1625",
        nazivPu: "Gola",
        naselje: "Gola",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1626",
        brojPu: "52210",
        redBroj: "1626",
        nazivPu: "Rovinj (Rovigno)",
        naselje: "Golaš",
        zupanija: "ISTARSKA",
      },
      {
        id: "1627",
        brojPu: "33520",
        redBroj: "1627",
        nazivPu: "Slatina",
        naselje: "Golenić",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1628",
        brojPu: "47280",
        redBroj: "1628",
        nazivPu: "Ozalj",
        naselje: "Goleši Žumberački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1629",
        brojPu: "10257",
        redBroj: "1629",
        nazivPu: "Brezovica",
        naselje: "Goli Breg",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1630",
        brojPu: "10340",
        redBroj: "1630",
        nazivPu: "Vrbovec",
        naselje: "Goli Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1631",
        brojPu: "10450",
        redBroj: "1631",
        nazivPu: "Jastrebarsko",
        naselje: "Goli Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1632",
        brojPu: "47000",
        redBroj: "1632",
        nazivPu: "Karlovac",
        naselje: "Goli Vrh Netretićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1633",
        brojPu: "47280",
        redBroj: "1633",
        nazivPu: "Ozalj",
        naselje: "Goli Vrh Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1634",
        brojPu: "51300",
        redBroj: "1634",
        nazivPu: "Delnice",
        naselje: "Golik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1635",
        brojPu: "51326",
        redBroj: "1635",
        nazivPu: "Vrbovsko",
        naselje: "Goliki",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1636",
        brojPu: "31540",
        redBroj: "1636",
        nazivPu: "Donji Miholjac",
        naselje: "Golinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1637",
        brojPu: "44410",
        redBroj: "1637",
        nazivPu: "Vrginmost",
        naselje: "Golinja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1638",
        brojPu: "33000",
        redBroj: "1638",
        nazivPu: "Virovitica",
        naselje: "Golo Brdo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1639",
        brojPu: "34000",
        redBroj: "1639",
        nazivPu: "Požega",
        naselje: "Golo Brdo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1640",
        brojPu: "35400",
        redBroj: "1640",
        nazivPu: "Nova Gradiška",
        naselje: "Golobrdac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1641",
        brojPu: "34000",
        redBroj: "1641",
        nazivPu: "Požega",
        naselje: "Golobrdci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1642",
        brojPu: "52000",
        redBroj: "1642",
        nazivPu: "Pazin",
        naselje: "Gologorica",
        zupanija: "ISTARSKA",
      },
      {
        id: "1643",
        brojPu: "52000",
        redBroj: "1643",
        nazivPu: "Pazin",
        naselje: "Gologorički Dol",
        zupanija: "ISTARSKA",
      },
      {
        id: "1644",
        brojPu: "51417",
        redBroj: "1644",
        nazivPu: "Mošćenička Draga",
        naselje: "Golovik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1645",
        brojPu: "22300",
        redBroj: "1645",
        nazivPu: "Knin",
        naselje: "Golubić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1646",
        brojPu: "23450",
        redBroj: "1646",
        nazivPu: "Obrovac",
        naselje: "Golubić",
        zupanija: "ZADARSKA",
      },
      {
        id: "1647",
        brojPu: "10454",
        redBroj: "1647",
        nazivPu: "Krašić",
        naselje: "Golubići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1648",
        brojPu: "52424",
        redBroj: "1648",
        nazivPu: "Motovun (Montona)",
        naselje: "Golubići - Golobici",
        zupanija: "ISTARSKA",
      },
      {
        id: "1649",
        brojPu: "43500",
        redBroj: "1649",
        nazivPu: "Daruvar",
        naselje: "Golubinjak",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1650",
        brojPu: "44440",
        redBroj: "1650",
        nazivPu: "Dvor",
        naselje: "Golubovac Divuški",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1651",
        brojPu: "10450",
        redBroj: "1651",
        nazivPu: "Jastrebarsko",
        naselje: "Goljak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1652",
        brojPu: "49290",
        redBroj: "1652",
        nazivPu: "Klanjec",
        naselje: "Goljak Klanječki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1653",
        brojPu: "51326",
        redBroj: "1653",
        nazivPu: "Vrbovsko",
        naselje: "Gomirje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1654",
        brojPu: "52220",
        redBroj: "1654",
        nazivPu: "Labin",
        naselje: "Gondolići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1655",
        brojPu: "10450",
        redBroj: "1655",
        nazivPu: "Jastrebarsko",
        naselje: "Gonjeva",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1656",
        brojPu: "44250",
        redBroj: "1656",
        nazivPu: "Petrinja",
        naselje: "Gora",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1657",
        brojPu: "52220",
        redBroj: "1657",
        nazivPu: "Labin",
        naselje: "Gora Glušići",
        zupanija: "ISTARSKA",
      },
      {
        id: "1658",
        brojPu: "49216",
        redBroj: "1658",
        nazivPu: "Desinić",
        naselje: "Gora Košnička",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1659",
        brojPu: "49252",
        redBroj: "1659",
        nazivPu: "Mihovljan",
        naselje: "Gora Veternička",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1660",
        brojPu: "51306",
        redBroj: "1660",
        nazivPu: "Čabar",
        naselje: "Gorači",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1661",
        brojPu: "10360",
        redBroj: "1661",
        nazivPu: "Sesvete",
        naselje: "Goranec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1662",
        brojPu: "42240",
        redBroj: "1662",
        nazivPu: "Ivanec",
        naselje: "Goranec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1663",
        brojPu: "51300",
        redBroj: "1663",
        nazivPu: "Delnice",
        naselje: "Gorani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1664",
        brojPu: "51326",
        redBroj: "1664",
        nazivPu: "Vrbovsko",
        naselje: "Gorenci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1665",
        brojPu: "23206",
        redBroj: "1665",
        nazivPu: "Sukošan",
        naselje: "Gorica",
        zupanija: "ZADARSKA",
      },
      {
        id: "1666",
        brojPu: "23250",
        redBroj: "1666",
        nazivPu: "Pag",
        naselje: "Gorica",
        zupanija: "ZADARSKA",
      },
      {
        id: "1667",
        brojPu: "47250",
        redBroj: "1667",
        nazivPu: "Duga Resa",
        naselje: "Gorica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1668",
        brojPu: "48000",
        redBroj: "1668",
        nazivPu: "Koprivnica",
        naselje: "Gorica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1669",
        brojPu: "10451",
        redBroj: "1669",
        nazivPu: "Pisarovina",
        naselje: "Gorica Jamnička",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1670",
        brojPu: "47000",
        redBroj: "1670",
        nazivPu: "Karlovac",
        naselje: "Gorica Lipnička",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1671",
        brojPu: "48260",
        redBroj: "1671",
        nazivPu: "Križevci",
        naselje: "Gorica Miholečka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1672",
        brojPu: "51300",
        redBroj: "1672",
        nazivPu: "Delnice",
        naselje: "Gorica Skradska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1673",
        brojPu: "10450",
        redBroj: "1673",
        nazivPu: "Jastrebarsko",
        naselje: "Gorica Svetojanska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1674",
        brojPu: "31550",
        redBroj: "1674",
        nazivPu: "Valpovo",
        naselje: "Gorica Valpovačka",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1675",
        brojPu: "22222",
        redBroj: "1675",
        nazivPu: "Skradin",
        naselje: "Gorice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1676",
        brojPu: "35400",
        redBroj: "1676",
        nazivPu: "Nova Gradiška",
        naselje: "Gorice",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1677",
        brojPu: "40320",
        redBroj: "1677",
        nazivPu: "Donji Kraljevec",
        naselje: "Goričan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1678",
        brojPu: "10380",
        redBroj: "1678",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Goričanec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1679",
        brojPu: "49225",
        redBroj: "1679",
        nazivPu: "Đurmanec",
        naselje: "Goričanovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1680",
        brojPu: "10380",
        redBroj: "1680",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Goričica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1681",
        brojPu: "47250",
        redBroj: "1681",
        nazivPu: "Duga Resa",
        naselje: "Goričice Dobranske",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1682",
        brojPu: "44440",
        redBroj: "1682",
        nazivPu: "Dvor",
        naselje: "Gorička",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1683",
        brojPu: "48000",
        redBroj: "1683",
        nazivPu: "Koprivnica",
        naselje: "Goričko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1684",
        brojPu: "53220",
        redBroj: "1684",
        nazivPu: "Otočac",
        naselje: "Gorići",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1685",
        brojPu: "47250",
        redBroj: "1685",
        nazivPu: "Duga Resa",
        naselje: "Gorinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1686",
        brojPu: "22222",
        redBroj: "1686",
        nazivPu: "Skradin",
        naselje: "Goriš",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1687",
        brojPu: "49218",
        redBroj: "1687",
        nazivPu: "Pregrada",
        naselje: "Gorjakovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1688",
        brojPu: "31400",
        redBroj: "1688",
        nazivPu: "Đakovo",
        naselje: "Gorjani",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1689",
        brojPu: "49000",
        redBroj: "1689",
        nazivPu: "Krapina",
        naselje: "Gorjani Sutinski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1690",
        brojPu: "49290",
        redBroj: "1690",
        nazivPu: "Klanjec",
        naselje: "Gorkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1691",
        brojPu: "47280",
        redBroj: "1691",
        nazivPu: "Ozalj",
        naselje: "Gorniki Vivodinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1692",
        brojPu: "44250",
        redBroj: "1692",
        nazivPu: "Petrinja",
        naselje: "Gornja Bačuga",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1693",
        brojPu: "49250",
        redBroj: "1693",
        nazivPu: "Zlatar",
        naselje: "Gornja Batina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1694",
        brojPu: "35000",
        redBroj: "1694",
        nazivPu: "Slavonski Brod",
        naselje: "Gornja Bebrina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1695",
        brojPu: "10298",
        redBroj: "1695",
        nazivPu: "Bistra",
        naselje: "Gornja Bistra",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1696",
        brojPu: "48260",
        redBroj: "1696",
        nazivPu: "Križevci",
        naselje: "Gornja Brckovčina",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1697",
        brojPu: "21250",
        redBroj: "1697",
        nazivPu: "Šestanovac",
        naselje: "Gornja Brela",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1698",
        brojPu: "47220",
        redBroj: "1698",
        nazivPu: "Vojnić",
        naselje: "Gornja Brusovača",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1699",
        brojPu: "44400",
        redBroj: "1699",
        nazivPu: "Glina",
        naselje: "Gornja Bučica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1700",
        brojPu: "44410",
        redBroj: "1700",
        nazivPu: "Vrginmost",
        naselje: "Gornja Čemernica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1701",
        brojPu: "51300",
        redBroj: "1701",
        nazivPu: "Delnice",
        naselje: "Gornja Dobra",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1702",
        brojPu: "10380",
        redBroj: "1702",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Gornja Drenova",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1703",
        brojPu: "40000",
        redBroj: "1703",
        nazivPu: "Čakovec",
        naselje: "Gornja Dubrava",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1704",
        brojPu: "43280",
        redBroj: "1704",
        nazivPu: "Garešnica",
        naselje: "Gornja Garešnica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1705",
        brojPu: "47240",
        redBroj: "1705",
        nazivPu: "Slunj",
        naselje: "Gornja Glina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1706",
        brojPu: "48260",
        redBroj: "1706",
        nazivPu: "Križevci",
        naselje: "Gornja Glogovnica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1707",
        brojPu: "44317",
        redBroj: "1707",
        nazivPu: "Popovača",
        naselje: "Gornja Gračenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1708",
        brojPu: "10370",
        redBroj: "1708",
        nazivPu: "Dugo Selo",
        naselje: "Gornja Greda",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1709",
        brojPu: "23210",
        redBroj: "1709",
        nazivPu: "Biograd na moru",
        naselje: "Gornja Jagodnja",
        zupanija: "ZADARSKA",
      },
      {
        id: "1710",
        brojPu: "44317",
        redBroj: "1710",
        nazivPu: "Popovača",
        naselje: "Gornja Jelenska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1711",
        brojPu: "49282",
        redBroj: "1711",
        nazivPu: "Konjščina",
        naselje: "Gornja Konjščina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1712",
        brojPu: "43270",
        redBroj: "1712",
        nazivPu: "Veliki Grđevac",
        naselje: "Gornja Kovačica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1713",
        brojPu: "51300",
        redBroj: "1713",
        nazivPu: "Delnice",
        naselje: "Gornja Krašićevica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1714",
        brojPu: "10454",
        redBroj: "1714",
        nazivPu: "Krašić",
        naselje: "Gornja Kupčina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1715",
        brojPu: "51326",
        redBroj: "1715",
        nazivPu: "Vrbovsko",
        naselje: "Gornja Lamana Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1716",
        brojPu: "44210",
        redBroj: "1716",
        nazivPu: "Sunja",
        naselje: "Gornja Letina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1717",
        brojPu: "10410",
        redBroj: "1717",
        nazivPu: "Velika Gorica",
        naselje: "Gornja Lomnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1718",
        brojPu: "44430",
        redBroj: "1718",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Gornja Meminska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1719",
        brojPu: "44250",
        redBroj: "1719",
        nazivPu: "Petrinja",
        naselje: "Gornja Mlinoga",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1720",
        brojPu: "47240",
        redBroj: "1720",
        nazivPu: "Slunj",
        naselje: "Gornja Močila",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1721",
        brojPu: "31500",
        redBroj: "1721",
        nazivPu: "Našice",
        naselje: "Gornja Motičina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1722",
        brojPu: "52420",
        redBroj: "1722",
        nazivPu: "Buzet",
        naselje: "Gornja Nugla",
        zupanija: "ISTARSKA",
      },
      {
        id: "1723",
        brojPu: "10310",
        redBroj: "1723",
        nazivPu: "Ivanić Grad",
        naselje: "Gornja Obreška",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1724",
        brojPu: "34550",
        redBroj: "1724",
        nazivPu: "Pakrac",
        naselje: "Gornja Obrijež",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1725",
        brojPu: "44440",
        redBroj: "1725",
        nazivPu: "Dvor",
        naselje: "Gornja Oraovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1726",
        brojPu: "49000",
        redBroj: "1726",
        nazivPu: "Krapina",
        naselje: "Gornja Pačetina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1727",
        brojPu: "44250",
        redBroj: "1727",
        nazivPu: "Petrinja",
        naselje: "Gornja Pastuša",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1728",
        brojPu: "43000",
        redBroj: "1728",
        nazivPu: "Bjelovar",
        naselje: "Gornja Petrička",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1729",
        brojPu: "33515",
        redBroj: "1729",
        nazivPu: "Orahovica",
        naselje: "Gornja Pištana",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1730",
        brojPu: "49218",
        redBroj: "1730",
        nazivPu: "Pregrada",
        naselje: "Gornja Plemenšćina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1731",
        brojPu: "53234",
        redBroj: "1731",
        nazivPu: "Udbina",
        naselje: "Gornja Ploča",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1732",
        brojPu: "43280",
        redBroj: "1732",
        nazivPu: "Garešnica",
        naselje: "Gornja Ploščica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1733",
        brojPu: "49240",
        redBroj: "1733",
        nazivPu: "Donja Stubica",
        naselje: "Gornja Podgora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1734",
        brojPu: "42223",
        redBroj: "1734",
        nazivPu: "Varaždinske Toplice",
        naselje: "Gornja Poljana",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1735",
        brojPu: "10450",
        redBroj: "1735",
        nazivPu: "Jastrebarsko",
        naselje: "Gornja Purgarija",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1736",
        brojPu: "10290",
        redBroj: "1736",
        nazivPu: "Zaprešić",
        naselje: "Gornja Pušća",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1737",
        brojPu: "43290",
        redBroj: "1737",
        nazivPu: "Grubišno Polje",
        naselje: "Gornja Rašenica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1738",
        brojPu: "10450",
        redBroj: "1738",
        nazivPu: "Jastrebarsko",
        naselje: "Gornja Reka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1739",
        brojPu: "48260",
        redBroj: "1739",
        nazivPu: "Križevci",
        naselje: "Gornja Rijeka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1740",
        brojPu: "49250",
        redBroj: "1740",
        nazivPu: "Zlatar",
        naselje: "Gornja Selnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1741",
        brojPu: "47000",
        redBroj: "1741",
        nazivPu: "Karlovac",
        naselje: "Gornja Stranica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1742",
        brojPu: "49240",
        redBroj: "1742",
        nazivPu: "Donja Stubica",
        naselje: "Gornja Stubica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1743",
        brojPu: "44440",
        redBroj: "1743",
        nazivPu: "Dvor",
        naselje: "Gornja Stupnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1744",
        brojPu: "53250",
        redBroj: "1744",
        nazivPu: "Donji Lapac",
        naselje: "Gornja Suvaja",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1745",
        brojPu: "49000",
        redBroj: "1745",
        nazivPu: "Krapina",
        naselje: "Gornja Šemnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1746",
        brojPu: "34550",
        redBroj: "1746",
        nazivPu: "Pakrac",
        naselje: "Gornja Šumetlica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1747",
        brojPu: "43240",
        redBroj: "1747",
        nazivPu: "Čazma",
        naselje: "Gornja Šušnjara",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1748",
        brojPu: "10380",
        redBroj: "1748",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Gornja Topličica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1749",
        brojPu: "47000",
        redBroj: "1749",
        nazivPu: "Karlovac",
        naselje: "Gornja Trebinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1750",
        brojPu: "43280",
        redBroj: "1750",
        nazivPu: "Garešnica",
        naselje: "Gornja Trnovitica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1751",
        brojPu: "44410",
        redBroj: "1751",
        nazivPu: "Vrginmost",
        naselje: "Gornja Trstenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1752",
        brojPu: "10454",
        redBroj: "1752",
        nazivPu: "Krašić",
        naselje: "Gornja Vas",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1753",
        brojPu: "44430",
        redBroj: "1753",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Gornja Velešnja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1754",
        brojPu: "10340",
        redBroj: "1754",
        nazivPu: "Vrbovec",
        naselje: "Gornja Velika",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1755",
        brojPu: "48000",
        redBroj: "1755",
        nazivPu: "Koprivnica",
        naselje: "Gornja Velika",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1756",
        brojPu: "47240",
        redBroj: "1756",
        nazivPu: "Slunj",
        naselje: "Gornja Visočka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1757",
        brojPu: "42250",
        redBroj: "1757",
        nazivPu: "Lepoglava",
        naselje: "Gornja Višnjica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1758",
        brojPu: "44317",
        redBroj: "1758",
        nazivPu: "Popovača",
        naselje: "Gornja Vlahinićka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1759",
        brojPu: "42240",
        redBroj: "1759",
        nazivPu: "Ivanec",
        naselje: "Gornja Voća",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1760",
        brojPu: "35000",
        redBroj: "1760",
        nazivPu: "Slavonski Brod",
        naselje: "Gornja Vrba",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1761",
        brojPu: "43500",
        redBroj: "1761",
        nazivPu: "Daruvar",
        naselje: "Gornja Vrijeska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1762",
        brojPu: "20240",
        redBroj: "1762",
        nazivPu: "Trpanj",
        naselje: "Gornja Vrućica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1763",
        brojPu: "10450",
        redBroj: "1763",
        nazivPu: "Jastrebarsko",
        naselje: "Gornja Zdenčina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1764",
        brojPu: "47240",
        redBroj: "1764",
        nazivPu: "Slunj",
        naselje: "Gornja Žrvnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1765",
        brojPu: "10340",
        redBroj: "1765",
        nazivPu: "Vrbovec",
        naselje: "Gornjaki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1766",
        brojPu: "49284",
        redBroj: "1766",
        nazivPu: "Budinščina",
        naselje: "Gornjaki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1767",
        brojPu: "33000",
        redBroj: "1767",
        nazivPu: "Virovitica",
        naselje: "Gornje Bazje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1768",
        brojPu: "23420",
        redBroj: "1768",
        nazivPu: "Benkovac",
        naselje: "Gornje Biljane",
        zupanija: "ZADARSKA",
      },
      {
        id: "1769",
        brojPu: "49231",
        redBroj: "1769",
        nazivPu: "Hum na Sutli",
        naselje: "Gornje Brezno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1770",
        brojPu: "47250",
        redBroj: "1770",
        nazivPu: "Duga Resa",
        naselje: "Gornje Bukovlje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1771",
        brojPu: "23420",
        redBroj: "1771",
        nazivPu: "Benkovac",
        naselje: "Gornje Ceranje",
        zupanija: "ZADARSKA",
      },
      {
        id: "1772",
        brojPu: "43500",
        redBroj: "1772",
        nazivPu: "Daruvar",
        naselje: "Gornje Cjepidlake",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1773",
        brojPu: "47300",
        redBroj: "1773",
        nazivPu: "Ogulin",
        naselje: "Gornje Dubrave",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1774",
        brojPu: "10370",
        redBroj: "1774",
        nazivPu: "Dugo Selo",
        naselje: "Gornje Dvorišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1775",
        brojPu: "47240",
        redBroj: "1775",
        nazivPu: "Slunj",
        naselje: "Gornje Gnojnice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1776",
        brojPu: "21327",
        redBroj: "1776",
        nazivPu: "Podgora",
        naselje: "Gornje Igrane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1777",
        brojPu: "44400",
        redBroj: "1777",
        nazivPu: "Glina",
        naselje: "Gornje Jame",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1778",
        brojPu: "49225",
        redBroj: "1778",
        nazivPu: "Đurmanec",
        naselje: "Gornje Jesenje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1779",
        brojPu: "44010",
        redBroj: "1779",
        nazivPu: "Sisak-Caprag",
        naselje: "Gornje Komarevo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1780",
        brojPu: "33520",
        redBroj: "1780",
        nazivPu: "Slatina",
        naselje: "Gornje Kusonje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1781",
        brojPu: "42243",
        redBroj: "1781",
        nazivPu: "Maruševec",
        naselje: "Gornje Ladanje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1782",
        brojPu: "42220",
        redBroj: "1782",
        nazivPu: "Novi Marof",
        naselje: "Gornje Makojišće",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1783",
        brojPu: "44250",
        redBroj: "1783",
        nazivPu: "Petrinja",
        naselje: "Gornje Mokrice",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1784",
        brojPu: "47250",
        redBroj: "1784",
        nazivPu: "Duga Resa",
        naselje: "Gornje Mrzlo Polje Mrežničko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1785",
        brojPu: "20236",
        redBroj: "1785",
        nazivPu: "Mokošica",
        naselje: "Gornje Obuljeno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1786",
        brojPu: "21203",
        redBroj: "1786",
        nazivPu: "Donji Muć",
        naselje: "Gornje Ogorje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1787",
        brojPu: "10380",
        redBroj: "1787",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Gornje Orešje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1788",
        brojPu: "22323",
        redBroj: "1788",
        nazivPu: "Unešić",
        naselje: "Gornje Planjane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1789",
        brojPu: "43000",
        redBroj: "1789",
        nazivPu: "Bjelovar",
        naselje: "Gornje Plavnice",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1790",
        brojPu: "10410",
        redBroj: "1790",
        nazivPu: "Velika Gorica",
        naselje: "Gornje Podotočje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1791",
        brojPu: "47000",
        redBroj: "1791",
        nazivPu: "Karlovac",
        naselje: "Gornje Pokupje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1792",
        brojPu: "21203",
        redBroj: "1792",
        nazivPu: "Donji Muć",
        naselje: "Gornje Postinje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1793",
        brojPu: "33520",
        redBroj: "1793",
        nazivPu: "Slatina",
        naselje: "Gornje Predrijevo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1794",
        brojPu: "10454",
        redBroj: "1794",
        nazivPu: "Krašić",
        naselje: "Gornje Prekrižje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1795",
        brojPu: "47000",
        redBroj: "1795",
        nazivPu: "Karlovac",
        naselje: "Gornje Prilišće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1796",
        brojPu: "47240",
        redBroj: "1796",
        nazivPu: "Slunj",
        naselje: "Gornje Primišlje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1797",
        brojPu: "10380",
        redBroj: "1797",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Gornje Psarjevo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1798",
        brojPu: "23210",
        redBroj: "1798",
        nazivPu: "Biograd na Moru",
        naselje: "Gornje Raštane",
        zupanija: "ZADARSKA",
      },
      {
        id: "1799",
        brojPu: "43000",
        redBroj: "1799",
        nazivPu: "Bjelovar",
        naselje: "Gornje Rovišće",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1800",
        brojPu: "44400",
        redBroj: "1800",
        nazivPu: "Glina",
        naselje: "Gornje Selište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1801",
        brojPu: "21430",
        redBroj: "1801",
        nazivPu: "Grohote",
        naselje: "Gornje Selo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1802",
        brojPu: "21311",
        redBroj: "1802",
        nazivPu: "Stobreč",
        naselje: "Gornje Sitno",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1803",
        brojPu: "47000",
        redBroj: "1803",
        nazivPu: "Karlovac",
        naselje: "Gornje Stative",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1804",
        brojPu: "44400",
        redBroj: "1804",
        nazivPu: "Glina",
        naselje: "Gornje Taborište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1805",
        brojPu: "47240",
        redBroj: "1805",
        nazivPu: "Slunj",
        naselje: "Gornje Taborište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1806",
        brojPu: "51300",
        redBroj: "1806",
        nazivPu: "Delnice",
        naselje: "Gornje Tihovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1807",
        brojPu: "22323",
        redBroj: "1807",
        nazivPu: "Unešić",
        naselje: "Gornje Utore",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1808",
        brojPu: "33520",
        redBroj: "1808",
        nazivPu: "Slatina",
        naselje: "Gornje Viljevo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1809",
        brojPu: "22323",
        redBroj: "1809",
        nazivPu: "Unešić",
        naselje: "Gornje Vinovo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1810",
        brojPu: "42208",
        redBroj: "1810",
        nazivPu: "Cestica",
        naselje: "Gornje Vratno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1811",
        brojPu: "53220",
        redBroj: "1811",
        nazivPu: "Otočac",
        naselje: "Gornje Vrhovine",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1812",
        brojPu: "47300",
        redBroj: "1812",
        nazivPu: "Ogulin",
        naselje: "Gornje Zagorje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1813",
        brojPu: "43000",
        redBroj: "1813",
        nazivPu: "Bjelovar",
        naselje: "Gornje Zdelice",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1814",
        brojPu: "35252",
        redBroj: "1814",
        nazivPu: "Sibinj",
        naselje: "Gornji Andrijevci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1815",
        brojPu: "53220",
        redBroj: "1815",
        nazivPu: "Otočac",
        naselje: "Gornji Babin Potok",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1816",
        brojPu: "21233",
        redBroj: "1816",
        nazivPu: "Hrvace",
        naselje: "Gornji Bitelić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1817",
        brojPu: "44430",
        redBroj: "1817",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Gornji Bjelovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1818",
        brojPu: "35430",
        redBroj: "1818",
        nazivPu: "Okučani",
        naselje: "Gornji Bogićevci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1819",
        brojPu: "43500",
        redBroj: "1819",
        nazivPu: "Daruvar",
        naselje: "Gornji Borki",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1820",
        brojPu: "20207",
        redBroj: "1820",
        nazivPu: "Mlini",
        naselje: "Gornji Brgat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1821",
        brojPu: "47220",
        redBroj: "1821",
        nazivPu: "Vojnić",
        naselje: "Gornji Budački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1822",
        brojPu: "47000",
        redBroj: "1822",
        nazivPu: "Karlovac",
        naselje: "Gornji Bukovac Žakanjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1823",
        brojPu: "47240",
        redBroj: "1823",
        nazivPu: "Slunj",
        naselje: "Gornji Cerovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1824",
        brojPu: "44430",
        redBroj: "1824",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Gornji Cerovljani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1825",
        brojPu: "35420",
        redBroj: "1825",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Gornji Crnogovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1826",
        brojPu: "34551",
        redBroj: "1826",
        nazivPu: "Lipik",
        naselje: "Gornji Čaglić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1827",
        brojPu: "10020",
        redBroj: "1827",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Gornji Čehi",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1828",
        brojPu: "49290",
        redBroj: "1828",
        nazivPu: "Klanjec",
        naselje: "Gornji Čemehovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1829",
        brojPu: "43500",
        redBroj: "1829",
        nazivPu: "Daruvar",
        naselje: "Gornji Daruvar",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1830",
        brojPu: "10450",
        redBroj: "1830",
        nazivPu: "Jastrebarsko",
        naselje: "Gornji Desinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1831",
        brojPu: "44440",
        redBroj: "1831",
        nazivPu: "Dvor",
        naselje: "Gornji Dobretin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1832",
        brojPu: "21204",
        redBroj: "1832",
        nazivPu: "Dugopolje",
        naselje: "Gornji Dolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1833",
        brojPu: "43240",
        redBroj: "1833",
        nazivPu: "Čazma",
        naselje: "Gornji Draganec",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1834",
        brojPu: "43240",
        redBroj: "1834",
        nazivPu: "Čazma",
        naselje: "Gornji Dragičevci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1835",
        brojPu: "10257",
        redBroj: "1835",
        nazivPu: "Brezovica",
        naselje: "Gornji Dragonožec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1836",
        brojPu: "48260",
        redBroj: "1836",
        nazivPu: "Križevci",
        naselje: "Gornji Dubovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1837",
        brojPu: "34000",
        redBroj: "1837",
        nazivPu: "Požega",
        naselje: "Gornji Emovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1838",
        brojPu: "48260",
        redBroj: "1838",
        nazivPu: "Križevci",
        naselje: "Gornji Fodrovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1839",
        brojPu: "47240",
        redBroj: "1839",
        nazivPu: "Slunj",
        naselje: "Gornji Furjan",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1840",
        brojPu: "47000",
        redBroj: "1840",
        nazivPu: "Karlovac",
        naselje: "Gornji Goli Vrh Lipnički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1841",
        brojPu: "34550",
        redBroj: "1841",
        nazivPu: "Pakrac",
        naselje: "Gornji Grahovljani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1842",
        brojPu: "34000",
        redBroj: "1842",
        nazivPu: "Požega",
        naselje: "Gornji Gučani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1843",
        brojPu: "44430",
        redBroj: "1843",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Gornji Hrastovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1844",
        brojPu: "40000",
        redBroj: "1844",
        nazivPu: "Čakovec",
        naselje: "Gornji Hrašćan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1845",
        brojPu: "10413",
        redBroj: "1845",
        nazivPu: "Kravarsko",
        naselje: "Gornji Hruševec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1846",
        brojPu: "21412",
        redBroj: "1846",
        nazivPu: "Pučišća",
        naselje: "Gornji Humac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1847",
        brojPu: "49216",
        redBroj: "1847",
        nazivPu: "Desinić",
        naselje: "Gornji Jalšovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1848",
        brojPu: "44440",
        redBroj: "1848",
        nazivPu: "Dvor",
        naselje: "Gornji Javoranj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1849",
        brojPu: "23450",
        redBroj: "1849",
        nazivPu: "Obrovac",
        naselje: "Gornji Karin",
        zupanija: "ZADARSKA",
      },
      {
        id: "1850",
        brojPu: "44400",
        redBroj: "1850",
        nazivPu: "Glina",
        naselje: "Gornji Klasnić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1851",
        brojPu: "42204",
        redBroj: "1851",
        nazivPu: "Turčin",
        naselje: "Gornji Kneginec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1852",
        brojPu: "40313",
        redBroj: "1852",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Gornji Koncovčak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1853",
        brojPu: "53202",
        redBroj: "1853",
        nazivPu: "Perušić",
        naselje: "Gornji Kosinj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1854",
        brojPu: "40315",
        redBroj: "1854",
        nazivPu: "Mursko Središče",
        naselje: "Gornji Kraljevec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1855",
        brojPu: "49284",
        redBroj: "1855",
        nazivPu: "Budinščina",
        naselje: "Gornji Kraljevec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1856",
        brojPu: "47240",
        redBroj: "1856",
        nazivPu: "Slunj",
        naselje: "Gornji Kremen",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1857",
        brojPu: "42000",
        redBroj: "1857",
        nazivPu: "Varaždin",
        naselje: "Gornji Kućan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1858",
        brojPu: "44430",
        redBroj: "1858",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Gornji Kukuruzari",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1859",
        brojPu: "40000",
        redBroj: "1859",
        nazivPu: "Čakovec",
        naselje: "Gornji Kuršanec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1860",
        brojPu: "51326",
        redBroj: "1860",
        nazivPu: "Vrbovsko",
        naselje: "Gornji Kuti",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1861",
        brojPu: "10292",
        redBroj: "1861",
        nazivPu: "Šenkovec",
        naselje: "Gornji Laduč",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1862",
        brojPu: "47240",
        redBroj: "1862",
        nazivPu: "Slunj",
        naselje: "Gornji Lađevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1863",
        brojPu: "53250",
        redBroj: "1863",
        nazivPu: "Donji Lapac",
        naselje: "Gornji Lapac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1864",
        brojPu: "35410",
        redBroj: "1864",
        nazivPu: "Nova Kapela",
        naselje: "Gornji Lipovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1865",
        brojPu: "43240",
        redBroj: "1865",
        nazivPu: "Čazma",
        naselje: "Gornji Lipovčani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1866",
        brojPu: "47280",
        redBroj: "1866",
        nazivPu: "Ozalj",
        naselje: "Gornji Lović",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1867",
        brojPu: "51300",
        redBroj: "1867",
        nazivPu: "Delnice",
        naselje: "Gornji Ložac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1868",
        brojPu: "49225",
        redBroj: "1868",
        nazivPu: "Đurmanec",
        naselje: "Gornji Macelj",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1869",
        brojPu: "10342",
        redBroj: "1869",
        nazivPu: "Dubrava",
        naselje: "Gornji Marinkovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1870",
        brojPu: "42230",
        redBroj: "1870",
        nazivPu: "Ludbreg",
        naselje: "Gornji Martijanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1871",
        brojPu: "48000",
        redBroj: "1871",
        nazivPu: "Koprivnica",
        naselje: "Gornji Maslarac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1872",
        brojPu: "33520",
        redBroj: "1872",
        nazivPu: "Slatina",
        naselje: "Gornji Meljani",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1873",
        brojPu: "40000",
        redBroj: "1873",
        nazivPu: "Čakovec",
        naselje: "Gornji Mihaljevec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1874",
        brojPu: "33520",
        redBroj: "1874",
        nazivPu: "Slatina",
        naselje: "Gornji Miholjac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1875",
        brojPu: "43240",
        redBroj: "1875",
        nazivPu: "Čazma",
        naselje: "Gornji Miklouš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1876",
        brojPu: "43000",
        redBroj: "1876",
        nazivPu: "Bjelovar",
        naselje: "Gornji Mosti",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1877",
        brojPu: "21203",
        redBroj: "1877",
        nazivPu: "Donji Muć",
        naselje: "Gornji Muć",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1878",
        brojPu: "47240",
        redBroj: "1878",
        nazivPu: "Slunj",
        naselje: "Gornji Nikšić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1879",
        brojPu: "51300",
        redBroj: "1879",
        nazivPu: "Delnice",
        naselje: "Gornji Okrug",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1880",
        brojPu: "10454",
        redBroj: "1880",
        nazivPu: "Krašić",
        naselje: "Gornji Oštrc",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1881",
        brojPu: "47280",
        redBroj: "1881",
        nazivPu: "Ozalj",
        naselje: "Gornji Oštri Vrh Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1882",
        brojPu: "23241",
        redBroj: "1882",
        nazivPu: "Poličnik",
        naselje: "Gornji Poličnik",
        zupanija: "ZADARSKA",
      },
      {
        id: "1883",
        brojPu: "47250",
        redBroj: "1883",
        nazivPu: "Duga Resa",
        naselje: "Gornji Poloj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1884",
        brojPu: "47240",
        redBroj: "1884",
        nazivPu: "Slunj",
        naselje: "Gornji Popovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1885",
        brojPu: "10315",
        redBroj: "1885",
        nazivPu: "Novoselec",
        naselje: "Gornji Prnjarovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1886",
        brojPu: "21260",
        redBroj: "1886",
        nazivPu: "Imotski",
        naselje: "Gornji Proložac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1887",
        brojPu: "35430",
        redBroj: "1887",
        nazivPu: "Okučani",
        naselje: "Gornji Rogolji",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1888",
        brojPu: "44400",
        redBroj: "1888",
        nazivPu: "Glina",
        naselje: "Gornji Selkovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1889",
        brojPu: "47000",
        redBroj: "1889",
        nazivPu: "Karlovac",
        naselje: "Gornji Sjeničak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1890",
        brojPu: "47220",
        redBroj: "1890",
        nazivPu: "Vojnić",
        naselje: "Gornji Skrad",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1891",
        brojPu: "35000",
        redBroj: "1891",
        nazivPu: "Slavonski Brod",
        naselje: "Gornji Slatinik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1892",
        brojPu: "43500",
        redBroj: "1892",
        nazivPu: "Daruvar",
        naselje: "Gornji Sređani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1893",
        brojPu: "10255",
        redBroj: "1893",
        nazivPu: "Gornji Stupnik",
        naselje: "Gornji Stupnik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1894",
        brojPu: "51326",
        redBroj: "1894",
        nazivPu: "Vrbovsko",
        naselje: "Gornji Šajn",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1895",
        brojPu: "51326",
        redBroj: "1895",
        nazivPu: "Vrbovsko",
        naselje: "Gornji Šehovac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1896",
        brojPu: "49290",
        redBroj: "1896",
        nazivPu: "Klanjec",
        naselje: "Gornji Škrnik",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1897",
        brojPu: "53250",
        redBroj: "1897",
        nazivPu: "Donji Lapac",
        naselje: "Gornji Štrbci",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1898",
        brojPu: "10340",
        redBroj: "1898",
        nazivPu: "Vrbovec",
        naselje: "Gornji Tkalec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1899",
        brojPu: "43000",
        redBroj: "1899",
        nazivPu: "Bjelovar",
        naselje: "Gornji Tomaš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1900",
        brojPu: "10257",
        redBroj: "1900",
        nazivPu: "Brezovica",
        naselje: "Gornji Trpuci",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "1901",
        brojPu: "51300",
        redBroj: "1901",
        nazivPu: "Delnice",
        naselje: "Gornji Turni",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1902",
        brojPu: "43280",
        redBroj: "1902",
        nazivPu: "Garešnica",
        naselje: "Gornji Uljanik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1903",
        brojPu: "53230",
        redBroj: "1903",
        nazivPu: "Korenica",
        naselje: "Gornji Vaganac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1904",
        brojPu: "35430",
        redBroj: "1904",
        nazivPu: "Okučani",
        naselje: "Gornji Varoš",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1905",
        brojPu: "47250",
        redBroj: "1905",
        nazivPu: "Duga Resa",
        naselje: "Gornji Velemerić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1906",
        brojPu: "44400",
        redBroj: "1906",
        nazivPu: "Glina",
        naselje: "Gornji Viduševac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1907",
        brojPu: "10380",
        redBroj: "1907",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Gornji Vinkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1908",
        brojPu: "21260",
        redBroj: "1908",
        nazivPu: "Imotski",
        naselje: "Gornji Vinjani",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1909",
        brojPu: "34000",
        redBroj: "1909",
        nazivPu: "Požega",
        naselje: "Gornji Vrhovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1910",
        brojPu: "51326",
        redBroj: "1910",
        nazivPu: "Vrbovsko",
        naselje: "Gornji Vučkovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1911",
        brojPu: "44250",
        redBroj: "1911",
        nazivPu: "Petrinja",
        naselje: "Gornji Vukojevac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1912",
        brojPu: "51326",
        redBroj: "1912",
        nazivPu: "Vrbovsko",
        naselje: "Gornji Vukšići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1913",
        brojPu: "10342",
        redBroj: "1913",
        nazivPu: "Dubrava",
        naselje: "Gornji Vukšinac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1914",
        brojPu: "51250",
        redBroj: "1914",
        nazivPu: "Novi Vinodolski",
        naselje: "Gornji Zagon",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1915",
        brojPu: "49216",
        redBroj: "1915",
        nazivPu: "Desinić",
        naselje: "Gornji Zbilj",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1916",
        brojPu: "40313",
        redBroj: "1916",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Gornji Zebanec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1917",
        brojPu: "47250",
        redBroj: "1917",
        nazivPu: "Duga Resa",
        naselje: "Gornji Zvečaj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1918",
        brojPu: "51306",
        redBroj: "1918",
        nazivPu: "Čabar",
        naselje: "Gornji Žagari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1919",
        brojPu: "44440",
        redBroj: "1919",
        nazivPu: "Dvor",
        naselje: "Gornji Žirovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1920",
        brojPu: "47000",
        redBroj: "1920",
        nazivPu: "Karlovac",
        naselje: "Goršćaki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1921",
        brojPu: "47280",
        redBroj: "1921",
        nazivPu: "Ozalj",
        naselje: "Goršćaki Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1922",
        brojPu: "51326",
        redBroj: "1922",
        nazivPu: "Vrbovsko",
        naselje: "Goršeti",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1923",
        brojPu: "42243",
        redBroj: "1923",
        nazivPu: "Maruševec",
        naselje: "Goruševnjak",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1924",
        brojPu: "53000",
        redBroj: "1924",
        nazivPu: "Gospić",
        naselje: "Gospić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1925",
        brojPu: "49216",
        redBroj: "1925",
        nazivPu: "Desinić",
        naselje: "Gostenje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1926",
        brojPu: "51511",
        redBroj: "1926",
        nazivPu: "Malinska",
        naselje: "Gostinjac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1927",
        brojPu: "10340",
        redBroj: "1927",
        nazivPu: "Vrbovec",
        naselje: "Gostović",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1928",
        brojPu: "22300",
        redBroj: "1928",
        nazivPu: "Knin",
        naselje: "Gošić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1929",
        brojPu: "49284",
        redBroj: "1929",
        nazivPu: "Budinščina",
        naselje: "Gotalovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1930",
        brojPu: "48331",
        redBroj: "1930",
        nazivPu: "Gola",
        naselje: "Gotalovo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1931",
        brojPu: "20225",
        redBroj: "1931",
        nazivPu: "Babino Polje",
        naselje: "Goveđari",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "1932",
        brojPu: "43500",
        redBroj: "1932",
        nazivPu: "Daruvar",
        naselje: "Goveđe Polje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1933",
        brojPu: "21238",
        redBroj: "1933",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Grab",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1934",
        brojPu: "23440",
        redBroj: "1934",
        nazivPu: "Gračac",
        naselje: "Grab",
        zupanija: "ZADARSKA",
      },
      {
        id: "1935",
        brojPu: "10450",
        redBroj: "1935",
        nazivPu: "Jastrebarsko",
        naselje: "Grabarak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1936",
        brojPu: "34310",
        redBroj: "1936",
        nazivPu: "Pleternica",
        naselje: "Grabarje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1937",
        brojPu: "35000",
        redBroj: "1937",
        nazivPu: "Slavonski Brod",
        naselje: "Grabarje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "1938",
        brojPu: "47240",
        redBroj: "1938",
        nazivPu: "Slunj",
        naselje: "Grabarska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1939",
        brojPu: "49221",
        redBroj: "1939",
        nazivPu: "Bedekovčina",
        naselje: "Grabe",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1940",
        brojPu: "10340",
        redBroj: "1940",
        nazivPu: "Vrbovec",
        naselje: "Graberanec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1941",
        brojPu: "10342",
        redBroj: "1941",
        nazivPu: "Dubrava",
        naselje: "Graberec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1942",
        brojPu: "44250",
        redBroj: "1942",
        nazivPu: "Petrinja",
        naselje: "Graberje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1943",
        brojPu: "10310",
        redBroj: "1943",
        nazivPu: "Ivanić Grad",
        naselje: "Graberje Ivanićko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1944",
        brojPu: "10340",
        redBroj: "1944",
        nazivPu: "Vrbovec",
        naselje: "Graberšćak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1945",
        brojPu: "33520",
        redBroj: "1945",
        nazivPu: "Slatina",
        naselje: "Grabić",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1946",
        brojPu: "43240",
        redBroj: "1946",
        nazivPu: "Čazma",
        naselje: "Grabik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1947",
        brojPu: "44430",
        redBroj: "1947",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Graboštani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1948",
        brojPu: "21250",
        redBroj: "1948",
        nazivPu: "Šestanovac",
        naselje: "Grabovac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1949",
        brojPu: "31300",
        redBroj: "1949",
        nazivPu: "Beli Manastir",
        naselje: "Grabovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1950",
        brojPu: "47240",
        redBroj: "1950",
        nazivPu: "Slunj",
        naselje: "Grabovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1951",
        brojPu: "44250",
        redBroj: "1951",
        nazivPu: "Petrinja",
        naselje: "Grabovac Banski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1952",
        brojPu: "47220",
        redBroj: "1952",
        nazivPu: "Vojnić",
        naselje: "Grabovac Krnjački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1953",
        brojPu: "47220",
        redBroj: "1953",
        nazivPu: "Vojnić",
        naselje: "Grabovac Vojnićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1954",
        brojPu: "22000",
        redBroj: "1954",
        nazivPu: "Šibenik",
        naselje: "Grabovci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1955",
        brojPu: "44440",
        redBroj: "1955",
        nazivPu: "Dvor",
        naselje: "Grabovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1956",
        brojPu: "43240",
        redBroj: "1956",
        nazivPu: "Čazma",
        naselje: "Grabovnica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "1957",
        brojPu: "32000",
        redBroj: "1957",
        nazivPu: "Vukovar",
        naselje: "Grabovo (Tompojevci)",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1958",
        brojPu: "32000",
        redBroj: "1958",
        nazivPu: "Vukovar",
        naselje: "Grabovo (Vukovar)",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1959",
        brojPu: "44317",
        redBroj: "1959",
        nazivPu: "Popovača",
        naselje: "Grabričina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1960",
        brojPu: "10340",
        redBroj: "1960",
        nazivPu: "Vrbovec",
        naselje: "Grabrić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1961",
        brojPu: "47250",
        redBroj: "1961",
        nazivPu: "Duga Resa",
        naselje: "Grabrk",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1962",
        brojPu: "44317",
        redBroj: "1962",
        nazivPu: "Popovača",
        naselje: "Grabrov Potok",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1963",
        brojPu: "51417",
        redBroj: "1963",
        nazivPu: "Mošćenička Draga",
        naselje: "Grabrova",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1964",
        brojPu: "49210",
        redBroj: "1964",
        nazivPu: "Zabok",
        naselje: "Grabrovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "1965",
        brojPu: "33405",
        redBroj: "1965",
        nazivPu: "Pitomača",
        naselje: "Grabrovnica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1966",
        brojPu: "40313",
        redBroj: "1966",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Grabrovnik",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1967",
        brojPu: "53230",
        redBroj: "1967",
        nazivPu: "Korenica",
        naselje: "Grabušić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1968",
        brojPu: "22222",
        redBroj: "1968",
        nazivPu: "Skradin",
        naselje: "Gračac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1969",
        brojPu: "23440",
        redBroj: "1969",
        nazivPu: "Gračac",
        naselje: "Gračac",
        zupanija: "ZADARSKA",
      },
      {
        id: "1970",
        brojPu: "10454",
        redBroj: "1970",
        nazivPu: "Krašić",
        naselje: "Gračac Slavetićki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1971",
        brojPu: "44400",
        redBroj: "1971",
        nazivPu: "Glina",
        naselje: "Gračanica Šišinečka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1972",
        brojPu: "10370",
        redBroj: "1972",
        nazivPu: "Dugo Selo",
        naselje: "Gračec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1973",
        brojPu: "48260",
        redBroj: "1973",
        nazivPu: "Križevci",
        naselje: "Gračina",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "1974",
        brojPu: "52000",
        redBroj: "1974",
        nazivPu: "Pazin",
        naselje: "Gračišće",
        zupanija: "ISTARSKA",
      },
      {
        id: "1975",
        brojPu: "21330",
        redBroj: "1975",
        nazivPu: "Gradac",
        naselje: "Gradac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "1976",
        brojPu: "22320",
        redBroj: "1976",
        nazivPu: "Drniš",
        naselje: "Gradac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1977",
        brojPu: "34310",
        redBroj: "1977",
        nazivPu: "Pleternica",
        naselje: "Gradac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1978",
        brojPu: "31500",
        redBroj: "1978",
        nazivPu: "Našice",
        naselje: "Gradac Našički",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "1979",
        brojPu: "10340",
        redBroj: "1979",
        nazivPu: "Vrbovec",
        naselje: "Gradec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1980",
        brojPu: "10451",
        redBroj: "1980",
        nazivPu: "Pisarovina",
        naselje: "Gradec Pokupski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1981",
        brojPu: "10340",
        redBroj: "1981",
        nazivPu: "Vrbovec",
        naselje: "Gradečki Pavlovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1982",
        brojPu: "10410",
        redBroj: "1982",
        nazivPu: "Velika Gorica",
        naselje: "Gradići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1983",
        brojPu: "22222",
        redBroj: "1983",
        nazivPu: "Skradin",
        naselje: "Gradina",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "1984",
        brojPu: "33410",
        redBroj: "1984",
        nazivPu: "Suhopolje",
        naselje: "Gradina",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "1985",
        brojPu: "52440",
        redBroj: "1985",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Gradina",
        zupanija: "ISTARSKA",
      },
      {
        id: "1986",
        brojPu: "53230",
        redBroj: "1986",
        nazivPu: "Korenica",
        naselje: "Gradina Korenička",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "1987",
        brojPu: "52000",
        redBroj: "1987",
        nazivPu: "Pazin",
        naselje: "Gradinje",
        zupanija: "ISTARSKA",
      },
      {
        id: "1988",
        brojPu: "52424",
        redBroj: "1988",
        nazivPu: "Motovun (Montona)",
        naselje: "Gradinje - Gradigne",
        zupanija: "ISTARSKA",
      },
      {
        id: "1989",
        brojPu: "40313",
        redBroj: "1989",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Gradiščak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "1990",
        brojPu: "47250",
        redBroj: "1990",
        nazivPu: "Duga Resa",
        naselje: "Gradišće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "1991",
        brojPu: "52341",
        redBroj: "1991",
        nazivPu: "Žminj",
        naselje: "Gradišće",
        zupanija: "ISTARSKA",
      },
      {
        id: "1992",
        brojPu: "32272",
        redBroj: "1992",
        nazivPu: "Cerna",
        naselje: "Gradište",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "1993",
        brojPu: "34340",
        redBroj: "1993",
        nazivPu: "Kutjevo",
        naselje: "Gradište",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1994",
        brojPu: "10430",
        redBroj: "1994",
        nazivPu: "Samobor",
        naselje: "Gradna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "1995",
        brojPu: "34000",
        redBroj: "1995",
        nazivPu: "Požega",
        naselje: "Gradski Vrhovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "1996",
        brojPu: "44210",
        redBroj: "1996",
        nazivPu: "Sunja",
        naselje: "Gradusa Posavska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "1997",
        brojPu: "51300",
        redBroj: "1997",
        nazivPu: "Delnice",
        naselje: "Gramalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "1998",
        brojPu: "42220",
        redBroj: "1998",
        nazivPu: "Novi Marof",
        naselje: "Grana",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "1999",
        brojPu: "10257",
        redBroj: "1999",
        nazivPu: "Brezovica",
        naselje: "Grančari",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2000",
        brojPu: "47280",
        redBroj: "2000",
        nazivPu: "Ozalj",
        naselje: "Grandić Breg",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2001",
        brojPu: "52207",
        redBroj: "2001",
        nazivPu: "Barban",
        naselje: "Grandići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2002",
        brojPu: "31500",
        redBroj: "2002",
        nazivPu: "Našice",
        naselje: "Granice",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2003",
        brojPu: "34000",
        redBroj: "2003",
        nazivPu: "Požega",
        naselje: "Granje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2004",
        brojPu: "51300",
        redBroj: "2004",
        nazivPu: "Delnice",
        naselje: "Grbajel",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2005",
        brojPu: "48000",
        redBroj: "2005",
        nazivPu: "Koprivnica",
        naselje: "Grbaševec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2006",
        brojPu: "20207",
        redBroj: "2006",
        nazivPu: "Mlini",
        naselje: "Grbavac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2007",
        brojPu: "43290",
        redBroj: "2007",
        nazivPu: "Grubišno Polje",
        naselje: "Grbavac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2008",
        brojPu: "23232",
        redBroj: "2008",
        nazivPu: "Nin",
        naselje: "Grbe",
        zupanija: "ZADARSKA",
      },
      {
        id: "2009",
        brojPu: "48000",
        redBroj: "2009",
        nazivPu: "Koprivnica",
        naselje: "Grdak",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2010",
        brojPu: "10430",
        redBroj: "2010",
        nazivPu: "Samobor",
        naselje: "Grdanjci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2011",
        brojPu: "49210",
        redBroj: "2011",
        nazivPu: "Zabok",
        naselje: "Grdenci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2012",
        brojPu: "52000",
        redBroj: "2012",
        nazivPu: "Pazin",
        naselje: "Grdoselo",
        zupanija: "ISTARSKA",
      },
      {
        id: "2013",
        brojPu: "47280",
        redBroj: "2013",
        nazivPu: "Ozalj",
        naselje: "Grdun",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2014",
        brojPu: "22000",
        redBroj: "2014",
        nazivPu: "Šibenik",
        naselje: "Grebaštica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2015",
        brojPu: "10340",
        redBroj: "2015",
        nazivPu: "Vrbovec",
        naselje: "Greda",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2016",
        brojPu: "42243",
        redBroj: "2016",
        nazivPu: "Maruševec",
        naselje: "Greda",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2017",
        brojPu: "44000",
        redBroj: "2017",
        nazivPu: "Sisak",
        naselje: "Greda",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2018",
        brojPu: "10310",
        redBroj: "2018",
        nazivPu: "Ivanić Grad",
        naselje: "Greda Breška",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2019",
        brojPu: "44210",
        redBroj: "2019",
        nazivPu: "Sunja",
        naselje: "Greda Sunjska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2020",
        brojPu: "49000",
        redBroj: "2020",
        nazivPu: "Krapina",
        naselje: "Gredenec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2021",
        brojPu: "49290",
        redBroj: "2021",
        nazivPu: "Klanjec",
        naselje: "Gredice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2022",
        brojPu: "35430",
        redBroj: "2022",
        nazivPu: "Okučani",
        naselje: "Gređani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2023",
        brojPu: "44400",
        redBroj: "2023",
        nazivPu: "Glina",
        naselje: "Gređani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2024",
        brojPu: "10430",
        redBroj: "2024",
        nazivPu: "Samobor",
        naselje: "Gregurić Breg",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2025",
        brojPu: "48260",
        redBroj: "2025",
        nazivPu: "Križevci",
        naselje: "Gregurovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2026",
        brojPu: "49217",
        redBroj: "2026",
        nazivPu: "Krapinske Toplice",
        naselje: "Gregurovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2027",
        brojPu: "49252",
        redBroj: "2027",
        nazivPu: "Mihovljan",
        naselje: "Gregurovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2028",
        brojPu: "42223",
        redBroj: "2028",
        nazivPu: "Varaždinske Toplice",
        naselje: "Grešćevina",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2029",
        brojPu: "47250",
        redBroj: "2029",
        nazivPu: "Duga Resa",
        naselje: "Grganjica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2030",
        brojPu: "10454",
        redBroj: "2030",
        nazivPu: "Krašić",
        naselje: "Grgetići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2031",
        brojPu: "43000",
        redBroj: "2031",
        nazivPu: "Bjelovar",
        naselje: "Grginac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2032",
        brojPu: "35252",
        redBroj: "2032",
        nazivPu: "Sibinj",
        naselje: "Grgurevići",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2033",
        brojPu: "23242",
        redBroj: "2033",
        nazivPu: "Posedarje",
        naselje: "Grgurice",
        zupanija: "ZADARSKA",
      },
      {
        id: "2034",
        brojPu: "10454",
        redBroj: "2034",
        nazivPu: "Krašić",
        naselje: "Grič",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2035",
        brojPu: "47000",
        redBroj: "2035",
        nazivPu: "Karlovac",
        naselje: "Griče",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2036",
        brojPu: "52000",
        redBroj: "2036",
        nazivPu: "Pazin",
        naselje: "Grimalda",
        zupanija: "ISTARSKA",
      },
      {
        id: "2037",
        brojPu: "51260",
        redBroj: "2037",
        nazivPu: "Crikvenica",
        naselje: "Grižane-Belgrad",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2038",
        brojPu: "35252",
        redBroj: "2038",
        nazivPu: "Sibinj",
        naselje: "Grižići",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2039",
        brojPu: "40313",
        redBroj: "2039",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Grkaveščak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2040",
        brojPu: "48350",
        redBroj: "2040",
        nazivPu: "Đurđevac",
        naselje: "Grkine",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2041",
        brojPu: "49231",
        redBroj: "2041",
        nazivPu: "Hum na Sutli",
        naselje: "Grletinec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2042",
        brojPu: "51556",
        redBroj: "2042",
        nazivPu: "Martinšćica",
        naselje: "Grmov",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2043",
        brojPu: "44440",
        redBroj: "2043",
        nazivPu: "Dvor",
        naselje: "Grmušani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2044",
        brojPu: "47240",
        redBroj: "2044",
        nazivPu: "Slunj",
        naselje: "Grobnik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2045",
        brojPu: "51218",
        redBroj: "2045",
        nazivPu: "Dražice",
        naselje: "Grobnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2046",
        brojPu: "52000",
        redBroj: "2046",
        nazivPu: "Pazin",
        naselje: "Grobnik",
        zupanija: "ISTARSKA",
      },
      {
        id: "2047",
        brojPu: "49216",
        redBroj: "2047",
        nazivPu: "Desinić",
        naselje: "Grohot",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2048",
        brojPu: "21430",
        redBroj: "2048",
        nazivPu: "Grohote",
        naselje: "Grohote",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2049",
        brojPu: "20232",
        redBroj: "2049",
        nazivPu: "Slano",
        naselje: "Gromača",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2050",
        brojPu: "35252",
        redBroj: "2050",
        nazivPu: "Sibinj",
        naselje: "Gromačnik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2051",
        brojPu: "21465",
        redBroj: "2051",
        nazivPu: "Jelsa",
        naselje: "Gromin Dolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2052",
        brojPu: "52460",
        redBroj: "2052",
        nazivPu: "Buje (Buie)",
        naselje: "Grožnjan - Grisignana",
        zupanija: "ISTARSKA",
      },
      {
        id: "2053",
        brojPu: "47250",
        redBroj: "2053",
        nazivPu: "Duga Resa",
        naselje: "Gršćaki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2054",
        brojPu: "49284",
        redBroj: "2054",
        nazivPu: "Budinščina",
        naselje: "Grtovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2055",
        brojPu: "52440",
        redBroj: "2055",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Grubići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2056",
        brojPu: "21260",
        redBroj: "2056",
        nazivPu: "Imotski",
        naselje: "Grubine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2057",
        brojPu: "43290",
        redBroj: "2057",
        nazivPu: "Grubišno Polje",
        naselje: "Grubišno Polje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2058",
        brojPu: "20215",
        redBroj: "2058",
        nazivPu: "Gruda",
        naselje: "Gruda",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2059",
        brojPu: "33515",
        redBroj: "2059",
        nazivPu: "Orahovica",
        naselje: "Grudnjak",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2060",
        brojPu: "52341",
        redBroj: "2060",
        nazivPu: "Žminj",
        naselje: "Gržini",
        zupanija: "ISTARSKA",
      },
      {
        id: "2061",
        brojPu: "49210",
        redBroj: "2061",
        nazivPu: "Zabok",
        naselje: "Gubaševo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2062",
        brojPu: "23440",
        redBroj: "2062",
        nazivPu: "Gračac",
        naselje: "Gubavčevo Polje",
        zupanija: "ZADARSKA",
      },
      {
        id: "2063",
        brojPu: "10450",
        redBroj: "2063",
        nazivPu: "Jastrebarsko",
        naselje: "Guci Draganički",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2064",
        brojPu: "51300",
        redBroj: "2064",
        nazivPu: "Delnice",
        naselje: "Guče Selo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2065",
        brojPu: "47280",
        redBroj: "2065",
        nazivPu: "Ozalj",
        naselje: "Gudalji",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2066",
        brojPu: "10410",
        redBroj: "2066",
        nazivPu: "Velika Gorica",
        naselje: "Gudci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2067",
        brojPu: "43000",
        redBroj: "2067",
        nazivPu: "Bjelovar",
        naselje: "Gudovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2068",
        brojPu: "35222",
        redBroj: "2068",
        nazivPu: "Gundinci",
        naselje: "Gundinci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2069",
        brojPu: "32257",
        redBroj: "2069",
        nazivPu: "Drenovci",
        naselje: "Gunja",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2070",
        brojPu: "35420",
        redBroj: "2070",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Gunjavci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2071",
        brojPu: "49240",
        redBroj: "2071",
        nazivPu: "Donja Stubica",
        naselje: "Gusakovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2072",
        brojPu: "10410",
        redBroj: "2072",
        nazivPu: "Velika Gorica",
        naselje: "Gustelnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2073",
        brojPu: "51300",
        redBroj: "2073",
        nazivPu: "Delnice",
        naselje: "Gusti Laz",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2074",
        brojPu: "21222",
        redBroj: "2074",
        nazivPu: "Marina",
        naselje: "Gustirna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2075",
        brojPu: "48260",
        redBroj: "2075",
        nazivPu: "Križevci",
        naselje: "Guščerovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2076",
        brojPu: "44000",
        redBroj: "2076",
        nazivPu: "Sisak",
        naselje: "Gušće",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2077",
        brojPu: "33410",
        redBroj: "2077",
        nazivPu: "Suhopolje",
        naselje: "Gvozdanska",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2078",
        brojPu: "44440",
        redBroj: "2078",
        nazivPu: "Dvor",
        naselje: "Gvozdansko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2079",
        brojPu: "10342",
        redBroj: "2079",
        nazivPu: "Dubrava",
        naselje: "Habjanovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2080",
        brojPu: "31550",
        redBroj: "2080",
        nazivPu: "Valpovo",
        naselje: "Habjanovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2081",
        brojPu: "44400",
        redBroj: "2081",
        nazivPu: "Glina",
        naselje: "Hađer",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2082",
        brojPu: "10342",
        redBroj: "2082",
        nazivPu: "Dubrava",
        naselje: "Haganj",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2083",
        brojPu: "51326",
        redBroj: "2083",
        nazivPu: "Vrbovsko",
        naselje: "Hajdine",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2084",
        brojPu: "44400",
        redBroj: "2084",
        nazivPu: "Glina",
        naselje: "Hajtić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2085",
        brojPu: "51326",
        redBroj: "2085",
        nazivPu: "Vrbovsko",
        naselje: "Hambarište",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2086",
        brojPu: "48350",
        redBroj: "2086",
        nazivPu: "Đurđevac",
        naselje: "Hampovica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2087",
        brojPu: "49290",
        redBroj: "2087",
        nazivPu: "Klanjec",
        naselje: "Harina Žlaka",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2088",
        brojPu: "31550",
        redBroj: "2088",
        nazivPu: "Valpovo",
        naselje: "Harkanovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2089",
        brojPu: "10292",
        redBroj: "2089",
        nazivPu: "Šenkovec",
        naselje: "Harmica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2090",
        brojPu: "10454",
        redBroj: "2090",
        nazivPu: "Krašić",
        naselje: "Hartje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2091",
        brojPu: "10257",
        redBroj: "2091",
        nazivPu: "Brezovica",
        naselje: "Havidić Selo",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2092",
        brojPu: "52000",
        redBroj: "2092",
        nazivPu: "Pazin",
        naselje: "Heki",
        zupanija: "ISTARSKA",
      },
      {
        id: "2093",
        brojPu: "40323",
        redBroj: "2093",
        nazivPu: "Prelog",
        naselje: "Hemuševec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2094",
        brojPu: "52440",
        redBroj: "2094",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Heraki",
        zupanija: "ISTARSKA",
      },
      {
        id: "2095",
        brojPu: "43280",
        redBroj: "2095",
        nazivPu: "Garešnica",
        naselje: "Hercegovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2096",
        brojPu: "48000",
        redBroj: "2096",
        nazivPu: "Koprivnica",
        naselje: "Herešin",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2097",
        brojPu: "49240",
        redBroj: "2097",
        nazivPu: "Donja Stubica",
        naselje: "Hižakovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2098",
        brojPu: "48260",
        redBroj: "2098",
        nazivPu: "Križevci",
        naselje: "Hižanovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2099",
        brojPu: "51511",
        redBroj: "2099",
        nazivPu: "Malinska",
        naselje: "Hlapa",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2100",
        brojPu: "40313",
        redBroj: "2100",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Hlapičina",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2101",
        brojPu: "48316",
        redBroj: "2101",
        nazivPu: "Đelekovec",
        naselje: "Hlebine",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2102",
        brojPu: "51300",
        redBroj: "2102",
        nazivPu: "Delnice",
        naselje: "Hlevci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2103",
        brojPu: "49225",
        redBroj: "2103",
        nazivPu: "Đurmanec",
        naselje: "Hlevnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2104",
        brojPu: "20230",
        redBroj: "2104",
        nazivPu: "Ston",
        naselje: "Hodilje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2105",
        brojPu: "47280",
        redBroj: "2105",
        nazivPu: "Ozalj",
        naselje: "Hodinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2106",
        brojPu: "40320",
        redBroj: "2106",
        nazivPu: "Donji Kraljevec",
        naselje: "Hodošan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2107",
        brojPu: "51300",
        redBroj: "2107",
        nazivPu: "Delnice",
        naselje: "Homer",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2108",
        brojPu: "53230",
        redBroj: "2108",
        nazivPu: "Korenica",
        naselje: "Homoljac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2109",
        brojPu: "10436",
        redBroj: "2109",
        nazivPu: "Rakov Potok",
        naselje: "Horvati",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2110",
        brojPu: "42240",
        redBroj: "2110",
        nazivPu: "Ivanec",
        naselje: "Horvatsko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2111",
        brojPu: "51300",
        redBroj: "2111",
        nazivPu: "Delnice",
        naselje: "Hosnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2112",
        brojPu: "10414",
        redBroj: "2112",
        nazivPu: "Pokupsko",
        naselje: "Hotnja",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2113",
        brojPu: "44000",
        redBroj: "2113",
        nazivPu: "Sisak",
        naselje: "Hrastelnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2114",
        brojPu: "31431",
        redBroj: "2114",
        nazivPu: "Čepin",
        naselje: "Hrastin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2115",
        brojPu: "10291",
        redBroj: "2115",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Hrastina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2116",
        brojPu: "10430",
        redBroj: "2116",
        nazivPu: "Samobor",
        naselje: "Hrastina Samoborska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2117",
        brojPu: "10380",
        redBroj: "2117",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Hrastje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2118",
        brojPu: "10450",
        redBroj: "2118",
        nazivPu: "Jastrebarsko",
        naselje: "Hrastje Plešivičko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2119",
        brojPu: "31431",
        redBroj: "2119",
        nazivPu: "Čepin",
        naselje: "Hrastovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2120",
        brojPu: "43280",
        redBroj: "2120",
        nazivPu: "Garešnica",
        naselje: "Hrastovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2121",
        brojPu: "42223",
        redBroj: "2121",
        nazivPu: "Varaždinske Toplice",
        naselje: "Hrastovec Toplički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2122",
        brojPu: "44250",
        redBroj: "2122",
        nazivPu: "Petrinja",
        naselje: "Hrastovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2123",
        brojPu: "47280",
        redBroj: "2123",
        nazivPu: "Ozalj",
        naselje: "Hrastovica Vivodinska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2124",
        brojPu: "42230",
        redBroj: "2124",
        nazivPu: "Ludbreg",
        naselje: "Hrastovljan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2125",
        brojPu: "42230",
        redBroj: "2125",
        nazivPu: "Ludbreg",
        naselje: "Hrastovsko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2126",
        brojPu: "10450",
        redBroj: "2126",
        nazivPu: "Jastrebarsko",
        naselje: "Hrašća",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2127",
        brojPu: "10020",
        redBroj: "2127",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Hrašće Turopoljsko",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2128",
        brojPu: "42000",
        redBroj: "2128",
        nazivPu: "Varaždin",
        naselje: "Hrašćica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2129",
        brojPu: "49284",
        redBroj: "2129",
        nazivPu: "Budinščina",
        naselje: "Hrašćina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2130",
        brojPu: "52207",
        redBroj: "2130",
        nazivPu: "Barban",
        naselje: "Hrboki",
        zupanija: "ISTARSKA",
      },
      {
        id: "2131",
        brojPu: "10290",
        redBroj: "2131",
        nazivPu: "Zaprešić",
        naselje: "Hrebine",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2132",
        brojPu: "10370",
        redBroj: "2132",
        nazivPu: "Dugo Selo",
        naselje: "Hrebinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2133",
        brojPu: "52207",
        redBroj: "2133",
        nazivPu: "Barban",
        naselje: "Hreljići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2134",
        brojPu: "51226",
        redBroj: "2134",
        nazivPu: "Hreljin",
        naselje: "Hreljin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2135",
        brojPu: "47300",
        redBroj: "2135",
        nazivPu: "Ogulin",
        naselje: "Hreljin Ogulinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2136",
        brojPu: "48260",
        redBroj: "2136",
        nazivPu: "Križevci",
        naselje: "Hrgovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2137",
        brojPu: "51306",
        redBroj: "2137",
        nazivPu: "Čabar",
        naselje: "Hrib",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2138",
        brojPu: "51300",
        redBroj: "2138",
        nazivPu: "Delnice",
        naselje: "Hribac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2139",
        brojPu: "31400",
        redBroj: "2139",
        nazivPu: "Đakovo",
        naselje: "Hrkanovci Đakovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2140",
        brojPu: "10380",
        redBroj: "2140",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Hrnjanec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2141",
        brojPu: "34340",
        redBroj: "2141",
        nazivPu: "Kutjevo",
        naselje: "Hrnjevac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2142",
        brojPu: "49225",
        redBroj: "2142",
        nazivPu: "Đurmanec",
        naselje: "Hromec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2143",
        brojPu: "47250",
        redBroj: "2143",
        nazivPu: "Duga Resa",
        naselje: "Hrsina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2144",
        brojPu: "48214",
        redBroj: "2144",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Hrsovo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2145",
        brojPu: "49217",
        redBroj: "2145",
        nazivPu: "Krapinske Toplice",
        naselje: "Hršak Breg",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2146",
        brojPu: "21260",
        redBroj: "2146",
        nazivPu: "Imotski",
        naselje: "Hršćevani",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2147",
        brojPu: "44440",
        redBroj: "2147",
        nazivPu: "Dvor",
        naselje: "Hrtić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2148",
        brojPu: "10360",
        redBroj: "2148",
        nazivPu: "Sesvete",
        naselje: "Hrušćica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2149",
        brojPu: "49240",
        redBroj: "2149",
        nazivPu: "Donja Stubica",
        naselje: "Hruševec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2150",
        brojPu: "10295",
        redBroj: "2150",
        nazivPu: "Kupljenovo",
        naselje: "Hruševec Kupljenski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2151",
        brojPu: "10290",
        redBroj: "2151",
        nazivPu: "Zaprešić",
        naselje: "Hruševec Pušćanski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2152",
        brojPu: "10340",
        redBroj: "2152",
        nazivPu: "Vrbovec",
        naselje: "Hruškovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2153",
        brojPu: "10340",
        redBroj: "2153",
        nazivPu: "Vrbovec",
        naselje: "Hruškovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2154",
        brojPu: "21233",
        redBroj: "2154",
        nazivPu: "Hrvace",
        naselje: "Hrvace",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2155",
        brojPu: "44430",
        redBroj: "2155",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Hrvatska Dubica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2156",
        brojPu: "44430",
        redBroj: "2156",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Hrvatska Kostajnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2157",
        brojPu: "44250",
        redBroj: "2157",
        nazivPu: "Petrinja",
        naselje: "Hrvatski Čuntić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2158",
        brojPu: "10257",
        redBroj: "2158",
        nazivPu: "Brezovica",
        naselje: "Hrvatski Leskovac",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2159",
        brojPu: "51306",
        redBroj: "2159",
        nazivPu: "Čabar",
        naselje: "Hrvatsko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2160",
        brojPu: "53220",
        redBroj: "2160",
        nazivPu: "Otočac",
        naselje: "Hrvatsko Polje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2161",
        brojPu: "44400",
        redBroj: "2161",
        nazivPu: "Glina",
        naselje: "Hrvatsko Selo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2162",
        brojPu: "47220",
        redBroj: "2162",
        nazivPu: "Vojnić",
        naselje: "Hrvatsko Žarište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2163",
        brojPu: "42230",
        redBroj: "2163",
        nazivPu: "Ludbreg",
        naselje: "Hrženica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2164",
        brojPu: "10454",
        redBroj: "2164",
        nazivPu: "Krašić",
        naselje: "Hrženik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2165",
        brojPu: "10257",
        redBroj: "2165",
        nazivPu: "Brezovica",
        naselje: "Hudi Bitek",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2166",
        brojPu: "48000",
        redBroj: "2166",
        nazivPu: "Koprivnica",
        naselje: "Hudovljani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2167",
        brojPu: "10340",
        redBroj: "2167",
        nazivPu: "Vrbovec",
        naselje: "Hudovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2168",
        brojPu: "33520",
        redBroj: "2168",
        nazivPu: "Slatina",
        naselje: "Hum",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2169",
        brojPu: "52420",
        redBroj: "2169",
        nazivPu: "Buzet",
        naselje: "Hum",
        zupanija: "ISTARSKA",
      },
      {
        id: "2170",
        brojPu: "49246",
        redBroj: "2170",
        nazivPu: "Marija Bistrica",
        naselje: "Hum Bistrički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2171",
        brojPu: "49216",
        redBroj: "2171",
        nazivPu: "Desinić",
        naselje: "Hum Košnički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2172",
        brojPu: "49231",
        redBroj: "2172",
        nazivPu: "Hum na Sutli",
        naselje: "Hum na Sutli",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2173",
        brojPu: "49240",
        redBroj: "2173",
        nazivPu: "Donja Stubica",
        naselje: "Hum Stubički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2174",
        brojPu: "33520",
        redBroj: "2174",
        nazivPu: "Slatina",
        naselje: "Hum Varoš",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2175",
        brojPu: "49210",
        redBroj: "2175",
        nazivPu: "Zabok",
        naselje: "Hum Zabočki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2176",
        brojPu: "21465",
        redBroj: "2176",
        nazivPu: "Jelsa",
        naselje: "Humac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2177",
        brojPu: "33515",
        redBroj: "2177",
        nazivPu: "Orahovica",
        naselje: "Humljani",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2178",
        brojPu: "44320",
        redBroj: "2178",
        nazivPu: "Kutina",
        naselje: "Husain",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2179",
        brojPu: "49282",
        redBroj: "2179",
        nazivPu: "Konjščina",
        naselje: "Husinec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2180",
        brojPu: "47000",
        redBroj: "2180",
        nazivPu: "Karlovac",
        naselje: "Husje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2181",
        brojPu: "10454",
        redBroj: "2181",
        nazivPu: "Krašić",
        naselje: "Hutin",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2182",
        brojPu: "21450",
        redBroj: "2182",
        nazivPu: "Hvar",
        naselje: "Hvar",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2183",
        brojPu: "51410",
        redBroj: "2183",
        nazivPu: "Opatija",
        naselje: "Ičići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2184",
        brojPu: "22222",
        redBroj: "2184",
        nazivPu: "Skradin",
        naselje: "Ićevo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2185",
        brojPu: "21327",
        redBroj: "2185",
        nazivPu: "Podgora",
        naselje: "Igrane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2186",
        brojPu: "10297",
        redBroj: "2186",
        nazivPu: "Jakovlje",
        naselje: "Igrišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2187",
        brojPu: "51410",
        redBroj: "2187",
        nazivPu: "Opatija",
        naselje: "Ika",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2188",
        brojPu: "32249",
        redBroj: "2188",
        nazivPu: "Tovarnik",
        naselje: "Ilača",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2189",
        brojPu: "33520",
        redBroj: "2189",
        nazivPu: "Slatina",
        naselje: "Ilmin Dvor",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2190",
        brojPu: "32236",
        redBroj: "2190",
        nazivPu: "Ilok",
        naselje: "Ilok",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2191",
        brojPu: "44320",
        redBroj: "2191",
        nazivPu: "Kutina",
        naselje: "Ilova",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2192",
        brojPu: "47280",
        redBroj: "2192",
        nazivPu: "Ozalj",
        naselje: "Ilovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2193",
        brojPu: "44400",
        redBroj: "2193",
        nazivPu: "Glina",
        naselje: "Ilovačak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2194",
        brojPu: "51218",
        redBroj: "2194",
        nazivPu: "Dražice",
        naselje: "Ilovik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2195",
        brojPu: "51552",
        redBroj: "2195",
        nazivPu: "Ilovik",
        naselje: "Ilovik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2196",
        brojPu: "43280",
        redBroj: "2196",
        nazivPu: "Garešnica",
        naselje: "Ilovski Klokočevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2197",
        brojPu: "48316",
        redBroj: "2197",
        nazivPu: "Đelekovec",
        naselje: "Imbriovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2198",
        brojPu: "42202",
        redBroj: "2198",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Imbriovec Jalžabetski",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2199",
        brojPu: "20230",
        redBroj: "2199",
        nazivPu: "Ston",
        naselje: "Imotica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2200",
        brojPu: "21260",
        redBroj: "2200",
        nazivPu: "Imotski",
        naselje: "Imotski",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2201",
        brojPu: "34340",
        redBroj: "2201",
        nazivPu: "Kutjevo",
        naselje: "Imrijevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2202",
        brojPu: "43500",
        redBroj: "2202",
        nazivPu: "Daruvar",
        naselje: "Imsovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2203",
        brojPu: "52424",
        redBroj: "2203",
        nazivPu: "Motovun (Montona)",
        naselje: "Ipši - Ipsi",
        zupanija: "ISTARSKA",
      },
      {
        id: "2204",
        brojPu: "47240",
        redBroj: "2204",
        nazivPu: "Slunj",
        naselje: "Irinovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2205",
        brojPu: "23420",
        redBroj: "2205",
        nazivPu: "Benkovac",
        naselje: "Islam Grčki",
        zupanija: "ZADARSKA",
      },
      {
        id: "2206",
        brojPu: "23242",
        redBroj: "2206",
        nazivPu: "Posedarje",
        naselje: "Islam Latinski",
        zupanija: "ZADARSKA",
      },
      {
        id: "2207",
        brojPu: "23293",
        redBroj: "2207",
        nazivPu: "Ist",
        naselje: "Ist",
        zupanija: "ZADARSKA",
      },
      {
        id: "2208",
        brojPu: "47300",
        redBroj: "2208",
        nazivPu: "Ogulin",
        naselje: "Istočni Trojvrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2209",
        brojPu: "51300",
        redBroj: "2209",
        nazivPu: "Delnice",
        naselje: "Iševnica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2210",
        brojPu: "21465",
        redBroj: "2210",
        nazivPu: "Jelsa",
        naselje: "Ivan Dolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2211",
        brojPu: "21260",
        redBroj: "2211",
        nazivPu: "Imotski",
        naselje: "Ivanbegovina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2212",
        brojPu: "33520",
        redBroj: "2212",
        nazivPu: "Slatina",
        naselje: "Ivanbrijeg",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2213",
        brojPu: "10342",
        redBroj: "2213",
        nazivPu: "Dubrava",
        naselje: "Ivančani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2214",
        brojPu: "48000",
        redBroj: "2214",
        nazivPu: "Koprivnica",
        naselje: "Ivančec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2215",
        brojPu: "10450",
        redBroj: "2215",
        nazivPu: "Jastrebarsko",
        naselje: "Ivančići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2216",
        brojPu: "47000",
        redBroj: "2216",
        nazivPu: "Karlovac",
        naselje: "Ivančići Pokupski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2217",
        brojPu: "34000",
        redBroj: "2217",
        nazivPu: "Požega",
        naselje: "Ivandol",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2218",
        brojPu: "42240",
        redBroj: "2218",
        nazivPu: "Ivanec",
        naselje: "Ivanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2219",
        brojPu: "10290",
        redBroj: "2219",
        nazivPu: "Zaprešić",
        naselje: "Ivanec Bistranski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2220",
        brojPu: "48260",
        redBroj: "2220",
        nazivPu: "Križevci",
        naselje: "Ivanec Križevački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2221",
        brojPu: "42240",
        redBroj: "2221",
        nazivPu: "Ivanec",
        naselje: "Ivanečka Željeznica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2222",
        brojPu: "42240",
        redBroj: "2222",
        nazivPu: "Ivanec",
        naselje: "Ivanečki Vrhovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2223",
        brojPu: "42240",
        redBroj: "2223",
        nazivPu: "Ivanec",
        naselje: "Ivanečko Naselje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2224",
        brojPu: "49216",
        redBroj: "2224",
        nazivPu: "Desinić",
        naselje: "Ivanić Desinićki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2225",
        brojPu: "49216",
        redBroj: "2225",
        nazivPu: "Desinić",
        naselje: "Ivanić Košnički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2226",
        brojPu: "49290",
        redBroj: "2226",
        nazivPu: "Klanjec",
        naselje: "Ivanić Miljanski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2227",
        brojPu: "10310",
        redBroj: "2227",
        nazivPu: "Ivanić Grad",
        naselje: "Ivanić-Grad",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2228",
        brojPu: "47000",
        redBroj: "2228",
        nazivPu: "Karlovac",
        naselje: "Ivanković Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2229",
        brojPu: "32100",
        redBroj: "2229",
        nazivPu: "Vinkovci",
        naselje: "Ivankovo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2230",
        brojPu: "31200",
        redBroj: "2230",
        nazivPu: "Osijek",
        naselje: "Ivanovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2231",
        brojPu: "31550",
        redBroj: "2231",
        nazivPu: "Valpovo",
        naselje: "Ivanovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2232",
        brojPu: "34340",
        redBroj: "2232",
        nazivPu: "Kutjevo",
        naselje: "Ivanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2233",
        brojPu: "31400",
        redBroj: "2233",
        nazivPu: "Đakovo",
        naselje: "Ivanovci Gorjanski",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2234",
        brojPu: "40000",
        redBroj: "2234",
        nazivPu: "Čakovec",
        naselje: "Ivanovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2235",
        brojPu: "31540",
        redBroj: "2235",
        nazivPu: "Donji Miholjac",
        naselje: "Ivanovo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2236",
        brojPu: "43500",
        redBroj: "2236",
        nazivPu: "Daruvar",
        naselje: "Ivanovo Polje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2237",
        brojPu: "43290",
        redBroj: "2237",
        nazivPu: "Grubišno Polje",
        naselje: "Ivanovo Selo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2238",
        brojPu: "43000",
        redBroj: "2238",
        nazivPu: "Bjelovar",
        naselje: "Ivanska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2239",
        brojPu: "10360",
        redBroj: "2239",
        nazivPu: "Sesvete",
        naselje: "Ivanja Reka",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2240",
        brojPu: "51557",
        redBroj: "2240",
        nazivPu: "Cres",
        naselje: "Ivanje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2241",
        brojPu: "44210",
        redBroj: "2241",
        nazivPu: "Sunja",
        naselje: "Ivanjski Bok",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2242",
        brojPu: "52440",
        redBroj: "2242",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ivići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2243",
        brojPu: "22300",
        redBroj: "2243",
        nazivPu: "Knin",
        naselje: "Ivoševci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2244",
        brojPu: "47000",
        redBroj: "2244",
        nazivPu: "Karlovac",
        naselje: "Ivošević Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2245",
        brojPu: "10450",
        redBroj: "2245",
        nazivPu: "Jastrebarsko",
        naselje: "Izimje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2246",
        brojPu: "51326",
        redBroj: "2246",
        nazivPu: "Vrbovsko",
        naselje: "Jablan",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2247",
        brojPu: "53270",
        redBroj: "2247",
        nazivPu: "Senj",
        naselje: "Jablanac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2248",
        brojPu: "10290",
        redBroj: "2248",
        nazivPu: "Zaprešić",
        naselje: "Jablanovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2249",
        brojPu: "43000",
        redBroj: "2249",
        nazivPu: "Bjelovar",
        naselje: "Jabučeta",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2250",
        brojPu: "21240",
        redBroj: "2250",
        nazivPu: "Trilj",
        naselje: "Jabuka",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2251",
        brojPu: "44250",
        redBroj: "2251",
        nazivPu: "Petrinja",
        naselje: "Jabukovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2252",
        brojPu: "51260",
        redBroj: "2252",
        nazivPu: "Crikvenica",
        naselje: "Jadranovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2253",
        brojPu: "52100",
        redBroj: "2253",
        nazivPu: "Pula (Pola)",
        naselje: "Jadreški - Giadreschi",
        zupanija: "ISTARSKA",
      },
      {
        id: "2254",
        brojPu: "47000",
        redBroj: "2254",
        nazivPu: "Karlovac",
        naselje: "Jadrići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2255",
        brojPu: "22000",
        redBroj: "2255",
        nazivPu: "Šibenik",
        naselje: "Jadrtovac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2256",
        brojPu: "52440",
        redBroj: "2256",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jadruhi",
        zupanija: "ISTARSKA",
      },
      {
        id: "2257",
        brojPu: "34550",
        redBroj: "2257",
        nazivPu: "Pakrac",
        naselje: "Jagma",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2258",
        brojPu: "48000",
        redBroj: "2258",
        nazivPu: "Koprivnica",
        naselje: "Jagnjedovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2259",
        brojPu: "10431",
        redBroj: "2259",
        nazivPu: "Sveta Nedelja",
        naselje: "Jagnjić Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2260",
        brojPu: "21465",
        redBroj: "2260",
        nazivPu: "Jelsa",
        naselje: "Jagodna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2261",
        brojPu: "10410",
        redBroj: "2261",
        nazivPu: "Velika Gorica",
        naselje: "Jagodno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2262",
        brojPu: "31300",
        redBroj: "2262",
        nazivPu: "Beli Manastir",
        naselje: "Jagodnjak",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2263",
        brojPu: "53234",
        redBroj: "2263",
        nazivPu: "Udbina",
        naselje: "Jagodnje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2264",
        brojPu: "47220",
        redBroj: "2264",
        nazivPu: "Vojnić",
        naselje: "Jagrovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2265",
        brojPu: "34000",
        redBroj: "2265",
        nazivPu: "Požega",
        naselje: "Jaguplije",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2266",
        brojPu: "52000",
        redBroj: "2266",
        nazivPu: "Pazin",
        naselje: "Jakačići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2267",
        brojPu: "35252",
        redBroj: "2267",
        nazivPu: "Sibinj",
        naselje: "Jakačina Mala",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2268",
        brojPu: "52440",
        redBroj: "2268",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jakići Dolinji",
        zupanija: "ISTARSKA",
      },
      {
        id: "2269",
        brojPu: "52440",
        redBroj: "2269",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jakići Gorinji",
        zupanija: "ISTARSKA",
      },
      {
        id: "2270",
        brojPu: "53291",
        redBroj: "2270",
        nazivPu: "Novalja",
        naselje: "Jakišnica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2271",
        brojPu: "52333",
        redBroj: "2271",
        nazivPu: "Podpićan",
        naselje: "Jakomići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2272",
        brojPu: "43000",
        redBroj: "2272",
        nazivPu: "Bjelovar",
        naselje: "Jakopovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2273",
        brojPu: "42204",
        redBroj: "2273",
        nazivPu: "Turčin",
        naselje: "Jakopovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2274",
        brojPu: "51250",
        redBroj: "2274",
        nazivPu: "Novi Vinodolski",
        naselje: "Jakov Polje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2275",
        brojPu: "34550",
        redBroj: "2275",
        nazivPu: "Pakrac",
        naselje: "Jakovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2276",
        brojPu: "47000",
        redBroj: "2276",
        nazivPu: "Karlovac",
        naselje: "Jakovci Netretićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2277",
        brojPu: "52000",
        redBroj: "2277",
        nazivPu: "Pazin",
        naselje: "Jakovici",
        zupanija: "ISTARSKA",
      },
      {
        id: "2278",
        brojPu: "10297",
        redBroj: "2278",
        nazivPu: "Jakovlje",
        naselje: "Jakovlje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2279",
        brojPu: "34000",
        redBroj: "2279",
        nazivPu: "Požega",
        naselje: "Jakšić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2280",
        brojPu: "51326",
        redBroj: "2280",
        nazivPu: "Vrbovsko",
        naselje: "Jakšići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2281",
        brojPu: "49240",
        redBroj: "2281",
        nazivPu: "Donja Stubica",
        naselje: "Jakšinec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2282",
        brojPu: "49210",
        redBroj: "2282",
        nazivPu: "Zabok",
        naselje: "Jakuševec Zabočki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2283",
        brojPu: "42220",
        redBroj: "2283",
        nazivPu: "Novi Marof",
        naselje: "Jales Breznički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2284",
        brojPu: "42000",
        redBroj: "2284",
        nazivPu: "Varaždin",
        naselje: "Jalkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2285",
        brojPu: "10370",
        redBroj: "2285",
        nazivPu: "Dugo Selo",
        naselje: "Jalševec Nartski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2286",
        brojPu: "42223",
        redBroj: "2286",
        nazivPu: "Varaždinske Toplice",
        naselje: "Jalševec Svibovečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2287",
        brojPu: "49210",
        redBroj: "2287",
        nazivPu: "Zabok",
        naselje: "Jalšje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2288",
        brojPu: "40313",
        redBroj: "2288",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Jalšovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2289",
        brojPu: "42202",
        redBroj: "2289",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Jalžabet",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2290",
        brojPu: "44320",
        redBroj: "2290",
        nazivPu: "Kutina",
        naselje: "Jamarica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2291",
        brojPu: "47240",
        redBroj: "2291",
        nazivPu: "Slunj",
        naselje: "Jamarje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2292",
        brojPu: "47240",
        redBroj: "2292",
        nazivPu: "Slunj",
        naselje: "Jame",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2293",
        brojPu: "10451",
        redBroj: "2293",
        nazivPu: "Pisarovina",
        naselje: "Jamnica Pisarovinska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2294",
        brojPu: "42250",
        redBroj: "2294",
        nazivPu: "Lepoglava",
        naselje: "Jamno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2295",
        brojPu: "47250",
        redBroj: "2295",
        nazivPu: "Duga Resa",
        naselje: "Jančani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2296",
        brojPu: "48000",
        redBroj: "2296",
        nazivPu: "Koprivnica",
        naselje: "Jankovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2297",
        brojPu: "47250",
        redBroj: "2297",
        nazivPu: "Duga Resa",
        naselje: "Jankovo Selište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2298",
        brojPu: "47300",
        redBroj: "2298",
        nazivPu: "Ogulin",
        naselje: "Janja Gora",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2299",
        brojPu: "44320",
        redBroj: "2299",
        nazivPu: "Kutina",
        naselje: "Janja Lipa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2300",
        brojPu: "20246",
        redBroj: "2300",
        nazivPu: "Janjina",
        naselje: "Janjina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2301",
        brojPu: "34550",
        redBroj: "2301",
        nazivPu: "Pakrac",
        naselje: "Japaga",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2302",
        brojPu: "48260",
        redBroj: "2302",
        nazivPu: "Križevci",
        naselje: "Jarčani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2303",
        brojPu: "47250",
        redBroj: "2303",
        nazivPu: "Duga Resa",
        naselje: "Jarče Polje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2304",
        brojPu: "22202",
        redBroj: "2304",
        nazivPu: "Primošten",
        naselje: "Jarebinjak",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2305",
        brojPu: "42220",
        redBroj: "2305",
        nazivPu: "Novi Marof",
        naselje: "Jarek Bisaški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2306",
        brojPu: "49284",
        redBroj: "2306",
        nazivPu: "Budinščina",
        naselje: "Jarek Habekov",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2307",
        brojPu: "42208",
        redBroj: "2307",
        nazivPu: "Cestica",
        naselje: "Jarki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2308",
        brojPu: "42223",
        redBroj: "2308",
        nazivPu: "Varaždinske Toplice",
        naselje: "Jarki Horvatićevi",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2309",
        brojPu: "32100",
        redBroj: "2309",
        nazivPu: "Vinkovci",
        naselje: "Jarmina",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2310",
        brojPu: "47000",
        redBroj: "2310",
        nazivPu: "Karlovac",
        naselje: "Jarnevići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2311",
        brojPu: "35222",
        redBroj: "2311",
        nazivPu: "Gundinci",
        naselje: "Jaruge",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2312",
        brojPu: "10430",
        redBroj: "2312",
        nazivPu: "Samobor",
        naselje: "Jarušje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2313",
        brojPu: "47300",
        redBroj: "2313",
        nazivPu: "Ogulin",
        naselje: "Jasenak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2314",
        brojPu: "33000",
        redBroj: "2314",
        nazivPu: "Virovitica",
        naselje: "Jasenaš",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2315",
        brojPu: "20210",
        redBroj: "2315",
        nazivPu: "Cavtat",
        naselje: "Jasenice",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2316",
        brojPu: "23450",
        redBroj: "2316",
        nazivPu: "Obrovac",
        naselje: "Jasenice",
        zupanija: "ZADARSKA",
      },
      {
        id: "2317",
        brojPu: "43000",
        redBroj: "2317",
        nazivPu: "Bjelovar",
        naselje: "Jasenik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2318",
        brojPu: "31300",
        redBroj: "2318",
        nazivPu: "Beli Manastir",
        naselje: "Jasenovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2319",
        brojPu: "44330",
        redBroj: "2319",
        nazivPu: "Novska",
        naselje: "Jasenovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2320",
        brojPu: "49217",
        redBroj: "2320",
        nazivPu: "Krapinske Toplice",
        naselje: "Jasenovac Zagorski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2321",
        brojPu: "44210",
        redBroj: "2321",
        nazivPu: "Sunja",
        naselje: "Jasenovčani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2322",
        brojPu: "47000",
        redBroj: "2322",
        nazivPu: "Karlovac",
        naselje: "Jasenovica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2323",
        brojPu: "52440",
        redBroj: "2323",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jasenovica",
        zupanija: "ISTARSKA",
      },
      {
        id: "2324",
        brojPu: "21230",
        redBroj: "2324",
        nazivPu: "Sinj",
        naselje: "Jasensko",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2325",
        brojPu: "34340",
        redBroj: "2325",
        nazivPu: "Kutjevo",
        naselje: "Jasik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2326",
        brojPu: "53230",
        redBroj: "2326",
        nazivPu: "Korenica",
        naselje: "Jasikovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2327",
        brojPu: "47220",
        redBroj: "2327",
        nazivPu: "Vojnić",
        naselje: "Jasnić Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2328",
        brojPu: "10450",
        redBroj: "2328",
        nazivPu: "Jastrebarsko",
        naselje: "Jastrebarsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2329",
        brojPu: "47280",
        redBroj: "2329",
        nazivPu: "Ozalj",
        naselje: "Jaškovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2330",
        brojPu: "44440",
        redBroj: "2330",
        nazivPu: "Dvor",
        naselje: "Javnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2331",
        brojPu: "10454",
        redBroj: "2331",
        nazivPu: "Krašić",
        naselje: "Javor",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2332",
        brojPu: "10430",
        redBroj: "2332",
        nazivPu: "Samobor",
        naselje: "Javorek",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2333",
        brojPu: "10291",
        redBroj: "2333",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Javorje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2334",
        brojPu: "51250",
        redBroj: "2334",
        nazivPu: "Novi Vinodolski",
        naselje: "Javorje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2335",
        brojPu: "44440",
        redBroj: "2335",
        nazivPu: "Dvor",
        naselje: "Javornik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2336",
        brojPu: "48350",
        redBroj: "2336",
        nazivPu: "Đurđevac",
        naselje: "Javorovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2337",
        brojPu: "44330",
        redBroj: "2337",
        nazivPu: "Novska",
        naselje: "Jazavica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2338",
        brojPu: "49216",
        redBroj: "2338",
        nazivPu: "Desinić",
        naselje: "Jazbina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2339",
        brojPu: "42250",
        redBroj: "2339",
        nazivPu: "Lepoglava",
        naselje: "Jazbina Cvetlinska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2340",
        brojPu: "42250",
        redBroj: "2340",
        nazivPu: "Lepoglava",
        naselje: "Jazbina Višnjička",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2341",
        brojPu: "44000",
        redBroj: "2341",
        nazivPu: "Sisak",
        naselje: "Jazvenik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2342",
        brojPu: "49000",
        redBroj: "2342",
        nazivPu: "Krapina",
        naselje: "Jazvine",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2343",
        brojPu: "48000",
        redBroj: "2343",
        nazivPu: "Koprivnica",
        naselje: "Jeduševac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2344",
        brojPu: "52440",
        redBroj: "2344",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jehnići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2345",
        brojPu: "42220",
        redBroj: "2345",
        nazivPu: "Novi Marof",
        naselje: "Jelenščak",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2346",
        brojPu: "49216",
        redBroj: "2346",
        nazivPu: "Desinić",
        naselje: "Jelenjak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2347",
        brojPu: "51218",
        redBroj: "2347",
        nazivPu: "Dražice",
        naselje: "Jelenje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2348",
        brojPu: "31500",
        redBroj: "2348",
        nazivPu: "Našice",
        naselje: "Jelisavac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2349",
        brojPu: "47240",
        redBroj: "2349",
        nazivPu: "Slunj",
        naselje: "Jelov Klanac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2350",
        brojPu: "49282",
        redBroj: "2350",
        nazivPu: "Konjščina",
        naselje: "Jelovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2351",
        brojPu: "42240",
        redBroj: "2351",
        nazivPu: "Ivanec",
        naselje: "Jelovec Voćanski",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2352",
        brojPu: "52420",
        redBroj: "2352",
        nazivPu: "Buzet",
        naselje: "Jelovice",
        zupanija: "ISTARSKA",
      },
      {
        id: "2353",
        brojPu: "21465",
        redBroj: "2353",
        nazivPu: "Jelsa",
        naselje: "Jelsa",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2354",
        brojPu: "34000",
        redBroj: "2354",
        nazivPu: "Požega",
        naselje: "Jeminovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2355",
        brojPu: "10410",
        redBroj: "2355",
        nazivPu: "Velika Gorica",
        naselje: "Jerebić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2356",
        brojPu: "42240",
        redBroj: "2356",
        nazivPu: "Ivanec",
        naselje: "Jerovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2357",
        brojPu: "49282",
        redBroj: "2357",
        nazivPu: "Konjščina",
        naselje: "Jertovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2358",
        brojPu: "21315",
        redBroj: "2358",
        nazivPu: "Dugi Rat",
        naselje: "Jesenice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2359",
        brojPu: "10363",
        redBroj: "2359",
        nazivPu: "Belovar",
        naselje: "Jesenovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2360",
        brojPu: "52333",
        redBroj: "2360",
        nazivPu: "Podpićan",
        naselje: "Jesenovik",
        zupanija: "ISTARSKA",
      },
      {
        id: "2361",
        brojPu: "22240",
        redBroj: "2361",
        nazivPu: "Tisno",
        naselje: "Jezera",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2362",
        brojPu: "53260",
        redBroj: "2362",
        nazivPu: "Brinje",
        naselje: "Jezerane",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2363",
        brojPu: "53230",
        redBroj: "2363",
        nazivPu: "Korenica",
        naselje: "Jezerce",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2364",
        brojPu: "10454",
        redBroj: "2364",
        nazivPu: "Krašić",
        naselje: "Jezerine",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2365",
        brojPu: "49225",
        redBroj: "2365",
        nazivPu: "Đurmanec",
        naselje: "Jezerišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2366",
        brojPu: "10454",
        redBroj: "2366",
        nazivPu: "Krašić",
        naselje: "Jezernice",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2367",
        brojPu: "34340",
        redBroj: "2367",
        nazivPu: "Kutjevo",
        naselje: "Jezero",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2368",
        brojPu: "47300",
        redBroj: "2368",
        nazivPu: "Ogulin",
        naselje: "Jezero",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2369",
        brojPu: "49210",
        redBroj: "2369",
        nazivPu: "Zabok",
        naselje: "Jezero Klanječko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2370",
        brojPu: "44000",
        redBroj: "2370",
        nazivPu: "Sisak",
        naselje: "Jezero Posavsko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2371",
        brojPu: "10250",
        redBroj: "2371",
        nazivPu: "Lučko",
        naselje: "Ježdovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2372",
        brojPu: "52000",
        redBroj: "2372",
        nazivPu: "Pazin",
        naselje: "Ježenj",
        zupanija: "ISTARSKA",
      },
      {
        id: "2373",
        brojPu: "21236",
        redBroj: "2373",
        nazivPu: "Vrlika",
        naselje: "Ježević",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2374",
        brojPu: "35000",
        redBroj: "2374",
        nazivPu: "Slavonski Brod",
        naselje: "Ježevik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2375",
        brojPu: "10370",
        redBroj: "2375",
        nazivPu: "Dugo Selo",
        naselje: "Ježevo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2376",
        brojPu: "42250",
        redBroj: "2376",
        nazivPu: "Lepoglava",
        naselje: "Ježovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2377",
        brojPu: "47250",
        redBroj: "2377",
        nazivPu: "Duga Resa",
        naselje: "Johi",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2378",
        brojPu: "10315",
        redBroj: "2378",
        nazivPu: "Novoselec",
        naselje: "Johovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2379",
        brojPu: "47220",
        redBroj: "2379",
        nazivPu: "Vojnić",
        naselje: "Johovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2380",
        brojPu: "47300",
        redBroj: "2380",
        nazivPu: "Ogulin",
        naselje: "Josipdol",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2381",
        brojPu: "31200",
        redBroj: "2381",
        nazivPu: "Osijek",
        naselje: "Josipovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2382",
        brojPu: "31400",
        redBroj: "2382",
        nazivPu: "Đakovo",
        naselje: "Josipovac Punitovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2383",
        brojPu: "33520",
        redBroj: "2383",
        nazivPu: "Slatina",
        naselje: "Josipovo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2384",
        brojPu: "53234",
        redBroj: "2384",
        nazivPu: "Udbina",
        naselje: "Jošan",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2385",
        brojPu: "44250",
        redBroj: "2385",
        nazivPu: "Petrinja",
        naselje: "Jošavica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2386",
        brojPu: "44400",
        redBroj: "2386",
        nazivPu: "Glina",
        naselje: "Joševica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2387",
        brojPu: "44440",
        redBroj: "2387",
        nazivPu: "Dvor",
        naselje: "Jovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2388",
        brojPu: "23248",
        redBroj: "2388",
        nazivPu: "Ražanac",
        naselje: "Jovići",
        zupanija: "ZADARSKA",
      },
      {
        id: "2389",
        brojPu: "47000",
        redBroj: "2389",
        nazivPu: "Karlovac",
        naselje: "Jugovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2390",
        brojPu: "33410",
        redBroj: "2390",
        nazivPu: "Suhopolje",
        naselje: "Jugovo Polje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2391",
        brojPu: "52420",
        redBroj: "2391",
        nazivPu: "Buzet",
        naselje: "Juradi",
        zupanija: "ISTARSKA",
      },
      {
        id: "2392",
        brojPu: "52341",
        redBroj: "2392",
        nazivPu: "Žminj",
        naselje: "Jural",
        zupanija: "ISTARSKA",
      },
      {
        id: "2393",
        brojPu: "51523",
        redBroj: "2393",
        nazivPu: "Baška",
        naselje: "Jurandvor",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2394",
        brojPu: "49250",
        redBroj: "2394",
        nazivPu: "Zlatar",
        naselje: "Juranšćina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2395",
        brojPu: "52220",
        redBroj: "2395",
        nazivPu: "Labin",
        naselje: "Jurazini",
        zupanija: "ISTARSKA",
      },
      {
        id: "2396",
        brojPu: "52440",
        redBroj: "2396",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jurcani",
        zupanija: "ISTARSKA",
      },
      {
        id: "2397",
        brojPu: "51211",
        redBroj: "2397",
        nazivPu: "Matulji",
        naselje: "Jurdani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2398",
        brojPu: "47220",
        redBroj: "2398",
        nazivPu: "Vojnić",
        naselje: "Jurga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2399",
        brojPu: "52470",
        redBroj: "2399",
        nazivPu: "Umag (Umago)",
        naselje: "Juricani - Giurizzani",
        zupanija: "ISTARSKA",
      },
      {
        id: "2400",
        brojPu: "52420",
        redBroj: "2400",
        nazivPu: "Buzet",
        naselje: "Juričići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2401",
        brojPu: "52207",
        redBroj: "2401",
        nazivPu: "Barban",
        naselje: "Jurićev Kal",
        zupanija: "ISTARSKA",
      },
      {
        id: "2402",
        brojPu: "52341",
        redBroj: "2402",
        nazivPu: "Žminj",
        naselje: "Jurići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2403",
        brojPu: "52440",
        redBroj: "2403",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Jurići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2404",
        brojPu: "31400",
        redBroj: "2404",
        nazivPu: "Đakovo",
        naselje: "Jurjevac Punitovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2405",
        brojPu: "10450",
        redBroj: "2405",
        nazivPu: "Jastrebarsko",
        naselje: "Jurjevčani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2406",
        brojPu: "49217",
        redBroj: "2406",
        nazivPu: "Krapinske Toplice",
        naselje: "Jurjevec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2407",
        brojPu: "42243",
        redBroj: "2407",
        nazivPu: "Maruševec",
        naselje: "Jurketinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2408",
        brojPu: "34340",
        redBroj: "2408",
        nazivPu: "Kutjevo",
        naselje: "Jurkovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2409",
        brojPu: "10454",
        redBroj: "2409",
        nazivPu: "Krašić",
        naselje: "Jurkovo Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2410",
        brojPu: "40313",
        redBroj: "2410",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Jurovčak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2411",
        brojPu: "40313",
        redBroj: "2411",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Jurovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2412",
        brojPu: "47000",
        redBroj: "2412",
        nazivPu: "Karlovac",
        naselje: "Jurovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2413",
        brojPu: "47000",
        redBroj: "2413",
        nazivPu: "Karlovac",
        naselje: "Jurovski Brod",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2414",
        brojPu: "52341",
        redBroj: "2414",
        nazivPu: "Žminj",
        naselje: "Juršići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2415",
        brojPu: "51211",
        redBroj: "2415",
        nazivPu: "Matulji",
        naselje: "Jušići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2416",
        brojPu: "10342",
        redBroj: "2416",
        nazivPu: "Dubrava",
        naselje: "Kabal",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2417",
        brojPu: "47000",
        redBroj: "2417",
        nazivPu: "Karlovac",
        naselje: "Kablar",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2418",
        brojPu: "49290",
        redBroj: "2418",
        nazivPu: "Klanjec",
        naselje: "Kačkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2419",
        brojPu: "34310",
        redBroj: "2419",
        nazivPu: "Pleternica",
        naselje: "Kadanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2420",
        brojPu: "22320",
        redBroj: "2420",
        nazivPu: "Drniš",
        naselje: "Kadina Glavica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2421",
        brojPu: "52440",
        redBroj: "2421",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kadumi",
        zupanija: "ISTARSKA",
      },
      {
        id: "2422",
        brojPu: "43280",
        redBroj: "2422",
        nazivPu: "Garešnica",
        naselje: "Kajgana",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2423",
        brojPu: "52420",
        redBroj: "2423",
        nazivPu: "Buzet",
        naselje: "Kajini",
        zupanija: "ISTARSKA",
      },
      {
        id: "2424",
        brojPu: "22300",
        redBroj: "2424",
        nazivPu: "Knin",
        naselje: "Kakanj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2425",
        brojPu: "43000",
        redBroj: "2425",
        nazivPu: "Bjelovar",
        naselje: "Kakinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2426",
        brojPu: "23210",
        redBroj: "2426",
        nazivPu: "Biograd na moru",
        naselje: "Kakma",
        zupanija: "ZADARSKA",
      },
      {
        id: "2427",
        brojPu: "51417",
        redBroj: "2427",
        nazivPu: "Mošćenička Draga",
        naselje: "Kalac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2428",
        brojPu: "52460",
        redBroj: "2428",
        nazivPu: "Buje (Buie)",
        naselje: "Kaldanija - Caldania",
        zupanija: "ISTARSKA",
      },
      {
        id: "2429",
        brojPu: "52424",
        redBroj: "2429",
        nazivPu: "Motovun (Montona)",
        naselje: "Kaldir - Caldier",
        zupanija: "ISTARSKA",
      },
      {
        id: "2430",
        brojPu: "53250",
        redBroj: "2430",
        nazivPu: "Donji Lapac",
        naselje: "Kaldrma",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2431",
        brojPu: "53230",
        redBroj: "2431",
        nazivPu: "Korenica",
        naselje: "Kalebovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2432",
        brojPu: "23273",
        redBroj: "2432",
        nazivPu: "Preko",
        naselje: "Kali",
        zupanija: "ZADARSKA",
      },
      {
        id: "2433",
        brojPu: "51300",
        redBroj: "2433",
        nazivPu: "Delnice",
        naselje: "Kalić",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2434",
        brojPu: "34310",
        redBroj: "2434",
        nazivPu: "Pleternica",
        naselje: "Kalinić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2435",
        brojPu: "48350",
        redBroj: "2435",
        nazivPu: "Đurđevac",
        naselje: "Kalinovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2436",
        brojPu: "53000",
        redBroj: "2436",
        nazivPu: "Gospić",
        naselje: "Kalinovača",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2437",
        brojPu: "10436",
        redBroj: "2437",
        nazivPu: "Rakov Potok",
        naselje: "Kalinovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2438",
        brojPu: "10380",
        redBroj: "2438",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Kalinje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2439",
        brojPu: "48260",
        redBroj: "2439",
        nazivPu: "Križevci",
        naselje: "Kalnik",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2440",
        brojPu: "53202",
        redBroj: "2440",
        nazivPu: "Perušić",
        naselje: "Kaluđerovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2441",
        brojPu: "10454",
        redBroj: "2441",
        nazivPu: "Krašić",
        naselje: "Kalje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2442",
        brojPu: "47280",
        redBroj: "2442",
        nazivPu: "Ozalj",
        naselje: "Kamanje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2443",
        brojPu: "21000",
        redBroj: "2443",
        nazivPu: "Split",
        naselje: "Kamen",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2444",
        brojPu: "42220",
        redBroj: "2444",
        nazivPu: "Novi Marof",
        naselje: "Kamena Gorica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2445",
        brojPu: "31300",
        redBroj: "2445",
        nazivPu: "Beli Manastir",
        naselje: "Kamenac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2446",
        brojPu: "47280",
        redBroj: "2446",
        nazivPu: "Ozalj",
        naselje: "Kamenci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2447",
        brojPu: "10340",
        redBroj: "2447",
        nazivPu: "Vrbovec",
        naselje: "Kamenica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2448",
        brojPu: "42250",
        redBroj: "2448",
        nazivPu: "Lepoglava",
        naselje: "Kamenica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2449",
        brojPu: "48000",
        redBroj: "2449",
        nazivPu: "Koprivnica",
        naselje: "Kamenica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2450",
        brojPu: "47300",
        redBroj: "2450",
        nazivPu: "Ogulin",
        naselje: "Kamenica Skradnička",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2451",
        brojPu: "42250",
        redBroj: "2451",
        nazivPu: "Lepoglava",
        naselje: "Kamenički Vrhovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2452",
        brojPu: "42250",
        redBroj: "2452",
        nazivPu: "Lepoglava",
        naselje: "Kameničko Podgorje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2453",
        brojPu: "21260",
        redBroj: "2453",
        nazivPu: "Imotski",
        naselje: "Kamenmost",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2454",
        brojPu: "34000",
        redBroj: "2454",
        nazivPu: "Požega",
        naselje: "Kamenska",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2455",
        brojPu: "51306",
        redBroj: "2455",
        nazivPu: "Čabar",
        naselje: "Kamenski Hrib",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2456",
        brojPu: "34000",
        redBroj: "2456",
        nazivPu: "Požega",
        naselje: "Kamenski Šeovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2457",
        brojPu: "34000",
        redBroj: "2457",
        nazivPu: "Požega",
        naselje: "Kamenski Vučjak",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2458",
        brojPu: "21256",
        redBroj: "2458",
        nazivPu: "Cista Provo",
        naselje: "Kamensko",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2459",
        brojPu: "51326",
        redBroj: "2459",
        nazivPu: "Vrbovsko",
        naselje: "Kamensko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2460",
        brojPu: "48260",
        redBroj: "2460",
        nazivPu: "Križevci",
        naselje: "Kamešnica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2461",
        brojPu: "51500",
        redBroj: "2461",
        nazivPu: "Krk",
        naselje: "Kampelje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2462",
        brojPu: "51280",
        redBroj: "2462",
        nazivPu: "Rab",
        naselje: "Kampor",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2463",
        brojPu: "52470",
        redBroj: "2463",
        nazivPu: "Umag (Umago)",
        naselje: "Kanegra - Canegra",
        zupanija: "ISTARSKA",
      },
      {
        id: "2464",
        brojPu: "52341",
        redBroj: "2464",
        nazivPu: "Žminj",
        naselje: "Kanfanar",
        zupanija: "ISTARSKA",
      },
      {
        id: "2465",
        brojPu: "22202",
        redBroj: "2465",
        nazivPu: "Primošten",
        naselje: "Kanica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2466",
        brojPu: "43280",
        redBroj: "2466",
        nazivPu: "Garešnica",
        naselje: "Kaniška Iva",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2467",
        brojPu: "35000",
        redBroj: "2467",
        nazivPu: "Slavonski Brod",
        naselje: "Kaniža",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2468",
        brojPu: "42240",
        redBroj: "2468",
        nazivPu: "Ivanec",
        naselje: "Kaniža",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2469",
        brojPu: "53000",
        redBroj: "2469",
        nazivPu: "Gospić",
        naselje: "Kaniža Gospićka",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2470",
        brojPu: "34000",
        redBroj: "2470",
        nazivPu: "Požega",
        naselje: "Kantrovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2471",
        brojPu: "22320",
        redBroj: "2471",
        nazivPu: "Drniš",
        naselje: "Kanjane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2472",
        brojPu: "22320",
        redBroj: "2472",
        nazivPu: "Drniš",
        naselje: "Kaočine",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2473",
        brojPu: "43000",
        redBroj: "2473",
        nazivPu: "Bjelovar",
        naselje: "Kapela",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2474",
        brojPu: "33000",
        redBroj: "2474",
        nazivPu: "Virovitica",
        naselje: "Kapela Dvor",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2475",
        brojPu: "42220",
        redBroj: "2475",
        nazivPu: "Novi Marof",
        naselje: "Kapela Kalnička",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2476",
        brojPu: "53230",
        redBroj: "2476",
        nazivPu: "Korenica",
        naselje: "Kapela Korenička",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2477",
        brojPu: "42230",
        redBroj: "2477",
        nazivPu: "Ludbreg",
        naselje: "Kapela Podravska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2478",
        brojPu: "48260",
        redBroj: "2478",
        nazivPu: "Križevci",
        naselje: "Kapela Ravenska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2479",
        brojPu: "42243",
        redBroj: "2479",
        nazivPu: "Maruševec",
        naselje: "Kapelec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2480",
        brojPu: "43280",
        redBroj: "2480",
        nazivPu: "Garešnica",
        naselje: "Kapelica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2481",
        brojPu: "52220",
        redBroj: "2481",
        nazivPu: "Labin",
        naselje: "Kapelica",
        zupanija: "ISTARSKA",
      },
      {
        id: "2482",
        brojPu: "31540",
        redBroj: "2482",
        nazivPu: "Donji Miholjac",
        naselje: "Kapelna",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2483",
        brojPu: "49290",
        redBroj: "2483",
        nazivPu: "Klanjec",
        naselje: "Kapelski Vrh",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2484",
        brojPu: "40313",
        redBroj: "2484",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Kapelščak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2485",
        brojPu: "34550",
        redBroj: "2485",
        nazivPu: "Pakrac",
        naselje: "Kapetanovo Polje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2486",
        brojPu: "33520",
        redBroj: "2486",
        nazivPu: "Slatina",
        naselje: "Kapinci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2487",
        brojPu: "47240",
        redBroj: "2487",
        nazivPu: "Slunj",
        naselje: "Kapljuv",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2488",
        brojPu: "52440",
        redBroj: "2488",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kapovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2489",
        brojPu: "22235",
        redBroj: "2489",
        nazivPu: "Kaprije",
        naselje: "Kaprije",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2490",
        brojPu: "34000",
        redBroj: "2490",
        nazivPu: "Požega",
        naselje: "Kaptol",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2491",
        brojPu: "32100",
        redBroj: "2491",
        nazivPu: "Vinkovci",
        naselje: "Karadžićevo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2492",
        brojPu: "21230",
        redBroj: "2492",
        nazivPu: "Sinj",
        naselje: "Karakašica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2493",
        brojPu: "22320",
        redBroj: "2493",
        nazivPu: "Drniš",
        naselje: "Karalić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2494",
        brojPu: "31300",
        redBroj: "2494",
        nazivPu: "Beli Manastir",
        naselje: "Karanac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2495",
        brojPu: "48260",
        redBroj: "2495",
        nazivPu: "Križevci",
        naselje: "Karane",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2496",
        brojPu: "47000",
        redBroj: "2496",
        nazivPu: "Karlovac",
        naselje: "Karasi",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2497",
        brojPu: "52466",
        redBroj: "2497",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Karigador -Carigador",
        zupanija: "ISTARSKA",
      },
      {
        id: "2498",
        brojPu: "49240",
        redBroj: "2498",
        nazivPu: "Donja Stubica",
        naselje: "Karivaroš",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2499",
        brojPu: "53288",
        redBroj: "2499",
        nazivPu: "Karlobag",
        naselje: "Karlobag",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2500",
        brojPu: "47000",
        redBroj: "2500",
        nazivPu: "Karlovac",
        naselje: "Karlovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2501",
        brojPu: "33515",
        redBroj: "2501",
        nazivPu: "Orahovica",
        naselje: "Karlovac Feričanački",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2502",
        brojPu: "42230",
        redBroj: "2502",
        nazivPu: "Ludbreg",
        naselje: "Karlovec Ludbreški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2503",
        brojPu: "52341",
        redBroj: "2503",
        nazivPu: "Žminj",
        naselje: "Karlovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2504",
        brojPu: "52424",
        redBroj: "2504",
        nazivPu: "Motovun (Montona)",
        naselje: "Karojba",
        zupanija: "ISTARSKA",
      },
      {
        id: "2505",
        brojPu: "47220",
        redBroj: "2505",
        nazivPu: "Vojnić",
        naselje: "Kartalije",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2506",
        brojPu: "51215",
        redBroj: "2506",
        nazivPu: "Kastav",
        naselje: "Kastav",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2507",
        brojPu: "47250",
        redBroj: "2507",
        nazivPu: "Duga Resa",
        naselje: "Kasuni",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2508",
        brojPu: "52000",
        redBroj: "2508",
        nazivPu: "Pazin",
        naselje: "Kašćerga",
        zupanija: "ISTARSKA",
      },
      {
        id: "2509",
        brojPu: "22213",
        redBroj: "2509",
        nazivPu: "Pirovac",
        naselje: "Kašić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2510",
        brojPu: "10362",
        redBroj: "2510",
        nazivPu: "Kašina",
        naselje: "Kašina",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2511",
        brojPu: "10362",
        redBroj: "2511",
        nazivPu: "Kašina",
        naselje: "Kašinska Sopnica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2512",
        brojPu: "43000",
        redBroj: "2512",
        nazivPu: "Bjelovar",
        naselje: "Kašljavac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2513",
        brojPu: "47280",
        redBroj: "2513",
        nazivPu: "Ozalj",
        naselje: "Kašt",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2514",
        brojPu: "52460",
        redBroj: "2514",
        nazivPu: "Buje (Buie)",
        naselje: "Kaštel - Castelvenere",
        zupanija: "ISTARSKA",
      },
      {
        id: "2515",
        brojPu: "43500",
        redBroj: "2515",
        nazivPu: "Daruvar",
        naselje: "Kaštel Dežanovački",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2516",
        brojPu: "21214",
        redBroj: "2516",
        nazivPu: "Kaštel Kambelovac",
        naselje: "Kaštel Gomilica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2517",
        brojPu: "21214",
        redBroj: "2517",
        nazivPu: "Kaštel Kambelovac",
        naselje: "Kaštel Kambelovac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2518",
        brojPu: "21214",
        redBroj: "2518",
        nazivPu: "Kaštel Kambelovac",
        naselje: "Kaštel Lukšić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2519",
        brojPu: "21217",
        redBroj: "2519",
        nazivPu: "Kaštel Štafilić",
        naselje: "Kaštel Novi",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2520",
        brojPu: "21217",
        redBroj: "2520",
        nazivPu: "Kaštel Štafilić",
        naselje: "Kaštel Stari",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2521",
        brojPu: "21212",
        redBroj: "2521",
        nazivPu: "Kaštel Sućurac",
        naselje: "Kaštel Sućurac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2522",
        brojPu: "21217",
        redBroj: "2522",
        nazivPu: "Kaštel Štafilić",
        naselje: "Kaštel Štafilić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2523",
        brojPu: "23450",
        redBroj: "2523",
        nazivPu: "Obrovac",
        naselje: "Kaštel Žegarski",
        zupanija: "ZADARSKA",
      },
      {
        id: "2524",
        brojPu: "42204",
        redBroj: "2524",
        nazivPu: "Turčin",
        naselje: "Kaštelanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2525",
        brojPu: "52440",
        redBroj: "2525",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kaštelir -Castelliere",
        zupanija: "ISTARSKA",
      },
      {
        id: "2526",
        brojPu: "43500",
        redBroj: "2526",
        nazivPu: "Daruvar",
        naselje: "Katinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2527",
        brojPu: "33000",
        redBroj: "2527",
        nazivPu: "Virovitica",
        naselje: "Katinka",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2528",
        brojPu: "44400",
        redBroj: "2528",
        nazivPu: "Glina",
        naselje: "Katinovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2529",
        brojPu: "44320",
        redBroj: "2529",
        nazivPu: "Kutina",
        naselje: "Katoličke Čaire",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2530",
        brojPu: "44317",
        redBroj: "2530",
        nazivPu: "Popovača",
        naselje: "Katoličko Selišće",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2531",
        brojPu: "52470",
        redBroj: "2531",
        nazivPu: "Umag (Umago)",
        naselje: "Katoro - Catoro",
        zupanija: "ISTARSKA",
      },
      {
        id: "2532",
        brojPu: "52440",
        redBroj: "2532",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Katun",
        zupanija: "ISTARSKA",
      },
      {
        id: "2533",
        brojPu: "21250",
        redBroj: "2533",
        nazivPu: "Šestanovac",
        naselje: "Katuni",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2534",
        brojPu: "52207",
        redBroj: "2534",
        nazivPu: "Barban",
        naselje: "Kavran",
        zupanija: "ISTARSKA",
      },
      {
        id: "2535",
        brojPu: "51326",
        redBroj: "2535",
        nazivPu: "Vrbovsko",
        naselje: "Kavrani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2536",
        brojPu: "49221",
        redBroj: "2536",
        nazivPu: "Bedekovčina",
        naselje: "Kebel",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2537",
        brojPu: "43000",
        redBroj: "2537",
        nazivPu: "Bjelovar",
        naselje: "Kegljevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2538",
        brojPu: "47250",
        redBroj: "2538",
        nazivPu: "Duga Resa",
        naselje: "Keići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2539",
        brojPu: "52440",
        redBroj: "2539",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kelci",
        zupanija: "ISTARSKA",
      },
      {
        id: "2540",
        brojPu: "42202",
        redBroj: "2540",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Kelemen",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2541",
        brojPu: "10380",
        redBroj: "2541",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Keleminovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2542",
        brojPu: "31500",
        redBroj: "2542",
        nazivPu: "Našice",
        naselje: "Kelešinka",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2543",
        brojPu: "48214",
        redBroj: "2543",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Kenđelovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2544",
        brojPu: "44440",
        redBroj: "2544",
        nazivPu: "Dvor",
        naselje: "Kepčije",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2545",
        brojPu: "10431",
        redBroj: "2545",
        nazivPu: "Sveta Nedelja",
        naselje: "Kerestinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2546",
        brojPu: "47280",
        redBroj: "2546",
        nazivPu: "Ozalj",
        naselje: "Keseri",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2547",
        brojPu: "47220",
        redBroj: "2547",
        nazivPu: "Vojnić",
        naselje: "Keserov Potok",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2548",
        brojPu: "47250",
        redBroj: "2548",
        nazivPu: "Duga Resa",
        naselje: "Kestenak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2549",
        brojPu: "47220",
        redBroj: "2549",
        nazivPu: "Vojnić",
        naselje: "Kestenovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2550",
        brojPu: "53250",
        redBroj: "2550",
        nazivPu: "Donji Lapac",
        naselje: "Kestenovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2551",
        brojPu: "47240",
        redBroj: "2551",
        nazivPu: "Slunj",
        naselje: "Kestenje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2552",
        brojPu: "31400",
        redBroj: "2552",
        nazivPu: "Đakovo",
        naselje: "Kešinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2553",
        brojPu: "44400",
        redBroj: "2553",
        nazivPu: "Glina",
        naselje: "Kihalac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2554",
        brojPu: "23440",
        redBroj: "2554",
        nazivPu: "Gračac",
        naselje: "Kijani",
        zupanija: "ZADARSKA",
      },
      {
        id: "2555",
        brojPu: "22300",
        redBroj: "2555",
        nazivPu: "Knin",
        naselje: "Kijevo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2556",
        brojPu: "23440",
        redBroj: "2556",
        nazivPu: "Gračac",
        naselje: "Kik",
        zupanija: "ZADARSKA",
      },
      {
        id: "2557",
        brojPu: "35000",
        redBroj: "2557",
        nazivPu: "Slavonski Brod",
        naselje: "Kindrovo",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2558",
        brojPu: "44210",
        redBroj: "2558",
        nazivPu: "Sunja",
        naselje: "Kinjačka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2559",
        brojPu: "43500",
        redBroj: "2559",
        nazivPu: "Daruvar",
        naselje: "Kip",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2560",
        brojPu: "44410",
        redBroj: "2560",
        nazivPu: "Vrginmost",
        naselje: "Kirin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2561",
        brojPu: "52440",
        redBroj: "2561",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kirmenjak",
        zupanija: "ISTARSKA",
      },
      {
        id: "2562",
        brojPu: "22300",
        redBroj: "2562",
        nazivPu: "Knin",
        naselje: "Kistanje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2563",
        brojPu: "31551",
        redBroj: "2563",
        nazivPu: "Belišće",
        naselje: "Kitišanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2564",
        brojPu: "53270",
        redBroj: "2564",
        nazivPu: "Senj",
        naselje: "Klada",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2565",
        brojPu: "33405",
        redBroj: "2565",
        nazivPu: "Pitomača",
        naselje: "Kladare",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2566",
        brojPu: "44210",
        redBroj: "2566",
        nazivPu: "Sunja",
        naselje: "Kladari",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2567",
        brojPu: "10380",
        redBroj: "2567",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Kladešćica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2568",
        brojPu: "10430",
        redBroj: "2568",
        nazivPu: "Samobor",
        naselje: "Kladje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2569",
        brojPu: "49290",
        redBroj: "2569",
        nazivPu: "Klanjec",
        naselje: "Kladnik",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2570",
        brojPu: "21204",
        redBroj: "2570",
        nazivPu: "Dugopolje",
        naselje: "Kladnjice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2571",
        brojPu: "35000",
        redBroj: "2571",
        nazivPu: "Slavonski Brod",
        naselje: "Klakar",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2572",
        brojPu: "10435",
        redBroj: "2572",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Klake",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2573",
        brojPu: "51217",
        redBroj: "2573",
        nazivPu: "Klana",
        naselje: "Klana",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2574",
        brojPu: "51326",
        redBroj: "2574",
        nazivPu: "Vrbovsko",
        naselje: "Klanac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2575",
        brojPu: "53000",
        redBroj: "2575",
        nazivPu: "Gospić",
        naselje: "Klanac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2576",
        brojPu: "47240",
        redBroj: "2576",
        nazivPu: "Slunj",
        naselje: "Klanac Perjasički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2577",
        brojPu: "51511",
        redBroj: "2577",
        nazivPu: "Malinska",
        naselje: "Klanice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2578",
        brojPu: "49290",
        redBroj: "2578",
        nazivPu: "Klanjec",
        naselje: "Klanjec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2579",
        brojPu: "49216",
        redBroj: "2579",
        nazivPu: "Desinić",
        naselje: "Klanječno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2580",
        brojPu: "52420",
        redBroj: "2580",
        nazivPu: "Buzet",
        naselje: "Klarići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2581",
        brojPu: "53230",
        redBroj: "2581",
        nazivPu: "Korenica",
        naselje: "Klašnjica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2582",
        brojPu: "20355",
        redBroj: "2582",
        nazivPu: "Opuzen",
        naselje: "Klek",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2583",
        brojPu: "49218",
        redBroj: "2583",
        nazivPu: "Pregrada",
        naselje: "Klenice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2584",
        brojPu: "53202",
        redBroj: "2584",
        nazivPu: "Perušić",
        naselje: "Klenovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2585",
        brojPu: "49231",
        redBroj: "2585",
        nazivPu: "Hum na Sutli",
        naselje: "Klenovec Humski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2586",
        brojPu: "51250",
        redBroj: "2586",
        nazivPu: "Novi Vinodolski",
        naselje: "Klenovica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2587",
        brojPu: "42240",
        redBroj: "2587",
        nazivPu: "Ivanec",
        naselje: "Klenovnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2588",
        brojPu: "52420",
        redBroj: "2588",
        nazivPu: "Buzet",
        naselje: "Klenovšćak",
        zupanija: "ISTARSKA",
      },
      {
        id: "2589",
        brojPu: "51326",
        redBroj: "2589",
        nazivPu: "Vrbovsko",
        naselje: "Klepeće Selo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2590",
        brojPu: "44320",
        redBroj: "2590",
        nazivPu: "Kutina",
        naselje: "Kletište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2591",
        brojPu: "49282",
        redBroj: "2591",
        nazivPu: "Konjščina",
        naselje: "Klimen",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2592",
        brojPu: "52341",
        redBroj: "2592",
        nazivPu: "Žminj",
        naselje: "Klimni",
        zupanija: "ISTARSKA",
      },
      {
        id: "2593",
        brojPu: "51511",
        redBroj: "2593",
        nazivPu: "Malinska",
        naselje: "Klimno",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2594",
        brojPu: "44250",
        redBroj: "2594",
        nazivPu: "Petrinja",
        naselje: "Klinac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2595",
        brojPu: "10450",
        redBroj: "2595",
        nazivPu: "Jastrebarsko",
        naselje: "Klinča Sela",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2596",
        brojPu: "47000",
        redBroj: "2596",
        nazivPu: "Karlovac",
        naselje: "Klipino Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2597",
        brojPu: "21204",
        redBroj: "2597",
        nazivPu: "Dugopolje",
        naselje: "Klis",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2598",
        brojPu: "31207",
        redBroj: "2598",
        nazivPu: "Tenja",
        naselje: "Klisa",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2599",
        brojPu: "34000",
        redBroj: "2599",
        nazivPu: "Požega",
        naselje: "Klisa",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2600",
        brojPu: "34550",
        redBroj: "2600",
        nazivPu: "Pakrac",
        naselje: "Klisa",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2601",
        brojPu: "20232",
        redBroj: "2601",
        nazivPu: "Slano",
        naselje: "Kliševo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2602",
        brojPu: "44010",
        redBroj: "2602",
        nazivPu: "Sisak-Caprag",
        naselje: "Klobučak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2603",
        brojPu: "47220",
        redBroj: "2603",
        nazivPu: "Vojnić",
        naselje: "Klokoč",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2604",
        brojPu: "43000",
        redBroj: "2604",
        nazivPu: "Bjelovar",
        naselje: "Klokočevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2605",
        brojPu: "31500",
        redBroj: "2605",
        nazivPu: "Našice",
        naselje: "Klokočevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2606",
        brojPu: "10430",
        redBroj: "2606",
        nazivPu: "Samobor",
        naselje: "Klokočevec Samoborski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2607",
        brojPu: "35212",
        redBroj: "2607",
        nazivPu: "Garčin",
        naselje: "Klokočevik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2608",
        brojPu: "49217",
        redBroj: "2608",
        nazivPu: "Krapinske Toplice",
        naselje: "Klokovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2609",
        brojPu: "52440",
        redBroj: "2609",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kloštar",
        zupanija: "ISTARSKA",
      },
      {
        id: "2610",
        brojPu: "10310",
        redBroj: "2610",
        nazivPu: "Ivanić Grad",
        naselje: "Kloštar Ivanić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2611",
        brojPu: "48350",
        redBroj: "2611",
        nazivPu: "Đurđevac",
        naselje: "Kloštar Podravski",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2612",
        brojPu: "48260",
        redBroj: "2612",
        nazivPu: "Križevci",
        naselje: "Kloštar Vojakovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2613",
        brojPu: "49217",
        redBroj: "2613",
        nazivPu: "Krapinske Toplice",
        naselje: "Klupci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2614",
        brojPu: "49223",
        redBroj: "2614",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Klupci Začretski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2615",
        brojPu: "47220",
        redBroj: "2615",
        nazivPu: "Vojnić",
        naselje: "Klupica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2616",
        brojPu: "47220",
        redBroj: "2616",
        nazivPu: "Vojnić",
        naselje: "Kljaić Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2617",
        brojPu: "22320",
        redBroj: "2617",
        nazivPu: "Drniš",
        naselje: "Kljake",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2618",
        brojPu: "21276",
        redBroj: "2618",
        nazivPu: "Vrgorac",
        naselje: "Kljenak",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2619",
        brojPu: "22320",
        redBroj: "2619",
        nazivPu: "Drniš",
        naselje: "Ključ",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2620",
        brojPu: "42220",
        redBroj: "2620",
        nazivPu: "Novi Marof",
        naselje: "Ključ",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2621",
        brojPu: "10292",
        redBroj: "2621",
        nazivPu: "Šenkovec",
        naselje: "Ključ Brdovečki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2622",
        brojPu: "47220",
        redBroj: "2622",
        nazivPu: "Vojnić",
        naselje: "Ključar",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2623",
        brojPu: "10413",
        redBroj: "2623",
        nazivPu: "Kravarsko",
        naselje: "Ključić Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2624",
        brojPu: "52470",
        redBroj: "2624",
        nazivPu: "Umag (Umago)",
        naselje: "Kmeti - Metti",
        zupanija: "ISTARSKA",
      },
      {
        id: "2625",
        brojPu: "42240",
        redBroj: "2625",
        nazivPu: "Ivanec",
        naselje: "Knapić",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2626",
        brojPu: "52440",
        redBroj: "2626",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Knapići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2627",
        brojPu: "47000",
        redBroj: "2627",
        nazivPu: "Karlovac",
        naselje: "Knez Gorica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2628",
        brojPu: "40000",
        redBroj: "2628",
        nazivPu: "Čakovec",
        naselje: "Knezovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2629",
        brojPu: "44430",
        redBroj: "2629",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Knezovljani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2630",
        brojPu: "34310",
        redBroj: "2630",
        nazivPu: "Pleternica",
        naselje: "Knežci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2631",
        brojPu: "34340",
        redBroj: "2631",
        nazivPu: "Kutjevo",
        naselje: "Kneževac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2632",
        brojPu: "31300",
        redBroj: "2632",
        nazivPu: "Beli Manastir",
        naselje: "Kneževi Vinogradi",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2633",
        brojPu: "47220",
        redBroj: "2633",
        nazivPu: "Vojnić",
        naselje: "Knežević Kosa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2634",
        brojPu: "31300",
        redBroj: "2634",
        nazivPu: "Beli Manastir",
        naselje: "Kneževo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2635",
        brojPu: "20236",
        redBroj: "2635",
        nazivPu: "Mokošica",
        naselje: "Knežica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2636",
        brojPu: "22300",
        redBroj: "2636",
        nazivPu: "Knin",
        naselje: "Knin",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2637",
        brojPu: "22300",
        redBroj: "2637",
        nazivPu: "Knin",
        naselje: "Kninsko Polje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2638",
        brojPu: "43000",
        redBroj: "2638",
        nazivPu: "Bjelovar",
        naselje: "Kobasičari",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2639",
        brojPu: "10410",
        redBroj: "2639",
        nazivPu: "Velika Gorica",
        naselje: "Kobilić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2640",
        brojPu: "47000",
        redBroj: "2640",
        nazivPu: "Karlovac",
        naselje: "Kobilić Pokupski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2641",
        brojPu: "20350",
        redBroj: "2641",
        nazivPu: "Metković",
        naselje: "Kobiljača",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2642",
        brojPu: "44400",
        redBroj: "2642",
        nazivPu: "Glina",
        naselje: "Kobiljak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2643",
        brojPu: "51326",
        redBroj: "2643",
        nazivPu: "Vrbovsko",
        naselje: "Kocijani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2644",
        brojPu: "51300",
        redBroj: "2644",
        nazivPu: "Delnice",
        naselje: "Kočičin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2645",
        brojPu: "52440",
        redBroj: "2645",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kočići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2646",
        brojPu: "47000",
        redBroj: "2646",
        nazivPu: "Karlovac",
        naselje: "Kohanjac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2647",
        brojPu: "43000",
        redBroj: "2647",
        nazivPu: "Bjelovar",
        naselje: "Kokinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2648",
        brojPu: "47220",
        redBroj: "2648",
        nazivPu: "Vojnić",
        naselje: "Kokirevo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2649",
        brojPu: "33515",
        redBroj: "2649",
        nazivPu: "Orahovica",
        naselje: "Kokočak",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2650",
        brojPu: "21276",
        redBroj: "2650",
        nazivPu: "Vrgorac",
        naselje: "Kokorići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2651",
        brojPu: "23250",
        redBroj: "2651",
        nazivPu: "Pag",
        naselje: "Kolan",
        zupanija: "ZADARSKA",
      },
      {
        id: "2652",
        brojPu: "53291",
        redBroj: "2652",
        nazivPu: "Novalja",
        naselje: "Kolanjski Gajac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2653",
        brojPu: "48260",
        redBroj: "2653",
        nazivPu: "Križevci",
        naselje: "Kolarec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2654",
        brojPu: "43000",
        redBroj: "2654",
        nazivPu: "Bjelovar",
        naselje: "Kolarevo Selo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2655",
        brojPu: "47220",
        redBroj: "2655",
        nazivPu: "Vojnić",
        naselje: "Kolarić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2656",
        brojPu: "23422",
        redBroj: "2656",
        nazivPu: "Stankovci",
        naselje: "Kolarina",
        zupanija: "ZADARSKA",
      },
      {
        id: "2657",
        brojPu: "42208",
        redBroj: "2657",
        nazivPu: "Cestica",
        naselje: "Kolarovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2658",
        brojPu: "22300",
        redBroj: "2658",
        nazivPu: "Knin",
        naselje: "Kolašac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2659",
        brojPu: "48000",
        redBroj: "2659",
        nazivPu: "Koprivnica",
        naselje: "Koledinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2660",
        brojPu: "10340",
        redBroj: "2660",
        nazivPu: "Vrbovec",
        naselje: "Kolenica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2661",
        brojPu: "47000",
        redBroj: "2661",
        nazivPu: "Karlovac",
        naselje: "Kolenovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2662",
        brojPu: "20221",
        redBroj: "2662",
        nazivPu: "Koločep",
        naselje: "Koločep",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2663",
        brojPu: "52440",
        redBroj: "2663",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kolumbera",
        zupanija: "ISTARSKA",
      },
      {
        id: "2664",
        brojPu: "21236",
        redBroj: "2664",
        nazivPu: "Vrlika",
        naselje: "Koljane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2665",
        brojPu: "23440",
        redBroj: "2665",
        nazivPu: "Gračac",
        naselje: "Kom",
        zupanija: "ZADARSKA",
      },
      {
        id: "2666",
        brojPu: "20215",
        redBroj: "2666",
        nazivPu: "Gruda",
        naselje: "Komaji",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2667",
        brojPu: "20355",
        redBroj: "2667",
        nazivPu: "Opuzen",
        naselje: "Komarna",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2668",
        brojPu: "35420",
        redBroj: "2668",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Komarnica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2669",
        brojPu: "42230",
        redBroj: "2669",
        nazivPu: "Ludbreg",
        naselje: "Komarnica Ludbreška",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2670",
        brojPu: "34000",
        redBroj: "2670",
        nazivPu: "Požega",
        naselje: "Komarovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2671",
        brojPu: "48316",
        redBroj: "2671",
        nazivPu: "Đelekovec",
        naselje: "Komatnica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2672",
        brojPu: "23450",
        redBroj: "2672",
        nazivPu: "Obrovac",
        naselje: "Komazeci",
        zupanija: "ZADARSKA",
      },
      {
        id: "2673",
        brojPu: "47240",
        redBroj: "2673",
        nazivPu: "Slunj",
        naselje: "Komesarac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2674",
        brojPu: "33520",
        redBroj: "2674",
        nazivPu: "Slatina",
        naselje: "Kometnik-Jorgići",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2675",
        brojPu: "33520",
        redBroj: "2675",
        nazivPu: "Slatina",
        naselje: "Kometnik-Zubići",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2676",
        brojPu: "53234",
        redBroj: "2676",
        nazivPu: "Udbina",
        naselje: "Komić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2677",
        brojPu: "10380",
        redBroj: "2677",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Komin",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2678",
        brojPu: "20340",
        redBroj: "2678",
        nazivPu: "Ploče",
        naselje: "Komin",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2679",
        brojPu: "21485",
        redBroj: "2679",
        nazivPu: "Komiža",
        naselje: "Komiža",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2680",
        brojPu: "51326",
        redBroj: "2680",
        nazivPu: "Vrbovsko",
        naselje: "Komlenići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2681",
        brojPu: "32252",
        redBroj: "2681",
        nazivPu: "Otok",
        naselje: "Komletinci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "2682",
        brojPu: "44430",
        redBroj: "2682",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Komogovina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2683",
        brojPu: "20236",
        redBroj: "2683",
        nazivPu: "Mokošica",
        naselje: "Komolac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2684",
        brojPu: "49223",
        redBroj: "2684",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Komor Začretski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2685",
        brojPu: "44440",
        redBroj: "2685",
        nazivPu: "Dvor",
        naselje: "Komora",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2686",
        brojPu: "34310",
        redBroj: "2686",
        nazivPu: "Pleternica",
        naselje: "Komorica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2687",
        brojPu: "52420",
        redBroj: "2687",
        nazivPu: "Buzet",
        naselje: "Kompanj",
        zupanija: "ISTARSKA",
      },
      {
        id: "2688",
        brojPu: "44317",
        redBroj: "2688",
        nazivPu: "Popovača",
        naselje: "Kompator",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2689",
        brojPu: "53220",
        redBroj: "2689",
        nazivPu: "Otočac",
        naselje: "Kompolje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2690",
        brojPu: "53230",
        redBroj: "2690",
        nazivPu: "Korenica",
        naselje: "Kompolje Koreničko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2691",
        brojPu: "43240",
        redBroj: "2691",
        nazivPu: "Čazma",
        naselje: "Komuševac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2692",
        brojPu: "34000",
        redBroj: "2692",
        nazivPu: "Požega",
        naselje: "Komušina",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2693",
        brojPu: "10340",
        redBroj: "2693",
        nazivPu: "Vrbovec",
        naselje: "Konak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2694",
        brojPu: "43500",
        redBroj: "2694",
        nazivPu: "Daruvar",
        naselje: "Končanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2695",
        brojPu: "53230",
        redBroj: "2695",
        nazivPu: "Korenica",
        naselje: "Končarev Kraj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2696",
        brojPu: "31400",
        redBroj: "2696",
        nazivPu: "Đakovo",
        naselje: "Kondrić",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2697",
        brojPu: "10315",
        redBroj: "2697",
        nazivPu: "Novoselec",
        naselje: "Konšćani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2698",
        brojPu: "10435",
        redBroj: "2698",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Konšćica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2699",
        brojPu: "52440",
        redBroj: "2699",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kontešići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2700",
        brojPu: "10454",
        redBroj: "2700",
        nazivPu: "Krašić",
        naselje: "Konjarić Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2701",
        brojPu: "22222",
        redBroj: "2701",
        nazivPu: "Skradin",
        naselje: "Konjevrate",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2702",
        brojPu: "47000",
        redBroj: "2702",
        nazivPu: "Karlovac",
        naselje: "Konjkovsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2703",
        brojPu: "21204",
        redBroj: "2703",
        nazivPu: "Dugopolje",
        naselje: "Konjsko",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2704",
        brojPu: "53288",
        redBroj: "2704",
        nazivPu: "Karlobag",
        naselje: "Konjsko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2705",
        brojPu: "53202",
        redBroj: "2705",
        nazivPu: "Perušić",
        naselje: "Konjsko Brdo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2706",
        brojPu: "49282",
        redBroj: "2706",
        nazivPu: "Konjščina",
        naselje: "Konjščina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2707",
        brojPu: "31326",
        redBroj: "2707",
        nazivPu: "Darda",
        naselje: "Kopačevo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2708",
        brojPu: "10370",
        redBroj: "2708",
        nazivPu: "Dugo Selo",
        naselje: "Kopčevec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2709",
        brojPu: "31200",
        redBroj: "2709",
        nazivPu: "Osijek",
        naselje: "Koprivna",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2710",
        brojPu: "34000",
        redBroj: "2710",
        nazivPu: "Požega",
        naselje: "Koprivna",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2711",
        brojPu: "48000",
        redBroj: "2711",
        nazivPu: "Koprivnica",
        naselje: "Koprivnica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2712",
        brojPu: "49225",
        redBroj: "2712",
        nazivPu: "Đurmanec",
        naselje: "Koprivnica Zagorska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2713",
        brojPu: "48000",
        redBroj: "2713",
        nazivPu: "Koprivnica",
        naselje: "Koprivnički Bregi",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2714",
        brojPu: "48000",
        redBroj: "2714",
        nazivPu: "Koprivnica",
        naselje: "Koprivnički Ivanec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2715",
        brojPu: "21204",
        redBroj: "2715",
        nazivPu: "Dugopolje",
        naselje: "Koprivno",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2716",
        brojPu: "22323",
        redBroj: "2716",
        nazivPu: "Unešić",
        naselje: "Koprno",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2717",
        brojPu: "47240",
        redBroj: "2717",
        nazivPu: "Slunj",
        naselje: "Korana",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2718",
        brojPu: "53230",
        redBroj: "2718",
        nazivPu: "Korenica",
        naselje: "Korana",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2719",
        brojPu: "47250",
        redBroj: "2719",
        nazivPu: "Duga Resa",
        naselje: "Koranska Strana",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2720",
        brojPu: "47220",
        redBroj: "2720",
        nazivPu: "Vojnić",
        naselje: "Koranski Brijeg",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2721",
        brojPu: "47240",
        redBroj: "2721",
        nazivPu: "Slunj",
        naselje: "Koranski Lug",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2722",
        brojPu: "47250",
        redBroj: "2722",
        nazivPu: "Duga Resa",
        naselje: "Koransko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2723",
        brojPu: "20260",
        redBroj: "2723",
        nazivPu: "Korčula",
        naselje: "Korčula",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2724",
        brojPu: "10454",
        redBroj: "2724",
        nazivPu: "Krašić",
        naselje: "Kordići Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2725",
        brojPu: "47240",
        redBroj: "2725",
        nazivPu: "Slunj",
        naselje: "Kordunski Ljeskovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2726",
        brojPu: "35212",
        redBroj: "2726",
        nazivPu: "Garčin",
        naselje: "Korduševci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2727",
        brojPu: "52000",
        redBroj: "2727",
        nazivPu: "Pazin",
        naselje: "Korelići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2728",
        brojPu: "53230",
        redBroj: "2728",
        nazivPu: "Korenica",
        naselje: "Korenica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2729",
        brojPu: "43500",
        redBroj: "2729",
        nazivPu: "Daruvar",
        naselje: "Koreničani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2730",
        brojPu: "47250",
        redBroj: "2730",
        nazivPu: "Duga Resa",
        naselje: "Korenić Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2731",
        brojPu: "52341",
        redBroj: "2731",
        nazivPu: "Žminj",
        naselje: "Korenići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2732",
        brojPu: "42243",
        redBroj: "2732",
        nazivPu: "Maruševec",
        naselje: "Korenjak",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2733",
        brojPu: "42243",
        redBroj: "2733",
        nazivPu: "Maruševec",
        naselje: "Koretinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2734",
        brojPu: "33000",
        redBroj: "2734",
        nazivPu: "Virovitica",
        naselje: "Korija",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2735",
        brojPu: "20225",
        redBroj: "2735",
        nazivPu: "Babino Polje",
        naselje: "Korita",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2736",
        brojPu: "21238",
        redBroj: "2736",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Korita",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2737",
        brojPu: "34550",
        redBroj: "2737",
        nazivPu: "Pakrac",
        naselje: "Korita",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2738",
        brojPu: "47240",
        redBroj: "2738",
        nazivPu: "Slunj",
        naselje: "Korita",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2739",
        brojPu: "47000",
        redBroj: "2739",
        nazivPu: "Karlovac",
        naselje: "Koritinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2740",
        brojPu: "10342",
        redBroj: "2740",
        nazivPu: "Dubrava",
        naselje: "Koritna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2741",
        brojPu: "31400",
        redBroj: "2741",
        nazivPu: "Đakovo",
        naselje: "Koritna",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2742",
        brojPu: "23420",
        redBroj: "2742",
        nazivPu: "Benkovac",
        naselje: "Korlat",
        zupanija: "ZADARSKA",
      },
      {
        id: "2743",
        brojPu: "52440",
        redBroj: "2743",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Korlevići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2744",
        brojPu: "22243",
        redBroj: "2744",
        nazivPu: "Murter",
        naselje: "Kornati",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2745",
        brojPu: "51500",
        redBroj: "2745",
        nazivPu: "Krk",
        naselje: "Kornić",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2746",
        brojPu: "31207",
        redBroj: "2746",
        nazivPu: "Tenja",
        naselje: "Korog",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2747",
        brojPu: "52220",
        redBroj: "2747",
        nazivPu: "Labin",
        naselje: "Koromačno",
        zupanija: "ISTARSKA",
      },
      {
        id: "2748",
        brojPu: "52207",
        redBroj: "2748",
        nazivPu: "Barban",
        naselje: "Koromani",
        zupanija: "ISTARSKA",
      },
      {
        id: "2749",
        brojPu: "21204",
        redBroj: "2749",
        nazivPu: "Dugopolje",
        naselje: "Korušce",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2750",
        brojPu: "47240",
        redBroj: "2750",
        nazivPu: "Slunj",
        naselje: "Kosa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2751",
        brojPu: "53202",
        redBroj: "2751",
        nazivPu: "Perušić",
        naselje: "Kosa Janjačka",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2752",
        brojPu: "51216",
        redBroj: "2752",
        nazivPu: "Viškovo",
        naselje: "Kosi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2753",
        brojPu: "47240",
        redBroj: "2753",
        nazivPu: "Slunj",
        naselje: "Kosijer Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2754",
        brojPu: "47250",
        redBroj: "2754",
        nazivPu: "Duga Resa",
        naselje: "Kosijersko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2755",
        brojPu: "52440",
        redBroj: "2755",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kosinožići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2756",
        brojPu: "44440",
        redBroj: "2756",
        nazivPu: "Dvor",
        naselje: "Kosna",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2757",
        brojPu: "21236",
        redBroj: "2757",
        nazivPu: "Vrlika",
        naselje: "Kosore",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2758",
        brojPu: "52420",
        redBroj: "2758",
        nazivPu: "Buzet",
        naselje: "Kosoriga",
        zupanija: "ISTARSKA",
      },
      {
        id: "2759",
        brojPu: "35430",
        redBroj: "2759",
        nazivPu: "Okučani",
        naselje: "Kosovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2760",
        brojPu: "49282",
        redBroj: "2760",
        nazivPu: "Konjščina",
        naselje: "Kosovečko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2761",
        brojPu: "48260",
        redBroj: "2761",
        nazivPu: "Križevci",
        naselje: "Kostadinovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2762",
        brojPu: "10342",
        redBroj: "2762",
        nazivPu: "Dubrava",
        naselje: "Kostanj",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2763",
        brojPu: "21250",
        redBroj: "2763",
        nazivPu: "Šestanovac",
        naselje: "Kostanje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2764",
        brojPu: "10454",
        redBroj: "2764",
        nazivPu: "Krašić",
        naselje: "Kostanjevac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2765",
        brojPu: "43280",
        redBroj: "2765",
        nazivPu: "Garešnica",
        naselje: "Kostanjevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2766",
        brojPu: "10430",
        redBroj: "2766",
        nazivPu: "Samobor",
        naselje: "Kostanjevec Podvrški",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2767",
        brojPu: "48260",
        redBroj: "2767",
        nazivPu: "Križevci",
        naselje: "Kostanjevec Riječki",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2768",
        brojPu: "52460",
        redBroj: "2768",
        nazivPu: "Buje (Buie)",
        naselje: "Kostanjica - Castagna",
        zupanija: "ISTARSKA",
      },
      {
        id: "2769",
        brojPu: "49218",
        redBroj: "2769",
        nazivPu: "Pregrada",
        naselje: "Kostel",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2770",
        brojPu: "10454",
        redBroj: "2770",
        nazivPu: "Krašić",
        naselje: "Kostel Pribićki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2771",
        brojPu: "49218",
        redBroj: "2771",
        nazivPu: "Pregrada",
        naselje: "Kostelsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2772",
        brojPu: "52333",
        redBroj: "2772",
        nazivPu: "Podpićan",
        naselje: "Kostrčani",
        zupanija: "ISTARSKA",
      },
      {
        id: "2773",
        brojPu: "51000",
        redBroj: "2773",
        nazivPu: "Rijeka",
        naselje: "Kostrena",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2774",
        brojPu: "44430",
        redBroj: "2774",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Kostreši Bjelovački",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2775",
        brojPu: "44210",
        redBroj: "2775",
        nazivPu: "Sunja",
        naselje: "Kostreši Šaški",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2776",
        brojPu: "44430",
        redBroj: "2776",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Kostrići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2777",
        brojPu: "31500",
        redBroj: "2777",
        nazivPu: "Našice",
        naselje: "Koška",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2778",
        brojPu: "42243",
        redBroj: "2778",
        nazivPu: "Maruševec",
        naselje: "Koškovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2779",
        brojPu: "23250",
        redBroj: "2779",
        nazivPu: "Pag",
        naselje: "Košljun",
        zupanija: "ZADARSKA",
      },
      {
        id: "2780",
        brojPu: "49216",
        redBroj: "2780",
        nazivPu: "Desinić",
        naselje: "Košnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2781",
        brojPu: "44330",
        redBroj: "2781",
        nazivPu: "Novska",
        naselje: "Košutarica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2782",
        brojPu: "21240",
        redBroj: "2782",
        nazivPu: "Trilj",
        naselje: "Košute",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2783",
        brojPu: "52440",
        redBroj: "2783",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Košutići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2784",
        brojPu: "44440",
        redBroj: "2784",
        nazivPu: "Dvor",
        naselje: "Kotarani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2785",
        brojPu: "10430",
        redBroj: "2785",
        nazivPu: "Samobor",
        naselje: "Kotari",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2786",
        brojPu: "49223",
        redBroj: "2786",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Kotarice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2787",
        brojPu: "21276",
        redBroj: "2787",
        nazivPu: "Vrgorac",
        naselje: "Kotezi",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2788",
        brojPu: "21204",
        redBroj: "2788",
        nazivPu: "Dugopolje",
        naselje: "Kotlenice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2789",
        brojPu: "52420",
        redBroj: "2789",
        nazivPu: "Buzet",
        naselje: "Kotli",
        zupanija: "ISTARSKA",
      },
      {
        id: "2790",
        brojPu: "31300",
        redBroj: "2790",
        nazivPu: "Beli Manastir",
        naselje: "Kotlina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2791",
        brojPu: "40320",
        redBroj: "2791",
        nazivPu: "Donji Kraljevec",
        naselje: "Kotoriba",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2792",
        brojPu: "34550",
        redBroj: "2792",
        nazivPu: "Pakrac",
        naselje: "Koturić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2793",
        brojPu: "34550",
        redBroj: "2793",
        nazivPu: "Pakrac",
        naselje: "Kovačevac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2794",
        brojPu: "35400",
        redBroj: "2794",
        nazivPu: "Nova Gradiška",
        naselje: "Kovačevac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2795",
        brojPu: "43000",
        redBroj: "2795",
        nazivPu: "Bjelovar",
        naselje: "Kovačevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2796",
        brojPu: "52440",
        redBroj: "2796",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kovači",
        zupanija: "ISTARSKA",
      },
      {
        id: "2797",
        brojPu: "22300",
        redBroj: "2797",
        nazivPu: "Knin",
        naselje: "Kovačić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2798",
        brojPu: "47250",
        redBroj: "2798",
        nazivPu: "Duga Resa",
        naselje: "Kozalj Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2799",
        brojPu: "44400",
        redBroj: "2799",
        nazivPu: "Glina",
        naselje: "Kozaperovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2800",
        brojPu: "31300",
        redBroj: "2800",
        nazivPu: "b",
        naselje: "Beli Manastir",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2801",
        brojPu: "44410",
        redBroj: "2801",
        nazivPu: "Vrginmost",
        naselje: "Kozarac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2802",
        brojPu: "48350",
        redBroj: "2802",
        nazivPu: "Đurđevac",
        naselje: "Kozarevac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2803",
        brojPu: "43270",
        redBroj: "2803",
        nazivPu: "Veliki Grđevac",
        naselje: "Kozarevac Račanski",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2804",
        brojPu: "20225",
        redBroj: "2804",
        nazivPu: "Babino Polje",
        naselje: "Kozarica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2805",
        brojPu: "44330",
        redBroj: "2805",
        nazivPu: "Novska",
        naselje: "Kozarice",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2806",
        brojPu: "44440",
        redBroj: "2806",
        nazivPu: "Dvor",
        naselje: "Kozibrod",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2807",
        brojPu: "21276",
        redBroj: "2807",
        nazivPu: "Vrgorac",
        naselje: "Kozica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2808",
        brojPu: "33520",
        redBroj: "2808",
        nazivPu: "Slatina",
        naselje: "Kozice",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2809",
        brojPu: "10370",
        redBroj: "2809",
        nazivPu: "Dugo Selo",
        naselje: "Kozinščak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2810",
        brojPu: "10413",
        redBroj: "2810",
        nazivPu: "Kravarsko",
        naselje: "Kozjača",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2811",
        brojPu: "31300",
        redBroj: "2811",
        nazivPu: "Beli Manastir",
        naselje: "Kozjak",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2812",
        brojPu: "49223",
        redBroj: "2812",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Kozjak Začretski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2813",
        brojPu: "53230",
        redBroj: "2813",
        nazivPu: "Korenica",
        naselje: "Kozjan",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2814",
        brojPu: "51306",
        redBroj: "2814",
        nazivPu: "Čabar",
        naselje: "Kozji Vrh",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2815",
        brojPu: "10450",
        redBroj: "2815",
        nazivPu: "Jastrebarsko",
        naselje: "Kozlikovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2816",
        brojPu: "23000",
        redBroj: "2816",
        nazivPu: "Zadar",
        naselje: "Kožino",
        zupanija: "ZADARSKA",
      },
      {
        id: "2817",
        brojPu: "23420",
        redBroj: "2817",
        nazivPu: "Benkovac",
        naselje: "Kožlovac",
        zupanija: "ZADARSKA",
      },
      {
        id: "2818",
        brojPu: "52333",
        redBroj: "2818",
        nazivPu: "Podpićan",
        naselje: "Kožljak",
        zupanija: "ISTARSKA",
      },
      {
        id: "2819",
        brojPu: "52207",
        redBroj: "2819",
        nazivPu: "Barban",
        naselje: "Kožljani",
        zupanija: "ISTARSKA",
      },
      {
        id: "2820",
        brojPu: "42220",
        redBroj: "2820",
        nazivPu: "Novi Marof",
        naselje: "Kračevec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2821",
        brojPu: "34550",
        redBroj: "2821",
        nazivPu: "Pakrac",
        naselje: "Kraguj",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2822",
        brojPu: "21232",
        redBroj: "2822",
        nazivPu: "Dicmo",
        naselje: "Kraj",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2823",
        brojPu: "23212",
        redBroj: "2823",
        nazivPu: "Tkon",
        naselje: "Kraj",
        zupanija: "ZADARSKA",
      },
      {
        id: "2824",
        brojPu: "51417",
        redBroj: "2824",
        nazivPu: "Mošćenička Draga",
        naselje: "Kraj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2825",
        brojPu: "10291",
        redBroj: "2825",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Kraj Donji",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2826",
        brojPu: "52220",
        redBroj: "2826",
        nazivPu: "Labin",
        naselje: "Kraj Drage",
        zupanija: "ISTARSKA",
      },
      {
        id: "2827",
        brojPu: "10291",
        redBroj: "2827",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Kraj Gornji",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2828",
        brojPu: "10292",
        redBroj: "2828",
        nazivPu: "Šenkovec",
        naselje: "Kraj Gornji",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2829",
        brojPu: "35252",
        redBroj: "2829",
        nazivPu: "Sibinj",
        naselje: "Krajačići",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2830",
        brojPu: "52341",
        redBroj: "2830",
        nazivPu: "Žminj",
        naselje: "Krajcar Breg",
        zupanija: "ISTARSKA",
      },
      {
        id: "2831",
        brojPu: "52460",
        redBroj: "2831",
        nazivPu: "Buje (Buie)",
        naselje: "Krajići - Craici",
        zupanija: "ISTARSKA",
      },
      {
        id: "2832",
        brojPu: "44320",
        redBroj: "2832",
        nazivPu: "Kutina",
        naselje: "Krajiška Kutinica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2833",
        brojPu: "33515",
        redBroj: "2833",
        nazivPu: "Orahovica",
        naselje: "Krajna",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2834",
        brojPu: "10297",
        redBroj: "2834",
        nazivPu: "Jakovlje",
        naselje: "Krajska Ves",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2835",
        brojPu: "10297",
        redBroj: "2835",
        nazivPu: "Jakovlje",
        naselje: "Kraljev Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2836",
        brojPu: "10340",
        redBroj: "2836",
        nazivPu: "Vrbovec",
        naselje: "Kraljev Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2837",
        brojPu: "51306",
        redBroj: "2837",
        nazivPu: "Čabar",
        naselje: "Kraljev Vrh",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2838",
        brojPu: "44330",
        redBroj: "2838",
        nazivPu: "Novska",
        naselje: "Kraljeva Velika",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2839",
        brojPu: "43000",
        redBroj: "2839",
        nazivPu: "Bjelovar",
        naselje: "Kraljevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2840",
        brojPu: "44250",
        redBroj: "2840",
        nazivPu: "Petrinja",
        naselje: "Kraljevčani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2841",
        brojPu: "49290",
        redBroj: "2841",
        nazivPu: "Klanjec",
        naselje: "Kraljevec na Sutli",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2842",
        brojPu: "49000",
        redBroj: "2842",
        nazivPu: "Krapina",
        naselje: "Kraljevec Radobojski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2843",
        brojPu: "49000",
        redBroj: "2843",
        nazivPu: "Krapina",
        naselje: "Kraljevec Šemnički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2844",
        brojPu: "51262",
        redBroj: "2844",
        nazivPu: "Kraljevica",
        naselje: "Kraljevica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2845",
        brojPu: "47250",
        redBroj: "2845",
        nazivPu: "Duga Resa",
        naselje: "Kraljevo Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2846",
        brojPu: "52440",
        redBroj: "2846",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Krančići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2847",
        brojPu: "51306",
        redBroj: "2847",
        nazivPu: "Čabar",
        naselje: "Kranjci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2848",
        brojPu: "52220",
        redBroj: "2848",
        nazivPu: "Labin",
        naselje: "Kranjci",
        zupanija: "ISTARSKA",
      },
      {
        id: "2849",
        brojPu: "52341",
        redBroj: "2849",
        nazivPu: "Žminj",
        naselje: "Kranjčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2850",
        brojPu: "52220",
        redBroj: "2850",
        nazivPu: "Labin",
        naselje: "Krapan",
        zupanija: "ISTARSKA",
      },
      {
        id: "2851",
        brojPu: "22000",
        redBroj: "2851",
        nazivPu: "Šibenik",
        naselje: "Krapanj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2852",
        brojPu: "49000",
        redBroj: "2852",
        nazivPu: "Krapina",
        naselje: "Krapina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2853",
        brojPu: "49282",
        redBroj: "2853",
        nazivPu: "Konjščina",
        naselje: "Krapina Selo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2854",
        brojPu: "49284",
        redBroj: "2854",
        nazivPu: "Budinščina",
        naselje: "Krapinica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2855",
        brojPu: "49217",
        redBroj: "2855",
        nazivPu: "Krapinske Toplice",
        naselje: "Krapinske Toplice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2856",
        brojPu: "44330",
        redBroj: "2856",
        nazivPu: "Novska",
        naselje: "Krapje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2857",
        brojPu: "51511",
        redBroj: "2857",
        nazivPu: "Malinska",
        naselje: "Kras",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2858",
        brojPu: "52420",
        redBroj: "2858",
        nazivPu: "Buzet",
        naselje: "Kras",
        zupanija: "ISTARSKA",
      },
      {
        id: "2859",
        brojPu: "51224",
        redBroj: "2859",
        nazivPu: "Krasica",
        naselje: "Krasica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2860",
        brojPu: "52460",
        redBroj: "2860",
        nazivPu: "Buje (Buie)",
        naselje: "Krasica - Crassiza",
        zupanija: "ISTARSKA",
      },
      {
        id: "2861",
        brojPu: "33515",
        redBroj: "2861",
        nazivPu: "Orahovica",
        naselje: "Krasković",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2862",
        brojPu: "53220",
        redBroj: "2862",
        nazivPu: "Otočac",
        naselje: "Krasno",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2863",
        brojPu: "10454",
        redBroj: "2863",
        nazivPu: "Krašić",
        naselje: "Krašić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2864",
        brojPu: "44000",
        redBroj: "2864",
        nazivPu: "Sisak",
        naselje: "Kratečko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2865",
        brojPu: "10413",
        redBroj: "2865",
        nazivPu: "Kravarsko",
        naselje: "Kravarsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2866",
        brojPu: "10430",
        redBroj: "2866",
        nazivPu: "Samobor",
        naselje: "Kravljak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2867",
        brojPu: "33410",
        redBroj: "2867",
        nazivPu: "Suhopolje",
        naselje: "Kravljak",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2868",
        brojPu: "53234",
        redBroj: "2868",
        nazivPu: "Udbina",
        naselje: "Krbava",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2869",
        brojPu: "52420",
        redBroj: "2869",
        nazivPu: "Buzet",
        naselje: "Krbavčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2870",
        brojPu: "53230",
        redBroj: "2870",
        nazivPu: "Korenica",
        naselje: "Krbavica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2871",
        brojPu: "52333",
        redBroj: "2871",
        nazivPu: "Podpićan",
        naselje: "Krbune",
        zupanija: "ISTARSKA",
      },
      {
        id: "2872",
        brojPu: "52341",
        redBroj: "2872",
        nazivPu: "Žminj",
        naselje: "Krculi",
        zupanija: "ISTARSKA",
      },
      {
        id: "2873",
        brojPu: "42220",
        redBroj: "2873",
        nazivPu: "Novi Marof",
        naselje: "Krč",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2874",
        brojPu: "47250",
        redBroj: "2874",
        nazivPu: "Duga Resa",
        naselje: "Krč Bosiljevski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2875",
        brojPu: "31540",
        redBroj: "2875",
        nazivPu: "Donji Miholjac",
        naselje: "Krčenik",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2876",
        brojPu: "31511",
        redBroj: "2876",
        nazivPu: "Đurđenovac",
        naselje: "Krčevina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2877",
        brojPu: "10380",
        redBroj: "2877",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Krečaves",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2878",
        brojPu: "20355",
        redBroj: "2878",
        nazivPu: "Opuzen",
        naselje: "Kremena",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2879",
        brojPu: "51511",
        redBroj: "2879",
        nazivPu: "Malinska",
        naselje: "Kremenići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2880",
        brojPu: "52341",
        redBroj: "2880",
        nazivPu: "Žminj",
        naselje: "Kresini",
        zupanija: "ISTARSKA",
      },
      {
        id: "2881",
        brojPu: "21250",
        redBroj: "2881",
        nazivPu: "Šestanovac",
        naselje: "Kreševo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2882",
        brojPu: "43500",
        redBroj: "2882",
        nazivPu: "Daruvar",
        naselje: "Kreštelovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2883",
        brojPu: "22320",
        redBroj: "2883",
        nazivPu: "Drniš",
        naselje: "Kričke",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2884",
        brojPu: "34550",
        redBroj: "2884",
        nazivPu: "Pakrac",
        naselje: "Kričke",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2885",
        brojPu: "44330",
        redBroj: "2885",
        nazivPu: "Novska",
        naselje: "Kričke",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2886",
        brojPu: "52000",
        redBroj: "2886",
        nazivPu: "Pazin",
        naselje: "Kringa",
        zupanija: "ISTARSKA",
      },
      {
        id: "2887",
        brojPu: "10310",
        redBroj: "2887",
        nazivPu: "Ivanić Grad",
        naselje: "Krišci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2888",
        brojPu: "40000",
        redBroj: "2888",
        nazivPu: "Čakovec",
        naselje: "Krištanovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2889",
        brojPu: "51300",
        redBroj: "2889",
        nazivPu: "Delnice",
        naselje: "Krivac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2890",
        brojPu: "34000",
        redBroj: "2890",
        nazivPu: "Požega",
        naselje: "Krivaj",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2891",
        brojPu: "44330",
        redBroj: "2891",
        nazivPu: "Novska",
        naselje: "Krivaj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2892",
        brojPu: "44210",
        redBroj: "2892",
        nazivPu: "Sunja",
        naselje: "Krivaj Sunjski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2893",
        brojPu: "43000",
        redBroj: "2893",
        nazivPu: "Bjelovar",
        naselje: "Krivaja",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2894",
        brojPu: "33515",
        redBroj: "2894",
        nazivPu: "Orahovica",
        naselje: "Krivaja Pustara",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2895",
        brojPu: "47220",
        redBroj: "2895",
        nazivPu: "Vojnić",
        naselje: "Krivaja Vojnićka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2896",
        brojPu: "53270",
        redBroj: "2896",
        nazivPu: "Senj",
        naselje: "Krivi Put",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2897",
        brojPu: "21238",
        redBroj: "2897",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Krivodol",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2898",
        brojPu: "21260",
        redBroj: "2898",
        nazivPu: "Imotski",
        naselje: "Krivodol",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2899",
        brojPu: "10315",
        redBroj: "2899",
        nazivPu: "Novoselec",
        naselje: "Križ",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2900",
        brojPu: "43000",
        redBroj: "2900",
        nazivPu: "Bjelovar",
        naselje: "Križ Gornji",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2901",
        brojPu: "44250",
        redBroj: "2901",
        nazivPu: "Petrinja",
        naselje: "Križ Hrastovački",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2902",
        brojPu: "53260",
        redBroj: "2902",
        nazivPu: "Brinje",
        naselje: "Križ Kamenica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2903",
        brojPu: "47250",
        redBroj: "2903",
        nazivPu: "Duga Resa",
        naselje: "Križ Koranski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2904",
        brojPu: "52341",
        redBroj: "2904",
        nazivPu: "Žminj",
        naselje: "Križanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "2905",
        brojPu: "42208",
        redBroj: "2905",
        nazivPu: "Cestica",
        naselje: "Križanče",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2906",
        brojPu: "49221",
        redBroj: "2906",
        nazivPu: "Bedekovčina",
        naselje: "Križanče",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2907",
        brojPu: "42204",
        redBroj: "2907",
        nazivPu: "Turčin",
        naselje: "Križanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2908",
        brojPu: "48260",
        redBroj: "2908",
        nazivPu: "Križevci",
        naselje: "Križevci",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2909",
        brojPu: "10380",
        redBroj: "2909",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Križevčec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2910",
        brojPu: "43000",
        redBroj: "2910",
        nazivPu: "Bjelovar",
        naselje: "Križic",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "2911",
        brojPu: "52470",
        redBroj: "2911",
        nazivPu: "Umag (Umago)",
        naselje: "Križine - Crisine",
        zupanija: "ISTARSKA",
      },
      {
        id: "2912",
        brojPu: "51262",
        redBroj: "2912",
        nazivPu: "Kraljevica",
        naselje: "Križišće",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2913",
        brojPu: "33405",
        redBroj: "2913",
        nazivPu: "Pitomača",
        naselje: "Križnica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "2914",
        brojPu: "40315",
        redBroj: "2914",
        nazivPu: "Mursko Središče",
        naselje: "Križovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "2915",
        brojPu: "42230",
        redBroj: "2915",
        nazivPu: "Ludbreg",
        naselje: "Križovljan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2916",
        brojPu: "42208",
        redBroj: "2916",
        nazivPu: "Cestica",
        naselje: "Križovljan Radovečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2917",
        brojPu: "53260",
        redBroj: "2917",
        nazivPu: "Brinje",
        naselje: "Križpolje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2918",
        brojPu: "51500",
        redBroj: "2918",
        nazivPu: "Krk",
        naselje: "Krk",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2919",
        brojPu: "10340",
        redBroj: "2919",
        nazivPu: "Vrbovec",
        naselje: "Krkač",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2920",
        brojPu: "42000",
        redBroj: "2920",
        nazivPu: "Varaždin",
        naselje: "Krkanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2921",
        brojPu: "22213",
        redBroj: "2921",
        nazivPu: "Pirovac",
        naselje: "Krković",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2922",
        brojPu: "52420",
        redBroj: "2922",
        nazivPu: "Buzet",
        naselje: "Krkuž",
        zupanija: "ISTARSKA",
      },
      {
        id: "2923",
        brojPu: "52210",
        redBroj: "2923",
        nazivPu: "Rovinj (Rovigno)",
        naselje: "Krmed",
        zupanija: "ISTARSKA",
      },
      {
        id: "2924",
        brojPu: "51250",
        redBroj: "2924",
        nazivPu: "Novi Vinodolski",
        naselje: "Krmpotske Vodice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2925",
        brojPu: "31400",
        redBroj: "2925",
        nazivPu: "Đakovo",
        naselje: "Krndija",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2926",
        brojPu: "23248",
        redBroj: "2926",
        nazivPu: "Ražanac",
        naselje: "Krneza",
        zupanija: "ZADARSKA",
      },
      {
        id: "2927",
        brojPu: "10454",
        redBroj: "2927",
        nazivPu: "Krašić",
        naselje: "Krnežići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2928",
        brojPu: "52207",
        redBroj: "2928",
        nazivPu: "Barban",
        naselje: "Krnica",
        zupanija: "ISTARSKA",
      },
      {
        id: "2929",
        brojPu: "52341",
        redBroj: "2929",
        nazivPu: "Žminj",
        naselje: "Krničari",
        zupanija: "ISTARSKA",
      },
      {
        id: "2930",
        brojPu: "47220",
        redBroj: "2930",
        nazivPu: "Vojnić",
        naselje: "Krnjak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2931",
        brojPu: "22300",
        redBroj: "2931",
        nazivPu: "Knin",
        naselje: "Krnjeuve",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2932",
        brojPu: "52420",
        redBroj: "2932",
        nazivPu: "Buzet",
        naselje: "Kropinjak",
        zupanija: "ISTARSKA",
      },
      {
        id: "2933",
        brojPu: "21270",
        redBroj: "2933",
        nazivPu: "Zagvozd",
        naselje: "Krstatice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2934",
        brojPu: "47220",
        redBroj: "2934",
        nazivPu: "Vojnić",
        naselje: "Krstinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2935",
        brojPu: "53202",
        redBroj: "2935",
        nazivPu: "Perušić",
        naselje: "Krš",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2936",
        brojPu: "52333",
        redBroj: "2936",
        nazivPu: "Podpićan",
        naselje: "Kršan",
        zupanija: "ISTARSKA",
      },
      {
        id: "2937",
        brojPu: "52341",
        redBroj: "2937",
        nazivPu: "Žminj",
        naselje: "Kršanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "2938",
        brojPu: "42220",
        redBroj: "2938",
        nazivPu: "Novi Marof",
        naselje: "Krščenovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2939",
        brojPu: "52470",
        redBroj: "2939",
        nazivPu: "Umag (Umago)",
        naselje: "Kršete - Carsette",
        zupanija: "ISTARSKA",
      },
      {
        id: "2940",
        brojPu: "52000",
        redBroj: "2940",
        nazivPu: "Pazin",
        naselje: "Kršikla",
        zupanija: "ISTARSKA",
      },
      {
        id: "2941",
        brojPu: "31500",
        redBroj: "2941",
        nazivPu: "Našice",
        naselje: "Kršinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2942",
        brojPu: "52440",
        redBroj: "2942",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kršuli",
        zupanija: "ISTARSKA",
      },
      {
        id: "2943",
        brojPu: "52424",
        redBroj: "2943",
        nazivPu: "Motovun (Montona)",
        naselje: "Krti",
        zupanija: "ISTARSKA",
      },
      {
        id: "2944",
        brojPu: "20232",
        redBroj: "2944",
        nazivPu: "Slano",
        naselje: "Kručica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2945",
        brojPu: "53250",
        redBroj: "2945",
        nazivPu: "Donji Lapac",
        naselje: "Kruge",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2946",
        brojPu: "52440",
        redBroj: "2946",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Krunčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2947",
        brojPu: "31540",
        redBroj: "2947",
        nazivPu: "Donji Miholjac",
        naselje: "Krunoslavje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2948",
        brojPu: "23450",
        redBroj: "2948",
        nazivPu: "Obrovac",
        naselje: "Krupa",
        zupanija: "ZADARSKA",
      },
      {
        id: "2949",
        brojPu: "10454",
        redBroj: "2949",
        nazivPu: "Krašić",
        naselje: "Krupače",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2950",
        brojPu: "53202",
        redBroj: "2950",
        nazivPu: "Perušić",
        naselje: "Kruščica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2951",
        brojPu: "35220",
        redBroj: "2951",
        nazivPu: "Slavonski Šamac",
        naselje: "Kruševica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2952",
        brojPu: "22202",
        redBroj: "2952",
        nazivPu: "Primošten",
        naselje: "Kruševo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "2953",
        brojPu: "23450",
        redBroj: "2953",
        nazivPu: "Obrovac",
        naselje: "Kruševo",
        zupanija: "ZADARSKA",
      },
      {
        id: "2954",
        brojPu: "34000",
        redBroj: "2954",
        nazivPu: "Požega",
        naselje: "Kruševo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2955",
        brojPu: "53000",
        redBroj: "2955",
        nazivPu: "Gospić",
        naselje: "Kruškovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2956",
        brojPu: "47240",
        redBroj: "2956",
        nazivPu: "Slunj",
        naselje: "Kruškovača",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2957",
        brojPu: "10340",
        redBroj: "2957",
        nazivPu: "Vrbovec",
        naselje: "Krušljevec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2958",
        brojPu: "42204",
        redBroj: "2958",
        nazivPu: "Turčin",
        naselje: "Krušljevec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2959",
        brojPu: "49243",
        redBroj: "2959",
        nazivPu: "Oroslavje",
        naselje: "Krušljevo Selo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "2960",
        brojPu: "21232",
        redBroj: "2960",
        nazivPu: "Dicmo",
        naselje: "Krušvar",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2961",
        brojPu: "52420",
        redBroj: "2961",
        nazivPu: "Buzet",
        naselje: "Krušvari",
        zupanija: "ISTARSKA",
      },
      {
        id: "2962",
        brojPu: "20350",
        redBroj: "2962",
        nazivPu: "Metković",
        naselje: "Krvavac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2963",
        brojPu: "20350",
        redBroj: "2963",
        nazivPu: "Metković",
        naselje: "Krvavac II",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2964",
        brojPu: "21320",
        redBroj: "2964",
        nazivPu: "Baška Voda",
        naselje: "Krvavica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2965",
        brojPu: "52460",
        redBroj: "2965",
        nazivPu: "Buje (Buie)",
        naselje: "Kuberton - Cuberton",
        zupanija: "ISTARSKA",
      },
      {
        id: "2966",
        brojPu: "48260",
        redBroj: "2966",
        nazivPu: "Križevci",
        naselje: "Kučari",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "2967",
        brojPu: "10410",
        redBroj: "2967",
        nazivPu: "Velika Gorica",
        naselje: "Kuče",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2968",
        brojPu: "10454",
        redBroj: "2968",
        nazivPu: "Krašić",
        naselje: "Kučer",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2969",
        brojPu: "47000",
        redBroj: "2969",
        nazivPu: "Karlovac",
        naselje: "Kučevice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2970",
        brojPu: "21250",
        redBroj: "2970",
        nazivPu: "Šestanovac",
        naselje: "Kučiće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2971",
        brojPu: "10362",
        redBroj: "2971",
        nazivPu: "Kašina",
        naselje: "Kučilovina",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2972",
        brojPu: "21210",
        redBroj: "2972",
        nazivPu: "Solin",
        naselje: "Kučine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "2973",
        brojPu: "20250",
        redBroj: "2973",
        nazivPu: "Orebić",
        naselje: "Kučište",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2974",
        brojPu: "42230",
        redBroj: "2974",
        nazivPu: "Ludbreg",
        naselje: "Kućan Ludbreški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2975",
        brojPu: "42000",
        redBroj: "2975",
        nazivPu: "Varaždin",
        naselje: "Kućan Marof",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "2976",
        brojPu: "31540",
        redBroj: "2976",
        nazivPu: "Donji Miholjac",
        naselje: "Kućanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2977",
        brojPu: "31400",
        redBroj: "2977",
        nazivPu: "Đakovo",
        naselje: "Kućanci Đakovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "2978",
        brojPu: "10360",
        redBroj: "2978",
        nazivPu: "Sesvete",
        naselje: "Kućanec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "2979",
        brojPu: "10340",
        redBroj: "2979",
        nazivPu: "Vrbovec",
        naselje: "Kućari",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "2980",
        brojPu: "51211",
        redBroj: "2980",
        nazivPu: "Matulji",
        naselje: "Kućeli",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2981",
        brojPu: "52460",
        redBroj: "2981",
        nazivPu: "Buje (Buie)",
        naselje: "Kućibreg - Cucibreg",
        zupanija: "ISTARSKA",
      },
      {
        id: "2982",
        brojPu: "53288",
        redBroj: "2982",
        nazivPu: "Karlobag",
        naselje: "Kućišta Cesarička",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2983",
        brojPu: "52207",
        redBroj: "2983",
        nazivPu: "Barban",
        naselje: "Kujići",
        zupanija: "ISTARSKA",
      },
      {
        id: "2984",
        brojPu: "34000",
        redBroj: "2984",
        nazivPu: "Požega",
        naselje: "Kujnik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2985",
        brojPu: "35250",
        redBroj: "2985",
        nazivPu: "Oriovac",
        naselje: "Kujnik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "2986",
        brojPu: "47240",
        redBroj: "2986",
        nazivPu: "Slunj",
        naselje: "Kuk",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2987",
        brojPu: "52440",
        redBroj: "2987",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kukci",
        zupanija: "ISTARSKA",
      },
      {
        id: "2988",
        brojPu: "23271",
        redBroj: "2988",
        nazivPu: "Kukljica",
        naselje: "Kukljica",
        zupanija: "ZADARSKA",
      },
      {
        id: "2989",
        brojPu: "53000",
        redBroj: "2989",
        nazivPu: "Gospić",
        naselje: "Kukljić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "2990",
        brojPu: "51218",
        redBroj: "2990",
        nazivPu: "Dražice",
        naselje: "Kukuljani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2991",
        brojPu: "51224",
        redBroj: "2991",
        nazivPu: "Krasica",
        naselje: "Kukuljanovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "2992",
        brojPu: "34550",
        redBroj: "2992",
        nazivPu: "Pakrac",
        naselje: "Kukunjevac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2993",
        brojPu: "52000",
        redBroj: "2993",
        nazivPu: "Pazin",
        naselje: "Kukurini",
        zupanija: "ISTARSKA",
      },
      {
        id: "2994",
        brojPu: "34340",
        redBroj: "2994",
        nazivPu: "Kutjevo",
        naselje: "Kula",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "2995",
        brojPu: "23420",
        redBroj: "2995",
        nazivPu: "Benkovac",
        naselje: "Kula Atlagić",
        zupanija: "ZADARSKA",
      },
      {
        id: "2996",
        brojPu: "20350",
        redBroj: "2996",
        nazivPu: "Metković",
        naselje: "Kula Norinska",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "2997",
        brojPu: "47280",
        redBroj: "2997",
        nazivPu: "Ozalj",
        naselje: "Kuljaji",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "2998",
        brojPu: "44440",
        redBroj: "2998",
        nazivPu: "Dvor",
        naselje: "Kuljani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "2999",
        brojPu: "49290",
        redBroj: "2999",
        nazivPu: "Klanjec",
        naselje: "Kumrovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3000",
        brojPu: "20215",
        redBroj: "3000",
        nazivPu: "Gruda",
        naselje: "Kuna Konavoska",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3001",
        brojPu: "20244",
        redBroj: "3001",
        nazivPu: "Potomje",
        naselje: "Kuna Pelješka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3002",
        brojPu: "47280",
        redBroj: "3002",
        nazivPu: "Ozalj",
        naselje: "Kunčani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3003",
        brojPu: "10342",
        redBroj: "3003",
        nazivPu: "Dubrava",
        naselje: "Kunđevac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3004",
        brojPu: "48260",
        redBroj: "3004",
        nazivPu: "Križevci",
        naselje: "Kunđevec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3005",
        brojPu: "47300",
        redBroj: "3005",
        nazivPu: "Ogulin",
        naselje: "Kunić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3006",
        brojPu: "47000",
        redBroj: "3006",
        nazivPu: "Karlovac",
        naselje: "Kunići Ribnički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3007",
        brojPu: "31551",
        redBroj: "3007",
        nazivPu: "Belišće",
        naselje: "Kunišinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3008",
        brojPu: "53250",
        redBroj: "3008",
        nazivPu: "Donji Lapac",
        naselje: "Kunovac Kupirovački",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3009",
        brojPu: "34000",
        redBroj: "3009",
        nazivPu: "Požega",
        naselje: "Kunovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3010",
        brojPu: "48000",
        redBroj: "3010",
        nazivPu: "Koprivnica",
        naselje: "Kunovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3011",
        brojPu: "48000",
        redBroj: "3011",
        nazivPu: "Koprivnica",
        naselje: "Kunovec Breg",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3012",
        brojPu: "52220",
        redBroj: "3012",
        nazivPu: "Labin",
        naselje: "Kunj",
        zupanija: "ISTARSKA",
      },
      {
        id: "3013",
        brojPu: "51300",
        redBroj: "3013",
        nazivPu: "Delnice",
        naselje: "Kupa",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3014",
        brojPu: "20207",
        redBroj: "3014",
        nazivPu: "Mlini",
        naselje: "Kupari",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3015",
        brojPu: "10454",
        redBroj: "3015",
        nazivPu: "Krašić",
        naselje: "Kupčina Žumberačka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3016",
        brojPu: "10450",
        redBroj: "3016",
        nazivPu: "Jastrebarsko",
        naselje: "Kupeć Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3017",
        brojPu: "35214",
        redBroj: "3017",
        nazivPu: "Donji Andrijevci",
        naselje: "Kupina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3018",
        brojPu: "10450",
        redBroj: "3018",
        nazivPu: "Jastrebarsko",
        naselje: "Kupinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3019",
        brojPu: "10257",
        redBroj: "3019",
        nazivPu: "Brezovica",
        naselje: "Kupinečki Kraljevec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3020",
        brojPu: "43000",
        redBroj: "3020",
        nazivPu: "Bjelovar",
        naselje: "Kupinovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3021",
        brojPu: "53250",
        redBroj: "3021",
        nazivPu: "Donji Lapac",
        naselje: "Kupirovo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3022",
        brojPu: "51300",
        redBroj: "3022",
        nazivPu: "Delnice",
        naselje: "Kupjak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3023",
        brojPu: "10295",
        redBroj: "3023",
        nazivPu: "Kupljenovo",
        naselje: "Kupljenovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3024",
        brojPu: "47220",
        redBroj: "3024",
        nazivPu: "Vojnić",
        naselje: "Kupljensko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3025",
        brojPu: "52341",
        redBroj: "3025",
        nazivPu: "Žminj",
        naselje: "Kurili",
        zupanija: "ISTARSKA",
      },
      {
        id: "3026",
        brojPu: "53234",
        redBroj: "3026",
        nazivPu: "Udbina",
        naselje: "Kurjak",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3027",
        brojPu: "52440",
        redBroj: "3027",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Kurjavići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3028",
        brojPu: "10454",
        redBroj: "3028",
        nazivPu: "Krašić",
        naselje: "Kurpezova Gorica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3029",
        brojPu: "40000",
        redBroj: "3029",
        nazivPu: "Čakovec",
        naselje: "Kuršanec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3030",
        brojPu: "47220",
        redBroj: "3030",
        nazivPu: "Vojnić",
        naselje: "Kusaja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3031",
        brojPu: "10370",
        redBroj: "3031",
        nazivPu: "Dugo Selo",
        naselje: "Kusanovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3032",
        brojPu: "48260",
        redBroj: "3032",
        nazivPu: "Križevci",
        naselje: "Kusijevec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3033",
        brojPu: "34550",
        redBroj: "3033",
        nazivPu: "Pakrac",
        naselje: "Kusonje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3034",
        brojPu: "53291",
        redBroj: "3034",
        nazivPu: "Novalja",
        naselje: "Kustići",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3035",
        brojPu: "31400",
        redBroj: "3035",
        nazivPu: "Đakovo",
        naselje: "Kuševac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3036",
        brojPu: "48214",
        redBroj: "3036",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Kuštani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3037",
        brojPu: "47240",
        redBroj: "3037",
        nazivPu: "Slunj",
        naselje: "Kutanja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3038",
        brojPu: "53220",
        redBroj: "3038",
        nazivPu: "Otočac",
        naselje: "Kuterevo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3039",
        brojPu: "44320",
        redBroj: "3039",
        nazivPu: "Kutina",
        naselje: "Kutina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3040",
        brojPu: "44320",
        redBroj: "3040",
        nazivPu: "Kutina",
        naselje: "Kutinica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3041",
        brojPu: "44320",
        redBroj: "3041",
        nazivPu: "Kutina",
        naselje: "Kutinska Slatina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3042",
        brojPu: "34340",
        redBroj: "3042",
        nazivPu: "Kutjevo",
        naselje: "Kutjevo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3043",
        brojPu: "48316",
        redBroj: "3043",
        nazivPu: "Đelekovec",
        naselje: "Kutnjak",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3044",
        brojPu: "33515",
        redBroj: "3044",
        nazivPu: "Orahovica",
        naselje: "Kutovi",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3045",
        brojPu: "33520",
        redBroj: "3045",
        nazivPu: "Slatina",
        naselje: "Kuzma",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3046",
        brojPu: "47240",
        redBroj: "3046",
        nazivPu: "Slunj",
        naselje: "Kuzma Perjasička",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3047",
        brojPu: "34000",
        redBroj: "3047",
        nazivPu: "Požega",
        naselje: "Kuzmica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3048",
        brojPu: "48000",
        redBroj: "3048",
        nazivPu: "Koprivnica",
        naselje: "Kuzminec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3049",
        brojPu: "49252",
        redBroj: "3049",
        nazivPu: "Mihovljan",
        naselje: "Kuzminec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3050",
        brojPu: "49290",
        redBroj: "3050",
        nazivPu: "Klanjec",
        naselje: "Kuzminec Miljanski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3051",
        brojPu: "51300",
        redBroj: "3051",
        nazivPu: "Delnice",
        naselje: "Kuželj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3052",
        brojPu: "53202",
        redBroj: "3052",
        nazivPu: "Perušić",
        naselje: "Kvarte",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3053",
        brojPu: "21220",
        redBroj: "3053",
        nazivPu: "Trogir",
        naselje: "Labin",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3054",
        brojPu: "52220",
        redBroj: "3054",
        nazivPu: "Labin",
        naselje: "Labin",
        zupanija: "ISTARSKA",
      },
      {
        id: "3055",
        brojPu: "52440",
        redBroj: "3055",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Labinci - S. Domenica",
        zupanija: "ISTARSKA",
      },
      {
        id: "3056",
        brojPu: "31540",
        redBroj: "3056",
        nazivPu: "Donji Miholjac",
        naselje: "Lacići",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3057",
        brojPu: "47000",
        redBroj: "3057",
        nazivPu: "Karlovac",
        naselje: "Ladešići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3058",
        brojPu: "52341",
        redBroj: "3058",
        nazivPu: "Žminj",
        naselje: "Ladići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3059",
        brojPu: "31550",
        redBroj: "3059",
        nazivPu: "Valpovo",
        naselje: "Ladimirevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3060",
        brojPu: "10342",
        redBroj: "3060",
        nazivPu: "Dubrava",
        naselje: "Ladina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3061",
        brojPu: "48214",
        redBroj: "3061",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Ladinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3062",
        brojPu: "43280",
        redBroj: "3062",
        nazivPu: "Garešnica",
        naselje: "Ladislav",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3063",
        brojPu: "48000",
        redBroj: "3063",
        nazivPu: "Koprivnica",
        naselje: "Ladislav Sokolovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3064",
        brojPu: "49250",
        redBroj: "3064",
        nazivPu: "Zlatar",
        naselje: "Ladislavec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3065",
        brojPu: "52440",
        redBroj: "3065",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ladrovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3066",
        brojPu: "47000",
        redBroj: "3066",
        nazivPu: "Karlovac",
        naselje: "Ladvenjak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3067",
        brojPu: "31500",
        redBroj: "3067",
        nazivPu: "Našice",
        naselje: "Lađanska",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3068",
        brojPu: "35430",
        redBroj: "3068",
        nazivPu: "Okučani",
        naselje: "Lađevac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3069",
        brojPu: "47240",
        redBroj: "3069",
        nazivPu: "Slunj",
        naselje: "Lađevačko Selište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3070",
        brojPu: "22213",
        redBroj: "3070",
        nazivPu: "Pirovac",
        naselje: "Lađevci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3071",
        brojPu: "52341",
        redBroj: "3071",
        nazivPu: "Žminj",
        naselje: "Laginji",
        zupanija: "ISTARSKA",
      },
      {
        id: "3072",
        brojPu: "51500",
        redBroj: "3072",
        nazivPu: "Krk",
        naselje: "Lakmartin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3073",
        brojPu: "52440",
        redBroj: "3073",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Lakovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3074",
        brojPu: "21236",
        redBroj: "3074",
        nazivPu: "Vrlika",
        naselje: "Laktac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3075",
        brojPu: "10380",
        redBroj: "3075",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Laktec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3076",
        brojPu: "34310",
        redBroj: "3076",
        nazivPu: "Pleternica",
        naselje: "Lakušija",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3077",
        brojPu: "43000",
        redBroj: "3077",
        nazivPu: "Bjelovar",
        naselje: "Lalići",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3078",
        brojPu: "43240",
        redBroj: "3078",
        nazivPu: "Čazma",
        naselje: "Laminac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3079",
        brojPu: "42240",
        redBroj: "3079",
        nazivPu: "Ivanec",
        naselje: "Lančić",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3080",
        brojPu: "10450",
        redBroj: "3080",
        nazivPu: "Jastrebarsko",
        naselje: "Lanišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3081",
        brojPu: "52333",
        redBroj: "3081",
        nazivPu: "Podpićan",
        naselje: "Lanišće",
        zupanija: "ISTARSKA",
      },
      {
        id: "3082",
        brojPu: "52420",
        redBroj: "3082",
        nazivPu: "Buzet",
        naselje: "Lanišće",
        zupanija: "ISTARSKA",
      },
      {
        id: "3083",
        brojPu: "47300",
        redBroj: "3083",
        nazivPu: "Ogulin",
        naselje: "Lapat",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3084",
        brojPu: "47240",
        redBroj: "3084",
        nazivPu: "Slunj",
        naselje: "Lapovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3085",
        brojPu: "31400",
        redBroj: "3085",
        nazivPu: "Đakovo",
        naselje: "Lapovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3086",
        brojPu: "40313",
        redBroj: "3086",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Lapšina",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3087",
        brojPu: "47000",
        redBroj: "3087",
        nazivPu: "Karlovac",
        naselje: "Lasinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3088",
        brojPu: "47250",
        redBroj: "3088",
        nazivPu: "Duga Resa",
        naselje: "Laslavići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3089",
        brojPu: "31207",
        redBroj: "3089",
        nazivPu: "Tenja",
        naselje: "Laslovo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3090",
        brojPu: "43270",
        redBroj: "3090",
        nazivPu: "Veliki Grđevac",
        naselje: "Lasovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3091",
        brojPu: "43270",
        redBroj: "3091",
        nazivPu: "Veliki Grđevac",
        naselje: "Lasovac Brdo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3092",
        brojPu: "49231",
        redBroj: "3092",
        nazivPu: "Hum na Sutli",
        naselje: "Lastine",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3093",
        brojPu: "20290",
        redBroj: "3093",
        nazivPu: "Lastovo",
        naselje: "Lastovo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3094",
        brojPu: "52440",
        redBroj: "3094",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Lašići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3095",
        brojPu: "47300",
        redBroj: "3095",
        nazivPu: "Ogulin",
        naselje: "Latin",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3096",
        brojPu: "34340",
        redBroj: "3096",
        nazivPu: "Kutjevo",
        naselje: "Latinovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3097",
        brojPu: "51306",
        redBroj: "3097",
        nazivPu: "Čabar",
        naselje: "Lautari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3098",
        brojPu: "49246",
        redBroj: "3098",
        nazivPu: "Marija Bistrica",
        naselje: "Laz Bistrički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3099",
        brojPu: "49246",
        redBroj: "3099",
        nazivPu: "Marija Bistrica",
        naselje: "Laz Stubički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3100",
        brojPu: "51300",
        redBroj: "3100",
        nazivPu: "Delnice",
        naselje: "Lazac Lokvarski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3101",
        brojPu: "52333",
        redBroj: "3101",
        nazivPu: "Podpićan",
        naselje: "Lazarići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3102",
        brojPu: "35420",
        redBroj: "3102",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Laze",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3103",
        brojPu: "34000",
        redBroj: "3103",
        nazivPu: "Požega",
        naselje: "Laze Prnjavor",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3104",
        brojPu: "51306",
        redBroj: "3104",
        nazivPu: "Čabar",
        naselje: "Lazi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3105",
        brojPu: "49000",
        redBroj: "3105",
        nazivPu: "Krapina",
        naselje: "Lazi Krapinski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3106",
        brojPu: "10410",
        redBroj: "3106",
        nazivPu: "Velika Gorica",
        naselje: "Lazi Turopoljski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3107",
        brojPu: "10410",
        redBroj: "3107",
        nazivPu: "Velika Gorica",
        naselje: "Lazina Čička",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3108",
        brojPu: "21204",
        redBroj: "3108",
        nazivPu: "Dugopolje",
        naselje: "Lećevica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3109",
        brojPu: "51250",
        redBroj: "3109",
        nazivPu: "Novi Vinodolski",
        naselje: "Ledenice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3110",
        brojPu: "31500",
        redBroj: "3110",
        nazivPu: "Našice",
        naselje: "Ledenik",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3111",
        brojPu: "53288",
        redBroj: "3111",
        nazivPu: "Karlobag",
        naselje: "Ledenik Cesarički",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3112",
        brojPu: "10340",
        redBroj: "3112",
        nazivPu: "Vrbovec",
        naselje: "Ledina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3113",
        brojPu: "49290",
        redBroj: "3113",
        nazivPu: "Klanjec",
        naselje: "Ledine Klanječke",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3114",
        brojPu: "42204",
        redBroj: "3114",
        nazivPu: "Turčin",
        naselje: "Ledinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3115",
        brojPu: "42204",
        redBroj: "3115",
        nazivPu: "Turčin",
        naselje: "Ledinec Gornji",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3116",
        brojPu: "52440",
        redBroj: "3116",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Legovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3117",
        brojPu: "48316",
        redBroj: "3117",
        nazivPu: "Đelekovec",
        naselje: "Legrad",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3118",
        brojPu: "44250",
        redBroj: "3118",
        nazivPu: "Petrinja",
        naselje: "Lekenik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3119",
        brojPu: "10410",
        redBroj: "3119",
        nazivPu: "Velika Gorica",
        naselje: "Lekneno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3120",
        brojPu: "48260",
        redBroj: "3120",
        nazivPu: "Križevci",
        naselje: "Lemeš",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3121",
        brojPu: "48260",
        redBroj: "3121",
        nazivPu: "Križevci",
        naselje: "Lemeš Križevački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3122",
        brojPu: "49290",
        redBroj: "3122",
        nazivPu: "Klanjec",
        naselje: "Lenišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3123",
        brojPu: "49240",
        redBroj: "3123",
        nazivPu: "Donja Stubica",
        naselje: "Lepa Ves",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3124",
        brojPu: "49000",
        redBroj: "3124",
        nazivPu: "Krapina",
        naselje: "Lepajci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3125",
        brojPu: "48000",
        redBroj: "3125",
        nazivPu: "Koprivnica",
        naselje: "Lepavina",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3126",
        brojPu: "22000",
        redBroj: "3126",
        nazivPu: "Šibenik",
        naselje: "Lepenica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3127",
        brojPu: "42250",
        redBroj: "3127",
        nazivPu: "Lepoglava",
        naselje: "Lepoglava",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3128",
        brojPu: "49290",
        redBroj: "3128",
        nazivPu: "Klanjec",
        naselje: "Lepoglavec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3129",
        brojPu: "10370",
        redBroj: "3129",
        nazivPu: "Dugo Selo",
        naselje: "Leprovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3130",
        brojPu: "10310",
        redBroj: "3130",
        nazivPu: "Ivanić Grad",
        naselje: "Lepšić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3131",
        brojPu: "51326",
        redBroj: "3131",
        nazivPu: "Vrbovsko",
        naselje: "Lesci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3132",
        brojPu: "52420",
        redBroj: "3132",
        nazivPu: "Buzet",
        naselje: "Lesišćina",
        zupanija: "ISTARSKA",
      },
      {
        id: "3133",
        brojPu: "51300",
        redBroj: "3133",
        nazivPu: "Delnice",
        naselje: "Leska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3134",
        brojPu: "51300",
        redBroj: "3134",
        nazivPu: "Delnice",
        naselje: "Leskova Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3135",
        brojPu: "47250",
        redBroj: "3135",
        nazivPu: "Duga Resa",
        naselje: "Leskovac Barilovićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3136",
        brojPu: "40313",
        redBroj: "3136",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Leskovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3137",
        brojPu: "42223",
        redBroj: "3137",
        nazivPu: "Varaždinske Toplice",
        naselje: "Leskovec Toplički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3138",
        brojPu: "42202",
        redBroj: "3138",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Leštakovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3139",
        brojPu: "52333",
        redBroj: "3139",
        nazivPu: "Podpićan",
        naselje: "Letaj",
        zupanija: "ISTARSKA",
      },
      {
        id: "3140",
        brojPu: "52220",
        redBroj: "3140",
        nazivPu: "Labin",
        naselje: "Letajac",
        zupanija: "ISTARSKA",
      },
      {
        id: "3141",
        brojPu: "43000",
        redBroj: "3141",
        nazivPu: "Bjelovar",
        naselje: "Letičani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3142",
        brojPu: "53260",
        redBroj: "3142",
        nazivPu: "Brinje",
        naselje: "Letinac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3143",
        brojPu: "44010",
        redBroj: "3143",
        nazivPu: "Sisak-Caprag",
        naselje: "Letovanci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3144",
        brojPu: "44000",
        redBroj: "3144",
        nazivPu: "Sisak",
        naselje: "Letovanić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3145",
        brojPu: "49290",
        redBroj: "3145",
        nazivPu: "Klanjec",
        naselje: "Letovčan Novodvorski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3146",
        brojPu: "49290",
        redBroj: "3146",
        nazivPu: "Klanjec",
        naselje: "Letovčan Tomaševečki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3147",
        brojPu: "31400",
        redBroj: "3147",
        nazivPu: "Đakovo",
        naselje: "Levanjska Varoš",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3148",
        brojPu: "33410",
        redBroj: "3148",
        nazivPu: "Suhopolje",
        naselje: "Levinovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3149",
        brojPu: "47000",
        redBroj: "3149",
        nazivPu: "Karlovac",
        naselje: "Levkušje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3150",
        brojPu: "51300",
        redBroj: "3150",
        nazivPu: "Delnice",
        naselje: "Lič",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3151",
        brojPu: "47300",
        redBroj: "3151",
        nazivPu: "Ogulin",
        naselje: "Lička Jesenica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3152",
        brojPu: "53000",
        redBroj: "3152",
        nazivPu: "Gospić",
        naselje: "Lički Čitluk",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3153",
        brojPu: "53000",
        redBroj: "3153",
        nazivPu: "Gospić",
        naselje: "Lički Novi",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3154",
        brojPu: "53000",
        redBroj: "3154",
        nazivPu: "Gospić",
        naselje: "Lički Osik",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3155",
        brojPu: "53000",
        redBroj: "3155",
        nazivPu: "Gospić",
        naselje: "Lički Ribnik",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3156",
        brojPu: "23440",
        redBroj: "3156",
        nazivPu: "Gračac",
        naselje: "Ličko Cerje",
        zupanija: "ZADARSKA",
      },
      {
        id: "3157",
        brojPu: "53220",
        redBroj: "3157",
        nazivPu: "Otočac",
        naselje: "Ličko Lešće",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3158",
        brojPu: "31511",
        redBroj: "3158",
        nazivPu: "Đurđenovac",
        naselje: "Ličko Novo Selo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3159",
        brojPu: "53230",
        redBroj: "3159",
        nazivPu: "Korenica",
        naselje: "Ličko Petrovo Selo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3160",
        brojPu: "51415",
        redBroj: "3160",
        nazivPu: "Lovran",
        naselje: "Liganj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3161",
        brojPu: "47280",
        redBroj: "3161",
        nazivPu: "Ozalj",
        naselje: "Liješće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3162",
        brojPu: "44000",
        redBroj: "3162",
        nazivPu: "Sisak",
        naselje: "Lijeva Luka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3163",
        brojPu: "10414",
        redBroj: "3163",
        nazivPu: "Pokupsko",
        naselje: "Lijevi Degoj",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3164",
        brojPu: "10310",
        redBroj: "3164",
        nazivPu: "Ivanić Grad",
        naselje: "Lijevi Dubrovčak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3165",
        brojPu: "10414",
        redBroj: "3165",
        nazivPu: "Pokupsko",
        naselje: "Lijevi Štefanki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3166",
        brojPu: "10451",
        redBroj: "3166",
        nazivPu: "Pisarovina",
        naselje: "Lijevo Sredičko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3167",
        brojPu: "44000",
        redBroj: "3167",
        nazivPu: "Sisak",
        naselje: "Lijevo Trebarjevo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3168",
        brojPu: "44000",
        redBroj: "3168",
        nazivPu: "Sisak",
        naselje: "Lijevo Željezno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3169",
        brojPu: "31500",
        redBroj: "3169",
        nazivPu: "Našice",
        naselje: "Lila",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3170",
        brojPu: "51500",
        redBroj: "3170",
        nazivPu: "Krk",
        naselje: "Linardići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3171",
        brojPu: "52000",
        redBroj: "3171",
        nazivPu: "Pazin",
        naselje: "Lindar",
        zupanija: "ISTARSKA",
      },
      {
        id: "3172",
        brojPu: "47250",
        redBroj: "3172",
        nazivPu: "Duga Resa",
        naselje: "Lipa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3173",
        brojPu: "51211",
        redBroj: "3173",
        nazivPu: "Matulji",
        naselje: "Lipa",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3174",
        brojPu: "53260",
        redBroj: "3174",
        nazivPu: "Brinje",
        naselje: "Lipice",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3175",
        brojPu: "34550",
        redBroj: "3175",
        nazivPu: "Pakrac",
        naselje: "Lipik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3176",
        brojPu: "31500",
        redBroj: "3176",
        nazivPu: "Našice",
        naselje: "Lipine",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3177",
        brojPu: "47000",
        redBroj: "3177",
        nazivPu: "Karlovac",
        naselje: "Lipje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3178",
        brojPu: "51326",
        redBroj: "3178",
        nazivPu: "Vrbovsko",
        naselje: "Liplje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3179",
        brojPu: "10257",
        redBroj: "3179",
        nazivPu: "Brezovica",
        naselje: "Lipnica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3180",
        brojPu: "10340",
        redBroj: "3180",
        nazivPu: "Vrbovec",
        naselje: "Lipnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3181",
        brojPu: "49290",
        redBroj: "3181",
        nazivPu: "Klanjec",
        naselje: "Lipnica Zagorska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3182",
        brojPu: "47000",
        redBroj: "3182",
        nazivPu: "Karlovac",
        naselje: "Lipnik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3183",
        brojPu: "47250",
        redBroj: "3183",
        nazivPu: "Duga Resa",
        naselje: "Lipošćaki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3184",
        brojPu: "47250",
        redBroj: "3184",
        nazivPu: "Duga Resa",
        naselje: "Lipov Pesak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3185",
        brojPu: "32252",
        redBroj: "3185",
        nazivPu: "Otok",
        naselje: "Lipovac",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3186",
        brojPu: "33410",
        redBroj: "3186",
        nazivPu: "Suhopolje",
        naselje: "Lipovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3187",
        brojPu: "34550",
        redBroj: "3187",
        nazivPu: "Pakrac",
        naselje: "Lipovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3188",
        brojPu: "47240",
        redBroj: "3188",
        nazivPu: "Slunj",
        naselje: "Lipovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3189",
        brojPu: "31431",
        redBroj: "3189",
        nazivPu: "Čepin",
        naselje: "Lipovac Hrastinski",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3190",
        brojPu: "47220",
        redBroj: "3190",
        nazivPu: "Vojnić",
        naselje: "Lipovac Krstinjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3191",
        brojPu: "43500",
        redBroj: "3191",
        nazivPu: "Daruvar",
        naselje: "Lipovac Majur",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3192",
        brojPu: "32000",
        redBroj: "3192",
        nazivPu: "Vukovar",
        naselje: "Lipovača",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3193",
        brojPu: "47240",
        redBroj: "3193",
        nazivPu: "Slunj",
        naselje: "Lipovača",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3194",
        brojPu: "43000",
        redBroj: "3194",
        nazivPu: "Bjelovar",
        naselje: "Lipovčani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3195",
        brojPu: "49282",
        redBroj: "3195",
        nazivPu: "Konjščina",
        naselje: "Lipovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3196",
        brojPu: "10310",
        redBroj: "3196",
        nazivPu: "Ivanić Grad",
        naselje: "Lipovec Lonjski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3197",
        brojPu: "44330",
        redBroj: "3197",
        nazivPu: "Novska",
        naselje: "Lipovljani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3198",
        brojPu: "53220",
        redBroj: "3198",
        nazivPu: "Otočac",
        naselje: "Lipovlje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3199",
        brojPu: "42240",
        redBroj: "3199",
        nazivPu: "Ivanec",
        naselje: "Lipovnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3200",
        brojPu: "43000",
        redBroj: "3200",
        nazivPu: "Bjelovar",
        naselje: "Lipovo Brdo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3201",
        brojPu: "53202",
        redBroj: "3201",
        nazivPu: "Perušić",
        naselje: "Lipovo Polje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3202",
        brojPu: "20230",
        redBroj: "3202",
        nazivPu: "Ston",
        naselje: "Lisac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3203",
        brojPu: "51217",
        redBroj: "3203",
        nazivPu: "Klana",
        naselje: "Lisac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3204",
        brojPu: "23420",
        redBroj: "3204",
        nazivPu: "Benkovac",
        naselje: "Lisičić",
        zupanija: "ZADARSKA",
      },
      {
        id: "3205",
        brojPu: "47250",
        redBroj: "3205",
        nazivPu: "Duga Resa",
        naselje: "Lisičina Gorica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3206",
        brojPu: "33520",
        redBroj: "3206",
        nazivPu: "Slatina",
        naselje: "Lisičine",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3207",
        brojPu: "47220",
        redBroj: "3207",
        nazivPu: "Vojnić",
        naselje: "Lisine",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3208",
        brojPu: "21204",
        redBroj: "3208",
        nazivPu: "Dugopolje",
        naselje: "Liska",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3209",
        brojPu: "23420",
        redBroj: "3209",
        nazivPu: "Benkovac",
        naselje: "Lišane Ostrovičke",
        zupanija: "ZADARSKA",
      },
      {
        id: "3210",
        brojPu: "23210",
        redBroj: "3210",
        nazivPu: "Biograd na Moru",
        naselje: "Lišane Tinjske",
        zupanija: "ZADARSKA",
      },
      {
        id: "3211",
        brojPu: "47250",
        redBroj: "3211",
        nazivPu: "Duga Resa",
        naselje: "Lišnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3212",
        brojPu: "22320",
        redBroj: "3212",
        nazivPu: "Drniš",
        naselje: "Lišnjak",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3213",
        brojPu: "52424",
        redBroj: "3213",
        nazivPu: "Motovun (Montona)",
        naselje: "Livade - Levade",
        zupanija: "ISTARSKA",
      },
      {
        id: "3214",
        brojPu: "34551",
        redBroj: "3214",
        nazivPu: "Lipik",
        naselje: "Livađani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3215",
        brojPu: "31431",
        redBroj: "3215",
        nazivPu: "Čepin",
        naselje: "Livana",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3216",
        brojPu: "52100",
        redBroj: "3216",
        nazivPu: "Pula (Pola)",
        naselje: "Ližnjan - Lisignano",
        zupanija: "ISTARSKA",
      },
      {
        id: "3217",
        brojPu: "49250",
        redBroj: "3217",
        nazivPu: "Zlatar",
        naselje: "Lobor",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3218",
        brojPu: "52207",
        redBroj: "3218",
        nazivPu: "Barban",
        naselje: "Loborika",
        zupanija: "ISTARSKA",
      },
      {
        id: "3219",
        brojPu: "10450",
        redBroj: "3219",
        nazivPu: "Jastrebarsko",
        naselje: "Lokošin Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3220",
        brojPu: "21310",
        redBroj: "3220",
        nazivPu: "Omiš",
        naselje: "Lokva Rogoznica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3221",
        brojPu: "51300",
        redBroj: "3221",
        nazivPu: "Delnice",
        naselje: "Lokve",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3222",
        brojPu: "51326",
        redBroj: "3222",
        nazivPu: "Vrbovsko",
        naselje: "Lokvica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3223",
        brojPu: "21260",
        redBroj: "3223",
        nazivPu: "Imotski",
        naselje: "Lokvičići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3224",
        brojPu: "47000",
        redBroj: "3224",
        nazivPu: "Karlovac",
        naselje: "Lončar Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3225",
        brojPu: "43290",
        redBroj: "3225",
        nazivPu: "Grubišno Polje",
        naselje: "Lončarica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3226",
        brojPu: "31500",
        redBroj: "3226",
        nazivPu: "Našice",
        naselje: "Londžica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3227",
        brojPu: "44000",
        redBroj: "3227",
        nazivPu: "Sisak",
        naselje: "Lonja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3228",
        brojPu: "47000",
        redBroj: "3228",
        nazivPu: "Karlovac",
        naselje: "Lonjgari",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3229",
        brojPu: "10340",
        redBroj: "3229",
        nazivPu: "Vrbovec",
        naselje: "Lonjica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3230",
        brojPu: "51218",
        redBroj: "3230",
        nazivPu: "Dražice",
        naselje: "Lopača",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3231",
        brojPu: "51280",
        redBroj: "3231",
        nazivPu: "Rab",
        naselje: "Lopar",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3232",
        brojPu: "40000",
        redBroj: "3232",
        nazivPu: "Čakovec",
        naselje: "Lopatinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3233",
        brojPu: "20222",
        redBroj: "3233",
        nazivPu: "Lopud",
        naselje: "Lopud",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3234",
        brojPu: "47220",
        redBroj: "3234",
        nazivPu: "Vojnić",
        naselje: "Loskunja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3235",
        brojPu: "44440",
        redBroj: "3235",
        nazivPu: "Dvor",
        naselje: "Lotine",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3236",
        brojPu: "32236",
        redBroj: "3236",
        nazivPu: "Ilok",
        naselje: "Lovas",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3237",
        brojPu: "44430",
        redBroj: "3237",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Lovča",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3238",
        brojPu: "35252",
        redBroj: "3238",
        nazivPu: "Sibinj",
        naselje: "Lovčić",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3239",
        brojPu: "47280",
        redBroj: "3239",
        nazivPu: "Ozalj",
        naselje: "Lović Prekriški",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3240",
        brojPu: "23241",
        redBroj: "3240",
        nazivPu: "Poličnik",
        naselje: "Lovinac",
        zupanija: "ZADARSKA",
      },
      {
        id: "3241",
        brojPu: "23440",
        redBroj: "3241",
        nazivPu: "Gračac",
        naselje: "Lovinac",
        zupanija: "ZADARSKA",
      },
      {
        id: "3242",
        brojPu: "20250",
        redBroj: "3242",
        nazivPu: "Orebić",
        naselje: "Lovište",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3243",
        brojPu: "20355",
        redBroj: "3243",
        nazivPu: "Opuzen",
        naselje: "Lovorje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3244",
        brojPu: "20215",
        redBroj: "3244",
        nazivPu: "Gruda",
        naselje: "Lovorno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3245",
        brojPu: "51415",
        redBroj: "3245",
        nazivPu: "Lovran",
        naselje: "Lovran",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3246",
        brojPu: "51415",
        redBroj: "3246",
        nazivPu: "Lovran",
        naselje: "Lovranska Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3247",
        brojPu: "42240",
        redBroj: "3247",
        nazivPu: "Ivanec",
        naselje: "Lovrečan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3248",
        brojPu: "49246",
        redBroj: "3248",
        nazivPu: "Marija Bistrica",
        naselje: "Lovrečan",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3249",
        brojPu: "52470",
        redBroj: "3249",
        nazivPu: "Umag (Umago)",
        naselje: "Lovrečica - San Lorenzo",
        zupanija: "ISTARSKA",
      },
      {
        id: "3250",
        brojPu: "10340",
        redBroj: "3250",
        nazivPu: "Vrbovec",
        naselje: "Lovrečka Varoš",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3251",
        brojPu: "10340",
        redBroj: "3251",
        nazivPu: "Vrbovec",
        naselje: "Lovrečka Velika",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3252",
        brojPu: "21256",
        redBroj: "3252",
        nazivPu: "Cista Provo",
        naselje: "Lovreć",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3253",
        brojPu: "49217",
        redBroj: "3253",
        nazivPu: "Krapinske Toplice",
        naselje: "Lovreća Sela",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3254",
        brojPu: "42223",
        redBroj: "3254",
        nazivPu: "Varaždinske Toplice",
        naselje: "Lovrentovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3255",
        brojPu: "52000",
        redBroj: "3255",
        nazivPu: "Pazin",
        naselje: "Lovrin",
        zupanija: "ISTARSKA",
      },
      {
        id: "3256",
        brojPu: "44330",
        redBroj: "3256",
        nazivPu: "Novska",
        naselje: "Lovska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3257",
        brojPu: "33000",
        redBroj: "3257",
        nazivPu: "Virovitica",
        naselje: "Lozan",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3258",
        brojPu: "52460",
        redBroj: "3258",
        nazivPu: "Buje (Buie)",
        naselje: "Lozari - Losari",
        zupanija: "ISTARSKA",
      },
      {
        id: "3259",
        brojPu: "20236",
        redBroj: "3259",
        nazivPu: "Mokošica",
        naselje: "Lozica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3260",
        brojPu: "51557",
        redBroj: "3260",
        nazivPu: "Cres",
        naselje: "Loznati",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3261",
        brojPu: "22222",
        redBroj: "3261",
        nazivPu: "Skradin",
        naselje: "Lozovac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3262",
        brojPu: "21405",
        redBroj: "3262",
        nazivPu: "Milna",
        naselje: "Ložišća",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3263",
        brojPu: "22202",
        redBroj: "3263",
        nazivPu: "Primošten",
        naselje: "Ložnice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3264",
        brojPu: "22202",
        redBroj: "3264",
        nazivPu: "Primošten",
        naselje: "Ložnice (Milišine)",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3265",
        brojPu: "51218",
        redBroj: "3265",
        nazivPu: "Dražice",
        naselje: "Lubarska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3266",
        brojPu: "10340",
        redBroj: "3266",
        nazivPu: "Vrbovec",
        naselje: "Lubena",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3267",
        brojPu: "51557",
        redBroj: "3267",
        nazivPu: "Cres",
        naselje: "Lubenice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3268",
        brojPu: "31300",
        redBroj: "3268",
        nazivPu: "Beli Manastir",
        naselje: "Luč",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3269",
        brojPu: "21230",
        redBroj: "3269",
        nazivPu: "Sinj",
        naselje: "Lučane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3270",
        brojPu: "10451",
        redBroj: "3270",
        nazivPu: "Pisarovina",
        naselje: "Lučelnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3271",
        brojPu: "49290",
        redBroj: "3271",
        nazivPu: "Klanjec",
        naselje: "Lučelnica Tomaševečka",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3272",
        brojPu: "47250",
        redBroj: "3272",
        nazivPu: "Duga Resa",
        naselje: "Lučica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3273",
        brojPu: "51300",
        redBroj: "3273",
        nazivPu: "Delnice",
        naselje: "Lučice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3274",
        brojPu: "20355",
        redBroj: "3274",
        nazivPu: "Opuzen",
        naselje: "Lučina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3275",
        brojPu: "34000",
        redBroj: "3275",
        nazivPu: "Požega",
        naselje: "Lučinci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3276",
        brojPu: "10250",
        redBroj: "3276",
        nazivPu: "Lučko",
        naselje: "Lučko",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3277",
        brojPu: "42230",
        redBroj: "3277",
        nazivPu: "Ludbreg",
        naselje: "Ludbreg",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3278",
        brojPu: "48000",
        redBroj: "3278",
        nazivPu: "Koprivnica",
        naselje: "Ludbreški Ivanac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3279",
        brojPu: "44317",
        redBroj: "3279",
        nazivPu: "Popovača",
        naselje: "Ludinica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3280",
        brojPu: "32000",
        redBroj: "3280",
        nazivPu: "Vukovar",
        naselje: "Ludvinci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3281",
        brojPu: "31326",
        redBroj: "3281",
        nazivPu: "Darda",
        naselje: "Lug",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3282",
        brojPu: "33410",
        redBroj: "3282",
        nazivPu: "Suhopolje",
        naselje: "Lug Gradinski",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3283",
        brojPu: "49221",
        redBroj: "3283",
        nazivPu: "Bedekovčina",
        naselje: "Lug Orehovički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3284",
        brojPu: "49221",
        redBroj: "3284",
        nazivPu: "Bedekovčina",
        naselje: "Lug Poznanovečki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3285",
        brojPu: "10430",
        redBroj: "3285",
        nazivPu: "Samobor",
        naselje: "Lug Samoborski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3286",
        brojPu: "31500",
        redBroj: "3286",
        nazivPu: "Našice",
        naselje: "Lug Subotički",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3287",
        brojPu: "49210",
        redBroj: "3287",
        nazivPu: "Zabok",
        naselje: "Lug Zabočki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3288",
        brojPu: "10292",
        redBroj: "3288",
        nazivPu: "Šenkovec",
        naselje: "Lugarski Breg",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3289",
        brojPu: "10297",
        redBroj: "3289",
        nazivPu: "Jakovlje",
        naselje: "Luka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3290",
        brojPu: "10340",
        redBroj: "3290",
        nazivPu: "Vrbovec",
        naselje: "Luka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3291",
        brojPu: "20230",
        redBroj: "3291",
        nazivPu: "Ston",
        naselje: "Luka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3292",
        brojPu: "23281",
        redBroj: "3292",
        nazivPu: "Sali",
        naselje: "Luka",
        zupanija: "ZADARSKA",
      },
      {
        id: "3293",
        brojPu: "51250",
        redBroj: "3293",
        nazivPu: "Novi Vinodolski",
        naselje: "Luka Krmpotska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3294",
        brojPu: "42230",
        redBroj: "3294",
        nazivPu: "Ludbreg",
        naselje: "Luka Ludbreška",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3295",
        brojPu: "47000",
        redBroj: "3295",
        nazivPu: "Karlovac",
        naselje: "Luka Pokupska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3296",
        brojPu: "33000",
        redBroj: "3296",
        nazivPu: "Virovitica",
        naselje: "Lukač",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3297",
        brojPu: "34340",
        redBroj: "3297",
        nazivPu: "Kutjevo",
        naselje: "Lukač",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3298",
        brojPu: "48260",
        redBroj: "3298",
        nazivPu: "Križevci",
        naselje: "Lukačevec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3299",
        brojPu: "42223",
        redBroj: "3299",
        nazivPu: "Varaždinske Toplice",
        naselje: "Lukačevec Toplički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3300",
        brojPu: "22320",
        redBroj: "3300",
        nazivPu: "Drniš",
        naselje: "Lukar",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3301",
        brojPu: "10370",
        redBroj: "3301",
        nazivPu: "Dugo Selo",
        naselje: "Lukarišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3302",
        brojPu: "33520",
        redBroj: "3302",
        nazivPu: "Slatina",
        naselje: "Lukavac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3303",
        brojPu: "10410",
        redBroj: "3303",
        nazivPu: "Velika Gorica",
        naselje: "Lukavec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3304",
        brojPu: "42240",
        redBroj: "3304",
        nazivPu: "Ivanec",
        naselje: "Lukavec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3305",
        brojPu: "49290",
        redBroj: "3305",
        nazivPu: "Klanjec",
        naselje: "Lukavec Klanječki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3306",
        brojPu: "44000",
        redBroj: "3306",
        nazivPu: "Sisak",
        naselje: "Lukavec Posavski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3307",
        brojPu: "10292",
        redBroj: "3307",
        nazivPu: "Šenkovec",
        naselje: "Lukavec Sutlanski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3308",
        brojPu: "47240",
        redBroj: "3308",
        nazivPu: "Slunj",
        naselje: "Luke",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3309",
        brojPu: "49290",
        redBroj: "3309",
        nazivPu: "Klanjec",
        naselje: "Luke Poljanske",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3310",
        brojPu: "51218",
        redBroj: "3310",
        nazivPu: "Dražice",
        naselje: "Lukeži",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3311",
        brojPu: "10414",
        redBroj: "3311",
        nazivPu: "Pokupsko",
        naselje: "Lukinić Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3312",
        brojPu: "23273",
        redBroj: "3312",
        nazivPu: "Preko",
        naselje: "Lukoran",
        zupanija: "ZADARSKA",
      },
      {
        id: "3313",
        brojPu: "49225",
        redBroj: "3313",
        nazivPu: "Đurmanec",
        naselje: "Lukovčak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3314",
        brojPu: "51326",
        redBroj: "3314",
        nazivPu: "Vrbovsko",
        naselje: "Lukovdol",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3315",
        brojPu: "48000",
        redBroj: "3315",
        nazivPu: "Koprivnica",
        naselje: "Lukovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3316",
        brojPu: "10340",
        redBroj: "3316",
        nazivPu: "Vrbovec",
        naselje: "Lukovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3317",
        brojPu: "53270",
        redBroj: "3317",
        nazivPu: "Senj",
        naselje: "Lukovo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3318",
        brojPu: "53288",
        redBroj: "3318",
        nazivPu: "Karlobag",
        naselje: "Lukovo Šugarje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3319",
        brojPu: "47280",
        redBroj: "3319",
        nazivPu: "Ozalj",
        naselje: "Lukšići Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3320",
        brojPu: "47280",
        redBroj: "3320",
        nazivPu: "Ozalj",
        naselje: "Lukunić Draga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3321",
        brojPu: "20260",
        redBroj: "3321",
        nazivPu: "Korčula",
        naselje: "Lumbarda",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3322",
        brojPu: "47240",
        redBroj: "3322",
        nazivPu: "Slunj",
        naselje: "Lumbardenik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3323",
        brojPu: "53291",
        redBroj: "3323",
        nazivPu: "Novalja",
        naselje: "Lun",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3324",
        brojPu: "42230",
        redBroj: "3324",
        nazivPu: "Ludbreg",
        naselje: "Lunjkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3325",
        brojPu: "49231",
        redBroj: "3325",
        nazivPu: "Hum na Sutli",
        naselje: "Lupinjak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3326",
        brojPu: "10370",
        redBroj: "3326",
        nazivPu: "Dugo Selo",
        naselje: "Lupoglav",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3327",
        brojPu: "52420",
        redBroj: "3327",
        nazivPu: "Buzet",
        naselje: "Lupoglav",
        zupanija: "ISTARSKA",
      },
      {
        id: "3328",
        brojPu: "44250",
        redBroj: "3328",
        nazivPu: "Petrinja",
        naselje: "Luščani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3329",
        brojPu: "10363",
        redBroj: "3329",
        nazivPu: "Belovar",
        naselje: "Lužan",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3330",
        brojPu: "42204",
        redBroj: "3330",
        nazivPu: "Turčin",
        naselje: "Lužan Biškupečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3331",
        brojPu: "35250",
        redBroj: "3331",
        nazivPu: "Oriovac",
        naselje: "Lužani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3332",
        brojPu: "49225",
        redBroj: "3332",
        nazivPu: "Đurmanec",
        naselje: "Lužani Zagorski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3333",
        brojPu: "10290",
        redBroj: "3333",
        nazivPu: "Zaprešić",
        naselje: "Lužnica",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3334",
        brojPu: "44440",
        redBroj: "3334",
        nazivPu: "Dvor",
        naselje: "Ljeskovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3335",
        brojPu: "35430",
        redBroj: "3335",
        nazivPu: "Okučani",
        naselje: "Lještani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3336",
        brojPu: "20232",
        redBroj: "3336",
        nazivPu: "Slano",
        naselje: "Ljubač",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3337",
        brojPu: "22300",
        redBroj: "3337",
        nazivPu: "Knin",
        naselje: "Ljubač",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3338",
        brojPu: "23248",
        redBroj: "3338",
        nazivPu: "Ražanac",
        naselje: "Ljubač",
        zupanija: "ZADARSKA",
      },
      {
        id: "3339",
        brojPu: "42220",
        redBroj: "3339",
        nazivPu: "Novi Marof",
        naselje: "Ljubelj",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3340",
        brojPu: "42220",
        redBroj: "3340",
        nazivPu: "Novi Marof",
        naselje: "Ljubelj Kalnički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3341",
        brojPu: "42220",
        redBroj: "3341",
        nazivPu: "Novi Marof",
        naselje: "Ljubešćica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3342",
        brojPu: "44440",
        redBroj: "3342",
        nazivPu: "Dvor",
        naselje: "Ljubina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3343",
        brojPu: "21220",
        redBroj: "3343",
        nazivPu: "Trogir",
        naselje: "Ljubitovica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3344",
        brojPu: "44000",
        redBroj: "3344",
        nazivPu: "Sisak",
        naselje: "Ljubljanica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3345",
        brojPu: "22323",
        redBroj: "3345",
        nazivPu: "Unešić",
        naselje: "Ljubostinje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3346",
        brojPu: "51326",
        redBroj: "3346",
        nazivPu: "Vrbovsko",
        naselje: "Ljubošina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3347",
        brojPu: "22320",
        redBroj: "3347",
        nazivPu: "Drniš",
        naselje: "Ljubotić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3348",
        brojPu: "43500",
        redBroj: "3348",
        nazivPu: "Daruvar",
        naselje: "Ljudevit Selo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3349",
        brojPu: "35400",
        redBroj: "3349",
        nazivPu: "Nova Gradiška",
        naselje: "Ljupina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3350",
        brojPu: "21256",
        redBroj: "3350",
        nazivPu: "Cista Provo",
        naselje: "Ljut",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3351",
        brojPu: "20215",
        redBroj: "3351",
        nazivPu: "Gruda",
        naselje: "Ljuta",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3352",
        brojPu: "51511",
        redBroj: "3352",
        nazivPu: "Malinska",
        naselje: "Ljutići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3353",
        brojPu: "40000",
        redBroj: "3353",
        nazivPu: "Čakovec",
        naselje: "Macinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3354",
        brojPu: "33520",
        redBroj: "3354",
        nazivPu: "Slatina",
        naselje: "Macute",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3355",
        brojPu: "49250",
        redBroj: "3355",
        nazivPu: "Zlatar",
        naselje: "Mače",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3356",
        brojPu: "10342",
        redBroj: "3356",
        nazivPu: "Dubrava",
        naselje: "Mački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3357",
        brojPu: "33520",
        redBroj: "3357",
        nazivPu: "Slatina",
        naselje: "Mačkovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3358",
        brojPu: "35400",
        redBroj: "3358",
        nazivPu: "Nova Gradiška",
        naselje: "Mačkovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3359",
        brojPu: "40000",
        redBroj: "3359",
        nazivPu: "Čakovec",
        naselje: "Mačkovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3360",
        brojPu: "44250",
        redBroj: "3360",
        nazivPu: "Petrinja",
        naselje: "Mačkovo Selo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3361",
        brojPu: "42230",
        redBroj: "3361",
        nazivPu: "Ludbreg",
        naselje: "Madaraševec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3362",
        brojPu: "42220",
        redBroj: "3362",
        nazivPu: "Novi Marof",
        naselje: "Madžarevo",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3363",
        brojPu: "44010",
        redBroj: "3363",
        nazivPu: "Sisak-Caprag",
        naselje: "Madžari",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3364",
        brojPu: "31540",
        redBroj: "3364",
        nazivPu: "Donji Miholjac",
        naselje: "Magadenovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3365",
        brojPu: "33515",
        redBroj: "3365",
        nazivPu: "Orahovica",
        naselje: "Magadinovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3366",
        brojPu: "35410",
        redBroj: "3366",
        nazivPu: "Nova Kapela",
        naselje: "Magić Mala",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3367",
        brojPu: "43000",
        redBroj: "3367",
        nazivPu: "Bjelovar",
        naselje: "Maglenča",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3368",
        brojPu: "47000",
        redBroj: "3368",
        nazivPu: "Karlovac",
        naselje: "Mahićno",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3369",
        brojPu: "44000",
        redBroj: "3369",
        nazivPu: "Sisak",
        naselje: "Mahovo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3370",
        brojPu: "44400",
        redBroj: "3370",
        nazivPu: "Glina",
        naselje: "Maja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3371",
        brojPu: "31400",
        redBroj: "3371",
        nazivPu: "Đakovo",
        naselje: "Majar",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3372",
        brojPu: "44440",
        redBroj: "3372",
        nazivPu: "Dvor",
        naselje: "Majdan",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3373",
        brojPu: "51326",
        redBroj: "3373",
        nazivPu: "Vrbovsko",
        naselje: "Majer",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3374",
        brojPu: "42208",
        redBroj: "3374",
        nazivPu: "Cestica",
        naselje: "Majerje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3375",
        brojPu: "10380",
        redBroj: "3375",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Majkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3376",
        brojPu: "20232",
        redBroj: "3376",
        nazivPu: "Slano",
        naselje: "Majkovi",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3377",
        brojPu: "52440",
        redBroj: "3377",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Majkusi",
        zupanija: "ISTARSKA",
      },
      {
        id: "3378",
        brojPu: "44400",
        redBroj: "3378",
        nazivPu: "Glina",
        naselje: "Majske Poljane",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3379",
        brojPu: "44400",
        redBroj: "3379",
        nazivPu: "Glina",
        naselje: "Majski Trtnik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3380",
        brojPu: "31300",
        redBroj: "3380",
        nazivPu: "Beli Manastir",
        naselje: "Majške Međe",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3381",
        brojPu: "10342",
        redBroj: "3381",
        nazivPu: "Dubrava",
        naselje: "Majur",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3382",
        brojPu: "44430",
        redBroj: "3382",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Majur",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3383",
        brojPu: "48260",
        redBroj: "3383",
        nazivPu: "Križevci",
        naselje: "Majurec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3384",
        brojPu: "21300",
        redBroj: "3384",
        nazivPu: "Makarska",
        naselje: "Makarska",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3385",
        brojPu: "51326",
        redBroj: "3385",
        nazivPu: "Vrbovsko",
        naselje: "Maklen",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3386",
        brojPu: "31500",
        redBroj: "3386",
        nazivPu: "Našice",
        naselje: "Makloševac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3387",
        brojPu: "20207",
        redBroj: "3387",
        nazivPu: "Mlini",
        naselje: "Makoše",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3388",
        brojPu: "51306",
        redBroj: "3388",
        nazivPu: "Čabar",
        naselje: "Makov Hrib",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3389",
        brojPu: "52460",
        redBroj: "3389",
        nazivPu: "Buje (Buie)",
        naselje: "Makovci - Macovzi",
        zupanija: "ISTARSKA",
      },
      {
        id: "3390",
        brojPu: "43500",
        redBroj: "3390",
        nazivPu: "Daruvar",
        naselje: "Mala Babina Gora",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3391",
        brojPu: "43290",
        redBroj: "3391",
        nazivPu: "Grubišno Polje",
        naselje: "Mala Barna",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3392",
        brojPu: "48000",
        redBroj: "3392",
        nazivPu: "Koprivnica",
        naselje: "Mala Branjska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3393",
        brojPu: "43280",
        redBroj: "3393",
        nazivPu: "Garešnica",
        naselje: "Mala Bršljanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3394",
        brojPu: "10413",
        redBroj: "3394",
        nazivPu: "Kravarsko",
        naselje: "Mala Buna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3395",
        brojPu: "43000",
        redBroj: "3395",
        nazivPu: "Bjelovar",
        naselje: "Mala Ciglena",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3396",
        brojPu: "47220",
        redBroj: "3396",
        nazivPu: "Vojnić",
        naselje: "Mala Crkvina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3397",
        brojPu: "33405",
        redBroj: "3397",
        nazivPu: "Pitomača",
        naselje: "Mala Črešnjevica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3398",
        brojPu: "43290",
        redBroj: "3398",
        nazivPu: "Grubišno Polje",
        naselje: "Mala Dapčevica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3399",
        brojPu: "51300",
        redBroj: "3399",
        nazivPu: "Delnice",
        naselje: "Mala Dobra",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3400",
        brojPu: "49217",
        redBroj: "3400",
        nazivPu: "Krapinske Toplice",
        naselje: "Mala Erpenja",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3401",
        brojPu: "49218",
        redBroj: "3401",
        nazivPu: "Pregrada",
        naselje: "Mala Gora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3402",
        brojPu: "10431",
        redBroj: "3402",
        nazivPu: "Sveta Nedelja",
        naselje: "Mala Gorica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3403",
        brojPu: "44250",
        redBroj: "3403",
        nazivPu: "Petrinja",
        naselje: "Mala Gorica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3404",
        brojPu: "44210",
        redBroj: "3404",
        nazivPu: "Sunja",
        naselje: "Mala Gradusa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3405",
        brojPu: "10315",
        redBroj: "3405",
        nazivPu: "Novoselec",
        naselje: "Mala Hrastilnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3406",
        brojPu: "52420",
        redBroj: "3406",
        nazivPu: "Buzet",
        naselje: "Mala Huba",
        zupanija: "ISTARSKA",
      },
      {
        id: "3407",
        brojPu: "43290",
        redBroj: "3407",
        nazivPu: "Grubišno Polje",
        naselje: "Mala Jasenovača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3408",
        brojPu: "10430",
        redBroj: "3408",
        nazivPu: "Samobor",
        naselje: "Mala Jazbina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3409",
        brojPu: "33410",
        redBroj: "3409",
        nazivPu: "Suhopolje",
        naselje: "Mala Klisa",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3410",
        brojPu: "35214",
        redBroj: "3410",
        nazivPu: "Donji Andrijevci",
        naselje: "Mala Kopanica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3411",
        brojPu: "47250",
        redBroj: "3411",
        nazivPu: "Duga Resa",
        naselje: "Mala Kosa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3412",
        brojPu: "10410",
        redBroj: "3412",
        nazivPu: "Velika Gorica",
        naselje: "Mala Kosnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3413",
        brojPu: "51300",
        redBroj: "3413",
        nazivPu: "Delnice",
        naselje: "Mala Lešnica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3414",
        brojPu: "44317",
        redBroj: "3414",
        nazivPu: "Popovača",
        naselje: "Mala Ludina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3415",
        brojPu: "21203",
        redBroj: "3415",
        nazivPu: "Donji Muć",
        naselje: "Mala Milešina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3416",
        brojPu: "10020",
        redBroj: "3416",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Mala Mlaka",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3417",
        brojPu: "43280",
        redBroj: "3417",
        nazivPu: "Garešnica",
        naselje: "Mala Mlinska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3418",
        brojPu: "48000",
        redBroj: "3418",
        nazivPu: "Koprivnica",
        naselje: "Mala Mučna",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3419",
        brojPu: "10370",
        redBroj: "3419",
        nazivPu: "Dugo Selo",
        naselje: "Mala Ostrna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3420",
        brojPu: "49000",
        redBroj: "3420",
        nazivPu: "Krapina",
        naselje: "Mala Pačetina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3421",
        brojPu: "47000",
        redBroj: "3421",
        nazivPu: "Karlovac",
        naselje: "Mala Paka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3422",
        brojPu: "44210",
        redBroj: "3422",
        nazivPu: "Sunja",
        naselje: "Mala Paukova",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3423",
        brojPu: "43290",
        redBroj: "3423",
        nazivPu: "Grubišno Polje",
        naselje: "Mala Peratovica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3424",
        brojPu: "43270",
        redBroj: "3424",
        nazivPu: "Veliki Grđevac",
        naselje: "Mala Pisanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3425",
        brojPu: "53000",
        redBroj: "3425",
        nazivPu: "Gospić",
        naselje: "Mala Plana",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3426",
        brojPu: "10430",
        redBroj: "3426",
        nazivPu: "Samobor",
        naselje: "Mala Rakovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3427",
        brojPu: "48000",
        redBroj: "3427",
        nazivPu: "Koprivnica",
        naselje: "Mala Rasinjica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3428",
        brojPu: "48000",
        redBroj: "3428",
        nazivPu: "Koprivnica",
        naselje: "Mala Rijeka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3429",
        brojPu: "44400",
        redBroj: "3429",
        nazivPu: "Glina",
        naselje: "Mala Solina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3430",
        brojPu: "40000",
        redBroj: "3430",
        nazivPu: "Čakovec",
        naselje: "Mala Subotica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3431",
        brojPu: "33410",
        redBroj: "3431",
        nazivPu: "Suhopolje",
        naselje: "Mala Trapinska",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3432",
        brojPu: "43280",
        redBroj: "3432",
        nazivPu: "Garešnica",
        naselje: "Mala Trnovitica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3433",
        brojPu: "51410",
        redBroj: "3433",
        nazivPu: "Opatija",
        naselje: "Mala Učka (Mošćenička Draga)",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3434",
        brojPu: "51410",
        redBroj: "3434",
        nazivPu: "Opatija",
        naselje: "Mala Učka (Opatija)",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3435",
        brojPu: "44400",
        redBroj: "3435",
        nazivPu: "Glina",
        naselje: "Mala Vranovina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3436",
        brojPu: "51326",
        redBroj: "3436",
        nazivPu: "Vrbovsko",
        naselje: "Male Drage",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3437",
        brojPu: "51211",
        redBroj: "3437",
        nazivPu: "Matulji",
        naselje: "Male Mune",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3438",
        brojPu: "48260",
        redBroj: "3438",
        nazivPu: "Križevci",
        naselje: "Male Sesvete",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3439",
        brojPu: "51550",
        redBroj: "3439",
        nazivPu: "Mali Lošinj",
        naselje: "Male Srakane",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3440",
        brojPu: "47220",
        redBroj: "3440",
        nazivPu: "Vojnić",
        naselje: "Malešević Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3441",
        brojPu: "47250",
        redBroj: "3441",
        nazivPu: "Duga Resa",
        naselje: "Maletići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3442",
        brojPu: "34550",
        redBroj: "3442",
        nazivPu: "Pakrac",
        naselje: "Mali Banovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3443",
        brojPu: "43500",
        redBroj: "3443",
        nazivPu: "Daruvar",
        naselje: "Mali Bastaji",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3444",
        brojPu: "34340",
        redBroj: "3444",
        nazivPu: "Kutjevo",
        naselje: "Mali Bilač",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3445",
        brojPu: "48000",
        redBroj: "3445",
        nazivPu: "Koprivnica",
        naselje: "Mali Botinovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3446",
        brojPu: "10340",
        redBroj: "3446",
        nazivPu: "Vrbovec",
        naselje: "Mali Brezovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3447",
        brojPu: "51211",
        redBroj: "3447",
        nazivPu: "Matulji",
        naselje: "Mali Brgud",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3448",
        brojPu: "34550",
        redBroj: "3448",
        nazivPu: "Pakrac",
        naselje: "Mali Budići",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3449",
        brojPu: "42230",
        redBroj: "3449",
        nazivPu: "Ludbreg",
        naselje: "Mali Bukovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3450",
        brojPu: "49250",
        redBroj: "3450",
        nazivPu: "Zlatar",
        naselje: "Mali Bukovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3451",
        brojPu: "48260",
        redBroj: "3451",
        nazivPu: "Križevci",
        naselje: "Mali Carevdar",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3452",
        brojPu: "51262",
        redBroj: "3452",
        nazivPu: "Kraljevica",
        naselje: "Mali Dol",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3453",
        brojPu: "47280",
        redBroj: "3453",
        nazivPu: "Ozalj",
        naselje: "Mali Erjavec",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3454",
        brojPu: "52220",
        redBroj: "3454",
        nazivPu: "Labin",
        naselje: "Mali Golji",
        zupanija: "ISTARSKA",
      },
      {
        id: "3455",
        brojPu: "42250",
        redBroj: "3455",
        nazivPu: "Lepoglava",
        naselje: "Mali Gorenec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3456",
        brojPu: "48000",
        redBroj: "3456",
        nazivPu: "Koprivnica",
        naselje: "Mali Grabičani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3457",
        brojPu: "44400",
        redBroj: "3457",
        nazivPu: "Glina",
        naselje: "Mali Gradac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3458",
        brojPu: "43270",
        redBroj: "3458",
        nazivPu: "Veliki Grđevac",
        naselje: "Mali Grđevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3459",
        brojPu: "23284",
        redBroj: "3459",
        nazivPu: "Veli Iž",
        naselje: "Mali Iž",
        zupanija: "ZADARSKA",
      },
      {
        id: "3460",
        brojPu: "51326",
        redBroj: "3460",
        nazivPu: "Vrbovsko",
        naselje: "Mali Jadrč",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3461",
        brojPu: "49250",
        redBroj: "3461",
        nazivPu: "Zlatar",
        naselje: "Mali Komor",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3462",
        brojPu: "47250",
        redBroj: "3462",
        nazivPu: "Duga Resa",
        naselje: "Mali Kozinac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3463",
        brojPu: "10430",
        redBroj: "3463",
        nazivPu: "Samobor",
        naselje: "Mali Lipovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3464",
        brojPu: "51550",
        redBroj: "3464",
        nazivPu: "Mali Lošinj",
        naselje: "Mali Lošinj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3465",
        brojPu: "42208",
        redBroj: "3465",
        nazivPu: "Cestica",
        naselje: "Mali Lovrečan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3466",
        brojPu: "51306",
        redBroj: "3466",
        nazivPu: "Čabar",
        naselje: "Mali Lug",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3467",
        brojPu: "40000",
        redBroj: "3467",
        nazivPu: "Čakovec",
        naselje: "Mali Mihaljevec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3468",
        brojPu: "43500",
        redBroj: "3468",
        nazivPu: "Daruvar",
        naselje: "Mali Miletinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3469",
        brojPu: "52420",
        redBroj: "3469",
        nazivPu: "Buzet",
        naselje: "Mali Mlun",
        zupanija: "ISTARSKA",
      },
      {
        id: "3470",
        brojPu: "47000",
        redBroj: "3470",
        nazivPu: "Karlovac",
        naselje: "Mali Modruš Potok",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3471",
        brojPu: "44400",
        redBroj: "3471",
        nazivPu: "Glina",
        naselje: "Mali Obljaj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3472",
        brojPu: "48316",
        redBroj: "3472",
        nazivPu: "Đelekovec",
        naselje: "Mali Otok",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3473",
        brojPu: "43280",
        redBroj: "3473",
        nazivPu: "Garešnica",
        naselje: "Mali Pašijan",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3474",
        brojPu: "51557",
        redBroj: "3474",
        nazivPu: "Cres",
        naselje: "Mali Podol",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3475",
        brojPu: "48000",
        redBroj: "3475",
        nazivPu: "Koprivnica",
        naselje: "Mali Poganac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3476",
        brojPu: "48260",
        redBroj: "3476",
        nazivPu: "Križevci",
        naselje: "Mali Potočec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3477",
        brojPu: "20350",
        redBroj: "3477",
        nazivPu: "Metković",
        naselje: "Mali Prolog",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3478",
        brojPu: "33515",
        redBroj: "3478",
        nazivPu: "Orahovica",
        naselje: "Mali Rastovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3479",
        brojPu: "48260",
        redBroj: "3479",
        nazivPu: "Križevci",
        naselje: "Mali Raven",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3480",
        brojPu: "20230",
        redBroj: "3480",
        nazivPu: "Ston",
        naselje: "Mali Ston",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3481",
        brojPu: "49231",
        redBroj: "3481",
        nazivPu: "Hum na Sutli",
        naselje: "Mali Tabor",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3482",
        brojPu: "52220",
        redBroj: "3482",
        nazivPu: "Labin",
        naselje: "Mali Turini",
        zupanija: "ISTARSKA",
      },
      {
        id: "3483",
        brojPu: "52207",
        redBroj: "3483",
        nazivPu: "Barban",
        naselje: "Mali Vareški",
        zupanija: "ISTARSKA",
      },
      {
        id: "3484",
        brojPu: "47280",
        redBroj: "3484",
        nazivPu: "Ozalj",
        naselje: "Mali Vrh Kamanjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3485",
        brojPu: "47240",
        redBroj: "3485",
        nazivPu: "Slunj",
        naselje: "Mali Vuković",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3486",
        brojPu: "43290",
        redBroj: "3486",
        nazivPu: "Grubišno Polje",
        naselje: "Mali Zdenci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3487",
        brojPu: "44400",
        redBroj: "3487",
        nazivPu: "Glina",
        naselje: "Malička",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3488",
        brojPu: "47250",
        redBroj: "3488",
        nazivPu: "Duga Resa",
        naselje: "Malik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3489",
        brojPu: "47280",
        redBroj: "3489",
        nazivPu: "Ozalj",
        naselje: "Malinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3490",
        brojPu: "35250",
        redBroj: "3490",
        nazivPu: "Oriovac",
        naselje: "Malino",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3491",
        brojPu: "31540",
        redBroj: "3491",
        nazivPu: "Donji Miholjac",
        naselje: "Malinovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3492",
        brojPu: "51511",
        redBroj: "3492",
        nazivPu: "Malinska",
        naselje: "Malinska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3493",
        brojPu: "52440",
        redBroj: "3493",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mališi",
        zupanija: "ISTARSKA",
      },
      {
        id: "3494",
        brojPu: "21450",
        redBroj: "3494",
        nazivPu: "Hvar",
        naselje: "Malo Grablje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3495",
        brojPu: "42208",
        redBroj: "3495",
        nazivPu: "Cestica",
        naselje: "Malo Gradišće",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3496",
        brojPu: "43000",
        redBroj: "3496",
        nazivPu: "Bjelovar",
        naselje: "Malo Korenovo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3497",
        brojPu: "44430",
        redBroj: "3497",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Malo Krčevo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3498",
        brojPu: "53202",
        redBroj: "3498",
        nazivPu: "Perušić",
        naselje: "Malo Polje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3499",
        brojPu: "51300",
        redBroj: "3499",
        nazivPu: "Delnice",
        naselje: "Malo Selce",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3500",
        brojPu: "51300",
        redBroj: "3500",
        nazivPu: "Delnice",
        naselje: "Malo Selo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3501",
        brojPu: "43000",
        redBroj: "3501",
        nazivPu: "Bjelovar",
        naselje: "Malo Trojstvo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3502",
        brojPu: "43280",
        redBroj: "3502",
        nazivPu: "Garešnica",
        naselje: "Malo Vukovje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3503",
        brojPu: "10450",
        redBroj: "3503",
        nazivPu: "Jastrebarsko",
        naselje: "Malunje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3504",
        brojPu: "47240",
        redBroj: "3504",
        nazivPu: "Slunj",
        naselje: "Maljevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3505",
        brojPu: "47240",
        redBroj: "3505",
        nazivPu: "Slunj",
        naselje: "Maljevačko Selište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3506",
        brojPu: "21233",
        redBroj: "3506",
        nazivPu: "Hrvace",
        naselje: "Maljkovo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3507",
        brojPu: "52000",
        redBroj: "3507",
        nazivPu: "Pazin",
        naselje: "Mandalenčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3508",
        brojPu: "20207",
        redBroj: "3508",
        nazivPu: "Mlini",
        naselje: "Mandaljena",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3509",
        brojPu: "47220",
        redBroj: "3509",
        nazivPu: "Vojnić",
        naselje: "Mandić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3510",
        brojPu: "31400",
        redBroj: "3510",
        nazivPu: "Đakovo",
        naselje: "Mandićevac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3511",
        brojPu: "51306",
        redBroj: "3511",
        nazivPu: "Čabar",
        naselje: "Mandli",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3512",
        brojPu: "23250",
        redBroj: "3512",
        nazivPu: "Pag",
        naselje: "Mandre",
        zupanija: "ZADARSKA",
      },
      {
        id: "3513",
        brojPu: "10430",
        redBroj: "3513",
        nazivPu: "Samobor",
        naselje: "Manja Vas",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3514",
        brojPu: "52207",
        redBroj: "3514",
        nazivPu: "Barban",
        naselje: "Manjadvorci",
        zupanija: "ISTARSKA",
      },
      {
        id: "3515",
        brojPu: "47000",
        redBroj: "3515",
        nazivPu: "Karlovac",
        naselje: "Manjerovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3516",
        brojPu: "21236",
        redBroj: "3516",
        nazivPu: "Vrlika",
        naselje: "Maovice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3517",
        brojPu: "20225",
        redBroj: "3517",
        nazivPu: "Babino Polje",
        naselje: "Maranovići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3518",
        brojPu: "52440",
        redBroj: "3518",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Marasi",
        zupanija: "ISTARSKA",
      },
      {
        id: "3519",
        brojPu: "52220",
        redBroj: "3519",
        nazivPu: "Labin",
        naselje: "Marceljani",
        zupanija: "ISTARSKA",
      },
      {
        id: "3520",
        brojPu: "42243",
        redBroj: "3520",
        nazivPu: "Maruševec",
        naselje: "Marčan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3521",
        brojPu: "52207",
        redBroj: "3521",
        nazivPu: "Barban",
        naselje: "Marčana",
        zupanija: "ISTARSKA",
      },
      {
        id: "3522",
        brojPu: "43240",
        redBroj: "3522",
        nazivPu: "Čazma",
        naselje: "Marčani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3523",
        brojPu: "51216",
        redBroj: "3523",
        nazivPu: "Viškovo",
        naselje: "Marčelji",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3524",
        brojPu: "52420",
        redBroj: "3524",
        nazivPu: "Buzet",
        naselje: "Marčenegla",
        zupanija: "ISTARSKA",
      },
      {
        id: "3525",
        brojPu: "52466",
        redBroj: "3525",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Mareda - Mareda",
        zupanija: "ISTARSKA",
      },
      {
        id: "3526",
        brojPu: "10340",
        redBroj: "3526",
        nazivPu: "Vrbovec",
        naselje: "Marenić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3527",
        brojPu: "49282",
        redBroj: "3527",
        nazivPu: "Konjščina",
        naselje: "Maretić",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3528",
        brojPu: "52440",
        redBroj: "3528",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Maretići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3529",
        brojPu: "42240",
        redBroj: "3529",
        nazivPu: "Ivanec",
        naselje: "Margečan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3530",
        brojPu: "52220",
        redBroj: "3530",
        nazivPu: "Labin",
        naselje: "Marići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3531",
        brojPu: "52341",
        redBroj: "3531",
        nazivPu: "Žminj",
        naselje: "Marići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3532",
        brojPu: "49284",
        redBroj: "3532",
        nazivPu: "Budinščina",
        naselje: "Marigutić",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3533",
        brojPu: "49246",
        redBroj: "3533",
        nazivPu: "Marija Bistrica",
        naselje: "Marija Bistrica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3534",
        brojPu: "10291",
        redBroj: "3534",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Marija Gorica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3535",
        brojPu: "10290",
        redBroj: "3535",
        nazivPu: "Zaprešić",
        naselje: "Marija Magdalena",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3536",
        brojPu: "51300",
        redBroj: "3536",
        nazivPu: "Delnice",
        naselje: "Marija Trošt",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3537",
        brojPu: "31551",
        redBroj: "3537",
        nazivPu: "Belišće",
        naselje: "Marijanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3538",
        brojPu: "21222",
        redBroj: "3538",
        nazivPu: "Marina",
        naselje: "Marina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3539",
        brojPu: "44400",
        redBroj: "3539",
        nazivPu: "Glina",
        naselje: "Marinbrod",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3540",
        brojPu: "32100",
        redBroj: "3540",
        nazivPu: "Vinkovci",
        naselje: "Marinci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3541",
        brojPu: "52420",
        redBroj: "3541",
        nazivPu: "Buzet",
        naselje: "Marinci",
        zupanija: "ISTARSKA",
      },
      {
        id: "3542",
        brojPu: "47240",
        redBroj: "3542",
        nazivPu: "Slunj",
        naselje: "Marindolsko Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3543",
        brojPu: "34000",
        redBroj: "3543",
        nazivPu: "Požega",
        naselje: "Marindvor",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3544",
        brojPu: "49218",
        redBroj: "3544",
        nazivPu: "Pregrada",
        naselje: "Marinec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3545",
        brojPu: "51216",
        redBroj: "3545",
        nazivPu: "Viškovo",
        naselje: "Marinići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3546",
        brojPu: "34551",
        redBroj: "3546",
        nazivPu: "Lipik",
        naselje: "Marino Selo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3547",
        brojPu: "48260",
        redBroj: "3547",
        nazivPu: "Križevci",
        naselje: "Marinovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3548",
        brojPu: "10380",
        redBroj: "3548",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Marinovec Zelinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3549",
        brojPu: "21480",
        redBroj: "3549",
        nazivPu: "Vis",
        naselje: "Marinje Zemlje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3550",
        brojPu: "31550",
        redBroj: "3550",
        nazivPu: "Valpovo",
        naselje: "Marjančaci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3551",
        brojPu: "31551",
        redBroj: "3551",
        nazivPu: "Belišće",
        naselje: "Marjanski Ivanovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3552",
        brojPu: "52220",
        redBroj: "3552",
        nazivPu: "Labin",
        naselje: "Markoci",
        zupanija: "ISTARSKA",
      },
      {
        id: "3553",
        brojPu: "22300",
        redBroj: "3553",
        nazivPu: "Knin",
        naselje: "Markovac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3554",
        brojPu: "34000",
        redBroj: "3554",
        nazivPu: "Požega",
        naselje: "Markovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3555",
        brojPu: "43500",
        redBroj: "3555",
        nazivPu: "Daruvar",
        naselje: "Markovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3556",
        brojPu: "52440",
        redBroj: "3556",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Markovac",
        zupanija: "ISTARSKA",
      },
      {
        id: "3557",
        brojPu: "48214",
        redBroj: "3557",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Markovac Križevački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3558",
        brojPu: "31500",
        redBroj: "3558",
        nazivPu: "Našice",
        naselje: "Markovac Našički",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3559",
        brojPu: "47300",
        redBroj: "3559",
        nazivPu: "Ogulin",
        naselje: "Marković Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3560",
        brojPu: "52440",
        redBroj: "3560",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Markovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3561",
        brojPu: "33520",
        redBroj: "3561",
        nazivPu: "Slatina",
        naselje: "Markovo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3562",
        brojPu: "10360",
        redBroj: "3562",
        nazivPu: "Sesvete",
        naselje: "Markovo Polje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3563",
        brojPu: "49250",
        redBroj: "3563",
        nazivPu: "Zlatar",
        naselje: "Markušbrijeg",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3564",
        brojPu: "10410",
        redBroj: "3564",
        nazivPu: "Velika Gorica",
        naselje: "Markuševec Turopoljski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3565",
        brojPu: "32100",
        redBroj: "3565",
        nazivPu: "Vinkovci",
        naselje: "Markušica",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3566",
        brojPu: "10454",
        redBroj: "3566",
        nazivPu: "Krašić",
        naselje: "Markušići",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3567",
        brojPu: "47250",
        redBroj: "3567",
        nazivPu: "Duga Resa",
        naselje: "Marlovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3568",
        brojPu: "40313",
        redBroj: "3568",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Marof",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3569",
        brojPu: "51511",
        redBroj: "3569",
        nazivPu: "Malinska",
        naselje: "Maršići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3570",
        brojPu: "42230",
        redBroj: "3570",
        nazivPu: "Ludbreg",
        naselje: "Martijanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3571",
        brojPu: "31500",
        redBroj: "3571",
        nazivPu: "Našice",
        naselje: "Martin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3572",
        brojPu: "51417",
        redBroj: "3572",
        nazivPu: "Mošćenička Draga",
        naselje: "Martina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3573",
        brojPu: "43000",
        redBroj: "3573",
        nazivPu: "Bjelovar",
        naselje: "Martinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3574",
        brojPu: "43240",
        redBroj: "3574",
        nazivPu: "Čazma",
        naselje: "Martinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3575",
        brojPu: "52420",
        redBroj: "3575",
        nazivPu: "Buzet",
        naselje: "Martinci",
        zupanija: "ISTARSKA",
      },
      {
        id: "3576",
        brojPu: "31540",
        redBroj: "3576",
        nazivPu: "Donji Miholjac",
        naselje: "Martinci Miholjački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3577",
        brojPu: "52460",
        redBroj: "3577",
        nazivPu: "Buje (Buie)",
        naselje: "Martinčići - Martincici",
        zupanija: "ISTARSKA",
      },
      {
        id: "3578",
        brojPu: "49221",
        redBroj: "3578",
        nazivPu: "Bedekovčina",
        naselje: "Martinec Orehovički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3579",
        brojPu: "42230",
        redBroj: "3579",
        nazivPu: "Ludbreg",
        naselje: "Martinić",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3580",
        brojPu: "49210",
        redBroj: "3580",
        nazivPu: "Zabok",
        naselje: "Martinišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3581",
        brojPu: "42223",
        redBroj: "3581",
        nazivPu: "Varaždinske Toplice",
        naselje: "Martinkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3582",
        brojPu: "20207",
        redBroj: "3582",
        nazivPu: "Mlini",
        naselje: "Martinovići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3583",
        brojPu: "44400",
        redBroj: "3583",
        nazivPu: "Glina",
        naselje: "Martinovići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3584",
        brojPu: "51218",
        redBroj: "3584",
        nazivPu: "Dražice",
        naselje: "Martinovo Selo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3585",
        brojPu: "10340",
        redBroj: "3585",
        nazivPu: "Vrbovec",
        naselje: "Martinska Ves",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3586",
        brojPu: "44000",
        redBroj: "3586",
        nazivPu: "Sisak",
        naselje: "Martinska Ves",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3587",
        brojPu: "47000",
        redBroj: "3587",
        nazivPu: "Karlovac",
        naselje: "Martinski Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3588",
        brojPu: "51556",
        redBroj: "3588",
        nazivPu: "Martinšćica",
        naselje: "Martinšćica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3589",
        brojPu: "49250",
        redBroj: "3589",
        nazivPu: "Zlatar",
        naselje: "Martinšćina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3590",
        brojPu: "40313",
        redBroj: "3590",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Martinuševec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3591",
        brojPu: "49218",
        redBroj: "3591",
        nazivPu: "Pregrada",
        naselje: "Martiša Ves",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3592",
        brojPu: "42243",
        redBroj: "3592",
        nazivPu: "Maruševec",
        naselje: "Maruševec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3593",
        brojPu: "21310",
        redBroj: "3593",
        nazivPu: "Omiš",
        naselje: "Marušići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3594",
        brojPu: "52460",
        redBroj: "3594",
        nazivPu: "Buje (Buie)",
        naselje: "Marušići - Marussici",
        zupanija: "ISTARSKA",
      },
      {
        id: "3595",
        brojPu: "52341",
        redBroj: "3595",
        nazivPu: "Žminj",
        naselje: "Maružini",
        zupanija: "ISTARSKA",
      },
      {
        id: "3596",
        brojPu: "43500",
        redBroj: "3596",
        nazivPu: "Daruvar",
        naselje: "Maslenjača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3597",
        brojPu: "21430",
        redBroj: "3597",
        nazivPu: "Grohote",
        naselje: "Maslinica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3598",
        brojPu: "52440",
        redBroj: "3598",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mastelići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3599",
        brojPu: "21220",
        redBroj: "3599",
        nazivPu: "Trogir",
        naselje: "Mastrinka",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3600",
        brojPu: "35400",
        redBroj: "3600",
        nazivPu: "Nova Gradiška",
        naselje: "Mašić",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3601",
        brojPu: "47240",
        redBroj: "3601",
        nazivPu: "Slunj",
        naselje: "Mašvina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3602",
        brojPu: "22300",
        redBroj: "3602",
        nazivPu: "Knin",
        naselje: "Matase",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3603",
        brojPu: "49240",
        redBroj: "3603",
        nazivPu: "Donja Stubica",
        naselje: "Matenci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3604",
        brojPu: "52470",
        redBroj: "3604",
        nazivPu: "Umag (Umago)",
        naselje: "Materada - Matterada",
        zupanija: "ISTARSKA",
      },
      {
        id: "3605",
        brojPu: "47250",
        redBroj: "3605",
        nazivPu: "Duga Resa",
        naselje: "Mateše",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3606",
        brojPu: "47250",
        redBroj: "3606",
        nazivPu: "Duga Resa",
        naselje: "Mateško Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3607",
        brojPu: "51326",
        redBroj: "3607",
        nazivPu: "Vrbovsko",
        naselje: "Matići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3608",
        brojPu: "52341",
        redBroj: "3608",
        nazivPu: "Žminj",
        naselje: "Matijaši",
        zupanija: "ISTARSKA",
      },
      {
        id: "3609",
        brojPu: "20350",
        redBroj: "3609",
        nazivPu: "Metković",
        naselje: "Matijevići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3610",
        brojPu: "44440",
        redBroj: "3610",
        nazivPu: "Dvor",
        naselje: "Matijevići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3611",
        brojPu: "35000",
        redBroj: "3611",
        nazivPu: "Slavonski Brod",
        naselje: "Matković Mala",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3612",
        brojPu: "52341",
        redBroj: "3612",
        nazivPu: "Žminj",
        naselje: "Matohanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "3613",
        brojPu: "52440",
        redBroj: "3613",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Matulini",
        zupanija: "ISTARSKA",
      },
      {
        id: "3614",
        brojPu: "51211",
        redBroj: "3614",
        nazivPu: "Matulji",
        naselje: "Matulji",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3615",
        brojPu: "49217",
        redBroj: "3615",
        nazivPu: "Krapinske Toplice",
        naselje: "Maturovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3616",
        brojPu: "47250",
        redBroj: "3616",
        nazivPu: "Duga Resa",
        naselje: "Maurovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3617",
        brojPu: "51218",
        redBroj: "3617",
        nazivPu: "Dražice",
        naselje: "Mavrinci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3618",
        brojPu: "23440",
        redBroj: "3618",
        nazivPu: "Gračac",
        naselje: "Mazin",
        zupanija: "ZADARSKA",
      },
      {
        id: "3619",
        brojPu: "31326",
        redBroj: "3619",
        nazivPu: "Darda",
        naselje: "Mece",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3620",
        brojPu: "44430",
        redBroj: "3620",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Mečenčani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3621",
        brojPu: "53000",
        redBroj: "3621",
        nazivPu: "Gospić",
        naselje: "Medak",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3622",
        brojPu: "52440",
        redBroj: "3622",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Medaki",
        zupanija: "ISTARSKA",
      },
      {
        id: "3623",
        brojPu: "35400",
        redBroj: "3623",
        nazivPu: "Nova Gradiška",
        naselje: "Medari",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3624",
        brojPu: "33520",
        redBroj: "3624",
        nazivPu: "Slatina",
        naselje: "Medinci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3625",
        brojPu: "21250",
        redBroj: "3625",
        nazivPu: "Šestanovac",
        naselje: "Medovdolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3626",
        brojPu: "10430",
        redBroj: "3626",
        nazivPu: "Samobor",
        naselje: "Medsave",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3627",
        brojPu: "52100",
        redBroj: "3627",
        nazivPu: "Pula (Pola)",
        naselje: "Medulin",
        zupanija: "ISTARSKA",
      },
      {
        id: "3628",
        brojPu: "51415",
        redBroj: "3628",
        nazivPu: "Lovran",
        naselje: "Medveja",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3629",
        brojPu: "52420",
        redBroj: "3629",
        nazivPu: "Buzet",
        naselje: "Medveje",
        zupanija: "ISTARSKA",
      },
      {
        id: "3630",
        brojPu: "10454",
        redBroj: "3630",
        nazivPu: "Krašić",
        naselje: "Medven Draga",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3631",
        brojPu: "52440",
        redBroj: "3631",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Medvidići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3632",
        brojPu: "21260",
        redBroj: "3632",
        nazivPu: "Imotski",
        naselje: "Medvidovića Draga",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3633",
        brojPu: "23420",
        redBroj: "3633",
        nazivPu: "Benkovac",
        naselje: "Medviđa",
        zupanija: "ZADARSKA",
      },
      {
        id: "3634",
        brojPu: "48260",
        redBroj: "3634",
        nazivPu: "Križevci",
        naselje: "Međa",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3635",
        brojPu: "22213",
        redBroj: "3635",
        nazivPu: "Pirovac",
        naselje: "Međare",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3636",
        brojPu: "51326",
        redBroj: "3636",
        nazivPu: "Vrbovsko",
        naselje: "Međedi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3637",
        brojPu: "47300",
        redBroj: "3637",
        nazivPu: "Ogulin",
        naselje: "Međeđak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3638",
        brojPu: "47220",
        redBroj: "3638",
        nazivPu: "Vojnić",
        naselje: "Međeđak Utinjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3639",
        brojPu: "43270",
        redBroj: "3639",
        nazivPu: "Veliki Grđevac",
        naselje: "Međurača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3640",
        brojPu: "44250",
        redBroj: "3640",
        nazivPu: "Petrinja",
        naselje: "Međurače",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3641",
        brojPu: "44320",
        redBroj: "3641",
        nazivPu: "Kutina",
        naselje: "Međurić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3642",
        brojPu: "52440",
        redBroj: "3642",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mekiši kod Kaštelira",
        zupanija: "ISTARSKA",
      },
      {
        id: "3643",
        brojPu: "52440",
        redBroj: "3643",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mekiši kod Vižinade",
        zupanija: "ISTARSKA",
      },
      {
        id: "3644",
        brojPu: "53250",
        redBroj: "3644",
        nazivPu: "Donji Lapac",
        naselje: "Melinovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3645",
        brojPu: "52207",
        redBroj: "3645",
        nazivPu: "Barban",
        naselje: "Melnica",
        zupanija: "ISTARSKA",
      },
      {
        id: "3646",
        brojPu: "53260",
        redBroj: "3646",
        nazivPu: "Brinje",
        naselje: "Melnice",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3647",
        brojPu: "42250",
        redBroj: "3647",
        nazivPu: "Lepoglava",
        naselje: "Meljan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3648",
        brojPu: "51557",
        redBroj: "3648",
        nazivPu: "Cres",
        naselje: "Merag",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3649",
        brojPu: "10295",
        redBroj: "3649",
        nazivPu: "Kupljenovo",
        naselje: "Merenje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3650",
        brojPu: "40313",
        redBroj: "3650",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Merhatovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3651",
        brojPu: "52460",
        redBroj: "3651",
        nazivPu: "Buje (Buie)",
        naselje: "Merišće - Merischie",
        zupanija: "ISTARSKA",
      },
      {
        id: "3652",
        brojPu: "31400",
        redBroj: "3652",
        nazivPu: "Đakovo",
        naselje: "Merolino Sikirevačko",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3653",
        brojPu: "53291",
        redBroj: "3653",
        nazivPu: "Novalja",
        naselje: "Metajna",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3654",
        brojPu: "20350",
        redBroj: "3654",
        nazivPu: "Metković",
        naselje: "Metković",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3655",
        brojPu: "20230",
        redBroj: "3655",
        nazivPu: "Ston",
        naselje: "Metohija",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3656",
        brojPu: "53202",
        redBroj: "3656",
        nazivPu: "Perušić",
        naselje: "Mezinovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3657",
        brojPu: "52440",
        redBroj: "3657",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mičetići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3658",
        brojPu: "48350",
        redBroj: "3658",
        nazivPu: "Đurđevac",
        naselje: "Mičetinac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3659",
        brojPu: "10410",
        redBroj: "3659",
        nazivPu: "Velika Gorica",
        naselje: "Mičevec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3660",
        brojPu: "48260",
        redBroj: "3660",
        nazivPu: "Križevci",
        naselje: "Mičijevac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3661",
        brojPu: "34340",
        redBroj: "3661",
        nazivPu: "Kutjevo",
        naselje: "Migalovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3662",
        brojPu: "34000",
        redBroj: "3662",
        nazivPu: "Požega",
        naselje: "Mihajlije",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3663",
        brojPu: "47250",
        redBroj: "3663",
        nazivPu: "Duga Resa",
        naselje: "Mihalić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3664",
        brojPu: "20355",
        redBroj: "3664",
        nazivPu: "Opuzen",
        naselje: "Mihalj",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3665",
        brojPu: "49000",
        redBroj: "3665",
        nazivPu: "Krapina",
        naselje: "Mihaljekov Jarek",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3666",
        brojPu: "53230",
        redBroj: "3666",
        nazivPu: "Korenica",
        naselje: "Mihaljevac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3667",
        brojPu: "34000",
        redBroj: "3667",
        nazivPu: "Požega",
        naselje: "Mihaljevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3668",
        brojPu: "34310",
        redBroj: "3668",
        nazivPu: "Pleternica",
        naselje: "Mihaljevići",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3669",
        brojPu: "20215",
        redBroj: "3669",
        nazivPu: "Gruda",
        naselje: "Mihanići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3670",
        brojPu: "49290",
        redBroj: "3670",
        nazivPu: "Klanjec",
        naselje: "Mihanovićev Dol",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3671",
        brojPu: "52440",
        redBroj: "3671",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mihatovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3672",
        brojPu: "52440",
        redBroj: "3672",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mihelići",
        zupanija: "ISTARSKA",
      },
      {
        id: "3673",
        brojPu: "51556",
        redBroj: "3673",
        nazivPu: "Martinšćica",
        naselje: "Miholašćica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3674",
        brojPu: "48260",
        redBroj: "3674",
        nazivPu: "Križevci",
        naselje: "Miholec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3675",
        brojPu: "31540",
        redBroj: "3675",
        nazivPu: "Donji Miholjac",
        naselje: "Miholjački Poreč",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3676",
        brojPu: "48350",
        redBroj: "3676",
        nazivPu: "Đurđevac",
        naselje: "Miholjanec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3677",
        brojPu: "47220",
        redBroj: "3677",
        nazivPu: "Vojnić",
        naselje: "Miholjsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3678",
        brojPu: "51211",
        redBroj: "3678",
        nazivPu: "Matulji",
        naselje: "Mihotići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3679",
        brojPu: "40000",
        redBroj: "3679",
        nazivPu: "Čakovec",
        naselje: "Mihovljan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3680",
        brojPu: "49252",
        redBroj: "3680",
        nazivPu: "Mihovljan",
        naselje: "Mihovljan",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3681",
        brojPu: "21270",
        redBroj: "3681",
        nazivPu: "Zagvozd",
        naselje: "Mijaca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3682",
        brojPu: "34000",
        redBroj: "3682",
        nazivPu: "Požega",
        naselje: "Mijači",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3683",
        brojPu: "40315",
        redBroj: "3683",
        nazivPu: "Mursko Središče",
        naselje: "Miklavec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3684",
        brojPu: "33515",
        redBroj: "3684",
        nazivPu: "Orahovica",
        naselje: "Mikleuš",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3685",
        brojPu: "44320",
        redBroj: "3685",
        nazivPu: "Kutina",
        naselje: "Mikleuška",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3686",
        brojPu: "32242",
        redBroj: "3686",
        nazivPu: "Slakovci",
        naselje: "Mikluševci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3687",
        brojPu: "48260",
        redBroj: "3687",
        nazivPu: "Križevci",
        naselje: "Mikovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3688",
        brojPu: "20215",
        redBroj: "3688",
        nazivPu: "Gruda",
        naselje: "Mikulići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3689",
        brojPu: "10450",
        redBroj: "3689",
        nazivPu: "Jastrebarsko",
        naselje: "Miladini",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3690",
        brojPu: "52440",
        redBroj: "3690",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Milanezi",
        zupanija: "ISTARSKA",
      },
      {
        id: "3691",
        brojPu: "47250",
        redBroj: "3691",
        nazivPu: "Duga Resa",
        naselje: "Milani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3692",
        brojPu: "34340",
        redBroj: "3692",
        nazivPu: "Kutjevo",
        naselje: "Milanlug",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3693",
        brojPu: "33000",
        redBroj: "3693",
        nazivPu: "Virovitica",
        naselje: "Milanovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3694",
        brojPu: "33515",
        redBroj: "3694",
        nazivPu: "Orahovica",
        naselje: "Milanovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3695",
        brojPu: "34000",
        redBroj: "3695",
        nazivPu: "Požega",
        naselje: "Milanovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3696",
        brojPu: "43240",
        redBroj: "3696",
        nazivPu: "Čazma",
        naselje: "Milaševac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3697",
        brojPu: "51218",
        redBroj: "3697",
        nazivPu: "Dražice",
        naselje: "Milaši",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3698",
        brojPu: "51511",
        redBroj: "3698",
        nazivPu: "Malinska",
        naselje: "Milčetići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3699",
        brojPu: "49240",
        redBroj: "3699",
        nazivPu: "Donja Stubica",
        naselje: "Milekovo Selo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3700",
        brojPu: "48000",
        redBroj: "3700",
        nazivPu: "Koprivnica",
        naselje: "Miličani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3701",
        brojPu: "31400",
        redBroj: "3701",
        nazivPu: "Đakovo",
        naselje: "Milinac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3702",
        brojPu: "34000",
        redBroj: "3702",
        nazivPu: "Požega",
        naselje: "Milivojevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3703",
        brojPu: "21405",
        redBroj: "3703",
        nazivPu: "Milna",
        naselje: "Milna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3704",
        brojPu: "21450",
        redBroj: "3704",
        nazivPu: "Hvar",
        naselje: "Milna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3705",
        brojPu: "21480",
        redBroj: "3705",
        nazivPu: "Vis",
        naselje: "Milna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3706",
        brojPu: "51500",
        redBroj: "3706",
        nazivPu: "Krk",
        naselje: "Milohnići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3707",
        brojPu: "47250",
        redBroj: "3707",
        nazivPu: "Duga Resa",
        naselje: "Miloševac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3708",
        brojPu: "52341",
        redBroj: "3708",
        nazivPu: "Žminj",
        naselje: "Milotski Breg",
        zupanija: "ISTARSKA",
      },
      {
        id: "3709",
        brojPu: "51511",
        redBroj: "3709",
        nazivPu: "Malinska",
        naselje: "Milovčići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3710",
        brojPu: "49290",
        redBroj: "3710",
        nazivPu: "Klanjec",
        naselje: "Miljana",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3711",
        brojPu: "43500",
        redBroj: "3711",
        nazivPu: "Daruvar",
        naselje: "Miljanovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3712",
        brojPu: "47240",
        redBroj: "3712",
        nazivPu: "Slunj",
        naselje: "Miljevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3713",
        brojPu: "33520",
        redBroj: "3713",
        nazivPu: "Slatina",
        naselje: "Miljevci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3714",
        brojPu: "21310",
        redBroj: "3714",
        nazivPu: "Omiš",
        naselje: "Mimice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3715",
        brojPu: "22300",
        redBroj: "3715",
        nazivPu: "Knin",
        naselje: "Miočić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3716",
        brojPu: "44250",
        redBroj: "3716",
        nazivPu: "Petrinja",
        naselje: "Miočinovići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3717",
        brojPu: "23420",
        redBroj: "3717",
        nazivPu: "Benkovac",
        naselje: "Miranje",
        zupanija: "ZADARSKA",
      },
      {
        id: "3718",
        brojPu: "21400",
        redBroj: "3718",
        nazivPu: "Supetar",
        naselje: "Mirca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3719",
        brojPu: "47280",
        redBroj: "3719",
        nazivPu: "Ozalj",
        naselje: "Mirkopolje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3720",
        brojPu: "31300",
        redBroj: "3720",
        nazivPu: "Beli Manastir",
        naselje: "Mirkovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3721",
        brojPu: "32100",
        redBroj: "3721",
        nazivPu: "Vinkovci",
        naselje: "Mirkovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3722",
        brojPu: "49223",
        redBroj: "3722",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Mirkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3723",
        brojPu: "42220",
        redBroj: "3723",
        nazivPu: "Novi Marof",
        naselje: "Mirkovec Breznički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3724",
        brojPu: "22320",
        redBroj: "3724",
        nazivPu: "Drniš",
        naselje: "Mirlović Polje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3725",
        brojPu: "22323",
        redBroj: "3725",
        nazivPu: "Unešić",
        naselje: "Mirlović Zagora",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3726",
        brojPu: "20350",
        redBroj: "3726",
        nazivPu: "Metković",
        naselje: "Mislina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3727",
        brojPu: "47000",
        redBroj: "3727",
        nazivPu: "Karlovac",
        naselje: "Mišinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3728",
        brojPu: "44320",
        redBroj: "3728",
        nazivPu: "Kutina",
        naselje: "Mišinka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3729",
        brojPu: "23250",
        redBroj: "3729",
        nazivPu: "Pag",
        naselje: "Miškovići",
        zupanija: "ZADARSKA",
      },
      {
        id: "3730",
        brojPu: "53250",
        redBroj: "3730",
        nazivPu: "Donji Lapac",
        naselje: "Mišljenovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3731",
        brojPu: "21222",
        redBroj: "3731",
        nazivPu: "Marina",
        naselje: "Mitlo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3732",
        brojPu: "31300",
        redBroj: "3732",
        nazivPu: "Beli Manastir",
        naselje: "Mitrovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3733",
        brojPu: "34340",
        redBroj: "3733",
        nazivPu: "Kutjevo",
        naselje: "Mitrovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3734",
        brojPu: "47240",
        redBroj: "3734",
        nazivPu: "Slunj",
        naselje: "Mjesto Primišlje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3735",
        brojPu: "51216",
        redBroj: "3735",
        nazivPu: "Viškovo",
        naselje: "Mladenići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3736",
        brojPu: "10340",
        redBroj: "3736",
        nazivPu: "Vrbovec",
        naselje: "Mlaka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3737",
        brojPu: "44330",
        redBroj: "3737",
        nazivPu: "Novska",
        naselje: "Mlaka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3738",
        brojPu: "32100",
        redBroj: "3738",
        nazivPu: "Vinkovci",
        naselje: "Mlaka Antinska",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3739",
        brojPu: "47220",
        redBroj: "3739",
        nazivPu: "Vojnić",
        naselje: "Mlakovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3740",
        brojPu: "53202",
        redBroj: "3740",
        nazivPu: "Perušić",
        naselje: "Mlakva",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3741",
        brojPu: "51326",
        redBroj: "3741",
        nazivPu: "Vrbovsko",
        naselje: "Mlinari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3742",
        brojPu: "20207",
        redBroj: "3742",
        nazivPu: "Mlini",
        naselje: "Mlini",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3743",
        brojPu: "20350",
        redBroj: "3743",
        nazivPu: "Metković",
        naselje: "Mlinište",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3744",
        brojPu: "43280",
        redBroj: "3744",
        nazivPu: "Garešnica",
        naselje: "Mlinski Vinogradi",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3745",
        brojPu: "20215",
        redBroj: "3745",
        nazivPu: "Gruda",
        naselje: "Močići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3746",
        brojPu: "51326",
        redBroj: "3746",
        nazivPu: "Vrbovsko",
        naselje: "Močile",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3747",
        brojPu: "22300",
        redBroj: "3747",
        nazivPu: "Knin",
        naselje: "Modrino Selo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3748",
        brojPu: "49240",
        redBroj: "3748",
        nazivPu: "Donja Stubica",
        naselje: "Modrovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3749",
        brojPu: "47300",
        redBroj: "3749",
        nazivPu: "Ogulin",
        naselje: "Modruš",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3750",
        brojPu: "52341",
        redBroj: "3750",
        nazivPu: "Žminj",
        naselje: "Modrušani",
        zupanija: "ISTARSKA",
      },
      {
        id: "3751",
        brojPu: "53000",
        redBroj: "3751",
        nazivPu: "Gospić",
        naselje: "Mogorić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3752",
        brojPu: "32236",
        redBroj: "3752",
        nazivPu: "Ilok",
        naselje: "Mohovo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3753",
        brojPu: "20236",
        redBroj: "3753",
        nazivPu: "Mokošica",
        naselje: "Mokošica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3754",
        brojPu: "34340",
        redBroj: "3754",
        nazivPu: "Kutjevo",
        naselje: "Mokreš",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3755",
        brojPu: "10380",
        redBroj: "3755",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Mokrica Tomaševečka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3756",
        brojPu: "49243",
        redBroj: "3756",
        nazivPu: "Oroslavje",
        naselje: "Mokrice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3757",
        brojPu: "48260",
        redBroj: "3757",
        nazivPu: "Križevci",
        naselje: "Mokrice Miholečke",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3758",
        brojPu: "22300",
        redBroj: "3758",
        nazivPu: "Knin",
        naselje: "Mokro Polje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3759",
        brojPu: "23292",
        redBroj: "3759",
        nazivPu: "Molat",
        naselje: "Molat",
        zupanija: "ZADARSKA",
      },
      {
        id: "3760",
        brojPu: "20215",
        redBroj: "3760",
        nazivPu: "Gruda",
        naselje: "Molunat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3761",
        brojPu: "48350",
        redBroj: "3761",
        nazivPu: "Đurđevac",
        naselje: "Molve",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3762",
        brojPu: "48350",
        redBroj: "3762",
        nazivPu: "Đurđevac",
        naselje: "Molve Grede",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3763",
        brojPu: "10430",
        redBroj: "3763",
        nazivPu: "Samobor",
        naselje: "Molvice",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3764",
        brojPu: "48350",
        redBroj: "3764",
        nazivPu: "Đurđevac",
        naselje: "Molvice",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3765",
        brojPu: "44400",
        redBroj: "3765",
        nazivPu: "Glina",
        naselje: "Momčilovića Kosa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3766",
        brojPu: "20350",
        redBroj: "3766",
        nazivPu: "Metković",
        naselje: "Momići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3767",
        brojPu: "52460",
        redBroj: "3767",
        nazivPu: "Buje (Buie)",
        naselje: "Momjan - Momiano",
        zupanija: "ISTARSKA",
      },
      {
        id: "3768",
        brojPu: "52470",
        redBroj: "3768",
        nazivPu: "Umag (Umago)",
        naselje: "Monterol - Monterol",
        zupanija: "ISTARSKA",
      },
      {
        id: "3769",
        brojPu: "52440",
        redBroj: "3769",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Montižana",
        zupanija: "ISTARSKA",
      },
      {
        id: "3770",
        brojPu: "52000",
        redBroj: "3770",
        nazivPu: "Pazin",
        naselje: "Montovani",
        zupanija: "ISTARSKA",
      },
      {
        id: "3771",
        brojPu: "10363",
        redBroj: "3771",
        nazivPu: "Belovar",
        naselje: "Moravče",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3772",
        brojPu: "51326",
        redBroj: "3772",
        nazivPu: "Vrbovsko",
        naselje: "Moravice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3773",
        brojPu: "51326",
        redBroj: "3773",
        nazivPu: "Vrbovsko",
        naselje: "Moravička Sela",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3774",
        brojPu: "23422",
        redBroj: "3774",
        nazivPu: "Stankovci",
        naselje: "Morpolača",
        zupanija: "ZADARSKA",
      },
      {
        id: "3775",
        brojPu: "22320",
        redBroj: "3775",
        nazivPu: "Drniš",
        naselje: "Moseć",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3776",
        brojPu: "44317",
        redBroj: "3776",
        nazivPu: "Popovača",
        naselje: "Moslavačka Slatina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3777",
        brojPu: "10342",
        redBroj: "3777",
        nazivPu: "Dubrava",
        naselje: "Mostari",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3778",
        brojPu: "52220",
        redBroj: "3778",
        nazivPu: "Labin",
        naselje: "Most-Raša",
        zupanija: "ISTARSKA",
      },
      {
        id: "3779",
        brojPu: "47000",
        redBroj: "3779",
        nazivPu: "Karlovac",
        naselje: "Mošanci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3780",
        brojPu: "44250",
        redBroj: "3780",
        nazivPu: "Petrinja",
        naselje: "Moščenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3781",
        brojPu: "51417",
        redBroj: "3781",
        nazivPu: "Mošćenička Draga",
        naselje: "Mošćenice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3782",
        brojPu: "51417",
        redBroj: "3782",
        nazivPu: "Mošćenička Draga",
        naselje: "Mošćenička Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3783",
        brojPu: "44250",
        redBroj: "3783",
        nazivPu: "Petrinja",
        naselje: "Moštanica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3784",
        brojPu: "52424",
        redBroj: "3784",
        nazivPu: "Motovun (Montona)",
        naselje: "Motovun - Montona",
        zupanija: "ISTARSKA",
      },
      {
        id: "3785",
        brojPu: "49290",
        redBroj: "3785",
        nazivPu: "Klanjec",
        naselje: "Movrač",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3786",
        brojPu: "42220",
        redBroj: "3786",
        nazivPu: "Novi Marof",
        naselje: "Možđenec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3787",
        brojPu: "47220",
        redBroj: "3787",
        nazivPu: "Vojnić",
        naselje: "Mracelj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3788",
        brojPu: "10410",
        redBroj: "3788",
        nazivPu: "Velika Gorica",
        naselje: "Mraclin",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3789",
        brojPu: "44430",
        redBroj: "3789",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Mračaj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3790",
        brojPu: "47220",
        redBroj: "3790",
        nazivPu: "Vojnić",
        naselje: "Mračaj Krstinjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3791",
        brojPu: "47250",
        redBroj: "3791",
        nazivPu: "Duga Resa",
        naselje: "Mračin",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3792",
        brojPu: "22320",
        redBroj: "3792",
        nazivPu: "Drniš",
        naselje: "Mratovo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3793",
        brojPu: "21210",
        redBroj: "3793",
        nazivPu: "Solin",
        naselje: "Mravince",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3794",
        brojPu: "20232",
        redBroj: "3794",
        nazivPu: "Slano",
        naselje: "Mravinjac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3795",
        brojPu: "20232",
        redBroj: "3795",
        nazivPu: "Slano",
        naselje: "Mravnica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3796",
        brojPu: "22000",
        redBroj: "3796",
        nazivPu: "Šibenik",
        naselje: "Mravnica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3797",
        brojPu: "20232",
        redBroj: "3797",
        nazivPu: "Slano",
        naselje: "Mrčevo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3798",
        brojPu: "47250",
        redBroj: "3798",
        nazivPu: "Duga Resa",
        naselje: "Mrežnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3799",
        brojPu: "47250",
        redBroj: "3799",
        nazivPu: "Duga Resa",
        naselje: "Mrežničke Poljice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3800",
        brojPu: "47250",
        redBroj: "3800",
        nazivPu: "Duga Resa",
        naselje: "Mrežnički Brest",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3801",
        brojPu: "47250",
        redBroj: "3801",
        nazivPu: "Duga Resa",
        naselje: "Mrežnički Brig",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3802",
        brojPu: "47250",
        redBroj: "3802",
        nazivPu: "Duga Resa",
        naselje: "Mrežnički Novaki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3803",
        brojPu: "47250",
        redBroj: "3803",
        nazivPu: "Duga Resa",
        naselje: "Mrežnički Varoš",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3804",
        brojPu: "47250",
        redBroj: "3804",
        nazivPu: "Duga Resa",
        naselje: "Mrežničko Dvorište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3805",
        brojPu: "52341",
        redBroj: "3805",
        nazivPu: "Žminj",
        naselje: "Mrgani",
        zupanija: "ISTARSKA",
      },
      {
        id: "3806",
        brojPu: "51300",
        redBroj: "3806",
        nazivPu: "Delnice",
        naselje: "Mrkopalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3807",
        brojPu: "34000",
        redBroj: "3807",
        nazivPu: "Požega",
        naselje: "Mrkoplje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3808",
        brojPu: "23212",
        redBroj: "3808",
        nazivPu: "Tkon",
        naselje: "Mrljane",
        zupanija: "ZADARSKA",
      },
      {
        id: "3809",
        brojPu: "51300",
        redBroj: "3809",
        nazivPu: "Delnice",
        naselje: "Mrzla Vodica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3810",
        brojPu: "53270",
        redBroj: "3810",
        nazivPu: "Senj",
        naselje: "Mrzli Dol",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3811",
        brojPu: "49210",
        redBroj: "3811",
        nazivPu: "Zabok",
        naselje: "Mrzlo Polje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3812",
        brojPu: "10454",
        redBroj: "3812",
        nazivPu: "Krašić",
        naselje: "Mrzlo Polje Žumberačko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3813",
        brojPu: "47000",
        redBroj: "3813",
        nazivPu: "Karlovac",
        naselje: "Mrzljaki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3814",
        brojPu: "32284",
        redBroj: "3814",
        nazivPu: "Stari Mikanovci",
        naselje: "Mrzović",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3815",
        brojPu: "51211",
        redBroj: "3815",
        nazivPu: "Matulji",
        naselje: "Mučići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3816",
        brojPu: "52440",
        redBroj: "3816",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mugeba",
        zupanija: "ISTARSKA",
      },
      {
        id: "3817",
        brojPu: "51280",
        redBroj: "3817",
        nazivPu: "Rab",
        naselje: "Mundanije",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3818",
        brojPu: "43290",
        redBroj: "3818",
        nazivPu: "Grubišno Polje",
        naselje: "Munije",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3819",
        brojPu: "52100",
        redBroj: "3819",
        nazivPu: "Pula (Pola)",
        naselje: "Muntić - Monticchio",
        zupanija: "ISTARSKA",
      },
      {
        id: "3820",
        brojPu: "52000",
        redBroj: "3820",
        nazivPu: "Pazin",
        naselje: "Muntrilj",
        zupanija: "ISTARSKA",
      },
      {
        id: "3821",
        brojPu: "47300",
        redBroj: "3821",
        nazivPu: "Ogulin",
        naselje: "Munjava",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3822",
        brojPu: "47300",
        redBroj: "3822",
        nazivPu: "Ogulin",
        naselje: "Munjava Modruška",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3823",
        brojPu: "51500",
        redBroj: "3823",
        nazivPu: "Krk",
        naselje: "Muraj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3824",
        brojPu: "42250",
        redBroj: "3824",
        nazivPu: "Lepoglava",
        naselje: "Muričevec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3825",
        brojPu: "52470",
        redBroj: "3825",
        nazivPu: "Umag (Umago)",
        naselje: "Murine - Morno",
        zupanija: "ISTARSKA",
      },
      {
        id: "3826",
        brojPu: "40315",
        redBroj: "3826",
        nazivPu: "Mursko Središče",
        naselje: "Mursko Središće",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3827",
        brojPu: "22243",
        redBroj: "3827",
        nazivPu: "Murter",
        naselje: "Murter",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3828",
        brojPu: "21420",
        redBroj: "3828",
        nazivPu: "Bol",
        naselje: "Murvica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3829",
        brojPu: "23000",
        redBroj: "3829",
        nazivPu: "Zadar",
        naselje: "Murvica",
        zupanija: "ZADARSKA",
      },
      {
        id: "3830",
        brojPu: "23000",
        redBroj: "3830",
        nazivPu: "Zadar",
        naselje: "Murvica Gornja",
        zupanija: "ZADARSKA",
      },
      {
        id: "3831",
        brojPu: "31400",
        redBroj: "3831",
        nazivPu: "Đakovo",
        naselje: "Musić",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3832",
        brojPu: "44317",
        redBroj: "3832",
        nazivPu: "Popovača",
        naselje: "Mustafina Klada",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3833",
        brojPu: "51326",
        redBroj: "3833",
        nazivPu: "Vrbovsko",
        naselje: "Musulini",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3834",
        brojPu: "52440",
        redBroj: "3834",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Mušalež",
        zupanija: "ISTARSKA",
      },
      {
        id: "3835",
        brojPu: "53000",
        redBroj: "3835",
        nazivPu: "Gospić",
        naselje: "Mušaluk",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3836",
        brojPu: "23450",
        redBroj: "3836",
        nazivPu: "Obrovac",
        naselje: "Muškovci",
        zupanija: "ZADARSKA",
      },
      {
        id: "3837",
        brojPu: "53234",
        redBroj: "3837",
        nazivPu: "Udbina",
        naselje: "Mutilić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3838",
        brojPu: "52207",
        redBroj: "3838",
        nazivPu: "Barban",
        naselje: "Mutvoran",
        zupanija: "ISTARSKA",
      },
      {
        id: "3839",
        brojPu: "44000",
        redBroj: "3839",
        nazivPu: "Sisak",
        naselje: "Mužilovčica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3840",
        brojPu: "52341",
        redBroj: "3840",
        nazivPu: "Žminj",
        naselje: "Mužini",
        zupanija: "ISTARSKA",
      },
      {
        id: "3841",
        brojPu: "23223",
        redBroj: "3841",
        nazivPu: "Škabrnja",
        naselje: "Nadin",
        zupanija: "ZADARSKA",
      },
      {
        id: "3842",
        brojPu: "23450",
        redBroj: "3842",
        nazivPu: "Obrovac",
        naselje: "Nadvoda",
        zupanija: "ZADARSKA",
      },
      {
        id: "3843",
        brojPu: "23440",
        redBroj: "3843",
        nazivPu: "Gračac",
        naselje: "Nadvrelo",
        zupanija: "ZADARSKA",
      },
      {
        id: "3844",
        brojPu: "51326",
        redBroj: "3844",
        nazivPu: "Vrbovsko",
        naselje: "Nadvučnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3845",
        brojPu: "51326",
        redBroj: "3845",
        nazivPu: "Vrbovsko",
        naselje: "Naglići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3846",
        brojPu: "21222",
        redBroj: "3846",
        nazivPu: "Marina",
        naselje: "Najevi",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3847",
        brojPu: "21292",
        redBroj: "3847",
        nazivPu: "Srinjine",
        naselje: "Naklice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3848",
        brojPu: "20250",
        redBroj: "3848",
        nazivPu: "Orebić",
        naselje: "Nakovanj",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3849",
        brojPu: "31550",
        redBroj: "3849",
        nazivPu: "Valpovo",
        naselje: "Nard",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3850",
        brojPu: "52440",
        redBroj: "3850",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Nardući",
        zupanija: "ISTARSKA",
      },
      {
        id: "3851",
        brojPu: "10370",
        redBroj: "3851",
        nazivPu: "Dugo Selo",
        naselje: "Nart Savski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3852",
        brojPu: "43000",
        redBroj: "3852",
        nazivPu: "Bjelovar",
        naselje: "Narta",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3853",
        brojPu: "10340",
        redBroj: "3853",
        nazivPu: "Vrbovec",
        naselje: "Naselje Stjepana Radića",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3854",
        brojPu: "31500",
        redBroj: "3854",
        nazivPu: "Našice",
        naselje: "Našice",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3855",
        brojPu: "31511",
        redBroj: "3855",
        nazivPu: "Đurđenovac",
        naselje: "Našičko Novo Selo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3856",
        brojPu: "42208",
        redBroj: "3856",
        nazivPu: "Cestica",
        naselje: "Natkrižovljan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3857",
        brojPu: "33410",
        redBroj: "3857",
        nazivPu: "Suhopolje",
        naselje: "Naudovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3858",
        brojPu: "53250",
        redBroj: "3858",
        nazivPu: "Donji Lapac",
        naselje: "Nebljusi",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3859",
        brojPu: "44250",
        redBroj: "3859",
        nazivPu: "Petrinja",
        naselje: "Nebojan",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3860",
        brojPu: "49216",
        redBroj: "3860",
        nazivPu: "Desinić",
        naselje: "Nebojse",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3861",
        brojPu: "21430",
        redBroj: "3861",
        nazivPu: "Grohote",
        naselje: "Nečujam",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3862",
        brojPu: "40000",
        redBroj: "3862",
        nazivPu: "Čakovec",
        naselje: "Nedelišće",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3863",
        brojPu: "42000",
        redBroj: "3863",
        nazivPu: "Varaždin",
        naselje: "Nedeljanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3864",
        brojPu: "52220",
        redBroj: "3864",
        nazivPu: "Labin",
        naselje: "Nedešćina",
        zupanija: "ISTARSKA",
      },
      {
        id: "3865",
        brojPu: "52420",
        redBroj: "3865",
        nazivPu: "Buzet",
        naselje: "Negnar",
        zupanija: "ISTARSKA",
      },
      {
        id: "3866",
        brojPu: "32000",
        redBroj: "3866",
        nazivPu: "Vukovar",
        naselje: "Negoslavci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3867",
        brojPu: "10340",
        redBroj: "3867",
        nazivPu: "Vrbovec",
        naselje: "Negovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3868",
        brojPu: "48260",
        redBroj: "3868",
        nazivPu: "Križevci",
        naselje: "Nemčevec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3869",
        brojPu: "31000",
        redBroj: "3869",
        nazivPu: "Osijek",
        naselje: "Nemetin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3870",
        brojPu: "51500",
        redBroj: "3870",
        nazivPu: "Krk",
        naselje: "Nenadići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3871",
        brojPu: "21203",
        redBroj: "3871",
        nazivPu: "Donji Muć",
        naselje: "Neorić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3872",
        brojPu: "51550",
        redBroj: "3872",
        nazivPu: "Mali Lošinj",
        naselje: "Nerezine",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3873",
        brojPu: "21400",
        redBroj: "3873",
        nazivPu: "Supetar",
        naselje: "Nerežišća",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3874",
        brojPu: "10380",
        redBroj: "3874",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Nespeš",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3875",
        brojPu: "53250",
        redBroj: "3875",
        nazivPu: "Donji Lapac",
        naselje: "Neteka",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3876",
        brojPu: "47000",
        redBroj: "3876",
        nazivPu: "Karlovac",
        naselje: "Netretić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3877",
        brojPu: "22323",
        redBroj: "3877",
        nazivPu: "Unešić",
        naselje: "Nevest",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3878",
        brojPu: "23271",
        redBroj: "3878",
        nazivPu: "Kukljica",
        naselje: "Neviđane",
        zupanija: "ZADARSKA",
      },
      {
        id: "3879",
        brojPu: "43270",
        redBroj: "3879",
        nazivPu: "Veliki Grđevac",
        naselje: "Nevinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3880",
        brojPu: "32252",
        redBroj: "3880",
        nazivPu: "Otok",
        naselje: "Nijemci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3881",
        brojPu: "51326",
        redBroj: "3881",
        nazivPu: "Vrbovsko",
        naselje: "Nikšići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3882",
        brojPu: "23232",
        redBroj: "3882",
        nazivPu: "Nin",
        naselje: "Nin",
        zupanija: "ZADARSKA",
      },
      {
        id: "3883",
        brojPu: "23232",
        redBroj: "3883",
        nazivPu: "Nin",
        naselje: "Ninski Stanovi",
        zupanija: "ZADARSKA",
      },
      {
        id: "3884",
        brojPu: "21204",
        redBroj: "3884",
        nazivPu: "Dugopolje",
        naselje: "Nisko",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3885",
        brojPu: "31500",
        redBroj: "3885",
        nazivPu: "Našice",
        naselje: "Niza",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3886",
        brojPu: "31500",
        redBroj: "3886",
        nazivPu: "Našice",
        naselje: "Normanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3887",
        brojPu: "10430",
        redBroj: "3887",
        nazivPu: "Samobor",
        naselje: "Noršić Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3888",
        brojPu: "22320",
        redBroj: "3888",
        nazivPu: "Drniš",
        naselje: "Nos Kalik",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "3889",
        brojPu: "33520",
        redBroj: "3889",
        nazivPu: "Slatina",
        naselje: "Noskovačka Dubrava",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3890",
        brojPu: "33520",
        redBroj: "3890",
        nazivPu: "Slatina",
        naselje: "Noskovci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3891",
        brojPu: "33520",
        redBroj: "3891",
        nazivPu: "Slatina",
        naselje: "Nova Bukovica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3892",
        brojPu: "43000",
        redBroj: "3892",
        nazivPu: "Bjelovar",
        naselje: "Nova Diklenica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3893",
        brojPu: "44250",
        redBroj: "3893",
        nazivPu: "Petrinja",
        naselje: "Nova Drenčina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3894",
        brojPu: "35400",
        redBroj: "3894",
        nazivPu: "Nova Gradiška",
        naselje: "Nova Gradiška",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3895",
        brojPu: "33515",
        redBroj: "3895",
        nazivPu: "Orahovica",
        naselje: "Nova Jošava",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3896",
        brojPu: "10342",
        redBroj: "3896",
        nazivPu: "Dubrava",
        naselje: "Nova Kapela",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3897",
        brojPu: "35410",
        redBroj: "3897",
        nazivPu: "Nova Kapela",
        naselje: "Nova Kapela",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3898",
        brojPu: "43500",
        redBroj: "3898",
        nazivPu: "Daruvar",
        naselje: "Nova Krivaja",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3899",
        brojPu: "47240",
        redBroj: "3899",
        nazivPu: "Slunj",
        naselje: "Nova Kršlja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3900",
        brojPu: "34000",
        redBroj: "3900",
        nazivPu: "Požega",
        naselje: "Nova Lipa",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3901",
        brojPu: "34340",
        redBroj: "3901",
        nazivPu: "Kutjevo",
        naselje: "Nova Lipovica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3902",
        brojPu: "34340",
        redBroj: "3902",
        nazivPu: "Kutjevo",
        naselje: "Nova Ljeskovica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3903",
        brojPu: "20236",
        redBroj: "3903",
        nazivPu: "Mokošica",
        naselje: "Nova Mokošica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3904",
        brojPu: "43270",
        redBroj: "3904",
        nazivPu: "Veliki Grđevac",
        naselje: "Nova Pisanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3905",
        brojPu: "43280",
        redBroj: "3905",
        nazivPu: "Garešnica",
        naselje: "Nova Ploščica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3906",
        brojPu: "43270",
        redBroj: "3906",
        nazivPu: "Veliki Grđevac",
        naselje: "Nova Rača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3907",
        brojPu: "20350",
        redBroj: "3907",
        nazivPu: "Metković",
        naselje: "Nova Sela",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "3908",
        brojPu: "21240",
        redBroj: "3908",
        nazivPu: "Trilj",
        naselje: "Nova Sela",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3909",
        brojPu: "21250",
        redBroj: "3909",
        nazivPu: "Šestanovac",
        naselje: "Nova Sela",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3910",
        brojPu: "44330",
        redBroj: "3910",
        nazivPu: "Novska",
        naselje: "Nova Subocka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3911",
        brojPu: "33520",
        redBroj: "3911",
        nazivPu: "Slatina",
        naselje: "Nova Šarovka",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3912",
        brojPu: "52333",
        redBroj: "3912",
        nazivPu: "Podpićan",
        naselje: "Nova Vas",
        zupanija: "ISTARSKA",
      },
      {
        id: "3913",
        brojPu: "52440",
        redBroj: "3913",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Nova Vas",
        zupanija: "ISTARSKA",
      },
      {
        id: "3914",
        brojPu: "52466",
        redBroj: "3914",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Nova Vas - Villanova",
        zupanija: "ISTARSKA",
      },
      {
        id: "3915",
        brojPu: "42208",
        redBroj: "3915",
        nazivPu: "Cestica",
        naselje: "Nova Ves Petrijanečka",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3916",
        brojPu: "48331",
        redBroj: "3916",
        nazivPu: "Gola",
        naselje: "Novačka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3917",
        brojPu: "10342",
        redBroj: "3917",
        nazivPu: "Dubrava",
        naselje: "Novaki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3918",
        brojPu: "10431",
        redBroj: "3918",
        nazivPu: "Sveta Nedelja",
        naselje: "Novaki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3919",
        brojPu: "33520",
        redBroj: "3919",
        nazivPu: "Slatina",
        naselje: "Novaki",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3920",
        brojPu: "42243",
        redBroj: "3920",
        nazivPu: "Maruševec",
        naselje: "Novaki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3921",
        brojPu: "10298",
        redBroj: "3921",
        nazivPu: "Bistra",
        naselje: "Novaki Bistranski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3922",
        brojPu: "31550",
        redBroj: "3922",
        nazivPu: "Valpovo",
        naselje: "Novaki Bizovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3923",
        brojPu: "47000",
        redBroj: "3923",
        nazivPu: "Karlovac",
        naselje: "Novaki Lipnički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3924",
        brojPu: "52424",
        redBroj: "3924",
        nazivPu: "Motovun (Montona)",
        naselje: "Novaki Motovunski",
        zupanija: "ISTARSKA",
      },
      {
        id: "3925",
        brojPu: "10361",
        redBroj: "3925",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Novaki Nartski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "3926",
        brojPu: "10370",
        redBroj: "3926",
        nazivPu: "Dugo Selo",
        naselje: "Novaki Oborovski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3927",
        brojPu: "47280",
        redBroj: "3927",
        nazivPu: "Ozalj",
        naselje: "Novaki Ozaljski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3928",
        brojPu: "52000",
        redBroj: "3928",
        nazivPu: "Pazin",
        naselje: "Novaki Pazinski",
        zupanija: "ISTARSKA",
      },
      {
        id: "3929",
        brojPu: "10450",
        redBroj: "3929",
        nazivPu: "Jastrebarsko",
        naselje: "Novaki Petrovinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3930",
        brojPu: "48260",
        redBroj: "3930",
        nazivPu: "Križevci",
        naselje: "Novaki Ravenski",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3931",
        brojPu: "10410",
        redBroj: "3931",
        nazivPu: "Velika Gorica",
        naselje: "Novaki Šćitarjevski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3932",
        brojPu: "40000",
        redBroj: "3932",
        nazivPu: "Čakovec",
        naselje: "Novakovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3933",
        brojPu: "42202",
        redBroj: "3933",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Novakovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3934",
        brojPu: "10380",
        redBroj: "3934",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Novakovec Bisaški",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3935",
        brojPu: "53291",
        redBroj: "3935",
        nazivPu: "Novalja",
        naselje: "Novalja",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3936",
        brojPu: "51326",
        redBroj: "3936",
        nazivPu: "Vrbovsko",
        naselje: "Nove Hiže",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3937",
        brojPu: "33000",
        redBroj: "3937",
        nazivPu: "Virovitica",
        naselje: "Novi Antunovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3938",
        brojPu: "34000",
        redBroj: "3938",
        nazivPu: "Požega",
        naselje: "Novi Bešinci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3939",
        brojPu: "31551",
        redBroj: "3939",
        nazivPu: "Belišće",
        naselje: "Novi Bezdan",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3940",
        brojPu: "31300",
        redBroj: "3940",
        nazivPu: "Beli Manastir",
        naselje: "Novi Bolman",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3941",
        brojPu: "48260",
        redBroj: "3941",
        nazivPu: "Križevci",
        naselje: "Novi Bošnjani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3942",
        brojPu: "31300",
        redBroj: "3942",
        nazivPu: "Beli Manastir",
        naselje: "Novi Čeminac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3943",
        brojPu: "47240",
        redBroj: "3943",
        nazivPu: "Slunj",
        naselje: "Novi Dol",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3944",
        brojPu: "49290",
        redBroj: "3944",
        nazivPu: "Klanjec",
        naselje: "Novi Dvori Klanječki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3945",
        brojPu: "48260",
        redBroj: "3945",
        nazivPu: "Križevci",
        naselje: "Novi Đurđic",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3946",
        brojPu: "44250",
        redBroj: "3946",
        nazivPu: "Petrinja",
        naselje: "Novi Farkašić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3947",
        brojPu: "48214",
        redBroj: "3947",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Novi Glog",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3948",
        brojPu: "49252",
        redBroj: "3948",
        nazivPu: "Mihovljan",
        naselje: "Novi Golubovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "3949",
        brojPu: "44330",
        redBroj: "3949",
        nazivPu: "Novska",
        naselje: "Novi Grabovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3950",
        brojPu: "35214",
        redBroj: "3950",
        nazivPu: "Donji Andrijevci",
        naselje: "Novi Grad",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3951",
        brojPu: "33410",
        redBroj: "3951",
        nazivPu: "Suhopolje",
        naselje: "Novi Gradac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3952",
        brojPu: "32242",
        redBroj: "3952",
        nazivPu: "Slakovci",
        naselje: "Novi Jankovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3953",
        brojPu: "51326",
        redBroj: "3953",
        nazivPu: "Vrbovsko",
        naselje: "Novi Lazi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3954",
        brojPu: "34550",
        redBroj: "3954",
        nazivPu: "Pakrac",
        naselje: "Novi Majur",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3955",
        brojPu: "42220",
        redBroj: "3955",
        nazivPu: "Novi Marof",
        naselje: "Novi Marof",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3956",
        brojPu: "34000",
        redBroj: "3956",
        nazivPu: "Požega",
        naselje: "Novi Mihaljevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3957",
        brojPu: "32284",
        redBroj: "3957",
        nazivPu: "Stari Mikanovci",
        naselje: "Novi Mikanovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "3958",
        brojPu: "43000",
        redBroj: "3958",
        nazivPu: "Bjelovar",
        naselje: "Novi Pavljani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3959",
        brojPu: "31400",
        redBroj: "3959",
        nazivPu: "Đakovo",
        naselje: "Novi Perkovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3960",
        brojPu: "33520",
        redBroj: "3960",
        nazivPu: "Slatina",
        naselje: "Novi Senkovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3961",
        brojPu: "43000",
        redBroj: "3961",
        nazivPu: "Bjelovar",
        naselje: "Novi Skucani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3962",
        brojPu: "34000",
        redBroj: "3962",
        nazivPu: "Požega",
        naselje: "Novi Štitnjak",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3963",
        brojPu: "35430",
        redBroj: "3963",
        nazivPu: "Okučani",
        naselje: "Novi Varoš",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3964",
        brojPu: "51250",
        redBroj: "3964",
        nazivPu: "Novi Vinodolski",
        naselje: "Novi Vinodolski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "3965",
        brojPu: "34340",
        redBroj: "3965",
        nazivPu: "Kutjevo",
        naselje: "Novi Zdenkovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3966",
        brojPu: "23312",
        redBroj: "3966",
        nazivPu: "Novigrad (Dalmacija)",
        naselje: "Novigrad",
        zupanija: "ZADARSKA",
      },
      {
        id: "3967",
        brojPu: "52466",
        redBroj: "3967",
        nazivPu: "Novigrad-Cittanova",
        naselje: "Novigrad - Cittanova",
        zupanija: "ISTARSKA",
      },
      {
        id: "3968",
        brojPu: "47250",
        redBroj: "3968",
        nazivPu: "Duga Resa",
        naselje: "Novigrad na Dobri",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3969",
        brojPu: "48350",
        redBroj: "3969",
        nazivPu: "Đurđevac",
        naselje: "Novigrad Podravski",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3970",
        brojPu: "10413",
        redBroj: "3970",
        nazivPu: "Kravarsko",
        naselje: "Novo Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3971",
        brojPu: "47250",
        redBroj: "3971",
        nazivPu: "Duga Resa",
        naselje: "Novo Brdo Mrežničko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3972",
        brojPu: "10410",
        redBroj: "3972",
        nazivPu: "Velika Gorica",
        naselje: "Novo Čiče",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3973",
        brojPu: "33520",
        redBroj: "3973",
        nazivPu: "Slatina",
        naselje: "Novo Kusonje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3974",
        brojPu: "10380",
        redBroj: "3974",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Novo Mjesto",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3975",
        brojPu: "31551",
        redBroj: "3975",
        nazivPu: "Belišće",
        naselje: "Novo Nevesinje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "3976",
        brojPu: "33515",
        redBroj: "3976",
        nazivPu: "Orahovica",
        naselje: "Novo Petrovo Polje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "3977",
        brojPu: "44010",
        redBroj: "3977",
        nazivPu: "Sisak-Caprag",
        naselje: "Novo Pračno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3978",
        brojPu: "44250",
        redBroj: "3978",
        nazivPu: "Petrinja",
        naselje: "Novo Selište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3979",
        brojPu: "10340",
        redBroj: "3979",
        nazivPu: "Vrbovec",
        naselje: "Novo Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3980",
        brojPu: "21425",
        redBroj: "3980",
        nazivPu: "Selca",
        naselje: "Novo Selo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "3981",
        brojPu: "34000",
        redBroj: "3981",
        nazivPu: "Požega",
        naselje: "Novo Selo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "3982",
        brojPu: "43240",
        redBroj: "3982",
        nazivPu: "Čazma",
        naselje: "Novo Selo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3983",
        brojPu: "44010",
        redBroj: "3983",
        nazivPu: "Sisak-Caprag",
        naselje: "Novo Selo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3984",
        brojPu: "47240",
        redBroj: "3984",
        nazivPu: "Slunj",
        naselje: "Novo Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3985",
        brojPu: "47250",
        redBroj: "3985",
        nazivPu: "Duga Resa",
        naselje: "Novo Selo Bosiljevsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3986",
        brojPu: "43280",
        redBroj: "3986",
        nazivPu: "Garešnica",
        naselje: "Novo Selo Garešničko",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "3987",
        brojPu: "44400",
        redBroj: "3987",
        nazivPu: "Glina",
        naselje: "Novo Selo Glinsko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3988",
        brojPu: "53230",
        redBroj: "3988",
        nazivPu: "Korenica",
        naselje: "Novo Selo Koreničko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "3989",
        brojPu: "47000",
        redBroj: "3989",
        nazivPu: "Karlovac",
        naselje: "Novo Selo Lasinjsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3990",
        brojPu: "40000",
        redBroj: "3990",
        nazivPu: "Čakovec",
        naselje: "Novo Selo na Dravi",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3991",
        brojPu: "10450",
        redBroj: "3991",
        nazivPu: "Jastrebarsko",
        naselje: "Novo Selo Okićko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3992",
        brojPu: "44000",
        redBroj: "3992",
        nazivPu: "Sisak",
        naselje: "Novo Selo Palanječko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "3993",
        brojPu: "47250",
        redBroj: "3993",
        nazivPu: "Duga Resa",
        naselje: "Novo Selo Perjasičko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "3994",
        brojPu: "42230",
        redBroj: "3994",
        nazivPu: "Ludbreg",
        naselje: "Novo Selo Podravsko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "3995",
        brojPu: "40000",
        redBroj: "3995",
        nazivPu: "Čakovec",
        naselje: "Novo Selo Rok",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "3996",
        brojPu: "10454",
        redBroj: "3996",
        nazivPu: "Krašić",
        naselje: "Novo Selo Žumberačko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "3997",
        brojPu: "35214",
        redBroj: "3997",
        nazivPu: "Donji Andrijevci",
        naselje: "Novo Topolje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "3998",
        brojPu: "48350",
        redBroj: "3998",
        nazivPu: "Đurđevac",
        naselje: "Novo Virje",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "3999",
        brojPu: "34000",
        redBroj: "3999",
        nazivPu: "Požega",
        naselje: "Novo Zvečevo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4000",
        brojPu: "34310",
        redBroj: "4000",
        nazivPu: "Pleternica",
        naselje: "Novoselci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4001",
        brojPu: "44210",
        redBroj: "4001",
        nazivPu: "Sunja",
        naselje: "Novoselci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4002",
        brojPu: "10315",
        redBroj: "4002",
        nazivPu: "Novoselec",
        naselje: "Novoselec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4003",
        brojPu: "53000",
        redBroj: "4003",
        nazivPu: "Gospić",
        naselje: "Novoselo Bilajsko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4004",
        brojPu: "53000",
        redBroj: "4004",
        nazivPu: "Gospić",
        naselje: "Novoselo Trnovačko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4005",
        brojPu: "43000",
        redBroj: "4005",
        nazivPu: "Bjelovar",
        naselje: "Novoseljani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4006",
        brojPu: "44330",
        redBroj: "4006",
        nazivPu: "Novska",
        naselje: "Novska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4007",
        brojPu: "22300",
        redBroj: "4007",
        nazivPu: "Knin",
        naselje: "Nunić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4008",
        brojPu: "34000",
        redBroj: "4008",
        nazivPu: "Požega",
        naselje: "Nurkovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4009",
        brojPu: "32100",
        redBroj: "4009",
        nazivPu: "Vinkovci",
        naselje: "Nuštar",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4010",
        brojPu: "34000",
        redBroj: "4010",
        nazivPu: "Požega",
        naselje: "Nježić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4011",
        brojPu: "51511",
        redBroj: "4011",
        nazivPu: "Malinska",
        naselje: "Njivice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4012",
        brojPu: "10410",
        redBroj: "4012",
        nazivPu: "Velika Gorica",
        naselje: "Obed",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4013",
        brojPu: "10315",
        redBroj: "4013",
        nazivPu: "Novoselec",
        naselje: "Obedišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4014",
        brojPu: "10370",
        redBroj: "4014",
        nazivPu: "Dugo Selo",
        naselje: "Obedišće Ježevsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4015",
        brojPu: "34000",
        redBroj: "4015",
        nazivPu: "Požega",
        naselje: "Oblakovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4016",
        brojPu: "10370",
        redBroj: "4016",
        nazivPu: "Dugo Selo",
        naselje: "Oborovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4017",
        brojPu: "10298",
        redBroj: "4017",
        nazivPu: "Bistra",
        naselje: "Oborovo Bistransko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4018",
        brojPu: "33515",
        redBroj: "4018",
        nazivPu: "Orahovica",
        naselje: "Obradovci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4019",
        brojPu: "42230",
        redBroj: "4019",
        nazivPu: "Ludbreg",
        naselje: "Obrankovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4020",
        brojPu: "10410",
        redBroj: "4020",
        nazivPu: "Velika Gorica",
        naselje: "Obrezina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4021",
        brojPu: "48260",
        redBroj: "4021",
        nazivPu: "Križevci",
        naselje: "Obrež Kalnički",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4022",
        brojPu: "47280",
        redBroj: "4022",
        nazivPu: "Ozalj",
        naselje: "Obrež Vivodinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4023",
        brojPu: "10380",
        redBroj: "4023",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Obrež Zelinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4024",
        brojPu: "47000",
        redBroj: "4024",
        nazivPu: "Karlovac",
        naselje: "Obrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4025",
        brojPu: "23450",
        redBroj: "4025",
        nazivPu: "Obrovac",
        naselje: "Obrovac",
        zupanija: "ZADARSKA",
      },
      {
        id: "4026",
        brojPu: "21238",
        redBroj: "4026",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Obrovac Sinjski",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4027",
        brojPu: "43000",
        redBroj: "4027",
        nazivPu: "Bjelovar",
        naselje: "Obrovnica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4028",
        brojPu: "51417",
        redBroj: "4028",
        nazivPu: "Mošćenička Draga",
        naselje: "Obrš",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4029",
        brojPu: "42250",
        redBroj: "4029",
        nazivPu: "Lepoglava",
        naselje: "Očura",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4030",
        brojPu: "49252",
        redBroj: "4030",
        nazivPu: "Mihovljan",
        naselje: "Očura",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4031",
        brojPu: "22300",
        redBroj: "4031",
        nazivPu: "Knin",
        naselje: "Oćestovo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4032",
        brojPu: "10020",
        redBroj: "4032",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Odra",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4033",
        brojPu: "44000",
        redBroj: "4033",
        nazivPu: "Sisak",
        naselje: "Odra Sisačka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4034",
        brojPu: "10020",
        redBroj: "4034",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Odranski Obrež",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4035",
        brojPu: "22202",
        redBroj: "4035",
        nazivPu: "Primošten",
        naselje: "Oglavci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4036",
        brojPu: "47300",
        redBroj: "4036",
        nazivPu: "Ogulin",
        naselje: "Ogulin",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4037",
        brojPu: "10410",
        redBroj: "4037",
        nazivPu: "Velika Gorica",
        naselje: "Ogulinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4038",
        brojPu: "52440",
        redBroj: "4038",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ohnići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4039",
        brojPu: "10315",
        redBroj: "4039",
        nazivPu: "Novoselec",
        naselje: "Okešinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4040",
        brojPu: "47220",
        redBroj: "4040",
        nazivPu: "Vojnić",
        naselje: "Okić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4041",
        brojPu: "22320",
        redBroj: "4041",
        nazivPu: "Drniš",
        naselje: "Oklaj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4042",
        brojPu: "21485",
        redBroj: "4042",
        nazivPu: "Komiža",
        naselje: "Oključna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4043",
        brojPu: "44317",
        redBroj: "4043",
        nazivPu: "Popovača",
        naselje: "Okoli",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4044",
        brojPu: "52341",
        redBroj: "4044",
        nazivPu: "Žminj",
        naselje: "Okreti",
        zupanija: "ISTARSKA",
      },
      {
        id: "4045",
        brojPu: "51306",
        redBroj: "4045",
        nazivPu: "Čabar",
        naselje: "Okrivje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4046",
        brojPu: "21220",
        redBroj: "4046",
        nazivPu: "Trogir",
        naselje: "Okrug Donji",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4047",
        brojPu: "21220",
        redBroj: "4047",
        nazivPu: "Trogir",
        naselje: "Okrug Gornji",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4048",
        brojPu: "40000",
        redBroj: "4048",
        nazivPu: "Čakovec",
        naselje: "Okrugli Vrh",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4049",
        brojPu: "33000",
        redBroj: "4049",
        nazivPu: "Virovitica",
        naselje: "Okrugljača",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4050",
        brojPu: "35430",
        redBroj: "4050",
        nazivPu: "Okučani",
        naselje: "Okučani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4051",
        brojPu: "10410",
        redBroj: "4051",
        nazivPu: "Velika Gorica",
        naselje: "Okuje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4052",
        brojPu: "20225",
        redBroj: "4052",
        nazivPu: "Babino Polje",
        naselje: "Okuklje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4053",
        brojPu: "10370",
        redBroj: "4053",
        nazivPu: "Dugo Selo",
        naselje: "Okunšćak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4054",
        brojPu: "23296",
        redBroj: "4054",
        nazivPu: "Olib",
        naselje: "Olib",
        zupanija: "ZADARSKA",
      },
      {
        id: "4055",
        brojPu: "34000",
        redBroj: "4055",
        nazivPu: "Požega",
        naselje: "Oljasi",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4056",
        brojPu: "10380",
        redBroj: "4056",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Omamno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4057",
        brojPu: "34550",
        redBroj: "4057",
        nazivPu: "Pakrac",
        naselje: "Omanovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4058",
        brojPu: "21310",
        redBroj: "4058",
        nazivPu: "Omiš",
        naselje: "Omiš",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4059",
        brojPu: "51511",
        redBroj: "4059",
        nazivPu: "Malinska",
        naselje: "Omišalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4060",
        brojPu: "23440",
        redBroj: "4060",
        nazivPu: "Gračac",
        naselje: "Omsica",
        zupanija: "ZADARSKA",
      },
      {
        id: "4061",
        brojPu: "53234",
        redBroj: "4061",
        nazivPu: "Udbina",
        naselje: "Ondić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4062",
        brojPu: "21256",
        redBroj: "4062",
        nazivPu: "Cista Provo",
        naselje: "Opanci",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4063",
        brojPu: "49246",
        redBroj: "4063",
        nazivPu: "Marija Bistrica",
        naselje: "Opasanjek",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4064",
        brojPu: "51410",
        redBroj: "4064",
        nazivPu: "Opatija",
        naselje: "Opatija",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4065",
        brojPu: "10414",
        redBroj: "4065",
        nazivPu: "Pokupsko ",
        naselje: "Opatija 1-36",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4066",
        brojPu: "10413",
        redBroj: "4066",
        nazivPu: "Kravarsko",
        naselje: "Opatija 37-91",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4067",
        brojPu: "10310",
        redBroj: "4067",
        nazivPu: "Ivanić Grad",
        naselje: "Opatinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4068",
        brojPu: "32236",
        redBroj: "4068",
        nazivPu: "Ilok",
        naselje: "Opatovac",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4069",
        brojPu: "35400",
        redBroj: "4069",
        nazivPu: "Nova Gradiška",
        naselje: "Opatovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4070",
        brojPu: "43240",
        redBroj: "4070",
        nazivPu: "Čazma",
        naselje: "Općevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4071",
        brojPu: "10291",
        redBroj: "4071",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Oplaznik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4072",
        brojPu: "40323",
        redBroj: "4072",
        nazivPu: "Prelog",
        naselje: "Oporovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4073",
        brojPu: "51415",
        redBroj: "4073",
        nazivPu: "Lovran",
        naselje: "Oprič",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4074",
        brojPu: "35212",
        redBroj: "4074",
        nazivPu: "Garčin",
        naselje: "Oprisavci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4075",
        brojPu: "35400",
        redBroj: "4075",
        nazivPu: "Nova Gradiška",
        naselje: "Opršinac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4076",
        brojPu: "52460",
        redBroj: "4076",
        nazivPu: "Buje (Buie)",
        naselje: "Oprtalj - Portole",
        zupanija: "ISTARSKA",
      },
      {
        id: "4077",
        brojPu: "20355",
        redBroj: "4077",
        nazivPu: "Opuzen",
        naselje: "Opuzen",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4078",
        brojPu: "21276",
        redBroj: "4078",
        nazivPu: "Vrgorac",
        naselje: "Orah",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4079",
        brojPu: "33515",
        redBroj: "4079",
        nazivPu: "Orahovica",
        naselje: "Orahovica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4080",
        brojPu: "53250",
        redBroj: "4080",
        nazivPu: "Donji Lapac",
        naselje: "Oraovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4081",
        brojPu: "20235",
        redBroj: "4081",
        nazivPu: "Zaton Veliki",
        naselje: "Orašac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4082",
        brojPu: "49217",
        redBroj: "4082",
        nazivPu: "Krapinske Toplice",
        naselje: "Oratje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4083",
        brojPu: "53230",
        redBroj: "4083",
        nazivPu: "Korenica",
        naselje: "Oravac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4084",
        brojPu: "52440",
        redBroj: "4084",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Orbani",
        zupanija: "ISTARSKA",
      },
      {
        id: "4085",
        brojPu: "52207",
        redBroj: "4085",
        nazivPu: "Barban",
        naselje: "Orbanići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4086",
        brojPu: "52341",
        redBroj: "4086",
        nazivPu: "Žminj",
        naselje: "Orbanići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4087",
        brojPu: "31500",
        redBroj: "4087",
        nazivPu: "Našice",
        naselje: "Ordanja",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4088",
        brojPu: "20250",
        redBroj: "4088",
        nazivPu: "Orebić",
        naselje: "Orebić",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4089",
        brojPu: "49240",
        redBroj: "4089",
        nazivPu: "Donja Stubica",
        naselje: "Orehova Gorica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4090",
        brojPu: "42220",
        redBroj: "4090",
        nazivPu: "Novi Marof",
        naselje: "Orehovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4091",
        brojPu: "48260",
        redBroj: "4091",
        nazivPu: "Križevci",
        naselje: "Orehovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4092",
        brojPu: "49000",
        redBroj: "4092",
        nazivPu: "Krapina",
        naselje: "Orehovec Radobojski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4093",
        brojPu: "40000",
        redBroj: "4093",
        nazivPu: "Čakovec",
        naselje: "Orehovica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4094",
        brojPu: "49221",
        redBroj: "4094",
        nazivPu: "Bedekovčina",
        naselje: "Orehovica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4095",
        brojPu: "33410",
        redBroj: "4095",
        nazivPu: "Suhopolje",
        naselje: "Orešac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4096",
        brojPu: "10431",
        redBroj: "4096",
        nazivPu: "Sveta Nedelja",
        naselje: "Orešje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4097",
        brojPu: "49231",
        redBroj: "4097",
        nazivPu: "Hum na Sutli",
        naselje: "Orešje Humsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4098",
        brojPu: "10450",
        redBroj: "4098",
        nazivPu: "Jastrebarsko",
        naselje: "Orešje Okićko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4099",
        brojPu: "52000",
        redBroj: "4099",
        nazivPu: "Pazin",
        naselje: "Orič",
        zupanija: "ISTARSKA",
      },
      {
        id: "4100",
        brojPu: "52207",
        redBroj: "4100",
        nazivPu: "Barban",
        naselje: "Orihi",
        zupanija: "ISTARSKA",
      },
      {
        id: "4101",
        brojPu: "47250",
        redBroj: "4101",
        nazivPu: "Duga Resa",
        naselje: "Orijevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4102",
        brojPu: "35250",
        redBroj: "4102",
        nazivPu: "Oriovac",
        naselje: "Oriovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4103",
        brojPu: "35000",
        redBroj: "4103",
        nazivPu: "Slavonski Brod",
        naselje: "Oriovčić",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4104",
        brojPu: "47250",
        redBroj: "4104",
        nazivPu: "Duga Resa",
        naselje: "Orišje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4105",
        brojPu: "10410",
        redBroj: "4105",
        nazivPu: "Velika Gorica",
        naselje: "Orle",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4106",
        brojPu: "51557",
        redBroj: "4106",
        nazivPu: "Cres",
        naselje: "Orlec",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4107",
        brojPu: "22300",
        redBroj: "4107",
        nazivPu: "Knin",
        naselje: "Orlić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4108",
        brojPu: "43270",
        redBroj: "4108",
        nazivPu: "Veliki Grđevac",
        naselje: "Orlovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4109",
        brojPu: "43290",
        redBroj: "4109",
        nazivPu: "Grubišno Polje",
        naselje: "Orlovac Zdenački",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4110",
        brojPu: "47280",
        redBroj: "4110",
        nazivPu: "Ozalj",
        naselje: "Orljakovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4111",
        brojPu: "34000",
        redBroj: "4111",
        nazivPu: "Požega",
        naselje: "Orljavac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4112",
        brojPu: "53000",
        redBroj: "4112",
        nazivPu: "Gospić",
        naselje: "Ornice",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4113",
        brojPu: "32242",
        redBroj: "4113",
        nazivPu: "Slakovci",
        naselje: "Orolik",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4114",
        brojPu: "49243",
        redBroj: "4114",
        nazivPu: "Oroslavje",
        naselje: "Oroslavje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4115",
        brojPu: "43000",
        redBroj: "4115",
        nazivPu: "Bjelovar",
        naselje: "Orovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4116",
        brojPu: "35400",
        redBroj: "4116",
        nazivPu: "Nova Gradiška",
        naselje: "Orubica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4117",
        brojPu: "42240",
        redBroj: "4117",
        nazivPu: "Ivanec",
        naselje: "Osečka",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4118",
        brojPu: "44317",
        redBroj: "4118",
        nazivPu: "Popovača",
        naselje: "Osekovo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4119",
        brojPu: "31000",
        redBroj: "4119",
        nazivPu: "Osijek",
        naselje: "Osijek",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4120",
        brojPu: "48260",
        redBroj: "4120",
        nazivPu: "Križevci",
        naselje: "Osijek Vojakovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4121",
        brojPu: "52460",
        redBroj: "4121",
        nazivPu: "Buje (Buie)",
        naselje: "Oskoruš - Oscurus",
        zupanija: "ISTARSKA",
      },
      {
        id: "4122",
        brojPu: "20240",
        redBroj: "4122",
        nazivPu: "Trpanj",
        naselje: "Oskorušno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4123",
        brojPu: "52000",
        redBroj: "4123",
        nazivPu: "Pazin",
        naselje: "Oslići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4124",
        brojPu: "20246",
        redBroj: "4124",
        nazivPu: "Janjina",
        naselje: "Osobjava",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4125",
        brojPu: "21232",
        redBroj: "4125",
        nazivPu: "Dicmo",
        naselje: "Osoje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4126",
        brojPu: "20236",
        redBroj: "4126",
        nazivPu: "Mokošica",
        naselje: "Osojnik",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4127",
        brojPu: "51326",
        redBroj: "4127",
        nazivPu: "Vrbovsko",
        naselje: "Osojnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4128",
        brojPu: "42250",
        redBroj: "4128",
        nazivPu: "Lepoglava",
        naselje: "Osonjak",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4129",
        brojPu: "51550",
        redBroj: "4129",
        nazivPu: "Mali Lošinj",
        naselje: "Osor",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4130",
        brojPu: "53250",
        redBroj: "4130",
        nazivPu: "Donji Lapac",
        naselje: "Osredci",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4131",
        brojPu: "49216",
        redBroj: "4131",
        nazivPu: "Desinić",
        naselje: "Osredek Desinićki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4132",
        brojPu: "10454",
        redBroj: "4132",
        nazivPu: "Krašić",
        naselje: "Osredek Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4133",
        brojPu: "44440",
        redBroj: "4133",
        nazivPu: "Dvor",
        naselje: "Ostojići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4134",
        brojPu: "22323",
        redBroj: "4134",
        nazivPu: "Unešić",
        naselje: "Ostrogašica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4135",
        brojPu: "31500",
        redBroj: "4135",
        nazivPu: "Našice",
        naselje: "Ostrošinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4136",
        brojPu: "23420",
        redBroj: "4136",
        nazivPu: "Benkovac",
        naselje: "Ostrovica",
        zupanija: "ZADARSKA",
      },
      {
        id: "4137",
        brojPu: "32100",
        redBroj: "4137",
        nazivPu: "Vinkovci",
        naselje: "Ostrovo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4138",
        brojPu: "44410",
        redBroj: "4138",
        nazivPu: "Vrginmost",
        naselje: "Ostrožin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4139",
        brojPu: "21292",
        redBroj: "4139",
        nazivPu: "Srinjine",
        naselje: "Ostrvica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4140",
        brojPu: "53000",
        redBroj: "4140",
        nazivPu: "Gospić",
        naselje: "Ostrvica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4141",
        brojPu: "10454",
        redBroj: "4141",
        nazivPu: "Krašić",
        naselje: "Osunja",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4142",
        brojPu: "23273",
        redBroj: "4142",
        nazivPu: "Preko",
        naselje: "Ošljak",
        zupanija: "ZADARSKA",
      },
      {
        id: "4143",
        brojPu: "20230",
        redBroj: "4143",
        nazivPu: "Ston",
        naselje: "Ošlje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4144",
        brojPu: "47300",
        redBroj: "4144",
        nazivPu: "Ogulin",
        naselje: "Oštarije",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4145",
        brojPu: "47240",
        redBroj: "4145",
        nazivPu: "Slunj",
        naselje: "Oštarski Stanovi",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4146",
        brojPu: "35420",
        redBroj: "4146",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Oštri Vrh",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4147",
        brojPu: "43280",
        redBroj: "4147",
        nazivPu: "Garešnica",
        naselje: "Oštri Zid",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4148",
        brojPu: "42220",
        redBroj: "4148",
        nazivPu: "Novi Marof",
        naselje: "Oštrice",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4149",
        brojPu: "51511",
        redBroj: "4149",
        nazivPu: "Malinska",
        naselje: "Oštrobradić",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4150",
        brojPu: "22320",
        redBroj: "4150",
        nazivPu: "Drniš",
        naselje: "Otavice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4151",
        brojPu: "53000",
        redBroj: "4151",
        nazivPu: "Gospić",
        naselje: "Oteš",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4152",
        brojPu: "21236",
        redBroj: "4152",
        nazivPu: "Vrlika",
        naselje: "Otišić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4153",
        brojPu: "43500",
        redBroj: "4153",
        nazivPu: "Daruvar",
        naselje: "Otkopi",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4154",
        brojPu: "53220",
        redBroj: "4154",
        nazivPu: "Otočac",
        naselje: "Otočac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4155",
        brojPu: "48331",
        redBroj: "4155",
        nazivPu: "Gola",
        naselje: "Otočka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4156",
        brojPu: "20355",
        redBroj: "4156",
        nazivPu: "Opuzen",
        naselje: "Otok",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4157",
        brojPu: "21238",
        redBroj: "4157",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Otok",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4158",
        brojPu: "32252",
        redBroj: "4158",
        nazivPu: "Otok",
        naselje: "Otok",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4159",
        brojPu: "40323",
        redBroj: "4159",
        nazivPu: "Prelog",
        naselje: "Otok",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4160",
        brojPu: "47250",
        redBroj: "4160",
        nazivPu: "Duga Resa",
        naselje: "Otok na Dobri",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4161",
        brojPu: "10361",
        redBroj: "4161",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Otok Nartski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4162",
        brojPu: "47300",
        redBroj: "4162",
        nazivPu: "Ogulin",
        naselje: "Otok Oštarijski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4163",
        brojPu: "10361",
        redBroj: "4163",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Otok Svibovski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4164",
        brojPu: "42208",
        redBroj: "4164",
        nazivPu: "Cestica",
        naselje: "Otok Virje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4165",
        brojPu: "22300",
        redBroj: "4165",
        nazivPu: "Knin",
        naselje: "Oton",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4166",
        brojPu: "10380",
        redBroj: "4166",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Otrčkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4167",
        brojPu: "23440",
        redBroj: "4167",
        nazivPu: "Gračac",
        naselje: "Otrić",
        zupanija: "ZADARSKA",
      },
      {
        id: "4168",
        brojPu: "20350",
        redBroj: "4168",
        nazivPu: "Metković",
        naselje: "Otrić-Seoci",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4169",
        brojPu: "33405",
        redBroj: "4169",
        nazivPu: "Pitomača",
        naselje: "Otrovanec",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4170",
        brojPu: "10430",
        redBroj: "4170",
        nazivPu: "Samobor",
        naselje: "Otruševec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4171",
        brojPu: "31400",
        redBroj: "4171",
        nazivPu: "Đakovo",
        naselje: "Ovčara",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4172",
        brojPu: "34340",
        redBroj: "4172",
        nazivPu: "Kutjevo",
        naselje: "Ovčare",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4173",
        brojPu: "21238",
        redBroj: "4173",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Ovrlje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4174",
        brojPu: "47280",
        redBroj: "4174",
        nazivPu: "Ozalj",
        naselje: "Ozalj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4175",
        brojPu: "34000",
        redBroj: "4175",
        nazivPu: "Požega",
        naselje: "Ozdakovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4176",
        brojPu: "34550",
        redBroj: "4176",
        nazivPu: "Pakrac",
        naselje: "Ožegovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4177",
        brojPu: "32000",
        redBroj: "4177",
        nazivPu: "Vukovar",
        naselje: "Pačetin",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4178",
        brojPu: "22300",
        redBroj: "4178",
        nazivPu: "Knin",
        naselje: "Pađene",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4179",
        brojPu: "23250",
        redBroj: "4179",
        nazivPu: "Pag",
        naselje: "Pag",
        zupanija: "ZADARSKA",
      },
      {
        id: "4180",
        brojPu: "52000",
        redBroj: "4180",
        nazivPu: "Pazin",
        naselje: "Pagubice",
        zupanija: "ISTARSKA",
      },
      {
        id: "4181",
        brojPu: "52440",
        redBroj: "4181",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Pajari",
        zupanija: "ISTARSKA",
      },
      {
        id: "4182",
        brojPu: "52341",
        redBroj: "4182",
        nazivPu: "Žminj",
        naselje: "Pajkovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4183",
        brojPu: "34340",
        redBroj: "4183",
        nazivPu: "Kutjevo",
        naselje: "Paka",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4184",
        brojPu: "42220",
        redBroj: "4184",
        nazivPu: "Novi Marof",
        naselje: "Paka",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4185",
        brojPu: "44330",
        redBroj: "4185",
        nazivPu: "Novska",
        naselje: "Paklenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4186",
        brojPu: "23210",
        redBroj: "4186",
        nazivPu: "Biograd na Moru",
        naselje: "Pakoštane",
        zupanija: "ZADARSKA",
      },
      {
        id: "4187",
        brojPu: "22320",
        redBroj: "4187",
        nazivPu: "Drniš",
        naselje: "Pakovo Selo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4188",
        brojPu: "34550",
        redBroj: "4188",
        nazivPu: "Pakrac",
        naselje: "Pakrac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4189",
        brojPu: "43500",
        redBroj: "4189",
        nazivPu: "Daruvar",
        naselje: "Pakrani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4190",
        brojPu: "31207",
        redBroj: "4190",
        nazivPu: "Tenja",
        naselje: "Palača",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4191",
        brojPu: "52420",
        redBroj: "4191",
        nazivPu: "Buzet",
        naselje: "Paladini",
        zupanija: "ISTARSKA",
      },
      {
        id: "4192",
        brojPu: "21485",
        redBroj: "4192",
        nazivPu: "Komiža",
        naselje: "Palagruža",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4193",
        brojPu: "43240",
        redBroj: "4193",
        nazivPu: "Čazma",
        naselje: "Palančani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4194",
        brojPu: "23440",
        redBroj: "4194",
        nazivPu: "Gračac",
        naselje: "Palanka",
        zupanija: "ZADARSKA",
      },
      {
        id: "4195",
        brojPu: "44000",
        redBroj: "4195",
        nazivPu: "Sisak",
        naselje: "Palanjek",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4196",
        brojPu: "44000",
        redBroj: "4196",
        nazivPu: "Sisak",
        naselje: "Palanjek Pokupski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4197",
        brojPu: "43280",
        redBroj: "4197",
        nazivPu: "Garešnica",
        naselje: "Palešnik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4198",
        brojPu: "40320",
        redBroj: "4198",
        nazivPu: "Donji Kraljevec",
        naselje: "Palinovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4199",
        brojPu: "51280",
        redBroj: "4199",
        nazivPu: "Rab",
        naselje: "Palit",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4200",
        brojPu: "40000",
        redBroj: "4200",
        nazivPu: "Čakovec",
        naselje: "Palovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4201",
        brojPu: "20215",
        redBroj: "4201",
        nazivPu: "Gruda",
        naselje: "Palje Brdo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4202",
        brojPu: "31400",
        redBroj: "4202",
        nazivPu: "Đakovo",
        naselje: "Paljevina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4203",
        brojPu: "43000",
        redBroj: "4203",
        nazivPu: "Bjelovar",
        naselje: "Paljevine",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4204",
        brojPu: "10450",
        redBroj: "4204",
        nazivPu: "Jastrebarsko",
        naselje: "Paljugi",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4205",
        brojPu: "23242",
        redBroj: "4205",
        nazivPu: "Posedarje",
        naselje: "Paljuv",
        zupanija: "ZADARSKA",
      },
      {
        id: "4206",
        brojPu: "52341",
        redBroj: "4206",
        nazivPu: "Žminj",
        naselje: "Pamići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4207",
        brojPu: "44430",
        redBroj: "4207",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Panjani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4208",
        brojPu: "44210",
        redBroj: "4208",
        nazivPu: "Sunja",
        naselje: "Papići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4209",
        brojPu: "42000",
        redBroj: "4209",
        nazivPu: "Varaždin",
        naselje: "Papinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4210",
        brojPu: "52220",
        redBroj: "4210",
        nazivPu: "Labin",
        naselje: "Paradiž",
        zupanija: "ISTARSKA",
      },
      {
        id: "4211",
        brojPu: "40000",
        redBroj: "4211",
        nazivPu: "Čakovec",
        naselje: "Parag",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4212",
        brojPu: "22300",
        redBroj: "4212",
        nazivPu: "Knin",
        naselje: "Parčić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4213",
        brojPu: "22320",
        redBroj: "4213",
        nazivPu: "Drniš",
        naselje: "Parčić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4214",
        brojPu: "51306",
        redBroj: "4214",
        nazivPu: "Čabar",
        naselje: "Parg",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4215",
        brojPu: "10342",
        redBroj: "4215",
        nazivPu: "Dubrava",
        naselje: "Paruževac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4216",
        brojPu: "10362",
        redBroj: "4216",
        nazivPu: "Kašina",
        naselje: "Paruževina",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4217",
        brojPu: "20290",
        redBroj: "4217",
        nazivPu: "Lastovo",
        naselje: "Pasadur",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4218",
        brojPu: "49240",
        redBroj: "4218",
        nazivPu: "Donja Stubica",
        naselje: "Pasanska Gorica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4219",
        brojPu: "34000",
        redBroj: "4219",
        nazivPu: "Požega",
        naselje: "Pasikovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4220",
        brojPu: "51211",
        redBroj: "4220",
        nazivPu: "Matulji",
        naselje: "Pasjak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4221",
        brojPu: "47240",
        redBroj: "4221",
        nazivPu: "Slunj",
        naselje: "Pašin Potok",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4222",
        brojPu: "23212",
        redBroj: "4222",
        nazivPu: "Tkon",
        naselje: "Pašman",
        zupanija: "ZADARSKA",
      },
      {
        id: "4223",
        brojPu: "42250",
        redBroj: "4223",
        nazivPu: "Lepoglava",
        naselje: "Pašnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4224",
        brojPu: "43000",
        redBroj: "4224",
        nazivPu: "Bjelovar",
        naselje: "Patkovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4225",
        brojPu: "51326",
        redBroj: "4225",
        nazivPu: "Vrbovsko",
        naselje: "Pauci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4226",
        brojPu: "31400",
        redBroj: "4226",
        nazivPu: "Đakovo",
        naselje: "Paučje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4227",
        brojPu: "44440",
        redBroj: "4227",
        nazivPu: "Dvor",
        naselje: "Paukovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4228",
        brojPu: "10380",
        redBroj: "4228",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Paukovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4229",
        brojPu: "31200",
        redBroj: "4229",
        nazivPu: "Osijek",
        naselje: "Paulin Dvor",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4230",
        brojPu: "43000",
        redBroj: "4230",
        nazivPu: "Bjelovar",
        naselje: "Paulovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4231",
        brojPu: "48000",
        redBroj: "4231",
        nazivPu: "Koprivnica",
        naselje: "Paunovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4232",
        brojPu: "33515",
        redBroj: "4232",
        nazivPu: "Orahovica",
        naselje: "Paušinci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4233",
        brojPu: "47000",
        redBroj: "4233",
        nazivPu: "Karlovac",
        naselje: "Pavičići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4234",
        brojPu: "52207",
        redBroj: "4234",
        nazivPu: "Barban",
        naselje: "Pavićini",
        zupanija: "ISTARSKA",
      },
      {
        id: "4235",
        brojPu: "47220",
        redBroj: "4235",
        nazivPu: "Vojnić",
        naselje: "Pavković Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4236",
        brojPu: "43240",
        redBroj: "4236",
        nazivPu: "Čazma",
        naselje: "Pavličani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4237",
        brojPu: "43000",
        redBroj: "4237",
        nazivPu: "Bjelovar",
        naselje: "Pavlin Kloštar",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4238",
        brojPu: "43270",
        redBroj: "4238",
        nazivPu: "Veliki Grđevac",
        naselje: "Pavlovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4239",
        brojPu: "47240",
        redBroj: "4239",
        nazivPu: "Slunj",
        naselje: "Pavlovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4240",
        brojPu: "53000",
        redBroj: "4240",
        nazivPu: "Gospić",
        naselje: "Pavlovac Vrebački",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4241",
        brojPu: "34000",
        redBroj: "4241",
        nazivPu: "Požega",
        naselje: "Pavlovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4242",
        brojPu: "35410",
        redBroj: "4242",
        nazivPu: "Nova Kapela",
        naselje: "Pavlovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4243",
        brojPu: "10450",
        redBroj: "4243",
        nazivPu: "Jastrebarsko",
        naselje: "Pavlovčani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4244",
        brojPu: "49218",
        redBroj: "4244",
        nazivPu: "Pregrada",
        naselje: "Pavlovec Pregradski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4245",
        brojPu: "48260",
        redBroj: "4245",
        nazivPu: "Križevci",
        naselje: "Pavlovec Ravenski",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4246",
        brojPu: "49210",
        redBroj: "4246",
        nazivPu: "Zabok",
        naselje: "Pavlovec Zabočki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4247",
        brojPu: "10450",
        redBroj: "4247",
        nazivPu: "Jastrebarsko",
        naselje: "Pavučnjak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4248",
        brojPu: "52420",
        redBroj: "4248",
        nazivPu: "Buzet",
        naselje: "Paz",
        zupanija: "ISTARSKA",
      },
      {
        id: "4249",
        brojPu: "52000",
        redBroj: "4249",
        nazivPu: "Pazin",
        naselje: "Pazin",
        zupanija: "ISTARSKA",
      },
      {
        id: "4250",
        brojPu: "49284",
        redBroj: "4250",
        nazivPu: "Budinščina",
        naselje: "Pažurovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4251",
        brojPu: "33410",
        redBroj: "4251",
        nazivPu: "Suhopolje",
        naselje: "Pčelić",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4252",
        brojPu: "42240",
        redBroj: "4252",
        nazivPu: "Ivanec",
        naselje: "Pece",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4253",
        brojPu: "49284",
        redBroj: "4253",
        nazivPu: "Budinščina",
        naselje: "Pece",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4254",
        brojPu: "44400",
        redBroj: "4254",
        nazivPu: "Glina",
        naselje: "Pecka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4255",
        brojPu: "44250",
        redBroj: "4255",
        nazivPu: "Petrinja",
        naselje: "Pecki",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4256",
        brojPu: "53230",
        redBroj: "4256",
        nazivPu: "Korenica",
        naselje: "Pećane",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4257",
        brojPu: "47280",
        redBroj: "4257",
        nazivPu: "Ozalj",
        naselje: "Pećarići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4258",
        brojPu: "51300",
        redBroj: "4258",
        nazivPu: "Delnice",
        naselje: "Pećišće",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4259",
        brojPu: "10454",
        redBroj: "4259",
        nazivPu: "Krašić",
        naselje: "Pećno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4260",
        brojPu: "47250",
        redBroj: "4260",
        nazivPu: "Duga Resa",
        naselje: "Pećurkovo Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4261",
        brojPu: "44440",
        redBroj: "4261",
        nazivPu: "Dvor",
        naselje: "Pedalj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4262",
        brojPu: "10342",
        redBroj: "4262",
        nazivPu: "Dubrava",
        naselje: "Pehardovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4263",
        brojPu: "40315",
        redBroj: "4263",
        nazivPu: "Mursko Središče",
        naselje: "Peklenica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4264",
        brojPu: "52420",
        redBroj: "4264",
        nazivPu: "Buzet",
        naselje: "Pengari",
        zupanija: "ISTARSKA",
      },
      {
        id: "4265",
        brojPu: "52420",
        redBroj: "4265",
        nazivPu: "Buzet",
        naselje: "Peničići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4266",
        brojPu: "33410",
        redBroj: "4266",
        nazivPu: "Suhopolje",
        naselje: "Pepelana",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4267",
        brojPu: "20340",
        redBroj: "4267",
        nazivPu: "Ploče",
        naselje: "Peračko Blato",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4268",
        brojPu: "52420",
        redBroj: "4268",
        nazivPu: "Buzet",
        naselje: "Perci",
        zupanija: "ISTARSKA",
      },
      {
        id: "4269",
        brojPu: "52440",
        redBroj: "4269",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Perci",
        zupanija: "ISTARSKA",
      },
      {
        id: "4270",
        brojPu: "34000",
        redBroj: "4270",
        nazivPu: "Požega",
        naselje: "Perenci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4271",
        brojPu: "52341",
        redBroj: "4271",
        nazivPu: "Žminj",
        naselje: "Peresiji",
        zupanija: "ISTARSKA",
      },
      {
        id: "4272",
        brojPu: "47220",
        redBroj: "4272",
        nazivPu: "Vojnić",
        naselje: "Perići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4273",
        brojPu: "52440",
        redBroj: "4273",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Perini",
        zupanija: "ISTARSKA",
      },
      {
        id: "4274",
        brojPu: "47250",
        redBroj: "4274",
        nazivPu: "Duga Resa",
        naselje: "Perjasica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4275",
        brojPu: "22000",
        redBroj: "4275",
        nazivPu: "Šibenik",
        naselje: "Perković",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4276",
        brojPu: "51211",
        redBroj: "4276",
        nazivPu: "Matulji",
        naselje: "Permani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4277",
        brojPu: "44400",
        redBroj: "4277",
        nazivPu: "Glina",
        naselje: "Perna",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4278",
        brojPu: "51557",
        redBroj: "4278",
        nazivPu: "Cres",
        naselje: "Pernat",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4279",
        brojPu: "52100",
        redBroj: "4279",
        nazivPu: "Pula (Pola)",
        naselje: "Peroj - Peroi",
        zupanija: "ISTARSKA",
      },
      {
        id: "4280",
        brojPu: "49250",
        redBroj: "4280",
        nazivPu: "Zlatar",
        naselje: "Peršaves",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4281",
        brojPu: "53202",
        redBroj: "4281",
        nazivPu: "Perušić",
        naselje: "Perušić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4282",
        brojPu: "23420",
        redBroj: "4282",
        nazivPu: "Benkovac",
        naselje: "Perušić Benkovački",
        zupanija: "ZADARSKA",
      },
      {
        id: "4283",
        brojPu: "23420",
        redBroj: "4283",
        nazivPu: "Benkovac",
        naselje: "Perušić Donji",
        zupanija: "ZADARSKA",
      },
      {
        id: "4284",
        brojPu: "52207",
        redBroj: "4284",
        nazivPu: "Barban",
        naselje: "Peruški",
        zupanija: "ISTARSKA",
      },
      {
        id: "4285",
        brojPu: "10454",
        redBroj: "4285",
        nazivPu: "Krašić",
        naselje: "Pesak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4286",
        brojPu: "48260",
        redBroj: "4286",
        nazivPu: "Križevci",
        naselje: "Pesek",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4287",
        brojPu: "10340",
        redBroj: "4287",
        nazivPu: "Vrbovec",
        naselje: "Peskovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4288",
        brojPu: "48000",
        redBroj: "4288",
        nazivPu: "Koprivnica",
        naselje: "Peščenik",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4289",
        brojPu: "44250",
        redBroj: "4289",
        nazivPu: "Petrinja",
        naselje: "Pešćenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4290",
        brojPu: "42243",
        redBroj: "4290",
        nazivPu: "Maruševec",
        naselje: "Pešćenica Vinička",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4291",
        brojPu: "49282",
        redBroj: "4291",
        nazivPu: "Konjščina",
        naselje: "Pešćeno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4292",
        brojPu: "52207",
        redBroj: "4292",
        nazivPu: "Barban",
        naselje: "Petehi",
        zupanija: "ISTARSKA",
      },
      {
        id: "4293",
        brojPu: "48316",
        redBroj: "4293",
        nazivPu: "Đelekovec",
        naselje: "Peteranec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4294",
        brojPu: "10410",
        redBroj: "4294",
        nazivPu: "Velika Gorica",
        naselje: "Petina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4295",
        brojPu: "10450",
        redBroj: "4295",
        nazivPu: "Jastrebarsko",
        naselje: "Petkov Breg",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4296",
        brojPu: "44250",
        redBroj: "4296",
        nazivPu: "Petrinja",
        naselje: "Petkovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4297",
        brojPu: "42223",
        redBroj: "4297",
        nazivPu: "Varaždinske Toplice",
        naselje: "Petkovec Toplički",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4298",
        brojPu: "31300",
        redBroj: "4298",
        nazivPu: "Beli Manastir",
        naselje: "Petlovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4299",
        brojPu: "20207",
        redBroj: "4299",
        nazivPu: "Mlini",
        naselje: "Petrača",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4300",
        brojPu: "47250",
        redBroj: "4300",
        nazivPu: "Duga Resa",
        naselje: "Petrakovo Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4301",
        brojPu: "10410",
        redBroj: "4301",
        nazivPu: "Velika Gorica",
        naselje: "Petravec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4302",
        brojPu: "23000",
        redBroj: "4302",
        nazivPu: "Zadar",
        naselje: "Petrčane",
        zupanija: "ZADARSKA",
      },
      {
        id: "4303",
        brojPu: "10454",
        redBroj: "4303",
        nazivPu: "Krašić",
        naselje: "Petričko Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4304",
        brojPu: "42208",
        redBroj: "4304",
        nazivPu: "Cestica",
        naselje: "Petrijanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4305",
        brojPu: "31550",
        redBroj: "4305",
        nazivPu: "Valpovo",
        naselje: "Petrijevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4306",
        brojPu: "44250",
        redBroj: "4306",
        nazivPu: "Petrinja",
        naselje: "Petrinja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4307",
        brojPu: "44210",
        redBroj: "4307",
        nazivPu: "Sunja",
        naselje: "Petrinjci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4308",
        brojPu: "49250",
        redBroj: "4308",
        nazivPu: "Zlatar",
        naselje: "Petrova Gora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4309",
        brojPu: "47220",
        redBroj: "4309",
        nazivPu: "Vojnić",
        naselje: "Petrova Poljana",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4310",
        brojPu: "31200",
        redBroj: "4310",
        nazivPu: "Osijek",
        naselje: "Petrova Slatina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4311",
        brojPu: "32000",
        redBroj: "4311",
        nazivPu: "Vukovar",
        naselje: "Petrovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4312",
        brojPu: "44250",
        redBroj: "4312",
        nazivPu: "Petrinja",
        naselje: "Petrovec",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4313",
        brojPu: "51326",
        redBroj: "4313",
        nazivPu: "Vrbovsko",
        naselje: "Petrovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4314",
        brojPu: "52470",
        redBroj: "4314",
        nazivPu: "Umag (Umago)",
        naselje: "Petrovija - Petrovia",
        zupanija: "ISTARSKA",
      },
      {
        id: "4315",
        brojPu: "10450",
        redBroj: "4315",
        nazivPu: "Jastrebarsko",
        naselje: "Petrovina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4316",
        brojPu: "10410",
        redBroj: "4316",
        nazivPu: "Velika Gorica",
        naselje: "Petrovina Turopoljska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4317",
        brojPu: "20236",
        redBroj: "4317",
        nazivPu: "Mokošica",
        naselje: "Petrovo Selo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4318",
        brojPu: "49000",
        redBroj: "4318",
        nazivPu: "Krapina",
        naselje: "Petrovsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4319",
        brojPu: "47250",
        redBroj: "4319",
        nazivPu: "Duga Resa",
        naselje: "Petrunići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4320",
        brojPu: "47280",
        redBroj: "4320",
        nazivPu: "Ozalj",
        naselje: "Petruš Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4321",
        brojPu: "49250",
        redBroj: "4321",
        nazivPu: "Zlatar",
        naselje: "Petruševec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4322",
        brojPu: "52000",
        redBroj: "4322",
        nazivPu: "Pazin",
        naselje: "Pićan",
        zupanija: "ISTARSKA",
      },
      {
        id: "4323",
        brojPu: "52341",
        redBroj: "4323",
        nazivPu: "Žminj",
        naselje: "Pifari",
        zupanija: "ISTARSKA",
      },
      {
        id: "4324",
        brojPu: "42202",
        redBroj: "4324",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Pihovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4325",
        brojPu: "53270",
        redBroj: "4325",
        nazivPu: "Senj",
        naselje: "Pijavica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4326",
        brojPu: "20244",
        redBroj: "4326",
        nazivPu: "Potomje",
        naselje: "Pijavičino",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4327",
        brojPu: "49240",
        redBroj: "4327",
        nazivPu: "Donja Stubica",
        naselje: "Pila",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4328",
        brojPu: "47280",
        redBroj: "4328",
        nazivPu: "Ozalj",
        naselje: "Pilatovci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4329",
        brojPu: "44330",
        redBroj: "4329",
        nazivPu: "Novska",
        naselje: "Piljenice",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4330",
        brojPu: "51500",
        redBroj: "4330",
        nazivPu: "Krk",
        naselje: "Pinezići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4331",
        brojPu: "52207",
        redBroj: "4331",
        nazivPu: "Barban",
        naselje: "Pinezići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4332",
        brojPu: "10340",
        redBroj: "4332",
        nazivPu: "Vrbovec",
        naselje: "Pirakovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4333",
        brojPu: "22222",
        redBroj: "4333",
        nazivPu: "Skradin",
        naselje: "Piramatovci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4334",
        brojPu: "52424",
        redBroj: "4334",
        nazivPu: "Motovun (Montona)",
        naselje: "Pirelići - Perelici",
        zupanija: "ISTARSKA",
      },
      {
        id: "4335",
        brojPu: "22213",
        redBroj: "4335",
        nazivPu: "Pirovac",
        naselje: "Pirovac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4336",
        brojPu: "21310",
        redBroj: "4336",
        nazivPu: "Omiš",
        naselje: "Pisak",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4337",
        brojPu: "10451",
        redBroj: "4337",
        nazivPu: "Pisarovina",
        naselje: "Pisarovina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4338",
        brojPu: "47000",
        redBroj: "4338",
        nazivPu: "Karlovac",
        naselje: "Piščetke",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4339",
        brojPu: "42223",
        redBroj: "4339",
        nazivPu: "Varaždinske Toplice",
        naselje: "Pišćanovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4340",
        brojPu: "31400",
        redBroj: "4340",
        nazivPu: "Đakovo",
        naselje: "Piškorevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4341",
        brojPu: "40000",
        redBroj: "4341",
        nazivPu: "Čakovec",
        naselje: "Piškorovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4342",
        brojPu: "48260",
        redBroj: "4342",
        nazivPu: "Križevci",
        naselje: "Piškovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4343",
        brojPu: "52440",
        redBroj: "4343",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Piškovica",
        zupanija: "ISTARSKA",
      },
      {
        id: "4344",
        brojPu: "33405",
        redBroj: "4344",
        nazivPu: "Pitomača",
        naselje: "Pitomača",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4345",
        brojPu: "21465",
        redBroj: "4345",
        nazivPu: "Jelsa",
        naselje: "Pitve",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4346",
        brojPu: "35430",
        redBroj: "4346",
        nazivPu: "Okučani",
        naselje: "Pivare",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4347",
        brojPu: "33410",
        redBroj: "4347",
        nazivPu: "Suhopolje",
        naselje: "Pivnica Slavonska",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4348",
        brojPu: "20355",
        redBroj: "4348",
        nazivPu: "Opuzen",
        naselje: "Pižinovac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4349",
        brojPu: "52100",
        redBroj: "4349",
        nazivPu: "Pula (Pola)",
        naselje: "Pješčana uvala",
        zupanija: "ISTARSKA",
      },
      {
        id: "4350",
        brojPu: "44410",
        redBroj: "4350",
        nazivPu: "Vrginmost",
        naselje: "Pješčanica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4351",
        brojPu: "51300",
        redBroj: "4351",
        nazivPu: "Delnice",
        naselje: "Plajzi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4352",
        brojPu: "51326",
        redBroj: "4352",
        nazivPu: "Vrbovsko",
        naselje: "Planica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4353",
        brojPu: "10362",
        redBroj: "4353",
        nazivPu: "Kašina",
        naselje: "Planina Donja",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4354",
        brojPu: "10362",
        redBroj: "4354",
        nazivPu: "Kašina",
        naselje: "Planina Gornja",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4355",
        brojPu: "47000",
        redBroj: "4355",
        nazivPu: "Karlovac",
        naselje: "Planina Kunićka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4356",
        brojPu: "51300",
        redBroj: "4356",
        nazivPu: "Delnice",
        naselje: "Planina Skradska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4357",
        brojPu: "21220",
        redBroj: "4357",
        nazivPu: "Trogir",
        naselje: "Plano",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4358",
        brojPu: "22222",
        redBroj: "4358",
        nazivPu: "Skradin",
        naselje: "Plastovo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4359",
        brojPu: "47300",
        redBroj: "4359",
        nazivPu: "Ogulin",
        naselje: "Plaški",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4360",
        brojPu: "20207",
        redBroj: "4360",
        nazivPu: "Mlini",
        naselje: "Plat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4361",
        brojPu: "10454",
        redBroj: "4361",
        nazivPu: "Krašić",
        naselje: "Plavci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4362",
        brojPu: "49290",
        redBroj: "4362",
        nazivPu: "Klanjec",
        naselje: "Plavić",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4363",
        brojPu: "22300",
        redBroj: "4363",
        nazivPu: "Knin",
        naselje: "Plavno",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4364",
        brojPu: "48350",
        redBroj: "4364",
        nazivPu: "Đurđevac",
        naselje: "Plavšinac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4365",
        brojPu: "51326",
        redBroj: "4365",
        nazivPu: "Vrbovsko",
        naselje: "Plemenitaš",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4366",
        brojPu: "42240",
        redBroj: "4366",
        nazivPu: "Ivanec",
        naselje: "Plemenšćina",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4367",
        brojPu: "44330",
        redBroj: "4367",
        nazivPu: "Novska",
        naselje: "Plesmo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4368",
        brojPu: "42250",
        redBroj: "4368",
        nazivPu: "Lepoglava",
        naselje: "Pleš",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4369",
        brojPu: "51306",
        redBroj: "4369",
        nazivPu: "Čabar",
        naselje: "Plešce",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4370",
        brojPu: "10450",
        redBroj: "4370",
        nazivPu: "Jastrebarsko",
        naselje: "Plešivica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4371",
        brojPu: "40313",
        redBroj: "4371",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Plešivica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4372",
        brojPu: "51326",
        redBroj: "4372",
        nazivPu: "Vrbovsko",
        naselje: "Plešivica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4373",
        brojPu: "40000",
        redBroj: "4373",
        nazivPu: "Čakovec",
        naselje: "Pleškovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4374",
        brojPu: "34310",
        redBroj: "4374",
        nazivPu: "Pleternica",
        naselje: "Pleternica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4375",
        brojPu: "34310",
        redBroj: "4375",
        nazivPu: "Pleternica",
        naselje: "Pleternički Mihaljevci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4376",
        brojPu: "20340",
        redBroj: "4376",
        nazivPu: "Ploče",
        naselje: "Plina Jezero",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4377",
        brojPu: "21480",
        redBroj: "4377",
        nazivPu: "Vis",
        naselje: "Plisko Polje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4378",
        brojPu: "53230",
        redBroj: "4378",
        nazivPu: "Korenica",
        naselje: "Plitvica Selo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4379",
        brojPu: "42240",
        redBroj: "4379",
        nazivPu: "Ivanec",
        naselje: "Plitvica Voćanska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4380",
        brojPu: "53230",
        redBroj: "4380",
        nazivPu: "Korenica",
        naselje: "Plitvička Jezera",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4381",
        brojPu: "53230",
        redBroj: "4381",
        nazivPu: "Korenica",
        naselje: "Plitvički Ljeskovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4382",
        brojPu: "20340",
        redBroj: "4382",
        nazivPu: "Ploče",
        naselje: "Ploče",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4383",
        brojPu: "20215",
        redBroj: "4383",
        nazivPu: "Gruda",
        naselje: "Pločice",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4384",
        brojPu: "52220",
        redBroj: "4384",
        nazivPu: "Labin",
        naselje: "Plomin",
        zupanija: "ISTARSKA",
      },
      {
        id: "4385",
        brojPu: "52220",
        redBroj: "4385",
        nazivPu: "Labin",
        naselje: "Plomin Luka",
        zupanija: "ISTARSKA",
      },
      {
        id: "4386",
        brojPu: "51224",
        redBroj: "4386",
        nazivPu: "Krasica",
        naselje: "Plosna",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4387",
        brojPu: "34550",
        redBroj: "4387",
        nazivPu: "Pakrac",
        naselje: "Ploštine",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4388",
        brojPu: "52460",
        redBroj: "4388",
        nazivPu: "Buje (Buie)",
        naselje: "Plovanija - Plovania",
        zupanija: "ISTARSKA",
      },
      {
        id: "4389",
        brojPu: "10297",
        redBroj: "4389",
        nazivPu: "Jakovlje",
        naselje: "Pluska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4390",
        brojPu: "43240",
        redBroj: "4390",
        nazivPu: "Čazma",
        naselje: "Pobjenik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4391",
        brojPu: "43240",
        redBroj: "4391",
        nazivPu: "Čazma",
        naselje: "Pobrđani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4392",
        brojPu: "44210",
        redBroj: "4392",
        nazivPu: "Sunja",
        naselje: "Pobrđani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4393",
        brojPu: "48260",
        redBroj: "4393",
        nazivPu: "Križevci",
        naselje: "Pobrđani Vojakovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4394",
        brojPu: "20236",
        redBroj: "4394",
        nazivPu: "Mokošica",
        naselje: "Pobrežje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4395",
        brojPu: "51211",
        redBroj: "4395",
        nazivPu: "Matulji",
        naselje: "Pobri",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4396",
        brojPu: "52420",
        redBroj: "4396",
        nazivPu: "Buzet",
        naselje: "Počekaji",
        zupanija: "ISTARSKA",
      },
      {
        id: "4397",
        brojPu: "53000",
        redBroj: "4397",
        nazivPu: "Gospić",
        naselje: "Počitelj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4398",
        brojPu: "21330",
        redBroj: "4398",
        nazivPu: "Gradac",
        naselje: "Podaca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4399",
        brojPu: "53000",
        redBroj: "4399",
        nazivPu: "Gospić",
        naselje: "Podastrana",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4400",
        brojPu: "21250",
        redBroj: "4400",
        nazivPu: "Šestanovac",
        naselje: "Podašpilje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4401",
        brojPu: "21260",
        redBroj: "4401",
        nazivPu: "Imotski",
        naselje: "Podbablje Gornje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4402",
        brojPu: "53270",
        redBroj: "4402",
        nazivPu: "Senj",
        naselje: "Podbilo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4403",
        brojPu: "44317",
        redBroj: "4403",
        nazivPu: "Popovača",
        naselje: "Podbrđe",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4404",
        brojPu: "40323",
        redBroj: "4404",
        nazivPu: "Prelog",
        naselje: "Podbrest",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4405",
        brojPu: "49225",
        redBroj: "4405",
        nazivPu: "Đurmanec",
        naselje: "Podbrezovica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4406",
        brojPu: "47280",
        redBroj: "4406",
        nazivPu: "Ozalj",
        naselje: "Podbrežje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4407",
        brojPu: "47240",
        redBroj: "4407",
        nazivPu: "Slunj",
        naselje: "Podcetin",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4408",
        brojPu: "35000",
        redBroj: "4408",
        nazivPu: "Slavonski Brod",
        naselje: "Podcrkavlje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4409",
        brojPu: "51218",
        redBroj: "4409",
        nazivPu: "Dražice",
        naselje: "Podčudnič",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4410",
        brojPu: "42220",
        redBroj: "4410",
        nazivPu: "Novi Marof",
        naselje: "Podevčevo",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4411",
        brojPu: "52420",
        redBroj: "4411",
        nazivPu: "Buzet",
        naselje: "Podgaće",
        zupanija: "ISTARSKA",
      },
      {
        id: "4412",
        brojPu: "49000",
        redBroj: "4412",
        nazivPu: "Krapina",
        naselje: "Podgaj Petrovski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4413",
        brojPu: "31540",
        redBroj: "4413",
        nazivPu: "Donji Miholjac",
        naselje: "Podgajci Podravski",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4414",
        brojPu: "48260",
        redBroj: "4414",
        nazivPu: "Križevci",
        naselje: "Podgajec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4415",
        brojPu: "43280",
        redBroj: "4415",
        nazivPu: "Garešnica",
        naselje: "Podgarić",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4416",
        brojPu: "22202",
        redBroj: "4416",
        nazivPu: "Primošten",
        naselje: "Podglavica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4417",
        brojPu: "20232",
        redBroj: "4417",
        nazivPu: "Slano",
        naselje: "Podgora",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4418",
        brojPu: "21327",
        redBroj: "4418",
        nazivPu: "Podgora",
        naselje: "Podgora",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4419",
        brojPu: "49290",
        redBroj: "4419",
        nazivPu: "Klanjec",
        naselje: "Podgora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4420",
        brojPu: "49000",
        redBroj: "4420",
        nazivPu: "Krapina",
        naselje: "Podgora Krapinska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4421",
        brojPu: "51300",
        redBroj: "4421",
        nazivPu: "Delnice",
        naselje: "Podgora Turkovska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4422",
        brojPu: "31500",
        redBroj: "4422",
        nazivPu: "Našice",
        naselje: "Podgorač",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4423",
        brojPu: "51326",
        redBroj: "4423",
        nazivPu: "Vrbovsko",
        naselje: "Podgorani",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4424",
        brojPu: "43000",
        redBroj: "4424",
        nazivPu: "Bjelovar",
        naselje: "Podgorci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4425",
        brojPu: "20250",
        redBroj: "4425",
        nazivPu: "Orebić",
        naselje: "Podgorje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4426",
        brojPu: "33000",
        redBroj: "4426",
        nazivPu: "Virovitica",
        naselje: "Podgorje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4427",
        brojPu: "34340",
        redBroj: "4427",
        nazivPu: "Kutjevo",
        naselje: "Podgorje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4428",
        brojPu: "44410",
        redBroj: "4428",
        nazivPu: "Vrginmost",
        naselje: "Podgorje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4429",
        brojPu: "42250",
        redBroj: "4429",
        nazivPu: "Lepoglava",
        naselje: "Podgorje Bednjansko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4430",
        brojPu: "49246",
        redBroj: "4430",
        nazivPu: "Marija Bistrica",
        naselje: "Podgorje Bistričko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4431",
        brojPu: "31500",
        redBroj: "4431",
        nazivPu: "Našice",
        naselje: "Podgorje Bračevačko",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4432",
        brojPu: "10451",
        redBroj: "4432",
        nazivPu: "Pisarovina",
        naselje: "Podgorje Jamničko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4433",
        brojPu: "47220",
        redBroj: "4433",
        nazivPu: "Vojnić",
        naselje: "Podgorje Krnjačko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4434",
        brojPu: "20355",
        redBroj: "4434",
        nazivPu: "Opuzen",
        naselje: "Podgradina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4435",
        brojPu: "23242",
        redBroj: "4435",
        nazivPu: "Posedarje",
        naselje: "Podgradina",
        zupanija: "ZADARSKA",
      },
      {
        id: "4436",
        brojPu: "21250",
        redBroj: "4436",
        nazivPu: "Šestanovac",
        naselje: "Podgrađe",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4437",
        brojPu: "23420",
        redBroj: "4437",
        nazivPu: "Benkovac",
        naselje: "Podgrađe",
        zupanija: "ZADARSKA",
      },
      {
        id: "4438",
        brojPu: "32252",
        redBroj: "4438",
        nazivPu: "Otok",
        naselje: "Podgrađe",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4439",
        brojPu: "49246",
        redBroj: "4439",
        nazivPu: "Marija Bistrica",
        naselje: "Podgrađe",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4440",
        brojPu: "10435",
        redBroj: "4440",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Podgrađe Podokićko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4441",
        brojPu: "47280",
        redBroj: "4441",
        nazivPu: "Ozalj",
        naselje: "Podgraj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4442",
        brojPu: "51218",
        redBroj: "4442",
        nazivPu: "Dražice",
        naselje: "Podhum",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4443",
        brojPu: "21480",
        redBroj: "4443",
        nazivPu: "Vis",
        naselje: "Podhumlje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4444",
        brojPu: "21238",
        redBroj: "4444",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Podi",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4445",
        brojPu: "20230",
        redBroj: "4445",
        nazivPu: "Ston",
        naselje: "Podimoć",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4446",
        brojPu: "22000",
        redBroj: "4446",
        nazivPu: "Šibenik",
        naselje: "Podine",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4447",
        brojPu: "10340",
        redBroj: "4447",
        nazivPu: "Vrbovec",
        naselje: "Podjales",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4448",
        brojPu: "51218",
        redBroj: "4448",
        nazivPu: "Dražice",
        naselje: "Podkilavac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4449",
        brojPu: "52420",
        redBroj: "4449",
        nazivPu: "Buzet",
        naselje: "Podkuk",
        zupanija: "ISTARSKA",
      },
      {
        id: "4450",
        brojPu: "53234",
        redBroj: "4450",
        nazivPu: "Udbina",
        naselje: "Podlapača",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4451",
        brojPu: "23420",
        redBroj: "4451",
        nazivPu: "Benkovac",
        naselje: "Podlug",
        zupanija: "ZADARSKA",
      },
      {
        id: "4452",
        brojPu: "10342",
        redBroj: "4452",
        nazivPu: "Dubrava",
        naselje: "Podlužan",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4453",
        brojPu: "47240",
        redBroj: "4453",
        nazivPu: "Slunj",
        naselje: "Podmelnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4454",
        brojPu: "51250",
        redBroj: "4454",
        nazivPu: "Novi Vinodolski",
        naselje: "Podmelnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4455",
        brojPu: "20250",
        redBroj: "4455",
        nazivPu: "Orebić",
        naselje: "Podobuče",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4456",
        brojPu: "10340",
        redBroj: "4456",
        nazivPu: "Vrbovec",
        naselje: "Podolec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4457",
        brojPu: "31300",
        redBroj: "4457",
        nazivPu: "Beli Manastir",
        naselje: "Podolje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4458",
        brojPu: "22202",
        redBroj: "4458",
        nazivPu: "Primošten",
        naselje: "Podorljak",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4459",
        brojPu: "21236",
        redBroj: "4459",
        nazivPu: "Vrlika",
        naselje: "Podosoje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4460",
        brojPu: "21260",
        redBroj: "4460",
        nazivPu: "Imotski",
        naselje: "Podosoje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4461",
        brojPu: "53000",
        redBroj: "4461",
        nazivPu: "Gospić",
        naselje: "Podoštra",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4462",
        brojPu: "21276",
        redBroj: "4462",
        nazivPu: "Vrgorac",
        naselje: "Podprolog",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4463",
        brojPu: "31000",
        redBroj: "4463",
        nazivPu: "Osijek",
        naselje: "Podravlje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4464",
        brojPu: "31540",
        redBroj: "4464",
        nazivPu: "Donji Miholjac",
        naselje: "Podravska Moslavina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4465",
        brojPu: "48350",
        redBroj: "4465",
        nazivPu: "Đurđevac",
        naselje: "Podravske Sesvete",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4466",
        brojPu: "47250",
        redBroj: "4466",
        nazivPu: "Duga Resa",
        naselje: "Podrebar",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4467",
        brojPu: "52420",
        redBroj: "4467",
        nazivPu: "Buzet",
        naselje: "Podrebar",
        zupanija: "ISTARSKA",
      },
      {
        id: "4468",
        brojPu: "32100",
        redBroj: "4468",
        nazivPu: "Vinkovci",
        naselje: "Podrinje",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4469",
        brojPu: "20350",
        redBroj: "4469",
        nazivPu: "Metković",
        naselje: "Podrujnica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4470",
        brojPu: "42220",
        redBroj: "4470",
        nazivPu: "Novi Marof",
        naselje: "Podrute",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4471",
        brojPu: "51218",
        redBroj: "4471",
        nazivPu: "Dražice",
        naselje: "Podrvanj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4472",
        brojPu: "47220",
        redBroj: "4472",
        nazivPu: "Vojnić",
        naselje: "Podsedlo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4473",
        brojPu: "21480",
        redBroj: "4473",
        nazivPu: "Vis",
        naselje: "Podselje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4474",
        brojPu: "51300",
        redBroj: "4474",
        nazivPu: "Delnice",
        naselje: "Podslemeni Lazi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4475",
        brojPu: "34000",
        redBroj: "4475",
        nazivPu: "Požega",
        naselje: "Podsreće",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4476",
        brojPu: "51300",
        redBroj: "4476",
        nazivPu: "Delnice",
        naselje: "Podstena",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4477",
        brojPu: "51306",
        redBroj: "4477",
        nazivPu: "Čabar",
        naselje: "Podstene",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4478",
        brojPu: "51326",
        redBroj: "4478",
        nazivPu: "Vrbovsko",
        naselje: "Podstene",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4479",
        brojPu: "21311",
        redBroj: "4479",
        nazivPu: "Stobreč",
        naselje: "Podstrana",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4480",
        brojPu: "21480",
        redBroj: "4480",
        nazivPu: "Vis",
        naselje: "Podstražje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4481",
        brojPu: "21480",
        redBroj: "4481",
        nazivPu: "Vis",
        naselje: "Podšpilje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4482",
        brojPu: "40000",
        redBroj: "4482",
        nazivPu: "Čakovec",
        naselje: "Podturen",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4483",
        brojPu: "53220",
        redBroj: "4483",
        nazivPu: "Otočac",
        naselje: "Podum",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4484",
        brojPu: "22323",
        redBroj: "4484",
        nazivPu: "Unešić",
        naselje: "Podumci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4485",
        brojPu: "47250",
        redBroj: "4485",
        nazivPu: "Duga Resa",
        naselje: "Podumol",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4486",
        brojPu: "31326",
        redBroj: "4486",
        nazivPu: "Darda",
        naselje: "Podunavlje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4487",
        brojPu: "35000",
        redBroj: "4487",
        nazivPu: "Slavonski Brod",
        naselje: "Podvinje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4488",
        brojPu: "48260",
        redBroj: "4488",
        nazivPu: "Križevci",
        naselje: "Podvinje Miholečko",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4489",
        brojPu: "42220",
        redBroj: "4489",
        nazivPu: "Novi Marof",
        naselje: "Podvorec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4490",
        brojPu: "10413",
        redBroj: "4490",
        nazivPu: "Kravarsko",
        naselje: "Podvornica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4491",
        brojPu: "47250",
        redBroj: "4491",
        nazivPu: "Duga Resa",
        naselje: "Podvožić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4492",
        brojPu: "10430",
        redBroj: "4492",
        nazivPu: "Samobor",
        naselje: "Podvrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4493",
        brojPu: "35400",
        redBroj: "4493",
        nazivPu: "Nova Gradiška",
        naselje: "Podvrško",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4494",
        brojPu: "51326",
        redBroj: "4494",
        nazivPu: "Vrbovsko",
        naselje: "Podvučnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4495",
        brojPu: "48260",
        redBroj: "4495",
        nazivPu: "Križevci",
        naselje: "Pofuki",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4496",
        brojPu: "10340",
        redBroj: "4496",
        nazivPu: "Vrbovec",
        naselje: "Pogančec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4497",
        brojPu: "31500",
        redBroj: "4497",
        nazivPu: "Našice",
        naselje: "Poganovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4498",
        brojPu: "10290",
        redBroj: "4498",
        nazivPu: "Zaprešić",
        naselje: "Pojatno",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4499",
        brojPu: "10340",
        redBroj: "4499",
        nazivPu: "Vrbovec",
        naselje: "Pokasin",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4500",
        brojPu: "10430",
        redBroj: "4500",
        nazivPu: "Samobor",
        naselje: "Poklek",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4501",
        brojPu: "49284",
        redBroj: "4501",
        nazivPu: "Budinščina",
        naselje: "Pokojec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4502",
        brojPu: "22323",
        redBroj: "4502",
        nazivPu: "Unešić",
        naselje: "Pokrovnik",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4503",
        brojPu: "10414",
        redBroj: "4503",
        nazivPu: "Pokupsko ",
        naselje: "Pokupsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4504",
        brojPu: "44000",
        redBroj: "4504",
        nazivPu: "Sisak",
        naselje: "Pokupsko Vratečko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4505",
        brojPu: "22300",
        redBroj: "4505",
        nazivPu: "Knin",
        naselje: "Polača",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4506",
        brojPu: "23210",
        redBroj: "4506",
        nazivPu: "Biograd na moru",
        naselje: "Polača",
        zupanija: "ZADARSKA",
      },
      {
        id: "4507",
        brojPu: "20225",
        redBroj: "4507",
        nazivPu: "Babino Polje",
        naselje: "Polače",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4508",
        brojPu: "49290",
        redBroj: "4508",
        nazivPu: "Klanjec",
        naselje: "Police",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4509",
        brojPu: "47280",
        redBroj: "4509",
        nazivPu: "Ozalj",
        naselje: "Police Pirišće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4510",
        brojPu: "23241",
        redBroj: "4510",
        nazivPu: "Poličnik",
        naselje: "Poličnik",
        zupanija: "ZADARSKA",
      },
      {
        id: "4511",
        brojPu: "10292",
        redBroj: "4511",
        nazivPu: "Šenkovec",
        naselje: "Pologi",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4512",
        brojPu: "34310",
        redBroj: "4512",
        nazivPu: "Pleternica",
        naselje: "Poloje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4513",
        brojPu: "47240",
        redBroj: "4513",
        nazivPu: "Slunj",
        naselje: "Polojski Varoš",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4514",
        brojPu: "10380",
        redBroj: "4514",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Polonje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4515",
        brojPu: "10380",
        redBroj: "4515",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Polonje Tomaševečko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4516",
        brojPu: "31500",
        redBroj: "4516",
        nazivPu: "Našice",
        naselje: "Polubaše",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4517",
        brojPu: "43270",
        redBroj: "4517",
        nazivPu: "Veliki Grđevac",
        naselje: "Polum",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4518",
        brojPu: "10340",
        redBroj: "4518",
        nazivPu: "Vrbovec",
        naselje: "Poljana",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4519",
        brojPu: "23273",
        redBroj: "4519",
        nazivPu: "Preko",
        naselje: "Poljana",
        zupanija: "ZADARSKA",
      },
      {
        id: "4520",
        brojPu: "34551",
        redBroj: "4520",
        nazivPu: "Lipik",
        naselje: "Poljana",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4521",
        brojPu: "51326",
        redBroj: "4521",
        nazivPu: "Vrbovsko",
        naselje: "Poljana",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4522",
        brojPu: "42000",
        redBroj: "4522",
        nazivPu: "Varaždin",
        naselje: "Poljana Biškupečka",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4523",
        brojPu: "10410",
        redBroj: "4523",
        nazivPu: "Velika Gorica",
        naselje: "Poljana Čička",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4524",
        brojPu: "48260",
        redBroj: "4524",
        nazivPu: "Križevci",
        naselje: "Poljana Križevačka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4525",
        brojPu: "44250",
        redBroj: "4525",
        nazivPu: "Petrinja",
        naselje: "Poljana Lekenička",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4526",
        brojPu: "49290",
        redBroj: "4526",
        nazivPu: "Klanjec",
        naselje: "Poljana Sutlanska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4527",
        brojPu: "47220",
        redBroj: "4527",
        nazivPu: "Vojnić",
        naselje: "Poljana Vojnićka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4528",
        brojPu: "53230",
        redBroj: "4528",
        nazivPu: "Korenica",
        naselje: "Poljanak",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4529",
        brojPu: "35212",
        redBroj: "4529",
        nazivPu: "Garčin",
        naselje: "Poljanci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4530",
        brojPu: "43000",
        redBroj: "4530",
        nazivPu: "Bjelovar",
        naselje: "Poljančani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4531",
        brojPu: "35400",
        redBroj: "4531",
        nazivPu: "Nova Gradiška",
        naselje: "Poljane",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4532",
        brojPu: "51410",
        redBroj: "4532",
        nazivPu: "Opatija",
        naselje: "Poljane",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4533",
        brojPu: "42230",
        redBroj: "4533",
        nazivPu: "Ludbreg",
        naselje: "Poljanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4534",
        brojPu: "43290",
        redBroj: "4534",
        nazivPu: "Grubišno Polje",
        naselje: "Poljani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4535",
        brojPu: "10298",
        redBroj: "4535",
        nazivPu: "Bistra",
        naselje: "Poljanica Bistranska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4536",
        brojPu: "49246",
        redBroj: "4536",
        nazivPu: "Marija Bistrica",
        naselje: "Poljanica Bistrička",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4537",
        brojPu: "10450",
        redBroj: "4537",
        nazivPu: "Jastrebarsko",
        naselje: "Poljanica Okićka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4538",
        brojPu: "34000",
        redBroj: "4538",
        nazivPu: "Požega",
        naselje: "Poljanska",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4539",
        brojPu: "10340",
        redBroj: "4539",
        nazivPu: "vrbovec",
        naselje: "Poljanski Lug",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4540",
        brojPu: "47240",
        redBroj: "4540",
        nazivPu: "Slunj",
        naselje: "Polje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4541",
        brojPu: "51511",
        redBroj: "4541",
        nazivPu: "Malinska",
        naselje: "Polje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4542",
        brojPu: "52220",
        redBroj: "4542",
        nazivPu: "Labin",
        naselje: "Polje",
        zupanija: "ISTARSKA",
      },
      {
        id: "4543",
        brojPu: "52333",
        redBroj: "4543",
        nazivPu: "Podpićan",
        naselje: "Polje Čepić",
        zupanija: "ISTARSKA",
      },
      {
        id: "4544",
        brojPu: "49000",
        redBroj: "4544",
        nazivPu: "Krapina",
        naselje: "Polje Krapinsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4545",
        brojPu: "47280",
        redBroj: "4545",
        nazivPu: "Ozalj",
        naselje: "Polje Ozaljsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4546",
        brojPu: "21222",
        redBroj: "4546",
        nazivPu: "Marina",
        naselje: "Poljica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4547",
        brojPu: "21260",
        redBroj: "4547",
        nazivPu: "Imotski",
        naselje: "Poljica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4548",
        brojPu: "21469",
        redBroj: "4548",
        nazivPu: "Sućuraj",
        naselje: "Poljica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4549",
        brojPu: "23235",
        redBroj: "4549",
        nazivPu: "Vrsi",
        naselje: "Poljica",
        zupanija: "ZADARSKA",
      },
      {
        id: "4550",
        brojPu: "51500",
        redBroj: "4550",
        nazivPu: "Krk",
        naselje: "Poljica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4551",
        brojPu: "21276",
        redBroj: "4551",
        nazivPu: "Vrgorac",
        naselje: "Poljica Kozička",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4552",
        brojPu: "23232",
        redBroj: "4552",
        nazivPu: "Nin",
        naselje: "Poljica-Brig",
        zupanija: "ZADARSKA",
      },
      {
        id: "4553",
        brojPu: "20215",
        redBroj: "4553",
        nazivPu: "Gruda",
        naselje: "Poljice",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4554",
        brojPu: "53234",
        redBroj: "4554",
        nazivPu: "Udbina",
        naselje: "Poljice",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4555",
        brojPu: "20225",
        redBroj: "4555",
        nazivPu: "Babino Polje",
        naselje: "Pomena",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4556",
        brojPu: "52100",
        redBroj: "4556",
        nazivPu: "Pula (Pola)",
        naselje: "Pomer",
        zupanija: "ISTARSKA",
      },
      {
        id: "4557",
        brojPu: "49284",
        redBroj: "4557",
        nazivPu: "Budinščina",
        naselje: "Pomperovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4558",
        brojPu: "44400",
        redBroj: "4558",
        nazivPu: "Glina",
        naselje: "Ponikvari",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4559",
        brojPu: "51326",
        redBroj: "4559",
        nazivPu: "Vrbovsko",
        naselje: "Ponikve",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4560",
        brojPu: "51224",
        redBroj: "4560",
        nazivPu: "Krasica",
        naselje: "Ponikve",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4561",
        brojPu: "47240",
        redBroj: "4561",
        nazivPu: "Slunj",
        naselje: "Ponor",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4562",
        brojPu: "53230",
        redBroj: "4562",
        nazivPu: "Korenica",
        naselje: "Ponor Korenički",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4563",
        brojPu: "47220",
        redBroj: "4563",
        nazivPu: "Vojnić",
        naselje: "Ponorac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4564",
        brojPu: "47250",
        redBroj: "4564",
        nazivPu: "Duga Resa",
        naselje: "Ponorac Perjasički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4565",
        brojPu: "53220",
        redBroj: "4565",
        nazivPu: "Otočac",
        naselje: "Ponori",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4566",
        brojPu: "20246",
        redBroj: "4566",
        nazivPu: "Janjina",
        naselje: "Popova Luka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4567",
        brojPu: "31300",
        redBroj: "4567",
        nazivPu: "Beli Manastir",
        naselje: "Popovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4568",
        brojPu: "33520",
        redBroj: "4568",
        nazivPu: "Slatina",
        naselje: "Popovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4569",
        brojPu: "44330",
        redBroj: "4569",
        nazivPu: "Novska",
        naselje: "Popovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4570",
        brojPu: "44317",
        redBroj: "4570",
        nazivPu: "Popovača",
        naselje: "Popovača",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4571",
        brojPu: "53000",
        redBroj: "4571",
        nazivPu: "Gospić",
        naselje: "Popovača Pazariška",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4572",
        brojPu: "34550",
        redBroj: "4572",
        nazivPu: "Pakrac",
        naselje: "Popovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4573",
        brojPu: "10360",
        redBroj: "4573",
        nazivPu: "Sesvete",
        naselje: "Popovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4574",
        brojPu: "48260",
        redBroj: "4574",
        nazivPu: "Križevci",
        naselje: "Popovec Kalnički",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4575",
        brojPu: "47000",
        redBroj: "4575",
        nazivPu: "Karlovac",
        naselje: "Popović Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4576",
        brojPu: "20215",
        redBroj: "4576",
        nazivPu: "Gruda",
        naselje: "Popovići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4577",
        brojPu: "23420",
        redBroj: "4577",
        nazivPu: "Benkovac",
        naselje: "Popovići",
        zupanija: "ZADARSKA",
      },
      {
        id: "4578",
        brojPu: "47280",
        redBroj: "4578",
        nazivPu: "Ozalj",
        naselje: "Popovići Žumberački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4579",
        brojPu: "47300",
        redBroj: "4579",
        nazivPu: "Ogulin",
        naselje: "Popovo Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4580",
        brojPu: "51511",
        redBroj: "4580",
        nazivPu: "Malinska",
        naselje: "Porat",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4581",
        brojPu: "34340",
        redBroj: "4581",
        nazivPu: "Kutjevo",
        naselje: "Poreč",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4582",
        brojPu: "52440",
        redBroj: "4582",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Poreč - Parenzo",
        zupanija: "ISTARSKA",
      },
      {
        id: "4583",
        brojPu: "49231",
        redBroj: "4583",
        nazivPu: "Hum na Sutli",
        naselje: "Poredje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4584",
        brojPu: "51557",
        redBroj: "4584",
        nazivPu: "Cres",
        naselje: "Porozina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4585",
        brojPu: "10310",
        redBroj: "4585",
        nazivPu: "Ivanić Grad",
        naselje: "Posavski Bregi",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4586",
        brojPu: "32257",
        redBroj: "4586",
        nazivPu: "Drenovci",
        naselje: "Posavski Podgajci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4587",
        brojPu: "23242",
        redBroj: "4587",
        nazivPu: "Posedarje",
        naselje: "Posedarje",
        zupanija: "ZADARSKA",
      },
      {
        id: "4588",
        brojPu: "21400",
        redBroj: "4588",
        nazivPu: "Supetar",
        naselje: "Postira",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4589",
        brojPu: "21260",
        redBroj: "4589",
        nazivPu: "Imotski",
        naselje: "Postranje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4590",
        brojPu: "47300",
        redBroj: "4590",
        nazivPu: "Ogulin",
        naselje: "Pothum Plaščanski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4591",
        brojPu: "20270",
        redBroj: "4591",
        nazivPu: "Vela Luka",
        naselje: "Potirna",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4592",
        brojPu: "22300",
        redBroj: "4592",
        nazivPu: "Knin",
        naselje: "Potkonje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4593",
        brojPu: "31400",
        redBroj: "4593",
        nazivPu: "Đakovo",
        naselje: "Potnjani",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4594",
        brojPu: "34000",
        redBroj: "4594",
        nazivPu: "Požega",
        naselje: "Potočani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4595",
        brojPu: "43500",
        redBroj: "4595",
        nazivPu: "Daruvar",
        naselje: "Potočani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4596",
        brojPu: "10340",
        redBroj: "4596",
        nazivPu: "Vrbovec",
        naselje: "Potočec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4597",
        brojPu: "53291",
        redBroj: "4597",
        nazivPu: "Novalja",
        naselje: "Potočnica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4598",
        brojPu: "43000",
        redBroj: "4598",
        nazivPu: "Bjelovar",
        naselje: "Potok",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4599",
        brojPu: "44317",
        redBroj: "4599",
        nazivPu: "Popovača",
        naselje: "Potok",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4600",
        brojPu: "47250",
        redBroj: "4600",
        nazivPu: "Duga Resa",
        naselje: "Potok Bosiljevski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4601",
        brojPu: "48260",
        redBroj: "4601",
        nazivPu: "Križevci",
        naselje: "Potok Kalnički",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4602",
        brojPu: "47300",
        redBroj: "4602",
        nazivPu: "Ogulin",
        naselje: "Potok Musulinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4603",
        brojPu: "47300",
        redBroj: "4603",
        nazivPu: "Ogulin",
        naselje: "Potok Tounjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4604",
        brojPu: "20244",
        redBroj: "4604",
        nazivPu: "Potomje",
        naselje: "Potomje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4605",
        brojPu: "52333",
        redBroj: "4605",
        nazivPu: "Podpićan",
        naselje: "Potpićan",
        zupanija: "ISTARSKA",
      },
      {
        id: "4606",
        brojPu: "47250",
        redBroj: "4606",
        nazivPu: "Duga Resa",
        naselje: "Potplaninsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4607",
        brojPu: "21233",
        redBroj: "4607",
        nazivPu: "Hrvace",
        naselje: "Potravlje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4608",
        brojPu: "48260",
        redBroj: "4608",
        nazivPu: "Križevci",
        naselje: "Povelić",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4609",
        brojPu: "51250",
        redBroj: "4609",
        nazivPu: "Novi Vinodolski",
        naselje: "Povile",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4610",
        brojPu: "21425",
        redBroj: "4610",
        nazivPu: "Selca",
        naselje: "Povlja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4611",
        brojPu: "23250",
        redBroj: "4611",
        nazivPu: "Pag",
        naselje: "Povljana",
        zupanija: "ZADARSKA",
      },
      {
        id: "4612",
        brojPu: "20350",
        redBroj: "4612",
        nazivPu: "Metković",
        naselje: "Pozla Gora",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4613",
        brojPu: "49221",
        redBroj: "4613",
        nazivPu: "Bedekovčina",
        naselje: "Poznanovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4614",
        brojPu: "21222",
        redBroj: "4614",
        nazivPu: "Marina",
        naselje: "Pozorac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4615",
        brojPu: "51300",
        redBroj: "4615",
        nazivPu: "Delnice",
        naselje: "Požar",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4616",
        brojPu: "49210",
        redBroj: "4616",
        nazivPu: "Zabok",
        naselje: "Požarkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4617",
        brojPu: "51306",
        redBroj: "4617",
        nazivPu: "Čabar",
        naselje: "Požarnica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4618",
        brojPu: "34000",
        redBroj: "4618",
        nazivPu: "Požega",
        naselje: "Požega",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4619",
        brojPu: "34310",
        redBroj: "4619",
        nazivPu: "Pleternica",
        naselje: "Požeška Koprivnica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4620",
        brojPu: "34000",
        redBroj: "4620",
        nazivPu: "Požega",
        naselje: "Požeški Brđani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4621",
        brojPu: "47280",
        redBroj: "4621",
        nazivPu: "Ozalj",
        naselje: "Požun",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4622",
        brojPu: "52420",
        redBroj: "4622",
        nazivPu: "Buzet",
        naselje: "Pračana",
        zupanija: "ISTARSKA",
      },
      {
        id: "4623",
        brojPu: "21220",
        redBroj: "4623",
        nazivPu: "Trogir",
        naselje: "Prapatnica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4624",
        brojPu: "21276",
        redBroj: "4624",
        nazivPu: "Vrgorac",
        naselje: "Prapatnice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4625",
        brojPu: "52420",
        redBroj: "4625",
        nazivPu: "Buzet",
        naselje: "Prapoće",
        zupanija: "ISTARSKA",
      },
      {
        id: "4626",
        brojPu: "40313",
        redBroj: "4626",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Praporčan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4627",
        brojPu: "51226",
        redBroj: "4627",
        nazivPu: "Hreljin",
        naselje: "Praputnjak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4628",
        brojPu: "10342",
        redBroj: "4628",
        nazivPu: "Dubrava",
        naselje: "Praščevac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4629",
        brojPu: "52440",
        redBroj: "4629",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Prašćari",
        zupanija: "ISTARSKA",
      },
      {
        id: "4630",
        brojPu: "47000",
        redBroj: "4630",
        nazivPu: "Karlovac",
        naselje: "Pravutina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4631",
        brojPu: "21412",
        redBroj: "4631",
        nazivPu: "Pučišća",
        naselje: "Pražnica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4632",
        brojPu: "42250",
        redBroj: "4632",
        nazivPu: "Lepoglava",
        naselje: "Prebukovje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4633",
        brojPu: "10370",
        redBroj: "4633",
        nazivPu: "Dugo Selo",
        naselje: "Prečec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4634",
        brojPu: "10310",
        redBroj: "4634",
        nazivPu: "Ivanić Grad",
        naselje: "Prečno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4635",
        brojPu: "43000",
        redBroj: "4635",
        nazivPu: "Bjelovar",
        naselje: "Predavac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4636",
        brojPu: "10310",
        redBroj: "4636",
        nazivPu: "Ivanić Grad",
        naselje: "Predavec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4637",
        brojPu: "48214",
        redBroj: "4637",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Predavec Križevački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4638",
        brojPu: "51557",
        redBroj: "4638",
        nazivPu: "Cres",
        naselje: "Predošćica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4639",
        brojPu: "49218",
        redBroj: "4639",
        nazivPu: "Pregrada",
        naselje: "Pregrada",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4640",
        brojPu: "42000",
        redBroj: "4640",
        nazivPu: "Varaždin",
        naselje: "Prekno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4641",
        brojPu: "23273",
        redBroj: "4641",
        nazivPu: "Preko",
        naselje: "Preko",
        zupanija: "ZADARSKA",
      },
      {
        id: "4642",
        brojPu: "43000",
        redBroj: "4642",
        nazivPu: "Bjelovar",
        naselje: "Prekobrdo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4643",
        brojPu: "40313",
        redBroj: "4643",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Prekopa",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4644",
        brojPu: "44400",
        redBroj: "4644",
        nazivPu: "Glina",
        naselje: "Prekopa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4645",
        brojPu: "34550",
        redBroj: "4645",
        nazivPu: "Pakrac",
        naselje: "Prekopakra",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4646",
        brojPu: "33515",
        redBroj: "4646",
        nazivPu: "Orahovica",
        naselje: "Prekoračani",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4647",
        brojPu: "10430",
        redBroj: "4647",
        nazivPu: "Samobor",
        naselje: "Prekrižje Plešivičko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4648",
        brojPu: "10362",
        redBroj: "4648",
        nazivPu: "Kašina",
        naselje: "Prekvršje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4649",
        brojPu: "40323",
        redBroj: "4649",
        nazivPu: "Prelog",
        naselje: "Prelog",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4650",
        brojPu: "44000",
        redBroj: "4650",
        nazivPu: "Sisak",
        naselje: "Prelošćica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4651",
        brojPu: "52100",
        redBroj: "4651",
        nazivPu: "Pula (Pola)",
        naselje: "Premantura",
        zupanija: "ISTARSKA",
      },
      {
        id: "4652",
        brojPu: "23294",
        redBroj: "4652",
        nazivPu: "Premuda",
        naselje: "Premuda",
        zupanija: "ZADARSKA",
      },
      {
        id: "4653",
        brojPu: "10380",
        redBroj: "4653",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Prepolno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4654",
        brojPu: "10362",
        redBroj: "4654",
        nazivPu: "Kašina",
        naselje: "Prepuštovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "4655",
        brojPu: "49284",
        redBroj: "4655",
        nazivPu: "Budinščina",
        naselje: "Prepuštovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4656",
        brojPu: "10310",
        redBroj: "4656",
        nazivPu: "Ivanić Grad",
        naselje: "Prerovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4657",
        brojPu: "42220",
        redBroj: "4657",
        nazivPu: "Novi Marof",
        naselje: "Presečno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4658",
        brojPu: "42220",
        redBroj: "4658",
        nazivPu: "Novi Marof",
        naselje: "Presečno Visočko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4659",
        brojPu: "10340",
        redBroj: "4659",
        nazivPu: "Vrbovec",
        naselje: "Preseka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4660",
        brojPu: "40000",
        redBroj: "4660",
        nazivPu: "Čakovec",
        naselje: "Preseka",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4661",
        brojPu: "10370",
        redBroj: "4661",
        nazivPu: "Dugo Selo",
        naselje: "Preseka Oborovska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4662",
        brojPu: "47280",
        redBroj: "4662",
        nazivPu: "Ozalj",
        naselje: "Preseka Ozaljska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4663",
        brojPu: "49000",
        redBroj: "4663",
        nazivPu: "Krapina",
        naselje: "Preseka Petrovska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4664",
        brojPu: "51326",
        redBroj: "4664",
        nazivPu: "Vrbovsko",
        naselje: "Presika",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4665",
        brojPu: "52220",
        redBroj: "4665",
        nazivPu: "Labin",
        naselje: "Presika",
        zupanija: "ISTARSKA",
      },
      {
        id: "4666",
        brojPu: "31400",
        redBroj: "4666",
        nazivPu: "Đakovo",
        naselje: "Preslatinci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4667",
        brojPu: "43000",
        redBroj: "4667",
        nazivPu: "Bjelovar",
        naselje: "Prespa",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4668",
        brojPu: "40000",
        redBroj: "4668",
        nazivPu: "Čakovec",
        naselje: "Pretetinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4669",
        brojPu: "49000",
        redBroj: "4669",
        nazivPu: "Krapina",
        naselje: "Pretkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4670",
        brojPu: "10380",
        redBroj: "4670",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Pretoki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4671",
        brojPu: "52000",
        redBroj: "4671",
        nazivPu: "Pazin",
        naselje: "Previž",
        zupanija: "ISTARSKA",
      },
      {
        id: "4672",
        brojPu: "10370",
        redBroj: "4672",
        nazivPu: "Dugo Selo",
        naselje: "Prevlaka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4673",
        brojPu: "44430",
        redBroj: "4673",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Prevršac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4674",
        brojPu: "51306",
        redBroj: "4674",
        nazivPu: "Čabar",
        naselje: "Prezid",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4675",
        brojPu: "34550",
        redBroj: "4675",
        nazivPu: "Pakrac",
        naselje: "Prgomelje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4676",
        brojPu: "43000",
        redBroj: "4676",
        nazivPu: "Bjelovar",
        naselje: "Prgomelje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4677",
        brojPu: "21220",
        redBroj: "4677",
        nazivPu: "Trogir",
        naselje: "Prgomet",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4678",
        brojPu: "52207",
        redBroj: "4678",
        nazivPu: "Barban",
        naselje: "Prhati",
        zupanija: "ISTARSKA",
      },
      {
        id: "4679",
        brojPu: "52440",
        redBroj: "4679",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Prhati",
        zupanija: "ISTARSKA",
      },
      {
        id: "4680",
        brojPu: "51306",
        redBroj: "4680",
        nazivPu: "Čabar",
        naselje: "Prhci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4681",
        brojPu: "10450",
        redBroj: "4681",
        nazivPu: "Jastrebarsko",
        naselje: "Prhoć",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4682",
        brojPu: "40313",
        redBroj: "4682",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Prhovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4683",
        brojPu: "51306",
        redBroj: "4683",
        nazivPu: "Čabar",
        naselje: "Prhutova Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4684",
        brojPu: "47250",
        redBroj: "4684",
        nazivPu: "Duga Resa",
        naselje: "Pribanjci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4685",
        brojPu: "10454",
        redBroj: "4685",
        nazivPu: "Krašić",
        naselje: "Pribić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4686",
        brojPu: "10454",
        redBroj: "4686",
        nazivPu: "Krašić",
        naselje: "Pribić Crkveni",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4687",
        brojPu: "40000",
        redBroj: "4687",
        nazivPu: "Čakovec",
        naselje: "Pribislavec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4688",
        brojPu: "31511",
        redBroj: "4688",
        nazivPu: "Đurđenovac",
        naselje: "Pribiševci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4689",
        brojPu: "21203",
        redBroj: "4689",
        nazivPu: "Donji Muć",
        naselje: "Pribude",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4690",
        brojPu: "23440",
        redBroj: "4690",
        nazivPu: "Gračac",
        naselje: "Pribudić",
        zupanija: "ZADARSKA",
      },
      {
        id: "4691",
        brojPu: "35250",
        redBroj: "4691",
        nazivPu: "Oriovac",
        naselje: "Pričac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4692",
        brojPu: "23312",
        redBroj: "4692",
        nazivPu: "Novigrad (Dalmacija)",
        naselje: "Pridraga",
        zupanija: "ZADARSKA",
      },
      {
        id: "4693",
        brojPu: "20215",
        redBroj: "4693",
        nazivPu: "Gruda",
        naselje: "Pridvorje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4694",
        brojPu: "31400",
        redBroj: "4694",
        nazivPu: "Đakovo",
        naselje: "Pridvorje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4695",
        brojPu: "42240",
        redBroj: "4695",
        nazivPu: "Ivanec",
        naselje: "Prigorec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4696",
        brojPu: "49225",
        redBroj: "4696",
        nazivPu: "Đurmanec",
        naselje: "Prigorje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4697",
        brojPu: "10291",
        redBroj: "4697",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Prigorje Brdovečko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4698",
        brojPu: "53230",
        redBroj: "4698",
        nazivPu: "Korenica",
        naselje: "Prijeboj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4699",
        brojPu: "44400",
        redBroj: "4699",
        nazivPu: "Glina",
        naselje: "Prijeka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4700",
        brojPu: "20236",
        redBroj: "4700",
        nazivPu: "Mokošica",
        naselje: "Prijevor",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4701",
        brojPu: "10370",
        redBroj: "4701",
        nazivPu: "Dugo Selo",
        naselje: "Prikraj",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4702",
        brojPu: "48260",
        redBroj: "4702",
        nazivPu: "Križevci",
        naselje: "Prikraj Križevački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4703",
        brojPu: "42230",
        redBroj: "4703",
        nazivPu: "Ludbreg",
        naselje: "Priles",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4704",
        brojPu: "10340",
        redBroj: "4704",
        nazivPu: "Vrbovec",
        naselje: "Prilesje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4705",
        brojPu: "10450",
        redBroj: "4705",
        nazivPu: "Jastrebarsko",
        naselje: "Prilipje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4706",
        brojPu: "21220",
        redBroj: "4706",
        nazivPu: "Trogir",
        naselje: "Primorski Dolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4707",
        brojPu: "22202",
        redBroj: "4707",
        nazivPu: "Primošten",
        naselje: "Primošten",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4708",
        brojPu: "22202",
        redBroj: "4708",
        nazivPu: "Primošten",
        naselje: "Primošten Burnji",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4709",
        brojPu: "47000",
        redBroj: "4709",
        nazivPu: "Karlovac",
        naselje: "Priselci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4710",
        brojPu: "47220",
        redBroj: "4710",
        nazivPu: "Vojnić",
        naselje: "Prisjeka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4711",
        brojPu: "21232",
        redBroj: "4711",
        nazivPu: "Dicmo",
        naselje: "Prisoje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4712",
        brojPu: "49290",
        redBroj: "4712",
        nazivPu: "Klanjec",
        naselje: "Pristava",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4713",
        brojPu: "49000",
        redBroj: "4713",
        nazivPu: "Krapina",
        naselje: "Pristava Krapinska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4714",
        brojPu: "23422",
        redBroj: "4714",
        nazivPu: "Stankovci",
        naselje: "Pristeg",
        zupanija: "ZADARSKA",
      },
      {
        id: "4715",
        brojPu: "49231",
        redBroj: "4715",
        nazivPu: "Hum na Sutli",
        naselje: "Prišlin",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4716",
        brojPu: "23233",
        redBroj: "4716",
        nazivPu: "Privlaka",
        naselje: "Privlaka",
        zupanija: "ZADARSKA",
      },
      {
        id: "4717",
        brojPu: "32252",
        redBroj: "4717",
        nazivPu: "Otok",
        naselje: "Privlaka",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4718",
        brojPu: "53270",
        redBroj: "4718",
        nazivPu: "Senj",
        naselje: "Prizna",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4719",
        brojPu: "52341",
        redBroj: "4719",
        nazivPu: "Žminj",
        naselje: "Prkačini",
        zupanija: "ISTARSKA",
      },
      {
        id: "4720",
        brojPu: "23223",
        redBroj: "4720",
        nazivPu: "Škabrnja",
        naselje: "Prkos",
        zupanija: "ZADARSKA",
      },
      {
        id: "4721",
        brojPu: "48000",
        redBroj: "4721",
        nazivPu: "Koprivnica",
        naselje: "Prkos",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4722",
        brojPu: "47000",
        redBroj: "4722",
        nazivPu: "Karlovac",
        naselje: "Prkos Lasinjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4723",
        brojPu: "32100",
        redBroj: "4723",
        nazivPu: "Vinkovci",
        naselje: "Prkovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4724",
        brojPu: "52440",
        redBroj: "4724",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Prkovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4725",
        brojPu: "23440",
        redBroj: "4725",
        nazivPu: "Gračac",
        naselje: "Prljevo",
        zupanija: "ZADARSKA",
      },
      {
        id: "4726",
        brojPu: "43240",
        redBroj: "4726",
        nazivPu: "Čazma",
        naselje: "Prnjarovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4727",
        brojPu: "35214",
        redBroj: "4727",
        nazivPu: "Donji Andrijevci",
        naselje: "Prnjavor",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4728",
        brojPu: "43000",
        redBroj: "4728",
        nazivPu: "Bjelovar",
        naselje: "Prnjavor",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4729",
        brojPu: "44250",
        redBroj: "4729",
        nazivPu: "Petrinja",
        naselje: "Prnjavor Čuntićki",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4730",
        brojPu: "48000",
        redBroj: "4730",
        nazivPu: "Koprivnica",
        naselje: "Prnjavor Lepavinski",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4731",
        brojPu: "52420",
        redBroj: "4731",
        nazivPu: "Buzet",
        naselje: "Prodani",
        zupanija: "ISTARSKA",
      },
      {
        id: "4732",
        brojPu: "10450",
        redBroj: "4732",
        nazivPu: "Jastrebarsko",
        naselje: "Prodin Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4733",
        brojPu: "52207",
        redBroj: "4733",
        nazivPu: "Barban",
        naselje: "Prodol",
        zupanija: "ISTARSKA",
      },
      {
        id: "4734",
        brojPu: "53260",
        redBroj: "4734",
        nazivPu: "Brinje",
        naselje: "Prokike",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4735",
        brojPu: "43000",
        redBroj: "4735",
        nazivPu: "Bjelovar",
        naselje: "Prokljuvani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4736",
        brojPu: "43240",
        redBroj: "4736",
        nazivPu: "Čazma",
        naselje: "Prokljuvani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4737",
        brojPu: "21320",
        redBroj: "4737",
        nazivPu: "Baška Voda",
        naselje: "Promajna",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4738",
        brojPu: "49290",
        redBroj: "4738",
        nazivPu: "Klanjec",
        naselje: "Prosenik",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4739",
        brojPu: "49210",
        redBroj: "4739",
        nazivPu: "Zabok",
        naselje: "Prosenik Gubaševski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4740",
        brojPu: "49210",
        redBroj: "4740",
        nazivPu: "Zabok",
        naselje: "Prosenik Začretski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4741",
        brojPu: "10292",
        redBroj: "4741",
        nazivPu: "Šenkovec",
        naselje: "Prosinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4742",
        brojPu: "47250",
        redBroj: "4742",
        nazivPu: "Duga Resa",
        naselje: "Protulipa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4743",
        brojPu: "23422",
        redBroj: "4743",
        nazivPu: "Stankovci",
        naselje: "Prović",
        zupanija: "ZADARSKA",
      },
      {
        id: "4744",
        brojPu: "53220",
        redBroj: "4744",
        nazivPu: "Otočac",
        naselje: "Prozor",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4745",
        brojPu: "10370",
        redBroj: "4745",
        nazivPu: "Dugo Selo",
        naselje: "Prozorje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4746",
        brojPu: "20225",
        redBroj: "4746",
        nazivPu: "Babino Polje",
        naselje: "Prožura",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4747",
        brojPu: "20225",
        redBroj: "4747",
        nazivPu: "Babino Polje",
        naselje: "Prožurska Luka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4748",
        brojPu: "51306",
        redBroj: "4748",
        nazivPu: "Čabar",
        naselje: "Pršleti",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4749",
        brojPu: "52440",
        redBroj: "4749",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Pršurići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4750",
        brojPu: "20350",
        redBroj: "4750",
        nazivPu: "Metković",
        naselje: "Prud",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4751",
        brojPu: "10291",
        redBroj: "4751",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Prudnice",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4752",
        brojPu: "48350",
        redBroj: "4752",
        nazivPu: "Đurđevac",
        naselje: "Prugovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4753",
        brojPu: "21204",
        redBroj: "4753",
        nazivPu: "Dugopolje",
        naselje: "Prugovo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4754",
        brojPu: "53202",
        redBroj: "4754",
        nazivPu: "Perušić",
        naselje: "Prvan Selo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4755",
        brojPu: "35400",
        redBroj: "4755",
        nazivPu: "Nova Gradiška",
        naselje: "Prvča",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4756",
        brojPu: "22233",
        redBroj: "4756",
        nazivPu: "Prvić Luka",
        naselje: "Prvić Luka",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4757",
        brojPu: "22234",
        redBroj: "4757",
        nazivPu: "Prvić Šepurine",
        naselje: "Prvić Šepurine",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4758",
        brojPu: "10454",
        redBroj: "4758",
        nazivPu: "Krašić",
        naselje: "Prvinci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4759",
        brojPu: "10410",
        redBroj: "4759",
        nazivPu: "Velika Gorica",
        naselje: "Prvonožina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4760",
        brojPu: "20355",
        redBroj: "4760",
        nazivPu: "Opuzen",
        naselje: "Pržinovac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4761",
        brojPu: "51300",
        redBroj: "4761",
        nazivPu: "Delnice",
        naselje: "Pucak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4762",
        brojPu: "52341",
        redBroj: "4762",
        nazivPu: "Žminj",
        naselje: "Pucići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4763",
        brojPu: "21412",
        redBroj: "4763",
        nazivPu: "Pučišća",
        naselje: "Pučišća",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4764",
        brojPu: "10370",
        redBroj: "4764",
        nazivPu: "Dugo Selo",
        naselje: "Puhovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4765",
        brojPu: "43500",
        redBroj: "4765",
        nazivPu: "Daruvar",
        naselje: "Puklica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4766",
        brojPu: "52100",
        redBroj: "4766",
        nazivPu: "Pula (Pola)",
        naselje: "Pula - Pola",
        zupanija: "ISTARSKA",
      },
      {
        id: "4766.1",
        brojPu: "52100",
        redBroj: "4766",
        nazivPu: "Pula (Pola)",
        naselje: "Pula",
        zupanija: "ISTARSKA",
      },
      {
        id: "4767",
        brojPu: "22320",
        redBroj: "4767",
        nazivPu: "Drniš",
        naselje: "Puljane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4768",
        brojPu: "51500",
        redBroj: "4768",
        nazivPu: "Krk",
        naselje: "Punat",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4769",
        brojPu: "42240",
        redBroj: "4769",
        nazivPu: "Ivanec",
        naselje: "Punikve",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4770",
        brojPu: "31400",
        redBroj: "4770",
        nazivPu: "Đakovo",
        naselje: "Punitovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4771",
        brojPu: "51550",
        redBroj: "4771",
        nazivPu: "Mali Lošinj",
        naselje: "Punta Križa",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4772",
        brojPu: "52207",
        redBroj: "4772",
        nazivPu: "Barban",
        naselje: "Puntera",
        zupanija: "ISTARSKA",
      },
      {
        id: "4773",
        brojPu: "43000",
        redBroj: "4773",
        nazivPu: "Bjelovar",
        naselje: "Pupelica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4774",
        brojPu: "20274",
        redBroj: "4774",
        nazivPu: "Pupnat",
        naselje: "Pupnat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4775",
        brojPu: "42250",
        redBroj: "4775",
        nazivPu: "Lepoglava",
        naselje: "Purga Bednjanska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4776",
        brojPu: "52333",
        redBroj: "4776",
        nazivPu: "Podpićan",
        naselje: "Purgarija Čepić",
        zupanija: "ISTARSKA",
      },
      {
        id: "4777",
        brojPu: "43000",
        redBroj: "4777",
        nazivPu: "Bjelovar",
        naselje: "Puričani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4778",
        brojPu: "44330",
        redBroj: "4778",
        nazivPu: "Novska",
        naselje: "Puska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4779",
        brojPu: "48000",
        redBroj: "4779",
        nazivPu: "Koprivnica",
        naselje: "Pustakovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4780",
        brojPu: "52341",
        redBroj: "4780",
        nazivPu: "Žminj",
        naselje: "Pusti",
        zupanija: "ISTARSKA",
      },
      {
        id: "4781",
        brojPu: "10413",
        redBroj: "4781",
        nazivPu: "Kravarsko",
        naselje: "Pustike",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4782",
        brojPu: "49240",
        redBroj: "4782",
        nazivPu: "Donja Stubica",
        naselje: "Pustodol",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4783",
        brojPu: "49221",
        redBroj: "4783",
        nazivPu: "Bedekovčina",
        naselje: "Pustodol Orehovički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4784",
        brojPu: "49223",
        redBroj: "4784",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Pustodol Začretski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4785",
        brojPu: "49290",
        redBroj: "4785",
        nazivPu: "Klanjec",
        naselje: "Pušava",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4786",
        brojPu: "49290",
        redBroj: "4786",
        nazivPu: "Klanjec",
        naselje: "Pušća",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4787",
        brojPu: "40000",
        redBroj: "4787",
        nazivPu: "Čakovec",
        naselje: "Pušćine",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4788",
        brojPu: "33515",
        redBroj: "4788",
        nazivPu: "Orahovica",
        naselje: "Pušina",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4789",
        brojPu: "47300",
        redBroj: "4789",
        nazivPu: "Ogulin",
        naselje: "Puškarići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4790",
        brojPu: "22213",
        redBroj: "4790",
        nazivPu: "Pirovac",
        naselje: "Putičanje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4791",
        brojPu: "52341",
        redBroj: "4791",
        nazivPu: "Žminj",
        naselje: "Putini",
        zupanija: "ISTARSKA",
      },
      {
        id: "4792",
        brojPu: "21204",
        redBroj: "4792",
        nazivPu: "Dugopolje",
        naselje: "Putišići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4793",
        brojPu: "49225",
        redBroj: "4793",
        nazivPu: "Đurmanec",
        naselje: "Putkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4794",
        brojPu: "20246",
        redBroj: "4794",
        nazivPu: "Janjina",
        naselje: "Putniković",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4795",
        brojPu: "51280",
        redBroj: "4795",
        nazivPu: "Rab",
        naselje: "Rab",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4796",
        brojPu: "20355",
        redBroj: "4796",
        nazivPu: "Opuzen",
        naselje: "Raba",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4797",
        brojPu: "52220",
        redBroj: "4797",
        nazivPu: "Labin",
        naselje: "Rabac",
        zupanija: "ISTARSKA",
      },
      {
        id: "4798",
        brojPu: "47240",
        redBroj: "4798",
        nazivPu: "Slunj",
        naselje: "Rabinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4799",
        brojPu: "47000",
        redBroj: "4799",
        nazivPu: "Karlovac",
        naselje: "Račak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4800",
        brojPu: "52420",
        redBroj: "4800",
        nazivPu: "Buzet",
        naselje: "Račice",
        zupanija: "ISTARSKA",
      },
      {
        id: "4801",
        brojPu: "52420",
        redBroj: "4801",
        nazivPu: "Buzet",
        naselje: "Račički Brijeg",
        zupanija: "ISTARSKA",
      },
      {
        id: "4802",
        brojPu: "32257",
        redBroj: "4802",
        nazivPu: "Drenovci",
        naselje: "Račinovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4803",
        brojPu: "20260",
        redBroj: "4803",
        nazivPu: "Korčula",
        naselje: "Račišće",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4804",
        brojPu: "52420",
        redBroj: "4804",
        nazivPu: "Buzet",
        naselje: "Račja Vas",
        zupanija: "ISTARSKA",
      },
      {
        id: "4805",
        brojPu: "49290",
        redBroj: "4805",
        nazivPu: "Klanjec",
        naselje: "Radakovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4806",
        brojPu: "47280",
        redBroj: "4806",
        nazivPu: "Ozalj",
        naselje: "Radatovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4807",
        brojPu: "48000",
        redBroj: "4807",
        nazivPu: "Koprivnica",
        naselje: "Radeljevo Selo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4808",
        brojPu: "42220",
        redBroj: "4808",
        nazivPu: "Novi Marof",
        naselje: "Radešić",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4809",
        brojPu: "52000",
        redBroj: "4809",
        nazivPu: "Pazin",
        naselje: "Radetići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4810",
        brojPu: "51511",
        redBroj: "4810",
        nazivPu: "Malinska",
        naselje: "Radići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4811",
        brojPu: "52440",
        redBroj: "4811",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Radići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4812",
        brojPu: "51326",
        redBroj: "4812",
        nazivPu: "Vrbovsko",
        naselje: "Radigojna",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4813",
        brojPu: "31540",
        redBroj: "4813",
        nazivPu: "Donji Miholjac",
        naselje: "Radikovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4814",
        brojPu: "10454",
        redBroj: "4814",
        nazivPu: "Krašić",
        naselje: "Radina Gorica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4815",
        brojPu: "47280",
        redBroj: "4815",
        nazivPu: "Ozalj",
        naselje: "Radina Vas",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4816",
        brojPu: "52470",
        redBroj: "4816",
        nazivPu: "Umag (Umago)",
        naselje: "Radini - Radini",
        zupanija: "ISTARSKA",
      },
      {
        id: "4817",
        brojPu: "10454",
        redBroj: "4817",
        nazivPu: "Krašić",
        naselje: "Radinovo Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4818",
        brojPu: "22300",
        redBroj: "4818",
        nazivPu: "Knin",
        naselje: "Radljevac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4819",
        brojPu: "52440",
        redBroj: "4819",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Radmani",
        zupanija: "ISTARSKA",
      },
      {
        id: "4820",
        brojPu: "47220",
        redBroj: "4820",
        nazivPu: "Vojnić",
        naselje: "Radmanovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4821",
        brojPu: "34000",
        redBroj: "4821",
        nazivPu: "Požega",
        naselje: "Radnovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4822",
        brojPu: "49000",
        redBroj: "4822",
        nazivPu: "Krapina",
        naselje: "Radoboj",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4823",
        brojPu: "51326",
        redBroj: "4823",
        nazivPu: "Vrbovsko",
        naselje: "Radočaj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4824",
        brojPu: "51300",
        redBroj: "4824",
        nazivPu: "Delnice",
        naselje: "Radočaj Brodski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4825",
        brojPu: "47250",
        redBroj: "4825",
        nazivPu: "Duga Resa",
        naselje: "Radočaji",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4826",
        brojPu: "10380",
        redBroj: "4826",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Radoišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4827",
        brojPu: "22222",
        redBroj: "4827",
        nazivPu: "Skradin",
        naselje: "Radonić (Drniš)",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4828",
        brojPu: "22222",
        redBroj: "4828",
        nazivPu: "Skradin",
        naselje: "Radonić (Šibenik)",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4829",
        brojPu: "47220",
        redBroj: "4829",
        nazivPu: "Vojnić",
        naselje: "Radonja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4830",
        brojPu: "44210",
        redBroj: "4830",
        nazivPu: "Sunja",
        naselje: "Radonja Luka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4831",
        brojPu: "33520",
        redBroj: "4831",
        nazivPu: "Slatina",
        naselje: "Radosavci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4832",
        brojPu: "51326",
        redBroj: "4832",
        nazivPu: "Vrbovsko",
        naselje: "Radoševići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4833",
        brojPu: "52440",
        redBroj: "4833",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Radoši kod Višnjana",
        zupanija: "ISTARSKA",
      },
      {
        id: "4834",
        brojPu: "52440",
        redBroj: "4834",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Radoši kod Žbandaja",
        zupanija: "ISTARSKA",
      },
      {
        id: "4835",
        brojPu: "21204",
        redBroj: "4835",
        nazivPu: "Dugopolje",
        naselje: "Radošić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4836",
        brojPu: "21230",
        redBroj: "4836",
        nazivPu: "Sinj",
        naselje: "Radošić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4837",
        brojPu: "23422",
        redBroj: "4837",
        nazivPu: "Stankovci",
        naselje: "Radašinovci",
        zupanija: "ZADARSKA",
      },
      {
        id: "4838",
        brojPu: "42240",
        redBroj: "4838",
        nazivPu: "Ivanec",
        naselje: "Radovan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4839",
        brojPu: "34000",
        redBroj: "4839",
        nazivPu: "Požega",
        naselje: "Radovanci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4840",
        brojPu: "52440",
        redBroj: "4840",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Radovani",
        zupanija: "ISTARSKA",
      },
      {
        id: "4841",
        brojPu: "35250",
        redBroj: "4841",
        nazivPu: "Oriovac",
        naselje: "Radovanje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4842",
        brojPu: "20215",
        redBroj: "4842",
        nazivPu: "Gruda",
        naselje: "Radovčići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "4843",
        brojPu: "42208",
        redBroj: "4843",
        nazivPu: "Cestica",
        naselje: "Radovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4844",
        brojPu: "42208",
        redBroj: "4844",
        nazivPu: "Cestica",
        naselje: "Radovec Polje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4845",
        brojPu: "23248",
        redBroj: "4845",
        nazivPu: "Ražanac",
        naselje: "Radovin",
        zupanija: "ZADARSKA",
      },
      {
        id: "4846",
        brojPu: "23440",
        redBroj: "4846",
        nazivPu: "Gračac",
        naselje: "Raduč",
        zupanija: "ZADARSKA",
      },
      {
        id: "4847",
        brojPu: "22300",
        redBroj: "4847",
        nazivPu: "Knin",
        naselje: "Radučić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4848",
        brojPu: "10342",
        redBroj: "4848",
        nazivPu: "Dubrava",
        naselje: "Radulec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4849",
        brojPu: "21203",
        redBroj: "4849",
        nazivPu: "Donji Muć",
        naselje: "Radunić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4850",
        brojPu: "44330",
        redBroj: "4850",
        nazivPu: "Novska",
        naselje: "Rađenovci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4851",
        brojPu: "52440",
        redBroj: "4851",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rafaeli",
        zupanija: "ISTARSKA",
      },
      {
        id: "4852",
        brojPu: "47280",
        redBroj: "4852",
        nazivPu: "Ozalj",
        naselje: "Rajakovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4853",
        brojPu: "44330",
        redBroj: "4853",
        nazivPu: "Novska",
        naselje: "Rajčići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4854",
        brojPu: "32257",
        redBroj: "4854",
        nazivPu: "Drenovci",
        naselje: "Rajevo Selo",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4855",
        brojPu: "43000",
        redBroj: "4855",
        nazivPu: "Bjelovar",
        naselje: "Rajić",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4856",
        brojPu: "44330",
        redBroj: "4856",
        nazivPu: "Novska",
        naselje: "Rajić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4857",
        brojPu: "47220",
        redBroj: "4857",
        nazivPu: "Vojnić",
        naselje: "Rajić Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4858",
        brojPu: "33515",
        redBroj: "4858",
        nazivPu: "Orahovica",
        naselje: "Rajino Polje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4859",
        brojPu: "52207",
        redBroj: "4859",
        nazivPu: "Barban",
        naselje: "Rajki",
        zupanija: "ISTARSKA",
      },
      {
        id: "4860",
        brojPu: "52440",
        redBroj: "4860",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rajki",
        zupanija: "ISTARSKA",
      },
      {
        id: "4861",
        brojPu: "34000",
        redBroj: "4861",
        nazivPu: "Požega",
        naselje: "Rajsavac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4862",
        brojPu: "52207",
        redBroj: "4862",
        nazivPu: "Barban",
        naselje: "Rakalj",
        zupanija: "ISTARSKA",
      },
      {
        id: "4863",
        brojPu: "10431",
        redBroj: "4863",
        nazivPu: "Sveta Nedelja",
        naselje: "Rakitje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4864",
        brojPu: "48350",
        redBroj: "4864",
        nazivPu: "Đurđevac",
        naselje: "Rakitnica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4865",
        brojPu: "10410",
        redBroj: "4865",
        nazivPu: "Velika Gorica",
        naselje: "Rakitovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4866",
        brojPu: "31540",
        redBroj: "4866",
        nazivPu: "Donji Miholjac",
        naselje: "Rakitovica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4867",
        brojPu: "52424",
        redBroj: "4867",
        nazivPu: "Motovun (Montona)",
        naselje: "Rakotule",
        zupanija: "ISTARSKA",
      },
      {
        id: "4868",
        brojPu: "10436",
        redBroj: "4868",
        nazivPu: "Rakov Potok",
        naselje: "Rakov Potok",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4869",
        brojPu: "52440",
        redBroj: "4869",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rakovci",
        zupanija: "ISTARSKA",
      },
      {
        id: "4870",
        brojPu: "10340",
        redBroj: "4870",
        nazivPu: "Vrbovec",
        naselje: "Rakovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4871",
        brojPu: "42220",
        redBroj: "4871",
        nazivPu: "Novi Marof",
        naselje: "Rakovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4872",
        brojPu: "49290",
        redBroj: "4872",
        nazivPu: "Klanjec",
        naselje: "Rakovec Tomaševečki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4873",
        brojPu: "47240",
        redBroj: "4873",
        nazivPu: "Slunj",
        naselje: "Rakovica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4874",
        brojPu: "47240",
        redBroj: "4874",
        nazivPu: "Slunj",
        naselje: "Rakovičko Selište",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4875",
        brojPu: "34000",
        redBroj: "4875",
        nazivPu: "Požega",
        naselje: "Ramanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4876",
        brojPu: "21203",
        redBroj: "4876",
        nazivPu: "Donji Muć",
        naselje: "Ramljane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4877",
        brojPu: "22300",
        redBroj: "4877",
        nazivPu: "Knin",
        naselje: "Ramljane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4878",
        brojPu: "53220",
        redBroj: "4878",
        nazivPu: "Otočac",
        naselje: "Ramljani",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4879",
        brojPu: "53260",
        redBroj: "4879",
        nazivPu: "Brinje",
        naselje: "Rapain Klanac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4880",
        brojPu: "52440",
        redBroj: "4880",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rapavel",
        zupanija: "ISTARSKA",
      },
      {
        id: "4881",
        brojPu: "52341",
        redBroj: "4881",
        nazivPu: "Žminj",
        naselje: "Raponji",
        zupanija: "ISTARSKA",
      },
      {
        id: "4882",
        brojPu: "48000",
        redBroj: "4882",
        nazivPu: "Koprivnica",
        naselje: "Rasinja",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4883",
        brojPu: "51300",
        redBroj: "4883",
        nazivPu: "Delnice",
        naselje: "Raskrižje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4884",
        brojPu: "51300",
        redBroj: "4884",
        nazivPu: "Delnice",
        naselje: "Raskrižje Tihovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4885",
        brojPu: "22000",
        redBroj: "4885",
        nazivPu: "Šibenik",
        naselje: "Raslina",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4886",
        brojPu: "34000",
        redBroj: "4886",
        nazivPu: "Požega",
        naselje: "Rasna",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4887",
        brojPu: "51300",
        redBroj: "4887",
        nazivPu: "Delnice",
        naselje: "Rasohe",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4888",
        brojPu: "51511",
        redBroj: "4888",
        nazivPu: "Malinska",
        naselje: "Rasopasno",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4889",
        brojPu: "23440",
        redBroj: "4889",
        nazivPu: "Gračac",
        naselje: "Rastičevo",
        zupanija: "ZADARSKA",
      },
      {
        id: "4890",
        brojPu: "53000",
        redBroj: "4890",
        nazivPu: "Gospić",
        naselje: "Rastoka",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4891",
        brojPu: "47240",
        redBroj: "4891",
        nazivPu: "Slunj",
        naselje: "Rastoke",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4892",
        brojPu: "10450",
        redBroj: "4892",
        nazivPu: "Jastrebarsko",
        naselje: "Rastoki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4893",
        brojPu: "21222",
        redBroj: "4893",
        nazivPu: "Marina",
        naselje: "Rastovac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4894",
        brojPu: "21270",
        redBroj: "4894",
        nazivPu: "Zagvozd",
        naselje: "Rastovac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4895",
        brojPu: "43000",
        redBroj: "4895",
        nazivPu: "Bjelovar",
        naselje: "Rastovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4896",
        brojPu: "43290",
        redBroj: "4896",
        nazivPu: "Grubišno Polje",
        naselje: "Rastovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4897",
        brojPu: "47220",
        redBroj: "4897",
        nazivPu: "Vojnić",
        naselje: "Rastovac Budački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4898",
        brojPu: "53230",
        redBroj: "4898",
        nazivPu: "Korenica",
        naselje: "Rastovača",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4899",
        brojPu: "35000",
        redBroj: "4899",
        nazivPu: "Slavonski Brod",
        naselje: "Rastušje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4900",
        brojPu: "52220",
        redBroj: "4900",
        nazivPu: "Labin",
        naselje: "Raša",
        zupanija: "ISTARSKA",
      },
      {
        id: "4901",
        brojPu: "21270",
        redBroj: "4901",
        nazivPu: "Zagvozd",
        naselje: "Rašćane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4902",
        brojPu: "21270",
        redBroj: "4902",
        nazivPu: "Zagvozd",
        naselje: "Rašćane Gornje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4903",
        brojPu: "48260",
        redBroj: "4903",
        nazivPu: "Križevci",
        naselje: "Rašćani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4904",
        brojPu: "52420",
        redBroj: "4904",
        nazivPu: "Buzet",
        naselje: "Rašpor",
        zupanija: "ISTARSKA",
      },
      {
        id: "4905",
        brojPu: "23420",
        redBroj: "4905",
        nazivPu: "Benkovac",
        naselje: "Raštević",
        zupanija: "ZADARSKA",
      },
      {
        id: "4906",
        brojPu: "31400",
        redBroj: "4906",
        nazivPu: "Đakovo",
        naselje: "Ratkov Dol",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4907",
        brojPu: "35430",
        redBroj: "4907",
        nazivPu: "Okučani",
        naselje: "Ratkovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4908",
        brojPu: "49250",
        redBroj: "4908",
        nazivPu: "Zlatar",
        naselje: "Ratkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4909",
        brojPu: "34310",
        redBroj: "4909",
        nazivPu: "Pleternica",
        naselje: "Ratkovica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4910",
        brojPu: "51218",
        redBroj: "4910",
        nazivPu: "Dražice",
        naselje: "Ratulje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4911",
        brojPu: "44430",
        redBroj: "4911",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Rausovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4912",
        brojPu: "23283",
        redBroj: "4912",
        nazivPu: "Rava",
        naselje: "Rava",
        zupanija: "ZADARSKA",
      },
      {
        id: "4913",
        brojPu: "35252",
        redBroj: "4913",
        nazivPu: "Sibinj",
        naselje: "Ravan",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4914",
        brojPu: "21276",
        redBroj: "4914",
        nazivPu: "Vrgorac",
        naselje: "Ravča",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4915",
        brojPu: "51300",
        redBroj: "4915",
        nazivPu: "Delnice",
        naselje: "Ravna Gora",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4916",
        brojPu: "43000",
        redBroj: "4916",
        nazivPu: "Bjelovar",
        naselje: "Ravneš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4917",
        brojPu: "52220",
        redBroj: "4917",
        nazivPu: "Labin",
        naselje: "Ravni",
        zupanija: "ISTARSKA",
      },
      {
        id: "4918",
        brojPu: "53288",
        redBroj: "4918",
        nazivPu: "Karlobag",
        naselje: "Ravni Dabar",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4919",
        brojPu: "47000",
        redBroj: "4919",
        nazivPu: "Karlovac",
        naselje: "Ravnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4920",
        brojPu: "49210",
        redBroj: "4920",
        nazivPu: "Zabok",
        naselje: "Ravnice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4921",
        brojPu: "51306",
        redBroj: "4921",
        nazivPu: "Čabar",
        naselje: "Ravnice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4922",
        brojPu: "49216",
        redBroj: "4922",
        nazivPu: "Desinić",
        naselje: "Ravnice Desinićke",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4923",
        brojPu: "49225",
        redBroj: "4923",
        nazivPu: "Đurmanec",
        naselje: "Ravninsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4924",
        brojPu: "49290",
        redBroj: "4924",
        nazivPu: "Klanjec",
        naselje: "Ravno Brezje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4925",
        brojPu: "44400",
        redBroj: "4925",
        nazivPu: "Glina",
        naselje: "Ravno Rašće",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4926",
        brojPu: "31500",
        redBroj: "4926",
        nazivPu: "Našice",
        naselje: "Razbojište",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4927",
        brojPu: "51326",
        redBroj: "4927",
        nazivPu: "Vrbovsko",
        naselje: "Razdrto",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4928",
        brojPu: "49290",
        redBroj: "4928",
        nazivPu: "Klanjec",
        naselje: "Razdrto Tuheljsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4929",
        brojPu: "51300",
        redBroj: "4929",
        nazivPu: "Delnice",
        naselje: "Razloge",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4930",
        brojPu: "51300",
        redBroj: "4930",
        nazivPu: "Delnice",
        naselje: "Razloški Okrug",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4931",
        brojPu: "10315",
        redBroj: "4931",
        nazivPu: "Novoselec",
        naselje: "Razljev",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4932",
        brojPu: "22320",
        redBroj: "4932",
        nazivPu: "Drniš",
        naselje: "Razvođe",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4933",
        brojPu: "49290",
        redBroj: "4933",
        nazivPu: "Klanjec",
        naselje: "Razvor",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4934",
        brojPu: "23248",
        redBroj: "4934",
        nazivPu: "Ražanac",
        naselje: "Ražanac",
        zupanija: "ZADARSKA",
      },
      {
        id: "4935",
        brojPu: "22202",
        redBroj: "4935",
        nazivPu: "Primošten",
        naselje: "Ražanj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4936",
        brojPu: "53234",
        redBroj: "4936",
        nazivPu: "Udbina",
        naselje: "Rebić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4937",
        brojPu: "52207",
        redBroj: "4937",
        nazivPu: "Barban",
        naselje: "Rebići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4938",
        brojPu: "47300",
        redBroj: "4938",
        nazivPu: "Ogulin",
        naselje: "Rebrovići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4939",
        brojPu: "47000",
        redBroj: "4939",
        nazivPu: "Karlovac",
        naselje: "Rečica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4940",
        brojPu: "10315",
        redBroj: "4940",
        nazivPu: "Novoselec",
        naselje: "Rečica Kriška",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4941",
        brojPu: "10450",
        redBroj: "4941",
        nazivPu: "Jastrebarsko",
        naselje: "Redovje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4942",
        brojPu: "48000",
        redBroj: "4942",
        nazivPu: "Koprivnica",
        naselje: "Reka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4943",
        brojPu: "10340",
        redBroj: "4943",
        nazivPu: "Vrbovec",
        naselje: "Remetinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4944",
        brojPu: "42220",
        redBroj: "4944",
        nazivPu: "Novi Marof",
        naselje: "Remetinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4945",
        brojPu: "43500",
        redBroj: "4945",
        nazivPu: "Daruvar",
        naselje: "Removac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4946",
        brojPu: "47250",
        redBroj: "4946",
        nazivPu: "Duga Resa",
        naselje: "Rendulići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4947",
        brojPu: "48350",
        redBroj: "4947",
        nazivPu: "Đurđevac",
        naselje: "Repaš",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4948",
        brojPu: "49240",
        redBroj: "4948",
        nazivPu: "Donja Stubica",
        naselje: "Repićevo Selo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4949",
        brojPu: "10340",
        redBroj: "4949",
        nazivPu: "Vrbovec",
        naselje: "Repinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4950",
        brojPu: "10450",
        redBroj: "4950",
        nazivPu: "Jastrebarsko",
        naselje: "Repišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4951",
        brojPu: "49250",
        redBroj: "4951",
        nazivPu: "Zlatar",
        naselje: "Repno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4952",
        brojPu: "49210",
        redBroj: "4952",
        nazivPu: "Zabok",
        naselje: "Repovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4953",
        brojPu: "44320",
        redBroj: "4953",
        nazivPu: "Kutina",
        naselje: "Repušnica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "4954",
        brojPu: "51300",
        redBroj: "4954",
        nazivPu: "Delnice",
        naselje: "Resnatac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4955",
        brojPu: "34310",
        redBroj: "4955",
        nazivPu: "Pleternica",
        naselje: "Resnik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "4956",
        brojPu: "47250",
        redBroj: "4956",
        nazivPu: "Duga Resa",
        naselje: "Resnik Bosiljevski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4957",
        brojPu: "53230",
        redBroj: "4957",
        nazivPu: "Korenica",
        naselje: "Rešetar",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4958",
        brojPu: "47250",
        redBroj: "4958",
        nazivPu: "Duga Resa",
        naselje: "Rešetarevo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4959",
        brojPu: "35400",
        redBroj: "4959",
        nazivPu: "Nova Gradiška",
        naselje: "Rešetari",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "4960",
        brojPu: "43000",
        redBroj: "4960",
        nazivPu: "Bjelovar",
        naselje: "Reškovci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4961",
        brojPu: "47280",
        redBroj: "4961",
        nazivPu: "Ozalj",
        naselje: "Reštovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4962",
        brojPu: "10454",
        redBroj: "4962",
        nazivPu: "Krašić",
        naselje: "Reštovo Žumberačko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4963",
        brojPu: "32100",
        redBroj: "4963",
        nazivPu: "Vinkovci",
        naselje: "Retkovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "4964",
        brojPu: "42223",
        redBroj: "4964",
        nazivPu: "Varaždinske Toplice",
        naselje: "Retkovec Svibovečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4965",
        brojPu: "33000",
        redBroj: "4965",
        nazivPu: "Virovitica",
        naselje: "Rezovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4966",
        brojPu: "33000",
        redBroj: "4966",
        nazivPu: "Virovitica",
        naselje: "Rezovačke Krčevine",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4967",
        brojPu: "52341",
        redBroj: "4967",
        nazivPu: "Žminj",
        naselje: "Režanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "4968",
        brojPu: "47000",
        redBroj: "4968",
        nazivPu: "Karlovac",
        naselje: "Ribari",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4969",
        brojPu: "47300",
        redBroj: "4969",
        nazivPu: "Ogulin",
        naselje: "Ribarići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4970",
        brojPu: "52440",
        redBroj: "4970",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ribarići",
        zupanija: "ISTARSKA",
      },
      {
        id: "4971",
        brojPu: "42240",
        redBroj: "4971",
        nazivPu: "Ivanec",
        naselje: "Ribić Breg",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4972",
        brojPu: "10410",
        redBroj: "4972",
        nazivPu: "Velika Gorica",
        naselje: "Ribnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "4973",
        brojPu: "47000",
        redBroj: "4973",
        nazivPu: "Karlovac",
        naselje: "Ribnik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "4974",
        brojPu: "43280",
        redBroj: "4974",
        nazivPu: "Garešnica",
        naselje: "Ribnjaci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4975",
        brojPu: "43270",
        redBroj: "4975",
        nazivPu: "Veliki Grđevac",
        naselje: "Ribnjačka",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "4976",
        brojPu: "31500",
        redBroj: "4976",
        nazivPu: "Našice",
        naselje: "Ribnjak",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "4977",
        brojPu: "48000",
        redBroj: "4977",
        nazivPu: "Koprivnica",
        naselje: "Ribnjak",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4978",
        brojPu: "21260",
        redBroj: "4978",
        nazivPu: "Imotski",
        naselje: "Ričice",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "4979",
        brojPu: "23440",
        redBroj: "4979",
        nazivPu: "Gračac",
        naselje: "Ričice",
        zupanija: "ZADARSKA",
      },
      {
        id: "4980",
        brojPu: "22300",
        redBroj: "4980",
        nazivPu: "Knin",
        naselje: "Riđane",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "4981",
        brojPu: "51000",
        redBroj: "4981",
        nazivPu: "Rijeka",
        naselje: "Rijeka",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4982",
        brojPu: "48000",
        redBroj: "4982",
        nazivPu: "Koprivnica",
        naselje: "Rijeka Koprivnička",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "4983",
        brojPu: "42240",
        redBroj: "4983",
        nazivPu: "Ivanec",
        naselje: "Rijeka Voćanska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4984",
        brojPu: "33515",
        redBroj: "4984",
        nazivPu: "Orahovica",
        naselje: "Rijenci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4985",
        brojPu: "51326",
        redBroj: "4985",
        nazivPu: "Vrbovsko",
        naselje: "Rim",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4986",
        brojPu: "52420",
        redBroj: "4986",
        nazivPu: "Buzet",
        naselje: "Rim",
        zupanija: "ISTARSKA",
      },
      {
        id: "4987",
        brojPu: "52420",
        redBroj: "4987",
        nazivPu: "Buzet",
        naselje: "Rimnjak",
        zupanija: "ISTARSKA",
      },
      {
        id: "4988",
        brojPu: "42250",
        redBroj: "4988",
        nazivPu: "Lepoglava",
        naselje: "Rinkovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4989",
        brojPu: "52220",
        redBroj: "4989",
        nazivPu: "Labin",
        naselje: "Ripenda Kosi",
        zupanija: "ISTARSKA",
      },
      {
        id: "4990",
        brojPu: "52220",
        redBroj: "4990",
        nazivPu: "Labin",
        naselje: "Ripenda Kras",
        zupanija: "ISTARSKA",
      },
      {
        id: "4991",
        brojPu: "52220",
        redBroj: "4991",
        nazivPu: "Labin",
        naselje: "Ripenda Verbanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "4992",
        brojPu: "51500",
        redBroj: "4992",
        nazivPu: "Krk",
        naselje: "Risika",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "4993",
        brojPu: "49290",
        redBroj: "4993",
        nazivPu: "Klanjec",
        naselje: "Risvica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "4994",
        brojPu: "33000",
        redBroj: "4994",
        nazivPu: "Virovitica",
        naselje: "Rit",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "4995",
        brojPu: "42230",
        redBroj: "4995",
        nazivPu: "Ludbreg",
        naselje: "Rivalno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "4996",
        brojPu: "23291",
        redBroj: "4996",
        nazivPu: "Sestrunj",
        naselje: "Rivanj",
        zupanija: "ZADARSKA",
      },
      {
        id: "4997",
        brojPu: "53000",
        redBroj: "4997",
        nazivPu: "Gospić",
        naselje: "Rizvanuša",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "4998",
        brojPu: "40313",
        redBroj: "4998",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Robadje",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "4999",
        brojPu: "52420",
        redBroj: "4999",
        nazivPu: "Buzet",
        naselje: "Roč",
        zupanija: "ISTARSKA",
      },
      {
        id: "5000",
        brojPu: "52420",
        redBroj: "5000",
        nazivPu: "Buzet",
        naselje: "Ročko Polje",
        zupanija: "ISTARSKA",
      },
      {
        id: "5001",
        brojPu: "23420",
        redBroj: "5001",
        nazivPu: "Benkovac",
        naselje: "Rodaljice",
        zupanija: "ZADARSKA",
      },
      {
        id: "5002",
        brojPu: "33410",
        redBroj: "5002",
        nazivPu: "Suhopolje",
        naselje: "Rodin Potok",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5003",
        brojPu: "21430",
        redBroj: "5003",
        nazivPu: "Grohote",
        naselje: "Rogač",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5004",
        brojPu: "21480",
        redBroj: "5004",
        nazivPu: "Vis",
        naselje: "Rogačić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5005",
        brojPu: "51300",
        redBroj: "5005",
        nazivPu: "Delnice",
        naselje: "Rogi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5006",
        brojPu: "52220",
        redBroj: "5006",
        nazivPu: "Labin",
        naselje: "Rogočana",
        zupanija: "ISTARSKA",
      },
      {
        id: "5007",
        brojPu: "20340",
        redBroj: "5007",
        nazivPu: "Ploče",
        naselje: "Rogotin",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5008",
        brojPu: "33000",
        redBroj: "5008",
        nazivPu: "Virovitica",
        naselje: "Rogovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5009",
        brojPu: "52440",
        redBroj: "5009",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rogovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5010",
        brojPu: "22202",
        redBroj: "5010",
        nazivPu: "Primošten",
        naselje: "Rogoznica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5011",
        brojPu: "43280",
        redBroj: "5011",
        nazivPu: "Garešnica",
        naselje: "Rogoža",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5012",
        brojPu: "34550",
        redBroj: "5012",
        nazivPu: "Pakrac",
        naselje: "Rogulje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5013",
        brojPu: "44440",
        redBroj: "5013",
        nazivPu: "Dvor",
        naselje: "Rogulje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5014",
        brojPu: "52440",
        redBroj: "5014",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rojci",
        zupanija: "ISTARSKA",
      },
      {
        id: "5015",
        brojPu: "52207",
        redBroj: "5015",
        nazivPu: "Barban",
        naselje: "Rojnići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5016",
        brojPu: "32100",
        redBroj: "5016",
        nazivPu: "Vinkovci",
        naselje: "Rokovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5017",
        brojPu: "20225",
        redBroj: "5017",
        nazivPu: "Babino Polje",
        naselje: "Ropa",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5018",
        brojPu: "47000",
        redBroj: "5018",
        nazivPu: "Karlovac",
        naselje: "Rosopajnik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5019",
        brojPu: "44430",
        redBroj: "5019",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Rosulje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5020",
        brojPu: "52440",
        redBroj: "5020",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rošini",
        zupanija: "ISTARSKA",
      },
      {
        id: "5021",
        brojPu: "52440",
        redBroj: "5021",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Roškići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5022",
        brojPu: "48260",
        redBroj: "5022",
        nazivPu: "Križevci",
        naselje: "Rovci",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5023",
        brojPu: "52210",
        redBroj: "5023",
        nazivPu: "Rovinj (Rovigno)",
        naselje: "Rovinj - Rovigno",
        zupanija: "ISTARSKA",
      },
      {
        id: "5024",
        brojPu: "52210",
        redBroj: "5024",
        nazivPu: "Rovinj (Rovigno)",
        naselje: "Rovinjsko Selo",
        zupanija: "ISTARSKA",
      },
      {
        id: "5025",
        brojPu: "43000",
        redBroj: "5025",
        nazivPu: "Bjelovar",
        naselje: "Rovišće",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5026",
        brojPu: "44400",
        redBroj: "5026",
        nazivPu: "Glina",
        naselje: "Roviška",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5027",
        brojPu: "48000",
        redBroj: "5027",
        nazivPu: "Koprivnica",
        naselje: "Rovištanci",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5028",
        brojPu: "49000",
        redBroj: "5028",
        nazivPu: "Krapina",
        naselje: "Rovno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5029",
        brojPu: "10292",
        redBroj: "5029",
        nazivPu: "Šenkovec",
        naselje: "Rozga",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5030",
        brojPu: "31500",
        redBroj: "5030",
        nazivPu: "Našice",
        naselje: "Rozmajerovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5031",
        brojPu: "20236",
        redBroj: "5031",
        nazivPu: "Mokošica",
        naselje: "Rožat",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5032",
        brojPu: "44330",
        redBroj: "5032",
        nazivPu: "Novska",
        naselje: "Roždanik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5033",
        brojPu: "21240",
        redBroj: "5033",
        nazivPu: "Trilj",
        naselje: "Rože",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5034",
        brojPu: "10414",
        redBroj: "5034",
        nazivPu: "Pokupsko",
        naselje: "Roženica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5035",
        brojPu: "51326",
        redBroj: "5035",
        nazivPu: "Vrbovsko",
        naselje: "Rtić",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5036",
        brojPu: "23248",
        redBroj: "5036",
        nazivPu: "Ražanac",
        naselje: "Rtina",
        zupanija: "ZADARSKA",
      },
      {
        id: "5037",
        brojPu: "10410",
        redBroj: "5037",
        nazivPu: "Velika Gorica",
        naselje: "Ruča",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5038",
        brojPu: "21238",
        redBroj: "5038",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Ruda",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5039",
        brojPu: "52341",
        redBroj: "5039",
        nazivPu: "Žminj",
        naselje: "Rudani",
        zupanija: "ISTARSKA",
      },
      {
        id: "5040",
        brojPu: "53230",
        redBroj: "5040",
        nazivPu: "Korenica",
        naselje: "Rudanovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5041",
        brojPu: "10430",
        redBroj: "5041",
        nazivPu: "Samobor",
        naselje: "Rude",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5042",
        brojPu: "10454",
        redBroj: "5042",
        nazivPu: "Krašić",
        naselje: "Rude Pribićke",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5043",
        brojPu: "44440",
        redBroj: "5043",
        nazivPu: "Dvor",
        naselje: "Rudeži",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5044",
        brojPu: "21460",
        redBroj: "5044",
        nazivPu: "Stari Grad",
        naselje: "Rudina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5045",
        brojPu: "51511",
        redBroj: "5045",
        nazivPu: "Malinska",
        naselje: "Rudine",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5046",
        brojPu: "53220",
        redBroj: "5046",
        nazivPu: "Otočac",
        naselje: "Rudopolje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5047",
        brojPu: "23440",
        redBroj: "5047",
        nazivPu: "Gračac",
        naselje: "Rudopolje Bruvanjsko",
        zupanija: "ZADARSKA",
      },
      {
        id: "5048",
        brojPu: "10370",
        redBroj: "5048",
        nazivPu: "Dugo Selo",
        naselje: "Rugvica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5049",
        brojPu: "44440",
        redBroj: "5049",
        nazivPu: "Dvor",
        naselje: "Rujevac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5050",
        brojPu: "47280",
        redBroj: "5050",
        nazivPu: "Ozalj",
        naselje: "Rujevo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5051",
        brojPu: "21480",
        redBroj: "5051",
        nazivPu: "Vis",
        naselje: "Rukavac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5052",
        brojPu: "51211",
        redBroj: "5052",
        nazivPu: "Matulji",
        naselje: "Rukavac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5053",
        brojPu: "42223",
        redBroj: "5053",
        nazivPu: "Varaždinske Toplice",
        naselje: "Rukljevina",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5054",
        brojPu: "21233",
        redBroj: "5054",
        nazivPu: "Hrvace",
        naselje: "Rumin",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5055",
        brojPu: "21260",
        redBroj: "5055",
        nazivPu: "Imotski",
        naselje: "Runović",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5056",
        brojPu: "51211",
        redBroj: "5056",
        nazivPu: "Matulji",
        naselje: "Rupa",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5057",
        brojPu: "23241",
        redBroj: "5057",
        nazivPu: "Poličnik",
        naselje: "Rupalj",
        zupanija: "ZADARSKA",
      },
      {
        id: "5058",
        brojPu: "22222",
        redBroj: "5058",
        nazivPu: "Skradin",
        naselje: "Rupe",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5059",
        brojPu: "52440",
        redBroj: "5059",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Rupeni",
        zupanija: "ISTARSKA",
      },
      {
        id: "5060",
        brojPu: "49231",
        redBroj: "5060",
        nazivPu: "Hum na Sutli",
        naselje: "Rusnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5061",
        brojPu: "33410",
        redBroj: "5061",
        nazivPu: "Suhopolje",
        naselje: "Rušani",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5062",
        brojPu: "35000",
        redBroj: "5062",
        nazivPu: "Slavonski Brod",
        naselje: "Ruščica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5063",
        brojPu: "34000",
        redBroj: "5063",
        nazivPu: "Požega",
        naselje: "Ruševac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5064",
        brojPu: "48260",
        redBroj: "5064",
        nazivPu: "Križevci",
        naselje: "Ruševac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5065",
        brojPu: "47240",
        redBroj: "5065",
        nazivPu: "Slunj",
        naselje: "Ruševica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5066",
        brojPu: "34340",
        redBroj: "5066",
        nazivPu: "Kutjevo",
        naselje: "Ruševo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5067",
        brojPu: "51250",
        redBroj: "5067",
        nazivPu: "Novi Vinodolski",
        naselje: "Ruševo Krmpotsko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5068",
        brojPu: "43000",
        redBroj: "5068",
        nazivPu: "Bjelovar",
        naselje: "Ruškovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5069",
        brojPu: "44317",
        redBroj: "5069",
        nazivPu: "Popovača",
        naselje: "Ruškovica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5070",
        brojPu: "22320",
        redBroj: "5070",
        nazivPu: "Drniš",
        naselje: "Ružić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5071",
        brojPu: "51211",
        redBroj: "5071",
        nazivPu: "Matulji",
        naselje: "Ružići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5072",
        brojPu: "52220",
        redBroj: "5072",
        nazivPu: "Labin",
        naselje: "Ružići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5073",
        brojPu: "52440",
        redBroj: "5073",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ružići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5074",
        brojPu: "47300",
        redBroj: "5074",
        nazivPu: "Ogulin",
        naselje: "Sabljak Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5075",
        brojPu: "47300",
        redBroj: "5075",
        nazivPu: "Ogulin",
        naselje: "Sabljaki Modruški",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5076",
        brojPu: "51511",
        redBroj: "5076",
        nazivPu: "Malinska",
        naselje: "Sabljići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5077",
        brojPu: "47300",
        redBroj: "5077",
        nazivPu: "Ogulin",
        naselje: "Saborsko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5078",
        brojPu: "47240",
        redBroj: "5078",
        nazivPu: "Slunj",
        naselje: "Sadikovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5079",
        brojPu: "47240",
        redBroj: "5079",
        nazivPu: "Slunj",
        naselje: "Sadilovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5080",
        brojPu: "10340",
        redBroj: "5080",
        nazivPu: "Vrbovec",
        naselje: "Salajci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5081",
        brojPu: "52220",
        redBroj: "5081",
        nazivPu: "Labin",
        naselje: "Salakovci",
        zupanija: "ISTARSKA",
      },
      {
        id: "5082",
        brojPu: "52341",
        redBroj: "5082",
        nazivPu: "Žminj",
        naselje: "Salambati",
        zupanija: "ISTARSKA",
      },
      {
        id: "5083",
        brojPu: "52420",
        redBroj: "5083",
        nazivPu: "Buzet",
        naselje: "Salež",
        zupanija: "ISTARSKA",
      },
      {
        id: "5084",
        brojPu: "23281",
        redBroj: "5084",
        nazivPu: "Sali",
        naselje: "Sali",
        zupanija: "ZADARSKA",
      },
      {
        id: "5085",
        brojPu: "42240",
        redBroj: "5085",
        nazivPu: "Ivanec",
        naselje: "Salinovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5086",
        brojPu: "10380",
        redBroj: "5086",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Salnik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5087",
        brojPu: "47240",
        redBroj: "5087",
        nazivPu: "Slunj",
        naselje: "Salopek Luke",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5088",
        brojPu: "47300",
        redBroj: "5088",
        nazivPu: "Ogulin",
        naselje: "Salopek Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5089",
        brojPu: "47300",
        redBroj: "5089",
        nazivPu: "Ogulin",
        naselje: "Salopeki Modruški",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5090",
        brojPu: "43000",
        redBroj: "5090",
        nazivPu: "Bjelovar",
        naselje: "Samarica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5091",
        brojPu: "31550",
        redBroj: "5091",
        nazivPu: "Valpovo",
        naselje: "Samatovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5092",
        brojPu: "49240",
        redBroj: "5092",
        nazivPu: "Donja Stubica",
        naselje: "Samci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5093",
        brojPu: "10430",
        redBroj: "5093",
        nazivPu: "Samobor",
        naselje: "Samobor",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5094",
        brojPu: "10340",
        redBroj: "5094",
        nazivPu: "Vrbovec",
        naselje: "Samoborec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5095",
        brojPu: "10430",
        redBroj: "5095",
        nazivPu: "Samobor",
        naselje: "Samoborski Otok",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5096",
        brojPu: "52220",
        redBroj: "5096",
        nazivPu: "Labin",
        naselje: "Santalezi",
        zupanija: "ISTARSKA",
      },
      {
        id: "5097",
        brojPu: "35214",
        redBroj: "5097",
        nazivPu: "Donji Andrijevci",
        naselje: "Sapci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5098",
        brojPu: "22202",
        redBroj: "5098",
        nazivPu: "Primošten",
        naselje: "Sapina Doca",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5099",
        brojPu: "20225",
        redBroj: "5099",
        nazivPu: "Babino Polje",
        naselje: "Saplunara",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5100",
        brojPu: "34340",
        redBroj: "5100",
        nazivPu: "Kutjevo",
        naselje: "Sapna",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5101",
        brojPu: "47250",
        redBroj: "5101",
        nazivPu: "Duga Resa",
        naselje: "Sarovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5102",
        brojPu: "51216",
        redBroj: "5102",
        nazivPu: "Viškovo",
        naselje: "Saršoni",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5103",
        brojPu: "31000",
        redBroj: "5103",
        nazivPu: "Osijek",
        naselje: "Sarvaš",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5104",
        brojPu: "10410",
        redBroj: "5104",
        nazivPu: "Velika Gorica",
        naselje: "Sasi",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5105",
        brojPu: "43270",
        redBroj: "5105",
        nazivPu: "Veliki Grđevac",
        naselje: "Sasovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5106",
        brojPu: "47240",
        redBroj: "5106",
        nazivPu: "Slunj",
        naselje: "Sastavak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5107",
        brojPu: "31550",
        redBroj: "5107",
        nazivPu: "Valpovo",
        naselje: "Satnica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5108",
        brojPu: "31400",
        redBroj: "5108",
        nazivPu: "Đakovo",
        naselje: "Satnica Đakovačka",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5109",
        brojPu: "21233",
        redBroj: "5109",
        nazivPu: "Hrvace",
        naselje: "Satrić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5110",
        brojPu: "23287",
        redBroj: "5110",
        nazivPu: "Veli Rat",
        naselje: "Savar",
        zupanija: "ZADARSKA",
      },
      {
        id: "5111",
        brojPu: "10430",
        redBroj: "5111",
        nazivPu: "Samobor",
        naselje: "Savršćak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5112",
        brojPu: "10340",
        redBroj: "5112",
        nazivPu: "Vrbovec",
        naselje: "Savska Cesta",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5113",
        brojPu: "40000",
        redBroj: "5113",
        nazivPu: "Čakovec",
        naselje: "Savska Ves",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5114",
        brojPu: "35400",
        redBroj: "5114",
        nazivPu: "Nova Gradiška",
        naselje: "Savski Bok",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5115",
        brojPu: "10291",
        redBroj: "5115",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Savski Marof",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5116",
        brojPu: "52470",
        redBroj: "5116",
        nazivPu: "Umag (Umago)",
        naselje: "Savudrija - Salvore",
        zupanija: "ISTARSKA",
      },
      {
        id: "5117",
        brojPu: "34000",
        redBroj: "5117",
        nazivPu: "Požega",
        naselje: "Sažije",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5118",
        brojPu: "51300",
        redBroj: "5118",
        nazivPu: "Delnice",
        naselje: "Sedalce",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5119",
        brojPu: "33405",
        redBroj: "5119",
        nazivPu: "Pitomača",
        naselje: "Sedlarica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5120",
        brojPu: "22323",
        redBroj: "5120",
        nazivPu: "Unešić",
        naselje: "Sedramić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5121",
        brojPu: "52470",
        redBroj: "5121",
        nazivPu: "Umag (Umago)",
        naselje: "Seget - Seghetto",
        zupanija: "ISTARSKA",
      },
      {
        id: "5122",
        brojPu: "21220",
        redBroj: "5122",
        nazivPu: "Trogir",
        naselje: "Seget Donji",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5123",
        brojPu: "21220",
        redBroj: "5123",
        nazivPu: "Trogir",
        naselje: "Seget Gornji",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5124",
        brojPu: "21220",
        redBroj: "5124",
        nazivPu: "Trogir",
        naselje: "Seget Vranjica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5125",
        brojPu: "42230",
        redBroj: "5125",
        nazivPu: "Ludbreg",
        naselje: "Segovina",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5126",
        brojPu: "42204",
        redBroj: "5126",
        nazivPu: "Turčin",
        naselje: "Seketin",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5127",
        brojPu: "49240",
        redBroj: "5127",
        nazivPu: "Donja Stubica",
        naselje: "Sekirevo Selo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5128",
        brojPu: "49223",
        redBroj: "5128",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Sekirišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5129",
        brojPu: "47280",
        redBroj: "5129",
        nazivPu: "Ozalj",
        naselje: "Sekulići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5130",
        brojPu: "33520",
        redBroj: "5130",
        nazivPu: "Slatina",
        naselje: "Sekulinci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5131",
        brojPu: "44000",
        redBroj: "5131",
        nazivPu: "Sisak",
        naselje: "Sela",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5132",
        brojPu: "47250",
        redBroj: "5132",
        nazivPu: "Duga Resa",
        naselje: "Sela Bosiljevska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5133",
        brojPu: "48260",
        redBroj: "5133",
        nazivPu: "Križevci",
        naselje: "Sela Ravenska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5134",
        brojPu: "47000",
        redBroj: "5134",
        nazivPu: "Karlovac",
        naselje: "Sela Žakanjska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5135",
        brojPu: "47220",
        redBroj: "5135",
        nazivPu: "Vojnić",
        naselje: "Selakova Poljana",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5136",
        brojPu: "48260",
        redBroj: "5136",
        nazivPu: "Križevci",
        naselje: "Selanec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5137",
        brojPu: "21425",
        redBroj: "5137",
        nazivPu: "Selca",
        naselje: "Selca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5138",
        brojPu: "52420",
        redBroj: "5138",
        nazivPu: "Buzet",
        naselje: "Selca",
        zupanija: "ISTARSKA",
      },
      {
        id: "5139",
        brojPu: "21469",
        redBroj: "5139",
        nazivPu: "Sućuraj",
        naselje: "Selca kod Bogomolja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5140",
        brojPu: "21460",
        redBroj: "5140",
        nazivPu: "Stari Grad",
        naselje: "Selca kod Starog Grada",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5141",
        brojPu: "51260",
        redBroj: "5141",
        nazivPu: "Crikvenica",
        naselje: "Selce",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5142",
        brojPu: "10430",
        redBroj: "5142",
        nazivPu: "Samobor",
        naselje: "Selce Žumberačko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5143",
        brojPu: "31550",
        redBroj: "5143",
        nazivPu: "Valpovo",
        naselje: "Selci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5144",
        brojPu: "31400",
        redBroj: "5144",
        nazivPu: "Đakovo",
        naselje: "Selci Đakovački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5145",
        brojPu: "42208",
        redBroj: "5145",
        nazivPu: "Cestica",
        naselje: "Selci Križovljanski",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5146",
        brojPu: "52440",
        redBroj: "5146",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Selina",
        zupanija: "ISTARSKA",
      },
      {
        id: "5147",
        brojPu: "23244",
        redBroj: "5147",
        nazivPu: "Starigrad Paklenica",
        naselje: "Seline",
        zupanija: "ZADARSKA",
      },
      {
        id: "5148",
        brojPu: "44210",
        redBroj: "5148",
        nazivPu: "Sunja",
        naselje: "Selišće Sunjsko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5149",
        brojPu: "44320",
        redBroj: "5149",
        nazivPu: "Kutina",
        naselje: "Selište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5150",
        brojPu: "47240",
        redBroj: "5150",
        nazivPu: "Slunj",
        naselje: "Selište Drežničko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5151",
        brojPu: "44430",
        redBroj: "5151",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Selište Kostajničko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5152",
        brojPu: "35212",
        redBroj: "5152",
        nazivPu: "Garčin",
        naselje: "Selna",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5153",
        brojPu: "40313",
        redBroj: "5153",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Selnica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5154",
        brojPu: "49246",
        redBroj: "5154",
        nazivPu: "Marija Bistrica",
        naselje: "Selnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5155",
        brojPu: "48260",
        redBroj: "5155",
        nazivPu: "Križevci",
        naselje: "Selnica Miholečka",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5156",
        brojPu: "48316",
        redBroj: "5156",
        nazivPu: "Đelekovec",
        naselje: "Selnica Podravska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5157",
        brojPu: "10380",
        redBroj: "5157",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Selnica Psarjevačka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5158",
        brojPu: "10410",
        redBroj: "5158",
        nazivPu: "Velika Gorica",
        naselje: "Selnica Šćitarjevska",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5159",
        brojPu: "42230",
        redBroj: "5159",
        nazivPu: "Ludbreg",
        naselje: "Selnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5160",
        brojPu: "42243",
        redBroj: "5160",
        nazivPu: "Maruševec",
        naselje: "Selnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5161",
        brojPu: "49217",
        redBroj: "5161",
        nazivPu: "Krapinske Toplice",
        naselje: "Selno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5162",
        brojPu: "51306",
        redBroj: "5162",
        nazivPu: "Čabar",
        naselje: "Selo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5163",
        brojPu: "53202",
        redBroj: "5163",
        nazivPu: "Perušić",
        naselje: "Selo Sveti Marko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5164",
        brojPu: "10451",
        redBroj: "5164",
        nazivPu: "Pisarovina",
        naselje: "Selsko Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5165",
        brojPu: "52420",
        redBroj: "5165",
        nazivPu: "Buzet",
        naselje: "Seljaci",
        zupanija: "ISTARSKA",
      },
      {
        id: "5166",
        brojPu: "42240",
        redBroj: "5166",
        nazivPu: "Ivanec",
        naselje: "Seljanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5167",
        brojPu: "31400",
        redBroj: "5167",
        nazivPu: "Đakovo",
        naselje: "Semeljci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5168",
        brojPu: "52420",
        redBroj: "5168",
        nazivPu: "Buzet",
        naselje: "Semić",
        zupanija: "ISTARSKA",
      },
      {
        id: "5169",
        brojPu: "52420",
        redBroj: "5169",
        nazivPu: "Buzet",
        naselje: "Senj",
        zupanija: "ISTARSKA",
      },
      {
        id: "5170",
        brojPu: "53270",
        redBroj: "5170",
        nazivPu: "Senj",
        naselje: "Senj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5171",
        brojPu: "53270",
        redBroj: "5171",
        nazivPu: "Senj",
        naselje: "Senjska Draga",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5172",
        brojPu: "21250",
        redBroj: "5172",
        nazivPu: "Šestanovac",
        naselje: "Seoca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5173",
        brojPu: "35410",
        redBroj: "5173",
        nazivPu: "Nova Kapela",
        naselje: "Seoce",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5174",
        brojPu: "34000",
        redBroj: "5174",
        nazivPu: "Požega",
        naselje: "Seoci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5175",
        brojPu: "31500",
        redBroj: "5175",
        nazivPu: "Našice",
        naselje: "Seona",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5176",
        brojPu: "53230",
        redBroj: "5176",
        nazivPu: "Korenica",
        naselje: "Sertić Poljana",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5177",
        brojPu: "23291",
        redBroj: "5177",
        nazivPu: "Sestrunj",
        naselje: "Sestrunj",
        zupanija: "ZADARSKA",
      },
      {
        id: "5178",
        brojPu: "10360",
        redBroj: "5178",
        nazivPu: "Sesvete",
        naselje: "Sesvete",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5179",
        brojPu: "34310",
        redBroj: "5179",
        nazivPu: "Pleternica",
        naselje: "Sesvete",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5180",
        brojPu: "42230",
        redBroj: "5180",
        nazivPu: "Ludbreg",
        naselje: "Sesvete Ludbreške",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5181",
        brojPu: "44000",
        redBroj: "5181",
        nazivPu: "Sisak",
        naselje: "Setuš",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5182",
        brojPu: "43000",
        redBroj: "5182",
        nazivPu: "Bjelovar",
        naselje: "Severin",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5183",
        brojPu: "51326",
        redBroj: "5183",
        nazivPu: "Vrbovsko",
        naselje: "Severin na Kupi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5184",
        brojPu: "48350",
        redBroj: "5184",
        nazivPu: "Đurđevac",
        naselje: "Severovci",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5185",
        brojPu: "21222",
        redBroj: "5185",
        nazivPu: "Marina",
        naselje: "Sevid",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5186",
        brojPu: "43270",
        redBroj: "5186",
        nazivPu: "Veliki Grđevac",
        naselje: "Sibenik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5187",
        brojPu: "44250",
        redBroj: "5187",
        nazivPu: "Petrinja",
        naselje: "Sibić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5188",
        brojPu: "35252",
        redBroj: "5188",
        nazivPu: "Sibinj",
        naselje: "Sibinj",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5189",
        brojPu: "51250",
        redBroj: "5189",
        nazivPu: "Novi Vinodolski",
        naselje: "Sibinj Krmpotski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5190",
        brojPu: "34340",
        redBroj: "5190",
        nazivPu: "Kutjevo",
        naselje: "Sibokovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5191",
        brojPu: "47250",
        redBroj: "5191",
        nazivPu: "Duga Resa",
        naselje: "Siča",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5192",
        brojPu: "21232",
        redBroj: "5192",
        nazivPu: "Dicmo",
        naselje: "Sičane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5193",
        brojPu: "35410",
        redBroj: "5193",
        nazivPu: "Nova Kapela",
        naselje: "Siče",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5194",
        brojPu: "35400",
        redBroj: "5194",
        nazivPu: "Nova Gradiška",
        naselje: "Sičice",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5195",
        brojPu: "44330",
        redBroj: "5195",
        nazivPu: "Novska",
        naselje: "Sigetac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5196",
        brojPu: "48316",
        redBroj: "5196",
        nazivPu: "Đelekovec",
        naselje: "Sigetec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5197",
        brojPu: "42230",
        redBroj: "5197",
        nazivPu: "Ludbreg",
        naselje: "Sigetec Ludbreški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5198",
        brojPu: "35222",
        redBroj: "5198",
        nazivPu: "Gundinci",
        naselje: "Sikirevci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5199",
        brojPu: "23210",
        redBroj: "5199",
        nazivPu: "Biograd na Moru",
        naselje: "Sikovo",
        zupanija: "ZADARSKA",
      },
      {
        id: "5200",
        brojPu: "31207",
        redBroj: "5200",
        nazivPu: "Tenja",
        naselje: "Silaš",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5201",
        brojPu: "23295",
        redBroj: "5201",
        nazivPu: "Silba",
        naselje: "Silba",
        zupanija: "ZADARSKA",
      },
      {
        id: "5202",
        brojPu: "53220",
        redBroj: "5202",
        nazivPu: "Otočac",
        naselje: "Sinac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5203",
        brojPu: "35400",
        redBroj: "5203",
        nazivPu: "Nova Gradiška",
        naselje: "Sinlije",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5204",
        brojPu: "52440",
        redBroj: "5204",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Sinožići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5205",
        brojPu: "21230",
        redBroj: "5205",
        nazivPu: "Sinj",
        naselje: "Sinj",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5206",
        brojPu: "43500",
        redBroj: "5206",
        nazivPu: "Daruvar",
        naselje: "Sirač",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5207",
        brojPu: "52420",
        redBroj: "5207",
        nazivPu: "Buzet",
        naselje: "Sirotići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5208",
        brojPu: "48350",
        redBroj: "5208",
        nazivPu: "Đurđevac",
        naselje: "Sirova Katalena",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5209",
        brojPu: "44000",
        redBroj: "5209",
        nazivPu: "Sisak",
        naselje: "Sisak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5210",
        brojPu: "43240",
        redBroj: "5210",
        nazivPu: "Čazma",
        naselje: "Sišćani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5211",
        brojPu: "21220",
        redBroj: "5211",
        nazivPu: "Trogir",
        naselje: "Sitno",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5212",
        brojPu: "22000",
        redBroj: "5212",
        nazivPu: "Šibenik",
        naselje: "Sitno Donje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5213",
        brojPu: "22320",
        redBroj: "5213",
        nazivPu: "Drniš",
        naselje: "Siverić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5214",
        brojPu: "40000",
        redBroj: "5214",
        nazivPu: "Čakovec",
        naselje: "Sivica",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5215",
        brojPu: "10454",
        redBroj: "5215",
        nazivPu: "Krašić",
        naselje: "Sječevac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5216",
        brojPu: "47000",
        redBroj: "5216",
        nazivPu: "Karlovac",
        naselje: "Sjeničak Lasinjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5217",
        brojPu: "44210",
        redBroj: "5217",
        nazivPu: "Sunja",
        naselje: "Sjeverovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5218",
        brojPu: "47000",
        redBroj: "5218",
        nazivPu: "Karlovac",
        naselje: "Skakavac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5219",
        brojPu: "44400",
        redBroj: "5219",
        nazivPu: "Glina",
        naselje: "Skela",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5220",
        brojPu: "34000",
        redBroj: "5220",
        nazivPu: "Požega",
        naselje: "Skenderovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5221",
        brojPu: "34550",
        redBroj: "5221",
        nazivPu: "Pakrac",
        naselje: "Skenderovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5222",
        brojPu: "52220",
        redBroj: "5222",
        nazivPu: "Labin",
        naselje: "Skitača",
        zupanija: "ISTARSKA",
      },
      {
        id: "5223",
        brojPu: "47250",
        redBroj: "5223",
        nazivPu: "Duga Resa",
        naselje: "Skoblić Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5224",
        brojPu: "51300",
        redBroj: "5224",
        nazivPu: "Delnice",
        naselje: "Skrad",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5225",
        brojPu: "22222",
        redBroj: "5225",
        nazivPu: "Skradin",
        naselje: "Skradin",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5226",
        brojPu: "22222",
        redBroj: "5226",
        nazivPu: "Skradin",
        naselje: "Skradinsko Polje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5227",
        brojPu: "47300",
        redBroj: "5227",
        nazivPu: "Ogulin",
        naselje: "Skradnik",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5228",
        brojPu: "47000",
        redBroj: "5228",
        nazivPu: "Karlovac",
        naselje: "Skradsko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5229",
        brojPu: "51500",
        redBroj: "5229",
        nazivPu: "Krk",
        naselje: "Skrbčići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5230",
        brojPu: "20290",
        redBroj: "5230",
        nazivPu: "Lastovo",
        naselje: "Skrivena Luka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5231",
        brojPu: "47250",
        redBroj: "5231",
        nazivPu: "Duga Resa",
        naselje: "Skukani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5232",
        brojPu: "47250",
        redBroj: "5232",
        nazivPu: "Duga Resa",
        naselje: "Skupica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5233",
        brojPu: "52220",
        redBroj: "5233",
        nazivPu: "Labin",
        naselje: "Skvaranska",
        zupanija: "ISTARSKA",
      },
      {
        id: "5234",
        brojPu: "44430",
        redBroj: "5234",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Slabinja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5235",
        brojPu: "33520",
        redBroj: "5235",
        nazivPu: "Slatina",
        naselje: "Sladojevački Lug",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5236",
        brojPu: "33520",
        redBroj: "5236",
        nazivPu: "Slatina",
        naselje: "Sladojevci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5237",
        brojPu: "32242",
        redBroj: "5237",
        nazivPu: "Slakovci",
        naselje: "Slakovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5238",
        brojPu: "40000",
        redBroj: "5238",
        nazivPu: "Čakovec",
        naselje: "Slakovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5239",
        brojPu: "44250",
        redBroj: "5239",
        nazivPu: "Petrinja",
        naselje: "Slana",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5240",
        brojPu: "10430",
        redBroj: "5240",
        nazivPu: "Samobor",
        naselje: "Slani Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5241",
        brojPu: "49240",
        redBroj: "5241",
        nazivPu: "Donja Stubica",
        naselje: "Slani Potok",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5242",
        brojPu: "20232",
        redBroj: "5242",
        nazivPu: "Slano",
        naselje: "Slano",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5243",
        brojPu: "42230",
        redBroj: "5243",
        nazivPu: "Ludbreg",
        naselje: "Slanje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5244",
        brojPu: "10430",
        redBroj: "5244",
        nazivPu: "Samobor",
        naselje: "Slapnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5245",
        brojPu: "47280",
        redBroj: "5245",
        nazivPu: "Ozalj",
        naselje: "Slapno",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5246",
        brojPu: "10340",
        redBroj: "5246",
        nazivPu: "Vrbovec",
        naselje: "Slatina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5247",
        brojPu: "33520",
        redBroj: "5247",
        nazivPu: "Slatina",
        naselje: "Slatina",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5248",
        brojPu: "44400",
        redBroj: "5248",
        nazivPu: "Glina",
        naselje: "Slatina Pokupska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5249",
        brojPu: "49000",
        redBroj: "5249",
        nazivPu: "Krapina",
        naselje: "Slatina Svedruška",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5250",
        brojPu: "21220",
        redBroj: "5250",
        nazivPu: "Trogir",
        naselje: "Slatine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5251",
        brojPu: "31400",
        redBroj: "5251",
        nazivPu: "Đakovo",
        naselje: "Slatinik Drenjski",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5252",
        brojPu: "33515",
        redBroj: "5252",
        nazivPu: "Orahovica",
        naselje: "Slatinski Drenovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5253",
        brojPu: "10430",
        redBroj: "5253",
        nazivPu: "Samobor",
        naselje: "Slavagora",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5254",
        brojPu: "10450",
        redBroj: "5254",
        nazivPu: "Jastrebarsko",
        naselje: "Slavetić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5255",
        brojPu: "51300",
        redBroj: "5255",
        nazivPu: "Delnice",
        naselje: "Slavica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5256",
        brojPu: "33515",
        redBroj: "5256",
        nazivPu: "Orahovica",
        naselje: "Slavonske Bare",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5257",
        brojPu: "35000",
        redBroj: "5257",
        nazivPu: "Slavonski Brod",
        naselje: "Slavonski Brod",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5258",
        brojPu: "35250",
        redBroj: "5258",
        nazivPu: "Oriovac",
        naselje: "Slavonski Kobaš",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5259",
        brojPu: "35220",
        redBroj: "5259",
        nazivPu: "Slavonski Šamac",
        naselje: "Slavonski Šamac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5260",
        brojPu: "44410",
        redBroj: "5260",
        nazivPu: "Vrginmost",
        naselje: "Slavsko Polje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5261",
        brojPu: "51300",
        redBroj: "5261",
        nazivPu: "Delnice",
        naselje: "Sleme",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5262",
        brojPu: "51300",
        redBroj: "5262",
        nazivPu: "Delnice",
        naselje: "Sleme Skradsko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5263",
        brojPu: "40000",
        redBroj: "5263",
        nazivPu: "Čakovec",
        naselje: "Slemenice",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5264",
        brojPu: "21250",
        redBroj: "5264",
        nazivPu: "Šestanovac",
        naselje: "Slime",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5265",
        brojPu: "42240",
        redBroj: "5265",
        nazivPu: "Ivanec",
        naselje: "Slivarsko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5266",
        brojPu: "23242",
        redBroj: "5266",
        nazivPu: "Posedarje",
        naselje: "Slivnica",
        zupanija: "ZADARSKA",
      },
      {
        id: "5267",
        brojPu: "21270",
        redBroj: "5267",
        nazivPu: "Zagvozd",
        naselje: "Slivno",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5268",
        brojPu: "22000",
        redBroj: "5268",
        nazivPu: "Šibenik",
        naselje: "Slivno",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5269",
        brojPu: "20355",
        redBroj: "5269",
        nazivPu: "Opuzen",
        naselje: "Slivno Ravno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5270",
        brojPu: "49217",
        redBroj: "5270",
        nazivPu: "Krapinske Toplice",
        naselje: "Slivonja Jarek",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5271",
        brojPu: "31400",
        redBroj: "5271",
        nazivPu: "Đakovo",
        naselje: "Slobodna Vlast",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5272",
        brojPu: "35252",
        redBroj: "5272",
        nazivPu: "Sibinj",
        naselje: "Slobodnica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5273",
        brojPu: "34000",
        redBroj: "5273",
        nazivPu: "Požega",
        naselje: "Sloboština",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5274",
        brojPu: "42230",
        redBroj: "5274",
        nazivPu: "Ludbreg",
        naselje: "Slokovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5275",
        brojPu: "44210",
        redBroj: "5275",
        nazivPu: "Sunja",
        naselje: "Slovinci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5276",
        brojPu: "43270",
        redBroj: "5276",
        nazivPu: "Veliki Grđevac",
        naselje: "Slovinska Kovačica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5277",
        brojPu: "52420",
        redBroj: "5277",
        nazivPu: "Buzet",
        naselje: "Slum",
        zupanija: "ISTARSKA",
      },
      {
        id: "5278",
        brojPu: "47240",
        redBroj: "5278",
        nazivPu: "Slunj",
        naselje: "Slunj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5279",
        brojPu: "47240",
        redBroj: "5279",
        nazivPu: "Slunj",
        naselje: "Slunjčica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5280",
        brojPu: "47000",
        redBroj: "5280",
        nazivPu: "Karlovac",
        naselje: "Slunjska Selnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5281",
        brojPu: "47000",
        redBroj: "5281",
        nazivPu: "Karlovac",
        naselje: "Slunjski Moravci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5282",
        brojPu: "49240",
        redBroj: "5282",
        nazivPu: "Donja Stubica",
        naselje: "Sljeme",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5283",
        brojPu: "10000",
        redBroj: "5283",
        nazivPu: "Zagreb",
        naselje: "Sljeme (Medvednica-Tomislavac)",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5284",
        brojPu: "10430",
        redBroj: "5284",
        nazivPu: "Samobor",
        naselje: "Smerovišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5285",
        brojPu: "23420",
        redBroj: "5285",
        nazivPu: "Benkovac",
        naselje: "Smilčić",
        zupanija: "ZADARSKA",
      },
      {
        id: "5286",
        brojPu: "53000",
        redBroj: "5286",
        nazivPu: "Gospić",
        naselje: "Smiljan",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5287",
        brojPu: "53000",
        redBroj: "5287",
        nazivPu: "Gospić",
        naselje: "Smiljansko Polje",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5288",
        brojPu: "51326",
        redBroj: "5288",
        nazivPu: "Vrbovsko",
        naselje: "Smišljak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5289",
        brojPu: "51326",
        redBroj: "5289",
        nazivPu: "Vrbovsko",
        naselje: "Smišljak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5290",
        brojPu: "23222",
        redBroj: "5290",
        nazivPu: "Zemunik",
        naselje: "Smoković",
        zupanija: "ZADARSKA",
      },
      {
        id: "5291",
        brojPu: "20230",
        redBroj: "5291",
        nazivPu: "Ston",
        naselje: "Smokovljani",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5292",
        brojPu: "23440",
        redBroj: "5292",
        nazivPu: "Gračac",
        naselje: "Smokrić",
        zupanija: "ZADARSKA",
      },
      {
        id: "5293",
        brojPu: "20271",
        redBroj: "5293",
        nazivPu: "Blato",
        naselje: "Smokvica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5294",
        brojPu: "23250",
        redBroj: "5294",
        nazivPu: "Pag",
        naselje: "Smokvica",
        zupanija: "ZADARSKA",
      },
      {
        id: "5295",
        brojPu: "51250",
        redBroj: "5295",
        nazivPu: "Novi Vinodolski",
        naselje: "Smokvica Krmpotska",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5296",
        brojPu: "52440",
        redBroj: "5296",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Smolici",
        zupanija: "ISTARSKA",
      },
      {
        id: "5297",
        brojPu: "21292",
        redBroj: "5297",
        nazivPu: "Srinjine",
        naselje: "Smolonje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5298",
        brojPu: "53230",
        redBroj: "5298",
        nazivPu: "Korenica",
        naselje: "Smoljanac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5299",
        brojPu: "52341",
        redBroj: "5299",
        nazivPu: "Žminj",
        naselje: "Smoljanci",
        zupanija: "ISTARSKA",
      },
      {
        id: "5300",
        brojPu: "34000",
        redBroj: "5300",
        nazivPu: "Požega",
        naselje: "Smoljanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5301",
        brojPu: "22300",
        redBroj: "5301",
        nazivPu: "Knin",
        naselje: "Smrdelje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5302",
        brojPu: "51306",
        redBroj: "5302",
        nazivPu: "Čabar",
        naselje: "Smrečje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5303",
        brojPu: "51306",
        redBroj: "5303",
        nazivPu: "Čabar",
        naselje: "Smrekari",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5304",
        brojPu: "35430",
        redBroj: "5304",
        nazivPu: "Okučani",
        naselje: "Smrtić",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5305",
        brojPu: "33520",
        redBroj: "5305",
        nazivPu: "Slatina",
        naselje: "Smude",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5306",
        brojPu: "52220",
        redBroj: "5306",
        nazivPu: "Labin",
        naselje: "Snašići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5307",
        brojPu: "47240",
        redBroj: "5307",
        nazivPu: "Slunj",
        naselje: "Snos",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5308",
        brojPu: "10360",
        redBroj: "5308",
        nazivPu: "Sesvete",
        naselje: "Soblinec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5309",
        brojPu: "10310",
        redBroj: "5309",
        nazivPu: "Ivanić Grad",
        naselje: "Sobočani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5310",
        brojPu: "51218",
        redBroj: "5310",
        nazivPu: "Dražice",
        naselje: "Soboli",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5311",
        brojPu: "20225",
        redBroj: "5311",
        nazivPu: "Babino Polje",
        naselje: "Sobra",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5312",
        brojPu: "44440",
        redBroj: "5312",
        nazivPu: "Dvor",
        naselje: "Sočanica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5313",
        brojPu: "51306",
        redBroj: "5313",
        nazivPu: "Čabar",
        naselje: "Sokoli",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5314",
        brojPu: "31300",
        redBroj: "5314",
        nazivPu: "Beli Manastir",
        naselje: "Sokolovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5315",
        brojPu: "43500",
        redBroj: "5315",
        nazivPu: "Daruvar",
        naselje: "Sokolovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5316",
        brojPu: "48000",
        redBroj: "5316",
        nazivPu: "Koprivnica",
        naselje: "Sokolovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5317",
        brojPu: "47280",
        redBroj: "5317",
        nazivPu: "Ozalj",
        naselje: "Soldatići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5318",
        brojPu: "21210",
        redBroj: "5318",
        nazivPu: "Solin",
        naselje: "Solin",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5319",
        brojPu: "20207",
        redBroj: "5319",
        nazivPu: "Mlini",
        naselje: "Soline",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5320",
        brojPu: "23287",
        redBroj: "5320",
        nazivPu: "Veli Rat",
        naselje: "Soline",
        zupanija: "ZADARSKA",
      },
      {
        id: "5321",
        brojPu: "47250",
        redBroj: "5321",
        nazivPu: "Duga Resa",
        naselje: "Soline",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5322",
        brojPu: "51511",
        redBroj: "5322",
        nazivPu: "Malinska",
        naselje: "Soline",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5323",
        brojPu: "32257",
        redBroj: "5323",
        nazivPu: "Drenovci",
        naselje: "Soljani",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5324",
        brojPu: "22222",
        redBroj: "5324",
        nazivPu: "Skradin",
        naselje: "Sonković",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5325",
        brojPu: "10361",
        redBroj: "5325",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Sop",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5326",
        brojPu: "10410",
        redBroj: "5326",
        nazivPu: "Velika Gorica",
        naselje: "Sop Bukevski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5327",
        brojPu: "51300",
        redBroj: "5327",
        nazivPu: "Delnice",
        naselje: "Sopač",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5328",
        brojPu: "47000",
        redBroj: "5328",
        nazivPu: "Karlovac",
        naselje: "Sopčić Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5329",
        brojPu: "33520",
        redBroj: "5329",
        nazivPu: "Slatina",
        naselje: "Sopjanska Greda",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5330",
        brojPu: "33520",
        redBroj: "5330",
        nazivPu: "Slatina",
        naselje: "Sopje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5331",
        brojPu: "49218",
        redBroj: "5331",
        nazivPu: "Pregrada",
        naselje: "Sopot",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5332",
        brojPu: "10454",
        redBroj: "5332",
        nazivPu: "Krašić",
        naselje: "Sopote",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5333",
        brojPu: "10454",
        redBroj: "5333",
        nazivPu: "Krašić",
        naselje: "Sošice",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5334",
        brojPu: "52341",
        redBroj: "5334",
        nazivPu: "Žminj",
        naselje: "Sošići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5335",
        brojPu: "32000",
        redBroj: "5335",
        nazivPu: "Vukovar",
        naselje: "Sotin",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5336",
        brojPu: "43240",
        redBroj: "5336",
        nazivPu: "Čazma",
        naselje: "Sovari",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5337",
        brojPu: "52420",
        redBroj: "5337",
        nazivPu: "Buzet",
        naselje: "Sovinjak",
        zupanija: "ISTARSKA",
      },
      {
        id: "5338",
        brojPu: "52420",
        redBroj: "5338",
        nazivPu: "Buzet",
        naselje: "Sovinjska Brda",
        zupanija: "ISTARSKA",
      },
      {
        id: "5339",
        brojPu: "52420",
        redBroj: "5339",
        nazivPu: "Buzet",
        naselje: "Sovinjsko Polje",
        zupanija: "ISTARSKA",
      },
      {
        id: "5340",
        brojPu: "33410",
        redBroj: "5340",
        nazivPu: "Suhopolje",
        naselje: "Sovjak",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5341",
        brojPu: "34340",
        redBroj: "5341",
        nazivPu: "Kutjevo",
        naselje: "Sovski Dol",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5342",
        brojPu: "47250",
        redBroj: "5342",
        nazivPu: "Duga Resa",
        naselje: "Spahići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5343",
        brojPu: "20230",
        redBroj: "5343",
        nazivPu: "Ston",
        naselje: "Sparagovići",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5344",
        brojPu: "47240",
        redBroj: "5344",
        nazivPu: "Slunj",
        naselje: "Sparednjak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5345",
        brojPu: "21000",
        redBroj: "5345",
        nazivPu: "Split",
        naselje: "Split",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5346",
        brojPu: "21400",
        redBroj: "5346",
        nazivPu: "Supetar",
        naselje: "Splitska",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5347",
        brojPu: "47000",
        redBroj: "5347",
        nazivPu: "Karlovac",
        naselje: "Sračak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5348",
        brojPu: "42000",
        redBroj: "5348",
        nazivPu: "Varaždin",
        naselje: "Sračinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5349",
        brojPu: "53250",
        redBroj: "5349",
        nazivPu: "Donji Lapac",
        naselje: "Srb",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5350",
        brojPu: "48350",
        redBroj: "5350",
        nazivPu: "Đurđevac",
        naselje: "Srdinac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5351",
        brojPu: "20207",
        redBroj: "5351",
        nazivPu: "Mlini",
        naselje: "Srebreno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5352",
        brojPu: "52440",
        redBroj: "5352",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Srebrnići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5353",
        brojPu: "10431",
        redBroj: "5353",
        nazivPu: "Sveta Nedelja",
        naselje: "Srebrnjak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5354",
        brojPu: "35214",
        redBroj: "5354",
        nazivPu: "Donji Andrijevci",
        naselje: "Sredanci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5355",
        brojPu: "43000",
        redBroj: "5355",
        nazivPu: "Bjelovar",
        naselje: "Sredice Gornje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5356",
        brojPu: "43000",
        redBroj: "5356",
        nazivPu: "Bjelovar",
        naselje: "Srednja Diklenica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5357",
        brojPu: "51306",
        redBroj: "5357",
        nazivPu: "Čabar",
        naselje: "Srednja Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5358",
        brojPu: "53234",
        redBroj: "5358",
        nazivPu: "Udbina",
        naselje: "Srednja Gora",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5359",
        brojPu: "51300",
        redBroj: "5359",
        nazivPu: "Delnice",
        naselje: "Srednja Krašićevica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5360",
        brojPu: "44430",
        redBroj: "5360",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Srednja Meminska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5361",
        brojPu: "10340",
        redBroj: "5361",
        nazivPu: "Vrbovec",
        naselje: "Srednja Velika",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5362",
        brojPu: "10450",
        redBroj: "5362",
        nazivPu: "Jastrebarsko",
        naselje: "Srednjak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5363",
        brojPu: "44250",
        redBroj: "5363",
        nazivPu: "Petrinja",
        naselje: "Srednje Mokrice",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5364",
        brojPu: "47000",
        redBroj: "5364",
        nazivPu: "Karlovac",
        naselje: "Srednje Prilišće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5365",
        brojPu: "21430",
        redBroj: "5365",
        nazivPu: "Grohote",
        naselje: "Srednje Selo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5366",
        brojPu: "34310",
        redBroj: "5366",
        nazivPu: "Pleternica",
        naselje: "Srednje Selo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5367",
        brojPu: "47240",
        redBroj: "5367",
        nazivPu: "Slunj",
        naselje: "Srednje Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5368",
        brojPu: "48260",
        redBroj: "5368",
        nazivPu: "Križevci",
        naselje: "Srednji Dubovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5369",
        brojPu: "34550",
        redBroj: "5369",
        nazivPu: "Pakrac",
        naselje: "Srednji Grahovljani",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5370",
        brojPu: "35410",
        redBroj: "5370",
        nazivPu: "Nova Kapela",
        naselje: "Srednji Lipovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5371",
        brojPu: "43000",
        redBroj: "5371",
        nazivPu: "Bjelovar",
        naselje: "Srednji Mosti",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5372",
        brojPu: "47250",
        redBroj: "5372",
        nazivPu: "Duga Resa",
        naselje: "Srednji Poloj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5373",
        brojPu: "20246",
        redBroj: "5373",
        nazivPu: "Janjina",
        naselje: "Sreser",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5374",
        brojPu: "21204",
        redBroj: "5374",
        nazivPu: "Dugopolje",
        naselje: "Srijane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5375",
        brojPu: "43000",
        redBroj: "5375",
        nazivPu: "Bjelovar",
        naselje: "Srijedska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5376",
        brojPu: "48000",
        redBroj: "5376",
        nazivPu: "Koprivnica",
        naselje: "Srijem",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5377",
        brojPu: "32242",
        redBroj: "5377",
        nazivPu: "Slakovci",
        naselje: "Srijemske Laze",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5378",
        brojPu: "22211",
        redBroj: "5378",
        nazivPu: "Vodice",
        naselje: "Srima",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5379",
        brojPu: "21292",
        redBroj: "5379",
        nazivPu: "Srinjine",
        naselje: "Srinjine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5380",
        brojPu: "51216",
        redBroj: "5380",
        nazivPu: "Viškovo",
        naselje: "Sroki",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5381",
        brojPu: "47280",
        redBroj: "5381",
        nazivPu: "Ozalj",
        naselje: "Sršići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5382",
        brojPu: "51511",
        redBroj: "5382",
        nazivPu: "Malinska",
        naselje: "Sršići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5383",
        brojPu: "53260",
        redBroj: "5383",
        nazivPu: "Brinje",
        naselje: "Stajnica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5384",
        brojPu: "52440",
        redBroj: "5384",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Stancija Vodopija",
        zupanija: "ISTARSKA",
      },
      {
        id: "5385",
        brojPu: "10370",
        redBroj: "5385",
        nazivPu: "Dugo Selo",
        naselje: "Stančić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5386",
        brojPu: "43000",
        redBroj: "5386",
        nazivPu: "Bjelovar",
        naselje: "Stančići",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5387",
        brojPu: "40313",
        redBroj: "5387",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Stanetinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5388",
        brojPu: "52420",
        redBroj: "5388",
        nazivPu: "Buzet",
        naselje: "Stanica Roč",
        zupanija: "ISTARSKA",
      },
      {
        id: "5389",
        brojPu: "10454",
        redBroj: "5389",
        nazivPu: "Krašić",
        naselje: "Staničići Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5390",
        brojPu: "51557",
        redBroj: "5390",
        nazivPu: "Cres",
        naselje: "Stanić",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5391",
        brojPu: "44440",
        redBroj: "5391",
        nazivPu: "Dvor",
        naselje: "Stanić Polje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5392",
        brojPu: "21310",
        redBroj: "5392",
        nazivPu: "Omiš",
        naselje: "Stanići",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5393",
        brojPu: "43000",
        redBroj: "5393",
        nazivPu: "Bjelovar",
        naselje: "Stanići",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5394",
        brojPu: "52440",
        redBroj: "5394",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Staniši",
        zupanija: "ISTARSKA",
      },
      {
        id: "5395",
        brojPu: "52220",
        redBroj: "5395",
        nazivPu: "Labin",
        naselje: "Stanišovi",
        zupanija: "ISTARSKA",
      },
      {
        id: "5396",
        brojPu: "53288",
        redBroj: "5396",
        nazivPu: "Karlobag",
        naselje: "Staništa",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5397",
        brojPu: "44400",
        redBroj: "5397",
        nazivPu: "Glina",
        naselje: "Stankovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5398",
        brojPu: "23422",
        redBroj: "5398",
        nazivPu: "Stankovci",
        naselje: "Stankovci",
        zupanija: "ZADARSKA",
      },
      {
        id: "5399",
        brojPu: "47000",
        redBroj: "5399",
        nazivPu: "Karlovac",
        naselje: "Stankovci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5400",
        brojPu: "20250",
        redBroj: "5400",
        nazivPu: "Orebić",
        naselje: "Stanković",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5401",
        brojPu: "10450",
        redBroj: "5401",
        nazivPu: "Jastrebarsko",
        naselje: "Stankovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5402",
        brojPu: "51500",
        redBroj: "5402",
        nazivPu: "Krk",
        naselje: "Stara Baška",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5403",
        brojPu: "43000",
        redBroj: "5403",
        nazivPu: "Bjelovar",
        naselje: "Stara Diklenica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5404",
        brojPu: "44000",
        redBroj: "5404",
        nazivPu: "Sisak",
        naselje: "Stara Drenčina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5405",
        brojPu: "35430",
        redBroj: "5405",
        nazivPu: "Okučani",
        naselje: "Stara Gradiška",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5406",
        brojPu: "33515",
        redBroj: "5406",
        nazivPu: "Orahovica",
        naselje: "Stara Jošava",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5407",
        brojPu: "10342",
        redBroj: "5407",
        nazivPu: "Dubrava",
        naselje: "Stara Kapela",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5408",
        brojPu: "35410",
        redBroj: "5408",
        nazivPu: "Nova Kapela",
        naselje: "Stara Kapela",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5409",
        brojPu: "43500",
        redBroj: "5409",
        nazivPu: "Daruvar",
        naselje: "Stara Krivaja",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5410",
        brojPu: "47240",
        redBroj: "5410",
        nazivPu: "Slunj",
        naselje: "Stara Kršlja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5411",
        brojPu: "34000",
        redBroj: "5411",
        nazivPu: "Požega",
        naselje: "Stara Lipa",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5412",
        brojPu: "34340",
        redBroj: "5412",
        nazivPu: "Kutjevo",
        naselje: "Stara Ljeskovica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5413",
        brojPu: "10310",
        redBroj: "5413",
        nazivPu: "Ivanić Grad",
        naselje: "Stara Marča",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5414",
        brojPu: "53291",
        redBroj: "5414",
        nazivPu: "Novalja",
        naselje: "Stara Novalja",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5415",
        brojPu: "43000",
        redBroj: "5415",
        nazivPu: "Bjelovar",
        naselje: "Stara Plošćica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5416",
        brojPu: "43270",
        redBroj: "5416",
        nazivPu: "Veliki Grđevac",
        naselje: "Stara Rača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5417",
        brojPu: "44330",
        redBroj: "5417",
        nazivPu: "Novska",
        naselje: "Stara Subocka",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5418",
        brojPu: "51300",
        redBroj: "5418",
        nazivPu: "Delnice",
        naselje: "Stara Sušica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5419",
        brojPu: "23250",
        redBroj: "5419",
        nazivPu: "Pag",
        naselje: "Stara Vas",
        zupanija: "ZADARSKA",
      },
      {
        id: "5420",
        brojPu: "49216",
        redBroj: "5420",
        nazivPu: "Desinić",
        naselje: "Stara Ves Košnička",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5421",
        brojPu: "49000",
        redBroj: "5421",
        nazivPu: "Krapina",
        naselje: "Stara Ves Petrovska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5422",
        brojPu: "48260",
        redBroj: "5422",
        nazivPu: "Križevci",
        naselje: "Stara Ves Ravenska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5423",
        brojPu: "35420",
        redBroj: "5423",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Starci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5424",
        brojPu: "43000",
        redBroj: "5424",
        nazivPu: "Bjelovar",
        naselje: "Starčevljani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5425",
        brojPu: "43000",
        redBroj: "5425",
        nazivPu: "Bjelovar",
        naselje: "Stare Plavnice",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5426",
        brojPu: "48260",
        redBroj: "5426",
        nazivPu: "Križevci",
        naselje: "Stari Bošnjani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5427",
        brojPu: "44000",
        redBroj: "5427",
        nazivPu: "Sisak",
        naselje: "Stari Brod",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5428",
        brojPu: "44000",
        redBroj: "5428",
        nazivPu: "Sisak",
        naselje: "Stari Farkašić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5429",
        brojPu: "10342",
        redBroj: "5429",
        nazivPu: "Dubrava",
        naselje: "Stari Glog",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5430",
        brojPu: "49250",
        redBroj: "5430",
        nazivPu: "Zlatar",
        naselje: "Stari Golubovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5431",
        brojPu: "44330",
        redBroj: "5431",
        nazivPu: "Novska",
        naselje: "Stari Grabovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5432",
        brojPu: "21460",
        redBroj: "5432",
        nazivPu: "Stari Grad",
        naselje: "Stari Grad",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5433",
        brojPu: "10454",
        redBroj: "5433",
        nazivPu: "Krašić",
        naselje: "Stari Grad Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5434",
        brojPu: "33405",
        redBroj: "5434",
        nazivPu: "Pitomača",
        naselje: "Stari Gradac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5435",
        brojPu: "32242",
        redBroj: "5435",
        nazivPu: "Slakovci",
        naselje: "Stari Jankovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5436",
        brojPu: "51300",
        redBroj: "5436",
        nazivPu: "Delnice",
        naselje: "Stari Laz",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5437",
        brojPu: "51326",
        redBroj: "5437",
        nazivPu: "Vrbovsko",
        naselje: "Stari Lazi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5438",
        brojPu: "34550",
        redBroj: "5438",
        nazivPu: "Pakrac",
        naselje: "Stari Majur",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5439",
        brojPu: "32284",
        redBroj: "5439",
        nazivPu: "Stari Mikanovci",
        naselje: "Stari Mikanovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5440",
        brojPu: "43000",
        redBroj: "5440",
        nazivPu: "Bjelovar",
        naselje: "Stari Pavljani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5441",
        brojPu: "35214",
        redBroj: "5441",
        nazivPu: "Donji Andrijevci",
        naselje: "Stari Perkovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5442",
        brojPu: "43000",
        redBroj: "5442",
        nazivPu: "Bjelovar",
        naselje: "Stari Skucani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5443",
        brojPu: "35252",
        redBroj: "5443",
        nazivPu: "Sibinj",
        naselje: "Stari Slatinik",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5444",
        brojPu: "34340",
        redBroj: "5444",
        nazivPu: "Kutjevo",
        naselje: "Stari Zdenkovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5445",
        brojPu: "52440",
        redBroj: "5445",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Starići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5446",
        brojPu: "23244",
        redBroj: "5446",
        nazivPu: "Starigrad Paklenica",
        naselje: "Starigrad",
        zupanija: "ZADARSKA",
      },
      {
        id: "5447",
        brojPu: "48000",
        redBroj: "5447",
        nazivPu: "Koprivnica",
        naselje: "Starigrad",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5448",
        brojPu: "53270",
        redBroj: "5448",
        nazivPu: "Senj",
        naselje: "Starigrad",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5449",
        brojPu: "33520",
        redBroj: "5449",
        nazivPu: "Slatina",
        naselje: "Starin",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5450",
        brojPu: "43240",
        redBroj: "5450",
        nazivPu: "Čazma",
        naselje: "Starine",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5451",
        brojPu: "10257",
        redBroj: "5451",
        nazivPu: "Brezovica",
        naselje: "Starjak",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5452",
        brojPu: "10410",
        redBroj: "5452",
        nazivPu: "Velika Gorica",
        naselje: "Staro Čiče",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5453",
        brojPu: "33515",
        redBroj: "5453",
        nazivPu: "Orahovica",
        naselje: "Staro Petrovo Polje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5454",
        brojPu: "35420",
        redBroj: "5454",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Staro Petrovo Selo",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5455",
        brojPu: "44000",
        redBroj: "5455",
        nazivPu: "Sisak",
        naselje: "Staro Pračno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5456",
        brojPu: "44010",
        redBroj: "5456",
        nazivPu: "Sisak-Caprag",
        naselje: "Staro Selo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5457",
        brojPu: "53220",
        redBroj: "5457",
        nazivPu: "Otočac",
        naselje: "Staro Selo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5458",
        brojPu: "44400",
        redBroj: "5458",
        nazivPu: "Glina",
        naselje: "Staro Selo Topusko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5459",
        brojPu: "43240",
        redBroj: "5459",
        nazivPu: "Čazma",
        naselje: "Staro Štefanje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5460",
        brojPu: "35214",
        redBroj: "5460",
        nazivPu: "Donji Andrijevci",
        naselje: "Staro Topolje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5461",
        brojPu: "33405",
        redBroj: "5461",
        nazivPu: "Pitomača",
        naselje: "Starogradački Marof",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5462",
        brojPu: "20340",
        redBroj: "5462",
        nazivPu: "Ploče",
        naselje: "Staševica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5463",
        brojPu: "44210",
        redBroj: "5463",
        nazivPu: "Sunja",
        naselje: "Staza",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5464",
        brojPu: "42240",
        redBroj: "5464",
        nazivPu: "Ivanec",
        naselje: "Stažnjevec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5465",
        brojPu: "52220",
        redBroj: "5465",
        nazivPu: "Labin",
        naselje: "Stepčići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5466",
        brojPu: "21276",
        redBroj: "5466",
        nazivPu: "Vrgorac",
        naselje: "Stilja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5467",
        brojPu: "53270",
        redBroj: "5467",
        nazivPu: "Senj",
        naselje: "Stinica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5468",
        brojPu: "44410",
        redBroj: "5468",
        nazivPu: "Vrginmost",
        naselje: "Stipan",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5469",
        brojPu: "31500",
        redBroj: "5469",
        nazivPu: "Našice",
        naselje: "Stipanovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5470",
        brojPu: "49218",
        redBroj: "5470",
        nazivPu: "Pregrada",
        naselje: "Stipernica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5471",
        brojPu: "51556",
        redBroj: "5471",
        nazivPu: "Martinšćica",
        naselje: "Stivan",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5472",
        brojPu: "22202",
        redBroj: "5472",
        nazivPu: "Primošten",
        naselje: "Stivašnica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5473",
        brojPu: "21311",
        redBroj: "5473",
        nazivPu: "Stobreč",
        naselje: "Stobreč",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5474",
        brojPu: "47280",
        redBroj: "5474",
        nazivPu: "Ozalj",
        naselje: "Stojavnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5475",
        brojPu: "34340",
        redBroj: "5475",
        nazivPu: "Kutjevo",
        naselje: "Stojčinovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5476",
        brojPu: "10430",
        redBroj: "5476",
        nazivPu: "Samobor",
        naselje: "Stojdraga",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5477",
        brojPu: "47240",
        redBroj: "5477",
        nazivPu: "Slunj",
        naselje: "Stojmerić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5478",
        brojPu: "53270",
        redBroj: "5478",
        nazivPu: "Senj",
        naselje: "Stolac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5479",
        brojPu: "21430",
        redBroj: "5479",
        nazivPu: "Grohote",
        naselje: "Stomorska",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5480",
        brojPu: "20230",
        redBroj: "5480",
        nazivPu: "Ston",
        naselje: "Ston",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5481",
        brojPu: "49000",
        redBroj: "5481",
        nazivPu: "Krapina",
        naselje: "Strahinje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5482",
        brojPu: "49000",
        redBroj: "5482",
        nazivPu: "Krapina",
        naselje: "Strahinje Radobojsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5483",
        brojPu: "40000",
        redBroj: "5483",
        nazivPu: "Čakovec",
        naselje: "Strahoninec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5484",
        brojPu: "52420",
        redBroj: "5484",
        nazivPu: "Buzet",
        naselje: "Strana",
        zupanija: "ISTARSKA",
      },
      {
        id: "5485",
        brojPu: "52440",
        redBroj: "5485",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Stranići kod Lovreča",
        zupanija: "ISTARSKA",
      },
      {
        id: "5486",
        brojPu: "52440",
        redBroj: "5486",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Stranići kod Nove Vasi",
        zupanija: "ISTARSKA",
      },
      {
        id: "5487",
        brojPu: "44250",
        redBroj: "5487",
        nazivPu: "Petrinja",
        naselje: "Strašnik",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5488",
        brojPu: "20210",
        redBroj: "5488",
        nazivPu: "Cavtat",
        naselje: "Stravča",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5489",
        brojPu: "47250",
        redBroj: "5489",
        nazivPu: "Duga Resa",
        naselje: "Straža",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5490",
        brojPu: "49000",
        redBroj: "5490",
        nazivPu: "Krapina",
        naselje: "Straža Krapinska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5491",
        brojPu: "43500",
        redBroj: "5491",
        nazivPu: "Daruvar",
        naselje: "Stražanac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5492",
        brojPu: "44250",
        redBroj: "5492",
        nazivPu: "Petrinja",
        naselje: "Stražbenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5493",
        brojPu: "34000",
        redBroj: "5493",
        nazivPu: "Požega",
        naselje: "Stražeman",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5494",
        brojPu: "40000",
        redBroj: "5494",
        nazivPu: "Čakovec",
        naselje: "Strelec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5495",
        brojPu: "44000",
        redBroj: "5495",
        nazivPu: "Sisak",
        naselje: "Strelečko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5496",
        brojPu: "10414",
        redBroj: "5496",
        nazivPu: "Pokupsko",
        naselje: "Strezojevo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5497",
        brojPu: "47250",
        redBroj: "5497",
        nazivPu: "Duga Resa",
        naselje: "Strgari",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5498",
        brojPu: "34000",
        redBroj: "5498",
        nazivPu: "Požega",
        naselje: "Striježevica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5499",
        brojPu: "51511",
        redBroj: "5499",
        nazivPu: "Malinska",
        naselje: "Strilčići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5500",
        brojPu: "21240",
        redBroj: "5500",
        nazivPu: "Trilj",
        naselje: "Strizirep",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5501",
        brojPu: "31400",
        redBroj: "5501",
        nazivPu: "Đakovo",
        naselje: "Strizivojna",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5502",
        brojPu: "34550",
        redBroj: "5502",
        nazivPu: "Pakrac",
        naselje: "Strižičevac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5503",
        brojPu: "10454",
        redBroj: "5503",
        nazivPu: "Krašić",
        naselje: "Strmac Pribićki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5504",
        brojPu: "47240",
        redBroj: "5504",
        nazivPu: "Slunj",
        naselje: "Strmačka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5505",
        brojPu: "10020",
        redBroj: "5505",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Strmec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5506",
        brojPu: "10340",
        redBroj: "5506",
        nazivPu: "Vrbovec",
        naselje: "Strmec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5507",
        brojPu: "10431",
        redBroj: "5507",
        nazivPu: "Sveta Nedelja",
        naselje: "Strmec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5508",
        brojPu: "49210",
        redBroj: "5508",
        nazivPu: "Zabok",
        naselje: "Strmec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5509",
        brojPu: "10410",
        redBroj: "5509",
        nazivPu: "Velika Gorica",
        naselje: "Strmec Bukevski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5510",
        brojPu: "49231",
        redBroj: "5510",
        nazivPu: "Hum na Sutli",
        naselje: "Strmec Humski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5511",
        brojPu: "42208",
        redBroj: "5511",
        nazivPu: "Cestica",
        naselje: "Strmec Podravski",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5512",
        brojPu: "42220",
        redBroj: "5512",
        nazivPu: "Novi Marof",
        naselje: "Strmec Remetinečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5513",
        brojPu: "49240",
        redBroj: "5513",
        nazivPu: "Donja Stubica",
        naselje: "Strmec Stubički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5514",
        brojPu: "49290",
        redBroj: "5514",
        nazivPu: "Klanjec",
        naselje: "Strmec Sutlanski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5515",
        brojPu: "44210",
        redBroj: "5515",
        nazivPu: "Sunja",
        naselje: "Strmen",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5516",
        brojPu: "21240",
        redBroj: "5516",
        nazivPu: "Trilj",
        naselje: "Strmendolac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5517",
        brojPu: "22300",
        redBroj: "5517",
        nazivPu: "Knin",
        naselje: "Strmica",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5518",
        brojPu: "32257",
        redBroj: "5518",
        nazivPu: "Drenovci",
        naselje: "Strošinci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5519",
        brojPu: "52440",
        redBroj: "5519",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Strpačići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5520",
        brojPu: "42230",
        redBroj: "5520",
        nazivPu: "Ludbreg",
        naselje: "Struga",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5521",
        brojPu: "44440",
        redBroj: "5521",
        nazivPu: "Dvor",
        naselje: "Struga Banska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5522",
        brojPu: "10361",
        redBroj: "5522",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Struga Nartska",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5523",
        brojPu: "35214",
        redBroj: "5523",
        nazivPu: "Donji Andrijevci",
        naselje: "Stružani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5524",
        brojPu: "44317",
        redBroj: "5524",
        nazivPu: "Popovača",
        naselje: "Stružec",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5525",
        brojPu: "10410",
        redBroj: "5525",
        nazivPu: "Velika Gorica",
        naselje: "Stružec Posavski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5526",
        brojPu: "44430",
        redBroj: "5526",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Stubalj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5527",
        brojPu: "51326",
        redBroj: "5527",
        nazivPu: "Vrbovsko",
        naselje: "Stubica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5528",
        brojPu: "49243",
        redBroj: "5528",
        nazivPu: "Oroslavje",
        naselje: "Stubička Slatina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5529",
        brojPu: "49240",
        redBroj: "5529",
        nazivPu: "Donja Stubica",
        naselje: "Stubičke Toplice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5530",
        brojPu: "51217",
        redBroj: "5530",
        nazivPu: "Klana",
        naselje: "Studena",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5531",
        brojPu: "21256",
        redBroj: "5531",
        nazivPu: "Cista Provo",
        naselje: "Studenci",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5532",
        brojPu: "53202",
        redBroj: "5532",
        nazivPu: "Perušić",
        naselje: "Studenci",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5533",
        brojPu: "20230",
        redBroj: "5533",
        nazivPu: "Ston",
        naselje: "Stupa",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5534",
        brojPu: "10454",
        redBroj: "5534",
        nazivPu: "Krašić",
        naselje: "Stupe",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5535",
        brojPu: "35000",
        redBroj: "5535",
        nazivPu: "Slavonski Brod",
        naselje: "Stupnički Kuti",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5536",
        brojPu: "10255",
        redBroj: "5536",
        nazivPu: "Gornji Stupnik",
        naselje: "Stupnički Obrež",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5537",
        brojPu: "44000",
        redBroj: "5537",
        nazivPu: "Sisak",
        naselje: "Stupno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5538",
        brojPu: "44320",
        redBroj: "5538",
        nazivPu: "Kutina",
        naselje: "Stupovača",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5539",
        brojPu: "34551",
        redBroj: "5539",
        nazivPu: "Lipik",
        naselje: "Subocka",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5540",
        brojPu: "48000",
        redBroj: "5540",
        nazivPu: "Koprivnica",
        naselje: "Subotica Podravska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5541",
        brojPu: "51417",
        redBroj: "5541",
        nazivPu: "Mošćenička Draga",
        naselje: "Sučići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5542",
        brojPu: "21469",
        redBroj: "5542",
        nazivPu: "Sućuraj",
        naselje: "Sućuraj",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5543",
        brojPu: "31300",
        redBroj: "5543",
        nazivPu: "Beli Manastir",
        naselje: "Sudaraž",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5544",
        brojPu: "42230",
        redBroj: "5544",
        nazivPu: "Ludbreg",
        naselje: "Sudovčina",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5545",
        brojPu: "48260",
        redBroj: "5545",
        nazivPu: "Križevci",
        naselje: "Sudovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5546",
        brojPu: "20223",
        redBroj: "5546",
        nazivPu: "Šipanska Luka",
        naselje: "Suđurađ",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5547",
        brojPu: "48350",
        redBroj: "5547",
        nazivPu: "Đurđevac",
        naselje: "Suha Katalena",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5548",
        brojPu: "33515",
        redBroj: "5548",
        nazivPu: "Orahovica",
        naselje: "Suha Mlaka",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5549",
        brojPu: "21230",
        redBroj: "5549",
        nazivPu: "Sinj",
        naselje: "Suhač",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5550",
        brojPu: "43240",
        redBroj: "5550",
        nazivPu: "Čazma",
        naselje: "Suhaja",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5551",
        brojPu: "47220",
        redBroj: "5551",
        nazivPu: "Vojnić",
        naselje: "Suhodol Budački",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5552",
        brojPu: "10380",
        redBroj: "5552",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Suhodol Zelinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5553",
        brojPu: "33410",
        redBroj: "5553",
        nazivPu: "Suhopolje",
        naselje: "Suhopolje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5554",
        brojPu: "51300",
        redBroj: "5554",
        nazivPu: "Delnice",
        naselje: "Suhor",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5555",
        brojPu: "23241",
        redBroj: "5555",
        nazivPu: "Poličnik",
        naselje: "Suhovare",
        zupanija: "ZADARSKA",
      },
      {
        id: "5556",
        brojPu: "22320",
        redBroj: "5556",
        nazivPu: "Drniš",
        naselje: "Suknovci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5557",
        brojPu: "23206",
        redBroj: "5557",
        nazivPu: "Sukošan",
        naselje: "Sukošan",
        zupanija: "ZADARSKA",
      },
      {
        id: "5558",
        brojPu: "34310",
        redBroj: "5558",
        nazivPu: "Pleternica",
        naselje: "Sulkovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5559",
        brojPu: "21425",
        redBroj: "5559",
        nazivPu: "Selca",
        naselje: "Sumartin",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5560",
        brojPu: "51300",
        redBroj: "5560",
        nazivPu: "Delnice",
        naselje: "Sunger",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5561",
        brojPu: "44210",
        redBroj: "5561",
        nazivPu: "Sunja",
        naselje: "Sunja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5562",
        brojPu: "21400",
        redBroj: "5562",
        nazivPu: "Supetar",
        naselje: "Supetar",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5563",
        brojPu: "51280",
        redBroj: "5563",
        nazivPu: "Rab",
        naselje: "Supetarska Draga",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5564",
        brojPu: "51561",
        redBroj: "5564",
        nazivPu: "Susak",
        naselje: "Susak",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5565",
        brojPu: "20236",
        redBroj: "5565",
        nazivPu: "Mokošica",
        naselje: "Sustjepan",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5566",
        brojPu: "10410",
        redBroj: "5566",
        nazivPu: "Velika Gorica",
        naselje: "Suša",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5567",
        brojPu: "20290",
        redBroj: "5567",
        nazivPu: "Lastovo",
        naselje: "Sušac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5568",
        brojPu: "53288",
        redBroj: "5568",
        nazivPu: "Karlobag",
        naselje: "Sušanj Cesarički",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5569",
        brojPu: "21232",
        redBroj: "5569",
        nazivPu: "Dicmo",
        naselje: "Sušci",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5570",
        brojPu: "52420",
        redBroj: "5570",
        nazivPu: "Buzet",
        naselje: "Sušići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5571",
        brojPu: "31511",
        redBroj: "5571",
        nazivPu: "Đurđenovac",
        naselje: "Sušine",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5572",
        brojPu: "49282",
        redBroj: "5572",
        nazivPu: "Konjščina",
        naselje: "Sušobreg",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5573",
        brojPu: "49246",
        redBroj: "5573",
        nazivPu: "Marija Bistrica",
        naselje: "Sušobreg Bistrički",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5574",
        brojPu: "21203",
        redBroj: "5574",
        nazivPu: "Donji Muć",
        naselje: "Sutina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5575",
        brojPu: "49252",
        redBroj: "5575",
        nazivPu: "Mihovljan",
        naselje: "Sutinske Toplice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5576",
        brojPu: "21400",
        redBroj: "5576",
        nazivPu: "Supetar",
        naselje: "Sutivan",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5577",
        brojPu: "52341",
        redBroj: "5577",
        nazivPu: "Žminj",
        naselje: "Sutivanac",
        zupanija: "ISTARSKA",
      },
      {
        id: "5578",
        brojPu: "23273",
        redBroj: "5578",
        nazivPu: "Preko",
        naselje: "Sutomišćica",
        zupanija: "ZADARSKA",
      },
      {
        id: "5579",
        brojPu: "44000",
        redBroj: "5579",
        nazivPu: "Sisak",
        naselje: "Suvoj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5580",
        brojPu: "31300",
        redBroj: "5580",
        nazivPu: "Beli Manastir",
        naselje: "Suza",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5581",
        brojPu: "51511",
        redBroj: "5581",
        nazivPu: "Malinska",
        naselje: "Sužan",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5582",
        brojPu: "49000",
        redBroj: "5582",
        nazivPu: "Krapina",
        naselje: "Svedruža",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5583",
        brojPu: "48350",
        redBroj: "5583",
        nazivPu: "Đurđevac",
        naselje: "Sveta Ana",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5584",
        brojPu: "10380",
        redBroj: "5584",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Sveta Helena",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5585",
        brojPu: "48260",
        redBroj: "5585",
        nazivPu: "Križevci",
        naselje: "Sveta Helena",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5586",
        brojPu: "51417",
        redBroj: "5586",
        nazivPu: "Mošćenička Draga",
        naselje: "Sveta Jelena",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5587",
        brojPu: "53270",
        redBroj: "5587",
        nazivPu: "Senj",
        naselje: "Sveta Jelena",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5588",
        brojPu: "52000",
        redBroj: "5588",
        nazivPu: "Pazin",
        naselje: "Sveta Katarina",
        zupanija: "ISTARSKA",
      },
      {
        id: "5589",
        brojPu: "52460",
        redBroj: "5589",
        nazivPu: "Buje (Buie)",
        naselje: "Sveta Lucija - S. Lucia",
        zupanija: "ISTARSKA",
      },
      {
        id: "5590",
        brojPu: "40320",
        redBroj: "5590",
        nazivPu: "Donji Kraljevec",
        naselje: "Sveta Marija",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5591",
        brojPu: "52460",
        redBroj: "5591",
        nazivPu: "Buje (Buie)",
        naselje: "Sveta Marija na Krasu - Madonna del Carso",
        zupanija: "ISTARSKA",
      },
      {
        id: "5592",
        brojPu: "52470",
        redBroj: "5592",
        nazivPu: "Umag (Umago)",
        naselje: "Sveta Marija na Krasu - Madonna del Carso",
        zupanija: "ISTARSKA",
      },
      {
        id: "5593",
        brojPu: "52220",
        redBroj: "5593",
        nazivPu: "Labin",
        naselje: "Sveta Marina",
        zupanija: "ISTARSKA",
      },
      {
        id: "5594",
        brojPu: "10431",
        redBroj: "5594",
        nazivPu: "Sveta Nedelja",
        naselje: "Sveta Nedelja",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5595",
        brojPu: "21465",
        redBroj: "5595",
        nazivPu: "Jelsa",
        naselje: "Sveta Nedjelja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5596",
        brojPu: "21485",
        redBroj: "5596",
        nazivPu: "Komiža",
        naselje: "Sveti Andrija",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5597",
        brojPu: "51417",
        redBroj: "5597",
        nazivPu: "Mošćenička Draga",
        naselje: "Sveti Anton",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5598",
        brojPu: "51511",
        redBroj: "5598",
        nazivPu: "Malinska",
        naselje: "Sveti Anton",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5599",
        brojPu: "52424",
        redBroj: "5599",
        nazivPu: "Motovun (Montona)",
        naselje: "Sveti Bartol - S. Bortolo",
        zupanija: "ISTARSKA",
      },
      {
        id: "5600",
        brojPu: "52220",
        redBroj: "5600",
        nazivPu: "Labin",
        naselje: "Sveti Bartul",
        zupanija: "ISTARSKA",
      },
      {
        id: "5601",
        brojPu: "52420",
        redBroj: "5601",
        nazivPu: "Buzet",
        naselje: "Sveti Donat",
        zupanija: "ISTARSKA",
      },
      {
        id: "5602",
        brojPu: "31540",
        redBroj: "5602",
        nazivPu: "Donji Miholjac",
        naselje: "Sveti Đurađ",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5603",
        brojPu: "33000",
        redBroj: "5603",
        nazivPu: "Virovitica",
        naselje: "Sveti Đurađ",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5604",
        brojPu: "42230",
        redBroj: "5604",
        nazivPu: "Ludbreg",
        naselje: "Sveti Đurđ",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5605",
        brojPu: "23210",
        redBroj: "5605",
        nazivPu: "Biograd na Moru",
        naselje: "Sveti Filip i Jakov",
        zupanija: "ZADARSKA",
      },
      {
        id: "5606",
        brojPu: "42204",
        redBroj: "5606",
        nazivPu: "Turčin",
        naselje: "Sveti Ilija",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5607",
        brojPu: "51511",
        redBroj: "5607",
        nazivPu: "Malinska",
        naselje: "Sveti Ivan",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5608",
        brojPu: "52420",
        redBroj: "5608",
        nazivPu: "Buzet",
        naselje: "Sveti Ivan",
        zupanija: "ISTARSKA",
      },
      {
        id: "5609",
        brojPu: "52440",
        redBroj: "5609",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Sveti Ivan",
        zupanija: "ISTARSKA",
      },
      {
        id: "5610",
        brojPu: "52460",
        redBroj: "5610",
        nazivPu: "Buje (Buie)",
        naselje: "Sveti Ivan - S. Giovanni",
        zupanija: "ISTARSKA",
      },
      {
        id: "5611",
        brojPu: "51511",
        redBroj: "5611",
        nazivPu: "Malinska",
        naselje: "Sveti Ivan Dobrinjski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5612",
        brojPu: "10380",
        redBroj: "5612",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Sveti Ivan Zelina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5613",
        brojPu: "48214",
        redBroj: "5613",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Sveti Ivan Žabno",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5614",
        brojPu: "51550",
        redBroj: "5614",
        nazivPu: "Mali Lošinj",
        naselje: "Sveti Jakov",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5615",
        brojPu: "42250",
        redBroj: "5615",
        nazivPu: "Lepoglava",
        naselje: "Sveti Josip",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5616",
        brojPu: "53270",
        redBroj: "5616",
        nazivPu: "Senj",
        naselje: "Sveti Juraj",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5617",
        brojPu: "40323",
        redBroj: "5617",
        nazivPu: "Prelog",
        naselje: "Sveti Juraj u Trnju",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5618",
        brojPu: "10291",
        redBroj: "5618",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Sveti Križ",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5619",
        brojPu: "40323",
        redBroj: "5619",
        nazivPu: "Prelog",
        naselje: "Sveti Križ",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5620",
        brojPu: "49284",
        redBroj: "5620",
        nazivPu: "Budinščina",
        naselje: "Sveti Križ",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5621",
        brojPu: "49290",
        redBroj: "5621",
        nazivPu: "Klanjec",
        naselje: "Sveti Križ",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5622",
        brojPu: "49223",
        redBroj: "5622",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Sveti Križ Začretje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5623",
        brojPu: "52220",
        redBroj: "5623",
        nazivPu: "Labin",
        naselje: "Sveti Lovreč Labinski",
        zupanija: "ISTARSKA",
      },
      {
        id: "5624",
        brojPu: "52440",
        redBroj: "5624",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Sveti Lovreč Pazenatički",
        zupanija: "ISTARSKA",
      },
      {
        id: "5625",
        brojPu: "48260",
        redBroj: "5625",
        nazivPu: "Križevci",
        naselje: "Sveti Martin",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5626",
        brojPu: "52220",
        redBroj: "5626",
        nazivPu: "Labin",
        naselje: "Sveti Martin",
        zupanija: "ISTARSKA",
      },
      {
        id: "5627",
        brojPu: "52420",
        redBroj: "5627",
        nazivPu: "Buzet",
        naselje: "Sveti Martin",
        zupanija: "ISTARSKA",
      },
      {
        id: "5628",
        brojPu: "40313",
        redBroj: "5628",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Sveti Martin na Muri",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5629",
        brojPu: "10435",
        redBroj: "5629",
        nazivPu: "Sveti Martin pod Okićem",
        naselje: "Sveti Martin pod Okićem",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5630",
        brojPu: "49240",
        redBroj: "5630",
        nazivPu: "Donja Stubica",
        naselje: "Sveti Matej",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5631",
        brojPu: "42230",
        redBroj: "5631",
        nazivPu: "Ludbreg",
        naselje: "Sveti Petar",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5632",
        brojPu: "47300",
        redBroj: "5632",
        nazivPu: "Ogulin",
        naselje: "Sveti Petar",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5633",
        brojPu: "51557",
        redBroj: "5633",
        nazivPu: "Cres",
        naselje: "Sveti Petar",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5634",
        brojPu: "48214",
        redBroj: "5634",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Sveti Petar Čvrstec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5635",
        brojPu: "47250",
        redBroj: "5635",
        nazivPu: "Duga Resa",
        naselje: "Sveti Petar Mrežnički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5636",
        brojPu: "23210",
        redBroj: "5636",
        nazivPu: "Biograd na Moru",
        naselje: "Sveti Petar na Moru",
        zupanija: "ZADARSKA",
      },
      {
        id: "5637",
        brojPu: "48260",
        redBroj: "5637",
        nazivPu: "Križevci",
        naselje: "Sveti Petar Orehovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5638",
        brojPu: "52341",
        redBroj: "5638",
        nazivPu: "Žminj",
        naselje: "Sveti Petar u Šumi",
        zupanija: "ISTARSKA",
      },
      {
        id: "5639",
        brojPu: "23440",
        redBroj: "5639",
        nazivPu: "Gračac",
        naselje: "Sveti Rok",
        zupanija: "ZADARSKA",
      },
      {
        id: "5640",
        brojPu: "40313",
        redBroj: "5640",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Sveti Urban",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5641",
        brojPu: "51511",
        redBroj: "5641",
        nazivPu: "Malinska",
        naselje: "Sveti Vid Dobrinjski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5642",
        brojPu: "51511",
        redBroj: "5642",
        nazivPu: "Malinska",
        naselje: "Sveti Vid-Miholjice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5643",
        brojPu: "47280",
        redBroj: "5643",
        nazivPu: "Ozalj",
        naselje: "Svetice",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5644",
        brojPu: "47280",
        redBroj: "5644",
        nazivPu: "Ozalj",
        naselje: "Svetičko Hrašće",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5645",
        brojPu: "34000",
        redBroj: "5645",
        nazivPu: "Požega",
        naselje: "Svetinja",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5646",
        brojPu: "31400",
        redBroj: "5646",
        nazivPu: "Đakovo",
        naselje: "Svetoblažje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5647",
        brojPu: "49218",
        redBroj: "5647",
        nazivPu: "Pregrada",
        naselje: "Svetojurski Vrh",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5648",
        brojPu: "10431",
        redBroj: "5648",
        nazivPu: "Sveta Nedelja",
        naselje: "Svetonedeljski Breg",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5649",
        brojPu: "52341",
        redBroj: "5649",
        nazivPu: "Žminj",
        naselje: "Svetvinčenat",
        zupanija: "ISTARSKA",
      },
      {
        id: "5650",
        brojPu: "21256",
        redBroj: "5650",
        nazivPu: "Cista Provo",
        naselje: "Svib",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5651",
        brojPu: "10361",
        redBroj: "5651",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Svibje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5652",
        brojPu: "42223",
        redBroj: "5652",
        nazivPu: "Varaždinske Toplice",
        naselje: "Svibovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5653",
        brojPu: "42000",
        redBroj: "5653",
        nazivPu: "Varaždin",
        naselje: "Svibovec Podravski",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5654",
        brojPu: "35214",
        redBroj: "5654",
        nazivPu: "Donji Andrijevci",
        naselje: "Svilaj",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5655",
        brojPu: "34310",
        redBroj: "5655",
        nazivPu: "Pleternica",
        naselje: "Svilna",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5656",
        brojPu: "21222",
        redBroj: "5656",
        nazivPu: "Marina",
        naselje: "Svinca",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5657",
        brojPu: "44430",
        redBroj: "5657",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Svinica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5658",
        brojPu: "47220",
        redBroj: "5658",
        nazivPu: "Vojnić",
        naselje: "Svinica Krstinjska",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5659",
        brojPu: "21250",
        redBroj: "5659",
        nazivPu: "Šestanovac",
        naselje: "Svinišće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5660",
        brojPu: "10342",
        redBroj: "5660",
        nazivPu: "Dubrava",
        naselje: "Svinjarec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5661",
        brojPu: "32242",
        redBroj: "5661",
        nazivPu: "Slakovci",
        naselje: "Svinjarevci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5662",
        brojPu: "21460",
        redBroj: "5662",
        nazivPu: "Stari Grad",
        naselje: "Svirče",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5663",
        brojPu: "47250",
        redBroj: "5663",
        nazivPu: "Duga Resa",
        naselje: "Svojić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5664",
        brojPu: "44400",
        redBroj: "5664",
        nazivPu: "Glina",
        naselje: "Svračica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5665",
        brojPu: "53234",
        redBroj: "5665",
        nazivPu: "Udbina",
        naselje: "Svračkovo Selo",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5666",
        brojPu: "10454",
        redBroj: "5666",
        nazivPu: "Krašić",
        naselje: "Svrževo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5667",
        brojPu: "31550",
        redBroj: "5667",
        nazivPu: "Valpovo",
        naselje: "Šag",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5668",
        brojPu: "35400",
        redBroj: "5668",
        nazivPu: "Nova Gradiška",
        naselje: "Šagovina Cernička",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5669",
        brojPu: "35430",
        redBroj: "5669",
        nazivPu: "Okučani",
        naselje: "Šagovina Mašićka",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5670",
        brojPu: "49240",
        redBroj: "5670",
        nazivPu: "Donja Stubica",
        naselje: "Šagudovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5671",
        brojPu: "52207",
        redBroj: "5671",
        nazivPu: "Barban",
        naselje: "Šajini",
        zupanija: "ISTARSKA",
      },
      {
        id: "5672",
        brojPu: "44440",
        redBroj: "5672",
        nazivPu: "Dvor",
        naselje: "Šakanlije",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5673",
        brojPu: "53230",
        redBroj: "5673",
        nazivPu: "Korenica",
        naselje: "Šalamunić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5674",
        brojPu: "48260",
        redBroj: "5674",
        nazivPu: "Križevci",
        naselje: "Šalamunovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5675",
        brojPu: "10380",
        redBroj: "5675",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Šalovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5676",
        brojPu: "40000",
        redBroj: "5676",
        nazivPu: "Čakovec",
        naselje: "Šandorovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5677",
        brojPu: "43000",
        redBroj: "5677",
        nazivPu: "Bjelovar",
        naselje: "Šandrovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5678",
        brojPu: "51211",
        redBroj: "5678",
        nazivPu: "Matulji",
        naselje: "Šapjane",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5679",
        brojPu: "31511",
        redBroj: "5679",
        nazivPu: "Đurđenovac",
        naselje: "Šaptinovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5680",
        brojPu: "32236",
        redBroj: "5680",
        nazivPu: "Ilok",
        naselje: "Šarengrad",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5681",
        brojPu: "20340",
        redBroj: "5681",
        nazivPu: "Ploče",
        naselje: "Šarić Struga",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5682",
        brojPu: "52207",
        redBroj: "5682",
        nazivPu: "Barban",
        naselje: "Šarići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5683",
        brojPu: "44320",
        redBroj: "5683",
        nazivPu: "Kutina",
        naselje: "Šartovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5684",
        brojPu: "44210",
        redBroj: "5684",
        nazivPu: "Sunja",
        naselje: "Šaš",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5685",
        brojPu: "42250",
        redBroj: "5685",
        nazivPu: "Lepoglava",
        naselje: "Šaša",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5686",
        brojPu: "44400",
        redBroj: "5686",
        nazivPu: "Glina",
        naselje: "Šaševa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5687",
        brojPu: "33520",
        redBroj: "5687",
        nazivPu: "Slatina",
        naselje: "Šaševo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5688",
        brojPu: "10360",
        redBroj: "5688",
        nazivPu: "Sesvete",
        naselje: "Šašinovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5689",
        brojPu: "44400",
        redBroj: "5689",
        nazivPu: "Glina",
        naselje: "Šatornja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5690",
        brojPu: "10310",
        redBroj: "5690",
        nazivPu: "Ivanić Grad",
        naselje: "Šćapovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5691",
        brojPu: "42220",
        redBroj: "5691",
        nazivPu: "Novi Marof",
        naselje: "Šćepanje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5692",
        brojPu: "10410",
        redBroj: "5692",
        nazivPu: "Velika Gorica",
        naselje: "Šćitarjevo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5693",
        brojPu: "49250",
        redBroj: "5693",
        nazivPu: "Zlatar",
        naselje: "Šćrbinec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5694",
        brojPu: "47250",
        redBroj: "5694",
        nazivPu: "Duga Resa",
        naselje: "Šćulac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5695",
        brojPu: "52420",
        redBroj: "5695",
        nazivPu: "Buzet",
        naselje: "Šćulci",
        zupanija: "ISTARSKA",
      },
      {
        id: "5696",
        brojPu: "47000",
        redBroj: "5696",
        nazivPu: "Karlovac",
        naselje: "Šebreki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5697",
        brojPu: "31300",
        redBroj: "5697",
        nazivPu: "Beli Manastir",
        naselje: "Šećerana",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5698",
        brojPu: "53230",
        redBroj: "5698",
        nazivPu: "Korenica",
        naselje: "Šeganovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5699",
        brojPu: "44440",
        redBroj: "5699",
        nazivPu: "Dvor",
        naselje: "Šegestin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5700",
        brojPu: "52207",
        redBroj: "5700",
        nazivPu: "Barban",
        naselje: "Šegotići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5701",
        brojPu: "47250",
        redBroj: "5701",
        nazivPu: "Duga Resa",
        naselje: "Šeketino Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5702",
        brojPu: "10340",
        redBroj: "5702",
        nazivPu: "Vrbovec",
        naselje: "Šelovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5703",
        brojPu: "48350",
        redBroj: "5703",
        nazivPu: "Đurđevac",
        naselje: "Šemovci",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5704",
        brojPu: "42202",
        redBroj: "5704",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Šemovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5705",
        brojPu: "10310",
        redBroj: "5705",
        nazivPu: "Ivanić Grad",
        naselje: "Šemovec Breški",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5706",
        brojPu: "10292",
        redBroj: "5706",
        nazivPu: "Šenkovec",
        naselje: "Šenkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5707",
        brojPu: "40000",
        redBroj: "5707",
        nazivPu: "Čakovec",
        naselje: "Šenkovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5708",
        brojPu: "34000",
        redBroj: "5708",
        nazivPu: "Požega",
        naselje: "Šeovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5709",
        brojPu: "34550",
        redBroj: "5709",
        nazivPu: "Pakrac",
        naselje: "Šeovica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5710",
        brojPu: "51326",
        redBroj: "5710",
        nazivPu: "Vrbovsko",
        naselje: "Šepci Podstenski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5711",
        brojPu: "52440",
        redBroj: "5711",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Šeraje",
        zupanija: "ISTARSKA",
      },
      {
        id: "5712",
        brojPu: "10414",
        redBroj: "5712",
        nazivPu: "Pokupsko",
        naselje: "Šestak Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5713",
        brojPu: "21250",
        redBroj: "5713",
        nazivPu: "Šestanovac",
        naselje: "Šestanovac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5714",
        brojPu: "51300",
        redBroj: "5714",
        nazivPu: "Delnice",
        naselje: "Ševalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5715",
        brojPu: "22000",
        redBroj: "5715",
        nazivPu: "Šibenik",
        naselje: "Šibenik",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5716",
        brojPu: "10290",
        redBroj: "5716",
        nazivPu: "Zaprešić",
        naselje: "Šibice",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5717",
        brojPu: "44400",
        redBroj: "5717",
        nazivPu: "Glina",
        naselje: "Šibine",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5718",
        brojPu: "43500",
        redBroj: "5718",
        nazivPu: "Daruvar",
        naselje: "Šibovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5719",
        brojPu: "42000",
        redBroj: "5719",
        nazivPu: "Varaždin",
        naselje: "Šijanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5720",
        brojPu: "51300",
        redBroj: "5720",
        nazivPu: "Delnice",
        naselje: "Šije",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5721",
        brojPu: "51511",
        redBroj: "5721",
        nazivPu: "Malinska",
        naselje: "Šilo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5722",
        brojPu: "10413",
        redBroj: "5722",
        nazivPu: "Kravarsko",
        naselje: "Šiljakovina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5723",
        brojPu: "20210",
        redBroj: "5723",
        nazivPu: "Cavtat",
        naselje: "Šilješki",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5724",
        brojPu: "47280",
        redBroj: "5724",
        nazivPu: "Ozalj",
        naselje: "Šiljki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5725",
        brojPu: "47240",
        redBroj: "5725",
        nazivPu: "Slunj",
        naselje: "Šiljkovača",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5726",
        brojPu: "51326",
        redBroj: "5726",
        nazivPu: "Vrbovsko",
        naselje: "Šimatovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5727",
        brojPu: "43000",
        redBroj: "5727",
        nazivPu: "Bjelovar",
        naselje: "Šimljana",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5728",
        brojPu: "43000",
        redBroj: "5728",
        nazivPu: "Bjelovar",
        naselje: "Šimljanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5729",
        brojPu: "43280",
        redBroj: "5729",
        nazivPu: "Garešnica",
        naselje: "Šimljanik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5730",
        brojPu: "10454",
        redBroj: "5730",
        nazivPu: "Krašić",
        naselje: "Šimraki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5731",
        brojPu: "49216",
        redBroj: "5731",
        nazivPu: "Desinić",
        naselje: "Šimunci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5732",
        brojPu: "10360",
        redBroj: "5732",
        nazivPu: "Sesvete",
        naselje: "Šimunčevec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5733",
        brojPu: "23250",
        redBroj: "5733",
        nazivPu: "Pag",
        naselje: "Šimuni",
        zupanija: "ZADARSKA",
      },
      {
        id: "5734",
        brojPu: "42250",
        redBroj: "5734",
        nazivPu: "Lepoglava",
        naselje: "Šinkovica Bednjanska",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5735",
        brojPu: "42250",
        redBroj: "5735",
        nazivPu: "Lepoglava",
        naselje: "Šinkovica Šaška",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5736",
        brojPu: "10430",
        redBroj: "5736",
        nazivPu: "Samobor",
        naselje: "Šipački Breg",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5737",
        brojPu: "20223",
        redBroj: "5737",
        nazivPu: "Šipanska Luka",
        naselje: "Šipanska Luka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5738",
        brojPu: "49250",
        redBroj: "5738",
        nazivPu: "Zlatar",
        naselje: "Šipki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5739",
        brojPu: "43000",
        redBroj: "5739",
        nazivPu: "Bjelovar",
        naselje: "Šiptari",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5740",
        brojPu: "35430",
        redBroj: "5740",
        nazivPu: "Okučani",
        naselje: "Širinci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5741",
        brojPu: "31300",
        redBroj: "5741",
        nazivPu: "Beli Manastir",
        naselje: "Širine",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5742",
        brojPu: "10315",
        redBroj: "5742",
        nazivPu: "Novoselec",
        naselje: "Širinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5743",
        brojPu: "22320",
        redBroj: "5743",
        nazivPu: "Drniš",
        naselje: "Širitovci",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5744",
        brojPu: "53000",
        redBroj: "5744",
        nazivPu: "Gospić",
        naselje: "Široka Kula",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5745",
        brojPu: "47220",
        redBroj: "5745",
        nazivPu: "Vojnić",
        naselje: "Široka Rijeka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5746",
        brojPu: "22202",
        redBroj: "5746",
        nazivPu: "Primošten",
        naselje: "Široke",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5747",
        brojPu: "31431",
        redBroj: "5747",
        nazivPu: "Čepin",
        naselje: "Široko Polje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5748",
        brojPu: "48000",
        redBroj: "5748",
        nazivPu: "Koprivnica",
        naselje: "Široko Selo",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5749",
        brojPu: "52100",
        redBroj: "5749",
        nazivPu: "Pula (Pola)",
        naselje: "Šišan - Sissano",
        zupanija: "ISTARSKA",
      },
      {
        id: "5750",
        brojPu: "44000",
        redBroj: "5750",
        nazivPu: "Sisak",
        naselje: "Šišinec",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5751",
        brojPu: "32272",
        redBroj: "5751",
        nazivPu: "Cerna",
        naselje: "Šiškovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5752",
        brojPu: "47000",
        redBroj: "5752",
        nazivPu: "Karlovac",
        naselje: "Šišljavić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5753",
        brojPu: "52341",
        redBroj: "5753",
        nazivPu: "Žminj",
        naselje: "Šivati",
        zupanija: "ISTARSKA",
      },
      {
        id: "5754",
        brojPu: "23223",
        redBroj: "5754",
        nazivPu: "Škabrnja",
        naselje: "Škabrnja",
        zupanija: "ZADARSKA",
      },
      {
        id: "5755",
        brojPu: "49216",
        redBroj: "5755",
        nazivPu: "Desinić",
        naselje: "Škalić Zagorski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5756",
        brojPu: "51217",
        redBroj: "5756",
        nazivPu: "Klana",
        naselje: "Škalnica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5757",
        brojPu: "47280",
        redBroj: "5757",
        nazivPu: "Ozalj",
        naselje: "Škaljevica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5758",
        brojPu: "53220",
        redBroj: "5758",
        nazivPu: "Otočac",
        naselje: "Škare",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5759",
        brojPu: "49000",
        redBroj: "5759",
        nazivPu: "Krapina",
        naselje: "Škarićevo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5760",
        brojPu: "42223",
        redBroj: "5760",
        nazivPu: "Varaždinske Toplice",
        naselje: "Škarnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5761",
        brojPu: "43500",
        redBroj: "5761",
        nazivPu: "Daruvar",
        naselje: "Škodinovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5762",
        brojPu: "52000",
        redBroj: "5762",
        nazivPu: "Pazin",
        naselje: "Škopljak",
        zupanija: "ISTARSKA",
      },
      {
        id: "5763",
        brojPu: "34000",
        redBroj: "5763",
        nazivPu: "Požega",
        naselje: "Škrabutnik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5764",
        brojPu: "42240",
        redBroj: "5764",
        nazivPu: "Ivanec",
        naselje: "Škriljevec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5765",
        brojPu: "48214",
        redBroj: "5765",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Škrinjari",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5766",
        brojPu: "21400",
        redBroj: "5766",
        nazivPu: "Supetar",
        naselje: "Škrip",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5767",
        brojPu: "51224",
        redBroj: "5767",
        nazivPu: "Krasica",
        naselje: "Škrljevo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5768",
        brojPu: "52424",
        redBroj: "5768",
        nazivPu: "Motovun (Montona)",
        naselje: "Škropeti",
        zupanija: "ISTARSKA",
      },
      {
        id: "5769",
        brojPu: "52420",
        redBroj: "5769",
        nazivPu: "Buzet",
        naselje: "Škuljari",
        zupanija: "ISTARSKA",
      },
      {
        id: "5770",
        brojPu: "47240",
        redBroj: "5770",
        nazivPu: "Slunj",
        naselje: "Šlivnjak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5771",
        brojPu: "31540",
        redBroj: "5771",
        nazivPu: "Donji Miholjac",
        naselje: "Šljivoševci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5772",
        brojPu: "44410",
        redBroj: "5772",
        nazivPu: "Vrginmost",
        naselje: "Šljivovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5773",
        brojPu: "51262",
        redBroj: "5773",
        nazivPu: "Kraljevica",
        naselje: "Šmrika",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5774",
        brojPu: "34000",
        redBroj: "5774",
        nazivPu: "Požega",
        naselje: "Šnjegavić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5775",
        brojPu: "31200",
        redBroj: "5775",
        nazivPu: "Osijek",
        naselje: "Šodolovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5776",
        brojPu: "23420",
        redBroj: "5776",
        nazivPu: "Benkovac",
        naselje: "Šopot",
        zupanija: "ZADARSKA",
      },
      {
        id: "5777",
        brojPu: "48260",
        redBroj: "5777",
        nazivPu: "Križevci",
        naselje: "Šopron",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5778",
        brojPu: "52460",
        redBroj: "5778",
        nazivPu: "Buje (Buie)",
        naselje: "Šorgi - Sorghi",
        zupanija: "ISTARSKA",
      },
      {
        id: "5779",
        brojPu: "52341",
        redBroj: "5779",
        nazivPu: "Žminj",
        naselje: "Šorići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5780",
        brojPu: "33520",
        redBroj: "5780",
        nazivPu: "Slatina",
        naselje: "Španat",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5781",
        brojPu: "34550",
        redBroj: "5781",
        nazivPu: "Pakrac",
        naselje: "Španovica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5782",
        brojPu: "47250",
        redBroj: "5782",
        nazivPu: "Duga Resa",
        naselje: "Špehari",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5783",
        brojPu: "49210",
        redBroj: "5783",
        nazivPu: "Zabok",
        naselje: "Špičkovina",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5784",
        brojPu: "10450",
        redBroj: "5784",
        nazivPu: "Jastrebarsko",
        naselje: "Špigelski Breg",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5785",
        brojPu: "48260",
        redBroj: "5785",
        nazivPu: "Križevci",
        naselje: "Špiranec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5786",
        brojPu: "33000",
        redBroj: "5786",
        nazivPu: "Virovitica",
        naselje: "Špišić Bukovica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5787",
        brojPu: "48000",
        redBroj: "5787",
        nazivPu: "Koprivnica",
        naselje: "Štaglinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5788",
        brojPu: "10370",
        redBroj: "5788",
        nazivPu: "Dugo Selo",
        naselje: "Štakorovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5789",
        brojPu: "47220",
        redBroj: "5789",
        nazivPu: "Vojnić",
        naselje: "Štakorovica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5790",
        brojPu: "20230",
        redBroj: "5790",
        nazivPu: "Ston",
        naselje: "Štedrica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5791",
        brojPu: "51326",
        redBroj: "5791",
        nazivPu: "Vrbovsko",
        naselje: "Štefanci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5792",
        brojPu: "40000",
        redBroj: "5792",
        nazivPu: "Čakovec",
        naselje: "Štefanec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5793",
        brojPu: "42202",
        redBroj: "5793",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Štefanec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5794",
        brojPu: "43240",
        redBroj: "5794",
        nazivPu: "Čazma",
        naselje: "Štefanje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5795",
        brojPu: "52460",
        redBroj: "5795",
        nazivPu: "Buje (Buie)",
        naselje: "Šterna - Sterna",
        zupanija: "ISTARSKA",
      },
      {
        id: "5796",
        brojPu: "52440",
        redBroj: "5796",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Štifanići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5797",
        brojPu: "23440",
        redBroj: "5797",
        nazivPu: "Gračac",
        naselje: "Štikada",
        zupanija: "ZADARSKA",
      },
      {
        id: "5798",
        brojPu: "22300",
        redBroj: "5798",
        nazivPu: "Knin",
        naselje: "Štikovo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5799",
        brojPu: "47250",
        redBroj: "5799",
        nazivPu: "Duga Resa",
        naselje: "Štirkovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5800",
        brojPu: "32270",
        redBroj: "5800",
        nazivPu: "Županja",
        naselje: "Štitar",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5801",
        brojPu: "34000",
        redBroj: "5801",
        nazivPu: "Požega",
        naselje: "Štitnjak",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5802",
        brojPu: "35420",
        redBroj: "5802",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Štivica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5803",
        brojPu: "52341",
        redBroj: "5803",
        nazivPu: "Žminj",
        naselje: "Štokovci",
        zupanija: "ISTARSKA",
      },
      {
        id: "5804",
        brojPu: "40313",
        redBroj: "5804",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Štrigova",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5805",
        brojPu: "48260",
        redBroj: "5805",
        nazivPu: "Križevci",
        naselje: "Štrigovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5806",
        brojPu: "52220",
        redBroj: "5806",
        nazivPu: "Labin",
        naselje: "Štrmac",
        zupanija: "ISTARSKA",
      },
      {
        id: "5807",
        brojPu: "52420",
        redBroj: "5807",
        nazivPu: "Buzet",
        naselje: "Štrped",
        zupanija: "ISTARSKA",
      },
      {
        id: "5808",
        brojPu: "49223",
        redBroj: "5808",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Štrucljevo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5809",
        brojPu: "40315",
        redBroj: "5809",
        nazivPu: "Mursko Središče",
        naselje: "Štrukovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5810",
        brojPu: "49000",
        redBroj: "5810",
        nazivPu: "Krapina",
        naselje: "Štuparje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5811",
        brojPu: "52440",
        redBroj: "5811",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Štuti",
        zupanija: "ISTARSKA",
      },
      {
        id: "5812",
        brojPu: "10380",
        redBroj: "5812",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Šulinec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5813",
        brojPu: "34000",
        redBroj: "5813",
        nazivPu: "Požega",
        naselje: "Šumanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5814",
        brojPu: "31300",
        redBroj: "5814",
        nazivPu: "Beli Manastir",
        naselje: "Šumarina",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5815",
        brojPu: "52220",
        redBroj: "5815",
        nazivPu: "Labin",
        naselje: "Šumber",
        zupanija: "ISTARSKA",
      },
      {
        id: "5816",
        brojPu: "10310",
        redBroj: "5816",
        nazivPu: "Ivanić Grad",
        naselje: "Šumećani",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5817",
        brojPu: "35000",
        redBroj: "5817",
        nazivPu: "Slavonski Brod",
        naselje: "Šumeće",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5818",
        brojPu: "33515",
        redBroj: "5818",
        nazivPu: "Orahovica",
        naselje: "Šumeđe",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5819",
        brojPu: "20236",
        redBroj: "5819",
        nazivPu: "Mokošica",
        naselje: "Šumet",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5820",
        brojPu: "21260",
        redBroj: "5820",
        nazivPu: "Imotski",
        naselje: "Šumet",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5821",
        brojPu: "35400",
        redBroj: "5821",
        nazivPu: "Nova Gradiška",
        naselje: "Šumetlica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5822",
        brojPu: "43500",
        redBroj: "5822",
        nazivPu: "Daruvar",
        naselje: "Šuplja Lipa",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5823",
        brojPu: "10380",
        redBroj: "5823",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Šurdovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5824",
        brojPu: "49000",
        redBroj: "5824",
        nazivPu: "Krapina",
        naselje: "Šušelj Brijeg",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5825",
        brojPu: "10315",
        redBroj: "5825",
        nazivPu: "Novoselec",
        naselje: "Šušnjari",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5826",
        brojPu: "34000",
        redBroj: "5826",
        nazivPu: "Požega",
        naselje: "Šušnjari",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5827",
        brojPu: "35212",
        redBroj: "5827",
        nazivPu: "Garčin",
        naselje: "Šušnjevci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5828",
        brojPu: "52333",
        redBroj: "5828",
        nazivPu: "Podpićan",
        naselje: "Šušnjevica",
        zupanija: "ISTARSKA",
      },
      {
        id: "5829",
        brojPu: "52440",
        redBroj: "5829",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Šušnjići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5830",
        brojPu: "31326",
        redBroj: "5830",
        nazivPu: "Darda",
        naselje: "Švajcarnica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5831",
        brojPu: "49223",
        redBroj: "5831",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Švaljkovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5832",
        brojPu: "53220",
        redBroj: "5832",
        nazivPu: "Otočac",
        naselje: "Švica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5833",
        brojPu: "44440",
        redBroj: "5833",
        nazivPu: "Dvor",
        naselje: "Švrakarica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5834",
        brojPu: "44250",
        redBroj: "5834",
        nazivPu: "Petrinja",
        naselje: "Taborište",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5835",
        brojPu: "52440",
        redBroj: "5835",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Tadini",
        zupanija: "ISTARSKA",
      },
      {
        id: "5836",
        brojPu: "44330",
        redBroj: "5836",
        nazivPu: "Novska",
        naselje: "Tanac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5837",
        brojPu: "52440",
        redBroj: "5837",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Tar - Torre",
        zupanija: "ISTARSKA",
      },
      {
        id: "5838",
        brojPu: "10310",
        redBroj: "5838",
        nazivPu: "Ivanić Grad",
        naselje: "Tarno",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5839",
        brojPu: "47240",
        redBroj: "5839",
        nazivPu: "Slunj",
        naselje: "Tatar Varoš",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5840",
        brojPu: "10370",
        redBroj: "5840",
        nazivPu: "Dugo Selo",
        naselje: "Tedrovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5841",
        brojPu: "34000",
        redBroj: "5841",
        nazivPu: "Požega",
        naselje: "Tekić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5842",
        brojPu: "49223",
        redBroj: "5842",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Temovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5843",
        brojPu: "31207",
        redBroj: "5843",
        nazivPu: "Tenja",
        naselje: "Tenja",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5844",
        brojPu: "31511",
        redBroj: "5844",
        nazivPu: "Đurđenovac",
        naselje: "Teodorovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5845",
        brojPu: "22320",
        redBroj: "5845",
        nazivPu: "Drniš",
        naselje: "Tepljuh",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5846",
        brojPu: "33000",
        redBroj: "5846",
        nazivPu: "Virovitica",
        naselje: "Terezino Polje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5847",
        brojPu: "31551",
        redBroj: "5847",
        nazivPu: "Belišće",
        naselje: "Tiborjanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5848",
        brojPu: "52440",
        redBroj: "5848",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Tićan",
        zupanija: "ISTARSKA",
      },
      {
        id: "5849",
        brojPu: "51326",
        redBroj: "5849",
        nazivPu: "Vrbovsko",
        naselje: "Tići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5850",
        brojPu: "10450",
        redBroj: "5850",
        nazivPu: "Jastrebarsko",
        naselje: "Tihočaj",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5851",
        brojPu: "21240",
        redBroj: "5851",
        nazivPu: "Trilj",
        naselje: "Tijarica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5852",
        brojPu: "31300",
        redBroj: "5852",
        nazivPu: "Beli Manastir",
        naselje: "Tikveš",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5853",
        brojPu: "44210",
        redBroj: "5853",
        nazivPu: "Sunja",
        naselje: "Timarci",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5854",
        brojPu: "23210",
        redBroj: "5854",
        nazivPu: "Biograd na moru",
        naselje: "Tinj",
        zupanija: "ZADARSKA",
      },
      {
        id: "5855",
        brojPu: "52000",
        redBroj: "5855",
        nazivPu: "Pazin",
        naselje: "Tinjan",
        zupanija: "ISTARSKA",
      },
      {
        id: "5856",
        brojPu: "49210",
        redBroj: "5856",
        nazivPu: "Zabok",
        naselje: "Tisanić Jarek",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5857",
        brojPu: "22240",
        redBroj: "5857",
        nazivPu: "Tisno",
        naselje: "Tisno",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5858",
        brojPu: "34550",
        redBroj: "5858",
        nazivPu: "Pakrac",
        naselje: "Tisovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5859",
        brojPu: "35420",
        redBroj: "5859",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Tisovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5860",
        brojPu: "10430",
        redBroj: "5860",
        nazivPu: "Samobor",
        naselje: "Tisovac Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5861",
        brojPu: "44000",
        redBroj: "5861",
        nazivPu: "Sisak",
        naselje: "Tišina Erdedska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5862",
        brojPu: "44000",
        redBroj: "5862",
        nazivPu: "Sisak",
        naselje: "Tišina Kaptolska",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5863",
        brojPu: "53250",
        redBroj: "5863",
        nazivPu: "Donji Lapac",
        naselje: "Tiškovac Lički",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5864",
        brojPu: "49000",
        redBroj: "5864",
        nazivPu: "Krapina",
        naselje: "Tkalci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5865",
        brojPu: "42220",
        redBroj: "5865",
        nazivPu: "Novi Marof",
        naselje: "Tkalec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5866",
        brojPu: "23212",
        redBroj: "5866",
        nazivPu: "Tkon",
        naselje: "Tkon",
        zupanija: "ZADARSKA",
      },
      {
        id: "5867",
        brojPu: "43270",
        redBroj: "5867",
        nazivPu: "Veliki Grđevac",
        naselje: "Tociljevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5868",
        brojPu: "47240",
        redBroj: "5868",
        nazivPu: "Slunj",
        naselje: "Točak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5869",
        brojPu: "47250",
        redBroj: "5869",
        nazivPu: "Duga Resa",
        naselje: "Točak Perjasički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5870",
        brojPu: "20230",
        redBroj: "5870",
        nazivPu: "Ston",
        naselje: "Točionik",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5871",
        brojPu: "53234",
        redBroj: "5871",
        nazivPu: "Udbina",
        naselje: "Tolić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5872",
        brojPu: "43000",
        redBroj: "5872",
        nazivPu: "Bjelovar",
        naselje: "Tomaš",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5873",
        brojPu: "31400",
        redBroj: "5873",
        nazivPu: "Đakovo",
        naselje: "Tomašanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5874",
        brojPu: "10454",
        redBroj: "5874",
        nazivPu: "Krašić",
        naselje: "Tomaševci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5875",
        brojPu: "10380",
        redBroj: "5875",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Tomaševec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5876",
        brojPu: "49290",
        redBroj: "5876",
        nazivPu: "Klanjec",
        naselje: "Tomaševec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5877",
        brojPu: "42204",
        redBroj: "5877",
        nazivPu: "Turčin",
        naselje: "Tomaševec Biškupečki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5878",
        brojPu: "43280",
        redBroj: "5878",
        nazivPu: "Garešnica",
        naselje: "Tomašica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5879",
        brojPu: "47250",
        redBroj: "5879",
        nazivPu: "Duga Resa",
        naselje: "Tomašići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5880",
        brojPu: "47280",
        redBroj: "5880",
        nazivPu: "Ozalj",
        naselje: "Tomašnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5881",
        brojPu: "35000",
        redBroj: "5881",
        nazivPu: "Slavonski Brod",
        naselje: "Tomica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5882",
        brojPu: "51326",
        redBroj: "5882",
        nazivPu: "Vrbovsko",
        naselje: "Tomići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5883",
        brojPu: "23440",
        redBroj: "5883",
        nazivPu: "Gračac",
        naselje: "Tomingaj",
        zupanija: "ZADARSKA",
      },
      {
        id: "5884",
        brojPu: "34340",
        redBroj: "5884",
        nazivPu: "Kutjevo",
        naselje: "Tominovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5885",
        brojPu: "20246",
        redBroj: "5885",
        nazivPu: "Janjina",
        naselje: "Tomislavovac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5886",
        brojPu: "52341",
        redBroj: "5886",
        nazivPu: "Žminj",
        naselje: "Tomišići",
        zupanija: "ISTARSKA",
      },
      {
        id: "5887",
        brojPu: "32242",
        redBroj: "5887",
        nazivPu: "Slakovci",
        naselje: "Tompojevci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5888",
        brojPu: "47250",
        redBroj: "5888",
        nazivPu: "Duga Resa",
        naselje: "Tončići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5889",
        brojPu: "52220",
        redBroj: "5889",
        nazivPu: "Labin",
        naselje: "Topid",
        zupanija: "ISTARSKA",
      },
      {
        id: "5890",
        brojPu: "10450",
        redBroj: "5890",
        nazivPu: "Jastrebarsko",
        naselje: "Toplice",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5891",
        brojPu: "40313",
        redBroj: "5891",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Toplice Sveti Martin",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5892",
        brojPu: "42220",
        redBroj: "5892",
        nazivPu: "Novi Marof",
        naselje: "Topličica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5893",
        brojPu: "49284",
        redBroj: "5893",
        nazivPu: "Budinščina",
        naselje: "Topličica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5894",
        brojPu: "31500",
        redBroj: "5894",
        nazivPu: "Našice",
        naselje: "Topoline",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5895",
        brojPu: "20230",
        redBroj: "5895",
        nazivPu: "Ston",
        naselje: "Topolo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5896",
        brojPu: "44000",
        redBroj: "5896",
        nazivPu: "Sisak",
        naselje: "Topolovac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5897",
        brojPu: "10340",
        redBroj: "5897",
        nazivPu: "Vrbovec",
        naselje: "Topolovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5898",
        brojPu: "10451",
        redBroj: "5898",
        nazivPu: "Pisarovina",
        naselje: "Topolovec Pisarovinski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5899",
        brojPu: "43270",
        redBroj: "5899",
        nazivPu: "Veliki Grđevac",
        naselje: "Topolovica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5900",
        brojPu: "51326",
        redBroj: "5900",
        nazivPu: "Vrbovsko",
        naselje: "Topolovica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5901",
        brojPu: "10310",
        redBroj: "5901",
        nazivPu: "Ivanić Grad",
        naselje: "Topolje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5902",
        brojPu: "31300",
        redBroj: "5902",
        nazivPu: "Beli Manastir",
        naselje: "Topolje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5903",
        brojPu: "44400",
        redBroj: "5903",
        nazivPu: "Glina",
        naselje: "Topusko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5904",
        brojPu: "34000",
        redBroj: "5904",
        nazivPu: "Požega",
        naselje: "Toranj",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5905",
        brojPu: "34550",
        redBroj: "5905",
        nazivPu: "Pakrac",
        naselje: "Toranj",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5906",
        brojPu: "48316",
        redBroj: "5906",
        nazivPu: "Đelekovec",
        naselje: "Torčec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5907",
        brojPu: "32100",
        redBroj: "5907",
        nazivPu: "Vinkovci",
        naselje: "Tordinci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5908",
        brojPu: "31551",
        redBroj: "5908",
        nazivPu: "Belišće",
        naselje: "Torjanci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5909",
        brojPu: "40000",
        redBroj: "5909",
        nazivPu: "Čakovec",
        naselje: "Totovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5910",
        brojPu: "47300",
        redBroj: "5910",
        nazivPu: "Ogulin",
        naselje: "Tounj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5911",
        brojPu: "32249",
        redBroj: "5911",
        nazivPu: "Tovarnik",
        naselje: "Tovarnik",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5912",
        brojPu: "42250",
        redBroj: "5912",
        nazivPu: "Lepoglava",
        naselje: "Trakošćan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5913",
        brojPu: "34310",
        redBroj: "5913",
        nazivPu: "Pleternica",
        naselje: "Trapari",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5914",
        brojPu: "22320",
        redBroj: "5914",
        nazivPu: "Drniš",
        naselje: "Trbounje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5915",
        brojPu: "44330",
        redBroj: "5915",
        nazivPu: "Novska",
        naselje: "Trebež",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5916",
        brojPu: "10310",
        redBroj: "5916",
        nazivPu: "Ivanić Grad",
        naselje: "Trebovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5917",
        brojPu: "43290",
        redBroj: "5917",
        nazivPu: "Grubišno Polje",
        naselje: "Treglava",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5918",
        brojPu: "48214",
        redBroj: "5918",
        nazivPu: "Sveti Ivan Žabno",
        naselje: "Trema",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5919",
        brojPu: "44250",
        redBroj: "5919",
        nazivPu: "Petrinja",
        naselje: "Tremušnjak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5920",
        brojPu: "34000",
        redBroj: "5920",
        nazivPu: "Požega",
        naselje: "Trenkovo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5921",
        brojPu: "44410",
        redBroj: "5921",
        nazivPu: "Vrginmost",
        naselje: "Trepča",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5922",
        brojPu: "47280",
        redBroj: "5922",
        nazivPu: "Ozalj",
        naselje: "Trešćerovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5923",
        brojPu: "34000",
        redBroj: "5923",
        nazivPu: "Požega",
        naselje: "Treštanovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5924",
        brojPu: "47280",
        redBroj: "5924",
        nazivPu: "Ozalj",
        naselje: "Trg",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5925",
        brojPu: "52220",
        redBroj: "5925",
        nazivPu: "Labin",
        naselje: "Trget",
        zupanija: "ISTARSKA",
      },
      {
        id: "5926",
        brojPu: "52220",
        redBroj: "5926",
        nazivPu: "Labin",
        naselje: "Trgetari",
        zupanija: "ISTARSKA",
      },
      {
        id: "5927",
        brojPu: "44440",
        redBroj: "5927",
        nazivPu: "Dvor",
        naselje: "Trgovi",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5928",
        brojPu: "49284",
        redBroj: "5928",
        nazivPu: "Budinščina",
        naselje: "Trgovišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5929",
        brojPu: "51260",
        redBroj: "5929",
        nazivPu: "Crikvenica",
        naselje: "Tribalj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5930",
        brojPu: "52460",
        redBroj: "5930",
        nazivPu: "Buje (Buie)",
        naselje: "Triban - Tribano",
        zupanija: "ISTARSKA",
      },
      {
        id: "5931",
        brojPu: "23244",
        redBroj: "5931",
        nazivPu: "Starigrad Paklenica",
        naselje: "Tribanj",
        zupanija: "ZADARSKA",
      },
      {
        id: "5932",
        brojPu: "51511",
        redBroj: "5932",
        nazivPu: "Malinska",
        naselje: "Tribulje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5933",
        brojPu: "22211",
        redBroj: "5933",
        nazivPu: "Vodice",
        naselje: "Tribunj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "5934",
        brojPu: "21240",
        redBroj: "5934",
        nazivPu: "Trilj",
        naselje: "Trilj",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5935",
        brojPu: "52440",
        redBroj: "5935",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Tripari",
        zupanija: "ISTARSKA",
      },
      {
        id: "5936",
        brojPu: "20355",
        redBroj: "5936",
        nazivPu: "Opuzen",
        naselje: "Trn",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5937",
        brojPu: "35430",
        redBroj: "5937",
        nazivPu: "Okučani",
        naselje: "Trnakovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5938",
        brojPu: "31400",
        redBroj: "5938",
        nazivPu: "Đakovo",
        naselje: "Trnava",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "5939",
        brojPu: "35430",
        redBroj: "5939",
        nazivPu: "Okučani",
        naselje: "Trnava",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5940",
        brojPu: "33410",
        redBroj: "5940",
        nazivPu: "Suhopolje",
        naselje: "Trnava Cabunska",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "5941",
        brojPu: "53230",
        redBroj: "5941",
        nazivPu: "Korenica",
        naselje: "Trnavac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5942",
        brojPu: "21250",
        redBroj: "5942",
        nazivPu: "Šestanovac",
        naselje: "Trnbusi",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5943",
        brojPu: "20232",
        redBroj: "5943",
        nazivPu: "Slano",
        naselje: "Trnova",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5944",
        brojPu: "34000",
        redBroj: "5944",
        nazivPu: "Požega",
        naselje: "Trnovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5945",
        brojPu: "53000",
        redBroj: "5945",
        nazivPu: "Gospić",
        naselje: "Trnovac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5946",
        brojPu: "44400",
        redBroj: "5946",
        nazivPu: "Glina",
        naselje: "Trnovac Glinski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5947",
        brojPu: "48000",
        redBroj: "5947",
        nazivPu: "Koprivnica",
        naselje: "Trnovac Sokolovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "5948",
        brojPu: "40000",
        redBroj: "5948",
        nazivPu: "Čakovec",
        naselje: "Trnovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "5949",
        brojPu: "42202",
        redBroj: "5949",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Trnovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5950",
        brojPu: "49216",
        redBroj: "5950",
        nazivPu: "Desinić",
        naselje: "Trnovec Desinićki",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5951",
        brojPu: "47240",
        redBroj: "5951",
        nazivPu: "Slunj",
        naselje: "Trnovi",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5952",
        brojPu: "20230",
        redBroj: "5952",
        nazivPu: "Ston",
        naselje: "Trnovica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5953",
        brojPu: "51218",
        redBroj: "5953",
        nazivPu: "Dražice",
        naselje: "Trnovica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5954",
        brojPu: "43280",
        redBroj: "5954",
        nazivPu: "Garešnica",
        naselje: "Trnovitički Popovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5955",
        brojPu: "47250",
        redBroj: "5955",
        nazivPu: "Duga Resa",
        naselje: "Trnovo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5956",
        brojPu: "35212",
        redBroj: "5956",
        nazivPu: "Garčin",
        naselje: "Trnjani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5957",
        brojPu: "35212",
        redBroj: "5957",
        nazivPu: "Garčin",
        naselje: "Trnjanski Kuti",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "5958",
        brojPu: "10410",
        redBroj: "5958",
        nazivPu: "Velika Gorica",
        naselje: "Trnje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5959",
        brojPu: "21220",
        redBroj: "5959",
        nazivPu: "Trogir",
        naselje: "Trogir",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5960",
        brojPu: "43500",
        redBroj: "5960",
        nazivPu: "Daruvar",
        naselje: "Trojeglava",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5961",
        brojPu: "43000",
        redBroj: "5961",
        nazivPu: "Bjelovar",
        naselje: "Trojstveni Markovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5962",
        brojPu: "47300",
        redBroj: "5962",
        nazivPu: "Ogulin",
        naselje: "Trojvrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5963",
        brojPu: "21220",
        redBroj: "5963",
        nazivPu: "Trogir",
        naselje: "Trolokve",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5964",
        brojPu: "52440",
        redBroj: "5964",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Trombal",
        zupanija: "ISTARSKA",
      },
      {
        id: "5965",
        brojPu: "51306",
        redBroj: "5965",
        nazivPu: "Čabar",
        naselje: "Tropeti",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5966",
        brojPu: "47300",
        redBroj: "5966",
        nazivPu: "Ogulin",
        naselje: "Trošmarija",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5967",
        brojPu: "20240",
        redBroj: "5967",
        nazivPu: "Trpanj",
        naselje: "Trpanj",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5968",
        brojPu: "32000",
        redBroj: "5968",
        nazivPu: "Vukovar",
        naselje: "Trpinja",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "5969",
        brojPu: "10291",
        redBroj: "5969",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Trstenik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5970",
        brojPu: "20244",
        redBroj: "5970",
        nazivPu: "Potomje",
        naselje: "Trstenik",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5971",
        brojPu: "52420",
        redBroj: "5971",
        nazivPu: "Buzet",
        naselje: "Trstenik",
        zupanija: "ISTARSKA",
      },
      {
        id: "5972",
        brojPu: "10361",
        redBroj: "5972",
        nazivPu: "Sesvete Kraljevec",
        naselje: "Trstenik Nartski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "5973",
        brojPu: "20235",
        redBroj: "5973",
        nazivPu: "Zaton Veliki",
        naselje: "Trsteno",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "5974",
        brojPu: "49290",
        redBroj: "5974",
        nazivPu: "Klanjec",
        naselje: "Trsteno",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5975",
        brojPu: "51306",
        redBroj: "5975",
        nazivPu: "Čabar",
        naselje: "Tršće",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5976",
        brojPu: "51300",
        redBroj: "5976",
        nazivPu: "Delnice",
        naselje: "Trški Lazi",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5977",
        brojPu: "49000",
        redBroj: "5977",
        nazivPu: "Krapina",
        naselje: "Trški Vrh",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5978",
        brojPu: "44400",
        redBroj: "5978",
        nazivPu: "Glina",
        naselje: "Trtnik Glinski",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "5979",
        brojPu: "47220",
        redBroj: "5979",
        nazivPu: "Vojnić",
        naselje: "Trupinjak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5980",
        brojPu: "52000",
        redBroj: "5980",
        nazivPu: "Pazin",
        naselje: "Trviž",
        zupanija: "ISTARSKA",
      },
      {
        id: "5981",
        brojPu: "10450",
        redBroj: "5981",
        nazivPu: "Jastrebarsko",
        naselje: "Tržić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5982",
        brojPu: "47240",
        redBroj: "5982",
        nazivPu: "Slunj",
        naselje: "Tržić Primišljanski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5983",
        brojPu: "47300",
        redBroj: "5983",
        nazivPu: "Ogulin",
        naselje: "Tržić Tounjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "5984",
        brojPu: "10340",
        redBroj: "5984",
        nazivPu: "Vrbovec",
        naselje: "Tučenik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5985",
        brojPu: "21300",
        redBroj: "5985",
        nazivPu: "Makarska",
        naselje: "Tučepi",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5986",
        brojPu: "21292",
        redBroj: "5986",
        nazivPu: "Srinjine",
        naselje: "Tugare",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "5987",
        brojPu: "49246",
        redBroj: "5987",
        nazivPu: "Marija Bistrica",
        naselje: "Tugonica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5988",
        brojPu: "49290",
        redBroj: "5988",
        nazivPu: "Klanjec",
        naselje: "Tuhelj",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5989",
        brojPu: "49290",
        redBroj: "5989",
        nazivPu: "Klanjec",
        naselje: "Tuheljske Toplice",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "5990",
        brojPu: "42223",
        redBroj: "5990",
        nazivPu: "Varaždinske Toplice",
        naselje: "Tuhovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "5991",
        brojPu: "43000",
        redBroj: "5991",
        nazivPu: "Bjelovar",
        naselje: "Tuk",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "5992",
        brojPu: "51326",
        redBroj: "5992",
        nazivPu: "Vrbovsko",
        naselje: "Tuk",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5993",
        brojPu: "53230",
        redBroj: "5993",
        nazivPu: "Korenica",
        naselje: "Tuk Bjelopoljski",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "5994",
        brojPu: "51300",
        redBroj: "5994",
        nazivPu: "Delnice",
        naselje: "Tuk Mrkopaljski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5995",
        brojPu: "51300",
        redBroj: "5995",
        nazivPu: "Delnice",
        naselje: "Tuk Vojni",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5996",
        brojPu: "51415",
        redBroj: "5996",
        nazivPu: "Lovran",
        naselje: "Tuliševica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "5997",
        brojPu: "34310",
        redBroj: "5997",
        nazivPu: "Pleternica",
        naselje: "Tulnik",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "5998",
        brojPu: "10454",
        redBroj: "5998",
        nazivPu: "Krašić",
        naselje: "Tupčina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "5999",
        brojPu: "40313",
        redBroj: "5999",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Tupkovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6000",
        brojPu: "52333",
        redBroj: "6000",
        nazivPu: "Podpićan",
        naselje: "Tupljak",
        zupanija: "ISTARSKA",
      },
      {
        id: "6001",
        brojPu: "33000",
        redBroj: "6001",
        nazivPu: "Virovitica",
        naselje: "Turanovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6002",
        brojPu: "23210",
        redBroj: "6002",
        nazivPu: "Biograd na Moru",
        naselje: "Turanj",
        zupanija: "ZADARSKA",
      },
      {
        id: "6003",
        brojPu: "44400",
        redBroj: "6003",
        nazivPu: "Glina",
        naselje: "Turčenica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6004",
        brojPu: "43290",
        redBroj: "6004",
        nazivPu: "Grubišno Polje",
        naselje: "Turčević Polje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6005",
        brojPu: "51511",
        redBroj: "6005",
        nazivPu: "Malinska",
        naselje: "Turčić",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6006",
        brojPu: "42204",
        redBroj: "6006",
        nazivPu: "Turčin",
        naselje: "Turčin",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6007",
        brojPu: "40000",
        redBroj: "6007",
        nazivPu: "Čakovec",
        naselje: "Turčišće",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6008",
        brojPu: "21230",
        redBroj: "6008",
        nazivPu: "Sinj",
        naselje: "Turjaci",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6009",
        brojPu: "53220",
        redBroj: "6009",
        nazivPu: "Otočac",
        naselje: "Turjanski",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6010",
        brojPu: "51300",
        redBroj: "6010",
        nazivPu: "Delnice",
        naselje: "Turke",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6011",
        brojPu: "10380",
        redBroj: "6011",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Turkovčina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6012",
        brojPu: "47300",
        redBroj: "6012",
        nazivPu: "Ogulin",
        naselje: "Turkovići Ogulinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6013",
        brojPu: "33405",
        redBroj: "6013",
        nazivPu: "Pitomača",
        naselje: "Turnašica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6014",
        brojPu: "34000",
        redBroj: "6014",
        nazivPu: "Požega",
        naselje: "Turnić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6015",
        brojPu: "49282",
        redBroj: "6015",
        nazivPu: "Konjščina",
        naselje: "Turnišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6016",
        brojPu: "49216",
        redBroj: "6016",
        nazivPu: "Desinić",
        naselje: "Turnišće Desinićko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6017",
        brojPu: "49210",
        redBroj: "6017",
        nazivPu: "Zabok",
        naselje: "Turnišće Klanječko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6018",
        brojPu: "49216",
        redBroj: "6018",
        nazivPu: "Desinić",
        naselje: "Turnovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6019",
        brojPu: "10410",
        redBroj: "6019",
        nazivPu: "Velika Gorica",
        naselje: "Turopolje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6020",
        brojPu: "51300",
        redBroj: "6020",
        nazivPu: "Delnice",
        naselje: "Tusti Vrh",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6021",
        brojPu: "47000",
        redBroj: "6021",
        nazivPu: "Karlovac",
        naselje: "Tušilović",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6022",
        brojPu: "47000",
        redBroj: "6022",
        nazivPu: "Karlovac",
        naselje: "Tuškani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6023",
        brojPu: "20355",
        redBroj: "6023",
        nazivPu: "Opuzen",
        naselje: "Tuštevac",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6024",
        brojPu: "42240",
        redBroj: "6024",
        nazivPu: "Ivanec",
        naselje: "Tužno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6025",
        brojPu: "43000",
        redBroj: "6025",
        nazivPu: "Bjelovar",
        naselje: "Tvrda Reka",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6026",
        brojPu: "31000",
        redBroj: "6026",
        nazivPu: "Osijek",
        naselje: "Tvrđavica",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6027",
        brojPu: "20290",
        redBroj: "6027",
        nazivPu: "Lastovo",
        naselje: "Uble",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6028",
        brojPu: "53234",
        redBroj: "6028",
        nazivPu: "Udbina",
        naselje: "Udbina",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6029",
        brojPu: "47000",
        redBroj: "6029",
        nazivPu: "Karlovac",
        naselje: "Udbinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6030",
        brojPu: "44440",
        redBroj: "6030",
        nazivPu: "Dvor",
        naselje: "Udetin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6031",
        brojPu: "21238",
        redBroj: "6031",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Udovičić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6032",
        brojPu: "34000",
        redBroj: "6032",
        nazivPu: "Požega",
        naselje: "Ugarci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6033",
        brojPu: "23273",
        redBroj: "6033",
        nazivPu: "Preko",
        naselje: "Ugljan",
        zupanija: "ZADARSKA",
      },
      {
        id: "6034",
        brojPu: "21240",
        redBroj: "6034",
        nazivPu: "Trilj",
        naselje: "Ugljane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6035",
        brojPu: "31326",
        redBroj: "6035",
        nazivPu: "Darda",
        naselje: "Uglješ",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6036",
        brojPu: "52420",
        redBroj: "6036",
        nazivPu: "Buzet",
        naselje: "Ugrini",
        zupanija: "ISTARSKA",
      },
      {
        id: "6037",
        brojPu: "43280",
        redBroj: "6037",
        nazivPu: "Garešnica",
        naselje: "Uljanički Brijeg",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6038",
        brojPu: "43280",
        redBroj: "6038",
        nazivPu: "Garešnica",
        naselje: "Uljanik",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6039",
        brojPu: "52470",
        redBroj: "6039",
        nazivPu: "Umag (Umago)",
        naselje: "Umag - Umago",
        zupanija: "ISTARSKA",
      },
      {
        id: "6040",
        brojPu: "21276",
        redBroj: "6040",
        nazivPu: "Vrgorac",
        naselje: "Umčani",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6041",
        brojPu: "44430",
        redBroj: "6041",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Umetić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6042",
        brojPu: "22320",
        redBroj: "6042",
        nazivPu: "Drniš",
        naselje: "Umljanović",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6043",
        brojPu: "47250",
        redBroj: "6043",
        nazivPu: "Duga Resa",
        naselje: "Umol",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6044",
        brojPu: "44440",
        redBroj: "6044",
        nazivPu: "Dvor",
        naselje: "Unčani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6045",
        brojPu: "22323",
        redBroj: "6045",
        nazivPu: "Unešić",
        naselje: "Unešić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6046",
        brojPu: "51562",
        redBroj: "6046",
        nazivPu: "Unije",
        naselje: "Unije",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6047",
        brojPu: "35430",
        redBroj: "6047",
        nazivPu: "Okučani",
        naselje: "Uskoci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6048",
        brojPu: "20210",
        redBroj: "6048",
        nazivPu: "Cavtat",
        naselje: "Uskoplje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6049",
        brojPu: "51550",
        redBroj: "6049",
        nazivPu: "Mali Lošinj",
        naselje: "Ustrine",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6050",
        brojPu: "44330",
        redBroj: "6050",
        nazivPu: "Novska",
        naselje: "Uštica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6051",
        brojPu: "47000",
        redBroj: "6051",
        nazivPu: "Karlovac",
        naselje: "Utinja",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6052",
        brojPu: "47220",
        redBroj: "6052",
        nazivPu: "Vojnić",
        naselje: "Utinja Vrelo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6053",
        brojPu: "43000",
        redBroj: "6053",
        nazivPu: "Bjelovar",
        naselje: "Utiskani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6054",
        brojPu: "44430",
        redBroj: "6054",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Utolica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6055",
        brojPu: "22300",
        redBroj: "6055",
        nazivPu: "Knin",
        naselje: "Uzdolje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6056",
        brojPu: "52440",
        redBroj: "6056",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vabriga - Abrega",
        zupanija: "ISTARSKA",
      },
      {
        id: "6057",
        brojPu: "22222",
        redBroj: "6057",
        nazivPu: "Skradin",
        naselje: "Vaćani",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6058",
        brojPu: "22202",
        redBroj: "6058",
        nazivPu: "Primošten",
        naselje: "Vadalj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6059",
        brojPu: "52341",
        redBroj: "6059",
        nazivPu: "Žminj",
        naselje: "Vadediji",
        zupanija: "ISTARSKA",
      },
      {
        id: "6060",
        brojPu: "10297",
        redBroj: "6060",
        nazivPu: "Jakovlje",
        naselje: "Vadina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6061",
        brojPu: "52207",
        redBroj: "6061",
        nazivPu: "Barban",
        naselje: "Vadreš",
        zupanija: "ISTARSKA",
      },
      {
        id: "6062",
        brojPu: "53000",
        redBroj: "6062",
        nazivPu: "Gospić",
        naselje: "Vaganac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6063",
        brojPu: "43240",
        redBroj: "6063",
        nazivPu: "Čazma",
        naselje: "Vagovina",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6064",
        brojPu: "47300",
        redBroj: "6064",
        nazivPu: "Ogulin",
        naselje: "Vajin Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6065",
        brojPu: "52100",
        redBroj: "6065",
        nazivPu: "Pula (Pola)",
        naselje: "Valbandon",
        zupanija: "ISTARSKA",
      },
      {
        id: "6066",
        brojPu: "52100",
        redBroj: "6066",
        nazivPu: "Pula (Pola)",
        naselje: "Valbonaša",
        zupanija: "ISTARSKA",
      },
      {
        id: "6067",
        brojPu: "31511",
        redBroj: "6067",
        nazivPu: "Đurđenovac",
        naselje: "Valenovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6068",
        brojPu: "52440",
        redBroj: "6068",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Valentići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6069",
        brojPu: "49218",
        redBroj: "6069",
        nazivPu: "Pregrada",
        naselje: "Valentinovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6070",
        brojPu: "10340",
        redBroj: "6070",
        nazivPu: "Vrbovec",
        naselje: "Valetić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6071",
        brojPu: "52470",
        redBroj: "6071",
        nazivPu: "Umag (Umago)",
        naselje: "Valica - Valizza",
        zupanija: "ISTARSKA",
      },
      {
        id: "6072",
        brojPu: "51218",
        redBroj: "6072",
        nazivPu: "Dražice",
        naselje: "Valići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6073",
        brojPu: "52440",
        redBroj: "6073",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Valkarin",
        zupanija: "ISTARSKA",
      },
      {
        id: "6074",
        brojPu: "31550",
        redBroj: "6074",
        nazivPu: "Valpovo",
        naselje: "Valpovo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6075",
        brojPu: "52100",
        redBroj: "6075",
        nazivPu: "Pula (Pola)",
        naselje: "Valtura - Altura",
        zupanija: "ISTARSKA",
      },
      {
        id: "6076",
        brojPu: "51557",
        redBroj: "6076",
        nazivPu: "Cres",
        naselje: "Valun",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6077",
        brojPu: "44440",
        redBroj: "6077",
        nazivPu: "Dvor",
        naselje: "Vanići",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6078",
        brojPu: "51511",
        redBroj: "6078",
        nazivPu: "Malinska",
        naselje: "Vantačići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6079",
        brojPu: "47280",
        redBroj: "6079",
        nazivPu: "Ozalj",
        naselje: "Varaštovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6080",
        brojPu: "42000",
        redBroj: "6080",
        nazivPu: "Varaždin",
        naselje: "Varaždin",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6081",
        brojPu: "42204",
        redBroj: "6081",
        nazivPu: "Turčin",
        naselje: "Varaždin Breg",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6082",
        brojPu: "42223",
        redBroj: "6082",
        nazivPu: "Varaždinske Toplice",
        naselje: "Varaždinske Toplice",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6083",
        brojPu: "31326",
        redBroj: "6083",
        nazivPu: "Darda",
        naselje: "Vardarac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6084",
        brojPu: "52470",
        redBroj: "6084",
        nazivPu: "Umag (Umago)",
        naselje: "Vardica - Vardiza",
        zupanija: "ISTARSKA",
      },
      {
        id: "6085",
        brojPu: "22300",
        redBroj: "6085",
        nazivPu: "Knin",
        naselje: "Varivode",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6086",
        brojPu: "47250",
        redBroj: "6086",
        nazivPu: "Duga Resa",
        naselje: "Varoš Bosiljevski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6087",
        brojPu: "34000",
        redBroj: "6087",
        nazivPu: "Požega",
        naselje: "Vasine Laze",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6088",
        brojPu: "33520",
        redBroj: "6088",
        nazivPu: "Slatina",
        naselje: "Vaška",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6089",
        brojPu: "51557",
        redBroj: "6089",
        nazivPu: "Cres",
        naselje: "Važminež",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6090",
        brojPu: "48260",
        redBroj: "6090",
        nazivPu: "Križevci",
        naselje: "Većeslavec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6091",
        brojPu: "53230",
        redBroj: "6091",
        nazivPu: "Korenica",
        naselje: "Vedašić",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6092",
        brojPu: "21240",
        redBroj: "6092",
        nazivPu: "Trilj",
        naselje: "Vedrine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6093",
        brojPu: "44210",
        redBroj: "6093",
        nazivPu: "Sunja",
        naselje: "Vedro Polje",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6094",
        brojPu: "52440",
        redBroj: "6094",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vejaki",
        zupanija: "ISTARSKA",
      },
      {
        id: "6095",
        brojPu: "20270",
        redBroj: "6095",
        nazivPu: "Vela Luka",
        naselje: "Vela Luka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6096",
        brojPu: "52000",
        redBroj: "6096",
        nazivPu: "Pazin",
        naselje: "Vela Traba",
        zupanija: "ISTARSKA",
      },
      {
        id: "6097",
        brojPu: "51410",
        redBroj: "6097",
        nazivPu: "Opatija",
        naselje: "Vela Učka",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6098",
        brojPu: "51211",
        redBroj: "6098",
        nazivPu: "Matulji",
        naselje: "Vele Mune",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6099",
        brojPu: "51550",
        redBroj: "6099",
        nazivPu: "Mali Lošinj",
        naselje: "Vele Srakane",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6100",
        brojPu: "52440",
        redBroj: "6100",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Veleniki",
        zupanija: "ISTARSKA",
      },
      {
        id: "6101",
        brojPu: "10410",
        redBroj: "6101",
        nazivPu: "Velika Gorica",
        naselje: "Veleševec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6102",
        brojPu: "49246",
        redBroj: "6102",
        nazivPu: "Marija Bistrica",
        naselje: "Veleškovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6103",
        brojPu: "51211",
        redBroj: "6103",
        nazivPu: "Matulji",
        naselje: "Veli Brgud",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6104",
        brojPu: "51262",
        redBroj: "6104",
        nazivPu: "Kraljevica",
        naselje: "Veli Dol",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6105",
        brojPu: "52220",
        redBroj: "6105",
        nazivPu: "Labin",
        naselje: "Veli Golji",
        zupanija: "ISTARSKA",
      },
      {
        id: "6106",
        brojPu: "23284",
        redBroj: "6106",
        nazivPu: "Veli Iž",
        naselje: "Veli Iž",
        zupanija: "ZADARSKA",
      },
      {
        id: "6107",
        brojPu: "51550",
        redBroj: "6107",
        nazivPu: "Mali Lošinj",
        naselje: "Veli Lošinj",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6108",
        brojPu: "52420",
        redBroj: "6108",
        nazivPu: "Buzet",
        naselje: "Veli Mlun",
        zupanija: "ISTARSKA",
      },
      {
        id: "6109",
        brojPu: "23287",
        redBroj: "6109",
        nazivPu: "Veli Rat",
        naselje: "Veli Rat",
        zupanija: "ZADARSKA",
      },
      {
        id: "6110",
        brojPu: "52220",
        redBroj: "6110",
        nazivPu: "Labin",
        naselje: "Veli Turini",
        zupanija: "ISTARSKA",
      },
      {
        id: "6111",
        brojPu: "21240",
        redBroj: "6111",
        nazivPu: "Trilj",
        naselje: "Velić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6112",
        brojPu: "52440",
        redBroj: "6112",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Velići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6113",
        brojPu: "34000",
        redBroj: "6113",
        nazivPu: "Požega",
        naselje: "Velika",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6114",
        brojPu: "33410",
        redBroj: "6114",
        nazivPu: "Suhopolje",
        naselje: "Velika Babina Gora",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6115",
        brojPu: "43290",
        redBroj: "6115",
        nazivPu: "Grubišno Polje",
        naselje: "Velika Barna",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6116",
        brojPu: "48000",
        redBroj: "6116",
        nazivPu: "Koprivnica",
        naselje: "Velika Branjska",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6117",
        brojPu: "43280",
        redBroj: "6117",
        nazivPu: "Garešnica",
        naselje: "Velika Bršljanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6118",
        brojPu: "10413",
        redBroj: "6118",
        nazivPu: "Kravarsko",
        naselje: "Velika Buna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6119",
        brojPu: "47220",
        redBroj: "6119",
        nazivPu: "Vojnić",
        naselje: "Velika Crkvina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6120",
        brojPu: "33405",
        redBroj: "6120",
        nazivPu: "Pitomača",
        naselje: "Velika Črešnjevica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6121",
        brojPu: "43290",
        redBroj: "6121",
        nazivPu: "Grubišno Polje",
        naselje: "Velika Dapčevica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6122",
        brojPu: "49290",
        redBroj: "6122",
        nazivPu: "Klanjec",
        naselje: "Velika Erpenja",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6123",
        brojPu: "22222",
        redBroj: "6123",
        nazivPu: "Skradin",
        naselje: "Velika Glava",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6124",
        brojPu: "10380",
        redBroj: "6124",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Velika Gora",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6125",
        brojPu: "49218",
        redBroj: "6125",
        nazivPu: "Pregrada",
        naselje: "Velika Gora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6126",
        brojPu: "10410",
        redBroj: "6126",
        nazivPu: "Velika Gorica",
        naselje: "Velika Gorica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6127",
        brojPu: "44210",
        redBroj: "6127",
        nazivPu: "Sunja",
        naselje: "Velika Gradusa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6128",
        brojPu: "49216",
        redBroj: "6128",
        nazivPu: "Desinić",
        naselje: "Velika Horvatska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6129",
        brojPu: "10315",
        redBroj: "6129",
        nazivPu: "Novoselec",
        naselje: "Velika Hrastilnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6130",
        brojPu: "10451",
        redBroj: "6130",
        nazivPu: "Pisarovina",
        naselje: "Velika Jamnička",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6131",
        brojPu: "43290",
        redBroj: "6131",
        nazivPu: "Grubišno Polje",
        naselje: "Velika Jasenovača",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6132",
        brojPu: "10430",
        redBroj: "6132",
        nazivPu: "Samobor",
        naselje: "Velika Jazbina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6133",
        brojPu: "33410",
        redBroj: "6133",
        nazivPu: "Suhopolje",
        naselje: "Velika Klisa",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6134",
        brojPu: "35214",
        redBroj: "6134",
        nazivPu: "Donji Andrijevci",
        naselje: "Velika Kopanica",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6135",
        brojPu: "10410",
        redBroj: "6135",
        nazivPu: "Velika Gorica",
        naselje: "Velika Kosnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6136",
        brojPu: "51300",
        redBroj: "6136",
        nazivPu: "Delnice",
        naselje: "Velika Lešnica",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6137",
        brojPu: "44317",
        redBroj: "6137",
        nazivPu: "Popovača",
        naselje: "Velika Ludina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6138",
        brojPu: "21203",
        redBroj: "6138",
        nazivPu: "Donji Muć",
        naselje: "Velika Milešina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6139",
        brojPu: "10010",
        redBroj: "6139",
        nazivPu: "Zagreb-Sloboština",
        naselje: "Velika Mlaka",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6140",
        brojPu: "43280",
        redBroj: "6140",
        nazivPu: "Garešnica",
        naselje: "Velika Mlinska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6141",
        brojPu: "48000",
        redBroj: "6141",
        nazivPu: "Koprivnica",
        naselje: "Velika Mučna",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6142",
        brojPu: "10370",
        redBroj: "6142",
        nazivPu: "Dugo Selo",
        naselje: "Velika Ostrna",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6143",
        brojPu: "47000",
        redBroj: "6143",
        nazivPu: "Karlovac",
        naselje: "Velika Paka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6144",
        brojPu: "43290",
        redBroj: "6144",
        nazivPu: "Grubišno Polje",
        naselje: "Velika Peratovica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6145",
        brojPu: "49250",
        redBroj: "6145",
        nazivPu: "Zlatar",
        naselje: "Velika Petrovagorska",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6146",
        brojPu: "43270",
        redBroj: "6146",
        nazivPu: "Veliki Grđevac",
        naselje: "Velika Pisanica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6147",
        brojPu: "53000",
        redBroj: "6147",
        nazivPu: "Gospić",
        naselje: "Velika Plana",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6148",
        brojPu: "23440",
        redBroj: "6148",
        nazivPu: "Gračac",
        naselje: "Velika Popina",
        zupanija: "ZADARSKA",
      },
      {
        id: "6149",
        brojPu: "10430",
        redBroj: "6149",
        nazivPu: "Samobor",
        naselje: "Velika Rakovica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6150",
        brojPu: "48000",
        redBroj: "6150",
        nazivPu: "Koprivnica",
        naselje: "Velika Rasinjica",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6151",
        brojPu: "44400",
        redBroj: "6151",
        nazivPu: "Glina",
        naselje: "Velika Solina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6152",
        brojPu: "33410",
        redBroj: "6152",
        nazivPu: "Suhopolje",
        naselje: "Velika Trapinska",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6153",
        brojPu: "43280",
        redBroj: "6153",
        nazivPu: "Garešnica",
        naselje: "Velika Trnava",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6154",
        brojPu: "43280",
        redBroj: "6154",
        nazivPu: "Garešnica",
        naselje: "Velika Trnovitica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6155",
        brojPu: "49000",
        redBroj: "6155",
        nazivPu: "Krapina",
        naselje: "Velika Ves",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6156",
        brojPu: "49252",
        redBroj: "6156",
        nazivPu: "Mihovljan",
        naselje: "Velika Veternička",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6157",
        brojPu: "51300",
        redBroj: "6157",
        nazivPu: "Delnice",
        naselje: "Velika Voda",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6158",
        brojPu: "44400",
        redBroj: "6158",
        nazivPu: "Glina",
        naselje: "Velika Vranovina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6159",
        brojPu: "53270",
        redBroj: "6159",
        nazivPu: "Senj",
        naselje: "Velike Brisnice",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6160",
        brojPu: "51326",
        redBroj: "6160",
        nazivPu: "Vrbovsko",
        naselje: "Velike Drage",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6161",
        brojPu: "48260",
        redBroj: "6161",
        nazivPu: "Križevci",
        naselje: "Velike Sesvete",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6162",
        brojPu: "34550",
        redBroj: "6162",
        nazivPu: "Pakrac",
        naselje: "Veliki Banovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6163",
        brojPu: "43500",
        redBroj: "6163",
        nazivPu: "Daruvar",
        naselje: "Veliki Bastaji",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6164",
        brojPu: "34340",
        redBroj: "6164",
        nazivPu: "Kutjevo",
        naselje: "Veliki Bilač",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6165",
        brojPu: "48000",
        redBroj: "6165",
        nazivPu: "Koprivnica",
        naselje: "Veliki Botinovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6166",
        brojPu: "10340",
        redBroj: "6166",
        nazivPu: "Vrbovec",
        naselje: "Veliki Brezovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6167",
        brojPu: "21204",
        redBroj: "6167",
        nazivPu: "Dugopolje",
        naselje: "Veliki Bročanac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6168",
        brojPu: "34550",
        redBroj: "6168",
        nazivPu: "Pakrac",
        naselje: "Veliki Budići",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6169",
        brojPu: "42230",
        redBroj: "6169",
        nazivPu: "Ludbreg",
        naselje: "Veliki Bukovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6170",
        brojPu: "49250",
        redBroj: "6170",
        nazivPu: "Zlatar",
        naselje: "Veliki Bukovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6171",
        brojPu: "47280",
        redBroj: "6171",
        nazivPu: "Ozalj",
        naselje: "Veliki Erjavec",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6172",
        brojPu: "42250",
        redBroj: "6172",
        nazivPu: "Lepoglava",
        naselje: "Veliki Gorenec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6173",
        brojPu: "48000",
        redBroj: "6173",
        nazivPu: "Koprivnica",
        naselje: "Veliki Grabičani",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6174",
        brojPu: "44400",
        redBroj: "6174",
        nazivPu: "Glina",
        naselje: "Veliki Gradac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6175",
        brojPu: "43270",
        redBroj: "6175",
        nazivPu: "Veliki Grđevac",
        naselje: "Veliki Grđevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6176",
        brojPu: "51326",
        redBroj: "6176",
        nazivPu: "Vrbovsko",
        naselje: "Veliki Jadrč",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6177",
        brojPu: "49221",
        redBroj: "6177",
        nazivPu: "Bedekovčina",
        naselje: "Veliki Komor 150-159",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6178",
        brojPu: "49250",
        redBroj: "6178",
        nazivPu: "Zlatar",
        naselje: "Veliki Komor 64-66",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6179",
        brojPu: "47250",
        redBroj: "6179",
        nazivPu: "Duga Resa",
        naselje: "Veliki Kozinac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6180",
        brojPu: "10430",
        redBroj: "6180",
        nazivPu: "Samobor",
        naselje: "Veliki Lipovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6181",
        brojPu: "42208",
        redBroj: "6181",
        nazivPu: "Cestica",
        naselje: "Veliki Lovrečan",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6182",
        brojPu: "43500",
        redBroj: "6182",
        nazivPu: "Daruvar",
        naselje: "Veliki Miletinac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6183",
        brojPu: "47000",
        redBroj: "6183",
        nazivPu: "Karlovac",
        naselje: "Veliki Modruš Potok",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6184",
        brojPu: "44400",
        redBroj: "6184",
        nazivPu: "Glina",
        naselje: "Veliki Obljaj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6185",
        brojPu: "48316",
        redBroj: "6185",
        nazivPu: "Đelekovec",
        naselje: "Veliki Otok",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6186",
        brojPu: "43280",
        redBroj: "6186",
        nazivPu: "Garešnica",
        naselje: "Veliki Pašijan",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6187",
        brojPu: "48000",
        redBroj: "6187",
        nazivPu: "Koprivnica",
        naselje: "Veliki Poganac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6188",
        brojPu: "48260",
        redBroj: "6188",
        nazivPu: "Križevci",
        naselje: "Veliki Potočec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6189",
        brojPu: "43280",
        redBroj: "6189",
        nazivPu: "Garešnica",
        naselje: "Veliki Prokop",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6190",
        brojPu: "21276",
        redBroj: "6190",
        nazivPu: "Vrgorac",
        naselje: "Veliki Prolog",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6191",
        brojPu: "33515",
        redBroj: "6191",
        nazivPu: "Orahovica",
        naselje: "Veliki Rastovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6192",
        brojPu: "48260",
        redBroj: "6192",
        nazivPu: "Križevci",
        naselje: "Veliki Raven",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6193",
        brojPu: "44250",
        redBroj: "6193",
        nazivPu: "Petrinja",
        naselje: "Veliki Šušnjar",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6194",
        brojPu: "52207",
        redBroj: "6194",
        nazivPu: "Barban",
        naselje: "Veliki Vareški",
        zupanija: "ISTARSKA",
      },
      {
        id: "6195",
        brojPu: "10454",
        redBroj: "6195",
        nazivPu: "Krašić",
        naselje: "Veliki Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6196",
        brojPu: "47280",
        redBroj: "6196",
        nazivPu: "Ozalj",
        naselje: "Veliki Vrh Kamanjski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6197",
        brojPu: "43290",
        redBroj: "6197",
        nazivPu: "Grubišno Polje",
        naselje: "Veliki Zdenci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6198",
        brojPu: "53000",
        redBroj: "6198",
        nazivPu: "Gospić",
        naselje: "Veliki Žitnik",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6199",
        brojPu: "21300",
        redBroj: "6199",
        nazivPu: "Makarska",
        naselje: "Veliko Brdo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6200",
        brojPu: "43000",
        redBroj: "6200",
        nazivPu: "Bjelovar",
        naselje: "Veliko Korenovo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6201",
        brojPu: "44430",
        redBroj: "6201",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Veliko Krčevo",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6202",
        brojPu: "10010",
        redBroj: "6202",
        nazivPu: "Zagreb-Sloboština",
        naselje: "Veliko Polje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6203",
        brojPu: "33000",
        redBroj: "6203",
        nazivPu: "Virovitica",
        naselje: "Veliko Polje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6204",
        brojPu: "51300",
        redBroj: "6204",
        nazivPu: "Delnice",
        naselje: "Veliko Selce",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6205",
        brojPu: "44000",
        redBroj: "6205",
        nazivPu: "Sisak",
        naselje: "Veliko Svinjičko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6206",
        brojPu: "49210",
        redBroj: "6206",
        nazivPu: "Zabok",
        naselje: "Veliko Trgovišće",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6207",
        brojPu: "43000",
        redBroj: "6207",
        nazivPu: "Bjelovar",
        naselje: "Veliko Trojstvo",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6208",
        brojPu: "43280",
        redBroj: "6208",
        nazivPu: "Garešnica",
        naselje: "Veliko Vukovje",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6209",
        brojPu: "23422",
        redBroj: "6209",
        nazivPu: "Stankovci",
        naselje: "Velim",
        zupanija: "ZADARSKA",
      },
      {
        id: "6210",
        brojPu: "31500",
        redBroj: "6210",
        nazivPu: "Našice",
        naselje: "Velimirovac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6211",
        brojPu: "49290",
        redBroj: "6211",
        nazivPu: "Klanjec",
        naselje: "Velinci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6212",
        brojPu: "31551",
        redBroj: "6212",
        nazivPu: "Belišće",
        naselje: "Veliškovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6213",
        brojPu: "21450",
        redBroj: "6213",
        nazivPu: "Hvar",
        naselje: "Velo Grablje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6214",
        brojPu: "22320",
        redBroj: "6214",
        nazivPu: "Drniš",
        naselje: "Velušić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6215",
        brojPu: "52333",
        redBroj: "6215",
        nazivPu: "Podpićan",
        naselje: "Veljaki",
        zupanija: "ISTARSKA",
      },
      {
        id: "6216",
        brojPu: "47240",
        redBroj: "6216",
        nazivPu: "Slunj",
        naselje: "Veljun",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6217",
        brojPu: "53270",
        redBroj: "6217",
        nazivPu: "Senj",
        naselje: "Veljun Primorski",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6218",
        brojPu: "47240",
        redBroj: "6218",
        nazivPu: "Slunj",
        naselje: "Veljunska Glina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6219",
        brojPu: "47240",
        redBroj: "6219",
        nazivPu: "Slunj",
        naselje: "Veljunski Ponorac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6220",
        brojPu: "47250",
        redBroj: "6220",
        nazivPu: "Duga Resa",
        naselje: "Venac Mrežnički",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6221",
        brojPu: "34340",
        redBroj: "6221",
        nazivPu: "Kutjevo",
        naselje: "Venje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6222",
        brojPu: "51410",
        redBroj: "6222",
        nazivPu: "Opatija",
        naselje: "Veprinac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6223",
        brojPu: "32000",
        redBroj: "6223",
        nazivPu: "Vukovar",
        naselje: "Vera",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6224",
        brojPu: "23287",
        redBroj: "6224",
        nazivPu: "Veli Rat",
        naselje: "Verunić",
        zupanija: "ZADARSKA",
      },
      {
        id: "6225",
        brojPu: "34310",
        redBroj: "6225",
        nazivPu: "Pleternica",
        naselje: "Vesela",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6226",
        brojPu: "47000",
        redBroj: "6226",
        nazivPu: "Karlovac",
        naselje: "Veselići",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6227",
        brojPu: "49252",
        redBroj: "6227",
        nazivPu: "Mihovljan",
        naselje: "Veternica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6228",
        brojPu: "34340",
        redBroj: "6228",
        nazivPu: "Kutjevo",
        naselje: "Vetovo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6229",
        brojPu: "22202",
        redBroj: "6229",
        nazivPu: "Primošten",
        naselje: "Vezac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6230",
        brojPu: "10315",
        redBroj: "6230",
        nazivPu: "Novoselec",
        naselje: "Vezišće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6231",
        brojPu: "49217",
        redBroj: "6231",
        nazivPu: "Krapinske Toplice",
        naselje: "Viča Sela",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6232",
        brojPu: "20350",
        redBroj: "6232",
        nazivPu: "Metković",
        naselje: "Vid",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6233",
        brojPu: "53291",
        redBroj: "6233",
        nazivPu: "Novalja",
        naselje: "Vidalići",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6234",
        brojPu: "47240",
        redBroj: "6234",
        nazivPu: "Slunj",
        naselje: "Videkić Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6235",
        brojPu: "20350",
        redBroj: "6235",
        nazivPu: "Metković",
        naselje: "Vidonje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6236",
        brojPu: "53288",
        redBroj: "6236",
        nazivPu: "Karlobag",
        naselje: "Vidovac Cesarički",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6237",
        brojPu: "34000",
        redBroj: "6237",
        nazivPu: "Požega",
        naselje: "Vidovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6238",
        brojPu: "42000",
        redBroj: "6238",
        nazivPu: "Varaždin",
        naselje: "Vidovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6239",
        brojPu: "49000",
        redBroj: "6239",
        nazivPu: "Krapina",
        naselje: "Vidovec Krapinski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6240",
        brojPu: "49000",
        redBroj: "6240",
        nazivPu: "Krapina",
        naselje: "Vidovec Petrovski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6241",
        brojPu: "51556",
        redBroj: "6241",
        nazivPu: "Martinšćica",
        naselje: "Vidovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6242",
        brojPu: "44317",
        redBroj: "6242",
        nazivPu: "Popovača",
        naselje: "Vidrenjak",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6243",
        brojPu: "52341",
        redBroj: "6243",
        nazivPu: "Žminj",
        naselje: "Vidulini",
        zupanija: "ISTARSKA",
      },
      {
        id: "6244",
        brojPu: "20250",
        redBroj: "6244",
        nazivPu: "Orebić",
        naselje: "Viganj",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6245",
        brojPu: "47250",
        redBroj: "6245",
        nazivPu: "Duga Resa",
        naselje: "Vijenac Barilovićki",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6246",
        brojPu: "49290",
        redBroj: "6246",
        nazivPu: "Klanjec",
        naselje: "Vilanci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6247",
        brojPu: "52470",
        redBroj: "6247",
        nazivPu: "Umag (Umago)",
        naselje: "Vilanija - Villania",
        zupanija: "ISTARSKA",
      },
      {
        id: "6248",
        brojPu: "42250",
        redBroj: "6248",
        nazivPu: "Lepoglava",
        naselje: "Viletinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6249",
        brojPu: "34000",
        redBroj: "6249",
        nazivPu: "Požega",
        naselje: "Vilić Selo",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6250",
        brojPu: "31540",
        redBroj: "6250",
        nazivPu: "Donji Miholjac",
        naselje: "Viljevo",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6251",
        brojPu: "21276",
        redBroj: "6251",
        nazivPu: "Vrgorac",
        naselje: "Vina",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6252",
        brojPu: "49218",
        redBroj: "6252",
        nazivPu: "Pregrada",
        naselje: "Vinagora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6253",
        brojPu: "21236",
        redBroj: "6253",
        nazivPu: "Vrlika",
        naselje: "Vinalić",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6254",
        brojPu: "48260",
        redBroj: "6254",
        nazivPu: "Križevci",
        naselje: "Vinarec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6255",
        brojPu: "52220",
        redBroj: "6255",
        nazivPu: "Labin",
        naselje: "Vinež",
        zupanija: "ISTARSKA",
      },
      {
        id: "6256",
        brojPu: "47280",
        redBroj: "6256",
        nazivPu: "Ozalj",
        naselje: "Vini Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6257",
        brojPu: "42243",
        redBroj: "6257",
        nazivPu: "Maruševec",
        naselje: "Vinica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6258",
        brojPu: "42243",
        redBroj: "6258",
        nazivPu: "Maruševec",
        naselje: "Vinica Breg",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6259",
        brojPu: "42220",
        redBroj: "6259",
        nazivPu: "Novi Marof",
        naselje: "Vinično",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6260",
        brojPu: "21240",
        redBroj: "6260",
        nazivPu: "Trilj",
        naselje: "Vinine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6261",
        brojPu: "49250",
        redBroj: "6261",
        nazivPu: "Zlatar",
        naselje: "Vinipotok",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6262",
        brojPu: "21222",
        redBroj: "6262",
        nazivPu: "Marina",
        naselje: "Vinišće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6263",
        brojPu: "32252",
        redBroj: "6263",
        nazivPu: "Otok",
        naselje: "Vinkovački Banovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6264",
        brojPu: "32100",
        redBroj: "6264",
        nazivPu: "Vinkovci",
        naselje: "Vinkovci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6265",
        brojPu: "10340",
        redBroj: "6265",
        nazivPu: "Vrbovec",
        naselje: "Vinkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6266",
        brojPu: "52100",
        redBroj: "6266",
        nazivPu: "Pula (Pola)",
        naselje: "Vinkuran",
        zupanija: "ISTARSKA",
      },
      {
        id: "6267",
        brojPu: "31551",
        redBroj: "6267",
        nazivPu: "Belišće",
        naselje: "Vinogradci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6268",
        brojPu: "42230",
        redBroj: "6268",
        nazivPu: "Ludbreg",
        naselje: "Vinogradi Ludbreški",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6269",
        brojPu: "21222",
        redBroj: "6269",
        nazivPu: "Marina",
        naselje: "Vinovac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6270",
        brojPu: "47000",
        redBroj: "6270",
        nazivPu: "Karlovac",
        naselje: "Vinski Vrh",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6271",
        brojPu: "49240",
        redBroj: "6271",
        nazivPu: "Donja Stubica",
        naselje: "Vinterovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6272",
        brojPu: "52100",
        redBroj: "6272",
        nazivPu: "Pula (Pola)",
        naselje: "Vintijan",
        zupanija: "ISTARSKA",
      },
      {
        id: "6273",
        brojPu: "23242",
        redBroj: "6273",
        nazivPu: "Posedarje",
        naselje: "Vinjerac",
        zupanija: "ZADARSKA",
      },
      {
        id: "6274",
        brojPu: "23234",
        redBroj: "6274",
        nazivPu: "Vir",
        naselje: "Vir",
        zupanija: "ZADARSKA",
      },
      {
        id: "6275",
        brojPu: "48350",
        redBroj: "6275",
        nazivPu: "Đurđevac",
        naselje: "Virje",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6276",
        brojPu: "42208",
        redBroj: "6276",
        nazivPu: "Cestica",
        naselje: "Virje Križovljansko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6277",
        brojPu: "33000",
        redBroj: "6277",
        nazivPu: "Virovitica",
        naselje: "Virovitica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6278",
        brojPu: "21480",
        redBroj: "6278",
        nazivPu: "Vis",
        naselje: "Vis",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6279",
        brojPu: "23241",
        redBroj: "6279",
        nazivPu: "Poličnik",
        naselje: "Visočane",
        zupanija: "ZADARSKA",
      },
      {
        id: "6280",
        brojPu: "20230",
        redBroj: "6280",
        nazivPu: "Ston",
        naselje: "Visočani",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6281",
        brojPu: "10454",
        redBroj: "6281",
        nazivPu: "Krašić",
        naselje: "Visoće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6282",
        brojPu: "22323",
        redBroj: "6282",
        nazivPu: "Unešić",
        naselje: "Visoka",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6283",
        brojPu: "35400",
        redBroj: "6283",
        nazivPu: "Nova Gradiška",
        naselje: "Visoka Greda",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6284",
        brojPu: "42220",
        redBroj: "6284",
        nazivPu: "Novi Marof",
        naselje: "Visoko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6285",
        brojPu: "43000",
        redBroj: "6285",
        nazivPu: "Bjelovar",
        naselje: "Visovi",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6286",
        brojPu: "53234",
        redBroj: "6286",
        nazivPu: "Udbina",
        naselje: "Visuć",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6287",
        brojPu: "10454",
        redBroj: "6287",
        nazivPu: "Krašić",
        naselje: "Višći Vrh",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6288",
        brojPu: "31400",
        redBroj: "6288",
        nazivPu: "Đakovo",
        naselje: "Viškovci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6289",
        brojPu: "34310",
        redBroj: "6289",
        nazivPu: "Pleternica",
        naselje: "Viškovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6290",
        brojPu: "52220",
        redBroj: "6290",
        nazivPu: "Labin",
        naselje: "Viškovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6291",
        brojPu: "51216",
        redBroj: "6291",
        nazivPu: "Viškovo",
        naselje: "Viškovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6292",
        brojPu: "52440",
        redBroj: "6292",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Višnjan - Visignano",
        zupanija: "ISTARSKA",
      },
      {
        id: "6293",
        brojPu: "31200",
        redBroj: "6293",
        nazivPu: "Osijek",
        naselje: "Višnjevac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6294",
        brojPu: "43000",
        redBroj: "6294",
        nazivPu: "Bjelovar",
        naselje: "Višnjevac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6295",
        brojPu: "49218",
        redBroj: "6295",
        nazivPu: "Pregrada",
        naselje: "Višnjevec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6296",
        brojPu: "10430",
        redBroj: "6296",
        nazivPu: "Samobor",
        naselje: "Višnjevec Podvrški",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6297",
        brojPu: "21276",
        redBroj: "6297",
        nazivPu: "Vrgorac",
        naselje: "Višnjica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6298",
        brojPu: "33520",
        redBroj: "6298",
        nazivPu: "Slatina",
        naselje: "Višnjica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6299",
        brojPu: "44330",
        redBroj: "6299",
        nazivPu: "Novska",
        naselje: "Višnjica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6300",
        brojPu: "20215",
        redBroj: "6300",
        nazivPu: "Gruda",
        naselje: "Vitaljina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6301",
        brojPu: "42240",
        redBroj: "6301",
        nazivPu: "Ivanec",
        naselje: "Vitešinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6302",
        brojPu: "47300",
        redBroj: "6302",
        nazivPu: "Ogulin",
        naselje: "Vitunj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6303",
        brojPu: "47280",
        redBroj: "6303",
        nazivPu: "Ozalj",
        naselje: "Vivodina",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6304",
        brojPu: "49250",
        redBroj: "6304",
        nazivPu: "Zlatar",
        naselje: "Vižanovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6305",
        brojPu: "52440",
        redBroj: "6305",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vižinada - Visinada",
        zupanija: "ISTARSKA",
      },
      {
        id: "6306",
        brojPu: "52460",
        redBroj: "6306",
        nazivPu: "Buje (Buie)",
        naselje: "Vižintini - Visintini",
        zupanija: "ISTARSKA",
      },
      {
        id: "6307",
        brojPu: "52460",
        redBroj: "6307",
        nazivPu: "Buje (Buie)",
        naselje: "Vižintini Vrhi-Monti di Visintini",
        zupanija: "ISTARSKA",
      },
      {
        id: "6308",
        brojPu: "49210",
        redBroj: "6308",
        nazivPu: "Zabok",
        naselje: "Vižovlje",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6309",
        brojPu: "33410",
        redBroj: "6309",
        nazivPu: "Suhopolje",
        naselje: "Vladimirovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6310",
        brojPu: "31431",
        redBroj: "6310",
        nazivPu: "Čepin",
        naselje: "Vladislavci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6311",
        brojPu: "35420",
        redBroj: "6311",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Vladisovo",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6312",
        brojPu: "44400",
        redBroj: "6312",
        nazivPu: "Glina",
        naselje: "Vlahović",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6313",
        brojPu: "48350",
        redBroj: "6313",
        nazivPu: "Đurđevac",
        naselje: "Vlaislav",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6314",
        brojPu: "20355",
        redBroj: "6314",
        nazivPu: "Opuzen",
        naselje: "Vlaka",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6315",
        brojPu: "21276",
        redBroj: "6315",
        nazivPu: "Vrgorac",
        naselje: "Vlaka",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6316",
        brojPu: "10454",
        redBroj: "6316",
        nazivPu: "Krašić",
        naselje: "Vlašić Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6317",
        brojPu: "23250",
        redBroj: "6317",
        nazivPu: "Pag",
        naselje: "Vlašići",
        zupanija: "ZADARSKA",
      },
      {
        id: "6318",
        brojPu: "10450",
        redBroj: "6318",
        nazivPu: "Jastrebarsko",
        naselje: "Vlaškovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6319",
        brojPu: "34340",
        redBroj: "6319",
        nazivPu: "Kutjevo",
        naselje: "Vlatkovac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6320",
        brojPu: "44330",
        redBroj: "6320",
        nazivPu: "Novska",
        naselje: "Voćarica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6321",
        brojPu: "33520",
        redBroj: "6321",
        nazivPu: "Slatina",
        naselje: "Voćin",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6322",
        brojPu: "51306",
        redBroj: "6322",
        nazivPu: "Čabar",
        naselje: "Vode",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6323",
        brojPu: "47250",
        redBroj: "6323",
        nazivPu: "Duga Resa",
        naselje: "Vodena Draga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6324",
        brojPu: "22211",
        redBroj: "6324",
        nazivPu: "Vodice",
        naselje: "Vodice",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6325",
        brojPu: "51557",
        redBroj: "6325",
        nazivPu: "Cres",
        naselje: "Vodice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6326",
        brojPu: "52420",
        redBroj: "6326",
        nazivPu: "Buzet",
        naselje: "Vodice",
        zupanija: "ISTARSKA",
      },
      {
        id: "6327",
        brojPu: "52100",
        redBroj: "6327",
        nazivPu: "Pula (Pola)",
        naselje: "Vodnjan - Dignano",
        zupanija: "ISTARSKA",
      },
      {
        id: "6328",
        brojPu: "47000",
        redBroj: "6328",
        nazivPu: "Karlovac",
        naselje: "Vodostaj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6329",
        brojPu: "53260",
        redBroj: "6329",
        nazivPu: "Brinje",
        naselje: "Vodoteč",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6330",
        brojPu: "20215",
        redBroj: "6330",
        nazivPu: "Gruda",
        naselje: "Vodovađa",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6331",
        brojPu: "32284",
        redBroj: "6331",
        nazivPu: "Stari Mikanovci",
        naselje: "Vođinci",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6332",
        brojPu: "48260",
        redBroj: "6332",
        nazivPu: "Križevci",
        naselje: "Vojakovac",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6333",
        brojPu: "47220",
        redBroj: "6333",
        nazivPu: "Vojnić",
        naselje: "Vojišnica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6334",
        brojPu: "33515",
        redBroj: "6334",
        nazivPu: "Orahovica",
        naselje: "Vojlovica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6335",
        brojPu: "47220",
        redBroj: "6335",
        nazivPu: "Vojnić",
        naselje: "Vojnić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6336",
        brojPu: "21240",
        redBroj: "6336",
        nazivPu: "Trilj",
        naselje: "Vojnić Sinjski",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6337",
        brojPu: "49221",
        redBroj: "6337",
        nazivPu: "Bedekovčina",
        naselje: "Vojnić-Breg",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6338",
        brojPu: "47300",
        redBroj: "6338",
        nazivPu: "Ogulin",
        naselje: "Vojnovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6339",
        brojPu: "48260",
        redBroj: "6339",
        nazivPu: "Križevci",
        naselje: "Vojnovec Kalnički",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6340",
        brojPu: "49250",
        redBroj: "6340",
        nazivPu: "Zlatar",
        naselje: "Vojnovec Loborski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6341",
        brojPu: "47220",
        redBroj: "6341",
        nazivPu: "Vojnić",
        naselje: "Vojnović Brdo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6342",
        brojPu: "49218",
        redBroj: "6342",
        nazivPu: "Pregrada",
        naselje: "Vojsak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6343",
        brojPu: "48000",
        redBroj: "6343",
        nazivPu: "Koprivnica",
        naselje: "Vojvodinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6344",
        brojPu: "53270",
        redBroj: "6344",
        nazivPu: "Senj",
        naselje: "Volarice",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6345",
        brojPu: "49240",
        redBroj: "6345",
        nazivPu: "Donja Stubica",
        naselje: "Volavec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6346",
        brojPu: "10450",
        redBroj: "6346",
        nazivPu: "Jastrebarsko",
        naselje: "Volavje",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6347",
        brojPu: "44440",
        redBroj: "6347",
        nazivPu: "Dvor",
        naselje: "Volinja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6348",
        brojPu: "44317",
        redBroj: "6348",
        nazivPu: "Popovača",
        naselje: "Voloder",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6349",
        brojPu: "48260",
        redBroj: "6349",
        nazivPu: "Križevci",
        naselje: "Voljavec Riječki",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6350",
        brojPu: "44400",
        redBroj: "6350",
        nazivPu: "Glina",
        naselje: "Vorkapić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6351",
        brojPu: "21240",
        redBroj: "6351",
        nazivPu: "Trilj",
        naselje: "Voštane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6352",
        brojPu: "52440",
        redBroj: "6352",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vošteni",
        zupanija: "ISTARSKA",
      },
      {
        id: "6353",
        brojPu: "52220",
        redBroj: "6353",
        nazivPu: "Labin",
        naselje: "Vozilići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6354",
        brojPu: "21238",
        redBroj: "6354",
        nazivPu: "Otok (Dalmacija)",
        naselje: "Vrabač",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6355",
        brojPu: "23210",
        redBroj: "6355",
        nazivPu: "Biograd na Moru",
        naselje: "Vrana",
        zupanija: "ZADARSKA",
      },
      {
        id: "6356",
        brojPu: "51557",
        redBroj: "6356",
        nazivPu: "Cres",
        naselje: "Vrana",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6357",
        brojPu: "33520",
        redBroj: "6357",
        nazivPu: "Slatina",
        naselje: "Vraneševci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6358",
        brojPu: "34000",
        redBroj: "6358",
        nazivPu: "Požega",
        naselje: "Vranić",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6359",
        brojPu: "52440",
        redBroj: "6359",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vranići kod Višnjana",
        zupanija: "ISTARSKA",
      },
      {
        id: "6360",
        brojPu: "52440",
        redBroj: "6360",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vranići kod Vižinade",
        zupanija: "ISTARSKA",
      },
      {
        id: "6361",
        brojPu: "23440",
        redBroj: "6361",
        nazivPu: "Gračac",
        naselje: "Vranik",
        zupanija: "ZADARSKA",
      },
      {
        id: "6362",
        brojPu: "49223",
        redBroj: "6362",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Vrankovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6363",
        brojPu: "42250",
        redBroj: "6363",
        nazivPu: "Lepoglava",
        naselje: "Vranojelje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6364",
        brojPu: "10450",
        redBroj: "6364",
        nazivPu: "Jastrebarsko",
        naselje: "Vranov Dol",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6365",
        brojPu: "53230",
        redBroj: "6365",
        nazivPu: "Korenica",
        naselje: "Vranovača",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6366",
        brojPu: "35000",
        redBroj: "6366",
        nazivPu: "Slavonski Brod",
        naselje: "Vranovci",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6367",
        brojPu: "53000",
        redBroj: "6367",
        nazivPu: "Gospić",
        naselje: "Vranovine",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6368",
        brojPu: "52420",
        redBroj: "6368",
        nazivPu: "Buzet",
        naselje: "Vranja",
        zupanija: "ISTARSKA",
      },
      {
        id: "6369",
        brojPu: "10454",
        redBroj: "6369",
        nazivPu: "Krašić",
        naselje: "Vranjak Žumberački",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6370",
        brojPu: "52440",
        redBroj: "6370",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vranje Selo",
        zupanija: "ISTARSKA",
      },
      {
        id: "6371",
        brojPu: "21210",
        redBroj: "6371",
        nazivPu: "Solin",
        naselje: "Vranjic",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6372",
        brojPu: "51300",
        redBroj: "6372",
        nazivPu: "Delnice",
        naselje: "Vrata",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6373",
        brojPu: "53270",
        redBroj: "6373",
        nazivPu: "Senj",
        naselje: "Vrataruša",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6374",
        brojPu: "44250",
        redBroj: "6374",
        nazivPu: "Petrinja",
        naselje: "Vratečko",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6375",
        brojPu: "40315",
        redBroj: "6375",
        nazivPu: "Mursko Središče",
        naselje: "Vratišinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6376",
        brojPu: "53260",
        redBroj: "6376",
        nazivPu: "Brinje",
        naselje: "Vratnik",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6377",
        brojPu: "10430",
        redBroj: "6377",
        nazivPu: "Samobor",
        naselje: "Vratnik Samoborski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6378",
        brojPu: "42208",
        redBroj: "6378",
        nazivPu: "Cestica",
        naselje: "Vratno Otok",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6379",
        brojPu: "52440",
        redBroj: "6379",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vrbani",
        zupanija: "ISTARSKA",
      },
      {
        id: "6380",
        brojPu: "42230",
        redBroj: "6380",
        nazivPu: "Ludbreg",
        naselje: "Vrbanovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6381",
        brojPu: "47280",
        redBroj: "6381",
        nazivPu: "Ozalj",
        naselje: "Vrbanska Draga",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6382",
        brojPu: "21460",
        redBroj: "6382",
        nazivPu: "Stari Grad",
        naselje: "Vrbanj",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6383",
        brojPu: "32257",
        redBroj: "6383",
        nazivPu: "Drenovci",
        naselje: "Vrbanja",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6384",
        brojPu: "32284",
        redBroj: "6384",
        nazivPu: "Stari Mikanovci",
        naselje: "Vrbica",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6385",
        brojPu: "43000",
        redBroj: "6385",
        nazivPu: "Bjelovar",
        naselje: "Vrbica",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6386",
        brojPu: "49231",
        redBroj: "6386",
        nazivPu: "Hum na Sutli",
        naselje: "Vrbišnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6387",
        brojPu: "35400",
        redBroj: "6387",
        nazivPu: "Nova Gradiška",
        naselje: "Vrbje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6388",
        brojPu: "22300",
        redBroj: "6388",
        nazivPu: "Knin",
        naselje: "Vrbnik",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6389",
        brojPu: "51500",
        redBroj: "6389",
        nazivPu: "Krk",
        naselje: "Vrbnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6390",
        brojPu: "42250",
        redBroj: "6390",
        nazivPu: "Lepoglava",
        naselje: "Vrbno",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6391",
        brojPu: "21465",
        redBroj: "6391",
        nazivPu: "Jelsa",
        naselje: "Vrboska",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6392",
        brojPu: "35420",
        redBroj: "6392",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Vrbova",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6393",
        brojPu: "43500",
        redBroj: "6393",
        nazivPu: "Daruvar",
        naselje: "Vrbovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6394",
        brojPu: "10340",
        redBroj: "6394",
        nazivPu: "Vrbovec",
        naselje: "Vrbovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6395",
        brojPu: "10430",
        redBroj: "6395",
        nazivPu: "Samobor",
        naselje: "Vrbovec Samoborski",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6396",
        brojPu: "10340",
        redBroj: "6396",
        nazivPu: "Vrbovec",
        naselje: "Vrbovečki Pavlovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6397",
        brojPu: "35430",
        redBroj: "6397",
        nazivPu: "Okučani",
        naselje: "Vrbovljani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6398",
        brojPu: "49284",
        redBroj: "6398",
        nazivPu: "Budinščina",
        naselje: "Vrbovo",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6399",
        brojPu: "10410",
        redBroj: "6399",
        nazivPu: "Velika Gorica",
        naselje: "Vrbovo Posavsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6400",
        brojPu: "51326",
        redBroj: "6400",
        nazivPu: "Vrbovsko",
        naselje: "Vrbovsko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6401",
        brojPu: "23250",
        redBroj: "6401",
        nazivPu: "Pag",
        naselje: "Vrčići",
        zupanija: "ZADARSKA",
      },
      {
        id: "6402",
        brojPu: "34310",
        redBroj: "6402",
        nazivPu: "Pleternica",
        naselje: "Vrčin Dol",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6403",
        brojPu: "53000",
        redBroj: "6403",
        nazivPu: "Gospić",
        naselje: "Vrebac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6404",
        brojPu: "52220",
        redBroj: "6404",
        nazivPu: "Labin",
        naselje: "Vrećari",
        zupanija: "ISTARSKA",
      },
      {
        id: "6405",
        brojPu: "53230",
        redBroj: "6405",
        nazivPu: "Korenica",
        naselje: "Vrelo Koreničko",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6406",
        brojPu: "23210",
        redBroj: "6406",
        nazivPu: "Biograd na Moru",
        naselje: "Vrgada",
        zupanija: "ZADARSKA",
      },
      {
        id: "6407",
        brojPu: "44410",
        redBroj: "6407",
        nazivPu: "Vrginmost",
        naselje: "Vrginmost",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6408",
        brojPu: "21276",
        redBroj: "6408",
        nazivPu: "Vrgorac",
        naselje: "Vrgorac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6409",
        brojPu: "51500",
        redBroj: "6409",
        nazivPu: "Krk",
        naselje: "Vrh",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6410",
        brojPu: "52420",
        redBroj: "6410",
        nazivPu: "Buzet",
        naselje: "Vrh",
        zupanija: "ISTARSKA",
      },
      {
        id: "6411",
        brojPu: "51300",
        redBroj: "6411",
        nazivPu: "Delnice",
        naselje: "Vrh Brodski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6412",
        brojPu: "52440",
        redBroj: "6412",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vrh Lašići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6413",
        brojPu: "44000",
        redBroj: "6413",
        nazivPu: "Sisak",
        naselje: "Vrh Letovanićki",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6414",
        brojPu: "42220",
        redBroj: "6414",
        nazivPu: "Novi Marof",
        naselje: "Vrh Visočki",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6415",
        brojPu: "49218",
        redBroj: "6415",
        nazivPu: "Pregrada",
        naselje: "Vrhi Pregradski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6416",
        brojPu: "49218",
        redBroj: "6416",
        nazivPu: "Pregrada",
        naselje: "Vrhi Vinagorski",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6417",
        brojPu: "52440",
        redBroj: "6417",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vrhjani",
        zupanija: "ISTARSKA",
      },
      {
        id: "6418",
        brojPu: "47250",
        redBroj: "6418",
        nazivPu: "Duga Resa",
        naselje: "Vrhova Gorica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6419",
        brojPu: "47280",
        redBroj: "6419",
        nazivPu: "Ozalj",
        naselje: "Vrhovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6420",
        brojPu: "48000",
        redBroj: "6420",
        nazivPu: "Koprivnica",
        naselje: "Vrhovac Sokolovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6421",
        brojPu: "47280",
        redBroj: "6421",
        nazivPu: "Ozalj",
        naselje: "Vrhovački Sopot",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6422",
        brojPu: "51306",
        redBroj: "6422",
        nazivPu: "Čabar",
        naselje: "Vrhovci",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6423",
        brojPu: "10430",
        redBroj: "6423",
        nazivPu: "Samobor",
        naselje: "Vrhovčak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6424",
        brojPu: "10340",
        redBroj: "6424",
        nazivPu: "Vrbovec",
        naselje: "Vrhovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6425",
        brojPu: "42250",
        redBroj: "6425",
        nazivPu: "Lepoglava",
        naselje: "Vrhovec Bednjanski",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6426",
        brojPu: "35212",
        redBroj: "6426",
        nazivPu: "Garčin",
        naselje: "Vrhovina",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6427",
        brojPu: "53220",
        redBroj: "6427",
        nazivPu: "Otočac",
        naselje: "Vrhovine",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6428",
        brojPu: "40313",
        redBroj: "6428",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Vrhovljan",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6429",
        brojPu: "21465",
        redBroj: "6429",
        nazivPu: "Jelsa",
        naselje: "Vrisnik",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6430",
        brojPu: "21236",
        redBroj: "6430",
        nazivPu: "Vrlika",
        naselje: "Vrlika",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6431",
        brojPu: "52460",
        redBroj: "6431",
        nazivPu: "Buje (Buie)",
        naselje: "Vrnjak - Vergnacco",
        zupanija: "ISTARSKA",
      },
      {
        id: "6432",
        brojPu: "53230",
        redBroj: "6432",
        nazivPu: "Korenica",
        naselje: "Vrpile",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6433",
        brojPu: "21240",
        redBroj: "6433",
        nazivPu: "Trilj",
        naselje: "Vrpolje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6434",
        brojPu: "22000",
        redBroj: "6434",
        nazivPu: "Šibenik",
        naselje: "Vrpolje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6435",
        brojPu: "22300",
        redBroj: "6435",
        nazivPu: "Knin",
        naselje: "Vrpolje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6436",
        brojPu: "35214",
        redBroj: "6436",
        nazivPu: "Donji Andrijevci",
        naselje: "Vrpolje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6437",
        brojPu: "52440",
        redBroj: "6437",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vrsar - Orsera",
        zupanija: "ISTARSKA",
      },
      {
        id: "6438",
        brojPu: "23235",
        redBroj: "6438",
        nazivPu: "Vrsi",
        naselje: "Vrsi",
        zupanija: "ZADARSKA",
      },
      {
        id: "6439",
        brojPu: "21222",
        redBroj: "6439",
        nazivPu: "Marina",
        naselje: "Vrsine",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6440",
        brojPu: "22000",
        redBroj: "6440",
        nazivPu: "Šibenik",
        naselje: "Vrsno",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6441",
        brojPu: "47280",
        redBroj: "6441",
        nazivPu: "Ozalj",
        naselje: "Vrškovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6442",
        brojPu: "42223",
        redBroj: "6442",
        nazivPu: "Varaždinske Toplice",
        naselje: "Vrtlinovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6443",
        brojPu: "43240",
        redBroj: "6443",
        nazivPu: "Čazma",
        naselje: "Vrtlinska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6444",
        brojPu: "49217",
        redBroj: "6444",
        nazivPu: "Krapinske Toplice",
        naselje: "Vrtnjakovec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6445",
        brojPu: "52440",
        redBroj: "6445",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vrvari",
        zupanija: "ISTARSKA",
      },
      {
        id: "6446",
        brojPu: "53260",
        redBroj: "6446",
        nazivPu: "Brinje",
        naselje: "Vrzići",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6447",
        brojPu: "52440",
        redBroj: "6447",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Vržnaveri",
        zupanija: "ISTARSKA",
      },
      {
        id: "6448",
        brojPu: "49221",
        redBroj: "6448",
        nazivPu: "Bedekovčina",
        naselje: "Vučak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6449",
        brojPu: "43240",
        redBroj: "6449",
        nazivPu: "Čazma",
        naselje: "Vučani",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6450",
        brojPu: "40000",
        redBroj: "6450",
        nazivPu: "Čakovec",
        naselje: "Vučetinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6451",
        brojPu: "31400",
        redBroj: "6451",
        nazivPu: "Đakovo",
        naselje: "Vučevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6452",
        brojPu: "21204",
        redBroj: "6452",
        nazivPu: "Dugopolje",
        naselje: "Vučevica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6453",
        brojPu: "10292",
        redBroj: "6453",
        nazivPu: "Šenkovec",
        naselje: "Vučilčevo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6454",
        brojPu: "51326",
        redBroj: "6454",
        nazivPu: "Vrbovsko",
        naselje: "Vučinići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6455",
        brojPu: "21233",
        redBroj: "6455",
        nazivPu: "Hrvace",
        naselje: "Vučipolje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6456",
        brojPu: "23440",
        redBroj: "6456",
        nazivPu: "Gračac",
        naselje: "Vučipolje",
        zupanija: "ZADARSKA",
      },
      {
        id: "6457",
        brojPu: "31511",
        redBroj: "6457",
        nazivPu: "Đurđenovac",
        naselje: "Vučjak Feričanački",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6458",
        brojPu: "51326",
        redBroj: "6458",
        nazivPu: "Vrbovsko",
        naselje: "Vučnik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6459",
        brojPu: "10360",
        redBroj: "6459",
        nazivPu: "Sesvete",
        naselje: "Vuger Selo",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6460",
        brojPu: "42240",
        redBroj: "6460",
        nazivPu: "Ivanec",
        naselje: "Vuglovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6461",
        brojPu: "40000",
        redBroj: "6461",
        nazivPu: "Čakovec",
        naselje: "Vugrišinec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6462",
        brojPu: "10360",
        redBroj: "6462",
        nazivPu: "Sesvete",
        naselje: "Vugrovec Donji",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6463",
        brojPu: "10360",
        redBroj: "6463",
        nazivPu: "Sesvete",
        naselje: "Vugrovec Gornji",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6464",
        brojPu: "48260",
        redBroj: "6464",
        nazivPu: "Križevci",
        naselje: "Vujići Vojakovački",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6465",
        brojPu: "51326",
        redBroj: "6465",
        nazivPu: "Vrbovsko",
        naselje: "Vujnovići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6466",
        brojPu: "31431",
        redBroj: "6466",
        nazivPu: "Čepin",
        naselje: "Vuka",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6467",
        brojPu: "49250",
        redBroj: "6467",
        nazivPu: "Zlatar",
        naselje: "Vukanci",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6468",
        brojPu: "40000",
        redBroj: "6468",
        nazivPu: "Čakovec",
        naselje: "Vukanovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6469",
        brojPu: "51326",
        redBroj: "6469",
        nazivPu: "Vrbovsko",
        naselje: "Vukelići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6470",
        brojPu: "47280",
        redBroj: "6470",
        nazivPu: "Ozalj",
        naselje: "Vuketić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6471",
        brojPu: "47000",
        redBroj: "6471",
        nazivPu: "Karlovac",
        naselje: "Vukmanić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6472",
        brojPu: "47000",
        redBroj: "6472",
        nazivPu: "Karlovac",
        naselje: "Vukoder",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6473",
        brojPu: "31500",
        redBroj: "6473",
        nazivPu: "Našice",
        naselje: "Vukojevci",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6474",
        brojPu: "34340",
        redBroj: "6474",
        nazivPu: "Kutjevo",
        naselje: "Vukojevica",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6475",
        brojPu: "10410",
        redBroj: "6475",
        nazivPu: "Velika Gorica",
        naselje: "Vukomerić",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6476",
        brojPu: "33000",
        redBroj: "6476",
        nazivPu: "Virovitica",
        naselje: "Vukosavljevica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6477",
        brojPu: "44210",
        redBroj: "6477",
        nazivPu: "Sunja",
        naselje: "Vukoševac",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6478",
        brojPu: "47000",
        redBroj: "6478",
        nazivPu: "Karlovac",
        naselje: "Vukova Gorica",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6479",
        brojPu: "32000",
        redBroj: "6479",
        nazivPu: "Vukovar",
        naselje: "Vukovar",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6480",
        brojPu: "48260",
        redBroj: "6480",
        nazivPu: "Križevci",
        naselje: "Vukovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6481",
        brojPu: "43500",
        redBroj: "6481",
        nazivPu: "Daruvar",
        naselje: "Vukovije",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6482",
        brojPu: "10410",
        redBroj: "6482",
        nazivPu: "Velika Gorica",
        naselje: "Vukovina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6483",
        brojPu: "10380",
        redBroj: "6483",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Vukovje Zelinsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6484",
        brojPu: "10454",
        redBroj: "6484",
        nazivPu: "Krašić",
        naselje: "Vukovo Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6485",
        brojPu: "10292",
        redBroj: "6485",
        nazivPu: "Šenkovec",
        naselje: "Vukovo Selo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6486",
        brojPu: "42240",
        redBroj: "6486",
        nazivPu: "Ivanec",
        naselje: "Vukovoj",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6487",
        brojPu: "47280",
        redBroj: "6487",
        nazivPu: "Ozalj",
        naselje: "Vuksani",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6488",
        brojPu: "23422",
        redBroj: "6488",
        nazivPu: "Stankovci",
        naselje: "Vukšić",
        zupanija: "ZADARSKA",
      },
      {
        id: "6489",
        brojPu: "10454",
        redBroj: "6489",
        nazivPu: "Krašić",
        naselje: "Vukšin Šipak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6490",
        brojPu: "48260",
        redBroj: "6490",
        nazivPu: "Križevci",
        naselje: "Vukšinec Riječki",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6491",
        brojPu: "40000",
        redBroj: "6491",
        nazivPu: "Čakovec",
        naselje: "Vularija",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6492",
        brojPu: "42250",
        redBroj: "6492",
        nazivPu: "Lepoglava",
        naselje: "Vulišinec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6493",
        brojPu: "10362",
        redBroj: "6493",
        nazivPu: "Kašina",
        naselje: "Vurnovec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6494",
        brojPu: "44000",
        redBroj: "6494",
        nazivPu: "Sisak",
        naselje: "Vurot",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6495",
        brojPu: "48316",
        redBroj: "6495",
        nazivPu: "Đelekovec",
        naselje: "Zablatje",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6496",
        brojPu: "10410",
        redBroj: "6496",
        nazivPu: "Velika Gorica",
        naselje: "Zablatje Posavsko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6497",
        brojPu: "49210",
        redBroj: "6497",
        nazivPu: "Zabok",
        naselje: "Zabok",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6498",
        brojPu: "47000",
        redBroj: "6498",
        nazivPu: "Karlovac",
        naselje: "Zaborsko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6499",
        brojPu: "10340",
        redBroj: "6499",
        nazivPu: "Vrbovec",
        naselje: "Zabrđe",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6500",
        brojPu: "20230",
        redBroj: "6500",
        nazivPu: "Ston",
        naselje: "Zabrđe",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6501",
        brojPu: "52000",
        redBroj: "6501",
        nazivPu: "Pazin",
        naselje: "Zabrežani",
        zupanija: "ISTARSKA",
      },
      {
        id: "6502",
        brojPu: "51250",
        redBroj: "6502",
        nazivPu: "Novi Vinodolski",
        naselje: "Zabukovac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6503",
        brojPu: "23000",
        redBroj: "6503",
        nazivPu: "Zadar",
        naselje: "Zadar",
        zupanija: "ZADARSKA",
      },
      {
        id: "6504",
        brojPu: "47000",
        redBroj: "6504",
        nazivPu: "Karlovac",
        naselje: "Zadobarje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6505",
        brojPu: "49221",
        redBroj: "6505",
        nazivPu: "Bedekovčina",
        naselje: "Zadravec",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6506",
        brojPu: "10380",
        redBroj: "6506",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Zadrkovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6507",
        brojPu: "35212",
        redBroj: "6507",
        nazivPu: "Garčin",
        naselje: "Zadubravlje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6508",
        brojPu: "21250",
        redBroj: "6508",
        nazivPu: "Šestanovac",
        naselje: "Zadvarje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6509",
        brojPu: "10257",
        redBroj: "6509",
        nazivPu: "Brezovica",
        naselje: "Zadvorsko",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6510",
        brojPu: "23281",
        redBroj: "6510",
        nazivPu: "Sali",
        naselje: "Zaglav",
        zupanija: "ZADARSKA",
      },
      {
        id: "6511",
        brojPu: "51300",
        redBroj: "6511",
        nazivPu: "Delnice",
        naselje: "Zagolik",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6512",
        brojPu: "49000",
        redBroj: "6512",
        nazivPu: "Krapina",
        naselje: "Zagora",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6513",
        brojPu: "51417",
        redBroj: "6513",
        nazivPu: "Mošćenička Draga",
        naselje: "Zagore",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6514",
        brojPu: "47220",
        redBroj: "6514",
        nazivPu: "Vojnić",
        naselje: "Zagorje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6515",
        brojPu: "47300",
        redBroj: "6515",
        nazivPu: "Ogulin",
        naselje: "Zagorje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6516",
        brojPu: "52220",
        redBroj: "6516",
        nazivPu: "Labin",
        naselje: "Zagorje",
        zupanija: "ISTARSKA",
      },
      {
        id: "6517",
        brojPu: "49290",
        redBroj: "6517",
        nazivPu: "Klanjec",
        naselje: "Zagorska Sela",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6518",
        brojPu: "23223",
        redBroj: "6518",
        nazivPu: "Škabrnja",
        naselje: "Zagrad",
        zupanija: "ZADARSKA",
      },
      {
        id: "6519",
        brojPu: "47250",
        redBroj: "6519",
        nazivPu: "Duga Resa",
        naselje: "Zagradci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6520",
        brojPu: "34310",
        redBroj: "6520",
        nazivPu: "Pleternica",
        naselje: "Zagrađe",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6521",
        brojPu: "47000",
        redBroj: "6521",
        nazivPu: "Karlovac",
        naselje: "Zagraj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6522",
        brojPu: "10000",
        redBroj: "6522",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Črnomerec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6523",
        brojPu: "10040",
        redBroj: "6523",
        nazivPu: "Zagreb-Dubrava",
        naselje: "Zagreb-Donja Dubrava",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6524",
        brojPu: "10000",
        redBroj: "6524",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Donji Grad",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6525",
        brojPu: "10105",
        redBroj: "6525",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Donji Grad dio",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6526",
        brojPu: "10040",
        redBroj: "6526",
        nazivPu: "Zagreb-Dubrava",
        naselje: "Zagreb-Gornja Dubrava",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6527",
        brojPu: "10000",
        redBroj: "6527",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Gornji Grad- Medveščak",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6528",
        brojPu: "10000",
        redBroj: "6528",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Maksimir",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6529",
        brojPu: "10010",
        redBroj: "6529",
        nazivPu: "Zagreb-Sloboština",
        naselje: "Zagreb-Novi Zagreb-istok",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6530",
        brojPu: "10020",
        redBroj: "6530",
        nazivPu: "Zagreb-Novi Zagreb",
        naselje: "Zagreb-Novi Zagreb-zapad",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6531",
        brojPu: "10135",
        redBroj: "6531",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Pešćenica-Žitnjak",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6532",
        brojPu: "10000",
        redBroj: "6532",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Podsljeme",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6533",
        brojPu: "10090",
        redBroj: "6533",
        nazivPu: "Zagreb-Susedgrad",
        naselje: "Zagreb-Podsused-Vrapče",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6534",
        brojPu: "10090",
        redBroj: "6534",
        nazivPu: "Zagreb-Susedgrad",
        naselje: "Zagreb-Stenjevec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6535",
        brojPu: "10104",
        redBroj: "6535",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Trešnjevka-jug",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6536",
        brojPu: "10110",
        redBroj: "6536",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Trešnjevka-sjever",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6537",
        brojPu: "10000",
        redBroj: "6537",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Trnje",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6538",
        brojPu: "21270",
        redBroj: "6538",
        nazivPu: "Zagvozd",
        naselje: "Zagvozd",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6539",
        brojPu: "51326",
        redBroj: "6539",
        nazivPu: "Vrbovsko",
        naselje: "Zahrt",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6540",
        brojPu: "48260",
        redBroj: "6540",
        nazivPu: "Križevci",
        naselje: "Zaistovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6541",
        brojPu: "47280",
        redBroj: "6541",
        nazivPu: "Ozalj",
        naselje: "Zajačko Selo",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6542",
        brojPu: "52333",
        redBroj: "6542",
        nazivPu: "Podpićan",
        naselje: "Zajci",
        zupanija: "ISTARSKA",
      },
      {
        id: "6543",
        brojPu: "49284",
        redBroj: "6543",
        nazivPu: "Budinščina",
        naselje: "Zajezda",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6544",
        brojPu: "10310",
        redBroj: "6544",
        nazivPu: "Ivanić Grad",
        naselje: "Zaklepica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6545",
        brojPu: "53250",
        redBroj: "6545",
        nazivPu: "Donji Lapac",
        naselje: "Zaklopac",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6546",
        brojPu: "53230",
        redBroj: "6546",
        nazivPu: "Korenica",
        naselje: "Zaklopača",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6547",
        brojPu: "20290",
        redBroj: "6547",
        nazivPu: "Lastovo",
        naselje: "Zaklopatica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6548",
        brojPu: "44440",
        redBroj: "6548",
        nazivPu: "Dvor",
        naselje: "Zakopa",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6549",
        brojPu: "34000",
        redBroj: "6549",
        nazivPu: "Požega",
        naselje: "Zakorenje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6550",
        brojPu: "51300",
        redBroj: "6550",
        nazivPu: "Delnice",
        naselje: "Zakrajc Brodski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6551",
        brojPu: "51300",
        redBroj: "6551",
        nazivPu: "Delnice",
        naselje: "Zakrajc Turkovski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6552",
        brojPu: "21310",
        redBroj: "6552",
        nazivPu: "Omiš",
        naselje: "Zakučac",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6553",
        brojPu: "51300",
        redBroj: "6553",
        nazivPu: "Delnice",
        naselje: "Zalesina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6554",
        brojPu: "44400",
        redBroj: "6554",
        nazivPu: "Glina",
        naselje: "Zaloj",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6555",
        brojPu: "49231",
        redBroj: "6555",
        nazivPu: "Hum na Sutli",
        naselje: "Zalug",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6556",
        brojPu: "47280",
        redBroj: "6556",
        nazivPu: "Ozalj",
        naselje: "Zaluka",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6557",
        brojPu: "47000",
        redBroj: "6557",
        nazivPu: "Karlovac",
        naselje: "Zaluka Lipnička",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6558",
        brojPu: "51211",
        redBroj: "6558",
        nazivPu: "Matulji",
        naselje: "Zaluki",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6559",
        brojPu: "42250",
        redBroj: "6559",
        nazivPu: "Lepoglava",
        naselje: "Zalužje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6560",
        brojPu: "53220",
        redBroj: "6560",
        nazivPu: "Otočac",
        naselje: "Zalužnica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6561",
        brojPu: "52000",
        redBroj: "6561",
        nazivPu: "Pazin",
        naselje: "Zamask",
        zupanija: "ISTARSKA",
      },
      {
        id: "6562",
        brojPu: "52424",
        redBroj: "6562",
        nazivPu: "Motovun (Montona)",
        naselje: "Zamaski Dol",
        zupanija: "ISTARSKA",
      },
      {
        id: "6563",
        brojPu: "20230",
        redBroj: "6563",
        nazivPu: "Ston",
        naselje: "Zamaslina",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6564",
        brojPu: "52470",
        redBroj: "6564",
        nazivPu: "Umag (Umago)",
        naselje: "Zambratija - Zambrattia",
        zupanija: "ISTARSKA",
      },
      {
        id: "6565",
        brojPu: "42000",
        redBroj: "6565",
        nazivPu: "Varaždin",
        naselje: "Zamlača",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6566",
        brojPu: "44440",
        redBroj: "6566",
        nazivPu: "Dvor",
        naselje: "Zamlača",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6567",
        brojPu: "48260",
        redBroj: "6567",
        nazivPu: "Križevci",
        naselje: "Zamladinec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6568",
        brojPu: "42202",
        redBroj: "6568",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Zamlaka",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6569",
        brojPu: "51306",
        redBroj: "6569",
        nazivPu: "Čabar",
        naselje: "Zamost",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6570",
        brojPu: "51300",
        redBroj: "6570",
        nazivPu: "Delnice",
        naselje: "Zamost Brodski",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6571",
        brojPu: "47000",
        redBroj: "6571",
        nazivPu: "Karlovac",
        naselje: "Zamršje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6572",
        brojPu: "52333",
        redBroj: "6572",
        nazivPu: "Podpićan",
        naselje: "Zankovci",
        zupanija: "ISTARSKA",
      },
      {
        id: "6573",
        brojPu: "21330",
        redBroj: "6573",
        nazivPu: "Gradac",
        naselje: "Zaostrog",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6574",
        brojPu: "51326",
        redBroj: "6574",
        nazivPu: "Vrbovsko",
        naselje: "Zapeć",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6575",
        brojPu: "47240",
        redBroj: "6575",
        nazivPu: "Slunj",
        naselje: "Zapoljak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6576",
        brojPu: "35420",
        redBroj: "6576",
        nazivPu: "Staro Petrovo Selo",
        naselje: "Zapolje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6577",
        brojPu: "51300",
        redBroj: "6577",
        nazivPu: "Delnice",
        naselje: "Zapolje Brodsko",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6578",
        brojPu: "10290",
        redBroj: "6578",
        nazivPu: "Zaprešić",
        naselje: "Zaprešić",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6579",
        brojPu: "23292",
        redBroj: "6579",
        nazivPu: "Molat",
        naselje: "Zapuntel",
        zupanija: "ZADARSKA",
      },
      {
        id: "6580",
        brojPu: "23420",
        redBroj: "6580",
        nazivPu: "Benkovac",
        naselje: "Zapužane",
        zupanija: "ZADARSKA",
      },
      {
        id: "6581",
        brojPu: "21450",
        redBroj: "6581",
        nazivPu: "Hvar",
        naselje: "Zaraće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6582",
        brojPu: "52000",
        redBroj: "6582",
        nazivPu: "Pazin",
        naselje: "Zarečje",
        zupanija: "ISTARSKA",
      },
      {
        id: "6583",
        brojPu: "34310",
        redBroj: "6583",
        nazivPu: "Pleternica",
        naselje: "Zarilac",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6584",
        brojPu: "40000",
        redBroj: "6584",
        nazivPu: "Čakovec",
        naselje: "Zasadbreg",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6585",
        brojPu: "21233",
        redBroj: "6585",
        nazivPu: "Hrvace",
        naselje: "Zasiok",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6586",
        brojPu: "51218",
        redBroj: "6586",
        nazivPu: "Dražice",
        naselje: "Zastenice",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6587",
        brojPu: "20215",
        redBroj: "6587",
        nazivPu: "Gruda",
        naselje: "Zastolje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6588",
        brojPu: "21469",
        redBroj: "6588",
        nazivPu: "Sućuraj",
        naselje: "Zastražišće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6589",
        brojPu: "52333",
        redBroj: "6589",
        nazivPu: "Podpićan",
        naselje: "Zatka Čepić",
        zupanija: "ISTARSKA",
      },
      {
        id: "6590",
        brojPu: "22202",
        redBroj: "6590",
        nazivPu: "Primošten",
        naselje: "Zatoglav",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6591",
        brojPu: "20235",
        redBroj: "6591",
        nazivPu: "Zaton Veliki",
        naselje: "Zaton",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6592",
        brojPu: "22000",
        redBroj: "6592",
        nazivPu: "Šibenik",
        naselje: "Zaton",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6593",
        brojPu: "23232",
        redBroj: "6593",
        nazivPu: "Nin",
        naselje: "Zaton",
        zupanija: "ZADARSKA",
      },
      {
        id: "6594",
        brojPu: "20230",
        redBroj: "6594",
        nazivPu: "Ston",
        naselje: "Zaton Doli",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6595",
        brojPu: "23450",
        redBroj: "6595",
        nazivPu: "Obrovac",
        naselje: "Zaton Obrovački",
        zupanija: "ZADARSKA",
      },
      {
        id: "6596",
        brojPu: "51326",
        redBroj: "6596",
        nazivPu: "Vrbovsko",
        naselje: "Zaumol",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6597",
        brojPu: "20355",
        redBroj: "6597",
        nazivPu: "Opuzen",
        naselje: "Zavala",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6598",
        brojPu: "21465",
        redBroj: "6598",
        nazivPu: "Jelsa",
        naselje: "Zavala",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6599",
        brojPu: "40313",
        redBroj: "6599",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Zaveščak",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6600",
        brojPu: "53000",
        redBroj: "6600",
        nazivPu: "Gospić",
        naselje: "Zavođe",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6601",
        brojPu: "21276",
        redBroj: "6601",
        nazivPu: "Vrgorac",
        naselje: "Zavojane",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6602",
        brojPu: "20207",
        redBroj: "6602",
        nazivPu: "Mlini",
        naselje: "Zavrelje",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6603",
        brojPu: "51326",
        redBroj: "6603",
        nazivPu: "Vrbovsko",
        naselje: "Zavrh",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6604",
        brojPu: "34000",
        redBroj: "6604",
        nazivPu: "Požega",
        naselje: "Završje",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6605",
        brojPu: "35252",
        redBroj: "6605",
        nazivPu: "Sibinj",
        naselje: "Završje",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6606",
        brojPu: "51326",
        redBroj: "6606",
        nazivPu: "Vrbovsko",
        naselje: "Završje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6607",
        brojPu: "52460",
        redBroj: "6607",
        nazivPu: "Buje (Buie)",
        naselje: "Završje - Piemonte",
        zupanija: "ISTARSKA",
      },
      {
        id: "6608",
        brojPu: "49250",
        redBroj: "6608",
        nazivPu: "Zlatar",
        naselje: "Završje Belečko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6609",
        brojPu: "49250",
        redBroj: "6609",
        nazivPu: "Zlatar",
        naselje: "Završje Loborsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6610",
        brojPu: "47000",
        redBroj: "6610",
        nazivPu: "Karlovac",
        naselje: "Završje Netretićko",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6611",
        brojPu: "42240",
        redBroj: "6611",
        nazivPu: "Ivanec",
        naselje: "Završje Podbelsko",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6612",
        brojPu: "49223",
        redBroj: "6612",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Završje Začretsko",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6613",
        brojPu: "42202",
        redBroj: "6613",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Zbelava",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6614",
        brojPu: "51557",
        redBroj: "6614",
        nazivPu: "Cres",
        naselje: "Zbičina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6615",
        brojPu: "51557",
        redBroj: "6615",
        nazivPu: "Cres",
        naselje: "Zbišina",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6616",
        brojPu: "35000",
        redBroj: "6616",
        nazivPu: "Slavonski Brod",
        naselje: "Zbjeg",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6617",
        brojPu: "44320",
        redBroj: "6617",
        nazivPu: "Kutina",
        naselje: "Zbjegovača",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6618",
        brojPu: "47300",
        redBroj: "6618",
        nazivPu: "Ogulin",
        naselje: "Zdenac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6619",
        brojPu: "33515",
        redBroj: "6619",
        nazivPu: "Orahovica",
        naselje: "Zdenci",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6620",
        brojPu: "10291",
        redBroj: "6620",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Zdenci Brdovečki",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6621",
        brojPu: "43280",
        redBroj: "6621",
        nazivPu: "Garešnica",
        naselje: "Zdenčac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6622",
        brojPu: "43240",
        redBroj: "6622",
        nazivPu: "Čazma",
        naselje: "Zdenčec",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6623",
        brojPu: "10450",
        redBroj: "6623",
        nazivPu: "Jastrebarsko",
        naselje: "Zdihovo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6624",
        brojPu: "51326",
        redBroj: "6624",
        nazivPu: "Vrbovsko",
        naselje: "Zdihovo",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6625",
        brojPu: "40313",
        redBroj: "6625",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Zebanec Selo",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6626",
        brojPu: "52341",
        redBroj: "6626",
        nazivPu: "Žminj",
        naselje: "Zeci",
        zupanija: "ISTARSKA",
      },
      {
        id: "6627",
        brojPu: "47240",
        redBroj: "6627",
        nazivPu: "Slunj",
        naselje: "Zečev Varoš",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6628",
        brojPu: "22300",
        redBroj: "6628",
        nazivPu: "Knin",
        naselje: "Zečevo",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6629",
        brojPu: "22202",
        redBroj: "6629",
        nazivPu: "Primošten",
        naselje: "Zečevo Rogozničko",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6630",
        brojPu: "31550",
        redBroj: "6630",
        nazivPu: "Valpovo",
        naselje: "Zelčin",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6631",
        brojPu: "42208",
        redBroj: "6631",
        nazivPu: "Cestica",
        naselje: "Zelendvor",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6632",
        brojPu: "23450",
        redBroj: "6632",
        nazivPu: "Obrovac",
        naselje: "Zelengrad",
        zupanija: "ZADARSKA",
      },
      {
        id: "6633",
        brojPu: "31300",
        redBroj: "6633",
        nazivPu: "Beli Manastir",
        naselje: "Zeleno Polje",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6634",
        brojPu: "51300",
        redBroj: "6634",
        nazivPu: "Delnice",
        naselje: "Zelin Crnoluški",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6635",
        brojPu: "51300",
        redBroj: "6635",
        nazivPu: "Delnice",
        naselje: "Zelin Mrzlovodički",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6636",
        brojPu: "10310",
        redBroj: "6636",
        nazivPu: "Ivanić Grad",
        naselje: "Zelina Breška",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6637",
        brojPu: "21203",
        redBroj: "6637",
        nazivPu: "Donji Muć",
        naselje: "Zelovo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6638",
        brojPu: "21233",
        redBroj: "6638",
        nazivPu: "Hrvace",
        naselje: "Zelovo",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6639",
        brojPu: "23222",
        redBroj: "6639",
        nazivPu: "Zemunik",
        naselje: "Zemunik Donji",
        zupanija: "ZADARSKA",
      },
      {
        id: "6640",
        brojPu: "23222",
        redBroj: "6640",
        nazivPu: "Zemunik",
        naselje: "Zemunik Gornji",
        zupanija: "ZADARSKA",
      },
      {
        id: "6641",
        brojPu: "10342",
        redBroj: "6641",
        nazivPu: "Dubrava",
        naselje: "Zetkan",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6642",
        brojPu: "10342",
        redBroj: "6642",
        nazivPu: "Dubrava",
        naselje: "Zgališće",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6643",
        brojPu: "52440",
        redBroj: "6643",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Zgrabljići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6644",
        brojPu: "10414",
        redBroj: "6644",
        nazivPu: "Pokupsko",
        naselje: "Zgurić Brdo",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6645",
        brojPu: "51511",
        redBroj: "6645",
        nazivPu: "Malinska",
        naselje: "Zidarići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6646",
        brojPu: "47220",
        redBroj: "6646",
        nazivPu: "Vojnić",
        naselje: "Zimić",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6647",
        brojPu: "47250",
        redBroj: "6647",
        nazivPu: "Duga Resa",
        naselje: "Zinajevac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6648",
        brojPu: "44000",
        redBroj: "6648",
        nazivPu: "Sisak",
        naselje: "Zirčica",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6649",
        brojPu: "22232",
        redBroj: "6649",
        nazivPu: "Zlarin",
        naselje: "Zlarin",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6650",
        brojPu: "49250",
        redBroj: "6650",
        nazivPu: "Zlatar",
        naselje: "Zlatar",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6651",
        brojPu: "49246",
        redBroj: "6651",
        nazivPu: "Marija Bistrica",
        naselje: "Zlatar-Bistrica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6652",
        brojPu: "31300",
        redBroj: "6652",
        nazivPu: "Beli Manastir",
        naselje: "Zlatna Greda",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6653",
        brojPu: "49223",
        redBroj: "6653",
        nazivPu: "Sveti Križ Začretje",
        naselje: "Zleć",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6654",
        brojPu: "51300",
        redBroj: "6654",
        nazivPu: "Delnice",
        naselje: "Zlobin",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6655",
        brojPu: "42250",
        redBroj: "6655",
        nazivPu: "Lepoglava",
        naselje: "Zlogonje",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6656",
        brojPu: "31300",
        redBroj: "6656",
        nazivPu: "Beli Manastir",
        naselje: "Zmajevac",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6657",
        brojPu: "21260",
        redBroj: "6657",
        nazivPu: "Imotski",
        naselje: "Zmijavci",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6658",
        brojPu: "49250",
        redBroj: "6658",
        nazivPu: "Zlatar",
        naselje: "Znož",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6659",
        brojPu: "33515",
        redBroj: "6659",
        nazivPu: "Orahovica",
        naselje: "Zokov Gaj",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6660",
        brojPu: "31500",
        redBroj: "6660",
        nazivPu: "Našice",
        naselje: "Zoljan",
        zupanija: "OSJEČKO-BARANJSKA",
      },
      {
        id: "6661",
        brojPu: "35214",
        redBroj: "6661",
        nazivPu: "Donji Andrijevci",
        naselje: "Zoljani",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6662",
        brojPu: "51218",
        redBroj: "6662",
        nazivPu: "Dražice",
        naselje: "Zoretići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6663",
        brojPu: "52440",
        redBroj: "6663",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Zoričići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6664",
        brojPu: "47280",
        redBroj: "6664",
        nazivPu: "Ozalj",
        naselje: "Zorkovac",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6665",
        brojPu: "47280",
        redBroj: "6665",
        nazivPu: "Ozalj",
        naselje: "Zorkovac na Kupi",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6666",
        brojPu: "47280",
        redBroj: "6666",
        nazivPu: "Ozalj",
        naselje: "Zorkovac Vivodinski",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6667",
        brojPu: "52460",
        redBroj: "6667",
        nazivPu: "Buje (Buie)",
        naselje: "Zrenj - Stridone (Sdregna)",
        zupanija: "ISTARSKA",
      },
      {
        id: "6668",
        brojPu: "44440",
        redBroj: "6668",
        nazivPu: "Dvor",
        naselje: "Zrin",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6669",
        brojPu: "43270",
        redBroj: "6669",
        nazivPu: "Veliki Grđevac",
        naselje: "Zrinska",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6670",
        brojPu: "44440",
        redBroj: "6670",
        nazivPu: "Dvor",
        naselje: "Zrinska Draga",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6671",
        brojPu: "44440",
        redBroj: "6671",
        nazivPu: "Dvor",
        naselje: "Zrinski Brđani",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6672",
        brojPu: "43000",
        redBroj: "6672",
        nazivPu: "Bjelovar",
        naselje: "Zrinski Topolovac",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6673",
        brojPu: "10380",
        redBroj: "6673",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Zrinšćina",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6674",
        brojPu: "33000",
        redBroj: "6674",
        nazivPu: "Virovitica",
        naselje: "Zrinj Lukački",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6675",
        brojPu: "23440",
        redBroj: "6675",
        nazivPu: "Gračac",
        naselje: "Zrmanja",
        zupanija: "ZADARSKA",
      },
      {
        id: "6676",
        brojPu: "23440",
        redBroj: "6676",
        nazivPu: "Gračac",
        naselje: "Zrmanja Vrelo",
        zupanija: "ZADARSKA",
      },
      {
        id: "6677",
        brojPu: "53291",
        redBroj: "6677",
        nazivPu: "Novalja",
        naselje: "Zubovići",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6678",
        brojPu: "44440",
        redBroj: "6678",
        nazivPu: "Dvor",
        naselje: "Zut",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6679",
        brojPu: "47250",
        redBroj: "6679",
        nazivPu: "Duga Resa",
        naselje: "Zvečaj",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6680",
        brojPu: "21292",
        redBroj: "6680",
        nazivPu: "Srinjine",
        naselje: "Zvečanje",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6681",
        brojPu: "10342",
        redBroj: "6681",
        nazivPu: "Dubrava",
        naselje: "Zvekovac",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6682",
        brojPu: "20210",
        redBroj: "6682",
        nazivPu: "Cavtat",
        naselje: "Zvekovica",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6683",
        brojPu: "23291",
        redBroj: "6683",
        nazivPu: "Sestrunj",
        naselje: "Zverinac",
        zupanija: "ZADARSKA",
      },
      {
        id: "6684",
        brojPu: "43000",
        redBroj: "6684",
        nazivPu: "Bjelovar",
        naselje: "Zvijerci",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6685",
        brojPu: "22300",
        redBroj: "6685",
        nazivPu: "Knin",
        naselje: "Zvjerinac",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6686",
        brojPu: "51211",
        redBroj: "6686",
        nazivPu: "Matulji",
        naselje: "Zvoneće",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6687",
        brojPu: "10342",
        redBroj: "6687",
        nazivPu: "Dubrava",
        naselje: "Zvonik",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6688",
        brojPu: "33520",
        redBroj: "6688",
        nazivPu: "Slatina",
        naselje: "Zvonimirovac",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6689",
        brojPu: "33410",
        redBroj: "6689",
        nazivPu: "Suhopolje",
        naselje: "Zvonimirovo",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6690",
        brojPu: "53000",
        redBroj: "6690",
        nazivPu: "Gospić",
        naselje: "Žabica",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6691",
        brojPu: "43000",
        redBroj: "6691",
        nazivPu: "Bjelovar",
        naselje: "Žabjak",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6692",
        brojPu: "47250",
        redBroj: "6692",
        nazivPu: "Duga Resa",
        naselje: "Žabljak",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6693",
        brojPu: "10342",
        redBroj: "6693",
        nazivPu: "Dubrava",
        naselje: "Žabnica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6694",
        brojPu: "40313",
        redBroj: "6694",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Žabnik",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6695",
        brojPu: "42202",
        redBroj: "6695",
        nazivPu: "Trnovec Bartolovečki",
        naselje: "Žabnik",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6696",
        brojPu: "44000",
        redBroj: "6696",
        nazivPu: "Sisak",
        naselje: "Žabno",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6697",
        brojPu: "33515",
        redBroj: "6697",
        nazivPu: "Orahovica",
        naselje: "Žabnjača",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6698",
        brojPu: "10340",
        redBroj: "6698",
        nazivPu: "Vrbovec",
        naselje: "Žabnjak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6699",
        brojPu: "22000",
        redBroj: "6699",
        nazivPu: "Šibenik",
        naselje: "Žaborić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6700",
        brojPu: "52341",
        redBroj: "6700",
        nazivPu: "Žminj",
        naselje: "Žagrići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6701",
        brojPu: "22300",
        redBroj: "6701",
        nazivPu: "Knin",
        naselje: "Žagrović",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6702",
        brojPu: "47000",
        redBroj: "6702",
        nazivPu: "Karlovac",
        naselje: "Žakanje",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6703",
        brojPu: "51326",
        redBroj: "6703",
        nazivPu: "Vrbovsko",
        naselje: "Žakule",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6704",
        brojPu: "10454",
        redBroj: "6704",
        nazivPu: "Krašić",
        naselje: "Žamarija",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6705",
        brojPu: "42250",
        redBroj: "6705",
        nazivPu: "Lepoglava",
        naselje: "Žarovnica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6706",
        brojPu: "44250",
        redBroj: "6706",
        nazivPu: "Petrinja",
        naselje: "Žažina",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6707",
        brojPu: "22213",
        redBroj: "6707",
        nazivPu: "Pirovac",
        naselje: "Žažvić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6708",
        brojPu: "52440",
        redBroj: "6708",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Žbandaj",
        zupanija: "ISTARSKA",
      },
      {
        id: "6709",
        brojPu: "48331",
        redBroj: "6709",
        nazivPu: "Gola",
        naselje: "Ždala",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6710",
        brojPu: "43000",
        redBroj: "6710",
        nazivPu: "Bjelovar",
        naselje: "Ždralovi",
        zupanija: "BJELOVARSKO-BILOGORSKA",
      },
      {
        id: "6711",
        brojPu: "22222",
        redBroj: "6711",
        nazivPu: "Skradin",
        naselje: "Ždrapanj",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6712",
        brojPu: "23271",
        redBroj: "6712",
        nazivPu: "Kukljica",
        naselje: "Ždrelac",
        zupanija: "ZADARSKA",
      },
      {
        id: "6713",
        brojPu: "23242",
        redBroj: "6713",
        nazivPu: "Posedarje",
        naselje: "Ždrilo",
        zupanija: "ZADARSKA",
      },
      {
        id: "6714",
        brojPu: "21220",
        redBroj: "6714",
        nazivPu: "Trogir",
        naselje: "Žedno",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6715",
        brojPu: "51211",
        redBroj: "6715",
        nazivPu: "Matulji",
        naselje: "Žejane",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6716",
        brojPu: "10297",
        redBroj: "6716",
        nazivPu: "Jakovlje",
        naselje: "Žejinci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6717",
        brojPu: "40313",
        redBroj: "6717",
        nazivPu: "Sveti Martin na Muri",
        naselje: "Železna Gora",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6718",
        brojPu: "52207",
        redBroj: "6718",
        nazivPu: "Barban",
        naselje: "Želiski",
        zupanija: "ISTARSKA",
      },
      {
        id: "6719",
        brojPu: "53230",
        redBroj: "6719",
        nazivPu: "Korenica",
        naselje: "Željava",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6720",
        brojPu: "42240",
        redBroj: "6720",
        nazivPu: "Ivanec",
        naselje: "Željeznica",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6721",
        brojPu: "10454",
        redBroj: "6721",
        nazivPu: "Krašić",
        naselje: "Željezno Žumberačko",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6722",
        brojPu: "21480",
        redBroj: "6722",
        nazivPu: "Vis",
        naselje: "Žena Glava",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6723",
        brojPu: "52440",
        redBroj: "6723",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Ženodraga",
        zupanija: "ISTARSKA",
      },
      {
        id: "6724",
        brojPu: "23232",
        redBroj: "6724",
        nazivPu: "Nin",
        naselje: "Žerava",
        zupanija: "ZADARSKA",
      },
      {
        id: "6725",
        brojPu: "10360",
        redBroj: "6725",
        nazivPu: "Sesvete",
        naselje: "Žerjavinec",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6726",
        brojPu: "51511",
        redBroj: "6726",
        nazivPu: "Malinska",
        naselje: "Žestilac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6727",
        brojPu: "21250",
        redBroj: "6727",
        nazivPu: "Šestanovac",
        naselje: "Žeževica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6728",
        brojPu: "51500",
        redBroj: "6728",
        nazivPu: "Krk",
        naselje: "Žgaljići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6729",
        brojPu: "51511",
        redBroj: "6729",
        nazivPu: "Malinska",
        naselje: "Žgombići",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6730",
        brojPu: "48260",
        redBroj: "6730",
        nazivPu: "Križevci",
        naselje: "Žibrinovec",
        zupanija: "KOPRIVNIČKO-KRIŽEVAČKA",
      },
      {
        id: "6731",
        brojPu: "49221",
        redBroj: "6731",
        nazivPu: "Bedekovčina",
        naselje: "Židovinjak",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6732",
        brojPu: "34000",
        redBroj: "6732",
        nazivPu: "Požega",
        naselje: "Žigerovci",
        zupanija: "POŽEŠKO-SLAVONSKA",
      },
      {
        id: "6733",
        brojPu: "42204",
        redBroj: "6733",
        nazivPu: "Turčin",
        naselje: "Žigrovec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6734",
        brojPu: "52440",
        redBroj: "6734",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Žikovići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6735",
        brojPu: "22236",
        redBroj: "6735",
        nazivPu: "Žirje",
        naselje: "Žirje",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6736",
        brojPu: "33410",
        redBroj: "6736",
        nazivPu: "Suhopolje",
        naselje: "Žiroslavlje",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6737",
        brojPu: "40000",
        redBroj: "6737",
        nazivPu: "Čakovec",
        naselje: "Žiškovec",
        zupanija: "MEĐIMURSKA",
      },
      {
        id: "6738",
        brojPu: "10436",
        redBroj: "6738",
        nazivPu: "Rakov Potok",
        naselje: "Žitarka",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6739",
        brojPu: "10413",
        redBroj: "6739",
        nazivPu: "Kravarsko",
        naselje: "Žitkovčica",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6740",
        brojPu: "22320",
        redBroj: "6740",
        nazivPu: "Drniš",
        naselje: "Žitnić",
        zupanija: "ŠIBENSKO-KNINSKA",
      },
      {
        id: "6741",
        brojPu: "10380",
        redBroj: "6741",
        nazivPu: "Sveti Ivan Zelina",
        naselje: "Žitomir",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6742",
        brojPu: "44430",
        redBroj: "6742",
        nazivPu: "Hrvatska Kostajnica",
        naselje: "Živaja",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6743",
        brojPu: "35250",
        redBroj: "6743",
        nazivPu: "Oriovac",
        naselje: "Živike",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6744",
        brojPu: "47220",
        redBroj: "6744",
        nazivPu: "Vojnić",
        naselje: "Živković Kosa",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6745",
        brojPu: "21327",
        redBroj: "6745",
        nazivPu: "Podgora",
        naselje: "Živogošće",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6746",
        brojPu: "10291",
        redBroj: "6746",
        nazivPu: "Prigorje Brdovečko",
        naselje: "Žlebec Gorički",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6747",
        brojPu: "10290",
        redBroj: "6747",
        nazivPu: "Zaprešić",
        naselje: "Žlebec Pušćanski",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6748",
        brojPu: "33410",
        redBroj: "6748",
        nazivPu: "Suhopolje",
        naselje: "Žlebina",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6749",
        brojPu: "23281",
        redBroj: "6749",
        nazivPu: "Sali",
        naselje: "Žman",
        zupanija: "ZADARSKA",
      },
      {
        id: "6750",
        brojPu: "52341",
        redBroj: "6750",
        nazivPu: "Žminj",
        naselje: "Žminj",
        zupanija: "ISTARSKA",
      },
      {
        id: "6751",
        brojPu: "52460",
        redBroj: "6751",
        nazivPu: "Buje (Buie)",
        naselje: "Žnjidarići - Znidarici",
        zupanija: "ISTARSKA",
      },
      {
        id: "6752",
        brojPu: "52420",
        redBroj: "6752",
        nazivPu: "Buzet",
        naselje: "Žonti",
        zupanija: "ISTARSKA",
      },
      {
        id: "6753",
        brojPu: "44210",
        redBroj: "6753",
        nazivPu: "Sunja",
        naselje: "Žreme",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6754",
        brojPu: "51300",
        redBroj: "6754",
        nazivPu: "Delnice",
        naselje: "Žrnovac",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6755",
        brojPu: "21311",
        redBroj: "6755",
        nazivPu: "Stobreč",
        naselje: "Žrnovnica",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6756",
        brojPu: "20260",
        redBroj: "6756",
        nazivPu: "Korčula",
        naselje: "Žrnovo",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6757",
        brojPu: "35430",
        redBroj: "6757",
        nazivPu: "Okučani",
        naselje: "Žuberkovac",
        zupanija: "BRODSKO-POSAVSKA",
      },
      {
        id: "6758",
        brojPu: "33410",
        redBroj: "6758",
        nazivPu: "Suhopolje",
        naselje: "Žubrica",
        zupanija: "VIROVITIČKO-PODRAVSKA",
      },
      {
        id: "6759",
        brojPu: "47250",
        redBroj: "6759",
        nazivPu: "Duga Resa",
        naselje: "Žubrinci",
        zupanija: "KARLOVAČKA",
      },
      {
        id: "6760",
        brojPu: "52440",
        redBroj: "6760",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Žudetići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6761",
        brojPu: "10342",
        redBroj: "6761",
        nazivPu: "Dubrava",
        naselje: "Žukovec",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6762",
        brojPu: "20246",
        redBroj: "6762",
        nazivPu: "Janjina",
        naselje: "Žuljana",
        zupanija: "DUBROVAČKO-NERETVANSKA",
      },
      {
        id: "6763",
        brojPu: "10454",
        redBroj: "6763",
        nazivPu: "Krašić",
        naselje: "Žumberak",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6764",
        brojPu: "10340",
        redBroj: "6764",
        nazivPu: "Vrbovec",
        naselje: "Žunci",
        zupanija: "ZAGREBAČKA",
      },
      {
        id: "6765",
        brojPu: "52341",
        redBroj: "6765",
        nazivPu: "Žminj",
        naselje: "Žuntići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6766",
        brojPu: "21270",
        redBroj: "6766",
        nazivPu: "Zagvozd",
        naselje: "Župa",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6767",
        brojPu: "21270",
        redBroj: "6767",
        nazivPu: "Zagvozd",
        naselje: "Župa Srednja",
        zupanija: "SPLITSKO-DALMATINSKA",
      },
      {
        id: "6768",
        brojPu: "42230",
        redBroj: "6768",
        nazivPu: "Ludbreg",
        naselje: "Županec",
        zupanija: "VARAŽDINSKA",
      },
      {
        id: "6769",
        brojPu: "52220",
        redBroj: "6769",
        nazivPu: "Labin",
        naselje: "Županići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6770",
        brojPu: "32270",
        redBroj: "6770",
        nazivPu: "Županja",
        naselje: "Županja",
        zupanija: "VUKOVARSKO-SRIJEMSKA",
      },
      {
        id: "6771",
        brojPu: "51511",
        redBroj: "6771",
        nazivPu: "Malinska",
        naselje: "Županje",
        zupanija: "PRIMORSKO-GORANSKA",
      },
      {
        id: "6772",
        brojPu: "44250",
        redBroj: "6772",
        nazivPu: "Petrinja",
        naselje: "Župić",
        zupanija: "SISAČKO-MOSLAVAČKA",
      },
      {
        id: "6773",
        brojPu: "53260",
        redBroj: "6773",
        nazivPu: "Brinje",
        naselje: "Žuta Lokva",
        zupanija: "LIČKO-SENJSKA",
      },
      {
        id: "6774",
        brojPu: "49000",
        redBroj: "6774",
        nazivPu: "Krapina",
        naselje: "Žutnica",
        zupanija: "KRAPINSKO-ZAGORSKA",
      },
      {
        id: "6775",
        brojPu: "52000",
        redBroj: "6775",
        nazivPu: "Pazin",
        naselje: "Žužići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6776",
        brojPu: "52440",
        redBroj: "6776",
        nazivPu: "Poreč (Parenzo)",
        naselje: "Žužići",
        zupanija: "ISTARSKA",
      },
      {
        id: "6779",
        brojPu: "23244",
        redBroj: "6779",
        nazivPu: "Starigrad Paklenica",
        naselje: "Rovanjska",
        zupanija: "ZADARSKA",
      },
      {
        id: "6780",
        brojPu: "10108",
        redBroj: "6780",
        nazivPu: "Zagreb",
        naselje: "Zagreb-Maksimir",
        zupanija: "GRAD ZAGREB",
      },
      {
        id: "6581",
        brojPu: "10000",
        redBroj: "6537",
        nazivPu: "Zagreb",
        naselje: "Zagreb",
        zupanija: "GRAD ZAGREB",
      },
    ],
  },
};

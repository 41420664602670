import { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { useStateMachine } from "little-state-machine";
import dayjs from "dayjs";
import Table from "../../components/Table";
import InvoicePreview from "./InvoicePreview";
import DeleteModal from "./DeleteModal";
import Skeleton from "../../components/Skeleton";
import { formatCurrency } from "../../helpers/index";
import { getAllInvoices } from "../../helpers/getData";
import {
  updateInvoicePreview,
  updateInvoice,
  addProizvodi,
  updateInvoicePayment,
  updateProceeds,
} from "../../helpers/updateState";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import { generateInvoice } from "./functions";

import "./Invoices.css";
import { useTitle } from "../../helpers/hooks";

dayjs().format();

const status = {
  Plaćeno: "success",
  Neplaćeno: "danger",
  Djelomično: "warning",
};

const loaderStyle = {
  height: 30,
  marginTop: 15,
};
const InvoiceReview = () => {
  const currentUser = authenticationService.currentUserValue;
  let history = useHistory();
  const { data, isFetching } = useQuery("invoices", getAllInvoices);
  const { actions, state } = useStateMachine({
    addProizvodi,
    updateInvoicePreview,
    updateInvoice,
    updateInvoicePayment,
    updateProceeds,
  });
  const [openPreview, setOpenPreview] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  useTitle("Pregled računa");
  const queryClient = useQueryClient();

  const toggle = () => {
    setOpenPreview(false);
  };

  const handleDelete = (id) => {
    handlePostRequest(
      `${process.env.REACT_APP_HOST_URL}/invoices/delete`,
      [{ id }],
      currentUser.token
    )
      .then((res) => {
        if (res.status) {
          queryClient.invalidateQueries("invoices");
          setDeleteModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Broj računa",
        accessor: "broj",
        Cell: (props) => {
          return (
            <button
              disabled={props.value.slice(0, 2) === "P-" ? true : false}
              className={`tb-odr-id blankBtn ${
                props.value.slice(0, 2) === "P-" ? "utrzak" : "selectInvoice"
              }`}
              onClick={() => {
                actions.updateInvoicePreview(
                  generateInvoice(props.row.original, currentUser)
                );
                setOpenPreview(true);
              }}
            >
              {props.value}
            </button>
          );
        },
      },

      {
        Header: "Naziv kupca",
        accessor: "kupac",
      },
      {
        Header: "Ukupan iznos",
        accessor: "konacanIznos",
        Cell: (props) => {
          return (
            <span className="tb-odr-total w-100 text-right">
              <span className="amount">{formatCurrency(props.value)} HRK</span>
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => {
          return (
            <span className="tb-odr-status">
              <span className={`badge badge-dot badge-${status[props.value]}`}>
                {props.value}
              </span>
            </span>
          );
        },
      },
      {
        Header: "Plaćeno",
        accessor: "placeno",
        Cell: (props) => {
          return (
            <span className="tb-odr-total w-100 text-right">
              <span className="amount">{formatCurrency(props.value)} HRK</span>
            </span>
          );
        },
      },
      {
        Header: "Datum naplate",
        accessor: "datumPlacanja",
      },
      {
        Header: "Preostalo",
        accessor: "preostalo",
        Cell: (props) => {
          return (
            <span className="tb-odr-total w-100 text-right">
              <span className="amount">{formatCurrency(props.value)} HRK</span>
            </span>
          );
        },
      },
      {
        Header: "Datum računa",
        accessor: "datumRacun",
      },
      {
        Header: "Datum dospijeća",
        accessor: "datumDospijeca",
      },
      {
        Header: "Pregled",
        accessor: "pregled",
        noPrint: true,
        disableSortBy: true,
        Cell: (props) => {
          return (
            <button
              disabled={
                props.row.values.broj.slice(0, 2) === "P-" ? true : false
              }
              className="blankBtn"
              onClick={() => {
                actions.updateInvoicePreview(
                  generateInvoice(props.row.original, currentUser)
                );
                setOpenPreview(true);
              }}
            >
              <em className="icon ni ni-search tableIcons"></em>
            </button>
          );
        },
      },
      /* <em className="icon ni ni-cross"></em>> */
      {
        Header: "Naplata",
        accessor: "pay",
        disableSortBy: true,
        noPrint: true,
        Cell: (props) => {
          const disabled =
            props.row.values.status === "Plaćeno"
              ? true
              : props.row.values.broj.includes("Predložak")
              ? true
              : false;
          const handlePayment = () => {
            const {
              kupacDetails,
              datumRacun,
              ukupanIznos,
              preostalo,
              proizvodi,
              placeno,
              placanja,
            } = props.row.original;
            /*   setValue("klijent", e.value.kupacDetails.naziv);
                          setValue("datumRacun", e.value.datumRacun);
                          setValue("ukupanIznos", Number(e.value.ukupanIznos));
                          setValue("preostalo", Number(e.value.preostalo));
                          setValue("placeno", Number(e.value.placeno));
                          setValue("proizvodi", e.value.proizvodi);
                          setValue("placanja", e.value.placanja); */
            const data = {
              invoice: {
                value: props.row.original,
                label: `${props.row.original.broj} - ${props.row.original.datumRacun} - ${props.row.original.kupac}`,
              },
              klijent: kupacDetails.naziv,
              datumRacun,
              ukupanIznos: Number(ukupanIznos),
              preostalo: Number(preostalo),
              placeno: Number(placeno),
              proizvodi,
              placanja,
            };
            actions.updateInvoicePayment(data);
            history.push("/unos-placanja");
          };

          return (
            <button
              className="blankBtn"
              disabled={disabled}
              onClick={handlePayment}
            >
              <em className="icon ni ni-cc-alt-fill tableIcons"></em>
            </button>
          );
        },
      },
      {
        Header: "Uredi",
        accessor: "edit",
        disableSortBy: true,
        noPrint: true,
        Cell: (props) => {
          const disabled = props.row.values.jir ? true : false;
          return (
            <button
              className="blankBtn"
              disabled={disabled}
              onClick={() => {
                const {
                  broj,
                  datumDospijeca,
                  datumRacun,
                  id,
                  izvrsenoOd,
                  izvrsenoDo,
                  nacinPlacanja,
                  kupac,
                  kupacDetails,
                  operater,
                } = props.row.original;
                const data = {
                  id,
                  broj,
                  datumDospijeca: dayjs(datumDospijeca, "DD.MM.YYYY"),
                  datumRacuna: dayjs(datumRacun, "DD.MM.YYYY HH:mm"),
                  izvrsenoOd: dayjs(izvrsenoOd, "DD.MM.YYYY"),
                  izvrsenoDo: dayjs(izvrsenoDo, "DD.MM.YYYY"),
                  nacinPlacanja: {
                    value: nacinPlacanja,
                    label: nacinPlacanja,
                  },
                  klijent: { value: kupacDetails, label: kupac },
                  operater: operater,
                  from: broj.includes("Predložak") ? "editPredlozak" : "edit",
                };
                if (props.row.values.broj.slice(0, 2) === "P-") {
                  actions.updateProceeds({
                    ...props.row.original,
                    from: "edit",
                  });
                  history.push("/utrzak");
                } else {
                  actions.updateInvoice(data);
                  actions.addProizvodi(props.row.original.proizvodi);
                  history.push("/novi-racun");
                }
              }}
            >
              <em className="icon ni ni-edit tableIcons"></em>
            </button>
          );
        },
      },
      {
        Header: "Izbriši",
        accessor: "delete",
        disableSortBy: true,
        noPrint: true,
        Cell: (props) => {
          const lastItem =
            data &&
            [...data]
              .filter((item) => item.jir === null || item.jir === "")
              .sort((a, b, order, dataField) => {
                return (
                  a.broj.substring(0, a.broj.indexOf(a.broj.match(/\D/))) -
                  b.broj.substring(0, b.broj.indexOf(b.broj.match(/\D/)))
                ); // desc
              });
          const disabled =
            props.row.values.broj === lastItem[lastItem.length - 1].broj
              ? false
              : props.row.values.broj.includes("Predložak")
              ? false
              : props.row.values.broj.includes("P-")
              ? false
              : true;
          return (
            <button
              disabled={disabled}
              onClick={() => {
                actions.updateInvoicePreview(props.row.original);
                setDeleteModal(true);
              }}
              className="blankBtn"
            >
              <em
                className="icon ni ni-cross tableIcons"
                style={{ color: "#E85347" }}
              ></em>
            </button>
          );
        },
      },
    ],
    [data, actions, history, currentUser]
  );
  useEffect(() => {
    if (!isFetching && state.invoicePreview.from === "newInvoice") {
      setOpenPreview(true);
    }
  }, [isFetching, state]);

  return (
    <div className="container-fluid">
      {isFetching ? (
        <div>
          <h3>Učitavanje...</h3>
          <Skeleton style={loaderStyle} />
          <Skeleton style={loaderStyle} />
          <Skeleton style={loaderStyle} />
          <Skeleton style={loaderStyle} />
          <Skeleton style={loaderStyle} />
          <Skeleton style={loaderStyle} />
          <Skeleton style={loaderStyle} />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            data={data}
            name="pregled_racuna"
            pdfName="pregledRacuna"
            exportCols={columns.slice(0, columns.length - 4)}
            //replace text and dataField inside invoicehr-en html/pdf template
            pdfCols={columns
              .slice(0, columns.length - 4)
              .map((item) => ({ text: item.Header, dataField: item.accessor }))}
            exportData={data.map((item) => ({
              broj: item.broj,
              kupac: item.kupac,
              datumRacun: item.datumRacun,
              datumDospijeca: item.datumDospijeca,
              datumPlacanja: item.datumPlacanja,
              status: item.status,
              konacanIznos: item.konacanIznos,
              placeno: item.placeno,
              preostalo: item.preostalo,
            }))}
          />
        </>
      )}
      {openPreview && (
        <InvoicePreview data={data} open={openPreview} toggle={toggle} />
      )}
      {deleteModal && (
        <DeleteModal
          open={deleteModal}
          toggle={setDeleteModal}
          state={state}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default InvoiceReview;

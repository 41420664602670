import { forwardRef, useState } from "react";
import dayjs from "dayjs";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayJsUtils from "@date-io/dayjs";
import hr from "dayjs/locale/hr";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

const handleFocus = (event) => event.target.select();

const CustomInput = (props) => {
  return (
    <input
      {...props}
      className="form-control form-control-lg form-control-outlined date-picker"
      id="outlined-date-picker"
      name={props.name}
      ref={props.inputRef}
      onFocus={(e) => {
        handleFocus(e);
        props.setFocus(true);
      }}
      onBlur={() => props.setFocus(false)}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

const Datepicker = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setFocus] = useState(false);
  return (
    <MuiPickersUtilsProvider
      className="datepicker"
      utils={DayJsUtils}
      locale={hr}
    >
      <div className="form-control-wrap w-100">
        {props.pickerType === "pure" ? (
          <DatePicker
            {...props}
            inputRef={ref}
            cancelLabel="Odustani"
            okLabel="Ok"
            open={isOpen}
            invalidDateMessage="Neispravan datum"
            isFocused={isFocused}
            invalidLabel="Erorr"
            setFocus={setFocus}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            TextFieldComponent={CustomInput}
          />
        ) : (
          <KeyboardDatePicker
            {...props}
            inputRef={ref}
            cancelLabel="Odustani"
            okLabel="Ok"
            open={isOpen}
            invalidDateMessage="Neispravan datum"
            isFocused={isFocused}
            invalidLabel="Erorr"
            setFocus={setFocus}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            TextFieldComponent={CustomInput}
          />
        )}
        <div className="form-icon form-icon-right">
          <em
            className="icon ni ni-calendar-alt"
            style={{ cursor: "pointer" }}
            onClick={() => setIsOpen(!isOpen)}
          ></em>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
});

export default Datepicker;

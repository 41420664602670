import { useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { updateInvoice, updateProduct } from "../../helpers/updateState";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Input from "../../components/Input";
import InputValue from "../../components/InputValue";
import CustomSelect from "../../components/Select";
import NumberFormat from "react-number-format";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";

const NewProduct = ({ open, toggle, from, setProduct, products }) => {
  const currentUser = authenticationService.currentUserValue;
  const queryClient = useQueryClient();
  const { actions, state } = useStateMachine({
    updateInvoice,
    updateProduct,
  });
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
    setError,
    trigger,
  } = useForm();

  const submit = (data) => {
    if (
      from !== "editProduct" &&
      products.data.filter(function (e) {
        return e.naziv.toLowerCase() === data.naziv.toLowerCase();
      }).length > 0
    ) {
      setError("naziv", {
        type: "manual",
        message: "Unos s tim nazivom već postoji",
        shouldFocus: true,
      });
    } else if (
      from !== "editProduct" &&
      products.data.filter(function (e) {
        return Number(e.sifra) === Number(data.sifra);
      }).length > 0
    ) {
      setError("sifra", {
        type: "manual",
        message: "Unos s tom šifrom već postoji",
        shouldFocus: true,
      });
    } else {
      if (typeof data.cijena === typeof "") {
        data.cijena = Number(data.cijena.replaceAll(".", "").replace(",", "."));
      }
      data.id = state.productData.id;
      data.vrstaIsporuke = data.vrstaIsporuke.label;
      data.einvoiceUnit = "";
      handlePostRequest(
        `${process.env.REACT_APP_HOST_URL}/products/${
          from === "editProduct" ? "update" : "create"
        }`,
        [data],
        currentUser.token
      )
        .then((res) => {
          toggle();
          const { data: productData } = res.data;
          queryClient.invalidateQueries("products");
          if (from === "newInvoice") {
            actions.updateInvoice({
              proizvod: { label: productData.naziv, value: productData },
            });
            setProduct("proizvod", {
              label: productData.naziv,
              value: productData,
            });
            setProduct("cijena", productData.cijena);
          } else if (from === "newIncoming") {
            console.log(res.productData);
            setProduct("proizvod", {
              label: productData.naziv,
              value: productData,
            });
            setProduct("cijena", productData.cijena);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Modal
      open={open}
      toggle={toggle}
      title={from === "editProduct" ? "Uredi proizvod" : "Novi proizvod"}
    >
      <form
        onSubmit={handleSubmit(submit)}
        className="form form-validate is-alter"
      >
        <div className="form-group">
          <label className="form-label" htmlFor="full-name">
            Vrsta isporuke:
          </label>
          <div className="form-control-wrap">
            <Controller
              name="vrstaIsporuke"
              classnames="form-control-lg"
              control={control}
              id="vrstaIsporuke"
              defaultValue={state.productData.vrstaIsporuke}
              rules={{ required: "Izaberi vrstu isporuke" }}
              placeholder="Izaberi vrstu isporuke"
              menuPlacement="auto"
              options={[
                { label: "Roba", value: "Roba" },
                { label: "Usluga", value: "Usluga" },
              ]}
              noOptionsMessage={() => "Vrsta usluge ne postoji"}
              as={CustomSelect}
            />

            {errors.vrstaIsporuke && (
              <span className="error">{errors.vrstaIsporuke.message}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="email-address">
            Naziv:
          </label>
          <div className="form-control-wrap">
            <Controller
              classnames="form-control-lg"
              name="naziv"
              id="naziv"
              control={control}
              defaultValue={state.productData.naziv}
              rules={{ required: "Naziv je obvezan" }}
              placeholder="Naziv"
              as={Input}
            />

            {errors.naziv && (
              <span className="error">{errors.naziv.message}</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Šifra:
          </label>
          <div className="form-control-wrap">
            <Controller
              classnames="form-control-lg"
              name="sifra"
              id="sifra"
              control={control}
              defaultValue={state.productData.sifra}
              rules={{ required: "Šifra je obvezna" }}
              placeholder="Šifra"
              as={Input}
            />

            {errors.sifra && (
              <span className="error">{errors.sifra.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Cijena:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="cijena"
              control={control}
              name="cijena"
              defaultValue={state.productData.cijena}
              rules={{
                required: "Unesi cijenu",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  name={name}
                  type="text"
                  getInputRef={ref}
                  classnames="form-control-lg"
                  placeholder="Cijena"
                  customInput={InputValue}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  fixedDecimalScale
                  inputMode="decimal"
                  decimalScale={2}
                  decimalSeparator={","}
                  thousandSeparator={"."}
                />
              )}
            />
            {errors.cijena && (
              <span className="error">{errors.cijena.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="phone-no">
            Mjerna jedinica:
          </label>
          <div className="form-control-wrap">
            <Controller
              classnames="form-control-lg"
              name="unit"
              id="unit"
              control={control}
              defaultValue={state.productData.unit}
              rules={{ required: "Mjerna jedinica je obvezna" }}
              placeholder="Mjerna jedinica"
              as={Input}
            />

            {errors.unit && (
              <span className="error">{errors.unit.message}</span>
            )}
          </div>
        </div>
        <div className="form-group pt-2">
          <div className="form-control-wrap row">
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-start">
              <Button
                type="submit"
                classnames="btn-dim btn-outline-primary btn-lg"
              >
                {from === "editProduct"
                  ? "Ažuriraj proizvod"
                  : "Dodaj novi proizvod"}
              </Button>
            </div>
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-end">
              <Button
                type="button"
                onClick={toggle}
                classnames="btn-dim btn-outline-danger btn-lg"
              >
                Izađi bez spremanja
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default NewProduct;

import dayjs from "dayjs";
import { useMemo } from "react";
import { useQuery } from "react-query";
import Table from "../../components/Table";
import { formatCurrency } from "../../helpers";
import { getInventura } from "../../helpers/getData";
import { useTitle } from "../../helpers/hooks";

const StockHistory = () => {
  useTitle("Pregled skladišta");
  const { data, isFetching } = useQuery("stock", getInventura);
  console.log(data);

  const columns = useMemo(
    () => [
      {
        Header: "Datum",
        accessor: "datum",
        Cell: (props) => dayjs(props.value).format("DD.MM.YYYY HH:mm"),
      },
      { Header: "Broj računa", accessor: "broj" },
      { Header: "Naziv", accessor: "naziv" },
      {
        Header: "Ulazna količina",
        accessor: "ulazKolicina",
        Cell: (props) => (props.value ? formatCurrency(props.value) : ""),
      },
      {
        Header: "Ulazna cijena",
        accessor: "ulazVrijednost",
        Cell: (props) => (props.value ? formatCurrency(props.value) : ""),
      },
      {
        Header: "Izlazna količina",
        accessor: "izlazKolicina",
        Cell: (props) => (props.value ? formatCurrency(props.value) : ""),
      },
      {
        Header: "Izlazna cijena",
        accessor: "izlazVrijednost",
        Cell: (props) => (props.value ? formatCurrency(props.value) : ""),
      },
      {
        Header: "Saldo",
        accessor: "saldo",
        Cell: (props) => formatCurrency(props.value),
      },
    ],
    []
  );

  return (
    <div className="container-fluid">
      {isFetching ? (
        <div>
          <h3>Učitavanje</h3>
        </div>
      ) : (
        <Table
          columns={columns}
          data={data.history}
          name="skladiste"
          pdfName="pregledRacuna"
          exportCols={columns}
          //replace text and dataField inside invoicehr-en html/pdf template
          pdfCols={columns.map((item) => ({
            text: item.Header,
            dataField: item.accessor,
          }))}
          exportData={data.history.map((item) => ({
            datum: dayjs(item.datum).format("DD.MM.YYYY HH:mm"),
            broj: item.broj,
            naziv: item.naziv,
            ulazKolicina: item.ulazKolicina ? item.ulazKolicina : "",
            ulazCijena: item.ulazVrijednost ? item.ulazVrijednost : "",
            izlazKolicina: item.izlazKolicina ? item.izlazKolicina : "",
            izlazCijena: item.izlazVrijednost ? item.izlazVrijednost : "",
            saldo: item.saldo,
          }))}
        />
      )}
    </div>
  );
};

export default StockHistory;

import { authenticationService } from "../services/authentication";
import axios from "axios";

export function handleResponse(response) {
  return response.json().then((json) => {
    const data = json;
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        window.location.reload();
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
const instance = axios.create();
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //console.log("handle function log", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //replace with currentUser.username later
    console.log(error);
    authenticationService.logout();
    sessionStorage.setItem(
      "sessionEndMsg",
      "Sesija je istekla, ponovo se prijavite."
    );
    window.location.reload();
    return Promise.reject(error);
  }
);

export function handlePostRequest(url, options, token) {
  return instance.post(url, ...options, {
    headers: {
      Authorization: `JWT ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  });
}
export function handleGetRequest(url, token, type) {
  return instance.get(url, {
    headers: {
      Authorization: `JWT ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
    responseType: type,
  });
}
export function handleGetCountries(url) {
  return instance.get(url);
}
export function handlePutRequest(url, options, token) {
  return instance.put(url, ...options, {});
}
export function handleGetXml(url, xmls) {
  return instance.post(url, xmls);
}

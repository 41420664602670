import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../helpers/handle-response";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

if (sessionStorage.getItem("currentUser") === "") {
  sessionStorage.removeItem("currentUser");
}
const currentUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("currentUser"))
);
export const authenticationService = {
  login,
  googleLogin,
  logout,
  register,
  setUser,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function setUser(user) {
  sessionStorage.setItem("currentUser", JSON.stringify(user));
  currentUserSubject.next(user);
  return user;
}
function googleLogin(cookie) {
  const decoded = jwt_decode(cookie);
  const user = {
    id: decoded.id,
    username: decoded.username,
    email: decoded.email,
    logo: decoded.logo,
    naziv: decoded.naziv,
    vlasnik: decoded.vlasnik,
    ime: decoded.ime,
    prezime: decoded.prezime,
    adresa: decoded.adresa,
    ulica: decoded.ulica,
    kucniBroj: decoded.kucniBroj,
    mjesto: decoded.mjesto,
    postanskiBroj: decoded.postanskiBroj,
    oib: decoded.oib,
    iban: decoded.iban,
    banka: decoded.banka,
    telefon: decoded.telefon,
    djelatnost: decoded.djelatnost,
    nazivDjelatnosti: decoded.nazivDjelatnosti,
    delimiter: decoded.delimiter,
    brojRacuna: decoded.brojRacuna,
    exp: decoded.exp,
    oznakaProstora: decoded.oznakaProstora,
    oznakaUredaja: decoded.oznakaUredaja,
    operater: decoded.operater !== null ? decoded.operater : "",
    oibOperatera: decoded.oibOperatera !== null ? decoded.oibOperatera : "",
    emailRacun: decoded.emailRacun !== null ? decoded.emailRacun : "",
    podrucniUred: decoded.podrucniUred !== null ? decoded.podrucniUred : "",
    ispostava: decoded.ispostava !== null ? decoded.ispostava : "",
    poreznaKod: decoded.poreznaKod !== null ? decoded.poreznaKod : "",
    pdvid: decoded.pdvid !== null ? decoded.pdvid : "",
    uSustPDV: decoded.uSustPDV !== null ? decoded.uSustPDV : false,
    fromGoogle: true,
    token: cookie,
  };
  // store user details and jwt token in local storage to keep user logged in between page refreshes
  setUser(user);
  return user;
}
function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${process.env.REACT_APP_HOST_URL}/users/login`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      const decoded = jwt_decode(res);
      const user = {
        id: decoded.id,
        username: decoded.username,
        email: decoded.email,
        logo: decoded.logo,
        naziv: decoded.naziv,
        vlasnik: decoded.vlasnik,
        ime: decoded.ime,
        prezime: decoded.prezime,
        adresa: decoded.adresa,
        ulica: decoded.ulica,
        kucniBroj: decoded.kucniBroj,
        mjesto: decoded.mjesto,
        postanskiBroj: decoded.postanskiBroj,
        oib: decoded.oib,
        iban: decoded.iban,
        banka: decoded.banka,
        telefon: decoded.telefon,
        djelatnost: decoded.djelatnost,
        nazivDjelatnosti: decoded.nazivDjelatnosti,
        delimiter: decoded.delimiter,
        brojRacuna: decoded.brojRacuna,
        exp: decoded.exp,
        oznakaProstora: decoded.oznakaProstora,
        oznakaUredaja: decoded.oznakaUredaja,
        operater: decoded.operater !== null ? decoded.operater : "",
        oibOperatera: decoded.oibOperatera !== null ? decoded.oibOperatera : "",
        emailRacun: decoded.emailRacun !== null ? decoded.emailRacun : "",
        podrucniUred: decoded.podrucniUred !== null ? decoded.podrucniUred : "",
        ispostava: decoded.ispostava !== null ? decoded.ispostava : "",
        poreznaKod: decoded.poreznaKod !== null ? decoded.poreznaKod : "",
        pdvid: decoded.pdvid !== null ? decoded.pdvid : "",
        uSustPDV: decoded.uSustPDV !== null ? decoded.uSustPDV : false,
        token: res,
      };
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      setUser(user);
      return user;
    })
    .catch((error) => {
      return error;
    });
}

function logout(callback) {
  const currentUser = authenticationService.currentUserValue;
  // remove user from local storage to log user out
  sessionStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  if (currentUser.fromGoogle) {
    Cookies.remove("currentUser");
    window.open(`${process.env.REACT_APP_HOST_URL}/users/google-logout`, "_self");
  }
  callback();
}

function register(values) {
  const tempData = new FormData();
  for (var value in values) {
    tempData.append(value, values[value]);
    /* if (values[value] === undefined || values[value] === "") {
      tempData.append(value, "");
    } */
  }
  const requestOptions = {
    method: "POST",
    body: tempData,
  };

  return fetch(
    `${process.env.REACT_APP_HOST_URL}/users/register`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log("error: " + error);
      return error;
    });
}

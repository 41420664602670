import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { createBrowserHistory } from "history";
import { StateMachineProvider, createStore } from "little-state-machine";
import { allInitialState } from "../../initialState";
import { authenticationService } from "../../services/authentication";
import Header from "../../components/Header";
import Login from "../auth/Login";
import Register from "../auth/Register";
import Main from "../Main/Main";
import Footer from "../../components/Footer";
import NoMatch from "../NoMatch";
import PrivateRoute from "../../components/PrivateRoute";
import NewInvoice from "../Invoices/NewInvoice";
import InvoiceReview from "../Invoices/InvoiceReview";
import NewPayment from "../Payments/NewPayment";
import NewIncoming from "../IncomingInvoices/NewIncoming";
import StatReview from "../Statistics/StatReview";
import Settings from "../Settings/Settings";
import PoSd from "../Forms/PoSd";
import Tz from "../Forms/Tz";
import ClientsReview from "../Clients/ClientsReview";
import ProductsReview from "../Products/ProductsReview";
import EPorezna from "../Settings/EPorezna";
import PaymentReview from "../Payments/PaymentReview";
import KprReview from "../KPR/KprReview";
import WarehouseReview from "../Warehouse.js/WarehouseReview";
import ForgotPassword from "../auth/ForgotPassword";
import StockHistory from "../Warehouse.js/StockHistory";
import Proceeds from "../Invoices/Proceeds";
import NewPassword from "../auth/NewPassword";
import TitleContext, { getWelcomeMessage } from "../../helpers/context";
import { ScrollToTop } from "../../helpers/hooks";

const queryClient = new QueryClient();

let history = createBrowserHistory();

createStore(allInitialState);

function App() {
  const currentUser = authenticationService.currentUserValue;
  const [title, setTitle] = useState(getWelcomeMessage());
  const titleValue = { title, setTitle };

  return (
    <>
      <Router hisotry={history}>
        <ScrollToTop />
        <TitleContext.Provider value={titleValue}>
          <Header disabled={false} currentUser={currentUser} />
          <main id="main" className="mt-3">
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <QueryClientProvider client={queryClient}>
              <StateMachineProvider>
                <Switch>
                  <Route exact path="/login">
                    <Login currentUser={currentUser} />
                  </Route>
                  <Route exact path="/login/:cookie">
                    <Login currentUser={currentUser} />
                  </Route>
                  <Route path="/register">
                    <Register />
                  </Route>
                  <Route exact path="/zaboravljena-lozinka">
                    <ForgotPassword />
                  </Route>
                  <Route exact path="/reset/:token/:email">
                    <NewPassword />
                  </Route>
                  <PrivateRoute exact path="/">
                    <Main currentUser={currentUser} />
                  </PrivateRoute>
                  <PrivateRoute exact path="/novi-racun" checkUser={true}>
                    <NewInvoice />
                  </PrivateRoute>
                  <PrivateRoute exact path="/utrzak" checkUser={true}>
                    <Proceeds />
                  </PrivateRoute>
                  <PrivateRoute exact path="/pregled-racuna" checkUser={true}>
                    <InvoiceReview />
                  </PrivateRoute>
                  <PrivateRoute exact path="/unos-placanja" checkUser={true}>
                    <NewPayment />
                  </PrivateRoute>
                  <PrivateRoute exact path="/pregled-placanja" checkUser={true}>
                    <PaymentReview />
                  </PrivateRoute>
                  <PrivateRoute exact path="/kpr" checkUser={true}>
                    <KprReview />
                  </PrivateRoute>
                  <PrivateRoute exact path="/ulazni-racun" checkUser={true}>
                    <NewIncoming />
                  </PrivateRoute>
                  <PrivateRoute exact path="/statistika" checkUser={true}>
                    <StatReview />
                  </PrivateRoute>
                  <PrivateRoute path="/postavke">
                    <Settings />
                  </PrivateRoute>
                  <PrivateRoute exact path="/po-sd" checkUser={true}>
                    <PoSd />
                  </PrivateRoute>{" "}
                  <PrivateRoute exact path="/tz" checkUser={true}>
                    <Tz />
                  </PrivateRoute>
                  <PrivateRoute exact path="/klijenti" checkUser={true}>
                    <ClientsReview />
                  </PrivateRoute>
                  <PrivateRoute exact path="/proizvodi" checkUser={true}>
                    <ProductsReview />
                  </PrivateRoute>
                  <PrivateRoute exact path="/eporezna" checkUser={true}>
                    <EPorezna />
                  </PrivateRoute>
                  <PrivateRoute exact path="/inventura" checkUser={true}>
                    <WarehouseReview />
                  </PrivateRoute>
                  <PrivateRoute exact path="/skladiste" checkUser={true}>
                    <StockHistory />
                  </PrivateRoute>
                  <Route path="*">
                    <NoMatch />
                  </Route>
                </Switch>
              </StateMachineProvider>
            </QueryClientProvider>
          </main>
          <Footer disabled={false} />
        </TitleContext.Provider>
      </Router>
    </>
  );
}

export default App;

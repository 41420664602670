import { Bar } from "./Charts";

const StatBarChart = ({ data, timeMsg, size }) => {
  return (
    <div className="card card-preview h-100">
      <div className="card-inner h-100">
        <div className="card-head text-center mb-0">
          <h6 className="title">Primici {timeMsg}</h6>
        </div>
        <div className="h-100">
          <div className="chartjs-size-monitor h-100">
            <div className="chartjs-size-monitor-expand h-100">
              <div style={{ height: "100%" }}>
                <Bar
                  size={size}
                  data={Object.keys(data)
                    .map((key) => {
                      return {
                        datum: new Date(key).toString(),
                        Iznos: data[new Date(key)],
                      };
                    })
                    .filter((item) => item.Iznos !== 0)
                    .reverse()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatBarChart;

import Button from "../../components/Button";
import CustomModal from "../../components/Modal";

const DeleteModal = ({
  deleteClient,
  handleDelete,
  deleteModal,
  setDeleteModal,
  status,
  setStatus,
}) => {
  return (
    <CustomModal
      title="Obriši klijenta"
      open={deleteModal}
      toggle={() => setDeleteModal((open) => !open)}
      footer="Klijent će biti trajno izbrisan"
    >
      <div>
        <h4 className="text-center">
          Jeste li sigurni da želite obrisati klijenta?
        </h4>{" "}
        <h3 className="text-center">{deleteClient?.naziv}</h3>
        {!status.state && status.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto">
            <div
              className="alert alert-dismissible alert-icon alert-fill alert-success"
              role="alert"
            >
              <em className="icon ni ni-check-circle"></em>
              {status.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() =>
                  setStatus({ state: false, sending: false, message: "" })
                }
              ></button>
            </div>
          </div>
        )}
        <div className="d-flex col">
          <Button
            disabled={status.sending}
            onClick={handleDelete}
            classnames="btn-dim btn-lg btn-outline-danger mx-auto my-1"
          >
            Obriši
          </Button>
        </div>
      </div>
    </CustomModal>
  );
};

export default DeleteModal;

import { useState } from "react";
import { authenticationService } from "../../services/authentication";
import EditInvoice from "./EditInvoice";
import Button from "../../components/Button";

const SettingsInvoice = (props) => {
  const currentUser = authenticationService.currentUserValue;
  const [open, setOpen] = useState({ state: false, to: "" });
  const toggle = (to) => {
    setOpen((open) => ({ state: !open.state, to: to }));
  };

  return (
    <div className="w-100">
      <div className="card-inner card-inner-lg ">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">Fiskalizacija</h4>
            </div>
            <div className="nk-block-head-content align-self-start d-lg-none">
              <a className="toggle btn btn-icon btn-trigger mt-n1" onClick={props.setOpen} >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
            </div>
            <div className="nk-block-head-content align-self-center d-none d-lg-block">
              <Button
                classnames="btn-lg btn-dim btn-outline-primary"
                onClick={toggle}
              >
                Uredi
              </Button>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">Basics</h6>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("brojRacuna")}
            >
              <div className="data-col">
                <span className="data-label">Broj prošlog računa:</span>
                <span className="data-value">{currentUser.brojRacuna}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("oznakaProstora")}
            >
              <div className="data-col">
                <span className="data-label">Oznaka poslovnog prostora:</span>
                <span className="data-value">{currentUser.oznakaProstora}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("oznakaUredaja")}
            >
              <div className="data-col">
                <span className="data-label">Oznaka naplatnog uređaja:</span>
                <span className="data-value">{currentUser.oznakaUredaja}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more disable">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("operater")}
            >
              <div className="data-col">
                <span className="data-label">Operater:</span>
                <span className="data-value">{currentUser.operater}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
              onClick={() => toggle("oibOperatera")}
            >
              <div className="data-col">
                <span className="data-label">OIB operatera:</span>
                <span className="data-value">{currentUser.oibOperatera}</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
            >
              <div className="data-col">
                <span className="data-label">Interni akt:</span>
                <span className="data-value">generate</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>

            <div
              className="data-item"
              data-toggle="modal"
              data-target="#profile-edit"
            >
              <div className="data-col">
                <span className="data-label">
                  Odluka o blagajničkom maksimumu:
                </span>
                <span className="data-value">generate</span>
              </div>
              <div className="data-col data-col-end">
                <span className="data-more">
                  <em className="icon ni ni-forward-ios"></em>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditInvoice open={open} toggle={toggle} />
    </div>
  );
};

export default SettingsInvoice;

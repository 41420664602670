import { Modal } from "@material-ui/core";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "../../components/Button";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PoSdPreview = ({
  open,
  setOpen,
  html,
  pageNumber,
  setPageNumber,
  numPages,
  setNumPages,
}) => {
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="container card mt-4">
        <button className="close ml-auto mr-1 mt-2" onClick={() => setOpen(false)}>
          <em className="icon ni ni-cross"></em>
        </button>
        <div className="row">
          <Document
            className="mx-auto"
            file={`data:application/pdf;base64,${html}`}
            loading="Učitavanje dokumenta..."
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
          >
            <Page width={1050} height={700} pageNumber={pageNumber} />
          </Document>
        </div>
        <div className="row mx-auto my-2">
          <p>
            Stranica {pageNumber} od {numPages}
          </p>
        </div>
        <div className="row mx-auto my-2">
          {pageNumber === 1 ? (
            <Button
              classnames="customButton btn-dim btn-outline-primary form-control-lg d-flex my-2 mx-auto ml-lg-auto mr-lg-0 my-lg-0 "
              style={{ height: 30 }}
              onClick={() => setPageNumber(2)}
            >
              Sljedeća
            </Button>
          ) : (
            <Button
              classnames="customButton btn-dim btn-outline-primary form-control-lg d-flex my-2 mx-auto ml-lg-auto mr-lg-0 my-lg-0 "
              style={{ height: 30 }}
              onClick={() => setPageNumber(1)}
            >
              Prošla
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PoSdPreview;

import dayjs from "dayjs";
import { createCanvas, stringToNum } from "../../helpers/functions";
import { handlePostRequest } from "../../helpers/handle-response";

export const generateInvoice = (data, currentUser) => {
  console.log(data, currentUser);
  const code =
    "HRVHUB30\nHRK\n" +
    `${Number(data.konacanIznos).toFixed(2)} \n` +
    `${data.kupac} \n` +
    `${data.kupacDetails.adresa
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")} \n` +
    `${data.kupacDetails.postanskiBroj} ${data.kupacDetails.mjesto} \n` +
    `${currentUser && currentUser.naziv} \n` +
    `${currentUser && currentUser.adresa} \n` +
    `${currentUser && currentUser.postanskiBroj} ${
      currentUser && currentUser.mjesto
    } \n` +
    `${currentUser && currentUser.iban} \n` +
    `HR00\n` +
    `${dayjs(data.datumRacun, "DD.MM.YYYY").$y}${data.broj.slice(
      0,
      data.broj.indexOf("-")
    )}\n` +
    `COST\n` +
    `Račun: ${data.broj}\n`;
  const obj = { ...data, canvas: createCanvas(code).firstChild.toDataURL() };
  return obj;
};

export const updateInvoiceTemplate = (
  data,
  currentUser,
  broj,
  isPredlozak,
  setStatus,
  queryClient,
  toggle
) => {
  const obj = {
    ...data,
    broj: isPredlozak ? broj.predlozakNum : broj.invoiceNumber,
    datum: data.datumRacuna,
    izvrsenoOd: dayjs(data.izvrsenoOd, "DD.MM.YYYY").$d,
    izvrsenoDo: dayjs(data.izvrsenoDo, "DD.MM.YYYY").$d,
    datumDospijeca: dayjs(data.datumDospijeca, "DD.MM.YYYY").$d,
    user: currentUser.id,
  };
  handlePostRequest(
    `${process.env.REACT_APP_HOST_URL}/invoices${
      isPredlozak ? "/add" : "/update-predlozak"
    }`,
    [obj],
    currentUser.token
  )
    .then((res) => {
      if (res.data.error) {
        setStatus({
          state: false,
          error: res.data.error,
          message: "",
          loading: false,
        });
      } else {
        setStatus({
          state: true,
          loading: false,
          message: `Račun ${res.data.status} ${
            res.data.id ? "izdan" : "spremljen"
          }.`,
          error: "",
        });
        setTimeout(() => {
          setStatus({
            state: true,
            message: "",
            error: "",
            loading: false,
          });
          //push to /pregled racuna
          toggle();
          queryClient.invalidateQueries("invoiceNumber");
          queryClient.invalidateQueries("invoices");
        }, 2500);
      }
    })
    .catch((err) => console.log(err));
  return obj;
};
export function addIncomingProduct(data, proizvodi, currencyVal) {
  const kolicina = stringToNum(data.kolicina);
  const cijena = stringToNum(data.cijena);
  const popust = stringToNum(data.popust);

  const totalPrice = cijena * kolicina;
  const totalPopust = totalPrice * (popust / 100);
  const newPrice = totalPrice - totalPopust;
  const hrkPrice = cijena * currencyVal;
  const popustCalculated = hrkPrice * (popust / 100);
  console.log(
    "inside add incoming",
    newPrice * currencyVal,
    hrkPrice - popustCalculated,
    hrkPrice,
    popust > 0,
    popustCalculated
  );
  const product = {
    ...data.proizvod.value,
    kolicina,
    cijena,
    ukupnaCijena: cijena * kolicina,
    rabat: popust,
    popust: totalPopust,
    newPrice,
    pdvPrice: newPrice,
    option: { value: data.proizvod.value, label: data.proizvod.label },
    cijenaHRK: newPrice * currencyVal,
    oneCijenaHRK: hrkPrice - popustCalculated,
  };
  const exists = proizvodi.find((item) => {
    if (
      item.id === product.id &&
      item.cijena === product.cijena &&
      item.rabat === product.rabat
    ) {
      return item;
    } else return null;
  });
  if (exists) {
    const kolicina = exists.kolicina + product.kolicina;
    const totalPrice = exists.cijena * kolicina;
    const totalPopust = totalPrice * (product.rabat / 100);
    const newPrice = totalPrice - totalPopust;

    Object.assign(exists, {
      kolicina,
      ukupnaCijena: newPrice,
      pdvPrice: newPrice,
      newPrice: newPrice,
      cijenaHRK: newPrice * currencyVal,
    });
  }
  const products = exists
    ? proizvodi.filter((item) => {
        if (
          item.id === product.id &&
          item.cijena === product.cijena &&
          item.rabat === product.rabat
        ) {
          return false;
        } else return true;
      })
    : proizvodi;
  return { products, product, exists };
}

export const checkProductErrors = (proizvod, proizvodi, setError) => {
  const exists = proizvodi.find((item) => {
    if (item.id === proizvod.id) {
      return item;
    }
    return null;
  });
  const totalAll =
    proizvodi.length > 0 &&
    proizvodi.reduce((a, b) => ({
      kolicina: a.kolicina + b.kolicina,
    }));
  const totalWithExists =
    totalAll.hasOwnProperty("kolicina") &&
    totalAll.kolicina + proizvod.kolicina;
  console.log({ totalAll, proizvodi, proizvod, exists, totalWithExists });
  if (
    proizvod.vrstaIsporuke === "Roba" &&
    (proizvod.skladiste === undefined || proizvod.skladiste.stanje.ukupno < 1)
  ) {
    setError("proizvod", {
      type: "manual",
      message: "Nema proizvoda na skladištu",
    });
    return { state: true, msg: "Errors found" };
  } else if (
    (proizvod.vrstaIsporuke === "Roba" &&
      (proizvod.skladiste === undefined ||
        proizvod.skladiste.stanje.ukupno < proizvod.kolicina)) ||
    (exists && exists.skladiste.stanje.ukupno < totalWithExists)
  ) {
    setError("kolicina", {
      type: "manual",
      message: "Količina je veća od količine na skladištu",
    });
    return { state: true, msg: "Errors found" };
  } else if (exists && exists.kolicina >= exists.skladiste.stanje.ukupno) {
    console.log(
      "should errror",
      exists.kolicina,
      exists.skladiste.stanje.ukupno
    );
    setError("kolicina", "Ukupna količina sa skladišta već dodana");
    return { state: true, msg: "Errors found" };
  } else {
    return { state: false, msg: "No errors" };
  }
};

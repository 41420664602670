import { useState, useRef } from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import SettingsPersonal from "./SettingsPersonal";
import SettingsCompany from "./SettingsCompany";
import SettingsInvoice from "./SettingsInvoice";
import ResetPassword from "../Settings/ResetPassword";
import ProductsReviewSettings from "../Settings/ProductsReviewSettings";
import ClientsReviewSettings from "./ClientsReviewSettings";
import { authenticationService } from "../../services/authentication";
import { Link } from "react-router-dom";
import { useTitle, useOnClickOutside } from "../../helpers/hooks";
import PrivateRoute from "../../components/PrivateRoute";

function OldSchoolMenuLink({ children, to, activeOnlyWhenExact, setOpen }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <Link
      className={match ? "active" : ""}
      to={to}
      onClick={() => setOpen(false)}
    >
      {children}
    </Link>
  );
}

const Settings = () => {
  const currentUser = authenticationService.currentUserValue;
  const [modals, setModals] = useState({
    user: false,
    company: false,
    invoice: false,
  });

  const toggle = (value) => {
    setModals((modals) => ({ ...modals, [value]: !modals[value] }));
  };
  let { path, url, isExact } = useRouteMatch();
  useTitle("Postavke ");

  const [open, setOpen] = useState(false);

  const ref = useRef();

  console.log(path, url, isExact);
  return (
    <div>
      <div>
        <div className="nk-content ">
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-block">
                  <div className="card card-bordered noPrintBorder">
                    <div className="card-aside-wrap">
                      <Switch>
                        <PrivateRoute exact path={path}>
                          <SettingsPersonal setOpen={setOpen} />
                        </PrivateRoute>
                        <PrivateRoute exact path={`${path}/djelatnost`}>
                          <SettingsInvoice setOpen={setOpen} />
                        </PrivateRoute>
                        <PrivateRoute exact path={`${path}/porezne`}>
                          <SettingsCompany setOpen={setOpen} />
                        </PrivateRoute>
                        <PrivateRoute exact path={`${path}/promjena-lozinke`}>
                          <ResetPassword setOpen={setOpen} />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={`${path}/klijenti`}
                          checkUser={true}
                        >
                          <ClientsReviewSettings setOpen={setOpen} />
                        </PrivateRoute>
                        <PrivateRoute
                          exact
                          path={`${path}/proizvodi`}
                          checkUser={true}
                        >
                          <ProductsReviewSettings setOpen={setOpen} />
                        </PrivateRoute>
                      </Switch>
                      <div
                        className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg noPrint ${
                          open ? "content-active" : ""
                        }`}
                        data-content="userAside"
                        data-toggle-screen="lg"
                        data-toggle-overlay="true"
                        ref={ref}
                      >
                        <div className="card-inner-group" data-simplebar>
                          <div className="card-inner">
                            <div className="user-card">
                              <div className="user-avatar bg-primary">
                                <span>
                                  {currentUser?.ime?.charAt(0)}
                                  {currentUser?.prezime?.charAt(0)}
                                </span>
                              </div>
                              <div className="user-info">
                                <span className="lead-text">
                                  {currentUser?.ime} {currentUser?.prezime}
                                </span>
                                <span className="sub-text">
                                  {currentUser?.email}
                                </span>
                              </div>
                              <div className="user-action">
                                <div className="dropdown">
                                  <a
                                    className="btn btn-icon btn-trigger mr-n2"
                                    data-toggle="dropdown"
                                    href="#"
                                  >
                                    <em className="icon ni ni-more-v"></em>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a href="#">
                                          <em className="icon ni ni-camera-fill"></em>
                                          <span>Change Photo</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a href="#">
                                          <em className="icon ni ni-edit-fill"></em>
                                          <span>Update Profile</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-inner p-0">
                            <ul className="link-list-menu">
                              <li>
                                <OldSchoolMenuLink
                                  setOpen={setOpen}
                                  to={url}
                                  activeOnlyWhenExact={true}
                                >
                                  <em className="icon ni ni-user-fill-c"></em>
                                  <span>Osnovne informacije</span>
                                </OldSchoolMenuLink>
                              </li>
                              <li>
                                <OldSchoolMenuLink
                                  setOpen={setOpen}
                                  to={`${url}/djelatnost`}
                                >
                                  <em className="icon ni ni-user-fill-c"></em>
                                  <span>Fiskalizacija</span>
                                </OldSchoolMenuLink>
                              </li>
                              <li>
                                <OldSchoolMenuLink
                                  setOpen={setOpen}
                                  to={`${url}/porezne`}
                                >
                                  <em className="icon ni ni-user-fill-c"></em>
                                  <span>Porezne informacije</span>
                                </OldSchoolMenuLink>
                              </li>
                              <li>
                                <OldSchoolMenuLink
                                  setOpen={setOpen}
                                  to={`${url}/promjena-lozinke`}
                                >
                                  <em className="icon ni ni-shield-star-fill"></em>
                                  <span>Promjena lozinke</span>
                                </OldSchoolMenuLink>
                              </li>
                              <li>
                                <OldSchoolMenuLink
                                  setOpen={setOpen}
                                  to={`${url}/klijenti`}
                                >
                                  <em className="icon ni ni-db-fill"></em>
                                  <span>Baza klijenata</span>
                                </OldSchoolMenuLink>
                              </li>
                              <li>
                                <OldSchoolMenuLink
                                  setOpen={setOpen}
                                  to={`${url}/proizvodi`}
                                >
                                  <em className="icon ni ni-db-fill"></em>
                                  <span>Baza proizvoda</span>
                                </OldSchoolMenuLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div
                        className={open ? "toggle-overlay" : ""}
                        onClick={() => setOpen(false)}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

import { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
//import isObject from "lodash/isObject";
import { PDF417 } from "../services/pdf417";
import TitleContext from "./context";

/* const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join(".");
};
 */

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}
export function useCanvas(text, context = "2d") {
  const canvasRef = useRef(null);
  useEffect(() => {
    var bw = 1;
    var bh = 1;
    PDF417.init(text);
    var barcode = PDF417.getBarcodeArray();
    console.log(canvasRef, text, barcode);
    const ctx = canvasRef.current.getContext(context);
    ctx.width = bw * barcode["num_cols"];
    ctx.height = bh * barcode["num_rows"];

    let animationFrameId = requestAnimationFrame(renderFrame);

    function renderFrame() {
      animationFrameId = requestAnimationFrame(renderFrame);
      var y = 0;
      // for each row
      for (var r = 0; r < barcode["num_rows"]; ++r) {
        var x = 0;
        // for each column
        for (var c = 0; c < barcode["num_cols"]; ++c) {
          //eslint-disable-next-line
          if (barcode["bcode"][r][c] == 1) {
            ctx.fillRect(x, y, bw, bh);
          }
          x += bw;
        }
        y += bh;
      }
      //draw(ctx);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [context, text]);

  console.log(canvasRef);

  return canvasRef;
}

export function useTitle(newTitle) {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    //setTitle
    setTitle(newTitle);
  }, [newTitle, setTitle]); // Empty array ensures that effect is only run on mount

  return null;
}
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";
import { useStateMachine } from "little-state-machine";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import { updateProceeds, resetState } from "../../helpers/updateState";
import Button from "../../components/Button";
import Datepicker from "../../components/DatePicker";

import { Link } from "react-router-dom";
import InputValue from "../../components/InputValue";

dayjs.extend(isSameOrAfter);

const Proceeds = () => {
  const currentUser = authenticationService.currentUserValue;
  let history = useHistory();
  const [status, setStatus] = useState({
    state: false,
    loading: false,
    message: "",
    error: "",
  });

  const { actions, state } = useStateMachine({
    updateProceeds,
    resetState,
  });

  useEffect(() => {
    return () => {
      console.log("exit component");
      if (state.invoice.from.includes("edit")) {
        actions.resetState();
      }
    };
  }, []);

  const { handleSubmit, errors, getValues, control } = useForm({
    defaultValues: state.proceedsData,
  });

  const submit = (data, predlozak = false) => {
    const date = dayjs(data.datum).format("DDMMYYYY");
    const gotovina = Number(
      data.gotovina.replaceAll(".", "").replace(",", ".")
    );
    const kartice = Number(data.kartice.replaceAll(".", "").replace(",", "."));

    const obj = {
      id: state.proceedsData.from === "edit" ? state.proceedsData.id : null,
      user: currentUser && currentUser.id,
      kupac: "Dnevni promet",
      drzava: "Hrvatska",
      adresa: "",
      ulica: "",
      kucniBroj: "",
      mjesto: "",
      postanskiBroj: "",
      oib: "",
      broj: `P-${String(date)}`,
      datum: data.datum,
      izvrsenoOd: data.datum,
      izvrsenoDo: data.datum,
      datumDospijeca: data.datum,
      datumPlacanja: data.datum,
      operater: "",
      napomena: "",
      proizvod: "",
      status: "Plaćeno",
      ukupanIznos: gotovina + kartice,
      konacanIznos: gotovina + kartice,
      placeno: gotovina + kartice,
      kolicina: "",
      newPrice: gotovina + kartice,
      preostalo: 0,
      // pdv: 0,
      popust: "",
      proizvodi: [],
      pdvs: [],
      nacinPlacanja: "Transakcijski račun",
      logoBase64: "",
      kupacDetails: "",
    };
    handlePostRequest(
      `${process.env.REACT_APP_HOST_URL}/invoices/${
        state.proceedsData.from === "edit" ? "updateInvoice" : "add"
      }`,
      [obj],
      currentUser.token
    )
      .then(function (res) {
        if (res.data.error) {
          setStatus({
            state: true,
            error: "Utržak na taj datum već postoji",
            message: "",
            loading: false,
          });
          setTimeout(() => {
            setStatus("");
            // setSubmitting(false);
          }, 3000);
        } else {
          setStatus({
            state: true,
            loading: false,
            message: "Utržak  " + res.data.status + " spremljen",
            error: "",
          });

          // setAddedFlag(Math.random());
          setTimeout(() => {
            setStatus({
              state: true,
              message: "",
              error: "",
              loading: false,
            });

            history.push("/pregled-racuna");
          }, 2500);
        }
        //set flag invoice saved, settimeout 2s and rerender
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>Dnevni utržak</h4>
            <Link to="/novi-racun">
              <Button classnames="btn-primary btn-xl btn-pausal">
                Unos računa
              </Button>
            </Link>
          </div>
          <hr></hr>
          <form
            className="form"
            onSubmit={handleSubmit((data) => submit(data, false))}
          >
            {" "}
            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Datum
              </label>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  defaultValue={state.invoice.datum}
                  name="datum"
                  rules={{ required: "Datum je obvezan" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {errors.datum && (
                  <span className="error">{errors.datum.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-4">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Gotovina</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  name="gotovina"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value && getValues("kartice").length < 1)
                          return "Unesi iznos";
                        return true;
                      },
                    },
                  }}
                  render={({ onChange, onFocus, onBlur, value, name }) => {
                    return (
                      <NumberFormat
                        name={name}
                        classnames={`form-control-lg position-relative ${
                          errors.gotovina ? "error" : ""
                        }`}
                        placeholder="Gotovina"
                        customInput={InputValue}
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChange}
                        value={value}
                      />
                    );
                  }}
                />
                {errors.gotovina && (
                  <span className="error">{errors.gotovina.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-4">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label className="my-0">Kartice</label>
              </div>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  name="kartice"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) => {
                        if (!value && getValues("gotovina").length < 1)
                          return "Unesi iznos";
                        return true;
                      },
                    },
                  }}
                  render={({ onChange, onFocus, onBlur, value, name }) => {
                    return (
                      <NumberFormat
                        name={name}
                        classnames={`form-control-lg position-relative ${
                          errors.kartice ? "error" : ""
                        }`}
                        placeholder="Kartice"
                        customInput={InputValue}
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator={","}
                        thousandSeparator={"."}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        onChange={onChange}
                        value={value}
                      />
                    );
                  }}
                />
                {errors.kartice && (
                  <span className="error">{errors.kartice.message}</span>
                )}
              </div>
            </div>
            <hr></hr>
            {status.state && status.message.length > 0 && (
              <div style={{ maxWidth: 800 }} className="mx-auto">
                <div
                  className="alert alert-dismissible alert-icon alert-fill alert-success"
                  role="alert"
                >
                  <em className="icon ni ni-check-circle"></em>
                  {status.message.length > 0 && status.message}
                  <button
                    className="close"
                    data-dismiss="alert"
                    type="button"
                    onClick={() => setStatus({ state: false, message: "" })}
                  ></button>
                </div>
              </div>
            )}
            {status.state && status.error.length > 0 && (
              <div style={{ maxWidth: 800 }} className="mx-auto">
                <div
                  className="alert alert-dismissible alert-icon alert-fill alert-danger"
                  role="alert"
                >
                  <em className="icon ni ni-cross-circle"></em>
                  {status.error.length > 0 && status.error}
                  <button
                    className="close"
                    data-dismiss="alert"
                    type="button"
                    onClick={() => setStatus({ state: false, error: "" })}
                  ></button>
                </div>
              </div>
            )}
            <div className="form-group row d-flex align-items-center justify-content-end mb-4 mt-2">
              <div className="col-12 col-lg-2">
                <Button
                  disabled={status.state}
                  type="button"
                  onClick={handleSubmit((data) => submit(data, false))}
                  classnames="customButton btn-dim btn-outline-primary form-control-lg d-flex my-2 mx-auto ml-lg-auto mr-lg-0 my-lg-0 "
                >
                  Spremi utržak
                </Button>
              </div>

              <div className="col-12 col-lg-2 my-2 mx-lg-0 my-lg-0">
                <Button
                  disabled={status.state}
                  type="button"
                  classnames="customButton btn-dim btn-outline-danger form-control-lg d-flex my-2 mx-auto ml-lg-0 mr-lg-auto my-lg-0 "
                  onClick={() => history.push("/pregled-racuna")}
                >
                  Izađi bez spremanja
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Proceeds;

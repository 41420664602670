import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import logo from "../../assets/images/logo1beta.png";
import { authenticationService } from "../../services/authentication";
import { useStateMachine } from "little-state-machine";
import { updateInvoice, updateAccountInfo } from "../../helpers/updateState";
import { GoogleIcon } from "../Main/icons";

const Login = () => {
  const currentUser = authenticationService.currentUserValue;
  let history = useHistory();
  const { actions } = useStateMachine({ updateInvoice, updateAccountInfo });
  const [sessionEnd, setSessionEnd] = useState(
    sessionStorage.getItem("sessionEndMsg")
  );
  const [showPass, setShowPass] = useState(false);
  const [sending, setSending] = useState(false);
  const { register, handleSubmit, clearErrors, errors, setError } = useForm();
  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [history, currentUser]);

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.setItem("sessionEndMsg", "");
      setSessionEnd(sessionStorage.getItem("sessionEndMsg"));
    }, 2500);
  }, []);

  useEffect(() => {
    const cookie = Cookies.get("currentUser")
    console.log(cookie);
    if (cookie) {
      const user = authenticationService.googleLogin(cookie);
      actions.updateInvoice(user);
      actions.updateAccountInfo(user);
      history.push("/");
    } else console.log("no cookie");
  }, [history, actions]);

  const submit = (data) => {
    setSending(true);
    authenticationService.login(data.username, data.password).then((res) => {
      if (!res?.oib) {
        setSending(false);
        setError("status", { type: "manual", message: res });
        setTimeout(() => {
          clearErrors(["status"]);
        }, 3000);
      } else {
        console.log(res);
        actions.updateInvoice(res);
        actions.updateAccountInfo(res);
        setSending(false);
        history.push("/");
      }
    });
  };

  const togglePassVisibile = (e) => {
    setShowPass((showPass) => !showPass);
  };

  return (
    <div className="nk-app-root mt-n3">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <div className="nk-split nk-split-page nk-split-md">
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
                {/*  <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                  <Link
                    to="/"
                    className="toggle btn-white btn btn-icon btn-light"
                    data-target="athPromo"
                  >
                    <em className="icon ni ni-info"></em>
                  </Link>
                </div> */}
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="brand-logo pb-5">
                    <div className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={logo}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={logo}
                        alt="logo-dark"
                      />
                    </div>
                  </div>
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">Prijava</h5>
                      {/*  <div className="nk-block-des">
                        <p>
                          Access the DashLite panel using your email and
                          passcode.
                        </p>
                      </div> */}
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(submit)}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" for="default-01">
                          Korisničko ime
                        </label>
                      </div>
                      <input
                        type="text"
                        className={`form-control form-control-lg ${
                          errors.username && "error"
                        }`}
                        id="default-01"
                        name="username"
                        ref={register({ required: "Upiši korisničko ime" })}
                        placeholder="Unesi korisničko ime"
                      />
                      {errors.username && (
                        <span className="error">{errors.username.message}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" for="password">
                          Lozinka
                        </label>
                        <Link
                          className="link link-primary link-sm"
                          tabIndex="-1"
                          to="/zaboravljena-lozinka"
                        >
                          Zaboravljena lozinka?
                        </Link>
                      </div>
                      <div className="form-control-wrap">
                        <button
                          tabIndex="-1"
                          type="button"
                          onClick={togglePassVisibile}
                          className="form-icon form-icon-right passcode-switch blankBtn"
                          data-target="password"
                        >
                          {showPass ? (
                            <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                          ) : (
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                          )}
                        </button>
                        <input
                          type={showPass ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Unesi lozinku"
                          ref={register({ required: "Upiši lozinku" })}
                          className={`form-control form-control-lg ${
                            errors.password && "error"
                          }`}
                          style={{ marginBottom: 3 }}
                        />
                        {errors.password && (
                          <span className="error position-absolute">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {errors.status && errors.status.message && (
                      <div
                        className="alert alert-dismissible alert-icon alert-fill alert-danger"
                        role="alert"
                      >
                        <em className="icon ni ni-cross-circle"></em>
                        {errors.status && errors.status.message}
                        <button
                          className="close"
                          data-dismiss="alert"
                          type="button"
                          onClick={() => clearErrors(["status"])}
                        ></button>
                      </div>
                    )}
                    {sessionEnd && sessionEnd.length > 0 && (
                      <div
                        className="alert alert-dismissible alert-icon alert-fill alert-danger"
                        role="alert"
                      >
                        <em className="icon ni ni-cross-circle"></em>
                        {sessionEnd}
                        <button
                          className="close"
                          data-dismiss="alert"
                          type="button"
                          onClick={() => clearErrors(["status"])}
                        ></button>
                      </div>
                    )}
                    <div className="form-group mt-5">
                      <button
                        type="submit"
                        disabled={sending}
                        className="btn btn-lg btn-primary btn-block"
                      >
                        {sending ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span> Prijava... </span>{" "}
                          </>
                        ) : (
                          "Prijavi se"
                        )}
                      </button>
                    </div>
                  </form>

                  <div className="form-note-s2 pt-4" style={{ fontSize: 16 }}>
                    Nemaš račun?
                    <Link to="/register" className="ml-1">
                      Napravi novi račun
                    </Link>
                  </div>
                  <div className="text-center pt-4 pb-3">
                    <h6 className="overline-title overline-title-sap">
                      <span>ILI</span>
                    </h6>
                  </div>
                  <ul className="nav justify-center gx-4">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Facebook
                      </a>
                    </li>
                    <li className="nav-item">
                      <button
                        href="#"
                        class="btn btn-light"
                        onClick={() =>
                          window.open(
                            `http://localhost:5050/users/google-login`,
                            "_self"
                          )
                        }
                      >
                        <GoogleIcon />
                        <span className="ml-1">Google</span>{" "}
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="nk-block nk-auth-footer">
                  <div className="nk-block-between">
                    <ul className="nav nav-sm">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Terms & Condition
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Help
                        </a>
                      </li>
                      {/*      <li className="nav-item dropup">
                        <a
                          className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                          data-toggle="dropdown"
                          data-offset="0,10"
                        >
                          <small>English</small>
                        </a>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="language-list">
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/english.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">English</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/spanish.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Español</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/french.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Français</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/turkey.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Türkçe</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    */}{" "}
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>
                      &copy; {new Date().getFullYear()} Paušal.hr All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
              </div>

              <div className="nk-split-content nk-split-stretch bg-abstract"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import { useState } from "react";
import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import Button from "../../components/Button";

const ResetPassword = (props) => {

  let history = useHistory();
  const currentUser = authenticationService.currentUserValue;
  const { register, handleSubmit, errors } = useForm();
  const [showPass, setShowPass] = useState({
    current: false,
    new: false,
  });
  const [status, setStatus] = useState({
    state: false,
    loading: false,
    message: "",
  });
  const logout = () => {
    authenticationService.logout();
    history.push("/");
  };
  const togglePassVisibile = (e, which) => {
    setShowPass((showPass) => ({ ...showPass, [which]: !showPass[which] }));
  };
  const submit = (data) => {
    setStatus((status) => ({ ...status, loading: true }));
    console.log(data);
    if (data.password === data.passwordAgain) {
      handlePostRequest(
        `${process.env.REACT_APP_HOST_URL}/users/changePassword`,
        [
          {
            old_password: data.passwordCurrent,
            new_password: data.password,
            email: currentUser.email,
            id: currentUser.id,
          },
        ]
      )
        .then((res) => {
          if (res.data) {
            //remove form add success msg
            if (res.data.message === "Neispravna lozinka, pokušajte ponovo") {
              setStatus((status) => ({
                ...status,
                state: true,
                message: res.data.message,
              }));
              setTimeout(() => {
                setStatus({
                  loading: false,
                  state: false,
                  message: "",
                });
              }, 2500);
            } else {
              setStatus((status) => ({
                ...status,
                state: true,
                message:
                  res.data.message + ". Ponovno se prijavi s novom lozinkom",
              }));
              setTimeout(() => {
                logout();
              }, 2500);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (err) {
            setStatus((status) => ({
              ...status,
              message: "Korisnik s tim emailom ne postoji",
            }));
            setTimeout(() => {
              setStatus("");
              setStatus({ state: false, loading: false, message: "" });
            }, 2000);
          }
        });
    } else {
      setStatus((status) => ({
        ...status,
        state: true,
        message: "Lozinke se ne podudaraju",
      }));
      setTimeout(() => {
        setStatus({ state: false, loading: false, message: "" });
      }, 2500);
    }
  };
  console.log(status);
  return (
    <div className="w-100">
      <div className="card-inner card-inner-lg ">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">Promjena lozinke</h4>
            </div>
            <div className="nk-block-head-content align-self-start d-lg-none">
                <a className="toggle btn btn-icon btn-trigger mt-n1" onClick={props.setOpen} >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
            </div>
            
            {/*   <div className="nk-block-head-content align-self-center">
              <Button classnames="btn-lg btn-dim btn-outline-info">
                Spremi izmjene
              </Button>
            </div> */}
          </div>
        </div>
        <div className="nk-block">
          <div className="data-head mb-4">
            <h6 className="overline-title">Basics</h6>
          </div>
          {status.state ? (
            <div className="container">
              <div className="row justify-content-center mt-5">
                {status.message ===
                "Lozinka promijenjena. Ponovno se prijavi s novom lozinkom" ? (
                  <svg
                    style={{ height: "fill-content" }}
                    width="10em"
                    height="10em"
                    viewBox="0 0 16 16"
                    className="bi bi-file-lock-fill"
                    fill="#62B697"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM7 6a1 1 0 0 1 2 0v1H7V6zm3 0v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0zM6 8.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 8h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 11h-3a.637.637 0 0 1-.395-.125C6.02 10.807 6 10.742 6 10.7V8.3z"
                    />
                  </svg>
                ) : (
                  <svg
                    style={{ height: "fill-content" }}
                    width="10em"
                    height="10em"
                    viewBox="0 0 16 16"
                    class="bi bi-exclamation-square-fill"
                    fill="#b21b1b"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                    />
                  </svg>
                )}
              </div>
              <div className="row">
                <div className="mx-auto mt-4">
                  <p className="font-weight-bold" style={{ fontSize: 22 }}>
                    {status.message}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <form className="form" onSubmit={handleSubmit(submit)}>
              <div className="form-group row d-flex mb-4">
                <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end">
                  <label className="my-auto">Trenutna lozinka:</label>
                </div>
                <div className="col-12 form-control-wrap col-lg-4 d-flex justify-content-center justify-content-lg-start p-0">
                  <input
                    type={showPass.current ? "text" : "password"}
                    name="passwordCurrent"
                    id="passwordCurrent"
                    placeholder="Unesi lozinku"
                    ref={register({ required: "Upiši lozinku" })}
                    className={`form-control form-control-lg position-relative ${
                      errors.passwordCurrent && "error"
                    }`}
                    style={{ marginBottom: 3 }}
                  />
                  <button
                    tabIndex="-1"
                    type="button"
                    onClick={(e) => togglePassVisibile(e, "current")}
                    className="form-icon form-icon-right passcode-switch blankBtn"
                    data-target="passwordCurrent"
                  >
                    {showPass.current ? (
                      <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                    ) : (
                      <em className="passcode-icon icon-show icon ni ni-eye"></em>
                    )}
                  </button>
                  {errors.passwordCurrent && (
                    <span
                      className="error position-absolute"
                      style={{ bottom: -20 }}
                    >
                      {errors.passwordCurrent.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group row d-flex mb-4">
                <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end">
                  <label className="my-auto">Nova lozinka:</label>
                </div>
                <div className="col-12 form-control-wrap col-lg-4 d-flex justify-content-center justify-content-lg-start p-0">
                  <input
                    type={showPass.new ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder="Unesi novu lozinku"
                    ref={register({ required: "Upiši lozinku" })}
                    className={`form-control form-control-lg position-relative ${
                      errors.password && "error"
                    }`}
                    style={{ marginBottom: 3 }}
                  />
                  <button
                    tabIndex="-1"
                    type="button"
                    onClick={(e) => togglePassVisibile(e, "new")}
                    className="form-icon form-icon-right passcode-switch blankBtn"
                    data-target="password"
                  >
                    {showPass.new ? (
                      <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                    ) : (
                      <em className="passcode-icon icon-show icon ni ni-eye"></em>
                    )}
                  </button>
                  {errors.password && (
                    <span
                      className="error position-absolute"
                      style={{ bottom: -20 }}
                    >
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group row d-flex mb-4">
                <div className="col-12 col-lg-4 d-flex justify-content-center justify-content-lg-end">
                  <label className="my-auto">Ponovi lozinku:</label>
                </div>
                <div className="col-12 form-control-wrap col-lg-4 d-flex justify-content-center justify-content-lg-start p-0">
                  <input
                    type={showPass.new ? "text" : "password"}
                    name="passwordAgain"
                    id="passwordAgain"
                    placeholder="Ponovi novu lozinku"
                    ref={register({ required: "Upiši lozinku" })}
                    className={`form-control form-control-lg position-relative ${
                      errors.passwordAgain && "error"
                    }`}
                    style={{ marginBottom: 3 }}
                  />
                  <button
                    tabIndex="-1"
                    type="button"
                    onClick={(e) => togglePassVisibile(e, "new")}
                    className="form-icon form-icon-right passcode-switch blankBtn"
                    data-target="passwordAgain"
                  >
                    {showPass.new ? (
                      <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                    ) : (
                      <em className="passcode-icon icon-show icon ni ni-eye"></em>
                    )}
                  </button>
                  {errors.passwordAgain && (
                    <span
                      className="error position-absolute"
                      style={{ bottom: -20 }}
                    >
                      {errors.passwordAgain.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="form-group row d-flex mb-4">
                <div className="col-12 d-flex justify-content-center">
                  <Button
                    disabled={status.loading}
                    type="submit"
                    classnames="btn-dim btn-outline-primary"
                  >
                    Pošalji
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

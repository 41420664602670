import dayjs from "dayjs";
import { pick } from "lodash";
import {
  convertKeyToDate,
  createCalendarData,
  getDateArray,
} from "../../helpers";

export function getStats(data, paymentData, month, dates, size) {
  const unpaid = data
    .filter((item) => {
      return Number(item.preostalo) > 0 ? item : null;
    })
    .reduce(
      (a, b) => {
        return { preostalo: Number(a.preostalo) + Number(b.preostalo) };
      },
      { preostalo: 0 }
    );

  const totalYear = paymentData
    .filter((item) => !item.broj.includes("Predložak"))
    .filter((item) => {
      return item.datumPlacanja &&
        item.datumPlacanja.indexOf(month.getFullYear().toString()) > 0
        ? item
        : null;
    })
    .reduce(
      (a, b) => {
        return {
          placeno: Number(a.placeno) + Number(b.placeno),
        };
      },
      { placeno: 0 }
    );
  const totalMonth = paymentData
    .filter((item) => !item.broj.includes("Predložak"))
    .filter((item) => {
      if (
        item.datumPlacanja &&
        dayjs(item.datumPlacanja, "DD.MM.YYYY").isSameOrAfter(
          dayjs(dates.from)
        ) &&
        dayjs(item.datumPlacanja, "DD.MM.YYYY").isSameOrBefore(
          dayjs(dates.till)
        )
      ) {
        return true;
      } else return false;
    })
    .reduce(
      (a, b) => {
        return {
          placeno: Number(a.placeno) + Number(b.placeno),
        };
      },
      { placeno: 0 }
    );
  const pieData = [
    {
      id: "Plaćeno",
      label: "Plaćeno",
      value: data.filter((item) => {
        return item.status === "Plaćeno" ? item : null;
      }).length,
    },
    {
      id: "Neplaćeno",
      label: "Neplaćeno",
      value: data.filter((item) => {
        return item.status === "Neplaćeno" ? item : null;
      }).length,
    },
    {
      id: "Djelomično",
      label: "Djelomično",
      value: data.filter((item) => {
        return item.status === "Djelomično" ? item : null;
      }).length,
    },
  ];
  const dataLine =
    paymentData &&
    paymentData
      .filter((item) => item.status !== "Neplaćeno")
      .map((item) => {
        return {
          date: dayjs(item.datumPlacanja, "DD.MM.YYYY").format("DD.MM.YYYY"),
          value: Number(item.placeno),
        };
      });

  const calendarData =
    size.width > 700 ? createCalendarData(data, paymentData) : null;
  const reducedLineData = dataLine.reduce(
    (sums, object) => ({
      ...sums,
      [object.date]: sums[object.date]
        ? sums[object.date] + object.value
        : object.value,
    }),
    {}
  );
  const numberOfInvoiceOnDate = dataLine.reduce(
    (sums, object) => ({
      ...sums,
      [object.date]: sums[object.date] ? sums[object.date] + 1 : 1,
    }),
    {}
  );
  /*   const barDates = data.map(item => {
      return item.datumRacun.substring(0, item.datumRacun.indexOf(" "));
    });
    const dataBar = data.filter(item => {
      return item.datumRacun.indexOf(...barDates) ? item : null;
    }); */
  //const dates = getDates(month);
  //const lastMonthDate = new Date(new Date().setDate(new Date().getDate() - 30));
  const lastMonthArray = getDateArray(dates.from, dates.till);
  const lastMonthArrayNew = getDateArray(dates.from, dates.till, "array");
  const lineDataArray = {
    ...lastMonthArray,
    ...convertKeyToDate(reducedLineData),
  };
  const reducedLineDataArray = pick(lineDataArray, lastMonthArrayNew);

  const lineInvoiceNumArray = {
    ...lastMonthArray,
    ...convertKeyToDate(numberOfInvoiceOnDate),
  };

  const reducedlineInvoiceNumArray = pick(
    lineInvoiceNumArray,
    lastMonthArrayNew
  );
  return {
    unpaid,
    totalYear,
    totalMonth,
    pieData,
    calendarData,
    reducedLineDataArray,
    reducedlineInvoiceNumArray,
  };
}

import { useMemo, useCallback, useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router";
import NumberFormat from "react-number-format";
import { useStateMachine } from "little-state-machine";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import {
  getClients,
  getInvoiceNumber,
  getProducts,
} from "../../helpers/getData";
import {
  stringToNum,
  getTotalPrice,
  generatePdvs,
} from "../../helpers/functions";
import {
  updateInvoice,
  updateProizvod,
  updateProizvodi,
  deleteProizvodi,
  updateInvoicePreview,
  resetState,
} from "../../helpers/updateState";
import Button from "../../components/Button";
import Input from "../../components/Input";
import Datepicker from "../../components/DatePicker";
import CustomSelect from "../../components/Select";
import Table from "../../components/Table";
import { Modal } from "@material-ui/core";
import NewClient from "../Clients/NewClient";
import NewProduct from "../Products/NewProduct";
import { Link } from "react-router-dom";
import { useTitle } from "../../helpers/hooks";
import { formatCurrency } from "../../helpers";
import { checkProductErrors, generateInvoice } from "./functions";

dayjs.extend(isSameOrAfter);

const getEndpoint = (broj, from) => {
  if (broj.includes("Predložak") && from.includes("edit")) {
    return "/update-predlozak";
  } else if (!broj.includes("Predložak") && from.includes("edit")) {
    return "/updateInvoice";
  } else return "/add";
};

const NewInvoice = () => {
  const currentUser = authenticationService.currentUserValue;
  const queryClient = useQueryClient();
  let history = useHistory();
  const [status, setStatus] = useState({
    state: false,
    loading: false,
    message: "",
    error: "",
  });
  const [modals, setModal] = useState({ addClient: false, addProduct: false });
  const { data: clients } = useQuery("clients", getClients);
  const { data: products } = useQuery("products", getProducts);
  const { data: invoiceRes } = useQuery("invoiceNumber", getInvoiceNumber);
  const { actions, state } = useStateMachine({
    updateInvoice,
    updateProizvod,
    updateProizvodi,
    deleteProizvodi,
    updateInvoicePreview,
    resetState,
  });

  useEffect(() => {
    return () => {
      console.log("exit component");
      actions.resetState();
      /*  if (state.invoice.from.includes("edit")) {
      } */
    };
  }, []);

  useTitle("Unos novog računa");

  const {
    register,
    handleSubmit,
    clearErrors,
    errors,
    setError,
    control,
    setValue,
  } = useForm({
    defaultValues: state.invoice,
  });

  const {
    handleSubmit: handleSubmitP,
    errors: errorsP,
    setValue: setValueP,
    setError: setErrorP,
    control: controlP,
  } = useForm({
    defaultValues: state.proizvod,
  });

  const editProduct = useCallback(
    (values) => {
      setValueP("proizvod", values.option);
      setValueP("cijena", values.cijena);
      setValueP("kolicina", values.kolicina);
      setValueP("popust", values.rabat);
      actions.deleteProizvodi(values);
    },
    [actions, setValueP]
  );

  const cols = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Šifra", accessor: "sifra" },
      { Header: "JM", accessor: "unit" },
      {
        Header: "Cijena",
        accessor: "cijena",
        Cell: (props) => formatCurrency(props.value),
      },
      { Header: "Količina", accessor: "kolicina" },
      {
        Header: "Ukupna cijena",
        accessor: "ukupnaCijena",
        Cell: (props) => formatCurrency(props.value),
      },
      { Header: "Popust", accessor: "rabat" },
      {
        Header: "Konačna cijena",
        accessor: "pdvPrice",
        Cell: (props) => formatCurrency(props.value),
      },
      {
        Header: "Uredi",
        accessor: "option",
        Cell: (props) => {
          return (
            <button
              type="button"
              onClick={() => editProduct(props.row.values)}
              className="blankBtn d-flex mx-auto editButton"
            >
              <em className="icon ni ni-edit editButton"></em>
            </button>
          );
        },
      },
      {
        Header: "Obriši",
        accessor: "id",
        Cell: (props) => {
          return (
            <button
              onClick={() => actions.deleteProizvodi(props.row.values)}
              type="button"
              className="blankBtn d-flex mx-auto deleteButton"
            >
              <em className="icon ni ni-cross-round-fill"></em>
            </button>
          );
        },
      },
    ],
    [actions, editProduct]
  );

  const addProduct = (data) => {
    const kolicina = stringToNum(data.kolicina);
    const cijena = stringToNum(data.cijena);
    const popust = stringToNum(data.popust);

    const totalPrice = cijena * kolicina;
    const totalPopust = totalPrice * (popust / 100);
    const newPrice = totalPrice - totalPopust;

    const product = {
      ...data.proizvod.value,
      kolicina,
      cijena,
      ukupnaCijena: cijena * kolicina,
      rabat: popust === 0 ? null : popust,
      popust: totalPopust,
      newPrice,
      pdvPrice: newPrice,
      option: { value: data.proizvod.value, label: data.proizvod.label },
    };

    if (!checkProductErrors(product, state.proizvodi, setErrorP).state) {
      actions.updateProizvod(product);
      actions.updateProizvodi(product);
    }
  };

  const invoiceNumber =
    state.invoice.from === "edit"
      ? state.invoice.broj.includes("Predložak")
        ? invoiceRes?.invoiceNumber
        : state.invoice.broj
      : invoiceRes?.invoiceNumber;

  const submit = (data, predlozak = false) => {
    console.log(data);
    //compare dates with dayjs
    const izvrsenoDo = dayjs(data.izvrsenoDo)
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("ms", 0);
    const izvrsenoOd = dayjs(data.izvrsenoOd)
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .set("ms", 0);
    const dospijece = dayjs(data.datumDospijeca)
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59)
      .set("ms", 99);

    if (!izvrsenoDo.isSameOrAfter(izvrsenoOd)) {
      setError("izvrsenoDo", {
        type: "manual",
        message: "Izvrseno do je manje od izvrseno od",
      });
    } else {
      if (dayjs(data.datumRacuna).isAfter(dospijece)) {
        setError("datumDospijeca", {
          type: "manual",
          message: "Datum dospijeća je prije datuma računa",
        });
      } else if (state.proizvodi.length < 1) {
        setError("proizvodi", {
          type: "manual",
          message: "Niste dodali nijedan proizvod",
        });
        setTimeout(() => {
          clearErrors(["proizvodi"]);
        }, 2500);
      } else {
        setStatus({ state: true, message: "Slanje", error: "", loading: true });
        actions.updateInvoice(data);
        const obj = {
          id: state.invoice.from.includes("edit") ? state.invoice.id : 0,
          broj: predlozak ? invoiceRes.predlozakNum : invoiceNumber,
          kupac: data.klijent.label,
          kupacDetails: data.klijent.value,
          datum: data.datumRacuna,
          izvrsenoOd: data.izvrsenoOd,
          izvrsenoDo: data.izvrsenoDo,
          datumDospijeca: data.datumDospijeca,
          operater: data.operater,
          napomena: data.napomena,
          datumPlacanja: data.datumPlacanja,
          status: "Neplaćeno",
          ukupanIznos: getTotalPrice(state.proizvodi, "ukupnaCijena")
            .ukupnaCijena,
          cijenaPdv: getTotalPrice(state.proizvodi, "pdvPrice").pdvPrice,
          konacanIznos: getTotalPrice(state.proizvodi, "pdvPrice").pdvPrice,
          placeno: 0,
          preostalo: getTotalPrice(state.proizvodi, "pdvPrice").pdvPrice,
          proizvodi: state.proizvodi,
          nacinPlacanja: data.nacinPlacanja.label,
          proizvodiIds: state.proizvodi.map((item) => item.id),
          jir: "",
          pdvs: generatePdvs(state),
          placanja: [],
          user: currentUser.id,
        };
        const urlEndpoint = predlozak
          ? "/add"
          : getEndpoint(obj.broj, state.invoice.from);
        handlePostRequest(
          `${process.env.REACT_APP_HOST_URL}/invoices${urlEndpoint}`,
          [obj],
          currentUser.token
        )
          .then((res) => {
            if (res.data.error) {
              setStatus({
                state: false,
                error: res.data.error,
                message: "",
                loading: false,
              });
            } else {
              setStatus({
                state: true,
                loading: false,
                message: `Račun ${res.data.status} ${
                  res.data.id ? "izdan" : "spremljen"
                }.`,
                error: "",
              });
              setTimeout(() => {
                setStatus({
                  state: true,
                  message: "",
                  error: "",
                  loading: false,
                });
                //push to /pregled racuna
                actions.updateInvoicePreview({
                  ...generateInvoice(obj, currentUser),
                  id: res.data.id ? res.data.id : obj.id,
                  from: "newInvoice",
                });
                queryClient.invalidateQueries("invoiceNumber");
                history.push("/pregled-racuna");
              }, 2500);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  return (
    <div className="container">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>Predložak računa</h4>
            <Link to="/utrzak">
              <Button classnames="btn-primary btn-xl btn-pausal">
                Unos utrška
              </Button>
            </Link>
          </div>
          <hr></hr>
          <form
            className="form"
            onSubmit={handleSubmit((data) => submit(data, false))}
          >
            <div className="form-group row d-flex mb-4">
              <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
                <label htmlFor="client" className="my-0">
                  Odabir klijenta
                </label>
              </div>
              <div className="form-group col-lg-3 col-12 my-0">
                <Controller
                  name="klijent"
                  defaultValue={state.invoice.klijent}
                  control={control}
                  id="klijent"
                  rules={{ required: "Izaberi klijenta" }}
                  placeholder="Izaberi klijenta"
                  menuPlacement="auto"
                  options={clients?.options}
                  noOptionsMessage={() => "Klijent ne postoji"}
                  as={CustomSelect}
                />

                {errors.klijent && (
                  <span className="error">{errors.klijent.message}</span>
                )}
              </div>
              <div className="form-group mx-auto my-2 mx-lg-0 my-lg-0">
                <Button
                  type="button"
                  classnames="btn-dim btn-outline-secondary form-control-lg custNewInvBtn"
                  onClick={() =>
                    setModal((modals) => ({ ...modals, addClient: true }))
                  }
                >
                  Dodaj novog klijenta
                </Button>
              </div>
            </div>

            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Broj računa
              </label>
              <div className="form-group col-12 col-lg-4">
                <Input
                  classnames="customInput form-control-lg"
                  defaultValue={invoiceNumber}
                  readOnly
                />
              </div>
            </div>

            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Datum
              </label>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  defaultValue={state.invoice.datumRacuna}
                  name="datumRacuna"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY HH:mm"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {errors.datumRacuna && (
                  <span className="error">{errors.datumRacuna.message}</span>
                )}
              </div>
            </div>

            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Izvršeno
              </label>
              <div className="form-group col-12 col-lg-2 my-0">
                <Controller
                  control={control}
                  name="izvrsenoOd"
                  defaultValue={state.invoice.izvrsenoOd}
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {errors.izvrsenoOd && (
                  <span className="error">{errors.izvrsenoOd.message}</span>
                )}
              </div>
              <label className="align-self-center m-auto mx-lg-0">-</label>
              <div className="form-group col-12 col-lg-2">
                <Controller
                  control={control}
                  defaultValue={state.invoice.izvrsenoDo}
                  name="izvrsenoDo"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {errors.izvrsenoDo && (
                  <span className="error">{errors.izvrsenoDo.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Datum dospijeća
              </label>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  control={control}
                  defaultValue={state.invoice.datumDospijeca}
                  name="datumDospijeca"
                  rules={{ required: "Obavezno" }}
                  render={({ onChange, onBlur, value, ref }) => {
                    return (
                      <Datepicker
                        format="DD.MM.YYYY"
                        autoOk
                        ref={ref}
                        onBlur={onBlur}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                {errors.datumDospijeca && (
                  <span className="error">{errors.datumDospijeca.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Operater
              </label>
              <div className="form-group col-12 col-lg-4">
                <Input
                  classnames="customInput form-control-lg"
                  placeholder="Operater"
                  name="operater"
                  ref={register({ required: "Operater je obvezan" })}
                />
                {errors.operater && (
                  <span className="error">{errors.operater.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Napomena
              </label>
              <div className="form-group col-12 col-lg-4">
                <Input
                  classnames="customInput form-control-lg"
                  placeholder="Napomena"
                  name="napomena"
                  ref={register()}
                />
                {errors.napomena && (
                  <span className="error">{errors.napomena.message}</span>
                )}
              </div>
            </div>
            <div className="form-group row d-flex mb-4">
              <label className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end my-0">
                Odabir načina plaćanja
              </label>
              <div className="form-group col-12 col-lg-4">
                <Controller
                  name="nacinPlacanja"
                  control={control}
                  id="nacinPlacanja"
                  rules={{ required: "Odaberi način plaćanja" }}
                  placeholder="Odaberi način plaćanja"
                  menuPlacement="auto"
                  defaultValue={state.invoice.nacinPlacanja}
                  options={[
                    {
                      value: "Transakcijski račun",
                      label: "Transakcijski račun",
                    },
                  ]}
                  noOptionsMessage={() => "Nema rezultata"}
                  as={CustomSelect}
                />
                {errors.nacinPlacanja && (
                  <span className="error">{errors.nacinPlacanja.message}</span>
                )}
              </div>
            </div>
            <hr></hr>
          </form>
          <form className="form">
            <div className="form-group row d-flex align-items-center my-5">
              <h4 className="col-2 form-control-label d-flex justify-content-lg-center">
                Proizvodi
              </h4>
            </div>
            <div className="form-group row d-flex align-items-center mb-3">
              <div className="col-12 col-lg-1 space"></div>
              <div className="form-group col-12 col-lg-2 px-lg-0 m-lg-0">
                <Controller
                  name="proizvod"
                  control={controlP}
                  id="proizvod"
                  defaultValue={state.invoice.proizvod}
                  rules={{ required: "Odaberi proizvod" }}
                  render={(props) => {
                    return (
                      <CustomSelect
                        {...props}
                        onChange={(e) => {
                          setValueP("cijena", Number(e.value.cijena));
                          props.onChange(e);
                        }}
                        placeholder="Izaberi proizvod"
                        menuPlacement="auto"
                        options={products?.options}
                        noOptionsMessage={() => "Proizvod ne postoji"}
                      />
                    );
                  }}
                />
                {errorsP.proizvod && (
                  <span className="error">{errorsP.proizvod.message}</span>
                )}
              </div>
              <div className="orTxt col-12 col-lg-1">
                <p className="text-center text-lg-start">ili</p>
              </div>
              <div className=" mx-auto my-2 mx-lg-0 my-lg-0">
                <Button
                  classnames="btn-dim btn-outline-secondary form-control-lg custNewInvBtn"
                  type="button"
                  onClick={() =>
                    setModal((modals) => ({
                      ...modals,
                      addProduct: true,
                    }))
                  }
                >
                  Dodaj novi proizvod
                </Button>
              </div>
              <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                <Controller
                  control={controlP}
                  name="kolicina"
                  rules={{
                    required: "Unesi količinu",
                    validate: {
                      noZero: (value) =>
                        Number(stringToNum(value)) > 0
                          ? true
                          : "Unesi količinu",
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Količina"
                      customInput={Input}
                      inputMode="decimal"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator={","}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                {errorsP.kolicina && (
                  <span className="error">{errorsP.kolicina.message}</span>
                )}
              </div>
              <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                <Controller
                  control={controlP}
                  name="cijena"
                  rules={{
                    required: "Unesi cijenu",
                    validate: {
                      noZero: (value) =>
                        Number(stringToNum(value)) > 0 ? true : "Unesi cijenu",
                    },
                  }}
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Cijena"
                      customInput={Input}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      thousandSeparator={"."}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />

                {errorsP.cijena && (
                  <span className="error">{errorsP.cijena.message}</span>
                )}
              </div>
              <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                <Controller
                  control={controlP}
                  name="popust"
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Popust"
                      customInput={Input}
                      fixedDecimalScale
                      inputMode="decimal"
                      decimalScale={2}
                      decimalSeparator={","}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="col-12 col-lg  mx-auto my-2 mx-lg-0 my-lg-0">
                <Button
                  type="button"
                  onClick={handleSubmitP(addProduct)}
                  classnames="btn-dim btn-outline-secondary form-control-lg d-flex mx-auto "
                >
                  Potvrdi
                </Button>
              </div>
            </div>
            <br></br>
            {errors.proizvodi && errors.proizvodi.message && (
              <div style={{ maxWidth: 800 }} className="mx-auto">
                <div
                  className="alert alert-dismissible alert-icon alert-fill alert-danger"
                  role="alert"
                >
                  <em className="icon ni ni-cross-circle"></em>
                  {errors.proizvodi && errors.proizvodi.message}
                  <button
                    className="close"
                    data-dismiss="alert"
                    type="button"
                    onClick={() => clearErrors(["proizvodi"])}
                  ></button>
                </div>
              </div>
            )}
            {status.state && status.message.length > 0 && (
              <div style={{ maxWidth: 800 }} className="mx-auto">
                <div
                  className="alert alert-dismissible alert-icon alert-fill alert-success"
                  role="alert"
                >
                  <em className="icon ni ni-check-circle"></em>
                  {status.message.length > 0 && status.message}
                  <button
                    className="close"
                    data-dismiss="alert"
                    type="button"
                    onClick={() => setStatus({ state: false, message: "" })}
                  ></button>
                </div>
              </div>
            )}
            {status.state && status.error.length > 0 && (
              <div style={{ maxWidth: 800 }} className="mx-auto">
                <div
                  className="alert alert-dismissible alert-icon alert-fill alert-danger"
                  role="alert"
                >
                  <em className="icon ni ni-cross-circle"></em>
                  {status.error.length > 0 && status.error}
                  <button
                    className="close"
                    data-dismiss="alert"
                    type="button"
                    onClick={() => setStatus({ state: false, error: "" })}
                  ></button>
                </div>
              </div>
            )}
            {state.proizvodi.length > 0 ? (
              <Table
                disableButtons={true}
                columns={cols}
                data={state.proizvodi}
              />
            ) : null}
            <hr></hr>
            <div className="form-group row d-flex align-items-center justify-content-end mb-3">
              <div className="col-12 col-lg-2">
                <Button
                  disabled={status.state}
                  type="button"
                  onClick={handleSubmit((data) => submit(data, false))}
                  classnames="customButton btn-dim btn-outline-primary form-control-lg d-flex my-2 mx-auto ml-lg-auto mr-lg-0 my-lg-0 "
                >
                  Izdaj račun
                </Button>
              </div>
              <div className="col-12 col-lg-2 my-2 mx-lg-0 my-lg-0">
                <Button
                  disabled={status.state}
                  type="button"
                  classnames="customButton btn-dim btn-outline-success form-control-lg d-flex my-2 mx-auto mx-lg-auto my-lg-0 "
                  onClick={handleSubmit((data) => submit(data, true))}
                >
                  Spremi račun (predložak)
                </Button>
              </div>
              <div className="col-12 col-lg-2 my-2 mx-lg-0 my-lg-0">
                <Button
                  disabled={status.state}
                  type="button"
                  classnames="customButton btn-dim btn-outline-danger form-control-lg d-flex my-2 mx-auto ml-lg-0 mr-lg-auto my-lg-0 "
                  onClick={() => history.push("/pregled-racuna")}
                >
                  Izađi bez spremanja
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal open={status.loading}>
        <div style={{ marginTop: "20%" }}>
          <div class="d-flex justify-content-center">
            <div
              class="spinner-border text-light"
              role="status"
              style={{ width: "5em", height: "5em" }}
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <h1 className="text-center align-self-center text-light">
            Slanje...
          </h1>
        </div>
      </Modal>
      <NewClient
        from="newInvoice"
        open={modals.addClient}
        clients={clients}
        setClient={setValue}
        toggle={() =>
          setModal((modals) => ({ ...modals, addClient: !modals.addClient }))
        }
      />
      <NewProduct
        setProduct={setValueP}
        from="newInvoice"
        open={modals.addProduct}
        toggle={() =>
          setModal((modals) => ({ ...modals, addProduct: !modals.addProduct }))
        }
        products={products}
      />
    </div>
  );
};

export default NewInvoice;

import { useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import Table from "../../components/Table";
import Datepicker from "../../components/DatePicker";
import { getAllPaidInvoices } from "../../helpers/getData";
import { useTitle } from "../../helpers/hooks";
import { formatCurrency } from "../../helpers";

const KprReview = () => {
  const [dates, setDates] = useState({
    from: dayjs().date(1),
    till: dayjs().date(dayjs().daysInMonth()),
  });
  useTitle("KPR");
  const { data, isFetching } = useQuery(
    ["kpr", dates],
    async () =>
      await getAllPaidInvoices(
        dayjs(dates.from).hour(0).minute(0).second(0).millisecond(0),
        dayjs(dates.till).hour(0).minute(0).second(0).millisecond(0)
      )
  );
  const { control, errors } = useForm();

  const columns = useMemo(
    () => [
      { Header: "Redni broj", accessor: "id" },
      { Header: "Nadnevak", accessor: "datumPlacanja" },
      { Header: "Broj računa", accessor: "broj" },
      { Header: "Iznos naplaćen bezgotovinskim putem", accessor: "todo" },
      {
        Header: "Ukupno naplaćeni iznos",
        accessor: "ukupnoPlaceno",
        Cell: (props) => formatCurrency(props.value),
      },
    ],
    []
  );

  return (
    <div className="container-fluid">
      <div className="row d-flex mb-4 mb-lg-4 noPrint" id="kpr">
        <div className="col-12 col-lg-1 d-flex justify-content-center justify-content-lg-end">
          <label className="my-auto mr-0">Raspon računa:</label>
        </div>
        <div className="form-group col-12 col-lg-2 my-0">
          <Controller
            control={control}
            defaultValue={dates.from}
            name="datumOd"
            render={({ onChange, onBlur, value, ref }) => {
              return (
                <Datepicker
                  format="DD.MM.YYYY"
                  autoOk
                  ref={ref}
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setDates((dates) => ({ ...dates, from: e }));
                  }}
                />
              );
            }}
          />

          {errors.datumOd && (
            <span className="error">{errors.datumOd.message}</span>
          )}
        </div>
        <label className="align-self-center m-auto mx-lg-0">-</label>
        <div className="form-group col-12 col-lg-2">
          <Controller
            control={control}
            defaultValue={dates.till}
            name="datumDo"
            render={({ onChange, onBlur, value, ref }) => {
              return (
                <Datepicker
                  format="DD.MM.YYYY"
                  autoOk
                  ref={ref}
                  onBlur={onBlur}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setDates((dates) => ({ ...dates, till: e }));
                  }}
                />
              );
            }}
          />

          {errors.datumDo && (
            <span className="error">{errors.datumDo.message}</span>
          )}
        </div>
      </div>
      {isFetching ? (
        <div>
          <h3>Učitavanje...</h3>
        </div>
      ) : (
        <Table
          columns={columns}
          data={data.kpr}
          name="knjiga_prometa"
          pdfName="kpr"
          exportCols={columns}
          //replace text and dataField inside invoicehr-en html/pdf template
          pdfCols={columns.map((item) => ({
            text: item.Header,
            dataField: item.accessor,
          }))}
          exportData={data.kpr.map((item) => {
            return { ...item, ukupnoPlaceno: item.placeno };
          })}
        />
      )}
    </div>
  );
};

export default KprReview;

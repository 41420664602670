import { useState } from "react";
import dayjs from "dayjs";
import DatePicker from "../../components/DatePicker";
import Button from "../../components/Button";
import { getDates } from "../../helpers";
import { authenticationService } from "../../services/authentication";
import { useTitle } from "../../helpers/hooks";
import { useQuery } from "react-query";
import { getAllInvoices } from "../../helpers/getData";
import { getXml } from "./functions";

const initialDates = (value) => {
  const date = new Date(value.setMonth(value.getMonth() - 1));
  return getDates(date);
};

const generateData = (invoices, currentUser, dates, from) => {
  const filteredInvoices = invoices
    .filter((item) => {
      if (
        dayjs(item.datumRacun, "DD.MM.YYYY HH:mm").isSameOrAfter(dates.from) &&
        dayjs(item.datumRacun, "DD.MM.YYYY HH:mm").isSameOrBefore(dates.till)
      ) {
        return true;
      } else return false;
    })
    .filter((item) => {
      if (
        item.kupacDetails.vrstaKlijenta === "Pravna osoba" &&
        item.kupacDetails.zemlja !== "Hrvatska"
      ) {
        return true;
      } else return false;
    })
    .map((item) => {
      return item.proizvodi.map((proizvod) => {
        if (item.kupacDetails.zemlja.trim() === "EU") {
          return {
            zemlja: item.kupacDetails.zemlja.trim(),
            datum: dayjs(item.datumRacun, "DD.MM.YYYY HH:mm"),
            iznos: proizvod.pdvPrice,
            kupac: item.kupac,
            vrstaKupca: item.kupacDetails.vrstaKlijenta,
            vrstaIsporuke: proizvod.vrstaIsporuke,
            countryCode: item.kupacDetails.pdvid?.slice(0, 2),
            pdvid: item.kupacDetails.pdvid?.slice(
              2,
              item.kupacDetails.pdvid.length
            ),
            iznosRobeI3:
              proizvod.vrstaIsporuke === "Roba" ? proizvod.pdvPrice : 0,
            iznosUslugaI4:
              proizvod.vrstaIsporuke === "Usluga" ? proizvod.pdvPrice : 0,
            zp11: proizvod.vrstaIsporuke === "Roba" ? proizvod.pdvPrice : 0,
            zp14: proizvod.vrstaIsporuke === "Usluga" ? proizvod.pdvPrice : 0,
            iznosRobeI5: 0,
            iznosUslugaI5: 0,
          };
        } else {
          return {
            zemlja: item.kupacDetails.zemlja.trim(),
            datum: dayjs(item.datumRacun, "DD.MM.YYYY HH:mm"),
            iznos: proizvod.pdvPrice,
            kupac: item.kupac,
            vrstaKupca: item.kupacDetails.vrstaKlijenta,
            vrstaIsporuke: proizvod.vrstaIsporuke,
            countryCode: item.kupacDetails.pdvid?.slice(0, 2),
            pdvid: item.kupacDetails.pdvid?.slice(
              2,
              item.kupacDetails.pdvid.length
            ),
            iznosRobeI5:
              proizvod.vrstaIsporuke === "Roba" ? proizvod.pdvPrice : 0,
            iznosUslugaI5:
              proizvod.vrstaIsporuke === "Usluga" ? proizvod.pdvPrice : 0,
            iznosRobeI3: 0,
            iznosUslugaI4: 0,
          };
        }
      });
    })
    .flat(2);
  const ZP = filteredInvoices
    .filter((item) => item.zemlja.trim() === "EU")
    .reduce((sums, object) => {
      return {
        ...sums,
        [object.pdvid]: {
          zp11Iznos: sums[object.pdvid]
            ? sums[object.pdvid].zp11Iznos + Number(object.zp11)
            : object.zp11,
          zp14Iznos: sums[object.pdvid]
            ? sums[object.pdvid].zp14Iznos + Number(object.zp14)
            : object.zp14,
          countryCode: object.countryCode,
        },
      };
    }, {});
  const zpSum = Object.keys(ZP)
    .map((key, index) => {
      return ZP[key];
    })
    .reduce(
      (a, b) => {
        return {
          zp11Iznos: a.zp11Iznos + b.zp11Iznos,
          zp14Iznos: a.zp14Iznos + b.zp14Iznos,
        };
      },
      { zp11Iznos: 0, zp14Iznos: 0 }
    );
  const pdvSums = filteredInvoices.reduce(
    (a, b) => {
      return {
        iznosRobeI3: Number(a.iznosRobeI3) + Number(b.iznosRobeI3),
        iznosUslugaI4: Number(a.iznosUslugaI4) + Number(b.iznosUslugaI4),
        iznosUslugaI5: Number(a.iznosUslugaI5) + Number(b.iznosUslugaI5),
        iznosRobeI5: Number(a.iznosRobeI5) + Number(b.iznosRobeI5),
      };
    },
    {
      iznosRobeI3: 0,
      iznosUslugaI4: 0,
      iznosUslugaI5: 0,
      iznosRobeI5: 0,
      ukupnoVIII5: 0,
    }
  );
  Object.assign(pdvSums, {
    ukupnoVIII5:
      pdvSums.iznosUslugaI4 + pdvSums.iznosUslugaI5 + pdvSums.iznosRobeI5,
    ukupnoSve:
      pdvSums.iznosRobeI3 +
      pdvSums.iznosUslugaI4 +
      pdvSums.iznosUslugaI5 +
      pdvSums.iznosRobeI5,
  });
  if (from === "PDV") {
    getXml(pdvSums, currentUser, dates, "PDV");
  } else {
    getXml({ ZP, zpSum }, currentUser, dates, "ZP");
  }
  return { ZP, zpSum, pdvSums };
};

const EPorezna = () => {
  const currentUser = authenticationService.currentUserValue;
  useTitle("ePorezna XML");
  const [dates, setDates] = useState(initialDates(new Date()));
  const { data } = useQuery("invoices", getAllInvoices);

  console.log(dates, data);

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-12 col-lg-8">
          <div className="card card-bordered">
            <div className="card-inner">
              <div className="card-head"></div>
              <div className="row d-flex mb-5">
                <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end">
                  <label className="my-auto">Period:</label>
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-center">
                  <DatePicker
                    value={dates.from}
                    autoOk
                    openTo="month"
                    views={["year", "month"]}
                    disableFuture
                    maxDate={dates.till}
                    format="MMMM YYYY"
                    openOnFocus={true}
                    pickerType="pure"
                    onChange={(value) => {
                      setDates(getDates(value.$d));
                    }}
                  />
                </div>
                <div className="col-md-4"></div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-start my-2 my-lg-0">
                  <Button
                    classnames="btn-dim btn-outline-primary"
                    onClick={() =>
                      generateData(data, currentUser, dates, "PDV")
                    }
                  >
                    Preuzmi XML PDV Obrazca
                  </Button>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end my-2 my-lg-0">
                  <Button
                    onClick={() => generateData(data, currentUser, dates, "ZP")}
                    classnames="btn-dim btn-outline-primary"
                  >
                    Preuzmi XML ZP Obrazca
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
};

export default EPorezna;

import { authenticationService } from "../services/authentication";

export function resetState(state) {
  const currentUser = authenticationService.currentUserValue;
  return {
    ...state,
    invoice: {
      klijent: null,
      datumRacuna: new Date(),
      izvrsenoOd: new Date(),
      izvrsenoDo: new Date(),
      datumDospijeca: new Date(),
      operater: currentUser?.operater,
      proizvod: null,
      nacinPlacanja: {
        value: "Transakcijski račun",
        label: "Transakcijski račun",
      },
      from: "",
    },
    proizvodi: [],
    proizvod: {
      proizvod: null,
      cijena: null,
      kolicina: null,
      popust: null,
    },
  };
}
export function updateInvoice(state, payload) {
  return {
    ...state,
    invoice: {
      ...state.invoice,
      ...payload,
    },
  };
}
export function updateProizvod(state, payload) {
  return {
    ...state,
    proizvod: {
      ...state.proizvod,
      ...payload,
    },
  };
}
export function addProizvodi(state, payload) {
  console.log(payload);
  return { ...state, proizvodi: payload };
}
export function updateProizvodi(state, payload) {
  const exists = state.proizvodi.find((item) => {
    if (
      item.id === payload.id &&
      item.cijena === payload.cijena &&
      item.rabat === payload.rabat
    ) {
      return item;
    } else return null;
  });
  if (exists) {
    const kolicina = exists.kolicina + payload.kolicina;
    const totalPrice = exists.cijena * kolicina;
    const totalPopust = totalPrice * (payload.rabat / 100);
    const newPrice = totalPrice - totalPopust;

    Object.assign(exists, {
      kolicina,
      ukupnaCijena: newPrice,
      pdvPrice: newPrice,
      newPrice: newPrice,
      cijenaHRK: newPrice,
    });
  }
  const proizvodi = exists
    ? state.proizvodi.filter((item) => {
        if (
          item.id === payload.id &&
          item.cijena === payload.cijena &&
          item.rabat === payload.rabat
        ) {
          return false;
        } else return true;
      })
    : state.proizvodi;
  return {
    ...state,
    proizvodi: [...proizvodi, exists ? exists : payload],
  };
}
export function deleteProizvodi(state, payload) {
  const removed = state.proizvodi.filter((item) => {
    if (
      item.id === payload.id &&
      item.cijena === payload.cijena &&
      item.rabat === payload.rabat
    ) {
      return false;
    } else return true;
  });
  return {
    ...state,
    proizvodi: removed,
  };
}
export function updateInvoicePreview(state, payload) {
  return { ...state, invoicePreview: { ...state.invoicePreview, ...payload } };
}
export function updateClient(state, payload) {
  return { ...state, clientData: { ...state.clientData, ...payload } };
}
export function updateProduct(state, payload) {
  return { ...state, productData: { ...state.productData, ...payload } };
}
export function updateInvoicePayment(state, payload) {
  return { ...state, invoicePayment: { ...state.invoicePayment, ...payload } };
}
export function updateAccountInfo(state, payload) {
  return { ...state, accountInfo: { ...state.accountInfo, ...payload } };
}
export function updateProceeds(state, payload) {
  return { ...state, proceedsData: { ...state.proceedsData, ...payload } };
}

import { Link } from "react-router-dom";
import Button from "../components/Button";
import notFound from "../assets/images/gfx/errorCustom-404.svg";

const NoMatch = () => {
  return (
    <div class="nk-app-root mt-n5">
      <div class="nk-main ">
        <div class="nk-wrap nk-wrap-nosidebar">
          <div class="nk-content ">
            <div class="nk-block nk-block-middle wide-md mx-auto">
              <div class="nk-block-content nk-error-ld text-center">
                <img class="nk-error-gfx" src={notFound} alt="404 logo" />
                <div class="wide-xs mx-auto">
                  <h3 class="nk-error-title">Ups! Zašto ste ovdje?</h3>
                  <p class="nk-error-text">
                    Izgleda da pokušavate pristupiti stranici koja ne postoji ili je uklonjena.
                  </p>
                  <Link to="/">
                    <Button classnames="btn btn-lg btn-primary btn-pausal">
                      Nazad
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NoMatch;

import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie"
import logo from "../../assets/images/logo1beta.png";
import { authenticationService } from "../../services/authentication";
import { useStateMachine } from "little-state-machine";
import { updateInvoice } from "../../helpers/updateState";
import { generatePassword } from "../../helpers/functions";
import { GoogleIcon } from "../Main/icons";

const Register = () => {
  const currentUser = authenticationService.currentUserValue;
  let history = useHistory();
  const { actions } = useStateMachine({ updateInvoice });
  const [status, setStatus] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [sending, setSending] = useState(false);
  const {
    register,
    handleSubmit,
    clearErrors,
    errors,
    setError,
    setValue,
  } = useForm();

  useEffect(() => {
    if (currentUser) {
      history.push("/");
    }
  }, [history, currentUser]);

  const submit = (data) => {
    setSending(true);
    authenticationService
      .register({
        ...data,
        naziv: "",
        ime: "",
        prezime: "",
        ulica: "",
        kucniBroj: "",
        mjesto: "",
        postanskiBroj: "",
        oib: "",
        iban: "",
        banka: "",
        telefon: "",
        djelatnost: "",
        nazivDjelatnosti: "",
        oznakaProstora: "",
        oznakaUredaja: "",
        delimiter: "-",
        brojRacuna: "",
        logo: "",
        logoBase64: "",
      })
      .then((res) => {
        //  console.log(res, "after register");
        if (!res?.status) {
          setSending(false);
          setError("status", { type: "manual", message: res });
          setTimeout(() => {
            clearErrors(["status"]);
          }, 3000);
        } else {
          setSending(false);
          setStatus("Registracija uspješna, bit ćete prijavljeni");
          setTimeout(() => {
            authenticationService
              .login(data.username, data.password)
              .then((res) => {
                if (!res?.id) {
                  //console.log("login error", res);
                  setSending(false);
                  setError("status", { type: "manual", message: res });
                  setTimeout(() => {
                    clearErrors(["status"]);
                  }, 3000);
                } else {
                  actions.updateInvoice(res);
                  setSending(false);
                  setStatus("");
                  history.push("/");
                }
              });
          }, 3000);
        }
      });
  };

  const togglePassVisibile = (e) => {
    setShowPass((showPass) => !showPass);
  };
  useEffect(() => {
    const cookie = Cookies.get("currentUser");
    console.log(cookie);
    if (cookie) {
      const user = authenticationService.googleLogin(cookie);
      actions.updateInvoice(user);
      actions.updateAccountInfo(user);
      history.push("/");
    } else console.log("no cookie");
  }, [history, actions]);

  return (
    <div className="nk-app-root mt-n3">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <div className="nk-split nk-split-page nk-split-md">
              <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
                {/*  <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                  <Link
                    to="/"
                    className="toggle btn-white btn btn-icon btn-light"
                    data-target="athPromo"
                  >
                    <em className="icon ni ni-info"></em>
                  </Link>
                </div> */}
                <div className="nk-block nk-block-middle nk-auth-body">
                  <div className="brand-logo pb-5">
                    <div className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={logo}
                        alt="logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={logo}
                        alt="logo-dark"
                      />
                    </div>
                  </div>
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">
                        Registracija novog računa
                      </h5>
                      {/*  <div className="nk-block-des">
                        <p>
                          Access the DashLite panel using your email and
                          passcode.
                        </p>
                      </div> */}
                    </div>
                  </div>

                  <form onSubmit={handleSubmit(submit)}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" for="username">
                          Korisničko ime
                        </label>
                      </div>
                      <input
                        type="text"
                        className={`form-control form-control-lg ${
                          errors.username && "error"
                        }`}
                        id="username"
                        name="username"
                        ref={register({ required: "Unesi korisničko ime" })}
                        placeholder="Unesi korisničko ime"
                      />
                      {errors.username && (
                        <span className="error">{errors.username.message}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" for="email">
                          Email
                        </label>
                      </div>
                      <input
                        type="text"
                        className={`form-control form-control-lg ${
                          errors.email && "error"
                        }`}
                        id="email"
                        name="email"
                        ref={register({
                          required: "Unesi email",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Email nije ispravan",
                          },
                        })}
                        placeholder="Unesi korisničko ime"
                      />
                      {errors.email && (
                        <span className="error">{errors.email.message}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" for="password">
                          Lozinka
                        </label>
                        <Link
                          className="link link-primary link-sm"
                          tabIndex="-1"
                          onClick={() => {
                            setShowPass(true);
                            setValue("password", generatePassword());
                          }}
                        >
                          Predloži lozinku?
                        </Link>
                      </div>
                      <div className="form-control-wrap">
                        <button
                          tabIndex="-1"
                          type="button"
                          onClick={togglePassVisibile}
                          className="form-icon form-icon-right passcode-switch blankBtn"
                          data-target="password"
                        >
                          {showPass ? (
                            <em className="passcode-icon icon-show icon ni ni-eye-off"></em>
                          ) : (
                            <em className="passcode-icon icon-show icon ni ni-eye"></em>
                          )}
                        </button>
                        <input
                          type={showPass ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Unesi lozinku"
                          ref={register({ required: "Unesi lozinku" })}
                          className={`form-control form-control-lg ${
                            errors.password && "error"
                          }`}
                          style={{ marginBottom: 3 }}
                        />
                        {errors.password && (
                          <span className="error position-absolute">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group form-control-wrap mt-2">
                        <div className="custom-control custom-control-xs custom-checkbox customCheck">
                          <input
                            type="checkbox"
                            className="form-control custom-control-input position-relative"
                            id="terms"
                            name="terms"
                            ref={register({ required: "Potvrdi ..." })}
                          />

                          <label className="custom-control-label" for="terms">
                            Prihvaćam ...{" "}
                            <button
                              type="button"
                              className="blankBtn link customLink"
                              tabIndex="-1"
                              onClick={() =>
                                alert("This will show terms and conditions")
                              }
                            >
                              Uvjeti &amp; odredbe
                            </button>{" "}
                          </label>
                          {errors.terms && (
                            <span className="error position-absolute mt-4 ">
                              {errors.terms.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {errors.status && errors.status.message && (
                      <div
                        className="alert alert-dismissible alert-icon alert-fill alert-danger"
                        role="alert"
                      >
                        <em className="icon ni ni-cross-circle"></em>
                        {errors.status && errors.status.message}
                        <button
                          className="close"
                          data-dismiss="alert"
                          type="button"
                          onClick={() => clearErrors(["status"])}
                        ></button>
                      </div>
                    )}
                    {status.length > 0 && (
                      <div
                        className="alert alert-dismissible alert-icon alert-fill alert-success"
                        role="alert"
                      >
                        <em className="icon ni ni-check-circle"></em>
                        {status.length > 0 && status}
                        <button
                          className="close"
                          data-dismiss="alert"
                          type="button"
                          onClick={() => clearErrors(["status"])}
                        ></button>
                      </div>
                    )}
                    <div className="form-group mt-5">
                      <button
                        type="submit"
                        disabled={sending || status.length > 0 ? true : false}
                        className="btn btn-lg btn-primary btn-block btn-pausal"
                      >
                        {sending ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span> Prijava... </span>{" "}
                          </>
                        ) : (
                          "Registriraj se"
                        )}
                      </button>
                    </div>
                  </form>

                  <div className="form-note-s2 pt-4 " style={{ fontSize: 16 }}>
                    Imaš račun?
                    <Link to="/login" className="ml-1">
                      Prijavi se
                    </Link>
                  </div>
                  <div className="text-center pt-4 pb-3">
                    <h6 className="overline-title overline-title-sap">
                      <span>ILI</span>
                    </h6>
                  </div>
                  <ul className="nav justify-center gx-4">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Facebook
                      </a>
                    </li>
                    <li className="nav-item">
                      <button
                        href="#"
                        class="btn btn-light"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_HOST_URL}/users/google-login`,
                            "_self"
                          )
                        }
                      >
                        <GoogleIcon />
                        <span className="ml-1">Google</span>{" "}
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="nk-block nk-auth-footer pb-0">
                  <div className="nk-block-between">
                    <ul className="nav nav-sm ">
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Terms & Condition
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Help
                        </a>
                      </li>
                      {/*      <li className="nav-item dropup">
                        <a
                          className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                          data-toggle="dropdown"
                          data-offset="0,10"
                        >
                          <small>English</small>
                        </a>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="language-list">
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/english.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">English</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/spanish.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Español</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/french.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Français</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img
                                  src="./images/flags/turkey.png"
                                  alt=""
                                  className="language-flag"
                                />
                                <span className="language-name">Türkçe</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    */}{" "}
                    </ul>
                  </div>
                  <div className="mt-3">
                    <p>
                      &copy; {new Date().getFullYear()} Paušal.hr All Rights
                      Reserved.
                    </p>
                  </div>
                </div>
              </div>

              <div className="nk-split-content nk-split-stretch bg-abstract"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

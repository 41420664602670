import { createContext } from "react";
import { authenticationService } from "../services/authentication";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const getWelcomeMessage = () => {
  const currentUser = authenticationService.currentUserValue;

  const date = new Date();
  const afterMidnight = dayjs().set("hour", 0).set("minute", 0);
  const morning = dayjs().set("hour", 6).set("minute", 0);
  const noon = dayjs().set("hour", 12).set("minute", 0);
  const evening = dayjs().set("hour", 18).set("minute", 0);
  const midnight = dayjs().set("hour", 23).set("minute", 59);

  if (dayjs(date).isSameOrAfter(morning) && dayjs(date).isSameOrBefore(noon)) {
    return `Dobro jutro, ${currentUser && currentUser.ime}!`;
  } else if (
    dayjs(date).isSameOrAfter(noon) &&
    dayjs(date).isSameOrBefore(evening)
  ) {
    return `Dobar dan, ${currentUser && currentUser.ime}!`;
  } else if (
    dayjs(date).isSameOrAfter(evening) &&
    dayjs(date).isSameOrBefore(midnight)
  ) {
    return `Dobra večer, ${currentUser && currentUser.ime}!`;
  } else if (
    dayjs(date).isSameOrAfter(afterMidnight) &&
    dayjs(date).isSameOrBefore(morning)
  ) {
    return `Dobra večer, ${currentUser && currentUser.ime}!`;
  }
};

const TitleContext = createContext({
  title: getWelcomeMessage(),
  setTitle: () => {},
});

export default TitleContext;

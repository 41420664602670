import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { formatCurrency } from "../../helpers";

const DeleteModal = ({ open, toggle, state, handleDelete }) => {
  const { invoice } = state.invoicePayment;

  return (
    <Modal
      title="Obriši plaćanje"
      open={open}
      handleClose={() => toggle(false)}
      toggle={() => toggle((open) => !open)}
      size="lg"
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-center">
              Jeste li sigurni da želite obrisati plaćanje:{" "}
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col my-2">
            <p
              className="text-center"
              style={{ fontSize: 22, fontWeight: 500 }}
            >
              Broj računa:{" "}
              <span style={{ fontWeight: 600 }}>{invoice.broj}</span>
            </p>
          </div>
          <div className="col my-2">
            <p
              className="text-center"
              style={{ fontSize: 22, fontWeight: 500 }}
            >
              Plaćeni iznos:{" "}
              <span style={{ fontWeight: 600 }}>
                {formatCurrency(state.invoicePayment.thisPayment)} HRK
              </span>
            </p>
          </div>
          <div className="col-12">
            <p
              className="text-center"
              style={{ fontSize: 18, fontWeight: 600 }}
            ></p>
          </div>
          <div className="col-12 ">
            <Button
              type="button"
              onClick={() => handleDelete(state.invoicePayment)}
              classnames="customButton d-flex btn-dim btn-outline-danger form-control-lg d-flex mt-2 mx-auto mb-0 "
            >
              Obriši
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;

import { useState } from "react";
import saveAs from "file-saver";
import { authenticationService } from "../../services/authentication";
import { handlePostRequest } from "../../helpers/handle-response";
import Input from "../../components/Input";
import Datepicker from "../../components/DatePicker";
import Button from "../../components/Button";
import InputValue from "../../components/InputValue";
import { useTitle } from "../../helpers/hooks";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { b64toBlob, stringToNum } from "../../helpers/functions";

const Tz = () => {
  useTitle("TZ obrazac");
  const currentUser = authenticationService.currentUserValue;
  const [downloading, setDownloading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    errors,
  } = useForm({
    defaultValues: {
      podrucniUred: "",
      ispostava: "",
      naziv: `${currentUser.vlasnik}, ${currentUser.adresa}, ${currentUser.postanskiBroj} ${currentUser.mjesto}`,
      tvrtka: `${currentUser.naziv}, ${currentUser.adresa}, ${currentUser.postanskiBroj} ${currentUser.mjesto}`,
      sifraOpcine: "",
      brojNazivDjelatnosti: `${currentUser.djelatnost}, ${currentUser.nazivDjelatnosti}`,
      ured: "",
      obracunOd: new Date(`${new Date().getFullYear()}-01-01T12:00:00`),
      obracunDo: new Date(`${new Date().getFullYear()}-12-31T12:00:00`),
      osnovica: 0,
      stopa: 0,
      obracunato: 0,
      uplaceno: 0,
      razlikaUplata: 0,
      razlikaPovrat: 0,
      mjesecniPredujam: 0,
      mjesto: "",
      datum: new Date(),
      mjesto2: "",
      datum2: new Date(),
      odgovornaOsobaObrazac: "",
      odgovornaOsoba: "",
    },
  });

  const submit = (data) => {
    setDownloading(true);
    handlePostRequest(`https://pdf-api.accountant.hr/create-pdf/`, [
      {
        values: {
          ...data,
          osnovica: stringToNum(data.osnovica),
          obracunato: stringToNum(data.obracunato),
          uplaceno: stringToNum(data.uplaceno),
          stopa: stringToNum(data.stopa),
          mjesecniPredujam: stringToNum(data.stopa),
          razlikaPovrat: stringToNum(data.razlikaPovrat),
          razlikaUplata: stringToNum(data.razlikaUplata),
        },
        name: "tz-obrazac",
      },
    ])
      .then((res) => {
        setDownloading(false);

        saveAs(b64toBlob(res.data), "tz-obrazac.pdf");
      })
      .catch((e) => alert(e));
  };

  return (
    <div className="container">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>TZ Obrazac</h4>
          </div>
          <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="form-group row d-flex">
              <div className="col-12 col-lg-6 customBorder">
                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Područni ured:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg"
                      placeholder="Područni ured"
                      name="podrucniUred"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.podrucniUred && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.podrucniUred.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Ispostava:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg"
                      placeholder="Ispostava"
                      name="ispostava"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.ispostava && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.ispostava.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto text-center text-lg-right">
                      Naziv pravne osobe/ime i prezime fizičke osobe, adresa
                      sjedišta/prebivališta, porezni broj:
                    </label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <div
                      className="form-control-wrap "
                      style={{ width: "100%" }}
                    >
                      <textarea
                        className="form-control no-resize"
                        id="default-textarea"
                        name="naziv"
                        ref={register({ required: "Obvezno polje" })}
                      />
                      {errors.naziv && (
                        <span className="error" style={{ bottom: -20 }}>
                          {errors.naziv.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto text-center text-lg-right">
                      Tvrtka pravne osobe/obrta prema mjestu obavljanja
                      djelatnosti, odnosno adresa na kojoj se pružaju usluge:
                    </label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg my-auto"
                      name="tvrtka"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.tvrtka && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.tvrtka.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto text-right">
                      Šifra općine/grada sjedišta/prebivališta:
                    </label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="sifraOpcine"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Šifra općine"
                          customInput={Input}
                          allowLeadingZeros={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    {errors.sifraOpcine && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.sifraOpcine.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Broj i naziv djelatnosti:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg"
                      readOnly
                      name="brojNazivDjelatnosti"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.brojNazivDjelatnosti && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.brojNazivDjelatnosti.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Obračun članarine:</label>
                  </div>
                  <div className="form-group col-12 col-lg my-0">
                    <Controller
                      control={control}
                      name="obracunOd"
                      rules={{ required: "Obavezno" }}
                      render={({ onChange, onBlur, value, ref }) => {
                        return (
                          <Datepicker
                            format="DD.MM.YYYY"
                            autoOk
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />

                    {errors.obracunOd && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.obracunOd.message}
                      </span>
                    )}
                  </div>
                  <label className="align-self-center m-auto mx-lg-0">-</label>
                  <div className="form-group col-12 col-lg">
                    <Controller
                      control={control}
                      name="obracunDo"
                      rules={{ required: "Obavezno" }}
                      render={({ onChange, onBlur, value, ref }) => {
                        return (
                          <Datepicker
                            format="DD.MM.YYYY"
                            autoOk
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />

                    {errors.obracunDo && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.obracunDo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Osnovica:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="osnovica"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Osnovica"
                          customInput={InputValue}
                          allowLeadingZeros={true}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            const osnovica = stringToNum(e.target.value);
                            const stopa = stringToNum(getValues("stopa"));
                            const uplaceno = stringToNum(getValues("uplaceno"));
                            const totalMonths =
                              getValues("obracunDo").getMonth() +
                              1 -
                              (getValues("obracunOd").getMonth() + 1) +
                              1;
                            setValue("obracunato", osnovica * stopa);
                            setValue(
                              "razlikaUplata",
                              osnovica * stopa - uplaceno > 0
                                ? osnovica * stopa - uplaceno
                                : 0
                            );
                            setValue(
                              "mjesecniPredujam",
                              (osnovica * stopa) / totalMonths
                            );
                          }}
                          value={value}
                        />
                      )}
                    />
                    {errors.osnovica && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.osnovica.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Stopa:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="stopa"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Osnovica"
                          customInput={InputValue}
                          allowNegative={false}
                          allowLeadingZeros={true}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            const stopa = stringToNum(e.target.value);
                            const osnovica = stringToNum(getValues("osnovica"));
                            const uplaceno = stringToNum(getValues("uplaceno"));
                            const totalMonths =
                              getValues("obracunDo").getMonth() +
                              1 -
                              (getValues("obracunOd").getMonth() + 1) +
                              1;
                            setValue("obracunato", osnovica * stopa);
                            setValue(
                              "razlikaUplata",
                              osnovica * stopa - uplaceno > 0
                                ? osnovica * stopa - uplaceno
                                : 0
                            );
                            setValue(
                              "mjesecniPredujam",
                              (osnovica * stopa) / totalMonths
                            );
                          }}
                          value={value}
                        />
                      )}
                    />
                    {errors.stopa && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.stopa.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Obračunata svota:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="obracunato"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Obračunata svota"
                          customInput={InputValue}
                          allowLeadingZeros={true}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            const obracunato = stringToNum(e.target.value);
                            const uplaceno = stringToNum(getValues("uplaceno"));
                            const totalMonths =
                              getValues("obracunDo").getMonth() +
                              1 -
                              (getValues("obracunOd").getMonth() + 1) +
                              1;

                            setValue(
                              "razlikaUplata",
                              obracunato - uplaceno > 0
                                ? obracunato - uplaceno
                                : 0
                            );
                            setValue(
                              "razlikaPovrat",
                              uplaceno - obracunato > 0
                                ? Math.abs(uplaceno - obracunato)
                                : 0
                            );
                            setValue(
                              "mjesecniPredujam",
                              obracunato / totalMonths
                            );
                          }}
                          value={value}
                        />
                      )}
                    />
                    {errors.obracunato && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.obracunato.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/*Start of second collumn*/}

              <div className="col-12 col-lg-6">
                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Uplaćeni predujam:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="uplaceno"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Obračunata svota"
                          customInput={InputValue}
                          allowLeadingZeros={true}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            const uplaceno = stringToNum(e.target.value);
                            const obracunato = stringToNum(
                              getValues("obracunato")
                            );

                            setValue(
                              "razlikaUplata",
                              obracunato - uplaceno > 0
                                ? obracunato - uplaceno
                                : 0
                            );
                            setValue(
                              "razlikaPovrat",
                              obracunato - uplaceno < 0
                                ? Math.abs(obracunato - uplaceno)
                                : 0
                            );
                          }}
                          value={value}
                        />
                      )}
                    />
                    {errors.uplaceno && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.uplaceno.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Razlika za uplatu:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="razlikaUplata"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Razlika za uplatu"
                          customInput={InputValue}
                          allowLeadingZeros={true}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    {errors.razlikaUplata && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.razlikaUplata.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Razlika za povrat:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="razlikaPovrat"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Razlika za povrat"
                          customInput={InputValue}
                          allowLeadingZeros={true}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    {errors.razlikaPovrat && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.razlikaPovrat.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">
                      Mjesečni predujam za naredno razdoblje:
                    </label>
                  </div>
                  <div className="col-12 col-lg-6 form-group d-flex justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="mjesecniPredujam"
                      rules={{
                        required: "Unesi količinu",
                      }}
                      render={({ onChange, onBlur, value, name }) => (
                        <NumberFormat
                          name={name}
                          classnames="form-control-lg"
                          placeholder="Razlika za uplatu"
                          customInput={InputValue}
                          allowLeadingZeros={true}
                          allowNegative={false}
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator={","}
                          thousandSeparator={"."}
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            const obracunato = stringToNum(
                              getValues("obracunato")
                            );
                            const totalMonths =
                              getValues("obracunDo").getMonth() +
                              1 -
                              (getValues("obracunOd").getMonth() + 1) +
                              1;
                            setValue(
                              "mjesecniPredujam",
                              obracunato / totalMonths
                            );
                          }}
                          value={value}
                        />
                      )}
                    />
                    {errors.mjesecniPredujam && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.mjesecniPredujam.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row form-group d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Mjesto:</label>
                  </div>
                  <div className=" form-group col-12 mb-1 col-lg-6 d-flex justify-content-center justify-content-lg-start">
                    <Input
                      classnames="customInput form-control-lg"
                      name="mjesto"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.mjesto && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.mjesto.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Datum:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="datum"
                      rules={{ required: "Obavezno" }}
                      render={({ onChange, onBlur, value, ref }) => {
                        return (
                          <Datepicker
                            format="DD.MM.YYYY"
                            autoOk
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />
                  </div>
                  {errors.datum && (
                    <span className="error" style={{ bottom: -20 }}>
                      {errors.datum.message}
                    </span>
                  )}
                </div>
                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto text-right">
                      Odgovorna osoba za sastavljanje obrasca:
                    </label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg"
                      name="odgovornaOsobaObrazac"
                      ref={register({ required: "Obvezno polje" })}
                    />{" "}
                    {errors.odgovornaOsobaObrazac && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.odgovornaOsobaObrazac.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Odgovorna osoba:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg"
                      name="odgovornaOsoba"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.odgovornaOsoba && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.odgovornaOsoba.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Mjesto 2:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Input
                      classnames="form-control-lg"
                      name="mjesto2"
                      ref={register({ required: "Obvezno polje" })}
                    />
                    {errors.mjesto2 && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.mjesto2.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row d-flex mb-4 mb-lg-2">
                  <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                    <label className="my-auto">Datum 2:</label>
                  </div>
                  <div className="col-12 col-lg-6 d-flex mb-1 justify-content-center justify-content-lg-start">
                    <Controller
                      control={control}
                      name="datum2"
                      rules={{ required: "Obavezno" }}
                      render={({ onChange, onBlur, value, ref }) => {
                        return (
                          <Datepicker
                            format="DD.MM.YYYY"
                            autoOk
                            ref={ref}
                            onBlur={onBlur}
                            value={value}
                            onChange={onChange}
                          />
                        );
                      }}
                    />

                    {errors.datum2 && (
                      <span className="error" style={{ bottom: -20 }}>
                        {errors.datum2.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center mt-5">
              <Button
                disabled={downloading}
                type="submit"
                classnames="btn-dim btn-outline-primary"
              >
                {downloading ? (
                  <>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Loading...</span>
                  </>
                ) : (
                  "Preuzmi PDF"
                )}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Tz;

import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { useStateMachine } from "little-state-machine";
import { authenticationService } from "../../services/authentication";
import { updateAccountInfo } from "../../helpers/updateState";
import { getZip, updateProfile } from "../../helpers/functions";
import Modal from "../../components/Modal";
import Input from "../../components/Input";
import Button from "../../components/Button";

const EditInvoice = ({ open, toggle }) => {
  const currentUser = authenticationService.currentUserValue;
  const { actions, state } = useStateMachine({ updateAccountInfo });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    errors,
  } = useForm({
    defaultValues: state.accountInfo,
  });
  const [status, setStatus] = useState({
    state: false,
    message: "",
    loading: false,
  });

  const submit = (data) => {
    setStatus({ state: true, message: "Slanje...", loading: true });
    console.log(data);
    const tempData = new FormData();
    data.id = currentUser.id;

    for (var value in data) {
      if (value === "email" && currentUser.email !== "") {
      } else {
        tempData.append(value, data[value]);
      }
    }
    //create function to update and get profile data
    updateProfile(
      tempData,
      (successRes) => {
        console.log(successRes);
        actions.updateAccountInfo({ ...successRes.data, logo: null });
        setStatus({
          state: true,
          message: "Podaci spremljeni.",
          loading: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (errorRes) => {
        console.log(errorRes);
      }
    );
  };
  return (
    <Modal open={open.state} toggle={toggle} title="Uredi informacije faktura">
      <form onSubmit={handleSubmit(submit)} className="form-validate is-alter">
        <div className="form-group">
          <label className="form-label" hmtlFor="full-name">
            Broj prošlog računa:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="brojRacuna"
              control={control}
              name="brojRacuna"
              defaultValue={state.accountInfo.brojRacuna}
              rules={{
                required: "Unesi početni broj računa",
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  autoFocus={open.to === "brojRacuna"}
                  getInputRef={ref}
                  name={name}
                  allowLeadingZeros
                  decimalSeparator={false}
                  type="text"
                  classnames="form-control-lg"
                  placeholder="Poštanski broj"
                  customInput={Input}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            {errors.brojRacuna && (
              <span className="error">{errors.brojRacuna.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" hmtlFor="email-address">
            Oznaka poslovnog prostora:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "oznakaProstora"}
              classnames={`form-control-lg ${errors.oznakaProstora && "error"}`}
              name="oznakaProstora"
              ref={register({ required: "Upiši oznaku prostora" })}
            />
            {errors.oznakaProstora && (
              <span className="error">{errors.oznakaProstora.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" hmtlFor="phone-no">
            Oznaka naplatnog uređaja:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "oznakaUredaja"}
              classnames={`form-control-lg ${errors.oznakaUredaja && "error"}`}
              name="oznakaUredaja"
              ref={register({ required: "Upiši oznaku uređaja" })}
            />
            {errors.oznakaUredaja && (
              <span className="error">{errors.oznakaUredaja.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" hmtlFor="phone-no">
            Operater:
          </label>
          <div className="form-control-wrap">
            <Input
              autoFocus={open.to === "operater"}
              classnames={`form-control-lg ${errors.operater && "error"}`}
              name="operater"
              ref={register({ required: "Unesi operatera" })}
            />
            {errors.operater && (
              <span className="error">{errors.operater.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" hmtlFor="phone-no">
            OIB operatera:
          </label>
          <div className="form-control-wrap">
            <Controller
              id="oibOperatera"
              control={control}
              name="oibOperatera"
              defaultValue={state.accountInfo.oibOperatera}
              rules={{
                required: "Unesi OIB operatera",
                minLength: {
                  value: 11,
                  message: "OIB operatera mora sadržavati najmanje 11 znakova",
                },
              }}
              render={({ onChange, onBlur, value, name, ref }) => (
                <NumberFormat
                  autoFocus={open.to === "oibOperatera"}
                  getInputRef={ref}
                  maxLength={11}
                  name={name}
                  allowLeadingZeros
                  decimalSeparator={false}
                  type="text"
                  classnames="form-control-lg"
                  placeholder="OIB operatera"
                  customInput={Input}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />

            {errors.oibOperatera && (
              <span className="error">{errors.oibOperatera.message}</span>
            )}
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" hmtlFor="phone-no">
            Interni akt:
          </label>
          <div className="form-control-wrap">
            <Input classnames="form-control-lg"></Input>
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" hmtlFor="phone-no">
            Odluka o blagajničkom maksimumu:
          </label>
          <div className="form-control-wrap">
            <Input classnames="form-control-lg"></Input>
          </div>
        </div>
        <hr></hr>
        {status.state && status.message.length > 0 && (
          <div style={{ maxWidth: 800 }} className="mx-auto">
            <div
              className={`alert alert-dismissible alert-icon alert-fill alert-${
                status.loading ? "info" : "success"
              }`}
              role="alert"
            >
              <em
                className={`icon ni ni-${
                  status.loading ? "alert" : "check"
                }-circle`}
              ></em>
              {status.message.length > 0 && status.message}
              <button
                className="close"
                data-dismiss="alert"
                type="button"
                onClick={() => setStatus({ state: false, message: "" })}
              ></button>
            </div>
          </div>
        )}
        <div className="form-group mt-4">
          <div className="form-control-wrap row">
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-start">
              <Button
                disabled={status.state}
                type="submit"
                classnames="btn-dim btn-outline-primary btn-lg"
              >
                Spremi promjene
              </Button>
            </div>
            <div className="col-12 col-lg-6 d-flex my-2 my-lg-0 justify-content-center justify-content-lg-end">
              <Button
                disabled={status.state}
                type="button"
                onClick={toggle}
                classnames="btn-dim btn-outline-danger btn-lg"
              >
                Odustani
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EditInvoice;

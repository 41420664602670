export const invoice = {
  klijent: null,
  datumRacuna: new Date(),
  izvrsenoOd: new Date(),
  izvrsenoDo: new Date(),
  datumDospijeca: new Date(),
  operater: null,
  proizvod: null,
  nacinPlacanja: {
    value: "Transakcijski račun",
    label: "Transakcijski račun",
  },
  from: "",
};
export const proizvodi = [];
export const proizvod = {
  proizvod: null,
  cijena: null,
  kolicina: null,
  popust: null,
};
export const invoicePreview = { id: 0, from: "" };
export const clientData = {
  ulica: "",
  broj: "",
  naziv: "",
  drzava: null,
  oib: "",
  postanskiBroj: "",
  mjesto: "",
  vrstaKlijenta: null,
  hasPdvId: false,
};
export const productData = {
  vrstaIsporuke: null,
  naziv: "",
  sifra: "",
  cijena: "",
  unit: "",
};
export const invoicePayment = {
  datumPlacanja: new Date(),
  placanja: [],
  proizvodi: [],
  status: "",
  invoice: null,
  placeno: "",
  preostalo: "",
  ukupanIznos: "",
  placenoSad: "",
};
export const accountInfo = {
  naziv: "",
  ime: "",
  prezime: "",
  ulica: "",
  kucniBroj: "",
  mjesto: "",
  postanskiBroj: "",
  emailRacun: "",
  telefon: "",
};
export const proceedsData = {
  datum: new Date(),
  kartice: "",
  gotovina: "",
};
export const allInitialState = {
  invoice,
  proizvodi,
  proizvod,
  invoicePreview,
  clientData,
  productData,
  invoicePayment,
  accountInfo,
  proceedsData,
};

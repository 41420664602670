import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useStateMachine } from "little-state-machine";
import saveAs from "file-saver";
import { Modal } from "@material-ui/core";
import { authenticationService } from "../../services/authentication";
import {
  updateInvoicePreview,
  updateInvoice,
  addProizvodi,
} from "../../helpers/updateState";
import { formatCurrency, resetInvoiceData, storno } from "../../helpers";
import { b64toBlob, getTotalPrice } from "../../helpers/functions";
import { handlePostRequest } from "../../helpers/handle-response";
import Select from "../../components/Select";
import Button from "../../components/Button";
import { updateInvoiceTemplate } from "./functions";
import { getInvoiceNumber } from "../../helpers/getData";
import "./InvoicePreview.scss";

function handleGetPdf(current, currentUser, setDownloading, active, currency) {
  setDownloading(true);
  handlePostRequest(`https://pdf-api.accountant.hr/create-pdf`, [
    {
      selectedInvoice: current,
      currentUser,
      name: "invoice" + active,
      currency,
      barcode: current.canvas,
    },
  ])
    .then((res) => {
      setDownloading(false);
      saveAs(
        b64toBlob(res.data, "application/pdf"),
        `racun_${current.broj}_${current.datumRacun}.pdf`
      );
    })
    .catch((err) => {
      console.log(err);
    });
}
const InvoicePreview = ({ open, toggle, data }) => {
  let history = useHistory();
  const currentUser = authenticationService.currentUserValue;
  const {
    naziv,
    ulica,
    mjesto,
    kucniBroj,
    oib,
    iban,
    telefon,
    postanskiBroj,
    logo,
    email,
    emailRacun,
  } = currentUser;
  const { t, i18n } = useTranslation();
  const { data: invoiceRes } = useQuery("invoiceNumber", getInvoiceNumber);
  const queryClient = useQueryClient();
  const { actions, state } = useStateMachine({
    updateInvoicePreview,
    updateInvoice,
    addProizvodi,
  });
  const [status, setStatus] = useState({
    state: false,
    loading: false,
    message: "",
    error: "",
  });
  const invoice = data.filter((item) => item.id === state.invoicePreview.id)[0];
  const [current, setCurrent] = useState({
    ...invoice,
    canvas: state.invoicePreview.canvas,
  });

  const [active, setActive] = useState("hr");
  const [downloading, setDownloading] = useState(false);

  const [currency, setCurrency] = useState({ label: "HRK", value: "HRK" });
  const [loadCurrency, setLoadCurrency] = useState(false);

  const changeLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  const handleSetActive = (value) => {
    if (value === "hr") {
      current.nacinPlacanja = "Transakcijski račun";
    } else if (value === "en") {
      current.nacinPlacanja = "Bank transfer";
    }
    setActive(value);
  };
  const showStornoButton = () => {
    if (
      current.jir &&
      current.stornoRacuna === null &&
      current.storniran !== true
    )
      return true;
    else if (current.stornoRacuna !== null) return false;
    else if (current.storniran === true) return false;
  };
  const handleChangeCurrency = (value) => {
    setLoadCurrency(true);
    setCurrency({ value, label: value });
    if (value !== "HRK") {
      const date = current.datumRacun.split(" ")[0].split(".");

      handlePostRequest(`${process.env.REACT_APP_HOST_URL}/helpers/get/`, [
        {
          url: `https://api.hnb.hr/tecajn/v1?valuta=${value}&datum=${date[2]}-${date[1]}-${date[0]}`,
        },
      ]).then((res) => {
        const obj = Object.assign({}, current);
        const val = Number(res.data[0]["Srednji za devize"].replace(",", "."));
        const products = obj.proizvodi.map((item) => {
          const price = Number(item.cijena / val);
          const discount = (item.rabat / 100) * (price * item.kolicina);
          const totalPrice = price * item.kolicina - Number(discount);
          /*  console.log({
            price,
            totalPrice,
            kol: item.kolicina,
            discount: Number(discount),
          }); */
          return {
            ...item,
            newPrice: totalPrice,
            cijena: price,
            pdvPrice: totalPrice,
            ukupnaCijena: totalPrice,
            popust: discount,
            price,
            discount,
            totalPrice,
            itemPrice: price,
          };
        });
        obj.proizvodi = products;
        //console.log(obj, val, test);

        setCurrent(obj);
        setLoadCurrency(false);
      });
    } else {
      setCurrent({ ...invoice, canvas: state.invoicePreview.canvas });
      setLoadCurrency(false);
    }
  };
  //change *state.from* everytime modal is closed or exited

  return (
    <Modal
      open={open}
      onClose={() => {
        toggle();
        actions.updateInvoicePreview({ from: "" });
      }}
    >
      <div
        className="modal fade d-block"
        style={{ opacity: 1, overflowY: "auto" }}
        tabIndex="-1"
        id="modalForm"
      >
        <div className="modal-lg modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Pregled računa</h5>
              <button
                href="#"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  toggle();
                  actions.updateInvoicePreview({ from: "" });
                }}
              >
                <em className="icon ni ni-cross"></em>
              </button>
            </div>
            <div className="modal-body">
              {downloading ? (
                <div style={{ display: "flex" }}>
                  <span style={{ marginLeft: "auto" }}>
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </span>
                  <span
                    style={{
                      marginLeft: 15,
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    Preuzimanje...
                  </span>
                </div>
              ) : (
                current.hasOwnProperty("proizvodi") &&
                !current.broj.includes("Predložak") && (
                  <div className="d-flex flex-column mb-2">
                    <div className="d-flex ml-auto mr-4">
                      <button
                        className={`mr-2 ${
                          active === "hr"
                            ? "btn btn-primary"
                            : "customButton btn btn-dim btn-outline-primary"
                        }`}
                        onClick={() => {
                          handleSetActive("hr");
                          changeLanguage("hr");
                          handleChangeCurrency("HRK");
                        }}
                        style={
                          active === "hr" ? { transform: "scale(1.1)" } : {}
                        }
                      >
                        HR
                      </button>
                      <button
                        className={`mr-2 ${
                          active === "en"
                            ? "btn btn-primary"
                            : "customButton btn btn-dim btn-outline-primary"
                        }`}
                        style={
                          active === "en" ? { transform: "scale(1.1)" } : {}
                        }
                        onClick={() => {
                          handleSetActive("en");
                          changeLanguage("en");
                        }}
                      >
                        EN
                      </button>
                      <Button
                        classnames="customButton btn btn-success form-control-lg d-flex my-2 mx-auto mx-lg-auto my-lg-0 "
                        style={{ display: "flex" }}
                        onClick={() =>
                          handleGetPdf(
                            current,
                            currentUser,
                            setDownloading,
                            active,
                            currency.value
                          )
                        }
                      >
                        Preuzmi PDF
                      </Button>

                      {showStornoButton() ? (
                        <Button
                          classnames="customButton btn btn-secondary form-control-lg d-flex  my-2  my-lg-0 ml-1"
                          style={{ display: "flex" }}
                          onClick={() => {
                            //console.log(invoice, state.invoice);
                            //console.log(storno(current));
                            const stornoData = resetInvoiceData(
                              state.invoice,
                              current.broj,
                              storno(invoice),
                              "storno"
                            );
                            //add Placanja[] to sort
                            //console.log(stornoData);
                            actions.updateInvoice(stornoData);
                            history.push("/novi-racun");
                          }}
                        >
                          Storniraj račun
                        </Button>
                      ) : null}
                    </div>
                    {active === "en" && (
                      <div className="d-flex mr-4 mt-4">
                        <div
                          className="form-group row d-flex align-items-center ml-auto justify-content-end"
                          style={{ maxWidth: 385, width: "100%" }}
                        >
                          <label className="col-md-4 form-control-label d-flex justify-content-lg-end mb-0">
                            Odabir valute
                          </label>
                          <div className="form-group col-md-5 ">
                            <Select
                              value={currency}
                              onChange={(e) => handleChangeCurrency(e.value)}
                              options={[
                                { label: "HRK", value: "HRK" },
                                { label: "EUR", value: "EUR" },
                                { label: "USD", value: "USD" },
                                { label: "GBP", value: "GBP" },
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              )}
              <div
                style={{ opacity: loadCurrency ? "0.1" : "1" }}
                id="divcontents"
              >
                <div id="page_1">
                  <div className="dclr"></div>
                  <div className="image">
                    {logo !== "none.png" ? (
                      <div>
                        <img
                          src={`${process.env.REACT_APP_HOST_URL}/${logo}`}
                          alt="logo"
                        />
                      </div>
                    ) : null}
                    <div className="opis">
                      <div>
                        <h2>{naziv}</h2>
                        <p>
                          {ulica} {kucniBroj}, {postanskiBroj} {mjesto}
                        </p>
                      </div>
                      <div className="wrapper podaci">
                        <div className="labels">
                          <p>OIB</p>
                          <p>PDV ID</p>
                          <p>IBAN</p>
                        </div>
                        <div>
                          <p>{String(oib)}</p>
                          <p>HR{String(oib)}</p>
                          <p>{iban}</p>
                        </div>
                      </div>
                      <div className="wrapper telefon">
                        <div className="labels2">
                          <p>T: {telefon}</p>
                        </div>
                        <div>
                          <p>E: {emailRacun.length > 0 ? emailRacun : email}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="id1_1">
                    <p className="p0 ft0">
                      RAČUN / INVOICE: <nobr>{current.broj}</nobr>
                    </p>
                  </div>
                  <div id="id1_2">
                    <div id="id1_2_1">
                      <table cellPadding="0" cellSpacing="0" className="t0">
                        <tbody>
                          <tr>
                            <td className="tr0 td0">
                              <p className="p1 ft1">Mjesto:</p>
                            </td>
                            <td className="tr0 td1">
                              <p className="p1 ft1">Place:</p>
                            </td>
                            <td className="tr0 td2">
                              <p className="p2 ft2">
                                {current.kupacDetails.mjesto}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="tr1 td0">
                              <p className="p1 ft1">Datum izdavanja:</p>
                            </td>
                            <td className="tr1 td1">
                              <p className="p1 ft1">Date of issue:</p>
                            </td>
                            <td className="tr1 td2">
                              <p className="p2 ft2">{current.datumRacun}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="tr2 td0">
                              <p className="p1 ft1">Datum isporuke:</p>
                            </td>
                            <td className="tr2 td1">
                              <p className="p1 ft1">Date of delivery:</p>
                            </td>
                            <td className="tr2 td2">
                              <p className="p2 ft2">
                                {current.izvrsenoOd === current.izvrsenoDo
                                  ? current.izvrsenoOd
                                  : current.izvrsenoOd +
                                    " - " +
                                    current.izvrsenoDo}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="tr1 td0">
                              <p className="p1 ft1">Dospijeće:</p>
                            </td>
                            <td className="tr1 td1">
                              <p className="p1 ft1">Due date:</p>
                            </td>
                            <td className="tr1 td2">
                              <p className="p2 ft2">{current.datumDospijeca}</p>
                            </td>
                          </tr>
                          <tr>
                            <td className="tr1 td0">
                              <p className="p1 ft1">Poziv na broj:</p>
                            </td>
                            <td className="tr1 td1">
                              <p className="p1 ft1">Reference:</p>
                            </td>
                            <td className="tr1 td2">
                              <p className="p2 ft2">
                                HR00{" "}
                                <nobr>
                                  {current.broj.substring(
                                    0,
                                    current.broj.indexOf(
                                      current.broj.match(/\D/)
                                    )
                                  )}
                                </nobr>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="tr2 td0">
                              <p className="p1 ft1">Način plaćanja:</p>
                            </td>
                            <td className="tr2 td1">
                              <p className="p1 ft1">Payment method:</p>
                            </td>
                            <td className="tr2 td2">
                              <p className="p2 ft2">
                                {current.nacinPlacanja === undefined
                                  ? ""
                                  : current.nacinPlacanja}{" "}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td className="tr2 td0">
                              <p className="p1 ft1">Operater:</p>
                            </td>
                            <td className="tr2 td1">
                              <p className="p1 ft1">Issued by:</p>
                            </td>
                            <td className="tr2 td2">
                              <p className="p2 ft2">
                                {current.operater === undefined
                                  ? ""
                                  : current.operater}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div id="id1_2_2">
                      <p className="p0 ft3">{current.kupac}</p>
                      <p className="p3 ft4">
                        {current.kupacDetails.mjesto},{" "}
                        {current.kupacDetails.adresa}
                      </p>
                      <p className="p3 ft4">
                        OIB: {String(current.kupacDetails.oib)}
                      </p>
                    </div>
                  </div>
                  <div id="id1_3">
                    <table className="table">
                      <tbody>
                        <tr className="borderBottom">
                          <td className="tr3 td3">
                            <p className="p4 ft5">{t("vrstaRobe")}</p>
                          </td>
                          <td className="tr3 td4">
                            <p className="p5 ft5">{t("kolicina")}</p>
                          </td>
                          <td className="tr3 td5">
                            <p className="p6 ft5">{t("cijena")}</p>
                          </td>
                          <td className="tr3 td6">
                            <p className="p7 ft5"> {t("popust")}</p>
                          </td>
                          <td className="tr3 td7">
                            <p className="p8 ft6">
                              {t("vrijednostHRK")} {currency.value}
                            </p>
                          </td>
                        </tr>

                        {current.proizvodi.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="tr4 td3 borderRight">
                                <p className="pd1 ft7">{item.naziv}</p>
                              </td>
                              <td className="tr4 td4 borderRight">
                                <p className="pd2 ft7">
                                  {formatCurrency(item.kolicina)} {item.unit}
                                </p>
                              </td>
                              <td className="tr4 td5 borderRight">
                                <p className="pd3 ft7">
                                  {formatCurrency(item.cijena)}
                                </p>
                              </td>
                              <td className="tr4 td6 borderRight">
                                <p className="pd4 ft7">
                                  {formatCurrency(item.popust)} ({item.rabat}%)
                                </p>
                              </td>
                              <td className="tr4 td7">
                                <p className="pd5 ft7">
                                  {formatCurrency(item.newPrice)}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table style={{ borderBottom: 0 }}>
                      <tbody>
                        <tr>
                          <td className="tr4 td3" colSpan="1">
                            <p className="pd1 ft7"></p>
                          </td>
                          <td className="tr4 td4" colSpan="1">
                            <p className="pd2 ft7"></p>
                          </td>
                          <td className="tr4 td5" colSpan="1">
                            <p className="pd3 ft7"></p>
                          </td>
                          <td className="tr4 td6">
                            <p className="pd4 ft7 valuesSMargin">
                              UKUPNO {currency.value}
                            </p>
                          </td>
                          <td className="tr4 td7">
                            <p className="pd5 ft7 valuesFont">
                              {formatCurrency(
                                getTotalPrice(current.proizvodi, "newPrice")
                                  .newPrice
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="tr4 td3">
                            <p className="pd1 ft7"></p>
                          </td>
                          <td className="tr4 td4">
                            <p className="pd2 ft7"></p>
                          </td>
                          <td className="tr4 td5">
                            <p className="pd3 ft7"></p>
                          </td>
                          <td className="tr4 td6">
                            <p className="pd4 ft7 valuesSMargin">
                              TOTAL {currency.value}
                            </p>
                          </td>
                          <td className="tr4 td7">
                            <p className="pd5 ft7 valuesFont">
                              {formatCurrency(
                                getTotalPrice(current.proizvodi, "pdvPrice")
                                  .pdvPrice
                              )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      style={{
                        display: current.jir ? "block" : "none",
                      }}
                    >
                      <p>JIR: {current.jir}</p>
                    </div>
                    <div className="barcode">
                      <div style={{ flex: 1 }}>
                        <p>{current.napomena}</p>
                      </div>
                      <div>
                        <img
                          style={{
                            width: 250,
                            height: 80,
                            display: "flex",
                            marginLeft: "auto",
                          }}
                          src={document
                            .getElementById("barcode")
                            .firstChild.toDataURL()}
                          alt="barcode pay"
                        />
                      </div>
                    </div>

                    <p className="p19 ft11"></p>
                  </div>
                </div>
              </div>
              {status.state && status.message.length > 0 && (
                <div style={{ maxWidth: 800 }} className="mx-auto mb-2">
                  <div
                    className="alert alert-dismissible alert-icon alert-fill alert-success"
                    role="alert"
                  >
                    <em className="icon ni ni-check-circle"></em>
                    {status.message.length > 0 && status.message}
                    <button
                      className="close"
                      data-dismiss="alert"
                      type="button"
                      onClick={() => setStatus({ state: false, message: "" })}
                    ></button>
                  </div>
                </div>
              )}
              {current.broj.includes("Predložak") && (
                <div className="d-flex row ml-auto">
                  <div className="col-12 col-lg-3 ml-auto">
                    <Button
                      disabled={status.state}
                      type="button"
                      classnames="customButton btn-dim btn-outline-primary form-control-lg d-flex my-2 mx-auto ml-lg-auto mr-lg-0 my-lg-0 "
                      onClick={() => {
                        updateInvoiceTemplate(
                          current,
                          currentUser,
                          invoiceRes,
                          false,
                          setStatus,
                          queryClient,
                          toggle
                        );
                      }}
                    >
                      Izdaj račun
                    </Button>
                  </div>
                  <div className="col-12 col-lg-4 my-2 mx-lg-0 my-lg-0">
                    <Button
                      disabled={status.state}
                      type="button"
                      classnames="customButton btn-dim btn-outline-success form-control-lg d-flex my-2 mx-auto mx-lg-auto my-lg-0 "
                      onClick={() => {
                        const data = {
                          id: current.id,
                          broj: current.broj,
                          datumDospijeca: dayjs(
                            current.datumDospijeca,
                            "DD.MM.YYYY"
                          ),
                          datumRacuna: dayjs(
                            current.datumRacun,
                            "DD.MM.YYYY HH:mm"
                          ),
                          izvrsenoOd: dayjs(current.izvrsenoOd, "DD.MM.YYYY"),
                          izvrsenoDo: dayjs(current.izvrsenoDo, "DD.MM.YYYY"),
                          nacinPlacanja: {
                            value: current.nacinPlacanja,
                            label: current.nacinPlacanja,
                          },
                          klijent: {
                            value: current.kupacDetails,
                            label: current.kupac,
                          },
                          operater: current.operater,
                          from: "editPredlozak",
                        };
                        actions.updateInvoice(data);
                        actions.addProizvodi(current.proizvodi);
                        history.push("/novi-racun");
                      }}
                    >
                      Uredi predložak računa
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer bg-light">
              <span className="sub-text">Modal Footer Text</span>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InvoicePreview;

const StatCard = ({ title, description }) => {
    return (
        <div className="card bg-light">
            <div className="card-header h4 text-center">{title}</div>
            <div className="card-inner d-flex py-5">
                <h5 className="card-title m-auto">{description}</h5>
                
            </div>
        </div>
    );
}

export default StatCard;
import { useState } from "react";
import { useQuery } from "react-query";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import saveAs from "file-saver";
import CustomSelect from "../../components/Select";
import { options } from "./utils";
import InputValue from "../../components/InputValue";
import Button from "../../components/Button";
import { useTitle } from "../../helpers/hooks";
import { b64toBlob, stringToNum } from "../../helpers/functions";
import { handlePostRequest } from "../../helpers/handle-response";
import { authenticationService } from "../../services/authentication";
import { getAllInvoices } from "../../helpers/getData";
import { getPOSD } from "../Settings/functions";
import PoSdPreview from "./PoSdPreview";

const PoSd = () => {
  const currentUser = authenticationService.currentUserValue;
  const { data: invoices } = useQuery("invoices", getAllInvoices);
  useTitle("PO-SD");
  const {
    register,
    handleSubmit,
    clearErrors,
    errors,
    setError,
    control,
    getValues,
    setValue,
  } = useForm({ defaultValues: { uplaceno: "" } });
  const [open, setOpen] = useState(false);
  const [html, setHtml] = useState();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const userData = {
    adresa: currentUser.adresa,
    banka: currentUser.banka,
    djelatnost: currentUser.djelatnost,
    nazivDjelatnosti: currentUser.nazivDjelatnosti,
    email: currentUser.email,
    iban: currentUser.iban,
    ulica: currentUser.ulica,
    kucniBroj: currentUser.kucniBroj,
    mjesto: currentUser.mjesto,
    naziv: currentUser.naziv,
    oib: currentUser.oib,
    telefon: currentUser.telefon,
    vlasnik: currentUser.vlasnik,
    ime: currentUser.ime,
    prezime: currentUser.prezime,
  };
  const [downloading, setDownloading] = useState({
    pdf: false,
    xml: false,
    preview: false,
  });

  const submitPDF = (data) => {
    console.log(data);
    setDownloading((downloading) => ({ ...downloading, pdf: true }));
    const obj = {
      ...data,
      uplaceno: stringToNum(data.uplaceno.length > 0 ? data.uplaceno : "0,00"),
      umanjenje: data.vukovar || data.potpomognutaPodrucja || data.otociSkupine,
    };
    handlePostRequest(`https://pdf-api.accountant.hr/create-pdf/`, [
      {
        userData,
        values: obj,
        data: invoices,
        name: "PO-SD",
      },
    ])
      .then((res) => {
        setDownloading((downloading) => ({ ...downloading, pdf: false }));

        saveAs(b64toBlob(res.data), "po-sd_obrazac.pdf");
      })
      .catch((e) => console.error(e));
    console.log(obj);
  };
  const submitXML = async (data) => {
    console.log(data);
    const obj = {
      ...data,
      uplaceno: data.uplaceno.length > 0 ? data.uplaceno : "0,00",
    };
    setDownloading((downloading) => ({ ...downloading, xml: true }));
    getPOSD(currentUser, obj, invoices, () => {
      setDownloading((downloading) => ({ ...downloading, xml: false }));
    });
  };
  const handlePreview = (data) => {
    setDownloading((downloading) => ({ ...downloading, preview: true }));

    const obj = {
      ...data,
      uplaceno: stringToNum(data.uplaceno.length > 0 ? data.uplaceno : "0,00"),
      umanjenje: data.vukovar || data.potpomognutaPodrucja || data.otociSkupine,
    };
    handlePostRequest(`https://pdf-api.accountant.hr/create-pdf/`, [
      {
        userData,
        values: obj,
        data: invoices,
        name: "PO-SD",
      },
    ])
      .then((res) => {
        setHtml(res.data);
        setDownloading((downloading) => ({ ...downloading, preview: false }));
        setOpen(true);
      })
      .catch((e) => alert(e));
  };

  return (
    <div className="container-md">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="card-head">
            <h4>PO-SD Obrazac</h4>
          </div>
          <div>
            <hr></hr>
          </div>
          <form className="form" onSubmit={handleSubmit(submitPDF)}>
            <div className="row d-flex">
              <div className="col d-flex custom-control-lg custom-checkbox ml-4 ml-lg-0 mb-4 justify-content-start justify-content-md-center custom-control custom-control-xs custom-checkbox customCheck">
                <input
                  type="checkbox"
                  className="form-control custom-control-input position-relative"
                  id="vukovar"
                  name="vukovar"
                  ref={register()}
                />

                <label className="custom-control-label" htmlFor="vukovar">
                  Grad Vukovar
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex custom-control-lg custom-checkbox ml-4 ml-lg-0 mb-4 justify-content-start justify-content-md-center custom-control custom-control-xs custom-checkbox customCheck">
                <input
                  type="checkbox"
                  className="form-control custom-control-input position-relative"
                  id="potpomognutaPodrucja"
                  name="potpomognutaPodrucja"
                  ref={register()}
                />

                <label
                  className="custom-control-label"
                  htmlFor="potpomognutaPodrucja"
                >
                  Potpomognuta područja jedinica lokalne samouprave I. skupine
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col d-flex custom-control-lg custom-checkbox ml-4 ml-lg-0 mb-4 justify-content-start justify-content-md-center custom-control custom-control-xs custom-checkbox customCheck">
                <input
                  type="checkbox"
                  className="form-control custom-control-input position-relative"
                  id="otociSkupine"
                  name="otociSkupine"
                  ref={register()}
                />

                <label className="custom-control-label" htmlFor="otociSkupine">
                  Otoci I. skupine
                </label>
              </div>
            </div>
            <div className="row d-flex mt-4 mb-4">
              <div className="col-12 d-flex justify-content-center justify-content-lg-center mb-2">
                <label className="my-auto">Izaberi mjesto za prirez:</label>
              </div>
              <div className="col-4"></div>
              <div className="col-12 col-md-4">
                <Controller
                  name="prirez"
                  defaultValue={null}
                  control={control}
                  id="prirez"
                  rules={{ required: "Izaberi" }}
                  placeholder="Izaberi"
                  menuPlacement="auto"
                  options={options}
                  noOptionsMessage={() => "Mjesto ne postoji"}
                  as={CustomSelect}
                />

                {errors.prirez && (
                  <span className="error">{errors.prirez.message}</span>
                )}
              </div>

              <div className="col-4"></div>
            </div>
            <div className="row d-flex mb-5">
              <div className="col-12 d-flex justify-content-center justify-content-lg-center mb-2">
                <label className="my-auto">
                  Ukupno uplaćeni paušalni porez:{" "}
                </label>
              </div>

              <div className="col-12 col-md-4 offset-md-4 d-flex">
                <Controller
                  control={control}
                  name="uplaceno"
                  render={({ onChange, onBlur, value, name }) => (
                    <NumberFormat
                      name={name}
                      classnames="form-control-lg"
                      placeholder="Uplaćeni iznos"
                      customInput={InputValue}
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator={","}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.uplaceno && (
                  <span className="error" style={{ bottom: -20 }}>
                    {errors.uplaceno.message}
                  </span>
                )}
              </div>
            </div>

            <div className="row d-flex mb-2">
              <div className="col-12 col-lg-2 d-flex justify-content-center my-1 my-lg-0 justify-content-lg-center">
                <Button
                  type="button"
                  disabled={downloading.preview}
                  classnames="btn-lg btn-dim btn-outline-pausal"
                  onClick={handleSubmit(handlePreview)}
                >
                  {downloading.preview ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Loading...</span>
                    </>
                  ) : (
                    "Pregled PO-SD"
                  )}
                </Button>
              </div>
              <div className="col-lg-6 space"></div>
              <div className="col-12 col-lg-2 d-flex justify-content-center my-1 my-lg-0 justify-content-lg-end">
                <Button
                  type="button"
                  classnames="btn-lg btn-dim btn-outline-pausal"
                  onClick={handleSubmit(submitPDF)}
                  disabled={downloading.pdf}
                >
                  {downloading.pdf ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Loading...</span>
                    </>
                  ) : (
                    "Preuzmi PDF"
                  )}
                </Button>
              </div>
              <div className="col-12 col-lg-2 d-flex justify-content-center my-1 my-lg-0 justify-content-lg-center">
                <Button
                  type="button"
                  classnames="btn-lg btn-dim btn-outline-pausal"
                  onClick={handleSubmit(submitXML)}
                  disabled={downloading.xml}
                >
                  {downloading.xml ? (
                    <>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span class="sr-only">Loading...</span>
                    </>
                  ) : (
                    "Preuzmi XML"
                  )}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {open ? (
        <PoSdPreview
          open={open}
          setOpen={setOpen}
          html={html}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages}
          setNumPages={setNumPages}
        />
      ) : null}
    </div>
  );
};
export default PoSd;

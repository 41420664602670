import { useState, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import NewProduct from "../Products/NewProduct";
import { useStateMachine } from "little-state-machine";
import { updateProduct } from "../../helpers/updateState";
import { authenticationService } from "../../services/authentication";
import { handleGetRequest } from "../../helpers/handle-response";
import { getProducts } from "../../helpers/getData";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteModal from "../Products/DeleteModal";
import { formatCurrency } from "../../helpers";

const Buttons = ({ toggle }) => {
  return (
    <Button
      classnames="btn-dim btn-outline-pausal btnCustom borderAll"
      onClick={toggle}
    >
      Dodaj novi proizvod
    </Button>
  );
};

const ProductsReview = (props) => {
  const currentUser = authenticationService.currentUserValue;
  const queryClient = useQueryClient();
  const { data: products, isFetching } = useQuery("products", getProducts);
  const { actions } = useStateMachine({ updateProduct });
  const [deleteProduct, setDeleteProduct] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState({ state: false, from: "" });
  const toggle = () => {
    actions.updateProduct({
      id: null,
      vrstaIsporuke: null,
      naziv: "",
      sifra: "",
      cijena: "",
      unit: "",
    });
    setOpen((open) => ({ state: !open.state, from: "newProduct" }));
  };
  const [status, setStatus] = useState({
    state: false,
    sending: false,
    message: "",
  });
  console.log(products);
  const handleDelete = () => {
    setStatus((status) => ({ ...status, sending: true }));
    handleGetRequest(
      `${process.env.REACT_APP_HOST_URL}/products/delete?id=${deleteProduct.id}&client=${deleteProduct.naziv}`,
      currentUser.token
    )
      .then((res) => {
        queryClient.invalidateQueries("products");
        setStatus({ state: true, sending: false, message: res });

        setDeleteModal(false);
      })
      .catch((error) => console.log(error));
  };

  const columns = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Vrsta Isporuke", accessor: "vrstaIsporuke" },
      { Header: "Šifra", accessor: "sifra" },
      {
        Header: "Cijena",
        accessor: "cijena",
        Cell: (props) => formatCurrency(props.value),
      },
      { Header: "Jedinica mjere", accessor: "unit" },
      { Header: "Količina na skladištu", accessor: "kolicina" },
      {
        Header: "Uredi",
        noPrint: true,
        Cell: (props) => {
          return (
            <button
              className="blankBtn"
              onClick={() => {
                const productData = {
                  ...props.row.original,
                  cijena: Number(props.row.original.cijena),
                  vrstaIsporuke: {
                    label: props.row.original.vrstaIsporuke,
                    value: props.row.original.vrstaIsporuke,
                  },
                };
                actions.updateProduct(productData);
                setOpen((open) => ({
                  state: !open.state,
                  from: "editProduct",
                }));
              }}
            >
              <em className="icon ni ni-edit tableIcons"></em>
            </button>
          );
        },
      },
      {
        Header: "Izbriši",
        noPrint: true,
        Cell: (props) => {
          return (
            <button
              //disabled={disabled}
              onClick={() => {
                //actions.updateInvoicePreview(props.row.original);
                setDeleteProduct(props.row.original);
                setDeleteModal(true);
              }}
              className="blankBtn"
            >
              <em
                className="icon ni ni-cross tableIcons"
                style={{ color: "#E85347" }}
              ></em>
            </button>
          );
        },
      },
    ],
    []
  );
  return (
    <div className="w-100">
      <div className="card-inner card-inner-lg ">
        <div className="nk-block-head nk-block-head-lg noPrint">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">Baza proizvoda</h4>
            </div>
            <div className="nk-block-head-content align-self-start d-lg-none">
              <a
                className="toggle btn btn-icon btn-trigger mt-n1"
                onClick={props.setOpen}
              >
                <em className="icon ni ni-menu-alt-r"></em>
              </a>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="nk-data data-list">
            <div className="data-head mb-4 noPrint">
              <h6 className="overline-title">Basics</h6>
            </div>
            <div className=" container-fluid">
              {" "}
              {isFetching ? (
                <div>
                  <h3>Učitavanje...</h3>
                </div>
              ) : (
                <Table
                  columns={columns}
                  data={products?.data}
                  buttons={<Buttons toggle={toggle} />}
                  name="proizvodi"
                  pdfName="pregledRacuna"
                  exportCols={[
                    { Header: "Naziv", accessor: "naziv" },
                    { Header: "Šifra", accessor: "sifra" },
                    { Header: "Cijena", accessor: "cijena" },
                    { Header: "Jedinica mjere", accessor: "unit" },
                    { Header: "Količina na skladištu", accessor: "kolicina" },
                  ]}
                  //replace text and dataField inside invoicehr-en html/pdf template
                  pdfCols={[
                    { Header: "Naziv", accessor: "naziv" },
                    { Header: "Šifra", accessor: "sifra" },
                    { Header: "Cijena", accessor: "cijena" },
                    { Header: "Jedinica mjere", accessor: "unit" },
                    { Header: "Količina na skladištu", accessor: "kolicina" },
                  ].map((item) => ({
                    text: item.Header,
                    dataField: item.accessor,
                  }))}
                  exportData={products.data.map((item) => ({
                    naziv: item.naziv,
                    sifra: item.sifra,
                    cijena: item.cijena,
                    unit: item.unit,
                    kolicina: item.kolicina
                  }))}
                />
              )}
              <NewProduct
                open={open.state}
                toggle={toggle}
                from={open.from}
                products={products}
              />
              <DeleteModal
                deleteProduct={deleteProduct}
                handleDelete={handleDelete}
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                status={status}
                setStatus={setStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductsReview;

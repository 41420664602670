import { useState, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import NewProduct from "./NewProduct";
import { useStateMachine } from "little-state-machine";
import { updateProduct } from "../../helpers/updateState";
import { authenticationService } from "../../services/authentication";
import { handleGetRequest } from "../../helpers/handle-response";
import { getProducts } from "../../helpers/getData";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteModal from "./DeleteModal";
import { stringToNum } from "../../helpers/functions";

const Buttons = ({ toggle }) => {
  return (
    <Button classnames="btn-dim btn-outline-primary" onClick={toggle}>
      Dodaj novi proizvod
    </Button>
  );
};

const ProductsReview = () => {
  const currentUser = authenticationService.currentUserValue;
  const queryClient = useQueryClient();
  const { data: products, isFetching } = useQuery("products", getProducts);
  const { actions } = useStateMachine({ updateProduct });
  const [deleteProduct, setDeleteProduct] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [open, setOpen] = useState({ state: false, from: "" });
  const toggle = () => {
    actions.updateProduct({
      id: null,
      vrstaIsporuke: null,
      naziv: "",
      sifra: "",
      cijena: "",
      unit: "",
    });
    setOpen((open) => ({ state: !open.state, from: "newProduct" }));
  };
  const [status, setStatus] = useState({
    state: false,
    sending: false,
    message: "",
  });

  const handleDelete = () => {
    setStatus((status) => ({ ...status, sending: true }));
    handleGetRequest(
      `${process.env.REACT_APP_HOST_URL}/products/delete?id=${deleteProduct.id}&client=${deleteProduct.naziv}`,
      currentUser.token
    )
      .then((res) => {
        queryClient.invalidateQueries("products");
        setStatus({ state: true, sending: false, message: res });

        setDeleteModal(false);
      })
      .catch((error) => console.log(error));
  };

  const columns = useMemo(
    () => [
      { Header: "Naziv", accessor: "naziv" },
      { Header: "Vrsta Isporuke", accessor: "vrstaIsporuke" },
      { Header: "Šifra", accessor: "sifra" },
      { Header: "Cijena", accessor: "cijena" },
      { Header: "Jedinica mjere", accessor: "unit" },
      { Header: "Količina na skladištu", accessor: "" },
      {
        Header: "Uredi",
        Cell: (props) => {
          return (
            <button
              className="blankBtn"
              onClick={() => {
                const productData = {
                  ...props.row.original,
                  cijena: stringToNum(props.row.original),
                  vrstaIsporuke: {
                    label: props.row.original.vrstaIsporuke,
                    value: props.row.original.vrstaIsporuke,
                  },
                };
                actions.updateProduct(productData);
                setOpen((open) => ({
                  state: !open.state,
                  from: "editProduct",
                }));
              }}
            >
              <em className="icon ni ni-edit tableIcons"></em>
            </button>
          );
        },
      },
      {
        Header: "Izbriši",
        Cell: (props) => {
          return (
            <button
              //disabled={disabled}
              onClick={() => {
                //actions.updateInvoicePreview(props.row.original);
                setDeleteProduct(props.row.original);
                setDeleteModal(true);
              }}
              className="blankBtn"
            >
              <em
                className="icon ni ni-cross tableIcons"
                style={{ color: "#E85347" }}
              ></em>
            </button>
          );
        },
      },
    ],
    []
  );
  return (
    <div>
      <div className="container-fluid">
        {isFetching ? (
          <div>
            <h3>Učitavanje...</h3>
          </div>
        ) : (
          <Table
            columns={columns}
            data={products?.data}
            buttons={<Buttons toggle={toggle} />}
          />
        )}
      </div>
      <NewProduct
        open={open.state}
        toggle={toggle}
        from={open.from}
        products={products}
      />
      <DeleteModal
        deleteProduct={deleteProduct}
        handleDelete={handleDelete}
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        status={status}
        setStatus={setStatus}
      />
    </div>
  );
};
export default ProductsReview;
